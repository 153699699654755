import React, { useRef } from "react"
import { Loader } from "kui-utils"
import { observer } from "mobx-react"
import { MeterCostCellProps } from "./types"
import EditValueField from "../../../../../../../../components/common/EditValueField"
import useExpensesStore from "../../../../store"

const MeterCostCell = ({ meter, disabled }: MeterCostCellProps) => {
  const { overviewStore } = useExpensesStore()
  const loaderRef = useRef(new Loader())
  const loader = loaderRef.current
  const { noTariff, totalPrice } = meter

  const handleSave = async (value: number) => {
    if (overviewStore.id) {
      loader.startLoading()

      await meter.postMeterCost(overviewStore.id, value)

      loader.endLoading()
    }
  }

  return noTariff ? (
    <EditValueField
      isLoading={loader.isLoading}
      value={totalPrice || 0}
      placeholder={String(totalPrice)}
      onSave={handleSave}
      disabled={disabled}
    />
  ) : (
    <span>{totalPrice?.toLocaleString()} ₽</span>
  )
}

export default observer(MeterCostCell)
