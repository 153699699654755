import React, { useEffect, useMemo } from "react"
import { PhotoSliderProps } from "./types"
import FullSliderModal from "../FullSliderModal"
import PhotoSliderHeaderOptions from "./PhotoSliderHeaderOptions"
import { PhotoCardParams } from "../PhotoCard/types"

const PhotoSlider = <T extends PhotoCardParams>(props: PhotoSliderProps<T>) => {
  const { images, handleClose, onDelete, ...other } = props
  const formattedImages = useMemo(
    () => images.map((image) => image.largeImageUrl),
    [images]
  )

  useEffect(() => {
    if (images.length === 0) {
      handleClose()
    }
  }, [images])

  return (
    <FullSliderModal
      images={formattedImages}
      handleClose={handleClose}
      renderHeaderComponent={(index, slider) => (
        <PhotoSliderHeaderOptions<T>
          index={index}
          onDelete={onDelete}
          slider={slider}
          image={images[index]}
        />
      )}
      {...other}
    />
  )
}

export default PhotoSlider
