import React from "react"
import styled from "@emotion/styled"
import { Caption } from "kui-basic"
import { MeterTotalRowProps } from "./types"
import TableRow from "../../../../../../../../components/ui/TableRow"
import TableCell from "../../../../../../../../components/ui/TableCell"

const MeterTotalRow = (props: MeterTotalRowProps) => {
  const { totalPrice } = props

  return (
    <StyledRow>
      <TableCell colSpan={9}>Total</TableCell>
      <TableCell colSpan={2}>
        <Caption weight={600}>{totalPrice.toLocaleString()} ₽</Caption>
      </TableCell>
    </StyledRow>
  )
}

export default MeterTotalRow

const StyledRow = styled(TableRow)`
  td:first-child {
    padding-left: 27px;
  }
  td:last-child {
    padding-right: 15px;
  }
`
