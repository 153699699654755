import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { observer } from "mobx-react"
import { EditServiceReportFields, EditServiceReportFormProps } from "./types"
import EditValueField from "../../../../../components/common/EditValueField"

function EditServiceReportForm({ service }: EditServiceReportFormProps) {
  const form = useForm<EditServiceReportFields>({
    defaultValues: {
      reportNumber: service.reportNumber,
    },
  })

  const handleSubmit = async (data: EditServiceReportFields) => {
    await service.updateReport(data.reportNumber)
  }

  useEffect(() => {
    form.setValue("reportNumber", service.reportNumber)
  }, [service.reportNumber])

  return (
    <EditValueField
      placeholder={String(service.reportNumber)}
      onSave={form.handleSubmit(handleSubmit)}
      data-testid="utility_value"
      {...form.register("reportNumber")}
    />
  )
}

export default observer(EditServiceReportForm)
