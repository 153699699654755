/* eslint-disable */
// @ts-nocheck

import React, { Component, ErrorInfo } from "react"
import ErrorComponent from "../ErrorComponent"

class ErrorBoundary extends Component {
  constructor(props: any) {
    super(props)
    this.state = { hashError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ hasError: true })
    console.error(error && error.toString())
    console.error(`Error Stack: ${errorInfo && errorInfo.componentStack}`)
  }

  render() {
    if (this.state.hasError) {
      return <ErrorComponent height={this.props.height} />
    }
    return this.props.children
  }
}

export default ErrorBoundary
