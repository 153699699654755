import { makeAutoObservable } from "mobx"
import { InspectionRoomPhotoModel, InspectionRoomPhotoTypes } from "kui-crm"
import { Loader } from "kui-utils"
import ImageStore from "../../../../../store/templates/ImageStore"
import longPolling from "../../../../../utils/agent/longPolling"
import ApartmentInspectionAgent from "../../../../../agent/ApartmentInspection"

class RoomImagesStore {
  id: number

  type: InspectionRoomPhotoTypes

  comment?: string

  images: ImageStore[]

  roomNumber?: number

  isUploaded: boolean

  loader: Loader

  apartmentId: number

  inspectionId: number

  wasUploaded: boolean

  constructor(
    roomImages: InspectionRoomPhotoModel,
    inspectionId: number,
    apartmentId: number
  ) {
    this.id = roomImages.id
    this.type = roomImages.purpose
    this.comment = roomImages.comment
    this.images = roomImages.photos.map((image) =>
      ImageStore.initFromImageModel(image)
    )
    this.roomNumber =
      typeof roomImages.room_order_number === "number"
        ? roomImages.room_order_number + 1
        : 0
    this.isUploaded = roomImages.uploaded
    this.loader = new Loader()
    this.inspectionId = inspectionId
    this.apartmentId = apartmentId
    this.wasUploaded = false
    this.checkImagesIsUploaded()
    makeAutoObservable(this)
  }

  checkImagesIsUploaded = async () => {
    if (!this.isUploaded) {
      const roomImages = await longPolling<InspectionRoomPhotoModel>(
        () =>
          ApartmentInspectionAgent.getRoomImagesGroup(
            this.apartmentId,
            this.inspectionId,
            this.id
          ),
        (error) => this.loader.setError(error),
        (response) => response.uploaded,
        2000
      )

      if (roomImages) this.updateImage(roomImages)
    }
  }

  updateImage = (roomImages: InspectionRoomPhotoModel) => {
    this.isUploaded = true
    this.wasUploaded = true
    this.images = roomImages.photos.map((image) =>
      ImageStore.initFromImageModel(image)
    )
  }
}

export default RoomImagesStore
