import { ObjectType } from "kui-crm"

const setNestedNameValue = (
  fieldName: string,
  value: any,
  data: ObjectType
) => {
  const keys = fieldName.split(".")

  const obj: ObjectType = {}
  let currentObj = obj
  let currentData = data

  for (let i = 0; i < keys.length - 1; i += 1) {
    const key = keys[i]

    currentObj[key] = currentData[key] || {}
    currentObj = currentObj[key]
    currentData = currentData[key]
  }

  currentObj[keys[keys.length - 1]] = value

  return obj
}

export default setNestedNameValue
