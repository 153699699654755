import { makeAutoObservable, runInAction } from "mobx"
import { Loader, Paginator, resHandler } from "kui-utils"
import to from "await-to-js"
import DashboardPageStore from "../DashboardPageStore"
import DashboardAgent from "../../../../agent/Dashboard"
import ApartmentsStore from "../../../../store/lites/ApartmentsStore"
import { NewObjectModel } from "../../types/api/apartments"
import { NewObjectParams } from "../../types/cards/apartments"

class NewObjectsListStore {
  value: number | null

  objects: NewObjectParams[]

  paginator: Paginator

  loader: Loader

  dashboardStore: DashboardPageStore

  constructor(dashboardStore: DashboardPageStore) {
    this.value = null
    this.objects = []
    this.paginator = new Paginator(5)
    this.loader = new Loader()
    this.dashboardStore = dashboardStore
    makeAutoObservable(this)
  }

  fetchObjects = async () => {
    this.loader.startLoading()

    const response = await to(
      DashboardAgent.getNewObjects(
        this.paginator.limit,
        this.paginator.offset,
        this.dashboardStore.periodFilter
      )
    )

    runInAction(() => {
      resHandler(response, this.loader, (res) => {
        this.value = res.count
        this.objects = this.paginator.getPageResponse(
          res,
          this.objects,
          NewObjectsListStore.getObjectParams
        )
      })
    })
  }

  static getObjectParams = (object: NewObjectModel) => ({
    id: object.id,
    apartment: ApartmentsStore.getApartmentLinkParams(
      object.contract.apartment
    ),
    contract: {
      id: object.contract.id,
      number: object.contract.number,
    },
    rentPrice: Number(object.month_payment),
  })
}

export default NewObjectsListStore
