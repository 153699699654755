import React from "react"
import { Caption, Grid } from "kui-basic"
import _ from "lodash"
import styled from "@emotion/styled"
import { Link } from "kui-crm"
import { ClientContractRowProps } from "./types"
import { Routes } from "../../../../types/route"

const ClientContractRow = ({ contract }: ClientContractRowProps) => {
  const contractsLink =
    contract?.role === "tenant"
      ? Routes.rental_contracts
      : Routes.service_contracts

  return (
    <StyledRow container wrap="nowrap" alignItems="center">
      <StyledRole weight={500} size="s">
        {_.capitalize(contract.role)}
      </StyledRole>
      <StyledAddress>
        {contract.apartment?.id ? (
          <Link href={`${Routes.apartments}/${contract.apartment.id}`}>
            <Caption weight={600} size="s">
              {contract.apartment.address}
            </Caption>
          </Link>
        ) : (
          "-"
        )}
      </StyledAddress>

      <StyledContract href={`${contractsLink}/${contract.id}`}>
        <Caption weight={500} size="s">
          {contract.number}
        </Caption>
      </StyledContract>
    </StyledRow>
  )
}

export default ClientContractRow

const StyledRow = styled(Grid)`
  padding: 8px;
  min-height: 56px;
  background: ${({ theme }) => theme.palette.background.light2};
  transition: all ease-out 0.3s;
  margin-bottom: 2px;
  &:first-child {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
  &:last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    margin-bottom: 0;
  }
  &:hover {
    background: ${({ theme }) => theme.palette.background.light3};
  }
`

const StyledRole = styled(Caption)`
  min-width: 23%;
  max-width: 23%;
`

const StyledAddress = styled.div`
  min-width: 52%;
  max-width: 52%;
`

const StyledContract = styled(Link)`
  padding-right: 8px;
`
