import React from "react"
import { useForm } from "react-hook-form"
import { Box, Grid } from "kui-basic"
import { FormWrapper, InputByType } from "kui-crm"
import { observer } from "mobx-react"
import { yupResolver } from "@hookform/resolvers/yup"
import { ClosePeriodFormProps } from "./types"
import getClosePeriodFormSchema from "./schema"
import useExpensesStore from "../../../store"
import getClosingPeriodFields from "./fields"

const ClosePeriodForm = ({ handleSubmit }: ClosePeriodFormProps) => {
  const { periodsStore, summaryStore } = useExpensesStore()
  const { selectedPeriod } = periodsStore
  const { withoutLandlordPayOut, withoutTenantPayIn } =
    summaryStore.paymentsStore
  const schema = getClosePeriodFormSchema(
    !!selectedPeriod?.isLastMonthOfContract,
    withoutLandlordPayOut,
    withoutTenantPayIn,
    !!selectedPeriod?.rentalContract
  )
  const form = useForm({
    resolver: yupResolver(schema),
  })
  const fields = getClosingPeriodFields(
    withoutLandlordPayOut,
    withoutTenantPayIn,
    !!selectedPeriod?.isLastMonthOfContract,
    !!selectedPeriod?.rentalContract
  )

  return (
    <FormWrapper form={form} onSubmit={handleSubmit} label="Confirm">
      <Box pt={2}>
        <Grid container spacing={2}>
          {fields.map((field) => (
            <Grid item xs={12} key={field.name}>
              <InputByType form={form} {...field} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </FormWrapper>
  )
}

export default observer(ClosePeriodForm)
