import { rest } from "msw"
import { DateTime } from "luxon"
import { ServicesConfirmationStatus } from "../../../pages/ApartmentPage/pages/ExpensesPage/types/api/expensesServicesAPI"
import {
  confirmedStatusServices,
  documentMock,
  serviceFixedMock,
  servicesResponseMock,
  serviceUtilityMock,
  serviceVariableTenantMock,
} from "./data"
import {
  BillLineDocumentModel,
  PostDocumentRequest,
} from "../../../types/common"
import {
  PatchServiceRequest,
  PostServiceRequest,
  ServiceModel,
  ServicesResponse,
} from "../../../types/api/apartmentExpenses"

const apartmentServicesHandlers = [
  rest.get<ServicesResponse | Error>(
    `${process.env.REACT_APP_API_URL}/apartments/:id/expenses/bill-periods/:period_id/services`,
    (req, resp, ctx) => {
      const data = ctx.json(servicesResponseMock)
      return resp(data)
    }
  ),
  rest.get<ServicesResponse | Error>(
    `${process.env.REACT_APP_API_URL}/apartments/:id/expenses/bill-periods/pending-services`,
    (req, resp, ctx) => {
      const data = ctx.json([serviceVariableTenantMock])
      return resp(data)
    }
  ),
  rest.post<PostServiceRequest, ServiceModel | Error>(
    `${process.env.REACT_APP_API_URL}/apartments/:id/expenses/services`,
    (req, resp, ctx) => {
      if (req.body.period_info.duration_type === "open-ended") {
        const data = ctx.json({
          ...serviceUtilityMock,
          ...req.body,
          company: serviceUtilityMock.company,
          invoice: null,
          payment_order: null,
        })
        return resp(data)
      }
      if (req.body.period_info.duration_type === "period") {
        const data = ctx.json({
          ...serviceFixedMock,
          ...req.body,
          company: serviceFixedMock.company,
          invoice: null,
          payment_order: null,
        })
        return resp(data)
      }
      const data = ctx.json({
        ...serviceVariableTenantMock,
        ...req.body,
        company: serviceVariableTenantMock.company,
        invoice: null,
        payment_order: null,
      })
      return resp(data)
    }
  ),
  rest.post<PostDocumentRequest, BillLineDocumentModel | Error>(
    `${process.env.REACT_APP_API_URL}/apartments/:id/expenses/services/:bill_line_id/documents`,
    (req, resp, ctx) => {
      const data = ctx.json(documentMock)
      return resp(data)
    }
  ),
  rest.patch<PatchServiceRequest, ServiceModel | Error>(
    `${process.env.REACT_APP_API_URL}/apartments/:id/expenses/services/:service_id`,
    (req, resp, ctx) => {
      const periodInfo = {
        ...req.body.period_info,
        charge_from:
          DateTime.fromFormat(
            req.body.period_info!.charge_from!,
            "MM.yyyy"
          ).toISODate() || "",
      }

      if (req.body.period_info.duration_type === "open-ended") {
        const data = ctx.json({
          ...serviceUtilityMock,
          ...req.body,
          period_info: periodInfo,
          company: serviceUtilityMock.company,
          invoice: null,
          payment_order: null,
        })
        return resp(data)
      }
      if (req.body.period_info.duration_type === "period") {
        const data = ctx.json({
          ...serviceFixedMock,
          ...req.body,
          period_info: periodInfo,
          company: serviceFixedMock.company,
          invoice: null,
          payment_order: null,
        })
        return resp(data)
      }
      const data = ctx.json({
        ...serviceVariableTenantMock,
        ...req.body,
        period_info: periodInfo,
        company: serviceVariableTenantMock.company,
        invoice: null,
        payment_order: null,
      })

      return resp(data)
    }
  ),
  rest.delete<ServiceModel | Error>(
    `${process.env.REACT_APP_API_URL}/apartments/:id/expenses/services/:service_id`,
    (req, resp, ctx) => {
      const data = ctx.json(serviceUtilityMock)
      return resp(data)
    }
  ),
  rest.post<ServicesConfirmationStatus | Error>(
    `${process.env.REACT_APP_API_URL}/apartments/:id/expenses/bill-periods/:period_id/services/approval`,
    (req, resp, ctx) => {
      const data = ctx.json(confirmedStatusServices)
      return resp(data)
    }
  ),
]

export default apartmentServicesHandlers
