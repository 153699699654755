import React from "react"
import { Grid } from "kui-basic"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import getPaymentSummaryParts from "./content"
import ExpensesPaymentSummaryCell from "../ExpensesPaymentSummaryCell"
import { ExpensesPaymentSummaryProps, SummaryWrapperStylesProps } from "./types"

function ExpensesPaymentSummary(props: ExpensesPaymentSummaryProps) {
  const { summaryStore, variant } = props
  const cells = getPaymentSummaryParts(summaryStore)
  const align = variant === "left" ? "flex-start" : "flex-end"

  return (
    <StyledWrapper variant={variant} container alignItems={align}>
      <StyledCard item xs={12}>
        <Grid container spacing={3} justify={align}>
          {cells.map((item) => (
            <ExpensesPaymentSummaryCell
              key={item.name}
              valueTestId={item.name}
              {...item}
            />
          ))}
        </Grid>
      </StyledCard>
    </StyledWrapper>
  )
}

export default observer(ExpensesPaymentSummary)

const StyledWrapper = styled(Grid)<SummaryWrapperStylesProps>`
  padding: ${({ variant }) =>
    variant === "left" ? "0 12px 0 0" : "0 0 0 12px"};
`

const StyledCard = styled(Grid)`
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
  background: ${({ theme }) => theme.palette.background.light1};
  padding: 8px 12px;
`
