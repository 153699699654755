import { ApartmentLinkModel } from "kui-crm/types"

class ApartmentSimpleStore {
  id: number

  address: string

  mainImageURL: string | null

  constructor(apartment: ApartmentLinkModel) {
    this.id = apartment.id
    this.address = apartment.object_name || apartment.address
    this.mainImageURL = apartment.main_image?.image_small_url || null
  }
}

export default ApartmentSimpleStore
