import React from "react"
import { Grid, Caption, Button } from "kui-basic"
import styled from "@emotion/styled"
import { PlusIcon } from "kui-icon"
import {
  fieldsGroupTitleVariantsProps,
  FieldsGroupTitleWithButtonProps,
} from "./types"

const fieldsGroupTitleVariantsParams: fieldsGroupTitleVariantsProps = {
  black: {
    size: "xs",
    weight: 600,
  },
  grey: {
    weight: 700,
    color: "thirty",
  },
}

function FieldsGroupTitleWithButton(props: FieldsGroupTitleWithButtonProps) {
  const { title, icon, variant, ...buttonProps } = props

  return (
    <StyledWrapper container justify="space-between" alignItems="center">
      <Caption {...fieldsGroupTitleVariantsParams[variant!]}>
        {title?.toUpperCase()}
      </Caption>
      <Button isCircle variant="whiteWithGray" size="xs" {...buttonProps}>
        {icon || <PlusIcon width={10} height={10} />}
      </Button>
    </StyledWrapper>
  )
}

FieldsGroupTitleWithButton.defaultProps = {
  variant: "black",
}

export default FieldsGroupTitleWithButton

const StyledWrapper = styled(Grid)`
  padding: 12px 0;
`
