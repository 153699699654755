import React from "react"
import { Caption, Grid } from "kui-basic"
import styled from "@emotion/styled"
import { ApartmentDocumentStatusProps, StatusStylesProps } from "./types"
import { statusesStyles } from "./content"

const ApartmentDocumentStatus = ({ status }: ApartmentDocumentStatusProps) => {
  const statusParams = statusesStyles[status]

  return (
    <Grid container alignItems="center">
      <StyledPoint color={statusParams.color} />
      <Caption weight={500}>{statusParams.label}</Caption>
    </Grid>
  )
}

export default ApartmentDocumentStatus

const StyledPoint = styled.div<StatusStylesProps>`
  background: ${({ color }) => color};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 6px;
`
