import { ApartmentPayoutsParams } from "../../../../../../types/store/apartment"
import UserLiteStore from "../../../../../../../../store/templates/UserLite"

const emptyInvoiceOption = {
  label: "No invoices found",
  value: 0,
}

const getInvoiceOptions = (payouts: ApartmentPayoutsParams[]) => {
  const payoutsOptions = payouts.map((payout) => ({
    label: payout.number,
    value: payout.id,
  }))
  const invoiceOptions = payoutsOptions.length
    ? payoutsOptions
    : [emptyInvoiceOption]

  return {
    invoiceOptions,
    payoutsOptions,
  }
}

const getPayerOptions = (
  tenant: UserLiteStore | null,
  landlord: UserLiteStore | null
) => [
  ...(tenant ? [{ label: tenant?.fullName, value: tenant.id }] : []),
  { label: landlord?.fullName, value: landlord?.id },
]

export { getInvoiceOptions, getPayerOptions }
