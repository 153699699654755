import * as yup from "yup"

const SCApartmentFormSchema = yup.object().shape({
  apartment: yup
    .object({
      address: yup.string().required("This field is required"),
    })
    .when("hasInventoryInspection", {
      is: (value?: boolean) => value === false,
      then: (rule) =>
        rule.shape({
          address: yup
            .string()
            .max(0, "The object must have an inventory inspection"),
        }),
    })
    .when("hasInitialAppraisal", {
      is: (value?: boolean) => value === false,
      then: (rule) =>
        rule.shape({
          address: yup.string().max(0, "The object must have an appraisal"),
        }),
    }),
  hasInitialAppraisal: yup.boolean().isTrue(),
  hasInventoryInspection: yup.boolean().isTrue(),
})

export default SCApartmentFormSchema
