import React from "react"
import { observer } from "mobx-react"
import { Grid } from "kui-basic"
import styled from "@emotion/styled"
import CheckButton from "../../../../../components/common/CheckButton"
import useApartmentStore from "../../../store"
import ApartmentEditButton from "../ApartmentEditButton"
import { ApartmentTabsSettingsProps } from "./types"
import useQueryParam from "../../../../../hooks/useQueryParam"

const pathsWithEditing = [0, 1, 3, 5]

const ApartmentTabsSettings = ({ activeTab }: ApartmentTabsSettingsProps) => {
  const { checkServices } = useApartmentStore()
  const withEntity = Number(useQueryParam("id"))
  const showButton =
    pathsWithEditing.some((index) => index === Number(activeTab)) || withEntity

  return (
    <StyledGrid container alignItems="center">
      {activeTab === 1 && (
        <CheckButton
          services={checkServices}
          dataPopupLabel="To check object you need to fill:"
        />
      )}
      {!!showButton && <ApartmentEditButton />}
    </StyledGrid>
  )
}

export default observer(ApartmentTabsSettings)

const StyledGrid = styled(Grid)`
  width: auto;
`
