import * as React from "react"
import styled from "@emotion/styled"
import { ArrowBackIcon, ArrowNextIcon } from "kui-icon"
import { Caption, Grid, IconButton } from "kui-basic"
import { ForwardedRef, forwardRef } from "react"
import { ReactDatePickerCustomHeaderProps } from "react-datepicker"

const YearPickerHeader = forwardRef(
  (
    {
      decreaseYear,
      increaseYear,
      nextYearButtonDisabled,
      prevYearButtonDisabled,
      date,
    }: ReactDatePickerCustomHeaderProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => (
    <StyledHeader
      container
      alignItems="center"
      justify="space-between"
      ref={ref}
    >
      <Grid item>
        <StyledIconButton
          disabled={prevYearButtonDisabled}
          onClick={decreaseYear}
          type="button"
        >
          <ArrowBackIcon width={14} height={12} />
        </StyledIconButton>
      </Grid>
      <Grid item>
        <Caption size="sm" weight={600}>
          Years
        </Caption>
      </Grid>
      <Grid item>
        <StyledIconButton
          disabled={nextYearButtonDisabled}
          onClick={increaseYear}
          type="button"
        >
          <ArrowNextIcon width={14} height={12} />
        </StyledIconButton>
      </Grid>
    </StyledHeader>
  )
)

export default YearPickerHeader

const StyledIconButton = styled(IconButton)`
  &:disabled {
    opacity: 0;
  }
`

const StyledHeader = styled(Grid)`
  background: #fff;
  padding: 8px 16px 4px;
  border-radius: 12px 12px 0 0;
`
