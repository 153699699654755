import { ResourceTypes } from "kui-crm"

const getCellsByResource = (resource: ResourceTypes | null) => {
  const withType = resource === "water" || resource === "electricity"

  if (withType) {
    return [
      { id: "type", label: "Type", width: "9%" },
      { id: "tariff_group", label: "Tariff groups", width: "18%" },
    ]
  }
  return [{ id: "tariff_group", label: "Tariff groups", width: "27%" }]
}

const metersHeadCellsParams = (resource: ResourceTypes) => [
  { id: "id", label: "ID", width: "6%" },
  { id: "number", label: "Number", width: "11%" },
  ...getCellsByResource(resource),
  { id: "object", label: "Object", width: "36%" },
  { id: "date_from", label: "Activation date", width: "11%" },
  { id: "next_check", label: "Next check", width: "10%" },
]

export default metersHeadCellsParams
