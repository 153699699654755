import { Container as ContainerKUI } from "kui-basic"
import styled from "@emotion/styled"

const Container = styled(ContainerKUI)`
  @media (min-width: 1344px) {
    max-width: 1344px;
  }
`

export default Container
