import React from "react"
import styled from "@emotion/styled"
import { InputDropdown } from "kui-complex"
import { CheckDropdownProps } from "./types"
import CheckServiceOption from "../CheckServiceOption"

const CheckServicesDropdown = (props: CheckDropdownProps) => {
  const { services, dataPopupLabel, ...other } = props

  return (
    <StyledInputDropdown {...other}>
      {services.map((service) => (
        <CheckServiceOption
          key={service.name}
          service={service}
          dataPopupLabel={dataPopupLabel}
        />
      ))}
    </StyledInputDropdown>
  )
}

export default CheckServicesDropdown

const StyledInputDropdown = styled(InputDropdown)`
  width: fit-content;
  > .KUI-Popper {
    padding-top: 8px;
    width: 240px !important;
    > div {
      padding: 0 8px;
      border-radius: 8px;
    }
  }
`
