import { theme } from "kui-basic"

export const appraisalStatusLabels = {
  rejected: "Rejected",
  signed: "Accepted",
  notSigned: "Not sign",
}

export const appraisalStatusColors = {
  rejected: {
    color: theme.palette.red.fiftyP,
    background: theme.palette.red.five,
  },
  signed: {
    color: theme.palette.green.fiftyP,
    background: theme.palette.green.five,
  },
  notSigned: {
    color: theme.palette.grey.fiftyP,
    background: theme.palette.background.light1,
  },
}
