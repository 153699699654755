import * as React from "react"
import { observer } from "mobx-react"
import { Modal, ModalProps, MeterFeaturesFormFields } from "kui-crm"
import EditSingleMeterForm from "../../../../forms/meters/EditSingleMeterForm"
import useExpensesStore from "../../../../store"

function EditWaterMeterModal({ open, handleClose }: ModalProps) {
  const { metersStore } = useExpensesStore()
  const waterMeter = metersStore.waterMetersStore.selectedMeterCounter

  const handleSubmit = async (data: MeterFeaturesFormFields) => {
    if (waterMeter?.id) {
      handleClose()
      await waterMeter.patchCounter(data)
    }
  }

  return (
    <Modal title="Edit water meter" open={open} handleClose={handleClose}>
      <EditSingleMeterForm
        handleSubmit={handleSubmit}
        meter={waterMeter}
        resource="water"
      />
    </Modal>
  )
}

export default observer(EditWaterMeterModal)
