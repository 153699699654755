import * as React from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import {
  BodyCellStylesProps,
  CellContentStylesProps,
  CellSettingsStylesProps,
  HeadCellStylesProps,
  LastCellStylesProps,
  TableCellProps,
} from "./types"

function TableCell({ children, ...props }: TableCellProps) {
  const {
    type,
    isLast,
    isFirst,
    textColor,
    align,
    onClick,
    parentWidth,
    ...other
  } = props

  return (
    <StyledTd
      type={type!}
      isLast={!!isLast}
      isFirst={!!isFirst}
      pointer={!!onClick}
      align={align!}
      role="gridcell"
      onClick={onClick}
      parentWidth={parentWidth}
      {...other}
    >
      <StyledContent textColor={textColor} align={align}>
        {children}
      </StyledContent>
    </StyledTd>
  )
}

TableCell.defaultProps = {
  isLast: false,
  isFirst: false,
  type: "body" as const,
  align: "left" as const,
}

export default TableCell

const bodyCSS = ({ type }: BodyCellStylesProps) =>
  type === "body" &&
  css`
    border: none;
    font-size: 14px;
  `

const headCSS = ({ theme, type }: HeadCellStylesProps) =>
  type === "head" &&
  css`
    font-size: 12px;
    border-bottom: 1px solid ${theme.palette.background.light2};
    border-top: 1px solid ${theme.palette.background.light2};
  `

const lastCellCSS = ({ isLast }: LastCellStylesProps) =>
  isLast &&
  css`
    padding-right: 36px;
    border-radius: 0 8px 8px 0;
  `

const firstCellCSS = ({ isFirst }: CellSettingsStylesProps) =>
  isFirst &&
  css`
    padding-left: 24px;
    border-radius: 8px 0 0 8px;
  `

const tdSettingsCSS = (props: CellSettingsStylesProps) => css`
  cursor: ${props.pointer ? "pointer" : "auto"};
  text-align: ${props.align};
  width: ${props.width ? props.width : "auto"};
  max-width: ${props.width
    ? `calc(${props.parentWidth || 1344}px * ${
        parseInt(props.width, 10) / 100
      })`
    : "unset"};
`

const withColorCSS = ({ theme, textColor }: CellContentStylesProps) =>
  textColor &&
  css`
    &,
    p {
      color: ${textColor === "black"
        ? theme!.palette.grey.seventy
        : theme!.palette.grey.fiftyP};
    }
  `

const StyledContent = styled.div<CellContentStylesProps>`
  min-height: 32px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: ${({ align }) => align};
  color: ${({ theme, textColor }) =>
    textColor === "gray"
      ? theme!.palette.grey.fiftyP
      : theme!.palette.grey.seventy};
  ${withColorCSS};
`

const StyledTd = styled.td<CellSettingsStylesProps>`
  padding: 12px 0 12px 22px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  letter-spacing: normal;
  ${bodyCSS};
  ${headCSS};
  ${tdSettingsCSS};
  ${firstCellCSS};
  ${lastCellCSS};
`
