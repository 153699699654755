import React from "react"
import { observer } from "mobx-react"
import MenuPanel from "../../../../../../../components/ui/MenuPanel"
import useTabIndex from "../../../../../../../hooks/useTabIndex"
import PromotionContentTab from "../../tabs/PromotionContentTab"
import PromotionChannelsTab from "../../tabs/PromotionChannelsTab"
import PromotionPredictorTab from "../../tabs/PromotionPredictorTab"

const tabs = ["Content", "Channels", "Prediction"]

const paths = [
  `?tab=promotion&subtab=content`,
  `?tab=promotion&subtab=channels`,
  `?tab=promotion&subtab=prediction`,
]

const tabPanels = [
  <PromotionContentTab />,
  <PromotionChannelsTab />,
  <PromotionPredictorTab />,
]

function PromotionTabsPanel() {
  const activeTab = useTabIndex(paths, "subtab")

  return (
    <MenuPanel
      activeTab={Number(activeTab)}
      paths={paths}
      tabs={tabs}
      tabPanels={tabPanels}
      label="Promotion"
    />
  )
}

export default observer(PromotionTabsPanel)
