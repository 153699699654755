import React from "react"
import styled from "@emotion/styled"
import { useWatch } from "react-hook-form"
import { CloseIcon } from "kui-icon"
import { UserWithDeleteFieldProps } from "./types"
import UserAutocomplete from "../../entityInputs/ClientAutocomplete"

function UserWithDeleteField<T extends object>(
  props: UserWithDeleteFieldProps<T>
) {
  const { index, remove, form, name, label, ...inputProps } = props
  const value = useWatch({
    control: form.control,
    name,
  })

  const handleDelete = () => {
    remove(index)
  }

  return (
    <UserAutocomplete
      label={!value?.name && label}
      endIcon={
        value?.name && remove && <StyledCrossIcon onClick={handleDelete} />
      }
      form={form}
      name={name}
      {...inputProps}
    />
  )
}

export default UserWithDeleteField

const StyledCrossIcon = styled(CloseIcon)`
  cursor: pointer;
  margin-right: -6px;
  width: 22px;
  height: 22px;
  path {
    fill: ${({ theme }) => theme.palette.grey.fiftyP};
  }
`
