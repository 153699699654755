import React, { TdHTMLAttributes } from "react"
import styled from "@emotion/styled"
import { Divider } from "kui-basic"

function TableRowDivider(props: TdHTMLAttributes<HTMLTableDataCellElement>) {
  return (
    <tr>
      <StyledTd colSpan={11} {...props}>
        <Divider />
      </StyledTd>
    </tr>
  )
}

export default TableRowDivider

const StyledTd = styled.td`
  padding: 0 4px 0 27px;
  width: 100%;
`
