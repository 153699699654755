import requests from "./index"
import {
  PatchServiceContractRequest,
  CloseServiceContractRequest,
  PlannedEndDateModel,
  PatchServiceContractParams,
} from "../types/api/service_contracts"
import { ActivateContractRequest } from "../types/api/contracts"

const ServiceContractAgent = {
  getById: (id: number) => requests.get(`/service-contracts/${id}`),
  patch: (id: number, data: PatchServiceContractParams) =>
    requests.patch(`/service-contracts/${id}`, data),
  createPendingChange: (id: number, data: PatchServiceContractRequest) =>
    requests.post(`/service-contracts/${id}/changes`, data),
  delete: (id: number) => requests.delete(`/service-contracts/${id}`),
  getDocuments: (id: number) =>
    requests.get(`/service-contracts/${id}/documents`),
  postDocument: (data: FormData, id: number) =>
    requests.post(`/service-contracts/${id}/documents`, data),
  patchDocument: (documentId: number, data: FormData, id: number) =>
    requests.patch(`/service-contracts/${id}/documents/${documentId}`, data),
  deleteDocument: (documentId: number, id: number) =>
    requests.delete(`/service-contracts/${id}/documents/${documentId}`),
  close: (id: number, data: CloseServiceContractRequest) =>
    requests.post(`/service-contracts/${id}/close`, data),
  saveTentativeCloseDate: (id: number, data: PlannedEndDateModel) =>
    requests.post(`/service-contracts/${id}/plan_end`, data),
  activateContract: (id: number, data: ActivateContractRequest) =>
    requests.post(`/service-contracts/${id}/activate`, data),
}

const ServiceContractChangesAgent = {
  getChanges: (id: number, isApplied?: boolean) =>
    requests.get(`/service-contracts/${id}/changes?applied=${!!isApplied}`),
  deleteChange: (scId: number, id: number) =>
    requests.delete(`/service-contracts/${scId}/changes/${id}`),
  editChange: (scId: number, id: number, data: PatchServiceContractRequest) =>
    requests.put(`/service-contracts/${scId}/changes/${id}`, data),
}

export default ServiceContractAgent
export { ServiceContractChangesAgent }
