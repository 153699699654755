const scHeadCellsParams = [
  { id: "id", label: "ID", width: "4%" },
  { id: "number", label: "Number", width: "12%" },
  { id: "address", label: "Object", width: "22%" },
  { id: "rental_status", label: "Status", width: "6%" },
  { id: "rental_type", label: "Type", width: "7%" },
  { id: "renter_payment_status", label: "Paid", width: "5%" },
  { id: "next_inspection", label: "Inspection", width: "7%" },
  { id: "day_of_payment", label: "Payday", width: "6%" },
  { id: "owner", label: "Landlord", width: "16%" },
  { id: "renter", label: "Tenant", width: "16%" },
]

export default scHeadCellsParams
