import * as yup from "yup"
import { isValidWithMaskExp } from "../../../../utils/content/regexp"

const CompanyOfficeSchema = yup.object({
  office: yup.object({
    address: yup.string().required("Enter the office address"),
  }),
  email: yup
    .string()
    .email("Enter the valid email")
    .required("Enter the office email"),
  zipCode: yup
    .string()
    .matches(isValidWithMaskExp, "Enter correct the company office zip code")
    .required("Enter the company office zip code"),
  phone: yup.object({
    phoneNumber: yup
      .string()
      .matches(isValidWithMaskExp, "Enter correct the company phone")
      .required("Enter the company phone"),
  }),
})

const CompanyOfficesFormSchema = yup.object().shape({
  mainOffice: CompanyOfficeSchema,
  offices: yup.array().of(CompanyOfficeSchema),
})

export default CompanyOfficesFormSchema
