export const serviceLabels = {
  openEnded: "Open-ended",
  period: "Period",
  once: "Once",
  own: "Own",
  auto: "Auto",
}

export const tableHeadCells = [
  { label: "Purpose", width: "7%" },
  { label: "Company", width: "12%" },
  { label: "Payment made by", width: "14%" },
  { label: "Refund from", width: "14%" },
  { label: "Payment", width: "7%" },
  { label: "Price (₽)", width: "11%" },
  { label: "Number", width: "9%" },
  { label: "Document", width: "6%" },
]

export const serviceTableCellsWidth = [
  "16%",
  ...tableHeadCells.map((cell) => cell.width),
  "4%",
]
