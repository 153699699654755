import React from "react"
import { useForm } from "react-hook-form"
import { Grid } from "kui-basic"
import { Modal, FormWrapper, InputByType } from "kui-crm"
import { StatisticalValuationModalProps } from "./types"
import statisticalValuationFields from "../../../../../../../components/forms/apartment/InspectionCreationForm/variations/AppraisalInspectionForm/StatisticalValuationStep/fields"
import { StatisticalValuationFormFields } from "../../../../../../../components/forms/apartment/InspectionCreationForm/variations/AppraisalInspectionForm/StatisticalValuationStep/types"

const StatisticalValuationModal = (props: StatisticalValuationModalProps) => {
  const { handleSubmit, ...other } = props
  const form = useForm<StatisticalValuationFormFields>()

  return (
    <Modal title="Add statistical valuation" {...other}>
      <FormWrapper form={form} onSubmit={form.handleSubmit(handleSubmit)}>
        <Grid container spacing={2}>
          {statisticalValuationFields.map((field) => (
            <Grid item xs={12} key={field.name}>
              <InputByType form={form} {...field} />
            </Grid>
          ))}
        </Grid>
      </FormWrapper>
    </Modal>
  )
}

export default StatisticalValuationModal
