import { DateTime } from "luxon"
import { makeAutoObservable } from "mobx"
import { CompanyObjectModel } from "../types/api/companyAPI"
import FileStore from "../../../store/templates/File"
import { ChangeReasonParams } from "../../../types/common"

class CompanyObjectStore {
  id: number

  logo: string | null

  address: string

  nextCheck: DateTime | null

  reason: ChangeReasonParams | null

  isSelected: boolean

  constructor(object: CompanyObjectModel) {
    this.id = object.apartment.id
    this.isSelected = false
    this.logo = object.apartment.main_image
      ? object.apartment.main_image?.image_small_url ??
        object.apartment.main_image.image_url
      : null
    this.address = object.apartment.object_name || object.apartment.address
    this.nextCheck = object.next_date
      ? DateTime.fromISO(object.next_date)
      : null
    this.reason = object.reason_document
      ? {
          comment: object.comment,
          file: FileStore.initFromDocumentModel(object.reason_document),
        }
      : null
    makeAutoObservable(this)
  }

  selectObject = () => {
    this.isSelected = true
  }

  unSelectObject = () => {
    this.isSelected = false
  }
}

export default CompanyObjectStore
