import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { Loader } from "kui-utils"
import { ResourceTypes } from "kui-crm"
import CompanyTariffStore from "./CompanyTariffStore"
import CompanyAgent from "../../../agent/Company"
import { GetCompanyTariffsResponse } from "../types/api/companyAPI"

class CompanyTariffsStore {
  loader: Loader

  actionLoader: Loader

  waterTariffs: CompanyTariffStore[]

  electricityTariffs: CompanyTariffStore[]

  gasTariffs: CompanyTariffStore[]

  heatingTariffs: CompanyTariffStore[]

  constructor() {
    this.waterTariffs = []
    this.electricityTariffs = []
    this.gasTariffs = []
    this.heatingTariffs = []
    this.loader = new Loader()
    this.actionLoader = new Loader()
    makeAutoObservable(this)
  }

  fetchTariffs = async (companyId: number) => {
    this.loader.startLoading()

    const [err, res] = await to<GetCompanyTariffsResponse>(
      CompanyAgent.getCompanyTariffs(companyId)
    )
    runInAction(() => {
      if (!err && res) {
        this.updateCompanyTariffs(res)
      } else {
        this.loader.setError("fetch tariffs", err)
      }
      this.loader.endLoading()
    })
  }

  updateCompanyTariffs = (res: GetCompanyTariffsResponse) => {
    this.waterTariffs = res.water_tariffs.map(
      (tariff) => new CompanyTariffStore(tariff, "water", this)
    )
    this.electricityTariffs = res.electricity_tariffs.map(
      (tariff) => new CompanyTariffStore(tariff, "electricity", this)
    )
    this.gasTariffs = res.gas_tariffs.map(
      (tariff) => new CompanyTariffStore(tariff, "gas", this)
    )
    this.heatingTariffs = res.heating_tariffs.map(
      (tariff) => new CompanyTariffStore(tariff, "heating", this)
    )
  }

  removeTariffFromList = (id: number, resource: ResourceTypes) => {
    const tariffs = `${resource}Tariffs` as keyof CompanyTariffsStore
    ;(this[tariffs] as CompanyTariffStore[]) = (
      this[tariffs] as CompanyTariffStore[]
    ).filter((tariff) => tariff.id !== id)
  }

  get activeWaterTariffs() {
    return this.waterTariffs.filter((tariff) => tariff.status !== "closed")
  }

  get activeGasTariffs() {
    return this.gasTariffs.filter((tariff) => tariff.status !== "closed")
  }

  get activeHeatingTariffs() {
    return this.heatingTariffs.filter((tariff) => tariff.status !== "closed")
  }

  get activeElectricityTariffs() {
    return this.electricityTariffs.filter(
      (tariff) => tariff.status !== "closed"
    )
  }

  get archiveTariffs() {
    return [
      ...this.waterTariffs.filter((tariff) => tariff.status === "closed"),
      ...this.electricityTariffs.filter((tariff) => tariff.status === "closed"),
      ...this.gasTariffs.filter((tariff) => tariff.status === "closed"),
      ...this.heatingTariffs.filter((tariff) => tariff.status === "closed"),
    ]
  }
}

export default CompanyTariffsStore
