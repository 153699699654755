import React, { useState } from "react"
import { observer } from "mobx-react"
import {
  InventoryInspectionFormFields,
  renderMetersSteps,
} from "kui-crm_actions"
import MultistepForm from "../../../../../common/MultistepForm"
import HouseServicesInfoStep from "./HouseServicesInfoStep"
import TelecommunicationsInfoStep from "./TelecommunicationsInfoStep"
import AdditionalInfoStep from "./AdditionalInfoStep"
import MetersInfoStep from "./MetersInfoStep"
import FillingInfoStep from "./FillingInfoStep"
import FillingImagesStep from "./FillingImagesStep"
import renderImagesSteps from "../../InspectionImagesStep/imagesSteps/renderSteps"
import { InspectionFormProps } from "../../../../../../pages/ApartmentPage/forms/inspections/InspectionCreationForm/types"
import MeterInfoStep from "../../InspectionMetersStep/FullMeterInfoStep"
import MeterPhotoStep from "../../InspectionMetersStep/MeterPhotoStep"
import useApartmentLiteStore from "../../../../../../store/shared/apartment"
import useResetMultistepForm from "../../../../../../hooks/useResetMultistepForm"

const InventoryInspectionForm = (props: InspectionFormProps) => {
  const { handleCreate, shift, handleClose } = props
  const { inspectionsStore, descriptionStore } = useApartmentLiteStore()
  const [fillingList, setFillingList] = useState([0])
  const formStore = inspectionsStore.creationForm
  const maxMeters = formStore.fields?.maxMeters

  const addFilling = () => {
    setFillingList((prev) => [...prev, prev.length])
    formStore.nextStep()
  }

  const removeFilling = () => {
    setFillingList((prev) => prev.slice(0, prev.length - 1))
  }

  const handleSubmit = async () => {
    handleClose()
    const inspectionId = await inspectionsStore.createInventoryInspection(
      formStore.fields as InventoryInspectionFormFields
    )
    handleCreate(inspectionId)
  }

  useResetMultistepForm(formStore)

  return (
    <MultistepForm stepShift={shift} form={formStore}>
      <HouseServicesInfoStep />
      <TelecommunicationsInfoStep />
      <AdditionalInfoStep />
      <MetersInfoStep />
      {renderMetersSteps(MeterInfoStep, MeterPhotoStep, maxMeters)}
      {fillingList.map((filling) => [
        <FillingInfoStep
          key={`info_${filling}`}
          index={filling}
          onPrev={removeFilling}
        />,
        <FillingImagesStep
          key={`images_${filling}`}
          index={filling}
          addStep={addFilling}
        />,
      ])}
      {renderImagesSteps("inventory", handleSubmit, descriptionStore.fields)}
    </MultistepForm>
  )
}

export default observer(InventoryInspectionForm)
