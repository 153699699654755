import React from "react"
import { ProxyRegistryRowProps } from "./types"
import TableRow from "../../../../../../components/ui/TableRow"
import TableCell from "../../../../../../components/ui/TableCell"
import ApartmentLinkCell from "../../../../../../components/common/ApartmentLinkCell"
import UserBadge from "../../../../../../components/common/UserBadge"
import ApartmentDocumentStatus from "../../../common/ApartmentDocumentStatus"

const ProxyRegistryRow = (props: ProxyRegistryRowProps) => {
  const { insurance, isDark } = props

  return (
    <TableRow isDark={isDark}>
      <TableCell isFirst textColor="gray">
        {insurance.id}
      </TableCell>
      <TableCell>{insurance.folder}</TableCell>
      <TableCell>
        <ApartmentLinkCell apartment={insurance.apartment} />
      </TableCell>
      <TableCell>
        {insurance.client && (
          <UserBadge
            user={insurance.client}
            name={insurance.client.partialName}
            weight={500}
          />
        )}
      </TableCell>
      <TableCell>{insurance.number ? `№ ${insurance.number}` : "-"}</TableCell>
      <TableCell>{insurance.endDate?.toFormat("dd.MM.yyyy") || "-"}</TableCell>
      <TableCell isLast>
        <ApartmentDocumentStatus status={insurance.status} />
      </TableCell>
    </TableRow>
  )
}

export default ProxyRegistryRow
