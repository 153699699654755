import React from "react"
import { observer } from "mobx-react"
import { Grid } from "kui-basic"
import userPassportFieldsGroup from "./fields"
import UpdatedFieldsGroup from "../../../../../components/common/UpdatedFieldsGroup"
import useClientStore from "../../../store"
import { ClientPassportFieldsProps } from "./types"

function ClientPassportFields({ form, editor }: ClientPassportFieldsProps) {
  const { editor: clientEditor } = useClientStore()

  return (
    <Grid container spacing={2}>
      <UpdatedFieldsGroup
        form={form}
        disabled={!clientEditor.isEditing}
        editor={editor}
        {...userPassportFieldsGroup}
      />
    </Grid>
  )
}

export default observer(ClientPassportFields)
