import React from "react"
import { ModalProps, Modal } from "kui-crm"
import useExpensesStore from "../../../../store"
import ClosePeriodForm from "../../../../forms/summary/ClosePeriodForm"
import { ClosePeriodFormFields } from "../../../../forms/summary/ClosePeriodForm/types"

function ClosePeriodModal(props: ModalProps) {
  const { open, handleClose } = props
  const { overviewStore, periodsStore } = useExpensesStore()
  const { selectedPeriod } = periodsStore

  const handleSubmit = async (data: ClosePeriodFormFields) => {
    if (overviewStore.id) {
      await selectedPeriod?.closePeriod(overviewStore.id, data)
    }
    handleClose()
  }

  return (
    <Modal
      data-testid="confirmation_modal"
      title="Close period"
      description="Adding confirmation of payment to the landlord"
      open={open}
      handleClose={handleClose}
    >
      <ClosePeriodForm handleSubmit={handleSubmit} />
    </Modal>
  )
}

export default ClosePeriodModal
