import React from "react"
import Page from "../Page"
import NoAccessComponent from "../NoAccessComponent"

const NoAccessPage = () => (
  <Page withContainer={false}>
    <NoAccessComponent />
  </Page>
)

export default NoAccessPage
