import React from "react"
import { Grid } from "kui-basic"
import promotionChannelsLinksFields from "./content"
import PropertyLinkField from "../PropertyLinkField"
import { PromotionChannelsLinksFieldsProps } from "./types"

const PromotionChannelsLinksFields = (
  props: PromotionChannelsLinksFieldsProps
) => {
  const { form, disabled } = props

  return (
    <Grid item xs={7}>
      <Grid container spacing={2}>
        {promotionChannelsLinksFields.map((field) => (
          <PropertyLinkField
            key={field.name}
            form={form}
            disabled={disabled}
            {...field}
          />
        ))}
      </Grid>
    </Grid>
  )
}

export default PromotionChannelsLinksFields
