import React, { useState } from "react"
import { observer } from "mobx-react"
import useTabIndex from "../../../../../hooks/useTabIndex"
import CompanyInfoTab from "../../tabs/CompanyInfoTab"
import CompanyTariffsTab from "../../tabs/CompanyTariffsTab"
import useCompanyStore from "../../../store"
import CompanyBankInfoTab from "../../tabs/CompanyBankInfoTab"
import CompanyObjectsTab from "../../tabs/CompanyObjectsTab"
import CompanyOfficesTab from "../../tabs/CompanyOfficesTab"
import PageTabsPanel from "../../../../../components/ui/PageTabsPanel"
import CompanyTabsButtons from "../CompanyTabsButtons"

function CompanyTabsPanel() {
  const { overviewStore, editor } = useCompanyStore()
  const withTariffs = overviewStore?.type === "administrative"

  const paths = [
    "?tab=info",
    "?tab=offices",
    withTariffs ? "?tab=tariffs" : null,
    "?tab=bank",
    "?tab=objects",
  ].filter((path) => !!path) as string[]

  const activeTab = useTabIndex(paths)
  const [currentTab, setCurrentTab] = useState(activeTab)

  const tabs = [
    "Info",
    "Offices",
    ...(withTariffs ? ["Tariff groups"] : []),
    "Banc acc",
    "Objects",
  ]

  const tabPanels = [
    <CompanyInfoTab />,
    <CompanyOfficesTab />,
    withTariffs ? <CompanyTariffsTab /> : null,
    <CompanyBankInfoTab />,
    <CompanyObjectsTab />,
  ].filter((tab) => !!tab)

  const handleTabChange = (e: any, tabIndex: number) => {
    setCurrentTab(tabIndex)
    editor.endEditing()
  }

  return (
    // eslint-disable-next-line react/jsx-no-undef
    <PageTabsPanel
      activeTab={Number(activeTab)}
      paths={paths}
      tabs={tabs}
      tabPanels={tabPanels}
      onChange={handleTabChange}
      endComponent={<CompanyTabsButtons activeTab={currentTab} />}
    />
  )
}

export default observer(CompanyTabsPanel)
