import React from "react"
import { observer } from "mobx-react"
import { ModalProps, Modal } from "kui-crm"
import CompanyOfficeCreationForm from "../../../../forms/CompanyOfficeCreationForm"
import useCompanyStore from "../../../../store"
import { CompanyOfficeCreationFields } from "../../../../forms/CompanyOfficeCreationForm/types"

function CompanyOfficeCreationModal({ open, handleClose }: ModalProps) {
  const { officesStore, overviewStore } = useCompanyStore()

  const handleSubmit = (data: CompanyOfficeCreationFields) => {
    if (overviewStore?.id) {
      officesStore?.createCompanyOffice(overviewStore.id, data)
    }
    handleClose()
  }

  return (
    <Modal title="New office" open={open} handleClose={handleClose}>
      <CompanyOfficeCreationForm handleSubmit={handleSubmit} />
    </Modal>
  )
}

export default observer(CompanyOfficeCreationModal)
