import {
  AnimalIcon,
  AreaIcon,
  BedIcon,
  ChildIcon,
  DoorIcon,
  DropIcon,
  LadderIcon,
  NoAnumalIcon,
  NoChildIcon,
  ElevatorIcon,
} from "kui-icon"
import * as React from "react"
import ApartmentDescriptionStore from "../../../../../../../store/description/ApartmentDescription"
import { PromotionFeatureParams } from "../PromotionFeature/types"

const getPromotionFeatures = (
  descriptionStore: ApartmentDescriptionStore
): PromotionFeatureParams[] => [
  {
    label: "room",
    icon: <DoorIcon />,
    hasPlural: true,
    value: descriptionStore.fields?.roomsNumber,
  },
  {
    label: "bedroom",
    icon: <BedIcon />,
    hasPlural: true,
    value: descriptionStore.fields?.bedroomsNumber,
  },
  {
    label: "bathroom",
    icon: <DropIcon />,
    hasPlural: true,
    value: descriptionStore.fields?.bathroomsNumber,
  },
  { label: "м²", icon: <AreaIcon />, value: descriptionStore.fields?.area },
  {
    label: "floors",
    icon: <LadderIcon />,
    value: `${descriptionStore.fields?.floor} / ${descriptionStore.fields?.floorCount}`,
  },
  {
    label: "elevator",
    icon: <ElevatorIcon />,
    hasPlural: true,
    value:
      Number(descriptionStore.fields?.passengerElevatorsCount) +
      Number(descriptionStore.fields?.serviceElevatorsCount),
  },
  {
    label: "With children",
    icon: <ChildIcon />,
    oppositeIcon: <NoChildIcon />,
    oppositeLabel: "Without children",
    value: descriptionStore.withChildren,
  },
  {
    label: "With pets",
    icon: <AnimalIcon />,
    oppositeIcon: <NoAnumalIcon />,
    oppositeLabel: "Without pets",
    value: descriptionStore.withPets,
  },
]

export default getPromotionFeatures
