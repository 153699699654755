import * as React from "react"
import styled from "@emotion/styled"
import { Caption, Grid } from "kui-basic"
import { CheckboxGroupWithTitleProps, CheckboxOptionType } from "./types"
import CheckboxWithController from "../CheckboxWithController"

const Wrapper = styled(Grid)`
  padding: 3px 16px;
  border: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
  border-radius: 66px;
  height: 40px;
`

function CheckboxGroupWithTitle<TFormValues extends object>({
  title,
  options,
  form,
  name,
  ...otherProps
}: CheckboxGroupWithTitleProps<TFormValues>) {
  return (
    <Wrapper container alignItems="center" justify="space-between">
      <Grid item>
        <Caption size="s" weight={500} color="fiftyP">
          {title}
        </Caption>
      </Grid>
      <Grid item>
        <Grid container>
          {options.map((option: CheckboxOptionType) => (
            <StyledCheckbox
              key={option.name}
              name={option.name}
              label={
                <Caption size="s" color="fiftyP" weight={500}>
                  {option.label}
                </Caption>
              }
              form={form as any}
              data-testid={option.name}
              {...otherProps}
            />
          ))}
        </Grid>
      </Grid>
    </Wrapper>
  )
}

export default CheckboxGroupWithTitle

const StyledCheckbox = styled(CheckboxWithController)`
  &:not(:last-child) {
    margin-right: 22px;
  }
`
