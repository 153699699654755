import React from "react"
import { Box } from "kui-basic"
import { observer } from "mobx-react"
import { LoaderState } from "kui-crm"
import PriceTerms from "./PriceTerms"
import PromotionChannelsForm from "../../../../forms/PromotionChannelsForm"
import usePromotionStore from "../../../../store"

const PromotionChannelsContent = () => {
  const { channelsStore } = usePromotionStore()

  return (
    <Box mt={3}>
      <PriceTerms />
      <PromotionChannelsForm />

      <LoaderState loader={channelsStore.contentStore.loader} />
    </Box>
  )
}

export default observer(PromotionChannelsContent)
