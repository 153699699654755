import { makeAutoObservable } from "mobx"
import { Loader, Paginator, resHandler, SortingFilter } from "kui-utils"
import to from "await-to-js"
import { EstateAgentParams } from "../../types/store/estateAgents"
import {
  EstateAgentModel,
  EstateAgentResponse,
} from "../../types/api/estateAgents"
import CatalogsAgent from "../../agent/Catalogs"

class EstateAgentsStore {
  estateAgents: EstateAgentParams[]

  loader: Loader

  filter: SortingFilter

  paginator: Paginator

  constructor() {
    this.estateAgents = []
    this.loader = new Loader()
    this.filter = new SortingFilter()
    this.paginator = new Paginator()
    makeAutoObservable(this)
  }

  fetchEstateAgents = async () => {
    this.loader.startLoading()

    const response = await to(
      CatalogsAgent.getEstateAgents(
        this.paginator.offset,
        this.paginator.limit,
        this.filter.filterParams
      )
    )

    resHandler(response, this.loader, this.updateEstateAgents)
  }

  updateEstateAgents = (res: EstateAgentResponse) => {
    const mapper = (agent: EstateAgentModel) => ({
      id: agent.id,
      name: agent.full_name,
    })

    this.estateAgents = this.paginator.getPageResponse<
      EstateAgentModel,
      EstateAgentParams
    >(res, this.estateAgents, mapper)
  }
}

export default EstateAgentsStore
