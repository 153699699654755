import React from "react"
import { useForm } from "react-hook-form"
import { Box, Grid } from "kui-basic"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormBottom, InputByType } from "kui-crm"
import { CompanyCreationFormProps, CompanyOfficeCreationFields } from "./types"
import CompanyOfficeCreationSchema from "./schema"
import companyOfficeFields from "./fields"
import useResetForm from "../../../../hooks/useResetForm"

function CompanyOfficeCreationForm({ handleSubmit }: CompanyCreationFormProps) {
  const form = useForm<CompanyOfficeCreationFields>({
    resolver: yupResolver(CompanyOfficeCreationSchema),
  })

  useResetForm(form)

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)}>
      <Box pr={5} pl={5} pb={3}>
        <Grid container spacing={2}>
          {companyOfficeFields.map(({ size, ...field }) => (
            <Grid item key={field.name} xs={size || 12}>
              <InputByType form={form} {...field} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <FormBottom label="Add" />
    </form>
  )
}

export default CompanyOfficeCreationForm
