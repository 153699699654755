import { createContext, useContext } from "react"
import hostStore from "../../../store"
import ApartmentPageStore from "./ApartmentPageStore"

const apartmentStore = new ApartmentPageStore(hostStore)
const ApartmentContext = createContext(apartmentStore)

const useApartmentStore = () => useContext(ApartmentContext)

export default useApartmentStore
export { apartmentStore, ApartmentContext }
