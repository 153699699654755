import React from "react"
import { Grid } from "kui-basic"
import { InputByType } from "kui-crm"
import { InputsModalGroupWithTitleProps } from "./types"
import FieldsGroupTitle from "../FieldsGroupTitle"

function InputsModalGroupWithTitle<T extends object>(
  props: InputsModalGroupWithTitleProps<T>
) {
  const { fields, form, title } = props

  return (
    <>
      <FieldsGroupTitle title={title} />
      <Grid container spacing={2}>
        {fields.map(({ size, ...inputProps }) => (
          <Grid item xs={size || 6} key={inputProps.name}>
            <InputByType testId={inputProps.name} form={form} {...inputProps} />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default InputsModalGroupWithTitle
