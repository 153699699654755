import { makeAutoObservable } from "mobx"
import { Loader } from "kui-utils"
import ApartmentImagesStore from "./photo/ApartmentImages"
import ApartmentPageStore from "../../../../store/ApartmentPageStore"
import PromotionDescriptionStore from "./PromotionDescriptionStore"

class PromotionContentStore {
  loader: Loader

  imagesStore: ApartmentImagesStore

  descriptionStore: PromotionDescriptionStore

  apartmentStore: ApartmentPageStore

  constructor(apartmentStore: ApartmentPageStore) {
    this.apartmentStore = apartmentStore
    this.imagesStore = new ApartmentImagesStore(apartmentStore)
    this.descriptionStore = new PromotionDescriptionStore(apartmentStore)
    this.loader = new Loader(true)
    makeAutoObservable(this)
  }

  initPromotionContentTab = async () => {
    const apartmentId = this.apartmentStore.overviewStore.id
    if (apartmentId) {
      this.loader.startLoading("promotion content")

      await Promise.allSettled([
        this.descriptionStore.fetchPromotionContent(),
        this.imagesStore.fetchImages(apartmentId),
      ])

      this.loader.endLoading()
    }
  }
}

export default PromotionContentStore
