import { getRoomsImagesSteps as getRoomsImagesStepsKUI } from "kui-crm_actions"
import { InspectionTypes } from "kui-crm"
import { InspectionRoomsFields } from "../types"

const getRoomsImagesSteps = (
  type: InspectionTypes,
  roomsParams?: InspectionRoomsFields | null
) =>
  getRoomsImagesStepsKUI(
    type,
    {
      kitchen: {
        description: "Add kitchen photos",
        label: "Comment about kitchen",
      },
      bedroom: (index: number) => ({
        description: `Add bedroom ${index}photos`,
        label: `Comment about bedroom`,
      }),
      room: (index: number) => ({
        description: `Add room ${index}photos`,
        label: `Comment about room`,
      }),
      bathroom: (index: number) => ({
        description: `Add bathroom ${index}photos`,
        label: "Comment about bathroom",
      }),
      toilet: (index: number) => ({
        description: `Add restroom ${index}photos`,
        label: "Comment about restroom",
      }),
      combinedBathroom: (index: number) => ({
        description: `Add combined bathroom ${index}photos`,
        label: "Comment about combined bathroom",
      }),
      others: {
        description: "Add corridor, hall, balcony and others photos",
        label: "Comment about corridor, hall, balcony and others",
      },
      keys: {
        description: "Add a photo of the keys",
        label: "Comment about keys",
      },
      entrance: {
        description: "Add entrance and adjacent territory photos",
        label: "Comment about entrance and adjacent territory",
      },
      parking: {
        description: "Add parking photos",
        label: "Comment about parking",
      },
      additional: {
        description: "Add additional info photos",
        label: "Comment about additional info",
      },
    },
    roomsParams
  )

export default getRoomsImagesSteps
