import React from "react"
import { PersonIcon } from "kui-icon"
import { observer } from "mobx-react"
import { ClosingContractsCardProps } from "./types"
import DashboardListCard from "../../common/DashboardListCard"
import ClosingContractRow from "./ClosingContractRow"

const ClosingContractsCard = (props: ClosingContractsCardProps) => {
  const { contractsStore } = props
  const { value, contracts, loader, fetchContracts, paginator, type } =
    contractsStore

  return (
    <DashboardListCard
      value={value}
      label={`Closing ${type} contracts`}
      icon={<PersonIcon />}
      loader={loader}
      paginator={paginator}
      fetchAll={fetchContracts}
    >
      {contracts.map((contract) => (
        <ClosingContractRow
          key={contract.id}
          contract={contract}
          contractType={type}
        />
      ))}
    </DashboardListCard>
  )
}

export default observer(ClosingContractsCard)
