import * as React from "react"
import { observer } from "mobx-react"
import { Box } from "kui-basic"
import { useEffect } from "react"
import { DateTime } from "luxon"
import { LoaderState } from "kui-crm"
import useExpensesStore from "./store"
import Loading from "../../../../components/common/Loading"
import ExpensesTabsPanel from "./components/common/ExpensesTabsPanel"
import { getQueryParam } from "../../../../utils/service/functions"

function ApartmentExpenses() {
  const {
    overviewStore,
    fetchLastOpenPeriod,
    periodsStore,
    date,
    loader,
    updateExpensesPage,
    setDate,
  } = useExpensesStore()
  const defaultDate = getQueryParam("date")

  useEffect(() => {
    if (overviewStore.id && !defaultDate) {
      fetchLastOpenPeriod(overviewStore.id)
    }
    if (defaultDate) {
      const formattedDate = DateTime.fromFormat(defaultDate, "MM.yy")

      setDate(formattedDate)
    }
    return () => updateExpensesPage()
  }, [])

  useEffect(() => {
    if (overviewStore.id && date) {
      periodsStore.getPeriods(overviewStore.id, date)
    }
  }, [date])

  useEffect(() => {
    if (overviewStore.id && date && !periodsStore.selectedPeriod) {
      periodsStore.getPeriods(overviewStore.id, date)
    }
  }, [periodsStore.selectedPeriod])

  if (loader.isLoading) return <Loading />

  return (
    <div key="static">
      <Box pt={3} pb={3}>
        <ExpensesTabsPanel />
      </Box>

      <LoaderState loader={loader} onlyError />
    </div>
  )
}

export default observer(ApartmentExpenses)
