import React from "react"
import { Caption } from "kui-basic"
import styled from "@emotion/styled"
import { CheckListProps } from "./types"
import CheckItem from "../CheckItem"

const CheckList = (props: CheckListProps) => {
  const { items, label } = props

  return (
    <>
      <StyledLabel size="s" weight={500} color="sixty" align="center">
        {label}
      </StyledLabel>
      {items.map((item) => (
        <CheckItem key={item.label} item={item} />
      ))}
    </>
  )
}

export default CheckList

const StyledLabel = styled(Caption)`
  margin-bottom: 14px;
`
