import React from "react"
import styled from "@emotion/styled"
import TableRow from "../../../../../../../../components/ui/TableRow"
import TableCell from "../../../../../../../../components/ui/TableCell"
import PartialPayerCell from "../PartialPayerCell"
import LinkButton from "../../../../../../../../components/common/LinkButton"
import { Routes } from "../../../../../../../../types/route"
import { SewerageRowProps } from "./types"
import TableRowDivider from "../../../../../../../../components/common/TableRowDivider"

const SewerageRow = (props: SewerageRowProps) => {
  const { sewerage } = props

  return (
    <>
      <StyledRow>
        <TableCell isFirst>Sewerage</TableCell>
        <TableCell />
        <PartialPayerCell
          mainPayer={sewerage.payer}
          refundPayer={sewerage.paymentsInfo}
        />
        <TableCell data-testid="tariff_group">
          <LinkButton
            href={`${Routes.tariffsGroups}/water/${sewerage.tariff?.id}`}
          >
            {sewerage.tariff?.name}
          </LinkButton>
        </TableCell>
        <TableCell>-</TableCell>
        <TableCell>-</TableCell>
        <TableCell>-</TableCell>
        <TableCell data-testid="consumption">
          {sewerage.tariffValues.consumption.toLocaleString()}
        </TableCell>
        <TableCell data-testid="tariff_value">
          {sewerage.tariffValues.tariffValue
            ? `${sewerage.tariffValues.tariffValue.toLocaleString()} ₽`
            : "-"}
        </TableCell>
        <TableCell>{sewerage.totalPrice?.toLocaleString()} ₽</TableCell>
      </StyledRow>
      <TableRowDivider />
    </>
  )
}

export default SewerageRow

const StyledRow = styled(TableRow)`
  td:first-child {
    padding-left: 27px;
  }
  td:last-child {
    padding-right: 15px;
  }
`
