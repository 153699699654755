import React from "react"
import { Button } from "kui-basic"
import { CheckIcon, PlusIcon } from "kui-icon"
import { useToggle } from "kui-utils"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { ImpairmentsInspectionHeaderProps } from "./types"
import InspectionTitle from "../../../components/InspectionTitle"
import ImpairmentModal from "../ImpairmentModal"
import useInspectionStore from "../../../store"
import ImpairmentConfirmModal from "../ImpairmentConfirmModal"

const ImpairmentsInspectionHeader = (
  props: ImpairmentsInspectionHeaderProps
) => {
  const { handleSubmit, form } = props
  const { editor, canEdit } = useInspectionStore()
  const [isAddingModalOpen, openAddingModal, closeAddingModal] = useToggle()
  const [
    isConfirmationModalOpen,
    openConfirmationModal,
    closeConfirmationModal,
  ] = useToggle()
  const disabled = !editor.isEditing

  return (
    <>
      <InspectionTitle
        form={form}
        endComponent={
          <Button
            isCircle
            size="xs"
            onClick={openAddingModal}
            disabled={disabled}
          >
            <PlusIcon width={12} height={12} />
          </Button>
        }
        headerComponent={
          canEdit && (
            <StyledButton
              data-testid="createInvoiceButton"
              size="xs"
              onClick={openConfirmationModal}
              endIcon={<CheckIcon />}
            >
              Create invoice
            </StyledButton>
          )
        }
      />
      <ImpairmentModal
        open={isAddingModalOpen}
        handleClose={closeAddingModal}
        handleSubmit={handleSubmit}
      />
      <ImpairmentConfirmModal
        open={isConfirmationModalOpen}
        handleClose={closeConfirmationModal}
      />
    </>
  )
}

export default observer(ImpairmentsInspectionHeader)

const StyledButton = styled(Button)`
  margin-left: 24px;
`
