import { InvoiceIcon } from "kui-icon"
import React from "react"
import { observer } from "mobx-react"
import DashboardListCard from "../../common/DashboardListCard"
import { CompaniesRequisitesCardProps } from "./types"
import CompanyRequisitesRow from "./CompanyRequisitesRow"
import CompaniesRequisitesArchive from "./CompaniesRequisitesArchive"

const CompaniesRequisitesCard = (props: CompaniesRequisitesCardProps) => {
  const { companiesStore } = props
  const { value, companies, loader, paginator, fetchCompanies } = companiesStore

  return (
    <DashboardListCard
      value={value}
      label="Companies with wrong requisites"
      icon={<InvoiceIcon />}
      renderArchive={(isOpen) => (
        <CompaniesRequisitesArchive
          isOpen={isOpen}
          companiesStore={companiesStore}
        />
      )}
      loader={loader}
      paginator={paginator}
      fetchAll={fetchCompanies}
    >
      {companies.map((company) => (
        <CompanyRequisitesRow key={company.id} company={company} />
      ))}
    </DashboardListCard>
  )
}

export default observer(CompaniesRequisitesCard)
