import { MetroAutocomplete, MetroStore } from "kui-crm"
import { InputsGroupWithTitleProps } from "../../../../../../components/common/InputsGroupWithTitle/types"
import {
  GasTypesValues,
  SlabsTypesValues,
  WallMaterialsValues,
} from "../../../../../../utils/content/listsOptions"
import { PromotionPredictorFormFields } from "./types"

const promotionPredictorFormFields = (
  handleMetroChange: (name: string, id: number) => void,
  metroStore: MetroStore
): Omit<InputsGroupWithTitleProps<PromotionPredictorFormFields>, "form">[] => [
  {
    title: "Main info",
    fields: [
      { label: "Total area", name: "area", type: "number", size: 2 },
      { label: "Rooms", name: "roomsNumber", type: "number", size: 2 },
      { label: "Floors", name: "floorCount", type: "number", size: 2 },
      {
        label: "Distance to the center",
        name: "distanceToCenter",
        type: "number",
        size: 2,
      },
    ],
  },
  {
    title: "Metro",
    fields: [
      {
        label: "Metro station",
        name: "metroStation.name",
        hideName: "metroStation.id",
        variant: "custom",
        CustomInput: MetroAutocomplete,
        size: 2,
        handleChange: handleMetroChange,
        metroStore,
      },
      {
        label: "Walk distance to metro",
        name: "metroStation.walkDistance",
        variant: "mask",
        mask: Number,
        thousandsSeparator: " ",
        size: 2,
      },
      {
        label: "Walking time to metro",
        name: "metroStation.walkTime",
        type: "number",
        size: 2,
      },
      {
        label: "Time in the car to metro",
        name: "metroStation.carTime",
        type: "number",
        size: 2,
      },
    ],
  },
  {
    title: "Houses info",
    fields: [
      {
        label: "Builded",
        name: "buildingYear",
        type: "number",
        size: 2,
      },
      {
        label: "Wall material",
        name: "wallMaterial",
        variant: "select",
        options: WallMaterialsValues,
        size: 2,
      },
      {
        label: "Overlap type",
        name: "overlapType",
        variant: "select",
        options: SlabsTypesValues,
        size: 2,
      },
      {
        label: "Gas",
        name: "gasLeads",
        variant: "select",
        options: GasTypesValues,
        size: 2,
      },
    ],
  },
]

export default promotionPredictorFormFields
