import { makeAutoObservable } from "mobx"
import { Loader } from "kui-utils"
import { PartialMeterInfoFields } from "kui-crm_actions"
import MetersStore from "./MetersStore"
import { ApartmentMeterParams } from "../../../../types/store/apartmentMeters"
import { ApartmentPageLiteStoreInterface } from "../../../../types/store/apartments"

class ApartmentMetersStore {
  waterMeters: MetersStore

  electricityMeters: MetersStore

  gasMeters: MetersStore

  heatingMeters: MetersStore

  apartmentStore: ApartmentPageLiteStoreInterface

  loader: Loader

  constructor(apartmentStore: ApartmentPageLiteStoreInterface) {
    this.waterMeters = new MetersStore("water", apartmentStore)
    this.electricityMeters = new MetersStore("electricity", apartmentStore)
    this.gasMeters = new MetersStore("gas", apartmentStore)
    this.heatingMeters = new MetersStore("heating", apartmentStore)
    this.loader = new Loader(true)
    this.apartmentStore = apartmentStore
    makeAutoObservable(this)
  }

  fetchMeters = async () => {
    this.loader.startLoading("apartment meters")

    await Promise.allSettled([
      this.waterMeters.fetchMeters(),
      this.electricityMeters.fetchMeters(),
      this.gasMeters.fetchMeters(),
      this.heatingMeters.fetchMeters(),
    ])

    this.loader.endLoading()
  }

  get meters() {
    return [
      ...this.waterMeters.meters,
      ...this.electricityMeters.meters,
      ...this.gasMeters.meters,
      ...this.heatingMeters.meters,
    ].map((meter) => ApartmentMetersStore.getMeterValuesParams(meter))
  }

  static getMeterValuesParams = (
    meter: ApartmentMeterParams
  ): PartialMeterInfoFields => ({
    id: meter.id,
    resource: meter.resource,
    type: meter.type,
    number: meter.number,
    values: {
      value: meter.lastValue ? Number(meter.lastValue) : null,
      T1: meter.T1?.lastValue ? Number(meter.T1.lastValue) : null,
      T2: meter.T2?.lastValue ? Number(meter.T2.lastValue) : null,
      T3: meter.T3?.lastValue ? Number(meter.T3.lastValue) : null,
    },
    images: [],
    maxValue: {
      value: meter.maxValue,
      T1: meter.T1?.maxValue,
      T2: meter.T2?.maxValue,
      T3: meter.T3?.maxValue,
    },
  })
}

export default ApartmentMetersStore
