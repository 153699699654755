import React from "react"
import { observer } from "mobx-react"
import { Grid, Heading } from "kui-basic"
import styled from "@emotion/styled"
import { InputByType } from "kui-crm"
import { TariffOverviewInfoFieldsProps } from "./types"
import useTariffStore from "../../store"
import tariffOverviewInfoFields from "./fields"

function TariffOverviewInfoFields({ form }: TariffOverviewInfoFieldsProps) {
  const { editor } = useTariffStore()
  const disabled = !editor.isEditing

  return (
    <>
      <StyledHeading size="h4" weight={700}>
        Info
      </StyledHeading>
      <Grid container spacing={3}>
        {tariffOverviewInfoFields.map(({ size, ...field }) => (
          <Grid item xs={size || 3} key={field.name}>
            <InputByType form={form} disabled={disabled} {...field} />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default observer(TariffOverviewInfoFields)

const StyledHeading = styled(Heading)`
  padding-bottom: 16px;
  padding-top: 4px;
`
