import { rest } from "msw"
import { paymentIds, paymentOwnerMock, paymentRenterMock } from "./data"
import {
  ApartmentPaymentModel,
  PostApartmentPaymentModel,
} from "../../../../pages/ApartmentPage/pages/ExpensesPage/types/api/expensesSummaryAPI"

const apartmentPaymentsHandlers = [
  rest.post<PostApartmentPaymentModel, ApartmentPaymentModel[] | Error>(
    `${process.env.REACT_APP_API_URL}/apartments/:id/expenses/payments`,
    (req, resp, ctx) => {
      if (req.body.payer && req.body.value && req.body.payment_date) {
        const payerId = Number(req.body.payer)
        if (payerId === paymentIds.renter) {
          const data = ctx.json([
            {
              ...paymentRenterMock,
              value: req.body.value,
              payment_date: req.body.payment_date,
            },
          ])
          return resp(data)
        }
        const data = ctx.json([
          {
            ...paymentOwnerMock,
            value: req.body.value,
            payment_date: req.body.payment_date,
          },
        ])
        return resp(data)
      }
      return resp(ctx.status(400))
    }
  ),
  rest.patch<PostApartmentPaymentModel, ApartmentPaymentModel[] | Error>(
    `${process.env.REACT_APP_API_URL}/apartments/:id/expenses/payments/:payment_id`,
    (req, resp, ctx) => {
      if (req.body.payer && req.body.value && req.body.payment_date) {
        const paymentId = Number(req.params.payment_id)
        if (paymentId === paymentIds.renter) {
          const data = ctx.json([
            {
              ...paymentRenterMock,
              value: req.body.value,
              payment_date: req.body.payment_date,
            },
          ])
          return resp(data)
        }
        const data = ctx.json([
          {
            ...paymentOwnerMock,
            value: req.body.value,
            payment_date: req.body.payment_date,
          },
        ])
        return resp(data)
      }
      return resp(ctx.status(400))
    }
  ),
  rest.delete<PostApartmentPaymentModel, ApartmentPaymentModel | Error>(
    `${process.env.REACT_APP_API_URL}/apartments/:id/expenses/payments/:payment_id`,
    (req, resp, ctx) => {
      const data = ctx.json(paymentRenterMock)
      return resp(data)
    }
  ),
]

export default apartmentPaymentsHandlers
