const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const isValidWithMaskExp = /^[^_]+$/

const dateRegExp =
  /(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/

const monthYearRegExp = /(0[1-9]|1[012])[- /.](19|20)\d\d/

const emailRegExp = /^[-\w.]+@([A-z0-9][-A-z0-9]+\.)+[A-z]{2,4}$/

const carNumberRegExp = /^([А-Яа-я]{1}[0-9]{3}[А-Яа-я]{2}\s{0,1}[0-9]{2,3})?$/

const innRegExp = /^(([0-9]{12})|([0-9]{10}))?$/

const corrAccountRegExp = /^(301[0-9]{17})?$/

const accountRegExp = /^408([0-9]{17})?$/

const bicRegExp = /^([0-9]{9})?$/

const swiftRegExp = /^([A-Za-z]{6}\w{2}\d{0,3})?$/

const simplePasswordRegExp =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{10,}$/

const mediumPasswordRegExp =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{12,}$/

const strongPasswordRegExp =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{14,}$/

export {
  phoneRegExp,
  dateRegExp,
  isValidWithMaskExp,
  emailRegExp,
  carNumberRegExp,
  innRegExp,
  monthYearRegExp,
  corrAccountRegExp,
  accountRegExp,
  bicRegExp,
  swiftRegExp,
  simplePasswordRegExp,
  mediumPasswordRegExp,
  strongPasswordRegExp,
}
