import { InputWithMap } from "kui-crm"
import { InputParams } from "../../../../components/common/InputsGroupWithTitle/types"
import { CompanyOfficeCreationFields } from "./types"

const companyOfficeFields: InputParams<CompanyOfficeCreationFields>[] = [
  {
    label: "Address",
    name: "office",
    variant: "custom",
    CustomInput: InputWithMap,
  },
  { label: "Email", name: "email" },
  { label: "Phone", name: "phone", variant: "phone" },
]

export default companyOfficeFields
