import React from "react"
import styled from "@emotion/styled"
import { Caption } from "kui-basic"

const Wrapper = styled.div`
  width: 100%;
  padding-top: 24px;
  padding-bottom: 12px;
`

type FieldsGroupTitleProps = {
  title: string
}

function FieldsGroupTitle({ title, ...otherProps }: FieldsGroupTitleProps) {
  return (
    <Wrapper {...otherProps}>
      <Caption size="xs" weight={600}>
        {title?.toUpperCase()}
      </Caption>
    </Wrapper>
  )
}

export default FieldsGroupTitle
