import { theme } from "kui-basic"

export const ContractStatusColor = {
  active: {
    color: theme.palette.brand.main,
    background: theme.palette.brand.light,
  },
  notActive: {
    color: theme.palette.grey.fiftyP,
    background: theme.palette.background.light3,
  },
}
