const appraisalsHeadCells = [
  { id: "id", label: "ID", width: "5%" },
  { id: "folder_number", label: "Folder", width: "5%" },
  { id: "apartment", label: "Object", width: "28%" },
  { id: "rental_contract", label: "Number", width: "15%" },
  { id: "creation_date", label: "Creation date", width: "10%" },
  { id: "status", label: "Status", width: "11%" },
  { id: "new_price_valid_from", label: "Change date", width: "9%" },
  { id: "price_change", label: "Δ Price", width: "9%" },
  { id: "price_change_percent", label: "Δ Price (%)", width: "8%" },
]

export default appraisalsHeadCells
