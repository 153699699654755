import React from "react"
import { observer } from "mobx-react"
import { RejectedAppraisalsArchiveProps } from "./types"
import RejectedInspectionRow from "../RejectedAppraisalRow"
import PaginationList from "../../../common/PaginationList"

const RejectedAppraisalsArchive = (props: RejectedAppraisalsArchiveProps) => {
  const { inspectionsStore, isOpen } = props
  const { archiveInspections, fetchArchive, archivePaginator, archiveLoader } =
    inspectionsStore

  return (
    <PaginationList
      paginator={archivePaginator}
      loader={archiveLoader}
      fetchAll={fetchArchive}
      isOpen={isOpen}
    >
      {archiveInspections.map((inspection) => (
        <RejectedInspectionRow
          key={inspection.id}
          isArchive
          inspection={inspection}
        />
      ))}
    </PaginationList>
  )
}

export default observer(RejectedAppraisalsArchive)
