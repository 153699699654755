import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { observer } from "mobx-react"
import {
  CompanyInfoFields as CompanyInfoFieldsType,
  CompanyInfoFormProps,
} from "./types"
import useCompanyStore from "../../store"
import CompanyInfoFields from "../../components/tabs/CompanyInfoTab/CompanyInfoFields"

function CompanyInfoForm({ handleSubmit }: CompanyInfoFormProps) {
  const { overviewStore, editor } = useCompanyStore()
  const disabled = !editor.isEditing

  const form = useForm<CompanyInfoFieldsType>({
    defaultValues: {
      inn: overviewStore!.inn,
      name: overviewStore!.name,
      type: overviewStore!.type,
      logo: overviewStore?.logo,
    },
  })

  useEffect(() => {
    if (editor.isAllowedToSendForm) {
      form.handleSubmit(handleSubmit, editor.blockToSendForm)()
    }
  }, [editor.isAllowedToSendForm])

  return (
    <form>
      <CompanyInfoFields form={form} disabled={disabled} />
    </form>
  )
}

export default observer(CompanyInfoForm)
