import React from "react"
import { Grid } from "kui-basic"
import { DateTime } from "luxon"
import { quickButtons } from "./content"
import CalendarQuickButton from "./CalendarQuickButton"
import useDashboardStore from "../../../store"
import { DashboardPeriodVariants } from "../../../types/store/common"

const CalendarQuickButtons = () => {
  const { periodVariant, setStartDate, setEndDate, setPeriodVariant } =
    useDashboardStore()

  const handleChange = (
    startDate: DateTime,
    endDate: DateTime,
    type: DashboardPeriodVariants
  ) => {
    setPeriodVariant(type)
    setStartDate(startDate)
    setEndDate(endDate)
  }

  return (
    <>
      {quickButtons.map((button) => (
        <Grid item>
          <CalendarQuickButton
            isSelected={periodVariant === button.type}
            type={button.type}
            options={[
              { label: `Current ${button.type}`, ...button.current },
              { label: `Prev ${button.type}`, ...button.prev },
            ]}
            onChange={handleChange}
          />
        </Grid>
      ))}
    </>
  )
}

export default CalendarQuickButtons
