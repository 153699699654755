import React from "react"
import _ from "lodash"
import { ResourceTypes } from "kui-crm/types"
import { Link } from "kui-crm"
import { TariffRowProps } from "./types"
import { Routes } from "../../../../types/route"
import TableRow from "../../../../components/ui/TableRow"
import TableCell from "../../../../components/ui/TableCell"
import TextOverflowWithCommentAndFile from "../../../../components/common/TextOverflowWithCommentAndFile"
import { TariffsOfTariffGroup } from "../../../TariffPage/types/store/tariffOverview"

const getResourceCells = (resource: ResourceTypes) => {
  switch (resource) {
    case "electricity":
      return [
        { label: "One-part", name: "T1" },
        { label: "Two-part", name: "T2" },
        { label: "Three-part", name: "T3" },
      ]
    case "water":
      return [
        { label: "Cold", name: "cold" },
        { label: "Hot", name: "hot" },
        { label: "Sewer", name: "sewerage" },
      ]
    default:
      return [{ label: "", name: "" }]
  }
}

function TariffRow({ tariff, isDark }: TariffRowProps) {
  const tariffsCells = getResourceCells(tariff.resource)

  return (
    <TableRow isDark={isDark}>
      <TableCell isFirst textColor="gray" width="4%">
        <TextOverflowWithCommentAndFile
          comment={tariff.reason?.comment}
          file={tariff.reason?.file}
          color="seventy"
          weight={500}
        >
          {tariff.id}
        </TextOverflowWithCommentAndFile>
      </TableCell>
      <TableCell>{tariff.district?.name || "-"}</TableCell>
      <TableCell>
        <Link href={`${Routes.tariffsGroups}/${tariff.resource}/${tariff.id}`}>
          {tariff.name}
        </Link>
      </TableCell>
      <TableCell>{_.capitalize(tariff.status)}</TableCell>
      {tariffsCells.map((cell) => (
        <TableCell align={cell.name ? "center" : "left"}>
          {tariff.tariffs[
            (cell.name || tariff.resource) as keyof TariffsOfTariffGroup
          ] || "-"}
        </TableCell>
      ))}
      <TableCell>{tariff.startDate?.toFormat("dd.MM.yyyy") || "-"}</TableCell>
      <TableCell isLast>
        {tariff.endDate?.toFormat("dd.MM.yyyy") || "-"}
      </TableCell>
    </TableRow>
  )
}

export default TariffRow
