/** @jsxImportSource @emotion/react */
import * as React from "react"
import { css, Theme, useTheme } from "@emotion/react"
import { Caption } from "kui-basic"

type LabelVariants = "white" | "grey"

type LabelAxisProps = {
  variant?: LabelVariants
  children: string
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

const labelWrapperStyles = (theme: Theme, variant: LabelVariants) => css`
  padding: 6px 10px;
  border-radius: 3px;
  text-align: center;
  background: ${variant === "white"
    ? "white"
    : theme.palette.background.light1};
`

function ApartmentChartAxisLabel({
  variant,
  children,
  ...otherProps
}: LabelAxisProps) {
  const theme = useTheme()

  return (
    <div css={labelWrapperStyles(theme, variant!)} {...otherProps}>
      <Caption size="xs" color="fiftyP">
        {children}
      </Caption>
    </div>
  )
}

ApartmentChartAxisLabel.defaultProps = {
  variant: "white",
}

export default ApartmentChartAxisLabel
