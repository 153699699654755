import React from "react"
import { Box, Grid } from "kui-basic"
import { observer } from "mobx-react"
import useApartmentStore from "../../../store"
import StatusBillet from "../../../../../components/common/StatusBillet"

const ApartmentStatuses = () => {
  const { overviewStore } = useApartmentStore()

  return (
    <Box pl={2}>
      <Grid container alignItems="center">
        <StatusBillet
          variant={overviewStore.status === "Archived" ? "default" : "warning"}
          label={overviewStore.status}
        />
        {overviewStore.isImported && (
          <Box pl={2}>
            <StatusBillet variant="warning" label="imported" />
          </Box>
        )}
      </Grid>
    </Box>
  )
}

export default observer(ApartmentStatuses)
