import * as React from "react"
import ReactDOM from "react-dom"
import { useRef } from "react"
import { MapOverlayProps } from "./types"
import OverlayView from "./OverlayView"

function createOverlayElement() {
  const el = document.createElement("div")
  el.style.position = "absolute"
  el.style.display = "inline-block"
  el.style.width = "auto"
  return el
}

function MapOverlay({ map, position, children }: MapOverlayProps) {
  const overlay = useRef<google.maps.OverlayView | null>(null)
  const el = useRef<HTMLDivElement | null>(null)

  React.useEffect(
    () => () => {
      if (overlay.current) overlay.current.setMap(null)
    },
    []
  )

  if (map && typeof window !== "undefined") {
    el.current = el.current || createOverlayElement()
    const Overlay = OverlayView()!
    overlay.current =
      overlay.current ||
      new Overlay({
        position: new window.google.maps.LatLng(position.lat, position.lng),
        content: el.current,
      })
    overlay.current.setMap(map)
    return ReactDOM.createPortal(children, el.current)
  }
  return null
}

MapOverlay.defaultProps = {
  map: null,
  position: { lat: 0, lng: 0 },
  children: null,
}

export default MapOverlay
