import { FormWrapper } from "kui-crm"
import React from "react"
import { useForm } from "react-hook-form"
import {
  InspectionFillingListFields,
  InspectionFillingCard,
  FillingCheckParams,
} from "kui-crm_actions"
import { Grid } from "kui-basic"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import useApartmentLiteStore from "../../../../../store/shared/apartment"
import ModalLabel from "../../../../common/ModalLabel"

const InspectionFillingStep = () => {
  const { inspectionsStore } = useApartmentLiteStore()
  const formStore = inspectionsStore.creationForm
  const form = useForm<InspectionFillingListFields>({
    // @ts-ignore
    values: {
      ...formStore.fields,
    },
  })

  const handleSubmit = (data: InspectionFillingListFields) => {
    formStore.updateFormFields(data)
    formStore.nextStep()
  }

  return (
    <>
      <ModalLabel label="Check the filling of the object" />
      <FormWrapper
        onSubmit={form.handleSubmit(handleSubmit)}
        form={form}
        withPrevStep
        handlePrevClick={formStore.prevStep}
        variant="next"
        label="Next"
      >
        <Grid container spacing={2}>
          {formStore.fields?.fillingList?.map(
            (filling: FillingCheckParams, index: number) => (
              <Grid item xs={12} key={filling.id}>
                <StyledFillingCard
                  form={form}
                  filling={filling}
                  index={index}
                  warningLabel="The filling was missing from the last inspection"
                />
              </Grid>
            )
          )}
        </Grid>
      </FormWrapper>
    </>
  )
}

export default observer(InspectionFillingStep)

const StyledFillingCard = styled(InspectionFillingCard)`
  border: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
`
