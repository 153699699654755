import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { DateTime } from "luxon"
import { MultistepForm, Loader } from "kui-utils"
import { MeterFeaturesFormFields } from "kui-crm"
import { HeatingMetersResponse, SingleMeterParams } from "kui-crm/types"
import ApartmentMetersAgent from "../../../../../../agent/ApartmentMeters"
import SingleMeterStore from "./SingleMeterStore"
import MetersAgent from "../../../../../../agent/Meters"
import {
  PostCommonMeterParams,
  PostMeterRequest,
} from "../../../../../../types/api/meters"
import ApartmentExpensesStore from "../ApartmentExpenses"
import { getCommonMeterParams } from "../../../../../../utils/service/apiMapper"

class ApartmentHeatingMetersStore {
  heatingMeters: SingleMeterStore[]

  selectedHeatingMeter: SingleMeterStore | null

  creationForm: MultistepForm<any>

  maxCountersCount: number | null

  loader: Loader

  actionLoader: Loader

  expensesStore: ApartmentExpensesStore

  total: number | null

  constructor(expensesStore: ApartmentExpensesStore) {
    this.heatingMeters = []
    this.selectedHeatingMeter = null
    this.creationForm = new MultistepForm<any>()
    this.maxCountersCount = null
    this.loader = new Loader()
    this.actionLoader = new Loader()
    this.expensesStore = expensesStore
    this.total = null
    makeAutoObservable(this)
  }

  getHeatingMeters = async (
    apartmentId: number,
    date: DateTime,
    contractId?: number
  ) => {
    this.loader.startLoading()

    const [err, res] = await to<HeatingMetersResponse>(
      ApartmentMetersAgent.getMeters(
        apartmentId,
        "heating",
        date.year,
        date.month,
        contractId
      )
    )

    runInAction(() => {
      if (!err && res) {
        this.total = Number(res.total_cost)
        this.heatingMeters = res.meters.map(
          (counter) => new SingleMeterStore(counter, "heating", this)
        )
      } else {
        this.loader.setError("fetch heating meters", err)
      }
      this.loader.endLoading()
    })
  }

  postMeter = async (data: MeterFeaturesFormFields, apartmentId: number) => {
    this.actionLoader.startLoading("heating meter creation")

    const body: PostMeterRequest =
      ApartmentHeatingMetersStore.getPostRequestBody(data, apartmentId)

    const [err, res] = await to<SingleMeterParams>(
      MetersAgent.createMeter(body, "heating")
    )

    runInAction(() => {
      if (!err && res) {
        const defaultValues = SingleMeterStore.getDefaultValues(res, data)
        this.heatingMeters.unshift(
          new SingleMeterStore(defaultValues, "heating", this)
        )
      } else {
        this.actionLoader.setError("heating meter creation", err)
      }
      this.actionLoader.endLoading()
    })
  }

  updateTotalPrice = (total: number) => {
    this.total = total
  }

  archiveById = (id: number) => {
    this.heatingMeters.find((counter) => counter.id === id)?.setArchived()
  }

  getHeatingMeterById = (id: number) => {
    this.selectedHeatingMeter =
      this.heatingMeters.find((counter) => counter.id === id) || null
  }

  updateCountersCount = (count: number | null) => {
    this.maxCountersCount = count
  }

  updateOperatingNumber = (operatingNumber: string) => {
    this.heatingMeters.forEach((meter) => {
      meter.updateOperatingNumber(operatingNumber)
    })
  }

  get activeHeatingMeters() {
    return this.heatingMeters.filter((counter) => !counter.isArchived)
  }

  get archiveHeatingMeters() {
    return this.heatingMeters.filter((counter) => counter.isArchived)
  }

  static getPostRequestBody = (
    data: MeterFeaturesFormFields,
    apartmentId: number
  ) => ({
    initial_value: data.initialValue,
    apartment: apartmentId,
    ...(getCommonMeterParams(data) as Omit<PostCommonMeterParams, "apartment">),
    ...(data.replaceableCounter && {
      replaceable_counter: {
        counter: Number(data.replaceableCounter),
        last_value: data.lastValue!,
      },
    }),
  })
}

export default ApartmentHeatingMetersStore
