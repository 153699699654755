const proxyHeadCells = [
  { id: "id", label: "ID", width: "5%" },
  { id: "folder_number", label: "Folder", width: "5%" },
  { id: "apartment", label: "Object", width: "29%" },
  { id: "owner", label: "Client", width: "21%" },
  { id: "number", label: "Number", width: "14%" },
  { id: "expiration_date", label: "Expired date", width: "14%" },
  { id: "status", label: "Status", width: "12%" },
]

export default proxyHeadCells
