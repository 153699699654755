import React from "react"
import { Grid, Button } from "kui-basic"
import styled from "@emotion/styled"
import { PlusIcon } from "kui-icon"
import InputSearch from "../../ui/InputSearch"
import { SearchPanelProps } from "./types"

function SearchPanel<T extends object>(props: SearchPanelProps<T>) {
  const { value, onChange, onClick } = props

  return (
    <Grid container alignItems="center" wrap="nowrap" justify="flex-end">
      <InputSearch value={value} onChange={onChange} />
      <StyledAddButton
        isCircle
        data-testid="open_modal"
        onClick={onClick}
        size="s"
        variant="orange"
      >
        <PlusIcon />
      </StyledAddButton>
    </Grid>
  )
}

export default SearchPanel

const StyledAddButton = styled(Button)`
  margin-left: 12px;
`
