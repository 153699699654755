import requests from "./index"
import { PostApartmentDocumentModel } from "../pages/RegistriesPage/types/api/apartmentDocuments"
import { MeterReadingRequestModel } from "../pages/RegistriesPage/types/api/metersReadings"

const RegistriesAgent = {
  getServicesRegistry: (offset: number, limit: number, params: string) =>
    requests.get(
      `/registry/service-expenses?limit=${limit}&offset=${offset}${params}`
    ),
  getServiceRegistry: (id: number) =>
    requests.get(`/registry/service-expenses/${id}`),
  getMetersRegistry: (offset: number, limit: number, params: string) =>
    requests.get(
      `/registry/meter-verifications?limit=${limit}&offset=${offset}${params}`
    ),
  getDocumentsRegistry: (
    type: string,
    offset: number,
    limit: number,
    params: string
  ) =>
    requests.get(`/registry/${type}?limit=${limit}&offset=${offset}${params}`),
  createDocument: (type: string, body: PostApartmentDocumentModel) =>
    requests.post(`/registry/${type}`, body),
  editDocument: (id: number, type: string, body: PostApartmentDocumentModel) =>
    requests.patch(`/registry/${type}/${id}`, body),
  removeDocument: (id: number, type: string) =>
    requests.delete(`/registry/${type}/${id}`),
  createMeterReading: (body: MeterReadingRequestModel) =>
    requests.post(`/registry/meter-verifications`, body),
  editMeterReading: (id: number, body: MeterReadingRequestModel) =>
    requests.patch(`/registry/meter-verifications/${id}`, body),
  deleteMeterReading: (id: number) =>
    requests.delete(`/registry/meter-verifications/${id}`),
  getInspectionsRegistry: (offset: number, limit: number, params: string) =>
    requests.get(
      `/registry/inspections?limit=${limit}&offset=${offset}${params}`
    ),
  getTenantAppraisalsRegistry: (
    offset: number,
    limit: number,
    params: string
  ) =>
    requests.get(
      `/registry/tenant-appraisals?limit=${limit}&offset=${offset}${params}`
    ),
  getLandlordAppraisalsRegistry: (
    offset: number,
    limit: number,
    params: string
  ) =>
    requests.get(
      `/registry/landlord-appraisals?limit=${limit}&offset=${offset}${params}`
    ),
}

export default RegistriesAgent
