import { createContext, useContext } from "react"
import ClientsStore from "./ClientsStore"

const clientsStore = new ClientsStore()
const ClientsContext = createContext(clientsStore)

const useClientsStore = () => useContext(ClientsContext)

export default useClientsStore
export { clientsStore }
