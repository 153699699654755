import React from "react"
import { observer } from "mobx-react"
import { ModalProps } from "kui-crm"
import useClientsStore from "./store"
import PaginationPage from "../../components/common/PaginationPageWithSearch"
import ClientsTable from "./components/ClientsTable"
import Page from "../../components/common/Page"
import ClientCreationModal from "../../components/modals/ClientCreationModal"

function ClientsPage() {
  const clientsStore = useClientsStore()

  return (
    <Page withContainer={false}>
      <PaginationPage label="Clients" store={clientsStore}>
        <ClientsTable />
        {(props: ModalProps) => (
          <ClientCreationModal
            {...props}
            creationForm={clientsStore.creationForm}
            handleSubmit={clientsStore.createClient}
          />
        )}
      </PaginationPage>
    </Page>
  )
}

export default observer(ClientsPage)
