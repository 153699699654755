import requests from "./index"
import {
  ClientModel,
  FetchClientsParams,
  PostClientRequest,
} from "../types/api/clients"
import {
  PatchClientCompanyBody,
  PatchUserBankRequest,
  PatchUserEmploymentRequest,
  PatchUserParams,
  PatchUserPersonalInfoRequest,
} from "../pages/ClientPage/types/api/clientAPI"

const ClientsAgent = {
  all: (offset: number, limit: number, params: string = "") =>
    requests.get<FetchClientsParams>(
      `/users?offset=${offset}&limit=${limit}${params}`
    ),
  getById: (id: number) => requests.get<ClientModel>(`/users/${id}`),
  create: (data: PostClientRequest) => requests.post<any>("/users", data),
  patch: (id: number, data: PatchUserParams) =>
    requests.patch<ClientModel>(`/users/${id}`, data),
  delete: (id: number) => requests.delete(`/users/${id}`),
}

const UserPersonalInfoAgent = {
  getById: (id: number) => requests.get(`/users/${id}/personal`),
  patchPersonalInfo: (
    id: number,
    data: Partial<PatchUserPersonalInfoRequest>
  ) => requests.patch(`/users/${id}/personal`, data),
  getDocuments: (id: number) => requests.get(`/users/${id}/personal/documents`),
  postDocument: (data: FormData, id: number) =>
    requests.post(`/users/${id}/personal/documents`, data),
  deleteDocument: (documentId: number, userId: number) =>
    requests.delete(`/users/${userId}/personal/documents/${documentId}`),
  patchDocument: (documentId: number, data: FormData, userId: number) =>
    requests.patch(`/users/${userId}/personal/documents/${documentId}`, data),
}

const UserCompanyInfoAgent = {
  getById: (id: number) => requests.get(`/users/${id}/company/contacts`),
  patchCompanyInfo: (id: number, data: Partial<PatchClientCompanyBody>) =>
    requests.patch(`/users/${id}/company/contacts`, data),
  getDocuments: (id: number) => requests.get(`/users/${id}/personal/documents`),
  postDocument: (data: FormData, id: number) =>
    requests.post(`/users/${id}/personal/documents`, data),
  deleteDocument: (documentId: number, userId: number) =>
    requests.delete(`/users/${userId}/personal/documents/${documentId}`),
  patchDocument: (documentId: number, data: FormData, userId: number) =>
    requests.patch(`/users/${userId}/personal/documents/${documentId}`, data),
}

const UserEmploymentInfoAgent = {
  getById: (id: number) => requests.get(`/users/${id}/employment`),
  patchEmploymentInfo: (
    id: number,
    data: Partial<PatchUserEmploymentRequest>
  ) => requests.patch(`/users/${id}/employment`, data),
  getDocuments: (id: number) =>
    requests.get(`/users/${id}/employment/documents`),
  postDocument: (data: FormData, id: number) =>
    requests.post(`/users/${id}/employment/documents`, data),
  deleteDocument: (documentId: number, userId: number) =>
    requests.delete(`/users/${userId}/employment/documents/${documentId}`),
  patchDocument: (documentId: number, data: FormData, userId: number) =>
    requests.patch(`/users/${userId}/employment/documents/${documentId}`, data),
}

const UserBankInfoAgent = {
  getById: (id: number) => requests.get(`/users/${id}/bank`),
  patchBankInfo: (id: number, data: Partial<PatchUserBankRequest>) =>
    requests.patch(`/users/${id}/bank`, data),
  getDocuments: (id: number) => requests.get(`/users/${id}/bank/documents`),
  postDocument: (data: FormData, id: number) =>
    requests.post(`/users/${id}/bank/documents`, data),
  deleteDocument: (documentId: number, userId: number) =>
    requests.delete(`/users/${userId}/bank/documents/${documentId}`),
  patchDocument: (documentId: number, data: FormData, userId: number) =>
    requests.patch(`/users/${userId}/bank/documents/${documentId}`, data),
}

export default ClientsAgent

export {
  UserPersonalInfoAgent,
  UserEmploymentInfoAgent,
  UserBankInfoAgent,
  UserCompanyInfoAgent,
}
