import { DependencyList, useEffect } from "react"
import { Paginator } from "kui-utils"

const useFetchPage = (
  paginator?: Paginator,
  fetchAll?: () => Promise<any>,
  deps?: DependencyList
) => {
  useEffect(() => {
    if (paginator?.isFetching && !paginator?.isFinishPage && fetchAll) {
      fetchAll()
    }
    if (paginator?.isFetching && paginator?.isFinishPage) {
      paginator?.stopFetching()
    }
  }, [paginator, paginator?.isFetching])

  useEffect(
    () => () => {
      paginator?.clearOffset()
      paginator?.startFetching()
    },
    deps || []
  )
}

export default useFetchPage
