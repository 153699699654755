import React from "react"
import { observer } from "mobx-react"
import useDashboardStore from "../../store"
import HousingInspectorDashboardCards from "./HousingInspectorDashboardCards"
import DashboardPageWrapper from "../../components/common/DashboardPageWrapper"

const HousingInspectorDashboard = () => {
  const { housingInspectorDashboard } = useDashboardStore()

  return (
    <DashboardPageWrapper dashboardStore={housingInspectorDashboard}>
      <HousingInspectorDashboardCards />
    </DashboardPageWrapper>
  )
}

export default observer(HousingInspectorDashboard)
