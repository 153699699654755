import { makeAutoObservable } from "mobx"
import { DateTime } from "luxon"
import AccountantDashboardStore from "./variants/AccountantDashboardStore"
import HousingInspectorDashboardStore from "./variants/HousingInspectorDashboardStore"
import HousingManagerDashboardStore from "./variants/HousingManagerDashboardStore"
import { DashboardPeriodVariants } from "../types/store/common"
import AdminDashboardStore from "./variants/AdminDashboardStore"
import SalesManagerDashboardStore from "./variants/SalesManagerDashboardStore"
import ConsultantDashboardStore from "./variants/ConsultantDashboardStore"

class DashboardPageStore {
  adminDashboard: AdminDashboardStore

  accountantDashboard: AccountantDashboardStore

  housingInspectorDashboard: HousingInspectorDashboardStore

  housingManagerDashboard: HousingManagerDashboardStore

  salesManagerDashboard: SalesManagerDashboardStore

  consultantDashboard: ConsultantDashboardStore

  periodVariant: DashboardPeriodVariants

  startDate: DateTime | null

  endDate: DateTime | null

  constructor() {
    this.accountantDashboard = new AccountantDashboardStore(this)
    this.housingInspectorDashboard = new HousingInspectorDashboardStore(this)
    this.housingManagerDashboard = new HousingManagerDashboardStore(this)
    this.salesManagerDashboard = new SalesManagerDashboardStore(this)
    this.adminDashboard = new AdminDashboardStore(this)
    this.consultantDashboard = new ConsultantDashboardStore(this)
    this.periodVariant = "calendar"
    this.startDate = null
    this.endDate = null
    makeAutoObservable(this)
  }

  setPeriodVariant = (periodVariant: DashboardPeriodVariants) => {
    this.periodVariant = periodVariant
  }

  setStartDate = (date: DateTime | null) => {
    this.startDate = date
  }

  setEndDate = (date: DateTime | null) => {
    this.endDate = date
  }

  clearFilters = () => {
    this.periodVariant = "calendar"
    this.startDate = null
    this.endDate = null
  }

  get periodFilter() {
    return `${
      this.startDate
        ? `&action_date_after=${this.startDate.minus({ day: 1 }).toISODate()}`
        : ""
    }${
      this.endDate
        ? `&action_date_before=${this.endDate.plus({ day: 1 }).toISODate()}`
        : ""
    }`
  }
}

export default DashboardPageStore
