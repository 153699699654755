import styled from "@emotion/styled"
import TabsPanel from "../TabsPanel"

const PageTabsPanel = styled(TabsPanel)`
  .KUI-TabsContainer {
    background: ${({ theme }) => theme.palette.background.light1};
    top: 121px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
  }
  .KUI-Tab {
    height: 55px;
    p {
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
    }
    &:disabled {
      p {
        opacity: 0.5;
      }
    }
  }
`

export default PageTabsPanel
