import React from "react"
import FillingInfoForm from "../../../../../../../pages/ApartmentPage/forms/filling/FillingInfoForm"
import { FillingInfoFormFields } from "../../../../../../../pages/ApartmentPage/forms/filling/FillingInfoForm/types"
import { FillingInfoStepProps } from "./types"
import useApartmentLiteStore from "../../../../../../../store/shared/apartment"

const FillingInfoStep = ({ index, onPrev }: FillingInfoStepProps) => {
  const { inspectionsStore } = useApartmentLiteStore()
  const formStore = inspectionsStore.creationForm
  const defaultValues = formStore.fields?.fillingList?.[index] || {}

  const handleSubmit = (data: FillingInfoFormFields) => {
    formStore.replaceFieldInArray("fillingList", data, index)
    formStore.nextStep()
  }

  const handlePrev = () => {
    formStore.prevStep()
    if (!Object.keys(defaultValues).length) onPrev()
  }

  return (
    <FillingInfoForm
      withType
      formMaker={formStore}
      defaultValues={defaultValues}
      handleSubmit={handleSubmit}
      formBottomProps={{
        withPrevStep: true,
        handlePrevClick: handlePrev,
      }}
    />
  )
}

export default FillingInfoStep
