import { InputParams } from "../../../../../components/common/InputsGroupWithTitle/types"
import { ApartmentDocumentFormFields } from "./types"
import ApartmentAutocomplete from "../../../../../components/entityInputs/ApartmentAutocomplete"
import { ApartmentDocumentVariants } from "../../../../ApartmentPage/types/store/apartment"
import getApartmentDocumentFormFields from "../../../../../components/forms/apartment/ApartmentDocumentForm/fields"

const getApartmentDocumentFields = (
  variant: ApartmentDocumentVariants
): InputParams<ApartmentDocumentFormFields>[] => [
  {
    name: "apartment",
    label: "Object",
    variant: "custom",
    CustomInput: ApartmentAutocomplete,
    size: 12,
  },
  ...getApartmentDocumentFormFields(variant),
]

export default getApartmentDocumentFields
