import React from "react"
import { observer } from "mobx-react"
import { DocumentsBlockWithActionsProps } from "./types"
import DocumentsBlock from "../DocumentsBlock"
import { UploadDocumentParams } from "../DocumentsBlockWithForm/types"

const DocumentsBlockWithActions = (props: DocumentsBlockWithActionsProps) => {
  const { documentsStore, requestParams, ...other } = props

  const createDocuments = async (data: UploadDocumentParams[]) => {
    await documentsStore.createDocuments(data, requestParams)
  }

  return (
    <DocumentsBlock
      requestParams={requestParams}
      addFiles={createDocuments}
      documents={documentsStore.documents}
      {...other}
    />
  )
}

export default observer(DocumentsBlockWithActions)
