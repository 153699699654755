import * as yup from "yup"
import { isValidWithMaskExp } from "../../../../utils/content/regexp"

const CompanyOfficeCreationSchema = yup.object().shape({
  office: yup.object({
    address: yup.string().required("Enter the company office address"),
  }),
  // eslint-disable-next-line react/forbid-prop-types
  phone: yup.object({
    phoneNumber: yup
      .string()
      .matches(isValidWithMaskExp, "Enter correct the company office phone")
      .required("Enter the company office phone"),
  }),
  email: yup.string().email(),
  zipCode: yup
    .string()
    .matches(isValidWithMaskExp, "Enter correct the zip code")
    .required("Enter the zip code"),
})

export default CompanyOfficeCreationSchema
