import { useRef } from "react"
import { Editor } from "kui-utils"

const useEditor = () => {
  const editorRef = useRef(new Editor())

  return editorRef.current
}

export default useEditor
