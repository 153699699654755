import React from "react"
import styled from "@emotion/styled"
import { Grid } from "kui-basic"
import { OptionParams } from "../types"
import { AutocompleteOptionProps } from "./types"

const AutocompleteOption = <T extends OptionParams>(
  props: AutocompleteOptionProps<T>
) => {
  const { option, renderOption, handleSelect, index } = props

  const handleClick = () => {
    handleSelect(option)
  }

  return (
    <StyledOptionWrapper
      item
      xs={12}
      onClick={handleClick}
      data-testid={`autocomplete_option_${index}`}
    >
      {renderOption(option)}
    </StyledOptionWrapper>
  )
}

export default AutocompleteOption

const StyledOptionWrapper = styled(Grid)`
  cursor: pointer;
`
