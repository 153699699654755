import React from "react"
import { observer } from "mobx-react"
import ApartmentsTable from "../ApartmentsTable"
import { ApartmentsContentViewProps } from "./types"
import ApartmentsMap from "../ApartmentsMap"
import MapWrapper from "../../../../components/common/MapWrapper"

function ApartmentsContentView({ view }: ApartmentsContentViewProps) {
  return view === "table" ? (
    <ApartmentsTable />
  ) : (
    <MapWrapper>
      <ApartmentsMap />
    </MapWrapper>
  )
}

export default observer(ApartmentsContentView)
