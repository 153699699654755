import * as React from "react"
import { observer } from "mobx-react"
import { useCallback, useEffect, useRef, useState } from "react"
import { AutocompleteOption, InputWithAutocomplete } from "kui-complex"
import { ModalProps, useAutocomplete } from "kui-crm"
import { MeterAutocompleteProps } from "./types"
import MetersLiteStore from "../../../store/lites/MetersLiteStore"
import { MeterLiteParams } from "../../../types/store/meters"
import { MeterCreationFormFields } from "../../forms/meters/MeterCreationFormFields/types"
import MeterCreationModal from "../../modals/MeterCreationModal"

function MeterAutocomplete<TFormValues extends object>(
  props: MeterAutocompleteProps<TFormValues>
) {
  const { form, name, label, resource, apartmentId, ...otherProps } = props
  const [isOpen, setIsOpen] = useState(false)
  const metersStoreRef = useRef(new MetersLiteStore())
  const metersStore = metersStoreRef.current
  const { meters, paginator, fetchAll, loader, filter, clearMeters } =
    metersStore

  const fetchAllByResource = useCallback(() => {
    fetchAll(resource)
  }, [resource, filter.filterQuery])

  const { onSearchItem, onScrollEnd } = useAutocomplete(
    filter,
    paginator,
    fetchAllByResource,
    isOpen
  )

  const onSelectItem = (item: MeterLiteParams | null) => {
    form.setValue(`${name}.id`, item?.id)
    form.setValue(`${name}.type`, item?.type)
    form.setValue(`${name}.name`, item?.name, { shouldValidate: true })
  }

  const onCreate = async (data: MeterCreationFormFields) => {
    const meter = await metersStore.createMeter(data)
    if (meter) onSelectItem(meter)
  }

  useEffect(() => {
    clearMeters()
  }, [resource])

  useEffect(() => {
    if (apartmentId) {
      filter.updateFilterQuery(`&apartment=${apartmentId}`)
    }
  }, [apartmentId])

  return (
    <InputWithAutocomplete
      renderOption={(option: MeterLiteParams) => (
        <AutocompleteOption label={option.name} />
      )}
      options={meters}
      isLoading={loader.isLoading}
      label={label}
      form={form}
      name={`${name}.name`}
      hideName={`${name}.id`}
      onSelectItem={onSelectItem}
      onSearchItem={onSearchItem}
      handleScrollEnd={onScrollEnd}
      creatingLabel="Add new meter"
      renderCreatingModal={(modalProps: ModalProps) => (
        <MeterCreationModal
          resource={resource}
          onCreate={onCreate}
          metersStore={metersStore}
          formStore={metersStore.creationForm}
          createMeter={metersStore.createMeter}
          {...modalProps}
        />
      )}
      onOpen={setIsOpen}
      {...otherProps}
    />
  )
}

MeterAutocomplete.defaultProps = {
  name: "meter",
}

export default observer(MeterAutocomplete)
