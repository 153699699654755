import React from "react"
import { observer } from "mobx-react"
import { useForm } from "react-hook-form"
import { Grid } from "kui-basic"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormWrapper, InputByType, getPartialMeterInfoFields } from "kui-crm"
import {
  getPartialMeterInfoSchema,
  PartialMeterInfoFields,
  PartialMeterInfoStepProps,
} from "kui-crm_actions"
import ModalLabel from "../../../../../common/ModalLabel"
import useApartmentLiteStore from "../../../../../../store/shared/apartment"
import getMeterLabel from "../../../../../../utils/service/getMeterLabel"

const PartialMeterInfoStep = (props: PartialMeterInfoStepProps) => {
  const { resource, type, index, withCheckValue } = props
  const { inspectionsStore } = useApartmentLiteStore()
  const formStore = inspectionsStore.creationForm
  const label = `Check and add ${getMeterLabel(resource, type)} value`
  const meter = formStore.fields?.meters?.[index]
  const fields = getPartialMeterInfoFields(
    resource,
    type,
    meter,
    withCheckValue
  )
  const schema = getPartialMeterInfoSchema(fields)

  const form = useForm({
    values: meter,
    resolver: yupResolver(schema),
  })

  const handleSubmit = (data: PartialMeterInfoFields) => {
    formStore.replaceFieldInArray("meters", { ...data, resource }, index)
    formStore.nextStep()
  }

  return (
    <div>
      <ModalLabel label={label} />
      <FormWrapper
        form={form}
        onSubmit={handleSubmit}
        withPrevStep
        handlePrevClick={formStore.prevStep}
        variant="next"
        label="Next"
      >
        <Grid container spacing={2}>
          {fields.map((field) => (
            <Grid item key={field.name} xs={6}>
              <InputByType form={form} {...field} />
            </Grid>
          ))}
        </Grid>
      </FormWrapper>
    </div>
  )
}

export default observer(PartialMeterInfoStep)
