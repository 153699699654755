import { MapStore, MetroStore } from "kui-crm"
import AuthStore from "./common/Auth"
import AppStore from "./common/AppStore"
import CabinetStore from "./common/Cabinet"

class RootStore {
  authStore: AuthStore

  metroStore: MetroStore

  appStore: AppStore

  mapStore: MapStore

  cabinetStore: CabinetStore

  constructor() {
    this.authStore = new AuthStore(this)
    this.metroStore = new MetroStore()
    this.appStore = new AppStore(this)
    this.mapStore = new MapStore()
    this.cabinetStore = new CabinetStore(this)
  }

  reset = () => {
    this.authStore.reset()
    this.metroStore.reset()
    this.appStore.reset()
    this.mapStore.reset()
    this.cabinetStore.reset()
  }
}

export default RootStore
