import { InputWithMap } from "kui-crm"
import { InputParams } from "../../../../../../components/common/InputsGroupWithTitle/types"
import { CompanyOfficesFields } from "../../../../forms/CompanyOfficesForm/types"

const companyOfficeFields: InputParams<CompanyOfficesFields>[] = [
  {
    label: "Address",
    name: "office",
    variant: "custom",
    CustomInput: InputWithMap,
    size: 4,
  },
  {
    label: "Zip code",
    name: "zipCode",
    variant: "mask",
    mask: "000000",
    size: 2,
  },
  { label: "Email", name: "email", size: 3 },
  { label: "Phone", name: "phone", variant: "phone", size: 3 },
]

export default companyOfficeFields
