import React from "react"
import { Grid } from "kui-basic"
import { InputWithController } from "kui-complex"
import { PromotionDescriptionFieldsProps } from "../types"

const PromotionLinksFields = (props: PromotionDescriptionFieldsProps) => {
  const { form, disabled } = props

  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <InputWithController
          label="Video link"
          name="videoLink"
          placeholder="youtube.com/000"
          form={form}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <InputWithController
          label="3D link"
          name="overview3DLink"
          placeholder="my.matterport.com/124"
          form={form}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  )
}

export default PromotionLinksFields
