import styled from "@emotion/styled"
import { TableRowProps } from "./types"

const TableRow = styled.tr<TableRowProps>`
  background: ${({ isDark, theme }) =>
    isDark ? theme.palette.background.light1 : "none"};
`

TableRow.defaultProps = {
  isDark: false,
}

export default TableRow
