import { makeAutoObservable } from "mobx"
import { DateTime } from "luxon"
import { InspectionTypes } from "kui-crm"
import to from "await-to-js"
import { Loader, resHandler } from "kui-utils"
import { ApartmentLinkParams } from "../../../../types/store/apartments"
import { InspectionNeedImpairmentsModel } from "../../types/api/inspections"
import ApartmentsStore from "../../../../store/lites/ApartmentsStore"
import DashboardAgent from "../../../../agent/Dashboard"
import InspectionsStore from "./InspectionsStore"

class InspectionStore {
  id: number

  notifyId: number

  apartment: ApartmentLinkParams

  date: DateTime | null

  type: InspectionTypes

  loader: Loader

  inspectionsStore: InspectionsStore

  constructor(
    { inspection, id }: InspectionNeedImpairmentsModel,
    inspectionsStore: InspectionsStore
  ) {
    this.id = inspection.id
    this.notifyId = id
    this.apartment = ApartmentsStore.getApartmentLinkParams(
      inspection.apartment
    )
    this.date = inspection.date ? DateTime.fromISO(inspection.date) : null
    this.type = inspection.inspection_type
    this.loader = new Loader()
    this.inspectionsStore = inspectionsStore
    makeAutoObservable(this)
  }

  archiveInspection = async () => {
    this.loader.startLoading()

    const response = await to(
      DashboardAgent.archiveDashboardItem(this.notifyId)
    )

    resHandler(response, this.loader, () => {
      this.inspectionsStore.removeFromInspectionsList(this.notifyId)
    })
  }

  restoreInspection = async () => {
    this.loader.startLoading()

    const response = await to(
      DashboardAgent.unArchiveDashboardItem(this.notifyId)
    )

    resHandler(response, this.loader, () => {
      this.inspectionsStore.removeFromArchiveList(this.notifyId)
    })
  }
}

export default InspectionStore
