import requests from "./index"

const UploadAgent = {
  uploadImage: (data: FormData | any) => requests.post("/upload/image", data),
  uploadDocument: (data: FormData | any) =>
    requests.post("/upload/document", data),
  uploadExpense: (data: FormData | any) =>
    requests.post("/upload/parse_service_expense", data),
  parsePassport: (data: FormData | any) =>
    requests.post("/upload/parse_passport", data),
  parseRequisites: (data: FormData | any) =>
    requests.post("/upload/parse-qr-billing-info", data),
}

export default UploadAgent
