import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { Box } from "kui-basic"
import Loading from "../../../../../components/common/Loading"
import ApartmentDescriptionsForm from "../../../forms/description/ApartmentDescriptionForm"
import { ApartmentDescriptionFields } from "../../../forms/description/ApartmentDescriptionForm/types"
import useApartmentStore from "../../../store"
import ApartmentFillingList from "./ApartmentFillingList"

function ApartmentDescriptionTab() {
  const {
    descriptionStore,
    overviewStore,
    editor,
    patchApartment,
    setEditPermission,
  } = useApartmentStore()

  const handleSubmit = (data: ApartmentDescriptionFields) => {
    if (overviewStore.id) {
      patchApartment(overviewStore.id, data, true)
      editor.endEditing()
    }
  }

  useEffect(() => {
    setEditPermission("objectDescriptionEdit")
    if (overviewStore.id) {
      descriptionStore.initDescriptionTab(overviewStore.id)
    }
  }, [overviewStore])

  if (descriptionStore.loader.isLoading) {
    return <Loading />
  }

  return (
    <Box pb={3}>
      <ApartmentDescriptionsForm handleSubmit={handleSubmit} />
      <ApartmentFillingList />
    </Box>
  )
}

export default observer(ApartmentDescriptionTab)
