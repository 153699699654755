import to from "await-to-js"
import { makeAutoObservable, runInAction } from "mobx"
import { DateTime } from "luxon"
import { Loader, Editor, callPromises } from "kui-utils"
import { RentalContractModel, ServiceContractModel } from "kui-crm/types"
import NotesStore from "../../../store/notes/Notes"
import RentalContractInfoStore from "../../../store/shared/contracts/RentalContractInfoStore"
import DocumentsStore from "../../../store/templates/DocumentsStore"
import HostStore from "../../../store/Root"
import RentalContractAgent from "../../../agent/RentalContract"
import ServiceContractAgent from "../../../agent/ServiceContract"
import { RCInfoFormFields } from "../components/RCInfoFields/types"
import ApartmentLiteServicesStore from "../../../store/shared/apartmentService/ApartmentServicesStore"
import ContractChangesStore from "../../../store/templates/ContractChangesStore"

class RentalContractPageStore {
  contractInfoStore: RentalContractInfoStore

  documentsStore: DocumentsStore

  notesStore: NotesStore | null

  editor: Editor

  loader: Loader

  actionLoader: Loader

  contractChangesStore: ContractChangesStore

  lastOpenPeriod: DateTime | null

  constructor(hostStore: HostStore) {
    this.contractInfoStore = new RentalContractInfoStore()
    this.notesStore = null
    this.documentsStore = new DocumentsStore(RentalContractAgent)
    this.loader = new Loader(true)
    this.actionLoader = new Loader()
    this.editor = new Editor()
    this.lastOpenPeriod = null
    this.contractChangesStore = new ContractChangesStore(
      "rental",
      this.actionLoader,
      this.contractInfoStore
    )
    makeAutoObservable(this)
  }

  initRCPage = async (id: number) => {
    this.updateRentalContractPage()

    this.loader.startLoading()

    const [scId] = await callPromises([
      this.fetchRentalContract(id),
      this.documentsStore.fetchDocuments(id),
      this.contractChangesStore.fetchPendingChanges(id),
      this.contractChangesStore.fetchAppliedChanges(id),
    ])
    if (scId) await this.fetchServiceContract(scId)
    const apartmentId = this.contractInfoStore.apartment?.id
    if (
      apartmentId &&
      !this.contractInfoStore.isClosed &&
      !this.contractInfoStore.isArchived
    )
      await this.fetchLastOpenPeriod(apartmentId)

    this.loader.endLoading()
  }

  fetchRentalContract = async (id: number) => {
    const [err, res] = await to<RentalContractModel>(
      RentalContractAgent.getById(id)
    )
    runInAction(() => {
      if (!err && res) {
        this.contractInfoStore.updateRentalContract(res)
        this.notesStore = new NotesStore("rental-contracts", res.id)
      } else {
        this.loader.setError("fetch rental contract", err)
      }
    })

    return res?.service_contract_id
  }

  fetchServiceContract = async (id: number) => {
    const [err, res] = await to<ServiceContractModel>(
      ServiceContractAgent.getById(id)
    )
    runInAction(() => {
      if (!err && res) {
        this.contractInfoStore.updateServiceContract(res)
      } else {
        this.loader.setError("fetch service contract", err)
      }
    })
  }

  fetchLastOpenPeriod = async (apartmentId: number) => {
    this.loader.startLoading()

    const [err, res] = await ApartmentLiteServicesStore.getLastOpenedPeriod(
      apartmentId
    )

    runInAction(() => {
      if (res) {
        this.lastOpenPeriod = res.period
      } else {
        this.loader.endLoading()
        this.loader.setError("fetch last open period", err)
      }
    })
  }

  deleteRentalContract = async (id: number) => {
    this.actionLoader.startLoading()

    const [err] = await to(RentalContractAgent.delete(id))
    if (err) {
      this.actionLoader.setError("rental contract removal", err)
    }
    this.actionLoader.endLoading()
  }

  addPendingChange = async (data: RCInfoFormFields) => {
    const contractId = this.contractInfoStore.id
    if (contractId) {
      this.actionLoader.startLoading("rental contract changes")

      await this.contractInfoStore.addPendingChange(data)
      await this.contractChangesStore.fetchPendingChanges(contractId)

      this.actionLoader.endLoading()
    }
  }

  updateRentalContractPage = () => {
    this.contractInfoStore = new RentalContractInfoStore()
    this.notesStore = null
    this.documentsStore = new DocumentsStore(RentalContractAgent)
    this.loader = new Loader(true)
    this.actionLoader = new Loader()
    this.editor = new Editor()
  }

  get contractId() {
    return this.contractInfoStore.id
  }
}

export default RentalContractPageStore
