import React from "react"
import { declineByNumber } from "kui-utils"
import { ObjectsNeedInitialMapProps } from "./types"
import ObjectsMap from "../ObjectsMap"
import { ObjectNeedInitialParams } from "../../../types/cards/apartments"

const ObjectsNeedInitialMap = (props: ObjectsNeedInitialMapProps) => {
  const getLabel = (inspection: ObjectNeedInitialParams) =>
    declineByNumber(Number(inspection.daysWithoutInitial), [
      "day",
      "days",
      "days",
    ])

  return <ObjectsMap {...props} getLabel={getLabel} />
}

export default ObjectsNeedInitialMap
