import React from "react"
import { observer } from "mobx-react"
import ApartmentOwnerFields from "../ApartmentOwnerFields"
import { ApartmentOwnersFieldsProps } from "./types"

function ApartmentOwnersFields(props: ApartmentOwnersFieldsProps) {
  const { fields, ...other } = props

  return (
    <>
      <ApartmentOwnerFields key={fields[0]?.key} index={0} {...other} />
      {fields.slice(1)?.map((owner, index) => (
        <ApartmentOwnerFields
          key={fields[index + 1].key}
          index={index + 1}
          {...other}
        />
      ))}
    </>
  )
}

export default observer(ApartmentOwnersFields)
