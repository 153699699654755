import * as React from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import styled from "@emotion/styled"
import { Button, Grid } from "kui-basic"
import { PlusIcon } from "kui-icon"
import { FormProps } from "../../../../../../../types/ui/form"
import ApartmentPaymentFields from "../../../components/tabs/ExpensesSummaryTab/ExpensesPaymentFields"
import { PaymentFormFields } from "../../../components/tabs/ExpensesSummaryTab/ExpensesPaymentFields/types"
import PaymentCreationFormSchema from "./schema"

function PaymentCreationForm({ handleSubmit, handleClick }: FormProps) {
  const form = useForm<PaymentFormFields>({
    resolver: yupResolver(PaymentCreationFormSchema),
  })

  return (
    <StyledForm
      data-testid="new_payment_form"
      onSubmit={form.handleSubmit(handleSubmit)}
    >
      <Grid container wrap="nowrap">
        <ApartmentPaymentFields withDefaultInvoice form={form} />
        <StyledButtonsWrapper
          container
          alignItems="center"
          wrap="nowrap"
          spacing={2}
        >
          <Grid item>
            <Button
              data-testid="cancel_create_btn"
              variant="grayLight"
              onClick={handleClick}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              data-testid="create_payment_btn"
              startIcon={<PlusIcon />}
              type="submit"
            >
              Add
            </Button>
          </Grid>
        </StyledButtonsWrapper>
      </Grid>
    </StyledForm>
  )
}

export default PaymentCreationForm

const StyledButtonsWrapper = styled(Grid)`
  width: auto;
  margin-left: auto;
  padding-right: 3px;
  button {
    padding: 0 24px;
  }
`

const StyledForm = styled.form`
  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
  }
`
