import React from "react"
import { Button } from "kui-basic"
import styled from "@emotion/styled"
import { CalendarIcon } from "kui-icon"
import { CalendarLabelProps, CalendarWithRangeButtonProps } from "./types"

const getButtonLabel = (props: CalendarLabelProps) => {
  const { isSelected, startDate, endDate } = props

  if (startDate && endDate) {
    if (startDate.diff(endDate, "days").days === 0) {
      return startDate.toFormat("dd.MM.yyyy")
    }
    return `${startDate.toFormat("dd.MM.yyyy")} - ${endDate.toFormat(
      "dd.MM.yyyy"
    )}`
  }
  if (isSelected) return "Current period"
  return "Period"
}

const CalendarWithRangeButton = (props: CalendarWithRangeButtonProps) => {
  const { isSelected, startDate, endDate, ...other } = props
  const label = getButtonLabel({ startDate, endDate, isSelected })

  return (
    <StyledButton
      startIcon={<CalendarIcon />}
      size="xs"
      variant="whiteWithGray"
      border={isSelected ? "brand" : "lightGray"}
      {...other}
    >
      {label}
    </StyledButton>
  )
}

export default CalendarWithRangeButton

const StyledButton = styled(Button)`
  border-radius: 8px;
`
