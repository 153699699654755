import React, { useRef, useState } from "react"
import styled from "@emotion/styled"
import { Button } from "kui-basic"
import { DotsIcon } from "kui-icon"
import { MenuButtonProps } from "./types"
import DropdownMenu from "../../ui/DropdownMenu"

function MenuButton({ options, disabled, ...otherProps }: MenuButtonProps) {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [isOpenDropdown, setIsOpenDropdown] = useState(false)

  const handleClick = () => {
    setIsOpenDropdown(!isOpenDropdown)
  }

  const handleClose = () => {
    setIsOpenDropdown(false)
  }

  return (
    <StyledWrapper {...otherProps}>
      <Button
        isCircle
        ref={buttonRef}
        size="xs"
        variant="transparent"
        onClick={handleClick}
        disabled={disabled}
        data-testid="menu_btn"
      >
        <StyledDotsIcon width={15} />
      </Button>
      <StyledDropdownMenu
        buttonRef={buttonRef}
        isOpen={isOpenDropdown}
        handleClose={handleClose}
        options={options}
      />
    </StyledWrapper>
  )
}

export default MenuButton

const StyledWrapper = styled.div`
  position: relative;
  text-align: right;
`

const StyledDotsIcon = styled(DotsIcon)`
  transform: rotate(90deg);
`

const StyledDropdownMenu = styled(DropdownMenu)`
  top: 45px;
  bottom: auto;
  width: 254px;
`
