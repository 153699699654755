const serviceHeadCells = [
  { id: "id", label: "ID", width: "5%" },
  { id: "folder_number", label: "Folder", width: "5%" },
  { id: "object", label: "Object", width: "23%" },
  { id: "period", label: "Period", width: "9%" },
  { id: "number", label: "Number", width: "12%" },
  { id: "price", label: "Price", width: "9%" },
  { id: "name", label: "Name", width: "17%" },
  { id: "report", label: "Report", width: "10%" },
  { id: "file", label: "File", width: "6%", withoutSort: true },
  { id: "", label: "", width: "4%", withoutSort: true },
]

export default serviceHeadCells
