import React from "react"
import { useToggle } from "kui-utils"
import { observer } from "mobx-react"
import { ServiceMenuCellProps } from "./types"
import MenuButton from "../../../../../../components/common/MenuButton"
import ServiceEditingModal from "../../../../../../components/modals/ServiceEditingModal"
import useRegistriesStore from "../../../../store"
import ServicesRegistryStore from "../../../../store/variants/ServicesRegistryStore"

function ServiceMenuCell({ service }: ServiceMenuCellProps) {
  const [isEditModalOpen, handleEditModalOpen, handleEditModalClose] =
    useToggle()
  const { activeRegistryStore } = useRegistriesStore()
  const servicesRegistry = activeRegistryStore as ServicesRegistryStore

  const handleDelete = async () => {
    await service.deleteService()
  }

  const handleEdit = async () => {
    await servicesRegistry.fetchByServiceId(service.id)
    handleEditModalOpen()
  }

  const duplicate = async () => {
    const { apartmentId } = service
    if (apartmentId) servicesRegistry.duplicateService(apartmentId, service.id)
  }

  const menuOptions = [
    { label: "Edit", handleClick: handleEdit },
    {
      label: "Copy to current period",
      handleClick: duplicate,
    },
    { label: "Delete", handleClick: handleDelete },
  ]

  return (
    <>
      <MenuButton options={menuOptions} />
      <ServiceEditingModal
        withApartment
        open={isEditModalOpen}
        handleClose={handleEditModalClose}
        service={service}
        isPeriodClosed={false}
        hasRentalContract={service.apartment?.hasRentalContract}
      />
    </>
  )
}

export default observer(ServiceMenuCell)
