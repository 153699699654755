import { useState } from "react"

type useModalOpenProps = () => [
  isOpen: boolean,
  handleOpen: () => void,
  handleClose: () => void
]

const useModalOpen: useModalOpenProps = () => {
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return [isOpen, handleOpen, handleClose]
}

export default useModalOpen
