import { makeAutoObservable } from "mobx"
import HostStore from "../../../../../store/Root"
import ApartmentPageStore from "../../../store/ApartmentPageStore"
import PromotionContentStore from "./content/PromotionContentStore"
import PromotionChannelsStore from "./channels/PromotionChannelsStore"
import PromotionPredictorStore from "./predictor/PromotionPredictorStore"

class PromotionPageStore {
  contentStore: PromotionContentStore

  channelsStore: PromotionChannelsStore

  predictorStore: PromotionPredictorStore

  apartmentStore: ApartmentPageStore

  private hostStore: HostStore

  constructor(hostStore: HostStore, apartmentStore: ApartmentPageStore) {
    this.hostStore = hostStore
    this.apartmentStore = apartmentStore
    this.contentStore = new PromotionContentStore(this.apartmentStore)
    this.channelsStore = new PromotionChannelsStore(this.apartmentStore, this)
    this.predictorStore = new PromotionPredictorStore(this.apartmentStore)
    makeAutoObservable(this)
  }

  updatePromotionPage = () => {
    this.contentStore = new PromotionContentStore(this.apartmentStore)
  }

  get descriptionStore() {
    return this.apartmentStore.descriptionStore
  }

  get overviewStore() {
    return this.apartmentStore.overviewStore
  }

  get editor() {
    return this.apartmentStore.editor
  }
}

export default PromotionPageStore
