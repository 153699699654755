import * as yup from "yup"

const NewApartmentFormSchema = yup.object().shape(
  {
    apartment: yup.object({
      address: yup
        .string()
        .required("This field is required")
        .test(
          "check-latitude-longitude",
          "Please select an address from the dropdown",
          function (value) {
            const { lat, lon } = this.parent.location
            return !!(lat && lon)
          }
        ),
      location: yup.object({
        lat: yup.number(),
        lon: yup.number(),
      }),
    }),
    owner: yup.object({
      name: yup.string().required("This field is required"),
    }),
    floor: yup
      .number()
      .integer()
      .when("floor", {
        is: (value: number) => value,
        then: (rule) => rule.positive("Must be a positive"),
      })
      .transform((value) => (Number.isNaN(value) ? undefined : value)),
  },
  [["floor", "floor"]]
)

export default NewApartmentFormSchema
