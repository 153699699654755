import { rest } from "msw"
import { ApartmentInspectionModel } from "kui-crm_actions"
import { GetApartmentInspectionsResponse } from "../../../pages/ApartmentPage/types/api/apartmentInspectionsAPI"
import { inspectionMock, inspectionsMock } from "./data"

const apartmentInspectionsHandlers = [
  rest.get<GetApartmentInspectionsResponse | Error>(
    `${process.env.REACT_APP_API_URL}/apartments/:id/inspections`,
    (req, resp, ctx) => {
      const data = ctx.json(inspectionsMock)
      return resp(data)
    }
  ),
  rest.post<FormData, ApartmentInspectionModel | Error>(
    `${process.env.REACT_APP_API_URL}/apartments/:id/inspections`,
    (req, resp, ctx) => {
      const data = ctx.json(inspectionMock)
      return resp(data)
    }
  ),
  rest.patch<FormData, ApartmentInspectionModel | Error>(
    `${process.env.REACT_APP_API_URL}/apartments/:id/inspections/:inspection_id`,
    (req, resp, ctx) => {
      const data = ctx.json(inspectionMock)
      return resp(data)
    }
  ),
  rest.delete<undefined | Error>(
    `${process.env.REACT_APP_API_URL}/apartments/:id/inspections/:inspection_id`,
    (req, resp, ctx) => {
      const data = ctx.json(undefined)
      return resp(data)
    }
  ),
]

export default apartmentInspectionsHandlers
