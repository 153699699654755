import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { Loader } from "kui-utils"
import { UserBankInfoAgent } from "../../../agent/Users"
import { UserBankAccFields } from "../forms/ClientBankAccForm/types"
import DocumentsStore from "../../../store/templates/DocumentsStore"
import { UserBankAccModel } from "../types/api/clientAPI"
import { clearNotValidFields } from "../../../utils/service/mapper"
import { ClientBankAccParams } from "../types/store/clientBankAcc"

class ClientBankAccStore {
  loader: Loader

  actionLoader: Loader

  fields: ClientBankAccParams | null

  documentsStore: DocumentsStore

  constructor() {
    this.loader = new Loader()
    this.actionLoader = new Loader()
    this.documentsStore = new DocumentsStore(UserBankInfoAgent)
    this.fields = null
    makeAutoObservable(this)
  }

  fetchClientBankAcc = async (userId: number) => {
    this.loader.startLoading()

    const [err, res] = await to(UserBankInfoAgent.getById(userId))
    if (res && !err) {
      this.updateClientBankAcc(res)
    } else {
      this.loader.setError("fetch client bank info")
    }
    this.loader.endLoading()
  }

  patchClientBankInfo = async (userId: number, data: UserBankAccFields) => {
    this.actionLoader.startLoading("user info changes")

    await Promise.allSettled([
      this.patchClientBank(userId, data),
      this.documentsStore.patchDocuments(data.documents, userId),
    ])

    this.actionLoader.endLoading()
  }

  patchClientBank = async (userId: number, data: UserBankAccFields) => {
    const body = ClientBankAccStore.getPatchBankBody(data)

    const [err, res] = await to(UserBankInfoAgent.patchBankInfo(userId, body))
    runInAction(() => {
      if (res && !err) {
        this.updateClientBankAcc(res)
      } else {
        this.actionLoader.setError("patch client bank info")
      }
    })
  }

  updateClientBankAcc = (client: Partial<UserBankAccModel>) => {
    this.fields = ClientBankAccStore.getBankInfo(client)
  }

  static getBankInfo = (
    user: Partial<UserBankAccModel>
  ): ClientBankAccParams => ({
    fullName: user.receiver || "",
    ITN: user.TIN || "",
    bankName: user.bank_name || "",
    SWIFT: user.SWIFT || "",
    BIC: user.BIC || "",
    correspondentAccount: user.correspondent_account || "",
    account: user.account || "",
  })

  static getPatchBankBody = ({ bankInfo }: UserBankAccFields) =>
    clearNotValidFields({
      receiver: bankInfo?.fullName || null,
      account: bankInfo?.account || null,
      bank_name: bankInfo?.bankName || null,
      BIC: bankInfo?.BIC || null,
      correspondent_account: bankInfo?.correspondentAccount || null,
      TIN: bankInfo?.ITN || null,
      SWIFT: bankInfo?.SWIFT || null,
    })
}

export default ClientBankAccStore
