import * as React from "react"
import { Caption, Box, Grid } from "kui-basic"
import { TextWithStartIconProps } from "./types"

function TextWithStartIcon({ icon, text }: TextWithStartIconProps) {
  return (
    <Grid container alignItems="center" wrap="nowrap">
      <Grid item>
        <Box mr={1}>{icon}</Box>
      </Grid>
      <Grid item>
        <Caption size="xs" weight={500} color="fiftyP">
          {text}
        </Caption>
      </Grid>
    </Grid>
  )
}

export default TextWithStartIcon
