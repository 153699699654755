import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { Heading, Box } from "kui-basic"
import { LoaderState } from "kui-crm"
import useCompanyStore from "../../../store"
import Loading from "../../../../../components/common/Loading"
import CompanyTariffsTable from "./CompanyTariffsTable"
import Container from "../../../../../components/ui/Container"

function CompanyTariffsTab() {
  const { tariffsStore, overviewStore } = useCompanyStore()

  useEffect(() => {
    if (overviewStore?.id) {
      tariffsStore.fetchTariffs(overviewStore.id)
    }
  }, [overviewStore])

  if (tariffsStore.loader.isLoading) {
    return <Loading />
  }

  return (
    <>
      <Container>
        <Box pt={3} pb={2}>
          <Heading size="h4">Tariff groups</Heading>
        </Box>
      </Container>
      <CompanyTariffsTable
        title="Water"
        tariffs={tariffsStore.activeWaterTariffs}
        type="water"
      />
      <Box mt={2}>
        <CompanyTariffsTable
          title="Electricity"
          tariffs={tariffsStore.activeElectricityTariffs}
          type="electricity"
        />
      </Box>
      <Box mt={2}>
        <CompanyTariffsTable
          title="Gas"
          tariffs={tariffsStore.activeGasTariffs}
          type="gas"
        />
      </Box>
      <Box mt={2}>
        <CompanyTariffsTable
          title="Heating"
          tariffs={tariffsStore.activeHeatingTariffs}
          type="heating"
        />
      </Box>
      <Box mt={5}>
        <CompanyTariffsTable
          title="Deactivated"
          tariffs={tariffsStore.archiveTariffs}
          variant="disable"
        />
      </Box>

      <LoaderState loader={tariffsStore.actionLoader} />
    </>
  )
}

export default observer(CompanyTariffsTab)
