import { FormWrapper, InputFileWithVisibility } from "kui-crm"
import React from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { ActivateContractFormFields, ActivateContractFormProps } from "./types"
import ActivateContractFormSchema from "./schema"
import useResetForm from "../../../../hooks/useResetForm"

const ActivateContractForm = (props: ActivateContractFormProps) => {
  const { handleSubmit } = props
  const form = useForm<ActivateContractFormFields>({
    resolver: yupResolver(ActivateContractFormSchema),
  })

  useResetForm(form)

  return (
    <div data-testid="formWrapper">
      <FormWrapper form={form} onSubmit={handleSubmit} label="Confirm">
        <InputFileWithVisibility
          data-testid="contractDocument"
          form={form}
          label="Document"
          name="document"
        />
      </FormWrapper>
    </div>
  )
}

export default ActivateContractForm
