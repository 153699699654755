import * as yup from "yup"
import { checkCurrentDateOlderTargetDate } from "../../../../utils/service/schema"

const ApartmentDocumentFormSchema = yup.object().shape(
  {
    number: yup.string().required("This field is required"),
    file: yup.mixed().nullable(),
    startDate: yup.mixed().required("This field is required"),
    endDate: checkCurrentDateOlderTargetDate("endDate", "startDate").required(
      "This field is required"
    ),
  },
  [["endDate", "endDate"]]
)

export default ApartmentDocumentFormSchema
