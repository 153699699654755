import React from "react"
import { useForm } from "react-hook-form"
import { observer } from "mobx-react"
import { Grid } from "kui-basic"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormWrapper, InputByType } from "kui-crm"
import { ManagerValuationStepFields, ManagerValuationStepProps } from "./types"
import managerValuationFields from "./fields"
import ManagerValuationStepSchema from "./schema"
import useApartmentLiteStore from "../../../../../../../store/shared/apartment"

const ManagerValuationStep = ({ handleSubmit }: ManagerValuationStepProps) => {
  const { inspectionsStore } = useApartmentLiteStore()
  const formStore = inspectionsStore.creationForm

  const form = useForm<ManagerValuationStepFields>({
    defaultValues: {
      ...formStore.fields,
      advertisingPeriod: formStore.fields?.advertisingPeriod ?? 4,
    },
    resolver: yupResolver(ManagerValuationStepSchema),
  })

  const handleFormSubmit = (data: ManagerValuationStepFields) => {
    formStore.updateFormFields(data)
    handleSubmit()
  }

  return (
    <FormWrapper
      form={form}
      onSubmit={form.handleSubmit(handleFormSubmit)}
      withPrevStep
      handlePrevClick={formStore.prevStep}
      label="Create appraisal"
    >
      <Grid container spacing={2}>
        {managerValuationFields.map(({ size, ...field }) => (
          <Grid item xs={size || 12} key={field.name}>
            <InputByType form={form} {...field} />
          </Grid>
        ))}
      </Grid>
    </FormWrapper>
  )
}

export default observer(ManagerValuationStep)
