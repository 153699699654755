import React from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box } from "kui-basic"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { FormBottom } from "kui-crm"
import SCCommonFields from "../../components/SCCommonFields"
import SCAdditionalTermsFormSchema from "./schema"
import useSCsStore from "../../store"
import { ServiceContractAdditionalTermsFields } from "../CreateServiceContractForm/types"
import scAdditionalTermsGroupFields from "./fields"
import InputsModalGroupWithTitle from "../../../../components/common/InputsModalGroupWithTitle"

function SCAdditionalTermsForm() {
  const { creationForm } = useSCsStore()
  const { fields, nextStep, prevStep, updateFormFields } = creationForm

  const form = useForm({
    resolver: yupResolver(SCAdditionalTermsFormSchema),
    defaultValues: {
      ...fields,
      penaltyInPreparation: fields?.penaltyInPreparation ?? 15000,
      penaltyWithRental: fields?.penaltyWithRental ?? 100,
      notificationPeriod: fields?.notificationPeriod ?? 3,
    },
  })

  const handleSubmit = (data: ServiceContractAdditionalTermsFields) => {
    nextStep()
    updateFormFields(data)
  }

  return (
    <form data-testid="form_step_4" onSubmit={form.handleSubmit(handleSubmit)}>
      <SCCommonFields form={form} />

      <StyledFieldsWrapper pr={5} pl={5} mb={3}>
        {scAdditionalTermsGroupFields.map((fieldsGroup) => (
          <InputsModalGroupWithTitle
            key={fieldsGroup.title}
            form={form}
            {...fieldsGroup}
          />
        ))}
      </StyledFieldsWrapper>
      <FormBottom
        withPrevStep
        variant="next"
        handlePrevClick={prevStep}
        label="Next"
      />
    </form>
  )
}

export default observer(SCAdditionalTermsForm)

const StyledFieldsWrapper = styled(Box)`
  textarea:first-of-type {
    margin-top: 0;
  }
`
