import React from "react"
import { useForm } from "react-hook-form"
import { Grid } from "kui-basic"
import { Modal, FormWrapper, InputByType } from "kui-crm"
import comparableObjectFields from "../../../../../../../components/forms/apartment/InspectionCreationForm/variations/AppraisalInspectionForm/ComparableObjectStep/fields"
import { ComparableObjectModalProps } from "./types"
import { ComparableObjectFormFields } from "../../../../../../../components/forms/apartment/InspectionCreationForm/variations/AppraisalInspectionForm/ComparableObjectStep/types"

const ComparableObjectModal = (props: ComparableObjectModalProps) => {
  const { handleSubmit, ...other } = props
  const form = useForm<ComparableObjectFormFields>()

  return (
    <Modal title="Add comparable object" {...other}>
      <FormWrapper form={form} onSubmit={form.handleSubmit(handleSubmit)}>
        <Grid container spacing={2}>
          {comparableObjectFields.map((field) => (
            <Grid item xs={12} key={field.name}>
              <InputByType form={form} {...field} />
            </Grid>
          ))}
        </Grid>
      </FormWrapper>
    </Modal>
  )
}

export default ComparableObjectModal
