import React from "react"
import { Heading, Box } from "kui-basic"
import { ApartmentSummaryTableSectionProps } from "./types"
import TableRowExpanses from "../TableRowExpanses"

function ExpensesSummaryTableSection({
  lines,
  title,
}: ApartmentSummaryTableSectionProps) {
  if (!lines?.length) return null

  return (
    <div data-testid={title.toLowerCase()}>
      <Box mt={3} mb={3}>
        <Heading size="h4">{title}</Heading>
      </Box>
      {lines?.map((item) => (
        <TableRowExpanses key={`${item.title}_${item.id}`} line={item} />
      ))}
    </div>
  )
}

export default ExpensesSummaryTableSection
