import * as React from "react"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import { useEffect } from "react"
import { LoaderState } from "kui-crm"
import {
  ApartmentFillingCardProps,
  FillingCardStylesProps,
  FillingParams,
} from "./types"
import ApartmentFillingImages from "../ApartmentFillingImages"
import ApartmentFillingMenu from "../ApartmentFillingMenu"
import ApartmentFillingInfo from "../ApartmentFillingInfo"

function ApartmentFillingCard<T extends FillingParams>(
  props: ApartmentFillingCardProps<T>
) {
  const {
    filling,
    disabled,
    index,
    onDelete,
    onEdit,
    onDeletePhoto,
    onAddPhoto,
    onDuplicate,
    form,
  } = props

  useEffect(() => {
    window.dispatchEvent(new Event("calcPortalPosition"))
  }, [filling.images?.length])

  return (
    <StyledWrapper>
      <StyledCard
        withWarning={filling.check === false || !!filling.isMissing}
        withEditing={!!onEdit}
      >
        <ApartmentFillingInfo
          disabled={disabled}
          form={form}
          index={index}
          filling={filling}
        />
        <ApartmentFillingImages
          index={index}
          filling={filling}
          onDeletePhoto={onDeletePhoto}
          onAddPhoto={onAddPhoto}
          disabled={disabled}
        />
        {!!onEdit && (
          <ApartmentFillingMenu
            index={index}
            filling={filling}
            disabled={disabled}
            onEdit={onEdit}
            onDelete={onDelete}
            onDuplicate={onDuplicate}
          />
        )}
      </StyledCard>

      {"loader" in filling && <LoaderState loader={filling.loader} />}
    </StyledWrapper>
  )
}

export default observer(ApartmentFillingCard)

const StyledWrapper = styled.div`
  margin-bottom: 8px;
  &:last-of-type {
    margin-bottom: 0;
  }
`

const StyledCard = styled.div<FillingCardStylesProps>`
  padding: 20px ${({ withEditing }) => (withEditing ? 0 : 16)}px 20px 24px;
  border-radius: 8px;
  background: white;
  display: flex;
  align-items: center;
  border: ${({ theme, withWarning }) =>
    withWarning ? `1px solid ${theme.palette.red.fiftyP}` : "none"};
`
