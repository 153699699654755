import React from "react"
import { useNavigate } from "react-router-dom"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { Modal } from "kui-crm"
import ApartmentCreationForm from "../../forms/apartment/ApartmentCreationForm"
import { CreateApartmentModalProps } from "./types"
import { Routes } from "../../../types/route"
import { NewApartmentFormParams } from "../../forms/apartment/ApartmentCreationForm/types"

function ApartmentCreationModal(props: CreateApartmentModalProps) {
  const { open, handleClose, withRoute, handleSubmit } = props
  const navigate = useNavigate()

  const handleFormSubmit = async (data: NewApartmentFormParams) => {
    handleClose()
    const apartmentId = await handleSubmit(data)
    if (apartmentId && withRoute) {
      navigate(`${Routes.apartments}/${apartmentId}`)
    }
  }

  return (
    <StyledModal title="New object" open={open} handleClose={handleClose}>
      <ApartmentCreationForm handleSubmit={handleFormSubmit} />
    </StyledModal>
  )
}

ApartmentCreationModal.defaultProps = {
  withRoute: true,
}

export default observer(ApartmentCreationModal)

const StyledModal = styled(Modal)`
  h2 {
    padding-bottom: 12px;
  }
`
