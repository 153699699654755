import * as React from "react"
import { useEffect, useRef, useState } from "react"
import styled from "@emotion/styled"
import { Grid, Button } from "kui-basic"
import _ from "lodash"
import { PreviewImageCard } from "kui-crm"
import { PlusIcon } from "kui-icon"
import ImageCardWithBackdrop from "./ImageCardWithBackdrop"
import { AdaptiveImagesBlockProps } from "./types"

const imageWidth = 64

function AdaptiveImagesBlock({
  images,
  onAdd,
  onDelete,
  onClick,
  addBtnProps,
  disabled,
  ...otherProps
}: AdaptiveImagesBlockProps) {
  const ref = useRef<HTMLDivElement>(null)
  const [displayedImagesCount, setDisplayedImagesCount] = useState(0)
  const displayedImages = images.slice(0, displayedImagesCount - 2)
  const blurImages = images.slice(
    displayedImagesCount - 2,
    displayedImagesCount
  )
  const notDisplayedImagesCount = images.slice(displayedImagesCount - 1).length

  useEffect(() => {
    const handleResize = _.debounce(() => {
      setDisplayedImagesCount(
        Math.floor(Number(ref.current?.clientWidth) / imageWidth)
      )
    }, 16)

    window.addEventListener("resize", handleResize)
    handleResize()

    return () => window.removeEventListener("resize", handleResize)
  }, [ref])

  return (
    <Wrapper container ref={ref} spacing={2} {...otherProps}>
      {onAdd && (
        <Grid item>
          <StyledButton
            isCircle
            size="l"
            variant="grayLight"
            onClick={onAdd}
            disabled={disabled}
            {...addBtnProps}
          >
            <PlusIcon width={18} height={18} />
          </StyledButton>
        </Grid>
      )}
      {displayedImages.map((image, index) => (
        <Grid item key={image.smallImageUrl}>
          <StyledImageCard
            url={image.smallImageUrl}
            index={index}
            onClick={onClick}
            onDelete={onDelete}
            disabled={disabled}
          />
        </Grid>
      ))}
      {blurImages.length > 0 && notDisplayedImagesCount > 0 && (
        <Grid item>
          <ImageCardWithBackdrop
            url={blurImages[0].smallImageUrl}
            index={displayedImages.length}
            onClick={onClick}
          >
            +{notDisplayedImagesCount}
          </ImageCardWithBackdrop>
        </Grid>
      )}
      {blurImages.length > 0 && notDisplayedImagesCount === 0 && (
        <Grid item>
          <StyledImageCard
            url={blurImages[0].smallImageUrl}
            index={displayedImages.length}
            onClick={onClick}
            onDelete={onDelete}
          />
        </Grid>
      )}
    </Wrapper>
  )
}

export default AdaptiveImagesBlock

const Wrapper = styled(Grid)`
  overflow: hidden;
  flex-wrap: nowrap;
`

const StyledButton = styled(Button)`
  border-radius: 8px;
  box-shadow: none !important;
`

const StyledImageCard = styled(PreviewImageCard)`
  margin-top: 0;
`
