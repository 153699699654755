import React, { ChangeEvent } from "react"
import { Box, Grid } from "kui-basic"
import { observer } from "mobx-react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormBottom, InputByType } from "kui-crm"
import SCBasicInfoFormSchema from "./schema"
import SCCommonFields from "../../components/SCCommonFields"
import useSCsStore from "../../store"
import { ServiceContractBasicInfoFields } from "../CreateServiceContractForm/types"
import getSCBasicInfoFields from "./fields"
import { ContractPeriodTypes } from "../../../../types/common"

function SCBasicInfoForm() {
  const { creationForm } = useSCsStore()
  const { fields, nextStep, prevStep, updateFormFields } = creationForm

  const form = useForm<Partial<ServiceContractBasicInfoFields>>({
    resolver: yupResolver(SCBasicInfoFormSchema),
    defaultValues: {
      ...fields,
      owner: (fields?.owners?.[0] ||
        null) as ServiceContractBasicInfoFields["owner"],
    },
  })

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    updateFormFields({
      contractType: e.target.defaultValue as ContractPeriodTypes,
    })
  }

  const scBasicInfoFields = getSCBasicInfoFields(
    fields?.contractType,
    handleChange,
    fields?.owners
  )

  const handleSubmit = (data: Partial<ServiceContractBasicInfoFields>) => {
    nextStep()
    updateFormFields(data)
  }

  return (
    <form data-testid="form_step_2" onSubmit={form.handleSubmit(handleSubmit)}>
      <SCCommonFields form={form} />

      <Box mt={3} pr={5} pl={5} mb={3}>
        <Grid container spacing={2} alignItems="center">
          {scBasicInfoFields.map(({ size, ...field }) => (
            <Grid item xs={size || 6}>
              <InputByType form={form} {...field} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <FormBottom
        withPrevStep
        label="Next"
        handlePrevClick={prevStep}
        variant="next"
      />
    </form>
  )
}

export default observer(SCBasicInfoForm)
