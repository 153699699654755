import React from "react"
import { InputWithController } from "kui-complex"
import { InputWithAddressAutocompleteProps } from "./types"
import MapWrapper from "../../common/MapWrapper"
import useAddressAutocomplete from "../../../hooks/useAddressAutocomplete"

function InputWithAddressAutocomplete<T extends object>({
  form,
  name,
  handlePlaceSelect,
  onlyAddress,
  ...inputProps
}: InputWithAddressAutocompleteProps<T>) {
  const { inputRef } = useAddressAutocomplete(
    form,
    name,
    onlyAddress,
    handlePlaceSelect
  )

  return (
    <MapWrapper>
      <InputWithController
        name={onlyAddress ? name : `${name}.address`}
        form={form}
        ref={inputRef}
        placeholder=""
        {...inputProps}
      />
    </MapWrapper>
  )
}

InputWithAddressAutocomplete.defaultProps = {
  label: "Address",
}

export default InputWithAddressAutocomplete
