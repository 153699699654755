import React from "react"
import styled from "@emotion/styled"
import { Caption, Grid } from "kui-basic"
import { WarningIcon } from "kui-icon"

const ExpensesPeriodWarning = () => (
  <StyledWarningBillet container alignItems="center" wrap="nowrap">
    <StyledWarningIcon />
    <Caption size="xs" color="fourty">
      To add deposits or expenses, you need to close the previous period
    </Caption>
  </StyledWarningBillet>
)

export default ExpensesPeriodWarning

const StyledWarningBillet = styled(Grid)`
  padding: 12px 16px 12px 12px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
  max-width: 250px;
  margin-right: 16px;
`

const StyledWarningIcon = styled(WarningIcon)`
  margin-right: 8px;
  width: 18px;
  height: 18px;
  min-width: 18px;
  path {
    fill: ${({ theme }) => theme.palette.red.seventy};
  }
`
