import React from "react"
import { observer } from "mobx-react"
import { Grid } from "kui-basic"
import styled from "@emotion/styled"
import ClientEditButton from "../ClientEditButton"
import { ClientTabsSettingsProps } from "./types"
import CheckButton from "../../../../../components/common/CheckButton"
import useClientStore from "../../../store"

const ClientTabsSettings = ({ activeTab }: ClientTabsSettingsProps) => {
  const { checkServices } = useClientStore()

  return (
    <StyledGrid container alignItems="center">
      <CheckButton
        services={checkServices}
        dataPopupLabel="To check client you need to fill:"
      />
      {activeTab === 1 && <ClientEditButton />}
    </StyledGrid>
  )
}

export default observer(ClientTabsSettings)

const StyledGrid = styled(Grid)`
  width: auto;
`
