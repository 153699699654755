import React from "react"
import { Grid } from "kui-basic"
import { useWatch } from "react-hook-form"
import { InputByType } from "kui-crm"
import { MeterReadingValuesFieldsProps } from "./types"
import getMeterReadingValuesFields from "./fields"

const MeterReadingValuesFields = (props: MeterReadingValuesFieldsProps) => {
  const { form } = props
  const [resource, meter] = useWatch({
    control: form.control,
    name: ["resource", "meter"],
  })

  const fields = getMeterReadingValuesFields(resource, meter?.type)

  return (
    <Grid container spacing={2}>
      {fields.map((field) => (
        <Grid item xs={fields.length > 1 ? 6 : 12}>
          <InputByType form={form} {...field} />
        </Grid>
      ))}
    </Grid>
  )
}

export default MeterReadingValuesFields
