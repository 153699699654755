import { ApartmentInspectionModel } from "kui-crm_actions"
import { ApartmentInspectionListModel } from "kui-crm"

export const initialInspectionMock: ApartmentInspectionModel = {
  id: 1,
  date: "2021-10-20",
  inspection_type: "transfer",
  impairment: null,
  comment: "",
  appraisal: {
    id: 1,
    date: "2023-10-25",
    min_price: "50000",
    max_price: "60000",
  },
}

export const inspectionMock: ApartmentInspectionModel = {
  id: 2,
  date: "2022-01-15",
  inspection_type: "transfer",
  impairment: null,
  comment: "",
  appraisal: {
    id: 1,
    date: "2023-10-25",
    min_price: "50000",
    max_price: "60000",
  },
  rental_contract: {
    id: 1,
    number: "12.0211.06.10",
    apartment: {
      id: 1,
      apartment_number: "4",
      address: "",
      main_image: null,
      object_name: "",
    },
  },
}

export const inspectionsMock = {
  count: 2,
  next: null,
  previous: null,
  next_inspection: "2024-06-15",
  frequency: 3,
  initial_inspection: initialInspectionMock,
  results: [inspectionMock, { ...inspectionMock, id: 3 }],
}

export const impairmentsMock: ApartmentInspectionListModel = {
  id: 1,
  date: "2024-10-05",
  inspection_type: "impairments",
  appraisal: null,
  impairment: null,
  based_on: null,
  owner_feedback: null,
  renter_feedback: null,
  conclusion: null,
}
