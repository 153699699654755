import React from "react"
import { useFieldArray } from "react-hook-form"
import { observer } from "mobx-react"
import { ApartmentMetersFieldsProps } from "./types"
import useInspectionStore from "../../store"
import ApartmentMeterFields from "./ApartmentMeterFields"
import Container from "../../../../../../components/ui/Container"

const ApartmentMetersFields = ({ form }: ApartmentMetersFieldsProps) => {
  const { editor } = useInspectionStore()
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "meters",
  })

  return (
    <Container>
      {fields.map((field, index) => (
        <ApartmentMeterFields
          key={field.id}
          index={index}
          resource={field.resource}
          form={form}
          append={append}
          remove={remove}
          disabled={!editor.isEditing}
        />
      ))}
    </Container>
  )
}

export default observer(ApartmentMetersFields)
