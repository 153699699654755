import { makeAutoObservable, runInAction } from "mobx"
import { Loader, Paginator, resHandler } from "kui-utils"
import to from "await-to-js"
import DashboardPageStore from "../DashboardPageStore"
import DashboardAgent from "../../../../agent/Dashboard"
import ApartmentsStore from "../../../../store/lites/ApartmentsStore"
import { NewObjectParams } from "../../types/cards/apartments"
import { NewContractModel } from "../../types/api/contracts"

class NewRentalContractsStore {
  value: number | null

  contracts: NewObjectParams[]

  paginator: Paginator

  loader: Loader

  dashboardStore: DashboardPageStore

  constructor(dashboardStore: DashboardPageStore) {
    this.value = null
    this.contracts = []
    this.paginator = new Paginator(5)
    this.loader = new Loader()
    this.dashboardStore = dashboardStore
    makeAutoObservable(this)
  }

  fetchContracts = async () => {
    this.loader.startLoading()

    const response = await to(
      DashboardAgent.getNewRentalContracts(
        this.paginator.limit,
        this.paginator.offset,
        this.dashboardStore.periodFilter
      )
    )

    runInAction(() => {
      resHandler(response, this.loader, (res) => {
        this.value = res.count
        this.contracts = this.paginator.getPageResponse(
          res,
          this.contracts,
          NewRentalContractsStore.getContractParams
        )
      })
    })
  }

  static getContractParams = (object: NewContractModel) => ({
    id: object.id,
    apartment: ApartmentsStore.getApartmentLinkParams(
      object.contract.apartment
    ),
    contract: {
      id: object.contract.id,
      number: object.contract.number,
    },
    rentPrice: Number(object.month_payment),
  })
}

export default NewRentalContractsStore
