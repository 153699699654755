import React from "react"
import { observer } from "mobx-react"
import EditButton from "../../../../../components/ui/EditButton"
import useClientStore from "../../../store"

function ClientEditButton() {
  const { editor } = useClientStore()

  return (
    <EditButton
      isEditing={editor.isEditing}
      onSaveHandler={editor.allowToSendForm}
      onEditHandler={editor.startEditing}
    />
  )
}

export default observer(ClientEditButton)
