import React from "react"
import { Grid } from "kui-basic"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import ApartmentOverviewInfoForm from "../../../../forms/overview/ApartmentOverviewInfoForm"
import DocumentsBlockWithForm from "../../../../../../components/common/DocumentsBlockWithForm"
import { ApartmentOverviewInfoFields } from "../../../../forms/overview/ApartmentOverviewInfoForm/types"
import ApartmentOverviewInfoSchema from "../../../../forms/overview/ApartmentOverviewInfoForm/schema"
import useApartmentStore from "../../../../store"

const ApartmentOverviewInfo = () => {
  const { overviewStore, editor } = useApartmentStore()

  const form = useForm<ApartmentOverviewInfoFields>({
    defaultValues: {
      locker: overviewStore.locker,
      folderNumber: overviewStore.folderNumber,
      paymentInfo: {
        communalServicesAccount: overviewStore.communalServicesAccount,
        company: overviewStore.managementCompany,
        payerCode: overviewStore.payerCode,
      },
      documents: overviewStore.documentsStore.documents || [],
    },
    resolver: yupResolver(ApartmentOverviewInfoSchema),
  })

  return (
    <Grid container spacing={3}>
      <Grid item xs={8}>
        <ApartmentOverviewInfoForm form={form} />
      </Grid>
      <StyledDocumentsWrapper item xs={4}>
        <DocumentsBlockWithForm
          withScroll
          form={form}
          disabled={!editor?.isEditing}
          documents={overviewStore.documentsStore.documents}
          loader={overviewStore.documentsStore.loader}
        />
      </StyledDocumentsWrapper>
    </Grid>
  )
}

export default observer(ApartmentOverviewInfo)

const StyledDocumentsWrapper = styled(Grid)`
  margin-top: 24px;
`
