import requests from "./index"
import { ClientModel } from "../types/api/clients"
import {
  PatchUserBankRequest,
  PatchUserEmploymentRequest,
  PatchUserParams,
  UserPersonalInfoModel,
} from "../pages/ClientPage/types/api/clientAPI"

const UserAgent = {
  getById: (id: number) => requests.get<ClientModel>(`/users/${id}`),
  patch: (id: number, data: PatchUserParams) =>
    requests.patch<ClientModel>(`/users/${id}`, data),
  delete: (id: number) => requests.delete(`/users/${id}`),
  getClientServices: (clientId: number) =>
    requests.get(`/users/${clientId}/checking`),
}

const UserPersonalInfoAgent = {
  getById: (id: number) => requests.get(`/users/${id}/personal`),
  patchPersonalInfo: (id: number, data: Partial<UserPersonalInfoModel>) =>
    requests.patch(`/users/${id}/personal`, data),
  getDocuments: (id: number) => requests.get(`/users/${id}/personal/documents`),
  postDocument: (data: FormData, id: number) =>
    requests.post(`/users/${id}/personal/documents`, data),
  deleteDocument: (documentId: number, userId: number) =>
    requests.delete(`/users/${userId}/personal/documents/${documentId}`),
  patchDocument: (documentId: number, data: FormData, userId: number) =>
    requests.patch(`/users/${userId}/personal/documents/${documentId}`, data),
}

const UserEmploymentInfoAgent = {
  getById: (id: number) => requests.get(`/users/${id}/employment`),
  patchEmploymentInfo: (
    id: number,
    data: Partial<PatchUserEmploymentRequest>
  ) => requests.patch(`/users/${id}/employment`, data),
  getDocuments: (id: number) =>
    requests.get(`/users/${id}/employment/documents`),
  postDocument: (data: FormData, id: number) =>
    requests.post(`/users/${id}/employment/documents`, data),
  deleteDocument: (documentId: number, userId: number) =>
    requests.delete(`/user/${userId}/employment/documents/${documentId}`),
  patchDocument: (documentId: number, data: FormData, userId: number) =>
    requests.patch(`/user/${userId}/employment/documents/${documentId}`, data),
}

const UserBankInfoAgent = {
  getById: (id: number) => requests.get(`/user/${id}/bank`),
  patchBankInfo: (id: number, data: Partial<PatchUserBankRequest>) =>
    requests.patch(`/user/${id}/bank`, data),
  getDocuments: (id: number) => requests.get(`/user/${id}/bank/documents`),
  postDocument: (data: FormData, id: number) =>
    requests.post(`/user/${id}/bank/documents`, data),
  deleteDocument: (documentId: number, userId: number) =>
    requests.delete(`/user/${userId}/bank/documents/${documentId}`),
  patchDocument: (documentId: number, data: FormData, userId: number) =>
    requests.patch(`/user/${userId}/bank/documents/${documentId}`, data),
}

export default UserAgent

export { UserPersonalInfoAgent, UserEmploymentInfoAgent, UserBankInfoAgent }
