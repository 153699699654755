import React, { SyntheticEvent } from "react"
import { Grid } from "kui-basic"
import styled from "@emotion/styled"
import { PlusIcon } from "kui-icon"
import { UploadButtonProps, UploadButtonStylesProps } from "./types"

const UploadButton = (props: UploadButtonProps) => {
  const { append, disabled } = props

  const appendImage = (e: SyntheticEvent<HTMLInputElement>) => {
    const uploadedFiles = (e.target as HTMLInputElement).files || []
    for (let i = 0; i < uploadedFiles.length; i += 1) {
      const file = uploadedFiles[i]
      const fileUrl = URL.createObjectURL(file)
      append({
        file,
        url: fileUrl,
        smallImageUrl: fileUrl,
        mediumImageUrl: fileUrl,
        largeImageUrl: fileUrl,
      })
    }
  }

  return (
    <Grid item>
      <StyledLabel disabled={disabled}>
        <StyledInput
          disabled={disabled}
          multiple
          type="file"
          onChange={appendImage}
        />

        <PlusIcon width={20} height={20} />
      </StyledLabel>
    </Grid>
  )
}

export default UploadButton

const StyledInput = styled.input`
  display: none;
`

const StyledLabel = styled.label<UploadButtonStylesProps>`
  border-radius: 8px;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.palette.background.light2};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  transition: all ease-out 0.3s;
  &:hover {
    background: ${({ theme, disabled }) =>
      disabled
        ? theme.palette.background.light2
        : theme.palette.background.light3};
  }
  path {
    fill: ${({ theme }) => theme.palette.grey.fiftyP};
  }
`
