import { ApartmentDocumentVariants } from "../../../../types/store/apartment"

const insuranceCellsWidth = ["23%", "18%", "15%", "15%", "15%", "9%", "5%"]

const proxyCellsWidth = ["23%", "21%", "18%", "18%", "15%", "5%"]

const getApartmentDocumentCellsWidth = (variant: ApartmentDocumentVariants) =>
  variant === "insurance" ? insuranceCellsWidth : proxyCellsWidth

export default getApartmentDocumentCellsWidth
