import React from "react"
import styled from "@emotion/styled"
import { Grid, Button } from "kui-basic"
import { observer } from "mobx-react"
import { useToggle } from "kui-utils"
import { PlusIcon } from "kui-icon"
import InputSearch from "../../ui/InputSearch"
import { TableSearchWithCreatingProps } from "./types"

function TableSearchWithCreating(props: TableSearchWithCreatingProps) {
  const { filter, paginator, children } = props
  const [isModalOpen, handleModalOpen, handleModalClose] = useToggle()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    filter.updateSearchQuery(e.currentTarget.value)
    if (e.target.value.length === 0) {
      paginator.clearOffset()
      paginator.startFetching()
    }
  }

  return (
    <Grid container alignItems="center" wrap="nowrap" justify="flex-end">
      <InputSearch value={filter.searchQuery} onChange={handleChange} />
      <StyledAddButton
        isCircle
        data-testid="open_modal"
        onClick={handleModalOpen}
        size="s"
        variant="orange"
      >
        <PlusIcon />
      </StyledAddButton>
      {children({
        open: isModalOpen,
        handleClose: handleModalClose,
      })}
    </Grid>
  )
}

export default observer(TableSearchWithCreating)

const StyledAddButton = styled(Button)`
  margin-left: 12px;
`
