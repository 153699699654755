import React from "react"
import { Button, ButtonProps, Grid, Heading } from "kui-basic"
import styled from "@emotion/styled"
import { CloseIcon, PlusIcon, UpdateIcon } from "kui-icon"
import { InputByType } from "kui-crm"
import { observer } from "mobx-react"
import { UpdatedFieldsWithTitleProps } from "./types"

function UpdatedFieldsWithTitle(props: UpdatedFieldsWithTitleProps) {
  const { title, fields, size, disabled, withoutButton, form } = props
  const { handleUpdate, handleAdd, handleRemove, editor } = props
  const buttonProps: Partial<ButtonProps> = {
    isCircle: true,
    size: "xs",
    variant: "whiteWithGray",
  }

  return (
    <StyledWrapper item xs={size || 12}>
      <StyledTitle container alignItems="center" justify="space-between">
        <Heading size="h4" weight={700}>
          {title}
        </Heading>
        <Grid item>
          <StyledButtonWrapper container alignItems="center">
            {!disabled && !withoutButton && (
              <>
                {handleUpdate && (
                  <Button onClick={handleUpdate} {...buttonProps}>
                    <UpdateIcon />
                  </Button>
                )}
                {handleAdd && (
                  <Button onClick={handleAdd} {...buttonProps}>
                    <PlusIcon width={10} height={10} />
                  </Button>
                )}
                {handleRemove && (
                  <Button
                    onClick={handleRemove}
                    {...buttonProps}
                    variant="grayLight"
                  >
                    <CloseIcon width={8} height={8} />
                  </Button>
                )}
              </>
            )}
          </StyledButtonWrapper>
        </Grid>
      </StyledTitle>

      <Grid container spacing={3}>
        {fields?.map(({ size: fieldSize, withGlobalEditor, ...field }) => (
          <Grid item xs={fieldSize || 12} key={field.name}>
            <InputByType
              disabled={withGlobalEditor ? disabled : !editor?.isEditing}
              form={form}
              {...field}
            />
          </Grid>
        ))}
      </Grid>
    </StyledWrapper>
  )
}

export default observer(UpdatedFieldsWithTitle)

const StyledTitle = styled(Grid)`
  margin-bottom: 16px;
  min-height: 34px;
`

const StyledWrapper = styled(Grid)`
  &:not(:first-child) {
    margin-top: 16px;
  }
`

const StyledButtonWrapper = styled(Grid)`
  > button:not(:last-child) {
    margin-right: 16px;
  }
`
