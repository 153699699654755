/** @jsxImportSource @emotion/react */
import React, { useState } from "react"
import { Grid } from "kui-basic"
import { observer } from "mobx-react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import styled from "@emotion/styled"
import { FormBottom, InputByType, MapComponent } from "kui-crm"
import MapWrapper from "../../../common/MapWrapper"
import { FormProps } from "../../../../types/ui/form"
import NewApartmentFormSchema from "./schema"
import { NewApartmentFormParams } from "./types"
import getApartmentFields from "./fields"
import useResetForm from "../../../../hooks/useResetForm"

function ApartmentCreationForm({ handleSubmit }: FormProps) {
  const [coords, setCoords] = useState<google.maps.LatLngLiteral>({
    lat: -1,
    lng: -1,
  })
  const form = useForm<NewApartmentFormParams>({
    resolver: yupResolver(NewApartmentFormSchema),
  })

  const fields = getApartmentFields(setCoords)

  useResetForm(form)

  return (
    <form data-testid="apartment_form">
      <Grid container>
        <StyledMapWrapper item xs={12}>
          <MapWrapper>
            <MapComponent
              center={
                coords.lat > 0 ? coords : { lat: 55.752626, lng: 37.614985 }
              }
              withMarker={coords.lat > 0}
              zoom={13}
            />
          </MapWrapper>
        </StyledMapWrapper>
        <StyledContainer item xs={12} container spacing={1}>
          {fields.map(({ size, ...field }) => (
            <Grid item xs={size || 6} key={field.name}>
              <InputByType form={form} {...field} />
            </Grid>
          ))}
        </StyledContainer>
      </Grid>
      <FormBottom
        label="Create object"
        type="button"
        handleClick={form.handleSubmit(handleSubmit)}
      />
    </form>
  )
}

export default observer(ApartmentCreationForm)

const StyledContainer = styled(Grid)`
  position: relative;
  top: -27px;
  padding: 0 40px;
`

const StyledMapWrapper = styled(Grid)`
  overflow: hidden;
  min-height: 108px;
`
