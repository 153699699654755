import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { Loader, Editor, resHandler } from "kui-utils"
import HostStore from "../../../store/Root"
import ClientOverviewStore from "./ClientOverviewStore"
import { EntityStoreInterface } from "../../../types/store/entity"
import UserAgent from "../../../agent/User"
import ClientPersonalInfoStore from "./ClientPersonalInfoStore"
import ClientEmploymentStore from "./ClientEmploymentStore"
import ClientBankAccStore from "./ClientBankAccStore"
import ClientCompanyInfoStore from "./ClientCompanyInfoStore"
import CheckServiceStore from "../../../store/templates/CheckServiceStore"
import { CheckServiceModel } from "../../../types/api/check_service"

class ClientPageStore implements EntityStoreInterface {
  loader: Loader

  actionLoader: Loader

  editor: Editor

  overviewStore: ClientOverviewStore | null

  personalInfoStore: ClientPersonalInfoStore

  companyInfoStore: ClientCompanyInfoStore

  employmentStore: ClientEmploymentStore

  bankStore: ClientBankAccStore

  checkServices: CheckServiceStore[]

  private hostStore: HostStore

  constructor(hostStore: HostStore) {
    this.loader = new Loader(true)
    this.actionLoader = new Loader()
    this.editor = new Editor()
    this.overviewStore = null
    this.hostStore = hostStore
    this.employmentStore = new ClientEmploymentStore()
    this.bankStore = new ClientBankAccStore()
    this.personalInfoStore = new ClientPersonalInfoStore(this)
    this.companyInfoStore = new ClientCompanyInfoStore(this)
    this.checkServices = []
    makeAutoObservable(this)
  }

  fetchClientById = async (id: number) => {
    this.loader.startLoading()

    const [err, res] = await to(UserAgent.getById(id))
    await this.fetchClientServices(id)

    runInAction(() => {
      if (res && !err) {
        this.overviewStore = new ClientOverviewStore(res)
      } else {
        this.loader.setError("fetch client", err)
      }
      this.loader.endLoading()
    })
  }

  fetchClientServices = async (clientId: number) => {
    const response = await to<CheckServiceModel[]>(
      UserAgent.getClientServices(clientId)
    )

    resHandler(
      response,
      this.loader,
      (resp) => {
        this.checkServices = resp.map(
          (service) =>
            new CheckServiceStore(service, `/users/${clientId}/checking`)
        )
      },
      "fetch client check services"
    )
  }

  deleteClient = async (id: number) => {
    this.actionLoader.startLoading("client removal")

    const [err] = await to(UserAgent.delete(id))

    if (err) {
      this.actionLoader.setError("client removal", err)
    }
    this.actionLoader.endLoading()
  }

  updateClientPage = () => {
    this.loader = new Loader(true)
    this.actionLoader = new Loader()
    this.editor = new Editor()
    this.overviewStore = null
  }
}

export default ClientPageStore
