import { EditIcon } from "kui-icon"
import React from "react"
import { observer } from "mobx-react"
import DashboardListCard from "../../common/DashboardListCard"
import { RejectedAppraisalsCardProps } from "./types"
import RejectedInspectionRow from "./RejectedAppraisalRow"
import RejectedAppraisalsArchive from "./RejectedAppraisalsArchive"

const RejectedAppraisalsCard = (props: RejectedAppraisalsCardProps) => {
  const { inspectionsStore } = props
  const { value, inspections, loader, paginator, fetchInspections } =
    inspectionsStore

  return (
    <DashboardListCard
      value={value}
      label="Rejected appraisals"
      icon={<EditIcon />}
      renderArchive={(isOpen) => (
        <RejectedAppraisalsArchive
          isOpen={isOpen}
          inspectionsStore={inspectionsStore}
        />
      )}
      loader={loader}
      paginator={paginator}
      fetchAll={fetchInspections}
    >
      {inspections.map((apartment) => (
        <RejectedInspectionRow key={apartment.id} inspection={apartment} />
      ))}
    </DashboardListCard>
  )
}

export default observer(RejectedAppraisalsCard)
