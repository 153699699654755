import { rest } from "msw"
import { ServiceContractModel } from "kui-crm/types"
import { PatchServiceContractParams } from "../../types/api/service_contracts"
import { serviceContractMock } from "./data"

const serviceContractsHandlers = [
  rest.get<ServiceContractModel | Error>(
    `${process.env.REACT_APP_API_URL}/service-contracts/:id`,
    (req, resp, ctx) => {
      const data = ctx.json(serviceContractMock)
      return resp(data)
    }
  ),
  rest.post<PatchServiceContractParams, ServiceContractModel | Error>(
    `${process.env.REACT_APP_API_URL}/service-contracts`,
    (req, resp, ctx) => {
      if (req.body.number && req.body.sign_date && req.body.start_date) {
        const data = ctx.json(serviceContractMock)
        return resp(data)
      }
      return resp(ctx.status(400))
    }
  ),
  rest.patch<PatchServiceContractParams, ServiceContractModel | Error>(
    `${process.env.REACT_APP_API_URL}/service-contracts/:id`,
    (req, resp, ctx) => {
      if (req.body.number && req.body.sign_date && req.body.start_date) {
        const data = ctx.json(serviceContractMock)
        return resp(data)
      }
      return resp(ctx.status(400))
    }
  ),
]

export default serviceContractsHandlers
