import React from "react"
import styled from "@emotion/styled"
import { Button, Grid } from "kui-basic"
import { UpdateIcon } from "kui-icon"
import { observer } from "mobx-react"
import { InspectionFeedbackStatusProps } from "./types"
import InspectionFeedbackBillet from "../InspectionFeedbackBillet"

const InspectionFeedbackStatus = (props: InspectionFeedbackStatusProps) => {
  const { feedback, label, resendLabel, resend } = props

  if (!feedback) return null

  return (
    <Grid item>
      <Grid container alignItems="center">
        <InspectionFeedbackBillet feedback={feedback} label={label} />
        {feedback.status === "rejected" && (
          <StyledButton
            size="xs"
            variant="orange"
            onClick={resend}
            endIcon={<UpdateIcon />}
          >
            {resendLabel}
          </StyledButton>
        )}
      </Grid>
    </Grid>
  )
}

export default observer(InspectionFeedbackStatus)

const StyledButton = styled(Button)`
  margin-left: 16px;
`
