import React from "react"
import { InputWithDatePicker as KUIInputWithDatePicker } from "kui-complex"
import { InputWithDatePickerProps } from "./types"

function InputWithDatePicker<TFormValues extends object>(
  props: InputWithDatePickerProps<TFormValues>
) {
  return (
    <KUIInputWithDatePicker footerLabel="Save" alignTitle="left" {...props} />
  )
}

InputWithDatePicker.defaultProps = {
  title: "Date",
  description: "",
  yearParams: { min: -10, max: 120 },
}

export default InputWithDatePicker
