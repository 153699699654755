import React, { useEffect, useState } from "react"
import { OptionParams } from "kui-complex/InputSelectBase"
import { inspectionsRequests } from "kui-crm_actions"
import { InspectionTypes } from "kui-crm"
import { observer } from "mobx-react"
import { InputSelectWithController, Loading } from "kui-complex"
import useApartmentLiteStore from "../../../../../store/shared/apartment"
import { ApartmentInspectionValues } from "../../../../../utils/content/listsOptions"
import { InspectionTypeFiledProps } from "./types"

const InspectionTypeFiled = (props: InspectionTypeFiledProps) => {
  const { form } = props
  const { inspectionsStore } = useApartmentLiteStore()
  const [inspectionTypesOptions, setInspectionTypesOptions] = useState<
    OptionParams[]
  >([])

  const getInspectionTypes = async () => {
    if (inspectionsStore.apartmentId) {
      const inspectionTypes = await inspectionsRequests.getInspectionTypes(
        inspectionsStore.apartmentId
      )

      if (inspectionTypes)
        setInspectionTypesOptions(
          ApartmentInspectionValues.filter((option) =>
            inspectionTypes?.includes(option.value as InspectionTypes)
          )
        )
    }
  }

  useEffect(() => {
    getInspectionTypes()
  }, [])

  return (
    <InputSelectWithController
      isRequired
      disabled={!inspectionTypesOptions.length}
      label="Inspection type"
      name="type"
      form={form}
      options={inspectionTypesOptions}
      endIcon={!inspectionTypesOptions.length && <Loading height="24px" />}
    />
  )
}

export default observer(InspectionTypeFiled)
