import React from "react"
import { observer } from "mobx-react"
import useDashboardStore from "../../store"
import HousingManagerDashboardCards from "./HousingManagerDashboardCards"
import DashboardPageWrapper from "../../components/common/DashboardPageWrapper"

const HousingManagerDashboard = () => {
  const { housingManagerDashboard } = useDashboardStore()

  return (
    <DashboardPageWrapper dashboardStore={housingManagerDashboard}>
      <HousingManagerDashboardCards />
    </DashboardPageWrapper>
  )
}

export default observer(HousingManagerDashboard)
