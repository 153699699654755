import React from "react"
import styled from "@emotion/styled"
import { Grid } from "kui-basic"
import { DateTime } from "luxon"
import { observer } from "mobx-react"
import { DashboardHeaderProps } from "../DashboardHeader/types"
import DashboardSettings from "../DashboardSettings"
import CalendarWithRange from "../CalendarWithRange"
import CalendarQuickButtons from "../CalendarQuickButtons"
import useDashboardStore from "../../../store"

const DashboardFilters = (props: DashboardHeaderProps) => {
  const { periodVariant, setStartDate, setEndDate, setPeriodVariant } =
    useDashboardStore()
  const isCalendarSelected = periodVariant === "calendar"

  const handleChange = (
    startDate: DateTime | null,
    endDate: DateTime | null
  ) => {
    setPeriodVariant("calendar")
    setStartDate(startDate)
    setEndDate(endDate)
  }

  return (
    <StyledWrapper container alignItems="center" spacing={1}>
      <CalendarQuickButtons />
      <CalendarWithRange
        isSelected={isCalendarSelected}
        onChange={handleChange}
      />
      <DashboardSettings {...props} />
    </StyledWrapper>
  )
}

export default observer(DashboardFilters)

const StyledWrapper = styled(Grid)`
  width: fit-content;
`
