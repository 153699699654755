import React from "react"
import { observer } from "mobx-react"
import ButtonSelect from "../../../../../../../components/ui/ButtonSelect"
import useExpensesStore from "../../../store"

function ExpensesContracts() {
  const { periodsStore } = useExpensesStore()
  const { selectedPeriod, availablePeriodsOptions, setSelectedPeriod } =
    periodsStore

  return (
    <ButtonSelect
      selectedValue={selectedPeriod?.id}
      options={availablePeriodsOptions}
      handleChange={setSelectedPeriod}
    />
  )
}

export default observer(ExpensesContracts)
