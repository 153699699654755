import React, { useState } from "react"
import { UploadPhotoModalProps } from "./types"
import UploadFilesModal from "../../modals/UploadFilesModal"
import { UploadFileFields } from "../../../types/common"

const UploadPhotoModal = (props: UploadPhotoModalProps) => {
  const { handleSubmit, ...other } = props
  const [count, setCount] = useState(0)

  const updateCount = (progress: number, total: number) => {
    setCount(total - progress)
  }

  const handleImagesSubmit = async (data: UploadFileFields) => {
    setCount(data.files.length)
    await handleSubmit(data, updateCount)
  }

  return (
    <UploadFilesModal
      handleSubmit={handleImagesSubmit}
      title="Upload photo"
      submitTitle="Upload photo"
      filesLeftCount={count}
      variant="image"
      {...other}
    />
  )
}

UploadPhotoModal.defaultProps = {
  reserveMessage: "add photos",
}

export default UploadPhotoModal
