import React from "react"
import { Button, Grid } from "kui-basic"
import styled from "@emotion/styled"
import { PlusIcon, MinusIcon } from "kui-icon"
import { InputCounterButtonsProps } from "./types"

const InputCounterButtons = (props: InputCounterButtonsProps) => {
  const { increment, decrement, disabled } = props

  return (
    <StyledWrapper container wrap="nowrap">
      <StyledButton
        isCircle
        variant="grayLight"
        onClick={increment}
        type="button"
        disabled={disabled}
      >
        <PlusIcon width={12} height={12} />
      </StyledButton>
      <StyledButton
        isCircle
        variant="grayLight"
        onClick={decrement}
        type="button"
        disabled={disabled}
      >
        <MinusIcon width={12} height={12} />
      </StyledButton>
    </StyledWrapper>
  )
}

export default InputCounterButtons

const StyledButton = styled(Button)`
  width: 24px;
  height: 24px;
  &:first-of-type {
    border-radius: 8px 0 0 8px;
    margin-right: 1px;
  }
  &:last-of-type {
    border-radius: 0 8px 8px 0;
  }
`

const StyledWrapper = styled(Grid)`
  width: fit-content;
`
