import React from "react"
import { observer } from "mobx-react"
import { LoaderState } from "kui-crm"
import useRCsStore from "./store"
import usePaginationPage from "../../hooks/usePaginationPage"
import RentalContractsTable from "./components/RentalContractsTable"

function RentalContractsPage() {
  const rcStore = useRCsStore()
  const { paginator, filter, fetchAll, loader, creationLoader } = rcStore

  usePaginationPage(paginator, fetchAll, filter)

  return (
    <>
      <RentalContractsTable />

      <LoaderState loader={creationLoader} />
      <LoaderState loader={loader} onlyError />
    </>
  )
}

export default observer(RentalContractsPage)
