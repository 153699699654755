import { rest } from "msw"
import {
  closedPeriodMock,
  lastOpenPeriodMock,
  openPeriodMock,
  periodIds,
} from "./data"
import { ApartmentExpensesPeriodModel } from "../../../../pages/ApartmentPage/pages/ExpensesPage/types/api/expensesPeriodAPI"
import { LastOpenPeriodModel } from "../../../../types/api/apartmentExpenses"

const apartmentPeriodHandlers = [
  rest.get<ApartmentExpensesPeriodModel | Error>(
    `${process.env.REACT_APP_API_URL}/apartments/:id/expenses/bill-periods`,
    (req, resp, ctx) => {
      const apartmentId = Number(req.params.id)
      if (apartmentId === periodIds.closed) {
        const data = ctx.json(closedPeriodMock)
        return resp(data)
      }
      const data = ctx.json(openPeriodMock)
      return resp(data)
    }
  ),
  rest.post<FormData, ApartmentExpensesPeriodModel | Error>(
    `${process.env.REACT_APP_API_URL}/apartments/:id/expenses/bill-periods/:period_id/close`,
    (req, resp, ctx) => {
      const data = ctx.json(closedPeriodMock)
      return resp(data)
    }
  ),
  rest.get<LastOpenPeriodModel | Error>(
    `${process.env.REACT_APP_API_URL}/apartments/:id/expenses/bill-periods/last-open`,
    (req, resp, ctx) => {
      const data = ctx.json(lastOpenPeriodMock)
      return resp(data)
    }
  ),
]

export default apartmentPeriodHandlers
