import { HeaderRouteParams, Routes } from "../types/route"

export const headerRoutes: HeaderRouteParams[] = [
  {
    title: "Dashboard",
    path: Routes.main,
    permission: "dashboardView",
  },
  {
    title: "Clients",
    path: Routes.clients,
    permission: "clientsView",
  },
  {
    title: "Objects",
    path: Routes.apartments,
    permission: "objectsView",
  },
  {
    title: "Companies",
    path: Routes.companies,
    permission: "companiesView",
  },
  {
    title: "Contracts",
    path: Routes.contracts,
    permission: "contractsView",
  },
  {
    title: "Functions",
    path: Routes.functions,
    permission: "functionsView",
  },
]
