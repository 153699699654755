import React from "react"
import { Box } from "kui-basic"
import { observer } from "mobx-react"
import ApartmentAutocomplete from "../../../../components/entityInputs/ApartmentAutocomplete"
import DotsContainer from "../../../../components/common/DotsContainerForFormSteps"
import useSCsStore from "../../store"
import { SCCommonFieldsProps } from "./types"
import { ApartmentLiteParams } from "../../../../types/store/apartments"

function SCCommonFields<T extends object>(props: SCCommonFieldsProps<T>) {
  const { form, onSelect } = props
  const { creationForm } = useSCsStore()

  const handleApartmentSelect = (apartment: ApartmentLiteParams | null) => {
    if (apartment) {
      if (apartment.folderNumber)
        creationForm.updateFormFields({ folderNumber: apartment.folderNumber })
      if (onSelect) onSelect(apartment)
    }
  }

  return (
    <>
      <Box pr={5} pl={5}>
        <ApartmentAutocomplete
          testId="apartment_autocomplete"
          form={form}
          defaultFilter="&with_service_contract=false"
          disabled={creationForm.step !== 1}
          onSelect={handleApartmentSelect}
        />
      </Box>
      {creationForm.step !== 1 && (
        <DotsContainer active={creationForm.step} dotsCount={6} />
      )}
    </>
  )
}

export default observer(SCCommonFields)
