import { rest } from "msw"
import {
  apartmentPayoutsWithAllPaymentsMock,
  apartmentPayoutsWithLandlordPaymentsMock,
  apartmentPayoutsWithTenantPaymentsMock,
} from "./data"
import { PayoutsModel } from "../../../../pages/ApartmentPage/pages/ExpensesPage/types/api/expensesSummaryAPI"
import { paymentIds } from "../apartmentPayments/data"

const apartmentPayoutsHandlers = [
  rest.get<PayoutsModel | Error>(
    `${process.env.REACT_APP_API_URL}/apartments/:id/expenses/bill-periods/:period_id/lines`,
    (req, resp, ctx) => {
      const apartmentId = Number(req.url.searchParams.get("id"))
      if (apartmentId === paymentIds.renter) {
        const data = ctx.json(apartmentPayoutsWithTenantPaymentsMock)
        return resp(data)
      }
      if (apartmentId === paymentIds.owner) {
        const data = ctx.json(apartmentPayoutsWithLandlordPaymentsMock)
        return resp(data)
      }
      const data = ctx.json(apartmentPayoutsWithAllPaymentsMock)
      return resp(data)
    }
  ),
]

export default apartmentPayoutsHandlers
