import to from "await-to-js"
import { makeAutoObservable, runInAction } from "mobx"
import { Loader, SortingFilter, Paginator } from "kui-utils"
import { ResourceTypes } from "kui-crm"
import { PaginationStoreInterface } from "../../../types/store/pagination"
import TariffAgent from "../../../agent/Tariff"
import { GetTariffCompaniesResponse } from "../types/api/tariffAPI"
import TariffCompanyStore from "./TariffCompanyStore"

class TariffCompaniesStore implements PaginationStoreInterface {
  loader: Loader

  filter: SortingFilter

  paginator: Paginator

  companies: TariffCompanyStore[]

  constructor() {
    this.loader = new Loader()
    this.filter = new SortingFilter()
    this.paginator = new Paginator()
    this.companies = []
    makeAutoObservable(this)
  }

  fetchTariffCompanies = async (tariffId: number, resource: ResourceTypes) => {
    this.loader.startLoading()

    const [err, res] = await to<GetTariffCompaniesResponse>(
      TariffAgent.getTariffCompanies(tariffId, resource)
    )
    runInAction(() => {
      if (!err && res) {
        this.companies = res.map((object) => new TariffCompanyStore(object))
      } else {
        this.loader.setError("fetch tariffs")
      }
      this.loader.endLoading()
    })
  }
}

export default TariffCompaniesStore
