import to from "await-to-js"
import { makeAutoObservable } from "mobx"
import { Loader, resHandler } from "kui-utils"
import { GetLockersResponse, LockerParams } from "kui-crm"
import ApartmentsAgent from "../../agent/Apartments"

class LockersStore {
  lockers: LockerParams[]

  loader: Loader

  constructor() {
    this.lockers = []
    this.loader = new Loader()
    makeAutoObservable(this)
  }

  fetchLockers = async () => {
    this.loader.startLoading()

    const response = await to<GetLockersResponse>(ApartmentsAgent.getLockers())

    resHandler(response, this.loader, (res) => {
      this.lockers = res.map((locker) => ({
        id: locker.id,
        name: locker.name,
        apartment: locker.apartment,
      }))
    })
  }
}

export default LockersStore
