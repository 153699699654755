import React from "react"
import { observer } from "mobx-react"
import { Grid } from "kui-basic"
import useSCStore from "../../store"
import { SCFieldsHeaderProps } from "./types"
import SCClosingButtons from "../SCClosingButtons"
import SCEditingButtons from "../SCEditingButtons"

const SCFieldsHeader = ({ form }: SCFieldsHeaderProps) => {
  const { contractInfoStore } = useSCStore()

  if (contractInfoStore.isClosed) {
    return null
  }

  return (
    <Grid container spacing={3} alignItems="center" justify="space-between">
      <SCClosingButtons />

      <SCEditingButtons form={form} />
    </Grid>
  )
}

export default observer(SCFieldsHeader)
