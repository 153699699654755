import React from "react"
import { ModalProps } from "kui-crm"
import useRegistriesStore from "../../../store"
import MetersReadingRegistryStore from "../../../store/variants/MetersReadingRegistryStore"
import MeterReadingModalKUI from "../../../../../components/modals/MeterReadingModal"

const MeterReadingModal = (props: ModalProps) => {
  const { activeRegistryStore } = useRegistriesStore()
  const metersStore = activeRegistryStore as MetersReadingRegistryStore

  return (
    <MeterReadingModalKUI
      createMeterReading={metersStore.createMeterReading}
      {...props}
    />
  )
}

export default MeterReadingModal
