import React from "react"
import { observer } from "mobx-react"
import PaginationPage from "../../../../../components/common/PaginationPage"
import useRegistriesStore from "../../../store"
import MetersReadingRegistryStore from "../../../store/variants/MetersReadingRegistryStore"
import SortingTable from "../../../../../components/common/SortingTable"
import meterReadingHeadCells from "./content"
import MeterReadingRegistryRow from "./MeterReadingRegistryRow"

const MetersReadingsRegistryTable = () => {
  const { activeRegistryStore } = useRegistriesStore()

  if (!activeRegistryStore) return null

  const { meters } = activeRegistryStore as MetersReadingRegistryStore

  return (
    <PaginationPage store={activeRegistryStore}>
      <SortingTable
        headParams={meterReadingHeadCells}
        store={activeRegistryStore}
        offset={188}
      >
        {meters?.map((meter, key) => (
          <MeterReadingRegistryRow
            key={meter.id}
            meter={meter}
            isDark={key % 2 !== 0}
          />
        ))}
      </SortingTable>
    </PaginationPage>
  )
}

export default observer(MetersReadingsRegistryTable)
