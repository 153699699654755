import React from "react"
import DatePicker, { ReactDatePickerCustomHeaderProps } from "react-datepicker"
import { Caption } from "kui-basic"
import { DateTime } from "luxon"
import { MonthPickerContainer } from "kui-complex"
import styled from "@emotion/styled"
import YearPickerHeader from "../CalendarYearHeader"

const CalendarYear = (props: ReactDatePickerCustomHeaderProps) => {
  const { changeYear, date, ...other } = props
  const label = DateTime.fromJSDate(date).toFormat("yyyy")

  const handleChange = (newDate: Date) => {
    changeYear(newDate.getFullYear())
  }

  return (
    <DatePicker
      selected={date}
      onChange={handleChange}
      customInput={<StyledLabel weight={600}>{label}</StyledLabel>}
      showYearPicker
      popperPlacement="bottom-end"
      calendarContainer={StyledYearPickerBody}
      renderCustomHeader={(headerProps: ReactDatePickerCustomHeaderProps) => (
        <YearPickerHeader {...headerProps} />
      )}
      {...other}
    />
  )
}

export default CalendarYear

const StyledLabel = styled(Caption)`
  cursor: pointer;
  width: fit-content;
`

const StyledYearPickerBody = styled(MonthPickerContainer)`
  .react-datepicker__year--container {
    width: 254px;
  }

  .react-datepicker__year-text {
    margin: 4px !important;
    /*width: auto !important;*/
    width: calc(100% / 3 - 8px) !important;
    height: 40px;
    position: relative;
    display: flex !important;
    justify-content: center;
    align-items: center;
    border-radius: 8px !important;
  }

  .react-datepicker__year-text:after {
    content: "";
    display: block;
    height: calc(100% + 8px);
    width: 1px;
    position: absolute;
    top: -4px;
    right: -4px;
    background: ${({ theme }) => theme.palette.grey.fifteenB};
  }

  .react-datepicker__year-text:before {
    content: "";
    display: block;
    height: 1px;
    width: calc(100% + 8px);
    position: absolute;
    bottom: -4px;
    left: -4px;
    background: ${({ theme }) => theme.palette.grey.fifteenB};
  }

  .react-datepicker__year-text:nth-child(3n):after {
    content: none;
  }

  .react-datepicker__year-text:nth-child(n + 10):before {
    content: none;
  }

  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: ${({ theme }) => theme.palette.brand.main} !important;
    border-radius: 8px !important;
    color: white !important;
  }
  .react-datepicker__year-wrapper {
    max-width: 100%;
    border-radius: 12px;
    border: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
  }
`
