import React from "react"
import { observer } from "mobx-react"
import useRegistriesStore from "../../../store"
import PaginationPage from "../../../../../components/common/PaginationPage"
import SortingTable from "../../../../../components/common/SortingTable"
import appraisalsHeadCells from "./content"
import LandlordAppraisalsRegistryStore from "../../../store/variants/LandlordAppraisalsRegistryStore"
import LandlordAppraisalRegistryRow from "./LandlordAppraisalRegistryRow"

const LandlordAppraisalsRegistryTable = () => {
  const { activeRegistryStore } = useRegistriesStore()

  if (!activeRegistryStore) return null

  const { appraisals } = activeRegistryStore as LandlordAppraisalsRegistryStore

  return (
    <PaginationPage store={activeRegistryStore}>
      <SortingTable
        headParams={appraisalsHeadCells}
        store={activeRegistryStore}
        offset={188}
      >
        {appraisals?.map((appraisal, key) => (
          <LandlordAppraisalRegistryRow
            key={appraisal.id}
            appraisal={appraisal}
            isDark={key % 2 !== 0}
          />
        ))}
      </SortingTable>
    </PaginationPage>
  )
}

export default observer(LandlordAppraisalsRegistryTable)
