import React from "react"
import { observer } from "mobx-react"
import SortingTable from "../../../../components/common/SortingTable"
import useTariffStore from "../../store"
import TariffCompanyRow from "../TariffCompanyRow"
import tariffCompaniesHeadCellsParams from "./headParams"

function TariffCompaniesTable() {
  const { companiesStore } = useTariffStore()

  return (
    <SortingTable
      headParams={tariffCompaniesHeadCellsParams}
      store={companiesStore}
      headVariant="grey"
      withFullLoading={false}
    >
      {companiesStore.companies.map((company, key) => (
        <TariffCompanyRow
          key={company.id}
          company={company}
          isDark={key % 2 !== 0}
        />
      ))}
    </SortingTable>
  )
}

export default observer(TariffCompaniesTable)
