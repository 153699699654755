import { InputFile } from "kui-complex"
import { LinkField } from "kui-crm"
import { InputParams } from "../../../../../../../components/common/InputsGroupWithTitle/types"
import { ComparableObjectFormFields } from "../../../../../../../components/forms/apartment/InspectionCreationForm/variations/AppraisalInspectionForm/ComparableObjectStep/types"

const getStatisticalValuationFields = (
  index: number
): InputParams<ComparableObjectFormFields>[] => [
  {
    label: "Name of data source",
    name: `statisticalValuations.${index}.dataSourceName`,
    isRequired: true,
  },
  {
    label: "Link of statistical valuation",
    name: `statisticalValuations.${index}.link`,
    variant: "custom",
    CustomInput: LinkField,
    isRequired: true,
  },
  {
    label: "Screenshot statistical valuation",
    name: `statisticalValuations.${index}.screenshot`,
    variant: "custom",
    CustomInput: InputFile,
    isRequired: true,
  },
  {
    label: "Min price",
    name: `statisticalValuations.${index}.minPrice`,
    variant: "mask",
    mask: Number,
    thousandsSeparator: " ",
    size: 6,
    cornerLabel: "₽",
    isRequired: true,
  },
  {
    label: "Max price",
    name: `statisticalValuations.${index}.maxPrice`,
    variant: "mask",
    mask: Number,
    thousandsSeparator: " ",
    size: 6,
    cornerLabel: "₽",
    isRequired: true,
  },
]

export default getStatisticalValuationFields
