import React, { useRef } from "react"
import { observer } from "mobx-react"
import { EstateAgentFieldProps } from "./types"
import EstateAgentsStore from "../../../store/lites/EstateAgentsStore"
import InputWithAutocomplete from "../../ui/InputWithAutocomplete"
import AutocompleteOption from "../../common/AutocompleteOption"
import { EstateAgentParams } from "../../../types/store/estateAgents"
import useAutocomplete from "../../../hooks/useAutocomplete"

const EstateAgentField = (props: EstateAgentFieldProps) => {
  const { form, name, ...otherProps } = props
  const estateAgentsStoreRef = useRef(new EstateAgentsStore())
  const { estateAgents, fetchEstateAgents, filter, loader, paginator } =
    estateAgentsStoreRef.current
  const { isLoading } = loader

  const { onSearchItem } = useAutocomplete(filter, paginator, fetchEstateAgents)

  const onSelectItem = (item: EstateAgentParams | null) => {
    if (item) {
      form.setValue(`${name}.id`, item?.id)
      form.setValue(`${name}.name`, item?.name, {
        shouldValidate: true,
      })
    }
  }

  return (
    <InputWithAutocomplete
      isRequired
      renderOption={(option: EstateAgentParams) => (
        <AutocompleteOption label={option.name || "Unknown user"} />
      )}
      isLoading={isLoading}
      options={estateAgents}
      label="Estate agent"
      form={form}
      name={`${name}.name`}
      hideName={`${name}.id`}
      onSelectItem={onSelectItem}
      onSearchItem={onSearchItem}
      {...otherProps}
    />
  )
}

EstateAgentField.defaultProps = {
  name: "estateAgent",
}

export default observer(EstateAgentField)
