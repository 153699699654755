import * as yup from "yup"
import { DateTime } from "luxon"

const checkCurrentDateOlderTargetDate = (
  currentName: string,
  targetName: string,
  label?: string
) =>
  yup.mixed().when(currentName, (value) => {
    if (value) {
      return yup
        .mixed()
        .test(
          currentName,
          `Can't be less than ${label || "Sign date"}`,
          function (startDate) {
            return (
              Number(
                (startDate as DateTime)
                  ?.diff(this.parent[targetName], "days")
                  .toObject().days
              ) >= 0
            )
          }
        )
    }
    return yup.mixed().notRequired()
  })

export { checkCurrentDateOlderTargetDate }
