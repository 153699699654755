import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { Paginator, SortingFilter, Loader, MultistepForm } from "kui-utils"
import {
  ResourceTypes,
  ElectricityMeterTypes,
  WaterMeterTypes,
  PaginationRes,
} from "kui-crm"

import { MeterLiteParams } from "../../types/store/meters"
import { MeterCreationFormFields } from "../../components/forms/meters/MeterCreationFormFields/types"
import {
  GetCommonMetersModel,
  GetCommonMetersResponse,
  MeterModel,
  PostCommonMeterParams,
  PostMeterRequest,
} from "../../types/api/meters"
import MetersAgent from "../../agent/Meters"
import { getCommonMeterParams } from "../../utils/service/apiMapper"
import { LiteMeterInterface } from "../../pages/ApartmentPage/pages/ExpensesPage/types/store/expensesMeters"

class MetersLiteStore {
  meters: MeterLiteParams[]

  replaceableMeters: LiteMeterInterface[]

  paginator: Paginator

  filter: SortingFilter

  loader: Loader

  actionLoader: Loader

  creationForm: MultistepForm<MeterCreationFormFields>

  apartmentResources: ResourceTypes[]

  constructor() {
    this.meters = []
    this.replaceableMeters = []
    this.loader = new Loader()
    this.actionLoader = new Loader()
    this.paginator = new Paginator()
    this.filter = new SortingFilter()
    this.creationForm = new MultistepForm<MeterCreationFormFields>()
    this.apartmentResources = []
    makeAutoObservable(this)
  }

  fetchAll = async (resource: ResourceTypes) => {
    this.loader.startLoading()

    const [err, res] = await to<GetCommonMetersResponse>(
      MetersAgent.all(
        resource,
        this.paginator.offset,
        this.paginator.limit,
        this.filter.filterParams
      )
    )
    runInAction(() => {
      if (!err && res) {
        const mapper = (meter: GetCommonMetersModel) =>
          MetersLiteStore.getMeterLiteParams(meter)

        this.meters = this.paginator.getPageResponse<
          GetCommonMetersModel,
          MeterLiteParams
        >(res, this.meters, mapper)
      } else {
        this.loader.setError("fetch meters")
      }
      this.loader.endLoading()
    })
  }

  fetchReplaceableMeters = async (
    apartmentId: number,
    resource: ResourceTypes,
    type?: ElectricityMeterTypes | WaterMeterTypes | null
  ) => {
    if (apartmentId) {
      this.loader.startLoading()

      const [, res] = await to<PaginationRes<MeterModel[]>>(
        MetersAgent.getReplaceableByApartmentId(
          apartmentId,
          resource,
          type && resource === "water" ? `&type=${type}` : ""
        )
      )
      runInAction(() => {
        if (res) {
          this.replaceableMeters = res.results.map((meter) => ({
            id: meter.id,
            number: meter.passport_number,
          }))
        } else {
          this.replaceableMeters = []
          this.loader.setError("fetch replaceable meters")
        }
        this.loader.endLoading()
      })
    }
  }

  fetchApartmentResources = async (apartmentId: number) => {
    this.actionLoader.startLoading()

    const [err, res] = await to<ResourceTypes[]>(
      MetersAgent.getApartmentResources(apartmentId)
    )

    runInAction(() => {
      if (!err && res) {
        this.apartmentResources = res
      } else {
        this.loader.setError("fetch apartment resources")
      }
      this.actionLoader.endLoading()
    })
  }

  createMeter = async (data: MeterCreationFormFields) => {
    this.actionLoader.startLoading()

    const body = MetersLiteStore.getCreationBody(data)

    const [err, res] = await to(MetersAgent.createMeter(body, data.resource!))

    if (!err && res) {
      this.meters = [MetersLiteStore.getMeterLiteParams(res), ...this.meters]
      this.paginator.clearOffset()
      this.paginator.startFetching()
    } else {
      this.actionLoader.setError("meter creation")
    }
    this.actionLoader.endLoading()

    return this.meters[0]
  }

  clearMeters = () => {
    this.meters = []
    this.replaceableMeters = []
    this.paginator.refresh()
    this.filter.updateSearchQuery("")

    this.loader = new Loader()
    this.actionLoader = new Loader()
    this.creationForm = new MultistepForm<MeterCreationFormFields>()
  }

  static getCreationBody = (data: MeterCreationFormFields) =>
    ({
      apartment: data.apartment?.id,
      ...(getCommonMeterParams(data) as Omit<
        PostCommonMeterParams,
        "apartment"
      >),
      ...MetersLiteStore.getMeterValues(data),
    } as PostMeterRequest)

  static getMeterValues = (data: MeterCreationFormFields) => {
    if (data.resource === "electricity") {
      return {
        values: {
          T1: data.initialValueT1!,
          T2: data.initialValueT2,
          T3: data.initialValueT3,
        },
        ...(data.replaceableCounter && {
          replaceable_counter: {
            counter: Number(data.replaceableCounter),
            T1: data.lastValueT1!,
            T2: data.lastValueT2,
            T3: data.lastValueT3,
          },
        }),
      }
    }

    return {
      initial_value: data.initialValue,
      ...(data.replaceableCounter && {
        replaceable_counter: {
          counter: Number(data.replaceableCounter),
          last_value: data.lastValue!,
        },
      }),
    }
  }

  static getMeterLiteParams = (meter: GetCommonMetersModel) => ({
    id: meter.id,
    type: meter.type,
    name: meter.passport_number,
  })
}

export default MetersLiteStore
