import React from "react"
import { Button, ButtonProps, Grid } from "kui-basic"
import { CloseIcon } from "kui-icon"

const ImpairmentDeleteButton = (props: ButtonProps) => (
  <Grid container justify="flex-end">
    <Button isCircle variant="whiteWithGray" size="s" {...props}>
      <CloseIcon width={10} height={10} />
    </Button>
  </Grid>
)

export default ImpairmentDeleteButton
