import React from "react"
import { Caption, Grid } from "kui-basic"
import { Tooltip } from "kui-crm"
import styled from "@emotion/styled"
import { CancelIcon, CheckIcon, CommentIcon } from "kui-icon"
import { InspectionFeedbackBilletProps } from "./types"
import { FeedbackStylesProps } from "../InspectionFeedbackStatus/types"

const InspectionFeedbackBillet = (props: InspectionFeedbackBilletProps) => {
  const { feedback, label } = props

  return (
    <StyledWrapper status={feedback.status}>
      <Grid container alignItems="center">
        <Caption size="s" weight={500} color="fiftyP">
          {label}
        </Caption>

        {feedback.status !== "notSigned" && (
          <StyledIconWrapper>
            {feedback.status === "signed" ? (
              <StyledCheckIcon />
            ) : (
              <StyledErrorIcon />
            )}
            {!!feedback.comment && (
              <Tooltip
                placement="bottom"
                content={
                  <div>
                    <Caption size="xs" color="fiftyP">
                      Comment
                    </Caption>
                    <StyledComment size="xs">{feedback.comment}</StyledComment>
                  </div>
                }
              >
                <StyledCommentIcon />
              </Tooltip>
            )}
          </StyledIconWrapper>
        )}
      </Grid>
    </StyledWrapper>
  )
}

export default InspectionFeedbackBillet

const StyledWrapper = styled.div<FeedbackStylesProps>`
  background: ${({ theme, status }) =>
    status === "notSigned"
      ? theme.palette.background.light1
      : theme.palette.grey.zero};
  border-radius: 66px;
  border: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
  padding: 6px 16px;
  width: fit-content;
`

const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
  > div {
    margin-left: 8px;
  }
`

const StyledCheckIcon = styled(CheckIcon)`
  path {
    fill: ${({ theme }) => theme.palette.green.fiftyP};
  }
`

const StyledErrorIcon = styled(CancelIcon)`
  width: 12px;
  height: 12px;
  path {
    fill: ${({ theme }) => theme.palette.red.fiftyP};
  }
`

const StyledComment = styled(Caption)`
  margin-top: 8px;
`

const StyledCommentIcon = styled(CommentIcon)`
  width: 13px;
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.palette.grey.zero};
    stroke: ${({ theme }) => theme.palette.grey.thirty};
  }
`
