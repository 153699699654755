import React from "react"
import styled from "@emotion/styled"
import { AppraisalTenantOfferStatusProps } from "./types"
import InspectionFeedbackIndicator from "../../../../../../../components/common/InspectionFeedbackStatus/InspectionFeedbackIndicator"
import appraisalOfferLabel from "./content"

const AppraisalTenantOfferStatus = (props: AppraisalTenantOfferStatusProps) => {
  const { offer } = props

  if (!offer) return null

  return (
    <StyledWrapper>
      <InspectionFeedbackIndicator
        feedback={offer}
        labels={appraisalOfferLabel}
      />
    </StyledWrapper>
  )
}

export default AppraisalTenantOfferStatus

const StyledWrapper = styled.div`
  margin-right: 8px;
`
