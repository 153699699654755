import React from "react"
import styled from "@emotion/styled"
import { Caption, Grid } from "kui-basic"
import { Avatar } from "kui-complex"
import { CommentBilletProps } from "./types"

function CommentBillet(props: CommentBilletProps) {
  const { avatar, name, date, children, endComponent } = props

  const isFileCreatedToday =
    Math.abs(Number(date?.diffNow(["hours"]).toObject().hours)) < 24
  const createdDate = isFileCreatedToday
    ? date?.setLocale("en").toRelative()
    : date?.toFormat("dd.MM.yyyy")

  return (
    <StyledCommentWrapper>
      <StyledContent>{children}</StyledContent>
      <StyledCommentInfoWrapper
        container
        alignItems="center"
        justify="space-between"
      >
        <Grid item>
          <Grid container alignItems="center">
            <Avatar size="xs" imageUrl={avatar} fullName={name} />
            <StyledCommentInfo size="s" color="fiftyP">
              {name || "Unknown"} • {createdDate}
            </StyledCommentInfo>
          </Grid>
        </Grid>
        <Grid item>{endComponent}</Grid>
      </StyledCommentInfoWrapper>
    </StyledCommentWrapper>
  )
}

export default CommentBillet

const StyledCommentWrapper = styled.div`
  background: ${({ theme }) => theme.palette.background.light2};
  border-radius: 1px 8px 8px 8px;
  padding: 20px 8px 12px 12px;
`

const StyledContent = styled.div`
  padding-bottom: 16px;
  border-bottom: 1px solid white;
`

const StyledCommentInfoWrapper = styled(Grid)`
  padding-top: 12px;
`

const StyledCommentInfo = styled(Caption)`
  margin-left: 12px;
`
