import { makeAutoObservable } from "mobx"
import { DateTime } from "luxon"
import { Loader, resHandler } from "kui-utils"
import { MeterTariffModel } from "kui-crm/types"
import {
  CounterInterface,
  CounterTariffValueInterface,
} from "../../types/store/expensesMeters"
import { PutMeterResponse } from "../../../../../../types/api/meters"

class ApartmentMeterTariffValues implements CounterTariffValueInterface {
  initialValue: number | string

  value: number | string

  prevMonthValue: number | string

  tariffValue: number | null

  editingDate: DateTime | null

  counterStore: CounterInterface | null

  title: string

  consumption: number

  loader: Loader

  totalPrice: number

  constructor(
    counter: MeterTariffModel,
    tariffValue: number | undefined | null,
    counterStore: CounterInterface | null,
    type?: string
  ) {
    this.initialValue = Number(counter.initial_value || 0)
    this.value = Number(counter.counter_value)
    this.editingDate = counter.edit_date
      ? DateTime.fromISO(counter.edit_date)
      : null
    this.prevMonthValue = Number(
      counter.previous_value || this.initialValue || 0
    )
    this.tariffValue = typeof tariffValue === "undefined" ? null : tariffValue
    this.counterStore = counterStore
    this.title = type || ""
    this.consumption = Number(counter.consumption)
    this.totalPrice = Number(counter.cost)
    this.loader = new Loader()
    makeAutoObservable(this)
  }

  postMeterValue = async (value: string, periodId: number) => {
    if (this.counterStore) {
      this.loader.startLoading("meter value changes")

      const response = await this.counterStore.postMeterValue(
        value,
        periodId,
        this.title
      )

      resHandler(
        response,
        this.loader,
        this.updateMeterTariff,
        "patch meter value"
      )
    }
  }

  updateMeterTariff = (data: PutMeterResponse) => {
    this.editingDate = DateTime.fromJSDate(new Date())
    this.value = Number(data.meter_value)
    this.updateMeterConsumption(Number(data.consumption))
    this.updateTotalPrice(Number(data.cost))
  }

  updateMeterConsumption = (consumption: number) => {
    this.consumption = consumption
  }

  setValue = (value: number) => {
    this.value = value
  }

  updateTotalPrice = (value: number) => {
    this.totalPrice = value
  }

  get values() {
    return {
      initial_value: this.initialValue,
      counter_value: this.value,
    }
  }

  updateMeter = (counter: Partial<MeterTariffModel>, tariffValue?: number) => {
    if (counter.initial_value) this.initialValue = Number(counter.initial_value)
    if (counter.counter_value) this.value = Number(counter.counter_value)
    if (counter.edit_date)
      this.editingDate = DateTime.fromISO(counter.edit_date)
    if (counter.previous_value)
      this.prevMonthValue = Number(counter.previous_value)
    if (tariffValue) this.tariffValue = Number(tariffValue)
    this.consumption = Number(counter.consumption)
  }
}

export default ApartmentMeterTariffValues
