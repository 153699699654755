import React from "react"
import { observer } from "mobx-react"
import EditButton from "../../../../components/ui/EditButton"
import useTariffStore from "../../store"

function TariffEditButton() {
  const { editor } = useTariffStore()

  return (
    <EditButton
      isEditing={editor.isEditing}
      onSaveHandler={editor.allowToSendForm}
      onEditHandler={editor.startEditing}
    />
  )
}

export default observer(TariffEditButton)
