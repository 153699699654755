import * as React from "react"
import { observer } from "mobx-react"
import { Modal, ModalProps } from "kui-crm"
import EditElectricityMeterForm from "../../../../forms/meters/EditElectricityMeterForm"
import useExpensesStore from "../../../../store"

function EditElectricityMeterModal({ open, handleClose }: ModalProps) {
  const { metersStore } = useExpensesStore()
  const electricityMeter =
    metersStore.electricityMetersStore.selectedElectricityMeter

  const handleSubmit = async (data: any) => {
    handleClose()
    if (electricityMeter?.id) {
      await electricityMeter.patchCounter(data)
    }
  }

  return (
    <Modal title="Edit electricity meter" open={open} handleClose={handleClose}>
      <EditElectricityMeterForm handleSubmit={handleSubmit} />
    </Modal>
  )
}

export default observer(EditElectricityMeterModal)
