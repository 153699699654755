import React from "react"
import { Caption, Box, Grid } from "kui-basic"
import ApartmentPhotoModalCard from "../ApartmentPhotoModalCard"
import { ApartmentPhotoModalBodyProps } from "./types"

function ApartmentPhotoModalBody(props: ApartmentPhotoModalBodyProps) {
  const { images, errorMessage } = props

  const changeActiveImage = (active: number) => {
    images.forEach((image) =>
      image.activeNumber > active
        ? image.setActiveNumber(image.activeNumber - 1)
        : null
    )
  }

  return (
    <Box pl={2} pr={2} pb={2}>
      <Grid container spacing={1}>
        {images.map((image) => (
          <ApartmentPhotoModalCard
            key={image.id}
            changeActiveImage={changeActiveImage}
            image={image}
          />
        ))}
      </Grid>

      {!!errorMessage && (
        <Box mt={1}>
          <Caption weight={500} size="s" colorGroup="red" color="fiftyP">
            {errorMessage}
          </Caption>
        </Box>
      )}
    </Box>
  )
}

export default ApartmentPhotoModalBody
