import requests from "./index"
import {
  CloseRentalContractRequest,
  DateOfLeavingRequest,
  PatchRentalContractParams,
} from "../types/api/rental_contract"
import { ActivateContractRequest } from "../types/api/contracts"

const RentalContractsAgent = {
  all: (offset: number, limit: number, params: string) =>
    requests.get(`/rental-contracts?offset=${offset}&limit=${limit}${params}`),
  getById: (id: number) => requests.get(`/rental-contracts/${id}`),
  create: (data: PatchRentalContractParams) =>
    requests.post("/rental-contracts", data),
  patch: (id: number, data: PatchRentalContractParams) =>
    requests.patch(`/rental-contracts/${id}`, data),
  delete: (id: number) => requests.delete(`/rental-contracts/${id}`),
  saveDateOfLeaving: (id: number, data: DateOfLeavingRequest) =>
    requests.post(`/rental-contracts/${id}/plan_leave`, data),
  close: (id: number, data: CloseRentalContractRequest) =>
    requests.post(`/rental-contracts/${id}/close`, data),
  getApartmentContracts: (apartmentId: number, year: number, month: number) =>
    requests.get(
      `/rental-contracts/lite?apartment_id=${apartmentId}&year=${year}&month=${month}`
    ),
  activateContract: (id: number, data: ActivateContractRequest) =>
    requests.post(`/rental-contracts/${id}/activate`, data),
}

export default RentalContractsAgent
