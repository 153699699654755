import React from "react"
import { observer } from "mobx-react"
import { LoaderState } from "kui-crm"
import ApartmentContractInspections from "../ApartmentContractInspections"
import ApartmentEvaluationInspections from "../ApartmentEvaluationInspections"
import InspectionsTabWrapper from "../InspectionsTabWrapper"
import { ApartmentInspectionsTableProps } from "./types"

function ApartmentInspectionsTable(props: ApartmentInspectionsTableProps) {
  const { inspectionsStore } = props
  const { inspectionsByContract } = inspectionsStore

  return (
    <InspectionsTabWrapper>
      <ApartmentEvaluationInspections {...props} />

      <div>
        {inspectionsByContract.map((store, index) => (
          <ApartmentContractInspections
            key={store.contract.number}
            inspectionsStore={store}
            index={index}
          />
        ))}
      </div>

      <LoaderState loader={inspectionsStore.loader} onlyError />
    </InspectionsTabWrapper>
  )
}

export default observer(ApartmentInspectionsTable)
