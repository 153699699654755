import React, { useEffect } from "react"
import { observer } from "mobx-react"
import EntityPage from "../../components/common/EntityPage"
import useClientStore from "./store"
import ClientHeader from "./components/common/ClientHeader"
import ClientOverviewHeader from "./components/common/ClientOverviewHeader"
import ClientTabsPanel from "./components/common/ClientTabsPanel"

function ClientPage() {
  const companyStore = useClientStore()

  useEffect(() => () => companyStore.updateClientPage(), [])

  return (
    <EntityPage store={companyStore} initPage={companyStore.fetchClientById}>
      <ClientHeader />
      <ClientOverviewHeader />
      <ClientTabsPanel />
    </EntityPage>
  )
}

export default observer(ClientPage)
