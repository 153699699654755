import React from "react"
import { Box, Grid } from "kui-basic"
import { InputByType } from "kui-crm"
import { CompanyInfoFieldsProps } from "./types"
import companyInfoFields from "./fields"

function CompanyInfoFields({ form, disabled }: CompanyInfoFieldsProps) {
  return (
    <Box mt={3}>
      <Grid container spacing={3}>
        {companyInfoFields.map((field) => (
          <Grid item xs={4} key={field.name}>
            <InputByType form={form} disabled={disabled} {...field} />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default CompanyInfoFields
