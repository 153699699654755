import React from "react"
import { observer } from "mobx-react"
import { MeterFeaturesFormFields, MeterFeaturesForm } from "kui-crm"
import { GasMeterFormProps } from "./types"
import useExpensesStore from "../../../store"

function GasMeterForm({
  handleClose,
  handleSubmit: onSubmit,
}: GasMeterFormProps) {
  const { metersStore, overviewStore } = useExpensesStore()
  const gasStore = metersStore.gasMetersStore
  const activeGasMeters = gasStore?.activeGasMeters
  const hasPayer = overviewStore.actualServiceContract?.metersPayers.gas
  const operatingAccount = overviewStore.operatingAccounts?.gas
  const isNewResource = gasStore?.gasMeters.length === 0 && !hasPayer

  const handleSubmit = (data: MeterFeaturesFormFields) => {
    const creationForm = gasStore?.creationForm
    if (creationForm) {
      onSubmit(data)
      creationForm.resetForm()
      handleClose()
    }
  }

  return (
    <MeterFeaturesForm
      resource="gas"
      formStore={gasStore?.creationForm}
      replacements={activeGasMeters}
      handleSubmit={handleSubmit}
      isNewResource={isNewResource}
      maxMeters={gasStore?.maxCountersCount}
      operatingAccount={operatingAccount}
      administrativeCompany={overviewStore.managementCompany}
      apartmentId={overviewStore.id}
    />
  )
}

export default observer(GasMeterForm)
