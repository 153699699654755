import React from "react"
import { Grid } from "kui-basic"
import styled from "@emotion/styled"
import { ImageCardProps, ImageSizesProps, ImageWrapperProps } from "./types"

function ImageWrapper({ height, children, ...otherProps }: ImageWrapperProps) {
  return <div {...otherProps}>{children}</div>
}

const StyledImageWrapper = styled(ImageWrapper)`
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  height: ${({ height }) => height};
  composes: ${({ className }) => className};
`

const Image = styled.img`
  min-width: 100%;
  width: 100%;
  height: 100%;
  cursor: pointer;
  object-fit: cover;
`

const ImageSizes: ImageSizesProps = {
  sm: { grid: 6, height: "94px" },
  md: { grid: 2, height: "196px" },
  lg: { grid: 3, height: "209px" },
}

function ImageCard({
  size,
  image,
  children,
  className,
  index,
  handleClick,
  ...otherProps
}: ImageCardProps) {
  const onClick = () => {
    if (handleClick) {
      handleClick(index)
    }
  }

  return (
    <Grid item xs={ImageSizes[size!].grid} onClick={onClick} {...otherProps}>
      <StyledImageWrapper
        className={className}
        height={ImageSizes[size!].height}
      >
        {image && (
          <Image
            src={size === "sm" ? image.smallImageUrl : image.mediumImageUrl}
            alt={image.name}
          />
        )}
        {children}
      </StyledImageWrapper>
    </Grid>
  )
}

ImageCard.defaultProps = {
  size: "lg" as const,
  children: null,
  image: null,
}

export default ImageCard
