import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { Loader } from "kui-utils"
import { ElectricityMeterTypes, WaterMeterTypes } from "kui-crm"
import { ResourceTypes, PaginationRes } from "kui-crm/types"
import WaterMetersStore from "./WaterMetersStore"
import ElectricityMetersStore from "./ElectricityMetersStore"
import GasMetersStore from "./GasMetersStore"
import { MetersStoreInterface } from "../types/store/meters"
import HeatingMetersStore from "./HeatingMetersStore"
import { MeterCreationFormFields } from "../../../components/forms/meters/MeterCreationFormFields/types"
import MetersAgent from "../../../agent/Meters"
import { LiteMeterInterface } from "../../ApartmentPage/pages/ExpensesPage/types/store/expensesMeters"
import { MeterModel } from "../../../types/api/meters"

class MetersStore {
  waterMetersStore: WaterMetersStore

  electricityMetersStore: ElectricityMetersStore

  gasMetersStore: GasMetersStore

  heatingMetersStore: HeatingMetersStore

  activeResource: ResourceTypes

  replaceableMeters: LiteMeterInterface[]

  loader: Loader

  actionLoader: Loader

  apartmentResources: ResourceTypes[]

  constructor() {
    this.waterMetersStore = new WaterMetersStore()
    this.electricityMetersStore = new ElectricityMetersStore()
    this.gasMetersStore = new GasMetersStore()
    this.heatingMetersStore = new HeatingMetersStore()
    this.activeResource = "electricity"
    this.replaceableMeters = []
    this.loader = new Loader()
    this.actionLoader = new Loader()
    this.apartmentResources = []
    makeAutoObservable(this)
  }

  createMeter = async (data: MeterCreationFormFields) => {
    await this.resource.createMeter(data)
  }

  fetchApartmentResources = async (apartmentId: number) => {
    this.actionLoader.startLoading()

    const [err, res] = await to<ResourceTypes[]>(
      MetersAgent.getApartmentResources(apartmentId)
    )

    runInAction(() => {
      if (!err && res) {
        this.apartmentResources = res
      } else {
        this.loader.setError("fetch apartment resources")
      }
      this.actionLoader.endLoading()
    })
  }

  fetchReplaceableMeters = async (
    apartmentId: number,
    resource: ResourceTypes,
    type?: ElectricityMeterTypes | WaterMeterTypes | null
  ) => {
    if (apartmentId) {
      this.loader.startLoading()

      const [, res] = await to<PaginationRes<MeterModel[]>>(
        MetersAgent.getReplaceableByApartmentId(
          apartmentId,
          resource,
          type && resource === "water" ? `&type=${type}` : ""
        )
      )
      runInAction(() => {
        if (res) {
          this.replaceableMeters = res.results.map((meter) => ({
            id: meter.id,
            number: meter.passport_number,
          }))
        } else {
          this.replaceableMeters = []
          this.loader.setError("fetch replaceable meters")
        }
        this.loader.endLoading()
      })
    }
  }

  setActiveResource = (resource: ResourceTypes) => {
    this.activeResource = resource
  }

  get resource(): MetersStoreInterface {
    return this[
      `${this.activeResource}MetersStore` as keyof MetersStore
    ] as MetersStoreInterface
  }
}

export default MetersStore
