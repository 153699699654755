import to from "await-to-js"
import { makeAutoObservable, runInAction } from "mobx"
import { Loader, MultistepForm, resHandler, callPromises } from "kui-utils"
import { FileBodyRequest, ImageBodyRequest } from "kui-crm"
import FillingAgent from "../../../../agent/Filling"
import {
  ApartmentFillingListModel,
  ApartmentFillingModel,
  PostApartmentFillingRequest,
} from "../../../ApartmentsPage/types/api/apartmentsAPI"
import ApartmentFillingStore from "./ApartmentFilling"
import { ApartmentFillingVariants } from "../../types/store/apartmentFilling"
import { FillingFormFields } from "../../forms/filling/types"
import {
  uploadImages,
  uploadNewFile,
} from "../../../../utils/agent/uploadFiles"

export const FillingFormStages = ["info", "photo", "manuals"] as const

class ApartmentFillingListStore {
  finish: ApartmentFillingStore[]

  furniture: ApartmentFillingStore[]

  electronics: ApartmentFillingStore[]

  accessories: ApartmentFillingStore[]

  loader: Loader

  actionLoader: Loader

  creatingForm: MultistepForm<FillingFormFields, typeof FillingFormStages>

  constructor() {
    this.finish = []
    this.furniture = []
    this.electronics = []
    this.accessories = []
    this.loader = new Loader()
    this.actionLoader = new Loader()
    this.creatingForm = new MultistepForm<
      FillingFormFields,
      typeof FillingFormStages
    >(null, FillingFormStages)
    makeAutoObservable(this)
  }

  fetchFilling = async (apartmentId: number) => {
    this.loader.startLoading()

    const response = await to<ApartmentFillingListModel>(
      FillingAgent.all(apartmentId)
    )
    runInAction(() => {
      resHandler(
        response,
        this.loader,
        this.updateFillingLists,
        "fetch filling"
      )
    })
  }

  createFilling = async (apartmentId: number, data: FillingFormFields) => {
    this.actionLoader.startLoading("filling creation")

    const uploaded = await callPromises([
      uploadImages(this.actionLoader, data.images),
      uploadNewFile(this.actionLoader, data.instruction),
    ])

    const body = ApartmentFillingListStore.getPostFillingBody(
      data,
      uploaded[0],
      uploaded[1]
    )

    const response = await to<ApartmentFillingModel>(
      FillingAgent.post(apartmentId, body)
    )

    resHandler(
      response,
      this.actionLoader,
      (resp) => {
        const type = data.type as ApartmentFillingVariants
        this.addFillingToList(resp, type)
      },
      "filling creation"
    )
  }

  duplicateFilling = async (
    apartmentId: number,
    fillingId: number,
    type: ApartmentFillingVariants
  ) => {
    this.actionLoader.startLoading("filling duplicate")

    const response = await to(FillingAgent.duplicate(apartmentId, fillingId))

    resHandler(
      response,
      this.actionLoader,
      (resp) => {
        this.addFillingToList(resp, type)
      },
      "filling duplicate"
    )
  }

  deleteFillingFromList = (
    fillingId: number,
    type: ApartmentFillingVariants
  ) => {
    this[type] = this[type].filter((filling) => filling.id !== fillingId)
  }

  addFillingToList = (
    resp: ApartmentFillingModel,
    type: ApartmentFillingVariants
  ) => {
    const newFilling = new ApartmentFillingStore(resp, type, this)
    this[type] = [...this[type], newFilling]
  }

  updateFillingLists = (res: ApartmentFillingListModel) => {
    this.finish = res.finish.map(
      (filling: any) => new ApartmentFillingStore(filling, "finish", this)
    )
    this.furniture = res.furniture.map(
      (filling: any) => new ApartmentFillingStore(filling, "furniture", this)
    )
    this.electronics = res.appliance.map(
      (filling: any) => new ApartmentFillingStore(filling, "electronics", this)
    )
    this.accessories = res.accessorie.map(
      (filling: any) => new ApartmentFillingStore(filling, "accessories", this)
    )
  }

  get fillingList() {
    return [
      ...this.finish,
      ...this.furniture,
      ...this.electronics,
      ...this.accessories,
    ]
  }

  static getPostFillingBody = (
    data: FillingFormFields,
    images: ImageBodyRequest[],
    file?: FileBodyRequest | null
  ) =>
    ({
      feature: data.tag.id,
      description: data.description || "",
      model: data.name || "",
      serial_code: data.serialNumber || "",
      amount: Number(data.numberOfSubjects),
      photos: images,
      instruction: file || null,
    } as PostApartmentFillingRequest)
}

export default ApartmentFillingListStore
