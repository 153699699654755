import React from "react"
import { observer } from "mobx-react"
import useApartmentStore from "../../../../store"
import ExpensesAttachments from "../../../common/ExpensesAttachments"

function ExpensesAttachmentsTab() {
  const { documentsStore, overviewStore } = useApartmentStore()
  const { expensesDocuments } = documentsStore

  if (!overviewStore.id || !expensesDocuments) return null

  return (
    <ExpensesAttachments
      clientsAttachments={expensesDocuments.clientsDocuments}
      internalAttachments={expensesDocuments.internalDocuments}
      apartmentId={overviewStore.id}
      date={documentsStore.date}
    />
  )
}

export default observer(ExpensesAttachmentsTab)
