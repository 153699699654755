import React from "react"
import { useFieldArray } from "react-hook-form"
import { Grid } from "kui-basic"
import { observer } from "mobx-react"
import { useToggle } from "kui-utils"
import { HeadingWithButton } from "kui-crm"
import { ComparableObjectsFieldsProps } from "./types"
import ComparableObjectFields from "../ComparableObjectFields"
import ComparableObjectModal from "../ComparableObjectModal"
import { ComparableObjectFormFields } from "../../../../../../../components/forms/apartment/InspectionCreationForm/variations/AppraisalInspectionForm/ComparableObjectStep/types"
import useInspectionStore from "../../../store"

const ComparableObjectsFields = ({ form }: ComparableObjectsFieldsProps) => {
  const { editor } = useInspectionStore()
  const [isModalOpen, handleModalOpen, handleModalClose] = useToggle()
  const { fields, remove, append } = useFieldArray({
    control: form.control,
    name: "comparableObjects",
  })
  const disabled = !editor.isEditing

  const addComparableObject = (data: ComparableObjectFormFields) => {
    append(data)
    handleModalClose()
  }

  return (
    <>
      <HeadingWithButton
        title="Comparative analysis"
        onClick={handleModalOpen}
        variant="primary"
        disabled={disabled}
      />
      <Grid container spacing={3}>
        {fields.map((field, index) => (
          <ComparableObjectFields
            key={field.id}
            index={index}
            form={form}
            remove={remove}
            disabled={disabled}
          />
        ))}
      </Grid>
      <ComparableObjectModal
        open={isModalOpen}
        handleClose={handleModalClose}
        handleSubmit={addComparableObject}
      />
    </>
  )
}

export default observer(ComparableObjectsFields)
