import * as yup from "yup"

const RentalContractTermsFormSchema = yup.object().shape({
  rentalPrice: yup
    .string()
    .required("This field is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  initialCommission: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .nullable(),
  securityPayment: yup
    .number()
    .min(0)
    .required("This field is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  paymentDate: yup
    .number()
    .min(1)
    .max(31)
    .required("This field is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  showing: yup
    .number()
    .min(0)
    .required("This field is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  installment: yup
    .number()
    .min(1)
    .max(2)
    .required("This field is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  penalty: yup
    .number()
    .min(0)
    .required("This field is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
})

export default RentalContractTermsFormSchema
