import React from "react"
import { useFieldArray } from "react-hook-form"
import _ from "lodash"
import ApartmentFillingList from "../../../../../../components/forms/apartmentFilling/FillingList"
import useInspectionStore from "../../store"
import { FillingFormFields } from "../../../../forms/filling/types"
import { FillingInfoFormFields } from "../../../../forms/filling/FillingInfoForm/types"
import { UploadFillingPhotoFields } from "../../../../forms/filling/UploadFillingPhoto/types"
import { FillingParams } from "../../../../../../components/forms/apartmentFilling/FillingList/ApartmentFillingCard/types"
import { InspectionFillingListProps } from "./types"

const InspectionEditableFillingList = (props: InspectionFillingListProps) => {
  const { form } = props
  const { editor } = useInspectionStore()
  const { fields, append, remove, update } = useFieldArray({
    control: form.control,
    name: "fillingList",
  })

  const handleAdd = (data: FillingFormFields) => {
    append(data)
  }

  const handleEdit = (
    data: FillingInfoFormFields,
    filling: FillingParams,
    index: number
  ) => {
    update(index, data)
  }

  const handleDuplicate = (filling: FillingParams) => {
    append(_.omit(filling, ["id"]))
  }

  const handleDelete = (filling: FillingParams, index: number) => {
    remove(index)
  }

  const deletePhoto = (
    filling: FillingParams,
    fillingIndex: number,
    imageIndex?: number
  ) => {
    if (typeof imageIndex === "number") {
      const images = fields[fillingIndex].images || []
      update(fillingIndex, {
        ...fields[fillingIndex],
        images: [
          ...images.slice(0, imageIndex),
          ...images.slice(imageIndex + 1),
        ],
      })
    }
  }

  const addPhoto = (
    filling: FillingParams,
    data: UploadFillingPhotoFields,
    index: number
  ) => {
    update(index, {
      ...fields[index],
      images: [...(fields[index].images || []), ...data.images],
    })
  }

  return (
    <ApartmentFillingList
      form={form}
      fillingList={fields}
      disabled={!editor.isEditing}
      onAdd={handleAdd}
      onEdit={handleEdit}
      onDuplicate={handleDuplicate}
      onDelete={handleDelete}
      onAddPhoto={addPhoto}
      onDeletePhoto={deletePhoto}
    />
  )
}

export default InspectionEditableFillingList
