import to from "await-to-js"

const longPolling = async <T>(
  callback: () => Promise<any>,
  errorHandler: (data: any) => void,
  stopCondition: (data: T) => boolean,
  delay: number
): Promise<T | null> => {
  const [err, res] = await to<T>(callback())

  if (err) {
    errorHandler(err)
    return null
  }
  if (stopCondition(res)) {
    return res
  }
  await new Promise((resolve) => {
    setTimeout(resolve, delay)
  })
  return longPolling(callback, errorHandler, stopCondition, delay)
}

export default longPolling
