import * as React from "react"
import { DepositIcon, PercentIcon, RubleIcon } from "kui-icon"
import { Grid } from "kui-basic"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import PurposeOfPayment from "./PurposeOfPayment"
import usePromotionStore from "../../../../../store"

const PurposeOfPayments = () => {
  const { contentStore } = usePromotionStore()
  const { descriptionStore } = contentStore

  return (
    <StyledWrapper container>
      <PurposeOfPayment
        icon={<RubleIcon width={30} height={30} />}
        title="Rental price"
        value={descriptionStore.rentPrice}
        variant="brand"
      />
      <PurposeOfPayment
        icon={<DepositIcon />}
        title="Security payment"
        value={descriptionStore.deposit}
        unit="%"
        withBorder
      />
      <PurposeOfPayment
        icon={<PercentIcon />}
        title="Initial commission"
        value={descriptionStore.commission}
        unit="%"
        withBorder
      />
    </StyledWrapper>
  )
}

export default observer(PurposeOfPayments)

const StyledWrapper = styled(Grid)`
  margin-bottom: 26px;
`
