import React from "react"
import _ from "lodash"
import styled from "@emotion/styled"
import TableRow from "../../../../../../components/ui/TableRow"
import { InspectionRegistryRowProps } from "./types"
import TableCell from "../../../../../../components/ui/TableCell"
import ApartmentLinkCell from "../../../../../../components/common/ApartmentLinkCell"
import LinkButton from "../../../../../../components/common/LinkButton"
import { DynamicRoutes } from "../../../../../../types/route"

const InspectionRegistryRow = (props: InspectionRegistryRowProps) => {
  const { inspection, isDark } = props
  const inspectionLink = DynamicRoutes.inspection(
    inspection.apartment.id,
    inspection.id
  )

  return (
    <TableRow isDark={isDark}>
      <TableCell isFirst textColor="gray">
        {inspection.id}
      </TableCell>
      <TableCell>{inspection.apartment?.folderNumber}</TableCell>
      <TableCell>
        <ApartmentLinkCell apartment={inspection.apartment} />
      </TableCell>
      <TableCell>
        <StyledLink href={inspectionLink}>
          {_.capitalize(inspection.type)} act
        </StyledLink>
      </TableCell>
      <TableCell>{_.capitalize(inspection.type)}</TableCell>
      <TableCell isLast>{inspection.date?.toFormat("dd.MM.yyyy")}</TableCell>
    </TableRow>
  )
}

export default InspectionRegistryRow

const StyledLink = styled(LinkButton)`
  min-width: 200px !important;
  > div {
    justify-content: space-between;
  }
  p {
    color: ${({ theme }) => theme.palette.grey.fiftyP} !important;
  }
`
