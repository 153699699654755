import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { LoaderState } from "kui-crm"
import PromotionDescription from "./PromotionDescription"
import PromotionPhoto from "./PromotionPhoto"
import usePromotionStore from "../../../store"
import Loading from "../../../../../../../components/common/Loading"

const PromotionContentTab = () => {
  const { contentStore, overviewStore } = usePromotionStore()

  useEffect(() => {
    if (overviewStore.id) contentStore.initPromotionContentTab()
  }, [overviewStore.id])

  if (contentStore.loader.isLoading) {
    return <Loading />
  }

  return (
    <>
      <PromotionDescription />
      <PromotionPhoto />

      <LoaderState loader={contentStore.loader} onlyError />
    </>
  )
}

export default observer(PromotionContentTab)
