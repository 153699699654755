import styled from "@emotion/styled"

const AccentBlock = styled.div`
  max-width: 701px;
  padding: 24px;
  background: ${({ theme }) => theme.palette.background.light1};
  border-radius: 16px;
  margin-bottom: 16px;
`

export default AccentBlock
