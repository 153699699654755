export const ownerDefaultFields = {
  member: null,
  typeOfShare: null,
  shareOfOwnership: "",
  isRegistered: null,
  ownershipTransferType: null,
  dateOfTransfer: null,
  ownershipTransferDocument: "",
  confirmationDocument: "",
  documentNumber: "",
  certificationAuthority: "",
  numberOfForm: "",
  dateOfIssue: null,
  dateOfEntry: null,
  registrationNumber: "",
}

export const proxyDefaultFields = {
  member: null,
  dateOfTransfer: null,
  confirmationDocument: "",
  certificationAuthority: "",
  documentNumber: "",
  numberOfForm: "",
  dateOfIssue: null,
  dateOfEntry: null,
  registrationNumber: "",
}
