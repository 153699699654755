import React from "react"
import { Caption } from "kui-basic"
import { ContractWithPriceRowProps } from "./types"
import RowWithApartment from "../RowWithApartment"
import { DynamicRoutes } from "../../../../../types/route"

const ContractWithPriceRow = (props: ContractWithPriceRowProps) => {
  const { contract, apartment, value } = props
  const contractLink = DynamicRoutes.contract(contract.id, "rental")
  const formattedValue = `${value.toLocaleString()} ₽`

  return (
    <RowWithApartment
      label={`#${contract.number}`}
      apartment={apartment}
      link={contractLink}
    >
      <Caption size="s" weight={600} color="fiftyP">
        {Number(value) > 0 ? `+ ${formattedValue}` : formattedValue}
      </Caption>
    </RowWithApartment>
  )
}

export default ContractWithPriceRow
