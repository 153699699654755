import {
  BillLinesModel,
  PayoutsModel,
} from "../../../../pages/ApartmentPage/pages/ExpensesPage/types/api/expensesSummaryAPI"
import { paymentOwnerMock, paymentRenterMock } from "../apartmentPayments/data"

export const renterBillMock: BillLinesModel = {
  payments: [],
  accruals: [
    {
      id: 15305,
      amount: "1500.00",
      type: "once",
      name: "Стекло",
      purpose: "Заключение",
      comment: "Жилец разбил стекло",
      invoice: null,
      payment_order: null,
    },
    {
      id: 15288,
      amount: "500.00",
      type: "period",
      name: "Интернет",
      purpose: "квитанция",
      comment: "",
      invoice: null,
      payment_order: null,
    },
    {
      id: 15387,
      amount: "52000.00",
      type: "rent",
      name: "Оплата найма",
      purpose: "Оплата найма",
      comment: "",
      invoice: null,
      payment_order: null,
    },
    {
      id: 15391,
      amount: "15600.00",
      type: "rent_commission",
      name: "Комиссия за подписание договора",
      purpose: "Комиссия за подписание договора",
      comment: "",
      invoice: null,
      payment_order: null,
    },
    {
      id: 15392,
      amount: "52000.00",
      type: "1stdeposit",
      name: "Депозит",
      purpose: "Депозит",
      comment: "",
      invoice: null,
      payment_order: null,
    },
    {
      id: 15393,
      amount: "423.34",
      type: "hot",
      name: "Расходы по счетчику: ГВС",
      purpose: "Расходы по счетчику: ГВС",
      comment: "",
      invoice: null,
      payment_order: null,
    },
    {
      id: 15395,
      amount: "87.14",
      type: "cold",
      name: "Расходы по счетчику: ХВС",
      purpose: "Расходы по счетчику: ХВС",
      comment: "",
      invoice: null,
      payment_order: null,
    },
    {
      id: 15397,
      amount: "128.08",
      type: "sewerage",
      name: "Расходы по счетчику: Водоотведение",
      purpose: "Расходы по счетчику: Водоотведение",
      comment: "",
      invoice: null,
      payment_order: null,
    },
    {
      id: 15399,
      amount: "65.10",
      type: "T1",
      name: "Расходы по счетчику: Электроснабжение T1",
      purpose: "Расходы по счетчику: Электроснабжение T1",
      comment: "",
      invoice: null,
      payment_order: null,
    },
    {
      id: 15401,
      amount: "13.92",
      type: "T2",
      name: "Расходы по счетчику: Электроснабжение T2",
      purpose: "Расходы по счетчику: Электроснабжение T2",
      comment: "",
      invoice: null,
      payment_order: null,
    },
  ],
  compensations: [],
  total: 122317.58,
}

export const ownerBillMock: BillLinesModel = {
  payments: [],
  accruals: [
    {
      id: 15244,
      amount: "4500.00",
      type: "open_ended",
      name: "Оплата квитанции ЖКХ",
      purpose:
        "Оплата за ФЛС №1454231234 за ноябрь 2022, адрес: Бойцовая улица д14 к7, без НДС",
      comment: "",
      invoice: null,
      payment_order: null,
    },
    {
      id: 15389,
      amount: "5200.00",
      type: "rent_commission",
      name: "Комиссия за пользование сервисом",
      purpose: "Комиссия за пользование сервисом",
      comment: "",
      invoice: null,
      payment_order: null,
    },
    {
      id: 15390,
      amount: "15600.00",
      type: "rent_commission",
      name: "Комиссия за подписание договора",
      purpose: "Комиссия за подписание договора",
      comment: "",
      invoice: null,
      payment_order: null,
    },
  ],
  compensations: [
    {
      id: 15388,
      amount: "52000.00",
      type: "income",
      name: "Оплата найма",
      purpose: "Оплата найма",
      comment: "",
      invoice: null,
      payment_order: null,
    },
    {
      id: 15394,
      amount: "423.34",
      type: "compensation_water",
      name: "Расходы по счетчику: ГВС",
      purpose: "Расходы по счетчику: ГВС",
      comment: "",
      invoice: null,
      payment_order: null,
    },
    {
      id: 15396,
      amount: "87.14",
      type: "compensation_water",
      name: "Расходы по счетчику: ХВС",
      purpose: "Расходы по счетчику: ХВС",
      comment: "",
      invoice: null,
      payment_order: null,
    },
    {
      id: 15398,
      amount: "128.08",
      type: "compensation_water",
      name: "Расходы по счетчику: Водоотведение",
      purpose: "Расходы по счетчику: Водоотведение",
      comment: "",
      invoice: null,
      payment_order: null,
    },
    {
      id: 15400,
      amount: "65.10",
      type: "compensation_electricity",
      name: "Расходы по счетчику: Электроснабжение T1",
      purpose: "Расходы по счетчику: Электроснабжение T1",
      comment: "",
      invoice: null,
      payment_order: null,
    },
    {
      id: 15402,
      amount: "13.92",
      type: "compensation_electricity",
      name: "Расходы по счетчику: Электроснабжение T2",
      purpose: "Расходы по счетчику: Электроснабжение T2",
      comment: "",
      invoice: null,
      payment_order: null,
    },
  ],
  total: 27417.58,
}

export const billMock = {
  id: 1,
  number: "khskdjsfsdf",
  invoice_date: "2023-10-15",
  renter_lines: renterBillMock,
  owner_lines: ownerBillMock,
}

export const apartmentPayoutsMock: PayoutsModel = {
  bills: [billMock],
  operating_expenses: [],
  period_id: 1,
  payments: [],
}

export const apartmentPayoutsWithAllPaymentsMock: PayoutsModel = {
  bills: [billMock],
  operating_expenses: [],
  period_id: 1,
  payments: [paymentRenterMock, paymentOwnerMock],
}

export const apartmentPayoutsWithTenantPaymentsMock: PayoutsModel = {
  bills: [billMock],
  operating_expenses: [],
  period_id: 1,
  payments: [paymentRenterMock],
}

export const apartmentPayoutsWithLandlordPaymentsMock: PayoutsModel = {
  bills: [billMock],
  operating_expenses: [],
  period_id: 1,
  payments: [paymentOwnerMock],
}
