import React from "react"
import styled from "@emotion/styled"
import { InspectionStatusProps } from "./types"
import InspectionFeedbackIndicator from "../../../../../../../components/common/InspectionFeedbackStatus/InspectionFeedbackIndicator"

const InspectionStatus = (props: InspectionStatusProps) => {
  const { statusParams } = props

  if (!statusParams || statusParams.status !== "notSigned") return null

  return (
    <StyledWrapper data-testid="inspectionStatus">
      <InspectionFeedbackIndicator feedback={statusParams} />
    </StyledWrapper>
  )
}

export default InspectionStatus

const StyledWrapper = styled.div`
  margin-right: 8px;
`
