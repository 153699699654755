import * as yup from "yup"
import { defaultRequiredMessage } from "../../../../utils/content/constants"

const EditContractFormSchema = yup.object().shape({
  date: yup.mixed().required(defaultRequiredMessage),
  file: yup.object({
    file: yup.mixed().required(defaultRequiredMessage),
  }),
})

export default EditContractFormSchema
