import { DropIcon } from "kui-icon"
import React from "react"
import { observer } from "mobx-react"
import DashboardListCard from "../../common/DashboardListCard"
import { MetersWithDeviatingCardProps } from "./types"
import MeterWithDeviatingRow from "./MeterWithDeviatingRow"
import MetersWithDeviatingArchive from "./MetersWithDeviatingArchive"

const MetersWithDeviatingCard = (props: MetersWithDeviatingCardProps) => {
  const { metersStore } = props
  const { value, meters, loader, paginator, fetchMeters } = metersStore

  return (
    <DashboardListCard
      value={value}
      label="Meters with deviating readings"
      icon={<DropIcon />}
      renderArchive={(isOpen) => (
        <MetersWithDeviatingArchive isOpen={isOpen} metersStore={metersStore} />
      )}
      loader={loader}
      paginator={paginator}
      fetchAll={fetchMeters}
    >
      {meters.map((meter) => (
        <MeterWithDeviatingRow key={meter.id} meter={meter} />
      ))}
    </DashboardListCard>
  )
}

export default observer(MetersWithDeviatingCard)
