import { CompanyAutocomplete } from "kui-crm"
import { FieldGroupsParams } from "../../../../../components/common/UpdatedFieldsGroup/types"
import { ApartmentOverviewPaymentInfoFields } from "./types"
import LockerAutocomplete from "../../../../../components/entityInputs/LockerAutocomplete"
import { InputParams } from "../../../../../components/common/InputsGroupWithTitle/types"

export const ApartmentInfoInputs: InputParams<any>[] = [
  { name: "folderNumber", label: "Folder number", size: 3 },
  {
    name: "locker",
    label: "Locker",
    variant: "custom",
    CustomInput: LockerAutocomplete,
    size: 3,
  },
]

const apartmentOverviewFieldsGroup: FieldGroupsParams<ApartmentOverviewPaymentInfoFields> =
  {
    title: "Payment info",
    fields: [
      {
        label: "Payer code",
        name: "paymentInfo.payerCode",
        placeholder: "XXXXXXXXXX",
        size: 6,
      },
      {
        label: "Operational account number",
        name: "paymentInfo.communalServicesAccount",
        placeholder: "XXXXXXXXXX",
        size: 6,
      },
      {
        label: "Administrative company",
        name: "paymentInfo.company",
        variant: "custom",
        CustomInput: CompanyAutocomplete,
        size: 6,
      },
    ],
  }

export const getApartmentPaymentInfoInputs = (
  apartmentId: number | null
): InputParams<any>[] => [
  {
    name: "payerCode",
    label: "Payer code",
    placeholder: "XXXXXXXXXX",
  },
  {
    name: "communalServicesAccount",
    label: "Operational account number",
    placeholder: "XXXXXXXXXX",
  },
  {
    name: "company",
    label: "Administrative company",
    variant: "custom",
    CustomInput: CompanyAutocomplete,
    forApartment: apartmentId,
  },
]

export default apartmentOverviewFieldsGroup
