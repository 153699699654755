import React from "react"
import { Caption, Grid } from "kui-basic"
import styled from "@emotion/styled"
import {
  ApartmentChartLegendProps,
  ApartmentChartLegendStylesProps,
} from "./types"

const ApartmentChartLegend = ({
  variant,
  label,
}: ApartmentChartLegendProps) => (
  <Grid item>
    <Grid container alignItems="center">
      <StyledLine variant={variant} />
      <Caption size="s" color="fiftyP">
        {label}
      </Caption>
    </Grid>
  </Grid>
)

export default ApartmentChartLegend

const StyledLine = styled.div<ApartmentChartLegendStylesProps>`
  width: 57px;
  height: 2px;
  margin-right: 16px;
  background: ${({ theme, variant }) => {
    const group = theme.palette[variant]
    return "main" in group ? group.main : group.seventy
  }};
`
