import React, { useEffect } from "react"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { LoaderState } from "kui-crm"
import { ApartmentContractInspectionProps } from "./types"
import ApartmentInspectionRow from "../ApartmentInspectionRow"
import useApartmentStore from "../../../../../store"
import Loading from "../../../../../../../components/common/Loading"
import ScrollingContainer from "../../../../../../../components/common/ScrollingContainer"
import AccordionWithSummary from "../../../../../../../components/common/AccordionWithSummary"

function ApartmentContractInspections(props: ApartmentContractInspectionProps) {
  const { inspectionsStore, index } = props
  const { overviewStore } = useApartmentStore()
  const { loader, inspections, fetchContractInspections, contract, paginator } =
    inspectionsStore

  useEffect(() => {
    if (paginator.isFetching && !paginator.isFinishPage && overviewStore.id) {
      fetchContractInspections(overviewStore.id)
    }
    if (paginator.isFetching && paginator.isFinishPage) {
      paginator.stopFetching()
    }
  }, [paginator, paginator.isFetching])

  const handleAccordionOpen = (expanded: boolean) => {
    if (expanded && index !== 0) {
      paginator.startFetching()
    }
  }

  useEffect(() => {
    if (index === 0) {
      paginator.startFetching()
    }
  }, [])

  return (
    <>
      <StyledAccordion
        title={`Rental contract ${contract.number}`}
        onChange={handleAccordionOpen}
        expanded={index === 0}
      >
        <StyledAccordionContent
          maxHeight={288}
          onScrollEnd={paginator.startFetching}
        >
          {inspections?.map((inspection) => (
            <ApartmentInspectionRow
              key={inspection.id}
              inspection={inspection}
            />
          ))}
          {loader.isLoading && paginator.offset !== 0 && (
            <Loading height="72px" />
          )}
        </StyledAccordionContent>
      </StyledAccordion>

      <LoaderState loader={loader} onlyError />
    </>
  )
}

export default observer(ApartmentContractInspections)

const StyledAccordionContent = styled(ScrollingContainer)`
  padding-top: 2px;
  max-height: 288px;
`

const StyledAccordion = styled(AccordionWithSummary)`
  padding: 16px 0;
  .KUI-Collapse {
    padding-bottom: 16px;
  }
`
