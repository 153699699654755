import React from "react"
import { LoaderState, Modal, ModalProps } from "kui-crm"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import InspectionCreationForm from "../../forms/InspectionCreationForm"
import useApartmentLiteStore from "../../../../../store/shared/apartment"

const InspectionModal = (props: ModalProps) => {
  const { open, handleClose } = props
  const { inspectionsStore } = useApartmentLiteStore()
  const inspectionType = inspectionsStore.creationForm.fields?.type
  const label = inspectionType ? `${inspectionType} ` : ""

  return (
    <StyledModal
      title={`New ${label}inspection`}
      open={open}
      handleClose={handleClose}
    >
      <InspectionCreationForm handleClose={handleClose} />
      <LoaderState loader={inspectionsStore.actionLoader} />
    </StyledModal>
  )
}

export default observer(InspectionModal)

const StyledModal = styled(Modal)`
  h2 {
    padding-bottom: 8px;
  }
`
