import React from "react"
import { observer } from "mobx-react"
import { LoaderState } from "kui-crm"
import usePromotionStore from "../../../../../store"

function ApartmentPhotoLoaders() {
  const {
    contentStore: { imagesStore },
  } = usePromotionStore()

  return (
    <>
      {imagesStore.images.map((image) => (
        <LoaderState loader={image.loader} />
      ))}
    </>
  )
}

export default observer(ApartmentPhotoLoaders)
