import React from "react"
import { Box, Button } from "kui-basic"
import { TrashIcon } from "kui-icon"
import { PhotoSliderHeaderOptionsProps } from "./types"
import { PhotoCardParams } from "../../PhotoCard/types"

const PhotoSliderHeaderOptions = <T extends PhotoCardParams>(
  props: PhotoSliderHeaderOptionsProps<T>
) => {
  const { image, slider, onDelete, index } = props

  if (!onDelete) return null

  const handleDelete = async () => {
    const swiper = slider.current?.swiper
    onDelete(image, index)
    if (swiper?.realIndex !== 0) {
      swiper?.slidePrev()
    }
  }

  return (
    <Box pr={2}>
      <Button
        size="s"
        variant="transparentWithBorder"
        endIcon={<TrashIcon />}
        onClick={handleDelete}
      >
        Delete
      </Button>
    </Box>
  )
}

export default PhotoSliderHeaderOptions
