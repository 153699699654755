import { makeAutoObservable } from "mobx"
import { DateTime } from "luxon"
import to from "await-to-js"
import {
  getPhoneNumberFromPhoneParams,
  getPhoneParamsFromString,
  toNumber,
  Loader,
} from "kui-utils"
import { PhoneParams } from "../../../types/common"
import { UserEmploymentFields } from "../forms/ClientEmploymentForm/types"
import { UserEmploymentInfoAgent } from "../../../agent/Users"
import DocumentsStore from "../../../store/templates/DocumentsStore"
import { UserEmploymentModel } from "../types/api/clientAPI"
import { clearNotValidFields } from "../../../utils/service/mapper"

class ClientEmploymentStore {
  loader: Loader

  actionLoader: Loader

  companyName: string = ""

  companyITN: string = ""

  startDate: DateTime | null = null

  position: string = ""

  income: number | null = null

  workPhone: PhoneParams | null = null

  workEmail: string = ""

  id: number | null = null

  documentsStore: DocumentsStore

  constructor() {
    this.loader = new Loader()
    this.actionLoader = new Loader()
    this.documentsStore = new DocumentsStore(UserEmploymentInfoAgent)
    makeAutoObservable(this)
  }

  fetchClientEmployment = async (userId: number) => {
    this.loader.startLoading()

    const [err, res] = await to(UserEmploymentInfoAgent.getById(userId))

    if (res && !err) {
      this.updateEmploymentStore(res)
    } else {
      this.loader.setError("fetch client employment", err)
    }
    this.loader.endLoading()
  }

  patchClientEmploymentInfo = async (
    userId: number,
    data: UserEmploymentFields
  ) => {
    this.actionLoader.startLoading("client info changes")

    await Promise.allSettled([
      this.patchClientEmployment(userId, data),
      this.documentsStore.patchDocuments(data.documents, userId),
    ])

    this.actionLoader.endLoading()
  }

  patchClientEmployment = async (
    userId: number,
    data: UserEmploymentFields
  ) => {
    const body = ClientEmploymentStore.getPatchEmploymentBody(data)

    const [err, res] = await to(
      UserEmploymentInfoAgent.patchEmploymentInfo(userId, body)
    )
    if (res && !err) {
      this.updateEmploymentStore(res)
    } else {
      this.actionLoader.setError("patch client employment", err)
    }
  }

  updateEmploymentStore = (user: Partial<UserEmploymentModel>) => {
    this.id = user.id ?? this.id
    this.companyName = user.company_name ?? this.companyName
    this.companyITN = user.company_itn ?? this.companyITN
    this.startDate = user.start_date
      ? DateTime.fromISO(user.start_date)
      : this.startDate
    this.position = user.position ?? this.position
    this.income = user.income ?? this.income
    this.workPhone = user.work_phone
      ? getPhoneParamsFromString(user.work_phone)
      : this.workPhone
    this.workEmail = user.work_email ?? this.workEmail
  }

  static getPatchEmploymentBody = (data: UserEmploymentFields) =>
    clearNotValidFields({
      company_name: data.companyName || null,
      company_itn: data.companyITN || null,
      start_date: data.startDate
        ? DateTime.fromJSDate(data.startDate).toISODate()
        : null,
      position: data.position || null,
      income: toNumber(data.income),
      work_phone: data.workPhone?.phoneNumber
        ? getPhoneNumberFromPhoneParams(data.workPhone)
        : null,
      work_email: data.workEmail || null,
    })
}

export default ClientEmploymentStore
