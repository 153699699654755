import * as React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { Caption, Grid, theme } from "kui-basic"
import {
  IconStylesProps,
  PurposeOfPaymentProps,
  WrapperStylesProps,
} from "./types"
import TypographyMoney from "../../../../../../../../../../components/common/TypographyMoney"

const PurposeOfPayment = (props: PurposeOfPaymentProps) => {
  const { icon, title, value, withBorder, variant, unit } = props

  return (
    <StyledWrapper item withBorder={!!withBorder}>
      <Grid container alignItems="center">
        <StyledIcon item variant={variant!}>
          {icon}
        </StyledIcon>
        <Grid item>
          <TitleStyles size="s" weight={500} color="fiftyP">
            {title}
          </TitleStyles>
          <ValueStyles variant={variant!}>
            <TypographyMoney weight={700} value={value} unit={unit} />
          </ValueStyles>
        </Grid>
      </Grid>
    </StyledWrapper>
  )
}

PurposeOfPayment.defaultProps = {
  withBorder: false,
  variant: "grey",
}

export default PurposeOfPayment

const withBorderStyles = ({ withBorder }: WrapperStylesProps) =>
  withBorder &&
  css`
    padding-left: 12px;
    border-left: 1px solid ${theme.palette.grey.fifteenB};
  `

const brandVariant = ({ variant }: IconStylesProps) =>
  variant === "brand" &&
  css`
    path {
      fill: ${theme.palette.brand.main};
    }
  `

const StyledWrapper = styled(Grid)<WrapperStylesProps>`
  box-sizing: border-box;
  width: 196px;
  ${withBorderStyles};
`

const StyledIcon = styled(Grid)<IconStylesProps>`
  padding-right: 16px;
  path {
    fill: ${theme.palette.grey.thirty};
  }
  ${brandVariant};
`

const ValueStyles = styled.div<IconStylesProps>`
  ${brandVariant};
`

const TitleStyles = styled(Caption)`
  margin-bottom: 4px;
`
