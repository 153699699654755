import React from "react"
import styled from "@emotion/styled"
import { GridCellWithLabelProps } from "./types"
import TwoCaptionsBlock from "../TwoCaptionsBlock"
import TextOverflow from "../TextOverflow"

const GridCellWithLabel = (props: GridCellWithLabelProps) => {
  const { value, label } = props

  return (
    <StyledTwoCaptionsBlock
      bottom={label}
      spacing={4}
      top={value ? <TextOverflow>{value}</TextOverflow> : "-"}
      topSize="s"
      topWeight={500}
      bottomSize="xs"
      bottomWeight={400}
    />
  )
}

export default GridCellWithLabel

const StyledTwoCaptionsBlock = styled(TwoCaptionsBlock)`
  padding-right: 4px;
`
