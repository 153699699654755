import React from "react"
import { useForm } from "react-hook-form"
import { Box, Grid } from "kui-basic"
import { yupResolver } from "@hookform/resolvers/yup"
import { CompanyAutocomplete, FormBottom, ChangeReasonFields } from "kui-crm"
import { CompanyChangeFormFields, CompanyChangeFormProps } from "./types"
import CompanyChangeSchema from "./schema"
import useResetForm from "../../../../hooks/useResetForm"

function CompanyChangeForm({ handleSubmit }: CompanyChangeFormProps) {
  const form = useForm<CompanyChangeFormFields>({
    resolver: yupResolver(CompanyChangeSchema),
  })

  useResetForm(form)

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)}>
      <Box mb={3} mr={5} ml={5}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CompanyAutocomplete
              form={form}
              name="newCompany"
              label="New company"
            />
          </Grid>
          <Grid item xs={12}>
            <ChangeReasonFields form={form} />
          </Grid>
        </Grid>
      </Box>
      <FormBottom label="Save" />
    </form>
  )
}

export default CompanyChangeForm
