import React from "react"
import { Grid } from "kui-basic"
import { observer } from "mobx-react"
import { InputByType } from "kui-crm"
import { CompanyOfficeFieldsProps } from "./types"
import useCompanyStore from "../../../../store"
import { CompanyOfficesFields } from "../../../../forms/CompanyOfficesForm/types"
import companyOfficeFields from "./fields"

function CompanyOfficeFields({
  form,
  name,
}: CompanyOfficeFieldsProps<CompanyOfficesFields>) {
  const { editor } = useCompanyStore()
  const disabled = !editor.isEditing

  return (
    <Grid container spacing={3}>
      {companyOfficeFields.map(({ size, name: fieldName, ...field }) => (
        <Grid item key={field.name} xs={size}>
          <InputByType
            form={form}
            name={`${name}.${fieldName}`}
            disabled={disabled}
            {...field}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default observer(CompanyOfficeFields)
