import React, { useEffect } from "react"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import { css } from "@emotion/react"
import { updateQueryParams } from "kui-utils"
import TableHead from "../../ui/TableHead"
import TableRow from "../../ui/TableRow"
import TableCell from "../../ui/TableCell"
import {
  SortingTableHeadCellParams,
  SortingTableProps,
  TableCellStylesProps,
} from "./types"
import TableBottomLoading from "../TableBottomLoading"
import Table from "../../ui/Table"
import TableSortLabel from "../../ui/TableSortLabel"
import useQueryParam from "../../../hooks/useQueryParam"
import Loading from "../Loading"

function SortingTable(props: SortingTableProps) {
  const {
    store,
    headParams,
    otherParams,
    children,
    headVariant,
    offset,
    withOverlaps,
    withFullLoading,
  } = props
  const { filter, paginator, loader } = store
  const defaultSort = useQueryParam("sort")

  const changeOrder = (item: SortingTableHeadCellParams) => {
    filter.setOrder(filter.order === "asc" ? "desc" : "asc")
    filter.setOrderBy(item.id)
    paginator.clearOffset()
    paginator.startFetching()
    updateQueryParams(`?sort=${filter.order === "desc" ? "-" : ""}${item.id}`)
  }

  useEffect(() => {
    if (defaultSort) {
      const withDescSort = defaultSort[0] === "-"
      if (withDescSort) {
        filter.setOrder("desc")
        filter.setOrderBy(defaultSort.slice(1))
      } else {
        filter.setOrder("asc")
        filter.setOrderBy(defaultSort)
      }
    }
  }, [])

  if (withFullLoading && loader.isLoading && paginator.offset < paginator.limit)
    return <Loading height="100vh" />

  return (
    <>
      <StyledTable>
        <TableHead variant={headVariant} offset={offset}>
          <TableRow>
            {headParams.map((item) => (
              <StyledTableCell
                width={item.width ?? "auto"}
                textColor="gray"
                type="head"
                key={item.id}
                align={item.align}
                withOverlaps={withOverlaps}
              >
                {item.withoutSort ? (
                  item.label
                ) : (
                  <TableSortLabel
                    active={filter.orderBy === item.id}
                    direction={
                      filter.orderBy === item.id ? filter.order : "asc"
                    }
                    onClick={() => changeOrder(item)}
                  >
                    {item.label}
                  </TableSortLabel>
                )}
              </StyledTableCell>
            ))}
            {otherParams?.map((item) => (
              <TableCell
                width={item.width ?? "auto"}
                textColor="gray"
                type="head"
                key={item.label}
              >
                {item.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <tbody>{children}</tbody>
      </StyledTable>
      <TableBottomLoading
        isLoading={loader.isLoading && paginator.offset !== 0}
      />
    </>
  )
}

SortingTable.defaultProps = {
  withFullLoading: true,
}

export default observer(SortingTable)

const StyledTable = styled(Table)`
  max-width: 1344px;
  margin: 0 auto;
`

const borderOverlapCSS = css`
  content: "";
  display: block;
  width: 24px;
  background: white;
  height: 42px;
  position: absolute;
  top: -1px;
  z-index: 4;
`

const withOverlapsCSS = ({ withOverlaps }: TableCellStylesProps) =>
  withOverlaps &&
  css`
    &:first-child {
      &:before {
        ${borderOverlapCSS};
        left: 0;
      }
    }
    &:last-child {
      &:before {
        ${borderOverlapCSS};
        top: 0;
        right: 0;
      }
    }
  `

const StyledTableCell = styled(TableCell)<TableCellStylesProps>`
  padding: 4px 0 4px ${({ align }) => (align === "center" ? 42 : 22)}px;
  &:first-child {
    padding-left: 24px;
    position: relative;
  }
  ${withOverlapsCSS};
`
