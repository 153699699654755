import requests from "./index"
import { PatchPromotionContentBodyRequest } from "../pages/ApartmentPage/pages/PromotionPage/types/api/promotionContentAPI"
import { PatchChannelsBodyRequest } from "../pages/ApartmentPage/pages/PromotionPage/types/api/promotionChannelsAPI"

const ApartmentPromotionAgent = {
  getPromotionContent: (apartmentId: number) =>
    requests.get(`/apartments/${apartmentId}/promotion/content`),
  patchPromotionContent: (
    apartmentId: number,
    data: PatchPromotionContentBodyRequest
  ) => requests.patch(`/apartments/${apartmentId}/promotion/content`, data),
  getPromotionChannels: (apartmentId: number) =>
    requests.get(`/apartments/${apartmentId}/promotion/channels`),
  patchPromotionChannels: (
    apartmentId: number,
    data: PatchChannelsBodyRequest
  ) => requests.patch(`/apartments/${apartmentId}/promotion/channels`, data),
  getVolumeOfApartments: () => requests.get("/ml-price/report"),
}

export default ApartmentPromotionAgent
