import React from "react"
import { useToggle } from "kui-utils"
import { LoaderState } from "kui-crm"
import { ApartmentPaymentLineProps } from "./types"
import ExpensesPaymentTableLine from "../ExpensesPaymentTableLine"
import PaymentEditingForm from "../../../../forms/summary/PaymentEditingForm"

function ExpensesPaymentLine({ payment }: ApartmentPaymentLineProps) {
  const [isEditing, startEditing, endEditing] = useToggle()

  return (
    <>
      {isEditing ? (
        <PaymentEditingForm payment={payment} onClick={endEditing} />
      ) : (
        <ExpensesPaymentTableLine onClick={startEditing} payment={payment} />
      )}

      <LoaderState loader={payment.loader} />
    </>
  )
}

export default ExpensesPaymentLine
