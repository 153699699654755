import React from "react"
import { Button, Grid } from "kui-basic"
import { useToggle } from "kui-utils"
import { PlusIcon } from "kui-icon"
import ApartmentDocumentsCalendar from "../ApartmentDocumentsCalendar"
import useApartmentStore from "../../../../store"
import { DocumentsTabSettingsProps } from "./types"
import UploadFilesWithSettingsModal from "../../../../../../components/modals/UploadFilesWithSettingsModal"
import { UploadDocumentParams } from "../../../../../../components/common/DocumentsBlockWithForm/types"

function DocumentsTabSettings({ activeTab }: DocumentsTabSettingsProps) {
  const { documentsStore, overviewStore } = useApartmentStore()
  const [isModalOpen, handleModalOpen, handleModalClose] = useToggle()
  const withCreation = +activeTab === 2
  const withPeriod = +activeTab !== 1 && +activeTab !== 2

  const handleSubmit = (data: UploadDocumentParams[]) => {
    if (overviewStore.id) {
      data.forEach((file) => {
        documentsStore.ownershipDocuments?.createDocument(
          file,
          overviewStore.id
        )
      })
    }
  }

  return (
    <Grid item>
      <Grid container spacing={3}>
        {withPeriod && (
          <Grid item>
            <ApartmentDocumentsCalendar />
          </Grid>
        )}
        {withCreation && (
          <Grid item>
            <Button
              isCircle
              variant="whiteWithGray"
              size="xs"
              onClick={handleModalOpen}
            >
              <PlusIcon width={10} height={10} />
            </Button>
          </Grid>
        )}

        <UploadFilesWithSettingsModal
          open={isModalOpen}
          handleClose={handleModalClose}
          handleSubmit={handleSubmit}
        />
      </Grid>
    </Grid>
  )
}

export default DocumentsTabSettings
