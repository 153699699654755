import React from "react"
import { Caption } from "kui-basic"
import styled from "@emotion/styled"
import { InvoiceBilletProps } from "./types"
import FilesWithDot from "../../../../../../../../components/common/FilesWithDot"

const InvoiceBillet = ({ payout }: InvoiceBilletProps) => {
  if (!payout) return null
  const invoiceParams = { label: "Invoice", link: payout.invoice }
  const depositParams = { label: "Deposit", link: payout.deposit }

  return (
    <StyledPayoutNumberWrapper>
      <StyledPayoutNumber>
        <Caption weight={600} size="xs" color="sixty" align="center">
          {payout.number}
          {payout.date && ` OF ${payout.date?.toFormat("dd.MM.yy")}`}
        </Caption>
        <FilesWithDot firstFile={invoiceParams} secondFile={depositParams} />
      </StyledPayoutNumber>
    </StyledPayoutNumberWrapper>
  )
}

export default InvoiceBillet

const StyledPayoutNumberWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: -4px;
`

const StyledPayoutNumber = styled.div`
  background: ${({ theme }) => theme.palette.background.light1};
  border: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
  border-radius: 8px;
  width: fit-content;
  padding: 4px 16px;
  z-index: 1;
  flex-wrap: nowrap;
`
