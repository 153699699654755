import React from "react"
import { observer } from "mobx-react"
import { LoaderState } from "kui-crm"
import CompanyInfoForm from "../../../forms/CompanyInfoForm"
import useCompanyStore from "../../../store"
import Loading from "../../../../../components/common/Loading"
import Container from "../../../../../components/ui/Container"
import { CompanyInfoFields } from "../../../forms/CompanyInfoForm/types"
import NoteBlock from "../../../../../components/common/NoteBlock"

function CompanyInfoTab() {
  const { overviewStore, actionLoader, editor } = useCompanyStore()

  const handleSubmit = (data: CompanyInfoFields) => {
    if (overviewStore?.id) {
      overviewStore.changeOverviewInfo(overviewStore.id, data)
      editor.endEditing()
    }
  }

  if (!overviewStore || actionLoader.isLoading) {
    return <Loading />
  }

  return (
    <Container>
      <CompanyInfoForm handleSubmit={handleSubmit} />
      <NoteBlock notesStore={overviewStore.notesStore} />

      <LoaderState loader={overviewStore?.loader} />
    </Container>
  )
}

export default observer(CompanyInfoTab)
