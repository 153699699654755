import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { LoaderState } from "kui-crm"
import useCompanyStore from "../../../store"
import CompanyObjectsTable from "./CompanyObjectsTable"
import Loading from "../../../../../components/common/Loading"
import CompanySelectedObjectsPopup from "./CompanySelectedObjectsPopup"
import CompanyObjectsHeader from "./CompanyObjectsHeader"

function CompanyObjectsTab() {
  const { objectsStore, overviewStore } = useCompanyStore()

  useEffect(() => {
    if (overviewStore?.id) {
      objectsStore.fetchCompanyObjects(overviewStore.id)
    }
  }, [overviewStore?.id])

  if (objectsStore.loader.isLoading) {
    return <Loading />
  }

  return (
    <>
      <CompanyObjectsHeader />
      <CompanyObjectsTable />
      <CompanySelectedObjectsPopup />

      <LoaderState loader={objectsStore.loader} onlyError />
      <LoaderState loader={objectsStore.actionLoader} />
    </>
  )
}

export default observer(CompanyObjectsTab)
