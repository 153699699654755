import React from "react"
import { observer } from "mobx-react"
import { getPhoneNumberFromPhoneParams } from "kui-utils"
import { SquarePreviewImage } from "kui-crm"
import AboutPageHeader from "../../../../../components/common/AboutPageHeader"
import useCompanyStore from "../../../store"

function CompanyOverviewHeader() {
  const { overviewStore, officesStore } = useCompanyStore()
  const phone = officesStore.mainOffice?.phone
  const formattedPhone = phone ? getPhoneNumberFromPhoneParams(phone) : ""

  const companyInfo = [
    {
      title: officesStore.mainOffice?.email,
      subtitle: "Email",
    },
    {
      title: formattedPhone,
      subtitle: "Phone",
    },
  ]

  return (
    <AboutPageHeader
      icon={
        <SquarePreviewImage
          imageSrc={overviewStore?.logo}
          name={overviewStore?.name}
        />
      }
      title={overviewStore?.name}
      subtitle={`${overviewStore?.id} • ${officesStore.mainOffice?.office?.address}`}
      info={companyInfo}
    />
  )
}

export default observer(CompanyOverviewHeader)
