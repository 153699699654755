import React, { useRef, useState } from "react"
import styled from "@emotion/styled"
import { Caption } from "kui-basic"
import { InputDropdown } from "kui-complex"
import { HalfArrowDownIcon } from "kui-icon"
import { ListWithLabelProps, OpeningStylesProps } from "./types"
import Loading from "../../common/Loading"

function ListWithLabel({
  label,
  isLoading,
  error,
  renderList,
  onFirstOpen,
  ...otherProps
}: ListWithLabelProps) {
  const [isFirstOpen, setIsFirstOpen] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const buttonRef = useRef(null)

  const handleClick = () => {
    setIsOpen((prev) => !prev)
    if (isFirstOpen && onFirstOpen) {
      onFirstOpen()
    }
    setIsFirstOpen(false)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <StyledWrapper {...otherProps}>
      <StyledDropdown
        isOpen={isOpen}
        handleClose={handleClose}
        buttonRef={buttonRef}
        className="list_dropdown"
        strategy="absolute"
        input={
          <StyledButton
            className="list_button"
            ref={buttonRef}
            isOpen={isOpen}
            onClick={handleClick}
          >
            <Caption weight={500} size="s">
              {label}
            </Caption>
            <StyledArrowIcon isOpen={isOpen} width={9} height={6} />
          </StyledButton>
        }
      >
        {error ? (
          <Caption color="fiftyP">Something went wrong...</Caption>
        ) : (
          <>
            {renderList()}
            {isLoading && <Loading height="56px" />}
          </>
        )}
      </StyledDropdown>
    </StyledWrapper>
  )
}

export default ListWithLabel

const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
`

const StyledButton = styled.div<OpeningStylesProps>`
  height: 32px;
  background: ${({ theme }) => theme.palette.background.light1};
  border: 1px solid
    ${({ theme, isOpen }) =>
      isOpen ? theme.palette.brand.main : theme.palette.background.light3};
  padding: 0 16px;
  display: flex;
  align-items: center;
  border-radius: 66px;
  cursor: pointer;
`

const StyledArrowIcon = styled(HalfArrowDownIcon)<OpeningStylesProps>`
  margin-left: 12px;
  transition: all ease-out 0.3s;
  transform: rotate(${({ isOpen }) => (isOpen ? 180 : 0)}deg);
`

const StyledDropdown = styled(InputDropdown)`
  width: 100%;
  div {
    width: 100%;
  }
  .KUI-Popper {
    z-index: 2;
    padding-top: 16px;
    > div {
      border: 1px solid ${({ theme }) => theme.palette.background.light3};
      box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      padding: 0 8px;

      > div > div {
        padding-top: 8px;
      }
    }
  }
`
