import { makeAutoObservable } from "mobx"
import { Loader, Paginator, SortingFilter, MultistepForm } from "kui-utils"
import ClientStore from "./ClientStore"
import { MultipleEntitiesStoreInterface } from "../../../types/store/pagination"
import { ClientLiteModel } from "../../../types/api/clients"
import { ClientCreationFields } from "../../../components/modals/ClientCreationModal/types"
import ClientsLiteStore, {
  ClientFormStages,
} from "../../../store/lites/ClientsLiteStore"

class ClientsStore implements MultipleEntitiesStoreInterface {
  clients: ClientStore[]

  paginator: Paginator

  filter: SortingFilter

  loader: Loader

  creationLoader: Loader

  creationForm: MultistepForm<ClientCreationFields, typeof ClientFormStages>

  constructor() {
    this.clients = []
    this.loader = new Loader()
    this.creationLoader = new Loader()
    this.paginator = new Paginator()
    this.filter = new SortingFilter("id")
    this.creationForm = new MultistepForm<
      ClientCreationFields,
      typeof ClientFormStages
    >(null, ClientFormStages)
    makeAutoObservable(this)
  }

  fetchAll = async () => {
    await ClientsLiteStore.fetchAllClients(this, (res) => {
      const mapper = (client: ClientLiteModel) => new ClientStore(client)

      this.clients = this.paginator.getPageResponse<
        ClientLiteModel,
        ClientStore
      >(res, this.clients, mapper)
    })
  }

  createClient = async (data: ClientCreationFields) =>
    ClientsLiteStore.postClient(this, data, (res) => {
      this.clients = [...this.clients, new ClientStore(res)]
    })
}

export default ClientsStore
