import { makeAutoObservable, runInAction } from "mobx"
import { Loader, Paginator, resHandler } from "kui-utils"
import to from "await-to-js"
import { AutoMeterParams } from "../../types/cards/autoMeters"
import DashboardAgent from "../../../../agent/Dashboard"
import ApartmentsStore from "../../../../store/lites/ApartmentsStore"
import { AutoMeterModel } from "../../types/api/meters"
import DashboardPageStore from "../DashboardPageStore"

class AutoMetersStore {
  value: number | null

  meters: AutoMeterParams[]

  paginator: Paginator

  loader: Loader

  dashboardStore: DashboardPageStore

  constructor(dashboardStore: DashboardPageStore) {
    this.value = null
    this.meters = []
    this.paginator = new Paginator(5)
    this.loader = new Loader()
    this.dashboardStore = dashboardStore
    makeAutoObservable(this)
  }

  fetchAutoMeters = async () => {
    this.loader.startLoading()

    const response = await to(
      DashboardAgent.getAutoMeters(
        this.paginator.limit,
        this.paginator.offset,
        this.dashboardStore.periodFilter
      )
    )

    runInAction(() => {
      resHandler(response, this.loader, (res) => {
        this.value = res.count
        this.meters = this.paginator.getPageResponse(
          res,
          this.meters,
          AutoMetersStore.getMeterParams
        )
      })
    })
  }

  static getMeterParams = (meter: AutoMeterModel) => ({
    id: meter.meter.id,
    apartment: ApartmentsStore.getApartmentLinkParams(meter.apartment),
    type: meter.meter.meter_type,
    resource: meter.meter.resource_type,
    number: meter.meter.passport_number,
  })
}

export default AutoMetersStore
