import React from "react"
import { NewObjectRowProps } from "./types"
import ContractWithPriceRow from "../../../common/ContractWithPriceRow"

const NewObjectRow = (props: NewObjectRowProps) => {
  const { object } = props
  const { contract, apartment, rentPrice } = object
  return (
    <ContractWithPriceRow
      contract={contract}
      apartment={apartment}
      value={rentPrice}
    />
  )
}

export default NewObjectRow
