import React from "react"
import { Modal } from "kui-crm"
import { EditContractModalProps } from "./types"
import EditContractForm from "../../forms/contacts/EditContractForm"
import { EditContractFormFields } from "../../forms/contacts/EditContractForm/types"

const EditContractModal = (props: EditContractModalProps) => {
  const { form, editor, open, handleClose, ...other } = props

  const handleSubmit = (data: EditContractFormFields) => {
    form.setValue("changesDate", data.date)
    form.setValue("changesFile", data.file)
    editor.startEditing()
    handleClose()
  }

  return (
    <Modal
      title="Date of application of changes"
      open={open}
      handleClose={handleClose}
    >
      <EditContractForm handleSubmit={handleSubmit} {...other} />
    </Modal>
  )
}

export default EditContractModal
