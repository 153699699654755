import React from "react"
import styled from "@emotion/styled"
import { Caption, Box } from "kui-basic"
import { ApartmentAccrualsGroupProps } from "./types"
import TableRowExpanses from "../TableRowExpanses"

function ExpensesAccrualsGroup({ title, lines }: ApartmentAccrualsGroupProps) {
  return (
    <StyledWrapper>
      <Box mb={1}>
        <Caption color="fiftyP" weight={500} size="s">
          {title.toUpperCase()}
        </Caption>
      </Box>
      {lines.map((item) => (
        <TableRowExpanses
          key={`${item.title}_${item.id}`}
          data-testid="accrual_line"
          line={item}
        />
      ))}
    </StyledWrapper>
  )
}

export default ExpensesAccrualsGroup

const StyledWrapper = styled.div`
  &:not(&:last-child) {
    margin-bottom: 24px;
  }
`
