import { makeAutoObservable, runInAction } from "mobx"
import { Loader, Paginator, resHandler } from "kui-utils"
import to from "await-to-js"
import { DateTime } from "luxon"
import DashboardPageStore from "../DashboardPageStore"
import DashboardAgent from "../../../../agent/Dashboard"
import ApartmentsStore from "../../../../store/lites/ApartmentsStore"
import { ObjectWithoutAppraisalParams } from "../../types/cards/inspections"
import { ObjectWithoutAppraisalModel } from "../../types/api/apartments"

class ObjectsWithoutAppraisalStore {
  value: number | null

  objects: ObjectWithoutAppraisalParams[]

  paginator: Paginator

  loader: Loader

  dashboardStore: DashboardPageStore

  constructor(dashboardStore: DashboardPageStore) {
    this.value = null
    this.objects = []
    this.paginator = new Paginator(5)
    this.loader = new Loader()
    this.dashboardStore = dashboardStore
    makeAutoObservable(this)
  }

  fetchObjects = async () => {
    this.loader.startLoading()

    const response = await to(
      DashboardAgent.getObjectsWithoutAppraisal(
        this.paginator.limit,
        this.paginator.offset,
        this.dashboardStore.periodFilter
      )
    )

    runInAction(() => {
      resHandler(response, this.loader, (res) => {
        this.value = res.count
        this.objects = this.paginator.getPageResponse(
          res,
          this.objects,
          ObjectsWithoutAppraisalStore.getObjectParams
        )
      })
    })
  }

  static getObjectParams = ({ inspection }: ObjectWithoutAppraisalModel) => ({
    id: inspection.id,
    apartment: ApartmentsStore.getApartmentLinkParams(inspection.apartment),
    type: inspection.inspection_type,
    date: inspection.date ? DateTime.fromISO(inspection.date) : null,
  })
}

export default ObjectsWithoutAppraisalStore
