import React from "react"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import { Link, PreviewContent } from "kui-crm"
import { TariffCompanyRowProps } from "./types"
import TableCell from "../../../../components/ui/TableCell"
import TableRow from "../../../../components/ui/TableRow"
import { Routes } from "../../../../types/route"
import TextOverflowWithCommentAndFile from "../../../../components/common/TextOverflowWithCommentAndFile"

function TariffCompanyRow({ company, isDark }: TariffCompanyRowProps) {
  return (
    <TableRow isDark={isDark}>
      <TableCell isFirst textColor="gray">
        {company.id}
      </TableCell>
      <TableCell isLast>
        <StyledPreviewContent
          imageSrc={company.logo}
          content={
            <TextOverflowWithCommentAndFile
              comment={company.reason?.comment}
              file={company.reason?.file}
            >
              <Link href={`${Routes.companies}/${company.id}`}>
                {company.name}
              </Link>
            </TextOverflowWithCommentAndFile>
          }
        />
      </TableCell>
    </TableRow>
  )
}

export default observer(TariffCompanyRow)

const StyledPreviewContent = styled(PreviewContent)`
  p {
    color: ${({ theme }) => theme.palette.grey.seventy};
    font-weight: 500;
  }
`
