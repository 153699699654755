import { OverlayViewProps } from "../types"

function OverlayView() {
  if (typeof window !== "undefined") {
    return class OverlayViewClass extends window.google.maps.OverlayView {
      position: google.maps.LatLng | null

      content: HTMLDivElement | null

      constructor({ position, content }: OverlayViewProps) {
        super()
        this.position = position ?? null
        this.content = content ?? null
      }

      onAdd = () => {
        if (this.content) this.getPanes()?.floatPane.appendChild(this.content)
      }

      onRemove = () => {
        if (this.content?.parentElement) {
          this.content.parentElement.removeChild(this.content)
        }
      }

      draw = () => {
        if (this.position) {
          const divPosition = this.getProjection().fromLatLngToDivPixel(
            this.position
          )
          if (this.content && divPosition) {
            this.content.style.left = `${divPosition.x}px`
            this.content.style.top = `${divPosition.y}px`
          }
        }
      }
    }
  }
}

export default OverlayView
