import * as React from "react"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import Page from "../../components/common/Page"
import store from "../../store"
import { getDashboardPage } from "./content"

function DashboardPage() {
  const { cabinetStore } = store
  const dashboardCards = cabinetStore.role
    ? getDashboardPage(cabinetStore.role)
    : null

  return <StyledPage>{dashboardCards}</StyledPage>
}

export default observer(DashboardPage)

const StyledPage = styled(Page)`
  background: ${({ theme }) => theme.palette.background.light1};
`
