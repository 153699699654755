import React from "react"
import {
  CountryAutocomplete,
  RegionAutocomplete,
  CityAutocomplete,
} from "kui-crm"
import { InputsGroupWithTitleParams } from "../../../../../../../components/common/InputsGroupWithTitle/types"
import { InitialInspectionFormFields } from "../types"
import CopyButton from "../../../../../../../components/common/CopyButton"
import InputWithAddressAutocomplete from "../../../../../../../components/ui/InputWithAddressAutocomplete"
import {
  BooleanValues,
  RoomTypesValues,
} from "../../../../../../../utils/content/listsOptions"
import { getApartmentDetailInfoFields } from "../../../../../../../utils/formFields/apartment"
import { ApartmentHouseInputs } from "../../../../../../../components/forms/apartment/ApartmentDescriptionFields/DescriptionFields/fields"

const getInitialInspectionInfoFieldGroup = (
  address?: string,
  roomCount?: number | null,
  bedroomsCount?: number | null
): InputsGroupWithTitleParams<InitialInspectionFormFields>[] => [
  {
    title: "Main object info",
    fields: [
      {
        name: "country",
        label: "Country",
        variant: "custom",
        CustomInput: CountryAutocomplete,
        size: 2,
      },
      {
        name: "region",
        label: "Region",
        variant: "custom",
        CustomInput: RegionAutocomplete,
        size: 2,
      },
      {
        name: "city",
        label: "City",
        variant: "custom",
        CustomInput: CityAutocomplete,
        size: 2,
        isRequired: true,
      },
      {
        name: "apartment",
        endIcon: <CopyButton text={address || ""} />,
        variant: "custom",
        CustomInput: InputWithAddressAutocomplete,
        size: 4,
        isRequired: true,
      },
      {
        name: "apartmentNumber",
        label: "Apartment",
        size: 2,
        isRequired: true,
      },
      { name: "zipCode", label: "ZIP code", size: 2 },
      {
        name: "floor",
        label: "Floor",
        size: 1,
        type: "number",
        isRequired: true,
      },
      {
        name: "floorCount",
        label: "Floors",
        size: 1,
        type: "number",
        isRequired: true,
      },
      { name: "entrance", label: "Entrance", size: 2, isRequired: true },
      {
        name: "typeOfObject",
        label: "Object type",
        size: 2,
        variant: "select",
        options: RoomTypesValues,
        isRequired: true,
      },
    ],
  },
  {
    title: "metro",
    fields: [],
  },
  {
    title: "Security and passwords",
    fields: [
      {
        name: "hasIntercom",
        label: "Intercom",
        size: 2,
        variant: "select",
        options: BooleanValues,
        isRequired: true,
      },
      { name: "intercomCode", label: "Intercom code", size: 2 },
      {
        name: "hasGate",
        label: "Gate",
        size: 2,
        variant: "select",
        options: BooleanValues,
        isRequired: true,
      },
      { name: "gateCode", label: "Gate code", size: 2 },
    ],
  },
  {
    title: "Apartment info",
    fields: getApartmentDetailInfoFields(roomCount, bedroomsCount),
  },
  {
    title: "Houses info",
    fields: ApartmentHouseInputs,
  },
]

export default getInitialInspectionInfoFieldGroup
