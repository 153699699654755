import * as React from "react"
import { observer } from "mobx-react"
import { Heading, Box } from "kui-basic"
import { LoaderState } from "kui-crm"
import Note from "../Note"
import NoteForm from "../../forms/note/NoteForm"
import NoteStore from "../../../store/notes/Note"
import { NoteBlockProps } from "./types"
import usePaginationPage from "../../../hooks/usePaginationPage"

function NotesBlock({ notesStore }: NoteBlockProps) {
  usePaginationPage(notesStore.paginator, notesStore.fetchNotes)

  return (
    <>
      <Box pt={3} mb={2}>
        <Heading size="h4">Comments</Heading>
      </Box>
      <NoteForm handleSubmit={notesStore.createNote} variant="create" />
      <Box mt={3}>
        {notesStore.notes?.map((note: NoteStore) => (
          <Note key={note.id} note={note} />
        ))}
      </Box>

      <LoaderState loader={notesStore.actionLoader} />
    </>
  )
}

export default observer(NotesBlock)
