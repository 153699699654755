import React, { useEffect } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { observer } from "mobx-react"
import { HeadingWithButton } from "kui-crm"
import { CloseIcon } from "kui-icon"
import { CompanyOfficesFields, CompanyOfficesFormProps } from "./types"
import useCompanyStore from "../../store"
import CompanyOfficeFields from "../../components/tabs/CompanyOfficesTab/CompanyOfficeFields"
import CompanyOfficesFormSchema from "./schema"

function CompanyOfficesForm({
  handleSubmit,
  handleClick,
}: CompanyOfficesFormProps) {
  const { officesStore, editor } = useCompanyStore()
  const disabled = !editor.isEditing

  const form = useForm<CompanyOfficesFields>({
    defaultValues: {
      mainOffice: {
        ...officesStore.mainOffice,
      },
      offices: officesStore.offices,
    },
    resolver: yupResolver(CompanyOfficesFormSchema),
  })
  const { fields, remove } = useFieldArray({
    control: form.control,
    name: "offices",
  })

  useEffect(() => {
    if (officesStore) {
      form.setValue("offices", officesStore.offices)
    }
  }, [officesStore?.offices])

  useEffect(() => {
    if (editor.isAllowedToSendForm) {
      form.handleSubmit(handleSubmit, editor.blockToSendForm)()
    }
  }, [editor.isAllowedToSendForm])

  return (
    <form>
      <HeadingWithButton
        onClick={handleClick}
        title="Main office"
        showButton={!disabled}
      />
      <CompanyOfficeFields form={form} name="mainOffice" />
      {fields.map((office, key) => (
        <React.Fragment key={fields[key]?.id}>
          <HeadingWithButton
            onClick={() => remove(key)}
            title={`Office ${key + 1}`}
            icon={<CloseIcon width={8} height={8} />}
            variant="backgroundLight"
            showButton={!disabled}
          />
          <CompanyOfficeFields form={form} name={`offices.${key}`} />
        </React.Fragment>
      ))}
    </form>
  )
}

export default observer(CompanyOfficesForm)
