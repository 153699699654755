import React from "react"
import { observer } from "mobx-react"
import { MeterFeaturesFormFields, MeterFeaturesForm } from "kui-crm"
import { ElectricityMeterFormProps } from "./types"
import useExpensesStore from "../../../store"

function ElectricityMeterForm({
  handleClose,
  handleSubmit: onSubmit,
}: ElectricityMeterFormProps) {
  const { metersStore, overviewStore } = useExpensesStore()
  const electricityStore = metersStore.electricityMetersStore
  const formattedElectricityMeters = electricityStore?.activeElectricityMeters
  const hasPayer = overviewStore.actualServiceContract?.metersPayers.electricity
  const operatingAccount = overviewStore.operatingAccounts?.electricity
  const isNewResource =
    electricityStore?.electricityMeters.length === 0 && !hasPayer

  const handleSubmit = (data: MeterFeaturesFormFields) => {
    const creationForm = electricityStore?.creationForm
    if (creationForm) {
      onSubmit(data)
      creationForm.resetForm()
      handleClose()
    }
  }

  return (
    <MeterFeaturesForm
      resource="electricity"
      formStore={electricityStore?.creationForm}
      replacements={formattedElectricityMeters}
      handleSubmit={handleSubmit}
      isNewResource={isNewResource}
      maxMeters={electricityStore?.maxCountersCount}
      operatingAccount={operatingAccount}
      administrativeCompany={overviewStore.managementCompany}
      apartmentId={overviewStore.id}
    />
  )
}

export default observer(ElectricityMeterForm)
