import React from "react"
import { Caption, Grid } from "kui-basic"
import styled from "@emotion/styled"
import { CheckIcon, CloseIcon } from "kui-icon"
import { CheckItemProps, CheckItemStylesProps } from "./types"

const CheckItem = (props: CheckItemProps) => {
  const { item } = props
  const { label, checked } = item

  return (
    <StyledWrapper container alignItems="center">
      {checked ? <SuccessIcon /> : <ErrorIcon />}
      <StyledLabel
        size="s"
        checked={checked}
        color={checked ? "fiftyP" : "seventy"}
      >
        {label}
      </StyledLabel>
    </StyledWrapper>
  )
}

export default CheckItem

const ErrorIcon = styled(CloseIcon)`
  width: 16px;
  height: 16px;
  padding-right: 4px;
  path {
    fill: ${({ theme }) => theme.palette.red.seventy};
  }
`

const SuccessIcon = styled(CheckIcon)`
  path {
    fill: ${({ theme }) => theme.palette.green.seventy};
  }
`

const StyledLabel = styled(Caption)<CheckItemStylesProps>`
  text-align: left;
  margin-left: 8px;
  text-decoration: ${({ checked }) => (checked ? "line-through" : "none")};
`

const StyledWrapper = styled(Grid)`
  padding: 4px 0;
  &:not(:last-of-type) {
    margin-bottom: 6px;
  }
`
