import { createContext, useContext } from "react"
import RentalContractsStore from "./RentalContractsStore"

const RCsStore = new RentalContractsStore()
const RCsContext = createContext(RCsStore)

const useRCsStore = () => useContext(RCsContext)

export default useRCsStore
export { RCsStore }
