import * as yup from "yup"

const SCCommonTermsFormSchema = yup.object().shape({
  initialCommission: yup
    .number()
    .min(0)
    .required("This field is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  advertisingPeriod: yup
    .number()
    .min(0)
    .required("This field is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  inspections: yup
    .number()
    .min(0)
    .required("This field is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  maintenanceCommission: yup
    .number()
    .min(0)
    .required("This field is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  minRentPrice: yup
    .string()
    .required("This field is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  maxRentPrice: yup
    .string()
    .required("This field is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  firstAppraisalPeriod: yup
    .number()
    .min(0)
    .required("This field is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
})

export default SCCommonTermsFormSchema
