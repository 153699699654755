import {
  ApartmentDetailInfoParams,
  ApartmentHouseInfoParams,
  ApartmentMainInfoParams,
  ApartmentModel,
  ApartmentSecurityInfoParams,
  ApartmentTelecommunicationInfoParams,
  DetailObjectInfoInterface,
  LatLonParams,
  MainObjectInfoInterface,
  MaxMetersInfoParams,
  SecurityObjectInfoInterface,
} from "kui-crm"
import { getPhoneParamsFromString } from "kui-utils"
import { ApartmentPartialDescriptionParams } from "../../../types/store/apartments"
import getNumberValue from "../../../utils/service/getNumberValue"

class ApartmentFieldsStore {
  static getApartmentDescriptionInfo = (
    apartment: ApartmentModel
  ): ApartmentPartialDescriptionParams => ({
    ...ApartmentFieldsStore.getApartmentMainInfo(apartment),
    ...ApartmentFieldsStore.getApartmentSecurityInfo(apartment),
    ...ApartmentFieldsStore.getApartmentDetailInfo(apartment),
    ...ApartmentFieldsStore.getApartmentTelecommunicationInfo(apartment),
    ...ApartmentFieldsStore.getApartmentHouseInfo(apartment),
    ...ApartmentFieldsStore.getMaxMetersParams(apartment),
  })

  static getApartmentMainInfo = (
    apartment: MainObjectInfoInterface
  ): ApartmentMainInfoParams => ({
    ...apartment,
    apartmentNumber: apartment.apartment_number,
    zipCode: apartment.post_index || "",
    floorCount: apartment.floor_count,
    entrance: apartment.num_entrance,
    geolocation: ApartmentFieldsStore.getGeolocationParams(
      apartment.geolocation
    ),
    distanceToCenter: apartment.distance_to_center_kilometers || null,
    typeOfObject: apartment.room_type,
  })

  static getApartmentSecurityInfo = (
    apartment: SecurityObjectInfoInterface
  ): ApartmentSecurityInfoParams => ({
    hasIntercom: apartment.has_intercom ?? null,
    hasGate: apartment.has_gate ?? null,
    hasBarrier: apartment.has_barrier ?? null,
    hasSecurity: apartment.has_security ?? null,
    hasConcierge: apartment.has_concierge ?? null,
    hasVideoControl: apartment.has_video_surveillance ?? null,
    intercomCode: apartment.intercom_code || "",
    gateCode: apartment.gate_code || "",
    barrierPhone: apartment.barrier_phone
      ? getPhoneParamsFromString(apartment.barrier_phone)
      : null,
    conciergePhone: apartment.concierge_phone
      ? getPhoneParamsFromString(apartment.concierge_phone)
      : null,
    securityPhone: apartment.security_phone
      ? getPhoneParamsFromString(apartment.security_phone)
      : null,
    videoControlPhone: apartment.video_surveillance_phone
      ? getPhoneParamsFromString(apartment.video_surveillance_phone)
      : null,
  })

  static getApartmentDetailInfo = (
    apartment: DetailObjectInfoInterface
  ): ApartmentDetailInfoParams => ({
    area: apartment.area,
    livingArea: apartment.living_area,
    kitchenArea: apartment.kitchen_area,
    roomsNumber: apartment.rooms_number,
    bedroomsNumber: apartment.bedrooms_number,
    bathroomsNumber: apartment.bathrooms_number,
    restroomsNumber: apartment.restrooms_number,
    combinedBathroomsNumber: apartment.combined_bathrooms_number,
    ceilingHeight: apartment.ceiling_height,
    numberOfWindows: apartment.number_of_windows,
    renovation: {
      year: apartment.renovation_year || null,
      type: apartment.renovation_type || null,
      style: apartment.renovation_style || null,
    },
    rooms:
      apartment.rooms?.map((room) => ({
        id: room.id,
        area: room.area || null,
        type: room.room_type || null,
      })) || [],
  })

  static getApartmentTelecommunicationInfo = (
    apartment: ApartmentModel
  ): ApartmentTelecommunicationInfoParams => ({
    homePhone: apartment.home_phone
      ? getPhoneParamsFromString(apartment.home_phone)
      : null,
    wifi: {
      name: apartment.wifi_name,
      password: apartment.wifi_password,
    },
    internetProvider: {
      link: apartment.internet_provider_link,
      login: apartment.internet_provider_login,
      password: apartment.internet_provider_password,
    },
    tvType: apartment.tv_type,
  })

  static getApartmentHouseInfo = (
    apartment: ApartmentModel
  ): ApartmentHouseInfoParams => ({
    typeOfHouse: apartment.type_houses,
    buildingYear: apartment.building_year,
    houseNumber: apartment.serial_number,
    wallMaterial: apartment.wall_type,
    overlapType: apartment.overlap_type,
    parkingType: apartment.parking_type,
    parkingNumber: apartment.parking_number,
    passengerElevatorsCount: getNumberValue(
      apartment.passenger_elevators_number
    ),
    serviceElevatorsCount: getNumberValue(apartment.service_elevators_number),
    gasLeads: apartment.type_gas,
    withHotWater: apartment.hot_water,
  })

  static getMaxMetersParams = (
    apartment: ApartmentModel
  ): MaxMetersInfoParams => ({
    maxMeters: {
      water: apartment.max_water_counters,
      electricity: apartment.max_electricity_counters,
      gas: apartment.max_gas_counters,
      heating: apartment.max_heating_counters,
    },
  })

  static getGeolocationParams = (geolocation?: LatLonParams) =>
    geolocation
      ? {
          lat: Number(geolocation.lat),
          lng: Number(geolocation.lon),
        }
      : null
}

export default ApartmentFieldsStore
