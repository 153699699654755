import styled from "@emotion/styled"
import { css } from "@emotion/react"
import * as React from "react"
import { useEffect, useState } from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Caption,
  Grid,
} from "kui-basic"
import { HalfArrowUpIcon } from "kui-icon"
import { AccordionWithSummaryProps, ButtonStylesProps } from "./types"
import { maxWidth } from "../../../utils/service/theme"

function AccordionWithSummary({
  title,
  children,
  titleSize,
  detailsProps,
  endComponent,
  onChange,
  expandedLabel,
  disabled,
  expanded: defaultExpanded,
  ...summaryProps
}: AccordionWithSummaryProps) {
  const [expanded, setExpanded] = useState(!!defaultExpanded)

  const handleChange = (expandedValue: boolean) => {
    setExpanded(expandedValue)

    if (onChange) onChange(expandedValue)
  }

  useEffect(() => {
    if (typeof defaultExpanded === "boolean") {
      setExpanded(defaultExpanded)
    }
  }, [defaultExpanded])

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      disabled={disabled}
      data-testid="documents_accordion"
    >
      <StyledAccordionSummary
        data-testid="documents_accordion_summary"
        {...summaryProps}
      >
        <Box mr={2}>
          <StyledTitle size={titleSize} weight={700}>
            {title}
          </StyledTitle>
        </Box>
        {!disabled && (
          <Grid item>
            <Grid container alignItems="center">
              {expandedLabel}
              <StyledButton
                isCircle
                size="xs"
                variant="transparent"
                expanded={expanded}
              >
                <HalfArrowUpIcon />
              </StyledButton>
            </Grid>
          </Grid>
        )}
        {endComponent}
      </StyledAccordionSummary>
      <StyledDetails {...detailsProps}>{children}</StyledDetails>
    </Accordion>
  )
}

AccordionWithSummary.defaultProps = {
  titleSize: "m" as const,
}

export default AccordionWithSummary

const StyledDetails = styled(AccordionDetails)``

const StyledTitle = styled(Caption)`
  ${maxWidth("xs")} {
    font-weight: 600;
  }
`

const expandedButtonCSS = ({ expanded }: ButtonStylesProps) =>
  !expanded &&
  css`
    svg {
      margin-top: 2px;
      transform: rotate(-180deg);
    }
  `

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "expanded",
})<ButtonStylesProps>`
  svg {
    transition: transform linear 0.3s;
    path {
      fill: ${({ theme }) => theme.palette.grey.fiftyP};
    }
  }
  ${maxWidth("xs")} {
    width: 24px;
    height: 24px;
  }
  ${expandedButtonCSS};
`

const StyledAccordionSummary = styled(AccordionSummary)`
  .KUI-Accordion_Summary-Content {
    align-items: center;
    margin: 0;
  }
`
