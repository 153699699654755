import React, { useState } from "react"
import DatePicker from "react-datepicker"
import { DateTime } from "luxon"
import { Grid } from "kui-basic"
import { CalendarWithRangeProps } from "./types"
import CalendarWithRangeButton from "./CalendarWithRangeButton"
import CalendarWithRangeHeader from "./CalendarWithRangeHeader"
import CalendarWithRangeBody from "./CalendarWithRangeBody"

const CalendarWithRange = (props: CalendarWithRangeProps) => {
  const { onChange, isSelected } = props
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const formattedStart = startDate ? DateTime.fromJSDate(startDate) : null
  const formattedEnd = endDate ? DateTime.fromJSDate(endDate) : null
  const isCurrentSelected = startDate === null && endDate === null && isSelected

  const handleChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)

    if (onChange && start && end) {
      onChange(DateTime.fromJSDate(start), DateTime.fromJSDate(end))
    }
  }

  const setIsCurrentSelected = () => {
    setStartDate(null)
    setEndDate(null)
    onChange(null, null)
  }

  return (
    <Grid item>
      <DatePicker
        selectsRange
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        onChange={handleChange}
        customInput={
          <CalendarWithRangeButton
            startDate={formattedStart}
            endDate={formattedEnd}
            isSelected={isSelected}
          />
        }
        renderCustomHeader={(headerProps) => (
          <CalendarWithRangeHeader
            isCurrentSelected={isCurrentSelected}
            setIsCurrentSelected={setIsCurrentSelected}
            {...headerProps}
          />
        )}
        popperPlacement="bottom"
        calendarContainer={CalendarWithRangeBody}
      />
    </Grid>
  )
}

export default CalendarWithRange
