import React from "react"
import styled from "@emotion/styled"
import { Caption } from "kui-basic"
import { DateTime } from "luxon"
import { ApartmentChartTooltipProps } from "./types"

const ApartmentChartTooltip = (props: ApartmentChartTooltipProps) => {
  const { active, payload, label, termination } = props
  if (!active || !payload || !payload.length) return null

  const formattedDate =
    label &&
    DateTime.fromFormat(label, "dd.MM.yy").toFormat("dd MMMM").toLowerCase()

  return (
    <StyledTooltip>
      <Caption size="xs">{formattedDate}</Caption>
      <Caption size="xs">
        {Number(payload[0].value).toLocaleString()} {termination}
      </Caption>
    </StyledTooltip>
  )
}

export default ApartmentChartTooltip

const StyledTooltip = styled.div`
  background: white;
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
  border-radius: 12px;
  padding: 18px 19px;
`
