import React from "react"
import { observer } from "mobx-react"
import { ServiceCreationProps } from "./types"
import MultistepForm from "../../../common/MultistepForm"
import ServiceMainInfoForm from "../ServiceMainInfoForm"
import ServicePaymentInfoForm from "../ServicePaymentInfoForm"
import { ServicePaymentInfoFormFields } from "../ServicePaymentInfoForm/types"
import ServiceApartmentForm from "../ServiceApartmentForm"
import useResetMultistepForm from "../../../../hooks/useResetMultistepForm"

function NewServiceForm(props: ServiceCreationProps) {
  const { handleSubmit, formMaker, withApartment, ...other } = props
  const { resetForm, updateFormFields, fields } = formMaker

  const formSubmit = (data: ServicePaymentInfoFormFields) => {
    updateFormFields(data)
    handleSubmit({ ...fields, ...data })
    resetForm()
  }

  useResetMultistepForm(formMaker)

  return (
    <MultistepForm form={formMaker}>
      {withApartment && <ServiceApartmentForm formMaker={formMaker} />}
      <ServiceMainInfoForm
        withPrevServicesList
        withApartment={withApartment}
        formMaker={formMaker}
        {...other}
      />
      <ServicePaymentInfoForm
        formStore={formMaker}
        handleSubmit={formSubmit}
        label="Create"
      />
    </MultistepForm>
  )
}

export default observer(NewServiceForm)
