import { makeAutoObservable } from "mobx"
import { ApartmentModel } from "kui-crm"
import { Loader, resHandler } from "kui-utils"
import to from "await-to-js"
import { FillingListStore } from "kui-crm_actions"
import ApartmentFieldsStore from "../shared/apartment/ApartmentFieldsStore"
import {
  ApartmentDescriptionLiteStore,
  ApartmentOverviewLiteStore,
} from "../../types/store/apartments"
import ApartmentMetersStore from "../shared/apartment/meters/ApartmentMetersStore"
import ApartmentAgent from "../../agent/Apartment"
import ApartmentInspectionsLiteStore from "./ApartmentInspectionsLiteStore"

class ApartmentPageLiteStore {
  overviewStore: ApartmentOverviewLiteStore

  descriptionStore: ApartmentDescriptionLiteStore

  metersStore: ApartmentMetersStore

  inspectionsStore: ApartmentInspectionsLiteStore

  fillingStore: FillingListStore

  loader: Loader

  constructor() {
    this.overviewStore = ApartmentPageLiteStore.getDefaultOverviewStore()
    this.metersStore = new ApartmentMetersStore(this)
    this.descriptionStore = { fields: null }
    this.inspectionsStore = new ApartmentInspectionsLiteStore(this)
    this.fillingStore = new FillingListStore()
    this.loader = new Loader()
    makeAutoObservable(this)
  }

  fetchApartmentById = async (id: number) => {
    this.loader.startLoading()

    const response = await to<ApartmentModel>(ApartmentAgent.getById(id))

    resHandler(
      response,
      this.loader,
      this.updateApartmentInfo,
      "fetch apartment"
    )
  }

  updateApartmentInfo = (apartment: ApartmentModel) => {
    this.overviewStore = ApartmentPageLiteStore.getOverviewParams(apartment)
    this.descriptionStore =
      ApartmentPageLiteStore.getDescriptionParams(apartment)
  }

  static getDescriptionParams = (apartment: ApartmentModel) => ({
    fields: ApartmentFieldsStore.getApartmentDescriptionInfo(apartment),
  })

  static getOverviewParams = (apartment: ApartmentModel) => ({
    id: apartment.id,
    payerCode: apartment.payer_code,
    communalServicesAccount: apartment.financial_personal_account,
    managementCompany: apartment.administrative_company
      ? {
          ...apartment.administrative_company,
          operatingAccount: apartment.payer_code,
        }
      : null,
    actualRentalContractId:
      apartment.rental_contracts?.find((contract) => contract.status === "Open")
        ?.id || null,
  })

  static getDefaultOverviewStore = () => ({
    id: null,
    payerCode: "",
    communalServicesAccount: "",
    managementCompany: null,
    actualRentalContractId: null,
  })
}

export default ApartmentPageLiteStore
