import React from "react"
import { observer } from "mobx-react"
import AboutPageHeader from "../../../../../components/common/AboutPageHeader"
import ApartmentPreviewImage from "../../../pages/PromotionPage/components/tabs/PromotionContentTab/PromotionPhoto/ApartmentPreviewImage"
import useApartmentStore from "../../../store"
import ApartmentStatuses from "../ApartmentStatuses"

function ApartmentOverviewHeader() {
  const { overviewStore } = useApartmentStore()

  const apartmentInfo = [
    {
      title: overviewStore.entrance,
      subtitle: "Entrance",
    },
    {
      title: overviewStore.doorCode,
      subtitle: "Doorcode",
    },
    {
      title: `${overviewStore.floor || "-"} / ${
        overviewStore.floorCount || "-"
      }`,
      subtitle: "Floor",
    },
  ]

  return (
    <AboutPageHeader
      icon={<ApartmentPreviewImage image={overviewStore.previewImage} />}
      title={overviewStore.objectName}
      status={<ApartmentStatuses />}
      subtitle={`Folder number: ${overviewStore.folderNumber || "-"}`}
      info={apartmentInfo}
    />
  )
}

export default observer(ApartmentOverviewHeader)
