import { makeAutoObservable } from "mobx"
import { DateTime } from "luxon"
import { Loader } from "kui-utils"
import ApartmentWaterMetersStore from "./ApartmentWaterMeters"
import ApartmentElectricityMetersStore from "./ApartmentElectricityMeters"
import ApartmentGasMetersStore from "./ApartmentGasMeters"
import ApartmentHeatingMetersStore from "./ApartmentHeatingMeters"
import ApartmentPageStore from "../../../../store/ApartmentPageStore"
import ApartmentExpensesStore from "../ApartmentExpenses"

/*
  Хранилище с общими сущностями счетчиков
*/

class ApartmentMetersStore {
  apartmentStore: ApartmentPageStore

  expensesStore: ApartmentExpensesStore

  waterMetersStore: ApartmentWaterMetersStore

  electricityMetersStore: ApartmentElectricityMetersStore

  gasMetersStore: ApartmentGasMetersStore

  heatingMetersStore: ApartmentHeatingMetersStore

  loader: Loader

  constructor(
    apartmentStore: ApartmentPageStore,
    expensesStore: ApartmentExpensesStore
  ) {
    this.apartmentStore = apartmentStore
    this.expensesStore = expensesStore
    this.waterMetersStore = new ApartmentWaterMetersStore(expensesStore)
    this.electricityMetersStore = new ApartmentElectricityMetersStore(
      expensesStore
    )
    this.gasMetersStore = new ApartmentGasMetersStore(expensesStore)
    this.heatingMetersStore = new ApartmentHeatingMetersStore(expensesStore)
    this.loader = new Loader(true)
    makeAutoObservable(this, { apartmentStore: false })
  }

  initCountersPage = async (
    apartmentId: number,
    date: DateTime,
    contractId?: number
  ) => {
    this.getMetersCount()
    await this.fetchMeters(apartmentId, date, contractId)
  }

  fetchMeters = async (
    apartmentId: number,
    date: DateTime,
    contractId?: number
  ) => {
    this.loader.startLoading()
    await Promise.allSettled([
      this.waterMetersStore.getWaterMeters(apartmentId, date, contractId),
      this.electricityMetersStore.getElectricityMeters(
        apartmentId,
        date,
        contractId
      ),
      this.gasMetersStore.getGasMeters(apartmentId, date, contractId),
      this.heatingMetersStore.getHeatingMeters(apartmentId, date, contractId),
    ])
    this.loader.endLoading()
  }

  getMetersCount = () => {
    const countersCount = this.apartmentStore.overviewStore.maxMetersCount

    if (countersCount) {
      this.waterMetersStore.updateCountersCount(countersCount.water || null)
      this.electricityMetersStore.updateCountersCount(
        countersCount.electricity || null
      )
      this.gasMetersStore.updateCountersCount(countersCount.gas || null)
      this.heatingMetersStore.updateCountersCount(countersCount.heating || null)
    }
  }

  cleanMetersTab = () => {
    this.waterMetersStore = new ApartmentWaterMetersStore(this.expensesStore)
    this.electricityMetersStore = new ApartmentElectricityMetersStore(
      this.expensesStore
    )
    this.gasMetersStore = new ApartmentGasMetersStore(this.expensesStore)
    this.heatingMetersStore = new ApartmentHeatingMetersStore(
      this.expensesStore
    )
    this.loader.endLoading()
  }
}

export default ApartmentMetersStore
