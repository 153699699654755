import React, { useState } from "react"
import { observer } from "mobx-react"
import {
  MeterFeaturesFormFields,
  MeterFeaturesForm,
  ElectricityMeterTypes,
  WaterMeterTypes,
} from "kui-crm"

import { WaterMeterFormProps } from "./types"
import useExpensesStore from "../../../store"
import SingleMeterStore from "../../../store/meters/SingleMeterStore"

function WaterMeterForm({
  handleClose,
  handleSubmit: onSubmit,
}: WaterMeterFormProps) {
  const { metersStore, overviewStore } = useExpensesStore()
  const waterStore = metersStore.waterMetersStore
  const formattedWaterMeters = waterStore?.activeWaterMeters
  const [replaceableMeters, setReplaceableMeters] =
    useState<SingleMeterStore[]>(formattedWaterMeters)
  const hasPayer = overviewStore.actualServiceContract?.metersPayers.water
  const operatingAccount = overviewStore.operatingAccounts?.electricity
  const isNewResource = waterStore?.waterMeters.length === 0 && !hasPayer

  const handleSubmit = (data: MeterFeaturesFormFields) => {
    const creationForm = waterStore?.creationForm
    if (creationForm) {
      onSubmit(data)
      creationForm.resetForm()
      handleClose()
    }
  }

  const updateReplaceableMeters = (
    meterType: ElectricityMeterTypes | WaterMeterTypes | null
  ) => {
    setReplaceableMeters(
      formattedWaterMeters?.filter((counter) =>
        meterType ? counter.type === meterType : counter
      ) || []
    )
  }

  return (
    <MeterFeaturesForm
      resource="water"
      formStore={waterStore?.creationForm}
      handleMeterTypeChange={updateReplaceableMeters}
      replacements={replaceableMeters}
      handleSubmit={handleSubmit}
      isNewResource={isNewResource}
      maxMeters={waterStore?.maxMetersCount}
      operatingAccount={operatingAccount}
      administrativeCompany={overviewStore.managementCompany}
      apartmentId={overviewStore.id}
    />
  )
}

export default observer(WaterMeterForm)
