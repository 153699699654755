import React from "react"
import { Caption, Grid } from "kui-basic"
import styled from "@emotion/styled"
import { DateWithDotProps, DateDotStylesProps } from "./types"

const DateWithDot = ({ date }: DateWithDotProps) => {
  const isClosingSoon = date.set({ day: 1 }).diffNow("month").months < 1
  const color = isClosingSoon ? "red" : "brand"

  return (
    <StyledWrapper container alignItems="center" wrap="nowrap">
      <Caption size="s" weight={500}>
        {date.toFormat("dd.MM.yyyy")}
      </Caption>
      <StyledDot color={color} />
    </StyledWrapper>
  )
}

export default DateWithDot

const StyledWrapper = styled(Grid)`
  width: fit-content;
  padding-left: 8px;
`

const StyledDot = styled.div<DateDotStylesProps>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: 4px;
  background: ${({ theme, color }) =>
    color === "red" ? theme.palette.red.fiftyP : theme.palette.brand.main};
`
