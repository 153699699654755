import { UserBankInfoFormFields } from "../../../../forms/ClientBankAccForm/types"
import { FieldGroupsParams } from "../../../../../../components/common/UpdatedFieldsGroup/types"

const userBankInfoFieldsGroup: FieldGroupsParams<UserBankInfoFormFields> = {
  title: "Bank account info",
  modalProps: {
    updatedTitle: "Change a personal bank acc",
  },
  fields: [
    {
      label: "Bank name",
      name: "bankInfo.bankName",
      size: 4,
      isRequired: true,
    },
    { label: "SWIFT", name: "bankInfo.SWIFT", size: 4, isRequired: true },
    {
      label: "Bank Identification Code",
      name: "bankInfo.BIC",
      size: 4,
      placeholder: "XXXXXXXXX",
      isRequired: true,
    },
    {
      label: "Correspondent account",
      name: "bankInfo.correspondentAccount",
      size: 6,
      placeholder: "30101XXXXXXXXXXXXXXX",
      isRequired: true,
    },
    {
      label: "Account",
      name: "bankInfo.account",
      size: 6,
      placeholder: "40XXXXXXXXXXXXXXXXXX",
      isRequired: true,
    },
  ],
}

export default userBankInfoFieldsGroup
