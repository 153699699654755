import React from "react"
import { useForm } from "react-hook-form"
import { observer } from "mobx-react"
import { Button, Grid } from "kui-basic"
import { PlusIcon } from "kui-icon"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormWrapper, InputByType } from "kui-crm"
import {
  StatisticalValuationFormFields,
  StatisticalValuationStepProps,
} from "./types"
import statisticalValuationFields from "./fields"
import ModalLabel from "../../../../../../common/ModalLabel"
import StatisticalValuationStepSchema from "./schema"
import useApartmentLiteStore from "../../../../../../../store/shared/apartment"

const StatisticalValuationStep = (props: StatisticalValuationStepProps) => {
  const { index, addStep } = props
  const { inspectionsStore } = useApartmentLiteStore()
  const formStore = inspectionsStore.creationForm
  const label = `Add ${
    formStore.fields?.statisticalValuations?.length || 1
  } statistical valuation`

  const form = useForm<StatisticalValuationFormFields>({
    defaultValues: {
      ...formStore.fields?.statisticalValuations?.[index],
    },
    resolver: yupResolver(StatisticalValuationStepSchema),
  })

  const handleSubmit = (data: StatisticalValuationFormFields) => {
    formStore.replaceFieldInArray("statisticalValuations", data, index)
    formStore.nextStep()
  }

  const handleAdd = (data: StatisticalValuationFormFields) => {
    formStore.replaceFieldInArray("statisticalValuations", data, index)
    addStep()
  }

  return (
    <>
      <ModalLabel label={label} />
      <FormWrapper
        form={form}
        onSubmit={form.handleSubmit(handleSubmit)}
        withPrevStep
        handlePrevClick={formStore.prevStep}
        variant="next"
        label="Next"
        endButton={
          <Button
            size="s"
            variant="orange"
            endIcon={<PlusIcon />}
            onClick={form.handleSubmit(handleAdd)}
          >
            Add valuation
          </Button>
        }
      >
        <Grid container spacing={2}>
          {statisticalValuationFields.map(({ size, ...field }) => (
            <Grid item xs={size || 12} key={field.name}>
              <InputByType form={form} {...field} />
            </Grid>
          ))}
        </Grid>
      </FormWrapper>
    </>
  )
}

export default observer(StatisticalValuationStep)
