import { HomeIcon } from "kui-icon"
import React from "react"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import DashboardListCard from "../../common/DashboardListCard"
import { ObjectsNeedInitialCardProps } from "./types"
import ObjectNeedInitialRow from "./ObjectNeedInitialRow"
import CardHeaderWithDone from "../../common/CardHeaderWithDone"

const ObjectsNeedInitialCard = (props: ObjectsNeedInitialCardProps) => {
  const { objectsStore } = props
  const { value, done, objects } = objectsStore

  return (
    <StyledCard
      value={value}
      icon={<HomeIcon />}
      customHeader={
        <CardHeaderWithDone
          value={value}
          done={done}
          valueLabel="Objects need initial inspection"
          doneLabel="Completed initial inspections"
        />
      }
    >
      {objects.map((object) => (
        <ObjectNeedInitialRow key={object.id} object={object} />
      ))}
    </StyledCard>
  )
}

export default observer(ObjectsNeedInitialCard)

const StyledCard = styled(DashboardListCard)`
  .PaginationList {
    max-height: 346px;
  }
`
