import React from "react"
import { observer } from "mobx-react"
import { useToggle } from "kui-utils"
import { LoaderState } from "kui-crm"
import useCompanyStore from "../../../store"
import Loading from "../../../../../components/common/Loading"
import Container from "../../../../../components/ui/Container"
import CompanyOfficeCreationModal from "./CompanyOfficeCreationModal"
import CompanyOfficesForm from "../../../forms/CompanyOfficesForm"
import { CompanyOfficesFields } from "../../../forms/CompanyOfficesForm/types"

function CompanyOfficesTab() {
  const { officesStore, overviewStore, actionLoader, editor } =
    useCompanyStore()
  const [isModalOpen, handleModalOpen, handleModalClose] = useToggle()

  const handleSubmit = (data: CompanyOfficesFields) => {
    if (overviewStore?.id) {
      officesStore.patchCompanyOffices(overviewStore.id, data)
    }
    editor.endEditing()
  }

  if (!overviewStore || actionLoader.isLoading) {
    return <Loading />
  }

  return (
    <Container>
      <CompanyOfficesForm
        handleSubmit={handleSubmit}
        handleClick={handleModalOpen}
      />
      <CompanyOfficeCreationModal
        open={isModalOpen}
        handleClose={handleModalClose}
      />

      <LoaderState loader={officesStore.loader} />
    </Container>
  )
}

export default observer(CompanyOfficesTab)
