import React from "react"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { Caption, Grid } from "kui-basic"
import { TypographyMoneyProps } from "./types"
import store from "../../../store"

function TypographyMoney(props: TypographyMoneyProps) {
  const { value, className, testId, prefix, unit, ...otherProps } = props
  const {
    appStore: { currencyCode },
  } = store
  const hasValue = typeof value === "number" || typeof value === "string"

  return (
    <StyledWrapper
      data-testid={testId}
      container
      alignItems="center"
      className={className}
    >
      <StyledValue className="TypographyMoney_value" {...otherProps}>
        {prefix}
        {hasValue ? Number(value).toLocaleString() : "-"}
      </StyledValue>
      {hasValue && (
        <StyledCurrencyCode {...otherProps}>
          {unit || currencyCode}
        </StyledCurrencyCode>
      )}
    </StyledWrapper>
  )
}

export default observer(TypographyMoney)

const StyledCurrencyCode = styled(Caption)`
  display: inline-block;
  width: 12px;
`

const StyledValue = styled(Caption)`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 4px;
  max-width: calc(100% - 12px);
  min-width: fit-content;
`

const StyledWrapper = styled(Grid)`
  display: inline-flex;
  width: auto;
  max-width: 100%;
  justify-content: flex-end;
  flex-wrap: nowrap;
`
