import React, { useEffect } from "react"
import { Box } from "kui-basic"
import { observer } from "mobx-react"
import getOwnerFieldsGroup from "./fields"
import { ApartmentOwnerFieldsProps } from "./types"
import OwnershipMemberModal from "../OwnershipMemberModal"
import { ApartmentOwnershipMembersFields } from "../OwnershipMemberModal/types"
import UpdatedFieldsGroup from "../../../../../../components/common/UpdatedFieldsGroup"
import { UpdatedFieldsModalProps } from "../../../../../../components/common/UpdatedFieldsModal/types"
import useEditor from "../../../../../../hooks/useEditor"
import useApartmentStore from "../../../../store"
import {
  ownerDefaultFields,
  proxyDefaultFields,
} from "../../../../forms/ownership/ApartmentOwnershipForm/content"

function ApartmentOwnerFields(props: ApartmentOwnerFieldsProps) {
  const { index, form } = props
  const { remove, appendOwner, appendProxy } = props
  const ownerFieldsGroup = getOwnerFieldsGroup(index)
  const { editor } = useApartmentStore()
  const ownerEditor = useEditor()

  const addMember = (data: ApartmentOwnershipMembersFields) => {
    if (data.memberType === "owner") {
      appendOwner(ownerDefaultFields)
    } else appendProxy(proxyDefaultFields)
  }

  const removeOwner = () => remove(index)

  useEffect(() => {
    if (!editor.isEditing) {
      ownerEditor.endEditing()
    }
  }, [editor.isEditing])

  useEffect(() => {
    const member = form.getValues(`owners.${index}.member.id`)
    if (!member && index > 0) ownerEditor.startEditing()
  }, [])

  return (
    <Box mb={3}>
      <UpdatedFieldsGroup
        disabled={!editor.isEditing}
        form={form}
        editor={ownerEditor}
        handleAdd={index === 0 && addMember}
        handleRemove={index !== 0 && removeOwner}
        AddModal={(modalProps: UpdatedFieldsModalProps) => (
          <OwnershipMemberModal {...modalProps} index={index} />
        )}
        name={`owners.${index}`}
        {...ownerFieldsGroup}
      />
    </Box>
  )
}

export default observer(ApartmentOwnerFields)
