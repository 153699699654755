import { useToggle } from "kui-utils"
import { UseModalProps } from "../types/common"

const useModalProps = (defaultValue?: boolean): UseModalProps => {
  const [open, handleOpen, handleClose] = useToggle(defaultValue)

  return { open, handleOpen, handleClose }
}

export default useModalProps
