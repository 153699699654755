import React from "react"
import { Modal } from "kui-crm"
import _ from "lodash"
import { ContractCheckoutDateModalProps } from "./types"
import { CheckoutDateFormFields } from "../../forms/contacts/ContractCheckoutDateForm/types"
import ContractCheckoutDateForm from "../../forms/contacts/ContractCheckoutDateForm"

const ContractCheckoutDateModal = (props: ContractCheckoutDateModalProps) => {
  const { setCheckoutDate, handleClose, open, label } = props
  const title = _.capitalize(`${label} date`)

  const handleSubmit = (data: CheckoutDateFormFields) => {
    setCheckoutDate(data)
    handleClose()
  }

  return (
    <Modal open={open} handleClose={handleClose} title={title}>
      <ContractCheckoutDateForm label={label} handleSubmit={handleSubmit} />
    </Modal>
  )
}

export default ContractCheckoutDateModal
