import React from "react"
import styled from "@emotion/styled"
import { Caption, Heading, Box, Grid, BoxSizing } from "kui-basic"
import { ApartmentSummaryTableProps } from "./types"
import ExpensesSummaryTableSection from "../ExpensesSummaryTableSection"
import ExpensesGroupedAccruals from "../ExpensesGroupedAccruals"

function ExpensesSummaryTable({ type, payout }: ApartmentSummaryTableProps) {
  return (
    <StyledWrapper
      data-testid={`payout_table_${type}`}
      pr={(type === "right" ? 0 : 2) as BoxSizing}
      pl={(type === "right" ? 2 : 0) as BoxSizing}
      pb={2}
    >
      <div>
        <ExpensesSummaryTableSection
          title="Income"
          lines={payout?.incomeLines}
        />
        <ExpensesGroupedAccruals accruals={payout?.accrualsLines} />
        <ExpensesSummaryTableSection
          title="Compensation"
          lines={payout?.compensationLines}
        />
        <ExpensesSummaryTableSection
          title="Payments"
          lines={payout?.payments}
        />
      </div>
      {!!payout?.totalPrice && (
        <Box mt={4}>
          <Grid container justify="space-between">
            <Grid item>
              <Caption size="s" weight={500} color="fiftyP">
                TOTAL
              </Caption>
            </Grid>
            <Grid item>
              <Heading data-testid={`total_price_${type}`} size="h3">
                {payout.totalPrice.toLocaleString()} ₽
              </Heading>
            </Grid>
          </Grid>
        </Box>
      )}
    </StyledWrapper>
  )
}

export default ExpensesSummaryTable

const StyledWrapper = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
