import React from "react"
import { observer } from "mobx-react"
import { Grid } from "kui-basic"
import useRCStore from "../../store"
import RCClosingButtons from "../RCClosingButtons"
import { RCFieldsHeaderProps } from "./types"
import RCEditingButtons from "../RCEditingButtons"

const RCFieldsHeader = ({ form }: RCFieldsHeaderProps) => {
  const { contractInfoStore } = useRCStore()

  if (contractInfoStore.isClosed) {
    return null
  }

  return (
    <Grid container spacing={3} alignItems="center" justify="space-between">
      <RCClosingButtons />

      <RCEditingButtons form={form} />
    </Grid>
  )
}

export default observer(RCFieldsHeader)
