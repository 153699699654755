import React from "react"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import { ApartmentFillingImagesProps } from "./types"
import ControlledImagesBlock from "../../../../common/ControlledImagesBlock"
import { maxWidth } from "../../../../../utils/service/theme"
import { UploadFillingPhotoFields } from "../../../../../pages/ApartmentPage/forms/filling/UploadFillingPhoto/types"
import { FillingParams } from "../ApartmentFillingCard/types"

function ApartmentFillingImages<T extends FillingParams>(
  props: ApartmentFillingImagesProps<T>
) {
  const { filling, onDeletePhoto, onAddPhoto, index, disabled } = props

  const formattedImages =
    filling.images?.map((image) => ({
      ...image,
      smallImageUrl:
        "smallImageUrl" in image ? image.smallImageUrl : image.url!,
      largeImageUrl:
        "largeImageUrl" in image ? image.largeImageUrl : image.url!,
    })) || []

  const deletePhoto = (imageIndex?: number) => {
    if (typeof imageIndex === "number" && filling.images && onDeletePhoto) {
      onDeletePhoto(filling, index, imageIndex)
    }
  }

  const addPhoto = (data: UploadFillingPhotoFields) => {
    if (onAddPhoto) onAddPhoto(filling, data, index)
  }

  return (
    <StyledAdaptiveImagesBlock
      data-testid="filling_images"
      images={formattedImages}
      justify="flex-end"
      name="images"
      onAdd={onAddPhoto && addPhoto}
      disabled={disabled}
      onDelete={deletePhoto}
    />
  )
}

export default observer(ApartmentFillingImages)

const StyledAdaptiveImagesBlock = styled(ControlledImagesBlock)`
  margin-left: auto;
  max-width: 360px;
  ${maxWidth("sm")} {
    max-width: unset;
    justify-content: space-between;
  }
`
