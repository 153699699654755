import React from "react"
import { observer } from "mobx-react"
import { Modal, ModalProps } from "kui-crm"
import useCompanyStore from "../../../../store"
import CompanyChangeForm from "../../../../../../components/forms/company/CompanyChangeForm"
import { CompanyChangeFormFields } from "../../../../../../components/forms/company/CompanyChangeForm/types"

function CompanyObjectsConnectionModal({ open, handleClose }: ModalProps) {
  const { objectsStore, overviewStore } = useCompanyStore()
  const selectedObjectsCount = objectsStore.selectedObjects.length

  const handleSubmit = (data: CompanyChangeFormFields) => {
    if (overviewStore?.id) {
      objectsStore.changeCompanyForSelectedObjects(overviewStore.id, data)
      handleClose()
    }
  }

  return (
    <Modal
      title={`Change company for ${selectedObjectsCount} objects`}
      open={open}
      handleClose={handleClose}
    >
      <CompanyChangeForm handleSubmit={handleSubmit} />
    </Modal>
  )
}

export default observer(CompanyObjectsConnectionModal)
