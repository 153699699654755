import { createContext, useContext } from "react"
import { apartmentStore } from "../../../store"
import InspectionPageStore from "./InspectionPageStore"

const inspectionStore = new InspectionPageStore(apartmentStore)
const InspectionContext = createContext(inspectionStore)

const useInspectionStore = () => useContext(InspectionContext)

export default useInspectionStore
export { inspectionStore, InspectionContext }
