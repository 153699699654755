import React from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormWrapper, InputFileWithVisibility } from "kui-crm"
import { EditingChangeFormFields, EditingChangeFormProps } from "./types"
import EditChangeFormSchema from "./schema"

const EditingChangeForm = (props: EditingChangeFormProps) => {
  const { handleSubmit } = props
  const form = useForm<EditingChangeFormFields>({
    resolver: yupResolver(EditChangeFormSchema),
  })

  return (
    <FormWrapper form={form} onSubmit={handleSubmit} label="Start editing">
      <InputFileWithVisibility
        data-testid="changeDocument"
        label="Document"
        name="document"
        form={form}
      />
    </FormWrapper>
  )
}

export default EditingChangeForm
