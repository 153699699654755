import React from "react"
import { Caption, Grid } from "kui-basic"
import { Tooltip } from "kui-complex"
import styled from "@emotion/styled"
import { SyncWithInfoIcon } from "kui-icon"
import {
  meterAutoSendingHint,
  meterAverageValueHint,
} from "../../../../../../../../utils/content/constants"
import { MeterLabelProps } from "./types"
import AverageValueIcon from "../../../../../../../../components/icons/AverageValueIcon"

const MeterLabel = ({ meter }: MeterLabelProps) => {
  const { title, withAutoSending, hasAverageValue } = meter

  return (
    <Grid container alignItems="center" wrap="nowrap">
      <span>{title}</span>
      {withAutoSending && (
        <StyledTooltip
          content={<Caption size="xs">{meterAutoSendingHint}</Caption>}
        >
          <StyledAutoIcon />
        </StyledTooltip>
      )}
      {hasAverageValue && (
        <StyledTooltip
          content={<Caption size="xs">{meterAverageValueHint}</Caption>}
        >
          <StyledAverageIcon />
        </StyledTooltip>
      )}
    </Grid>
  )
}

export default MeterLabel

const StyledAutoIcon = styled(SyncWithInfoIcon)`
  margin-left: 4px;
  path {
    fill: ${({ theme }) => theme.palette.brand.main};
  }
`

const StyledAverageIcon = styled(AverageValueIcon)`
  margin-left: 4px;
  path {
    fill: ${({ theme }) => theme.palette.brand.main};
  }
`

const StyledTooltip = styled(Tooltip)`
  width: fit-content;
  .Tooltip_content {
    z-index: auto;
  }
`
