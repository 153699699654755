import { makeAutoObservable, runInAction } from "mobx"
import { Loader, Paginator, resHandler } from "kui-utils"
import to from "await-to-js"
import DashboardPageStore from "../DashboardPageStore"
import DashboardAgent from "../../../../agent/Dashboard"
import ApartmentsStore from "../../../../store/lites/ApartmentsStore"
import { ObjectNeedTenantModel } from "../../types/api/apartments"
import { ObjectNeedTenantParams } from "../../types/cards/apartments"

class ObjectsNeedTenantStore {
  value: number | null

  objects: ObjectNeedTenantParams[]

  paginator: Paginator

  loader: Loader

  dashboardStore: DashboardPageStore

  constructor(dashboardStore: DashboardPageStore) {
    this.value = null
    this.objects = []
    this.paginator = new Paginator(5)
    this.loader = new Loader()
    this.dashboardStore = dashboardStore
    makeAutoObservable(this)
  }

  fetchObjects = async () => {
    this.loader.startLoading()

    const response = await to(
      DashboardAgent.getObjectsWithoutTenant(
        this.paginator.limit,
        this.paginator.offset,
        this.dashboardStore.periodFilter
      )
    )

    runInAction(() => {
      resHandler(response, this.loader, (res) => {
        this.value = res.count
        this.objects = this.paginator.getPageResponse(
          res,
          this.objects,
          ObjectsNeedTenantStore.getObjectParams
        )
      })
    })
  }

  static getObjectParams = (
    object: ObjectNeedTenantModel
  ): ObjectNeedTenantParams => ({
    id: object.id,
    apartment: ApartmentsStore.getApartmentLinkParams(object.apartment),
    daysWithoutTenant: object.days_without_tenant,
    isOverdue: object.is_overdue,
  })
}

export default ObjectsNeedTenantStore
