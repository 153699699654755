import React, { ReactElement } from "react"
import { PartialMeterInfoFields } from "kui-crm_actions"
import PartialMeterInfoStep from "./index"
import MeterPhotoStep from "../MeterPhotoStep"
import renderWaterMeterSteps from "../WaterMeterInfoStep/renderWaterMeterSteps"

const renderNotWaterMeterSteps = (
  meters: PartialMeterInfoFields[],
  waterMetersLength: number,
  withCheckValue?: boolean
): ReactElement[][] =>
  meters.map((meter, index) => [
    <PartialMeterInfoStep
      index={index + waterMetersLength}
      key={`meter_info_${index}`}
      resource={meter.resource}
      type={meter.type}
      withCheckValue={withCheckValue}
    />,
    <MeterPhotoStep
      key={`meter_photo_${index}`}
      index={index + waterMetersLength}
      resource={meter.resource}
    />,
  ])

const renderPartialMeterSteps = (
  meters: PartialMeterInfoFields[],
  withCheckValue?: boolean
): ReactElement[][] => {
  const waterMeters = meters?.filter((meter) => meter.resource === "water")
  const otherMeters = meters?.filter((meter) => meter.resource !== "water")

  return [
    ...renderWaterMeterSteps(waterMeters, withCheckValue),
    ...renderNotWaterMeterSteps(
      otherMeters,
      waterMeters?.length || 0,
      withCheckValue
    ),
  ]
}
export default renderPartialMeterSteps
