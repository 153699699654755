import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { observer } from "mobx-react"
import { yupResolver } from "@hookform/resolvers/yup"
import { InitialInspectionFormFields } from "./types"
import InitialInspectionInfoFields from "./InitialInspectionInfoFields"
import InspectionRoomsPhoto from "../../components/InspectionRoomsPhoto"
import useInspectionStore from "../../store"
import InitialInspectionStore from "../../store/variations/InitialInspectionStore"
import InspectionTitle from "../../components/InspectionTitle"
import InitialInspectionInfoSchema from "./schema"

const InitialInspectionInfo = () => {
  const { inspectionInfo, editor, patchInspection } = useInspectionStore()
  const inspection = inspectionInfo as InitialInspectionStore
  const form = useForm<InitialInspectionFormFields>({
    defaultValues: {
      ...inspection.mainObjectInfo,
      ...inspection.detailObjectInfo,
      ...inspection.securityObjectInfo,
      ...inspection.houseInfo,
      metroStations: inspection.metroStations,
      apartment: {
        address: inspection.mainObjectInfo.address,
        location: inspection.mainObjectInfo.geolocation,
      },
      roomsImages: inspection.roomsImages,
      dropboxLink: inspection.dropboxLink,
    },
    resolver: yupResolver(InitialInspectionInfoSchema),
  })

  useEffect(() => {
    if (editor.isAllowedToSendForm) {
      form.handleSubmit(patchInspection, editor.blockToSendForm)()
    }
  }, [editor.isAllowedToSendForm])

  return (
    <>
      <InspectionTitle form={form} />
      <InitialInspectionInfoFields form={form} />
      <InspectionRoomsPhoto form={form} />
    </>
  )
}

export default observer(InitialInspectionInfo)
