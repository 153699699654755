import * as React from "react"
import { css, Theme } from "@emotion/react"
import styled from "@emotion/styled"
import { Caption } from "kui-basic"
import { SearchIcon } from "kui-icon"
import InputWithAdornment from "../InputWithAdornment"
import { InputWithAdornmentsProps } from "../InputWithAdornment/types"

function InputSearch<TFormValues extends object>(
  props: InputWithAdornmentsProps<TFormValues>
) {
  return (
    <StyledInput
      startIcon={
        <IconWrapper>
          <SearchIcon />
        </IconWrapper>
      }
      endIcon={
        <EndIconWrapper>
          <Caption weight={600} size="xs" color="fiftyP">
            FILTER
          </Caption>
        </EndIconWrapper>
      }
      placeholder="Search.."
      testId="input_search"
      {...props}
    />
  )
}

export default InputSearch

const inputCSS = ({ theme }: { theme: Theme }) => css`
  background: ${theme.palette.background.light1} !important;
  border-radius: 66px;
  height: calc(40px * 16 / 14);
  border: 1.5px solid ${theme.palette.background.light3};
`

const StyledInput = styled(InputWithAdornment)`
  width: 368px;
  height: 40px !important;
  & .KUI-Input_label {
    top: -9px;
  }
  & .KUI-Input {
    ${inputCSS};
  }
`

const IconWrapper = styled.div`
  padding-left: 10px;
`

const EndIconWrapper = styled.div`
  background: ${({ theme }) => theme.palette.grey.zero};
  padding: 5px 8px;
  border-radius: 66px;
  cursor: pointer;
`
