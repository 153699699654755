import React from "react"
import { Grid } from "kui-basic"
import styled from "@emotion/styled"
import { GridTableCellStylesProps, GridTableRowProps } from "./types"

function GridTableRow(props: GridTableRowProps) {
  const { cellsWidth, children, ...other } = props
  const childrenList = React.Children.toArray(children)
  const formattedWidths: string[] = []
  let widthIndex = 0
  const formattedChildrenList = childrenList.map((child, index) => {
    if (
      typeof child === "object" &&
      "props" in child &&
      child.props["data-size"]
    ) {
      const cellSize = child.props["data-size"]
      const childWidths = cellsWidth
        .slice(widthIndex, widthIndex + cellSize)
        .map((cell) => parseInt(cell, 10))
      const cellWidth = childWidths.reduce((sum, cell) => sum + cell, 0)
      const unit = cellsWidth[widthIndex].replace(/\d/g, "")
      formattedWidths.push(`${cellWidth}${unit}`)
      widthIndex += cellSize
      return React.cloneElement(child, { cellsWidth: childWidths })
    }
    formattedWidths.push(cellsWidth[widthIndex])
    widthIndex += 1
    return child
  })

  return (
    <Grid
      container
      alignItems="center"
      justify="space-between"
      {...other}
      className={`GridRow ${other.className || ""}`}
    >
      {formattedWidths.map((cellWidth, index) => (
        <StyledCell className="GridCell" item width={cellWidth}>
          {formattedChildrenList[index]}
        </StyledCell>
      ))}
    </Grid>
  )
}

export default GridTableRow

const StyledCell = styled(Grid)<GridTableCellStylesProps>`
  width: ${({ width }) => width || "auto"};
  &,
  > p {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: ${({ theme }) => theme.palette.grey.seventy};
  }
`
