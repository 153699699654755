import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { Box, Grid } from "kui-basic"
import {
  ApartmentOverviewInfoFields,
  ApartmentOverviewInfoFormProps,
} from "./types"
import useApartmentStore from "../../../store"
import InputsGroupWithTitle from "../../../../../components/common/InputsGroupWithTitle"
import apartmentOverviewFieldsGroup, { ApartmentInfoInputs } from "./fields"
import UpdatedFieldsGroup from "../../../../../components/common/UpdatedFieldsGroup"
import useEditor from "../../../../../hooks/useEditor"

function ApartmentOverviewInfoForm({ form }: ApartmentOverviewInfoFormProps) {
  const { overviewStore, editor } = useApartmentStore()
  const overviewEditor = useEditor()
  const disabled = !editor.isEditing

  const handleSubmit = (data: ApartmentOverviewInfoFields) => {
    if (overviewStore.id) {
      overviewStore.patchOverviewInfo(overviewStore.id, data)
    }
    editor.endEditing()
    overviewEditor.endEditing()
  }

  useEffect(() => {
    if (editor.isAllowedToSendForm) {
      form.handleSubmit(handleSubmit, editor.blockToSendForm)()
    }
  }, [editor.isAllowedToSendForm])

  return (
    <>
      <InputsGroupWithTitle
        title="Info"
        form={form}
        isEditing={!disabled}
        fields={ApartmentInfoInputs}
        alignItems="flex-start"
      />
      <Box mt={3}>
        <Grid container spacing={2}>
          <UpdatedFieldsGroup
            disabled={disabled}
            form={form}
            editor={overviewEditor}
            name="paymentInfo"
            {...apartmentOverviewFieldsGroup}
          />
        </Grid>
      </Box>
    </>
  )
}

export default observer(ApartmentOverviewInfoForm)
