import React, { useEffect, useRef } from "react"
import styled from "@emotion/styled"
import { DropdownMenuProps } from "../DropdownMenu/types"
import DropdownOption from "./DropdownOption"

function DropdownMenuBase({
  options,
  isOpen,
  handleClose,
  buttonRef,
  children,
  menuItemStyles,
  ...other
}: DropdownMenuProps) {
  const dropdownRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const closeDropdown = (e: any) => {
      if (dropdownRef.current && buttonRef && buttonRef.current) {
        if (
          !dropdownRef.current.contains(e.target) &&
          !buttonRef.current.contains(e.target)
        ) {
          handleClose()
        }
      }
    }

    document.addEventListener("click", closeDropdown)
    return () => {
      document.removeEventListener("click", closeDropdown)
    }
  }, [buttonRef, handleClose])

  const handleOptionClick = (callback: () => void) => {
    callback()
    handleClose()
  }

  return (
    <StyledMenu
      data-testid="menu_dropdown"
      ref={dropdownRef}
      hidden={!isOpen}
      {...other}
    >
      {options?.map((option) => (
        <DropdownOption
          key={option.label}
          option={option}
          menuItemStyles={menuItemStyles}
          handleOptionClick={handleOptionClick}
        />
      ))}
      {children}
    </StyledMenu>
  )
}

export default DropdownMenuBase

const StyledMenu = styled.div`
  padding: 8px;
  background: ${({ theme }) => theme.palette.grey.zero};
  box-shadow: 0px 26px 34px rgba(0, 0, 0, 0.1);
  border: 1px solid ${({ theme }) => theme.palette.background.light1};
  position: absolute;
  right: 0;
  border-radius: 12px;
  width: max-content;
  z-index: 1000;
`
