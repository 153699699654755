import React from "react"
import _ from "lodash"
import { Link } from "kui-crm"
import { MeterReplacementRowProps } from "./types"
import TableRow from "../../../../components/ui/TableRow"
import TableCellWithLabel from "../../../../components/common/TableCellWithLabel"
import { Routes } from "../../../../types/route"
import TableCell from "../../../../components/ui/TableCell"
import LinkButton from "../../../../components/common/LinkButton"

function MeterReplacementRow({
  isDark,
  replacement,
}: MeterReplacementRowProps) {
  return (
    <TableRow isDark={isDark}>
      <TableCell textColor="gray" isFirst width="6%">
        {replacement.id}
      </TableCell>
      <TableCellWithLabel
        title={
          <Link
            href={`${Routes.meteringDevices}/${replacement.resource}/${replacement.id}`}
          >
            {replacement.passport}
          </Link>
        }
        label="Number"
        width="11%"
      />
      {replacement.type && (
        <TableCellWithLabel
          title={_.capitalize(replacement.type)}
          label="Type"
          width="8%"
        />
      )}
      <TableCell width={replacement.type ? "56%" : "64%"}>
        <LinkButton
          href={
            replacement.tariffGroup &&
            `${Routes.tariffsGroups}/${replacement.resource}/${replacement.tariffGroup?.id}`
          }
        >
          {replacement.tariffGroup?.name}
        </LinkButton>
      </TableCell>
      <TableCellWithLabel
        title={replacement.startDate?.toFormat("dd.MM.yyyy") || "-"}
        label="Activation date"
        width="10%"
      />
      <TableCellWithLabel
        isLast
        title={replacement.nextCheck?.toFormat("dd.MM.yyyy") || "-"}
        label="Next check"
      />
    </TableRow>
  )
}

export default MeterReplacementRow
