import {
  CountryAutocomplete,
  RegionAutocomplete,
  CityAutocomplete,
  DistrictAutocomplete,
} from "kui-crm"
import { InputParams } from "../../../../components/common/InputsGroupWithTitle/types"
import { TariffOverviewFormFields } from "../../forms/TariffOverviewInfoForm/types"

const tariffOverviewInfoFields: InputParams<TariffOverviewFormFields>[] = [
  {
    name: "country",
    variant: "custom",
    CustomInput: CountryAutocomplete,
    disabled: true,
  },
  {
    name: "region",
    variant: "custom",
    CustomInput: RegionAutocomplete,
  },
  {
    name: "city",
    variant: "custom",
    CustomInput: CityAutocomplete,
  },
  {
    name: "district",
    variant: "custom",
    CustomInput: DistrictAutocomplete,
  },
  {
    name: "residentialComplex",
    label: "Residential complex",
  },
  { name: "startDate", label: "Start date", variant: "date" },
  { name: "name", label: "Name", size: 6 },
]

export default tariffOverviewInfoFields
