import React from "react"
import { EditIcon } from "kui-icon"
import { observer } from "mobx-react"
import { UnsignedInspectionsCardProps } from "./types"
import DashboardListCard from "../../common/DashboardListCard"
import InspectionRow from "./InspectionRow"

const UnsignedInspectionsCard = (props: UnsignedInspectionsCardProps) => {
  const { inspectionsStore } = props
  const { value, inspections, loader, fetchInspections, paginator } =
    inspectionsStore

  return (
    <DashboardListCard
      value={value}
      label="Unsigned inspections"
      icon={<EditIcon />}
      loader={loader}
      paginator={paginator}
      fetchAll={fetchInspections}
    >
      {inspections.map((inspection) => (
        <InspectionRow key={inspection.id} inspection={inspection} />
      ))}
    </DashboardListCard>
  )
}

export default observer(UnsignedInspectionsCard)
