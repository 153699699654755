import React from "react"
import { ContractIncreasedRentRowProps } from "./types"
import ContractWithPriceRow from "../../../common/ContractWithPriceRow"

const ContractIncreasedRentRow = (props: ContractIncreasedRentRowProps) => {
  const { contract } = props
  const { apartment, increasedRentValue } = contract

  return (
    <ContractWithPriceRow
      contract={contract}
      apartment={apartment}
      value={increasedRentValue}
    />
  )
}

export default ContractIncreasedRentRow
