import * as yup from "yup"
import { OwnershipShareTypes } from "../../../types/api/apartmentOwnershipAPI"

const ApartmentOwnerSchema = yup.object({
  member: yup.object({
    name: yup.string().required("This field is required"),
  }),
  typeOfShare: yup.string().required("The field is required").nullable(),
  shareOfOwnership: yup
    .string()
    .when("typeOfShare", {
      is: (value: OwnershipShareTypes) => value === "proportion",
      then: (rule) =>
        rule
          .matches(/^(\d+)\/(\d+)$/, "Enter the value in fraction format")
          .required("The field is required"),
    })
    .when("typeOfShare", {
      is: (value: OwnershipShareTypes) => value === "percent",
      then: (rule) =>
        rule
          .matches(/^\d*[1-9]\d*$/, "Enter the positive number")
          .required("The field is required"),
    })
    .nullable(),
})

const ApartmentProxySchema = yup.object({
  member: yup.object({
    name: yup.string().required("This field is required"),
  }),
})

const ApartmentOwnershipFormSchema = yup.object().shape({
  owners: yup.array().of(ApartmentOwnerSchema),
  proxies: yup.array().of(ApartmentProxySchema),
})

export default ApartmentOwnershipFormSchema
