import React, { useState } from "react"
import { observer } from "mobx-react"
import { Box } from "kui-basic"
import { LoaderState } from "kui-crm"
import MetersTable from "../MetersTable"
import ComplexMeterRow from "../ComplexMeterRow"
import CreatingGasMeterModal from "../CreatingGasMeterModal"
import EditGasMeterModal from "../EditGasMeterModal"
import useExpensesStore from "../../../../store"

function ApartmentGasMetersTable() {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const { metersStore, periodsStore } = useExpensesStore()
  const { gasMetersStore } = metersStore
  const gasMeters = gasMetersStore?.activeGasMeters
  const { selectedPeriod } = periodsStore
  const disabled = !selectedPeriod?.canBeEdited

  const handleCloseModal = () => {
    setIsOpenModal(false)
  }

  const handleOpenModal = () => {
    setIsOpenModal(true)
  }

  const handleEditModalOpen = async (counterId: number) =>
    gasMetersStore?.getGasMeterById(counterId)

  return (
    <>
      <Box mb={3}>
        <MetersTable
          handleAdd={handleOpenModal}
          title="Gas"
          metersCount={gasMeters?.length}
          maxMetersCount={gasMetersStore?.maxCountersCount}
          total={gasMetersStore?.total}
        >
          {gasMeters?.map((counter) => (
            <ComplexMeterRow
              counter={counter}
              tariff={counter.tariffValues}
              disabled={disabled}
              onModalOpen={handleEditModalOpen}
              ModalComponent={EditGasMeterModal}
            />
          ))}
        </MetersTable>
      </Box>
      <CreatingGasMeterModal
        open={isOpenModal}
        handleClose={handleCloseModal}
      />

      <LoaderState loader={gasMetersStore.loader} onlyError />
      <LoaderState loader={gasMetersStore.actionLoader} />
    </>
  )
}

export default observer(ApartmentGasMetersTable)
