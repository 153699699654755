import React from "react"
import { observer } from "mobx-react"
import { Box } from "kui-basic"
import useExpensesStore from "../../../../store"
import Loading from "../../../../../../../../components/common/Loading"
import ApartmentWaterMetersTable from "../ExpensesWaterMetersTable"
import ApartmentElectricityMetersTable from "../ExpensesElectricityMetersTable"
import ApartmentGasMetersTable from "../ExpensesGasMetersTable"
import ApartmentHeatingMetersTable from "../ExpensesHeatingMetersTable"
import ApartmentArchivedMetersTable from "../ExpensesArchivedMetersTable"

function ExpensesMetersTables() {
  const { metersStore } = useExpensesStore()

  if (metersStore.loader.isLoading) {
    return <Loading height="60vh" />
  }

  return (
    <Box pt={3}>
      <ApartmentWaterMetersTable />
      <ApartmentElectricityMetersTable />
      <ApartmentGasMetersTable />
      <ApartmentHeatingMetersTable />
      <ApartmentArchivedMetersTable />
    </Box>
  )
}

export default observer(ExpensesMetersTables)
