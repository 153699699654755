import React from "react"
import { observer } from "mobx-react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box } from "kui-basic"
import { FormBottom } from "kui-crm"
import SCCommonTermsFormSchema from "./schema"
import useSCsStore from "../../store"
import SCCommonFields from "../../components/SCCommonFields"
import { ServiceContractCommonTermsFields } from "../CreateServiceContractForm/types"
import scCommonTermsGroupsFields from "./fields"
import InputsModalGroupWithTitle from "../../../../components/common/InputsModalGroupWithTitle"

function SCCommonTermsForm() {
  const { creationForm } = useSCsStore()
  const { fields, nextStep, prevStep, updateFormFields } = creationForm

  const form = useForm<ServiceContractCommonTermsFields>({
    resolver: yupResolver(SCCommonTermsFormSchema),
    defaultValues: {
      ...fields,
      initialCommission: fields?.initialCommission ?? 30,
      maintenanceCommission: fields?.maintenanceCommission ?? 10,
      advertisingPeriod: fields?.advertisingPeriod ?? 1,
      allowablePriceDeviation: fields?.allowablePriceDeviation ?? 10,
      inspections: fields?.inspections ?? 3,
      firstAppraisalPeriod: fields?.firstAppraisalPeriod ?? 9,
    },
  })

  const handleSubmit = (data: ServiceContractCommonTermsFields) => {
    nextStep()
    updateFormFields(data)
  }

  return (
    <form data-testid="form_step_3" onSubmit={form.handleSubmit(handleSubmit)}>
      <SCCommonFields form={form} />

      <Box pl={5} pr={5} mb={3}>
        {scCommonTermsGroupsFields.map((fieldGroup) => (
          <InputsModalGroupWithTitle
            key={fieldGroup.title}
            form={form}
            {...fieldGroup}
          />
        ))}
      </Box>
      <FormBottom
        withPrevStep
        variant="next"
        label="Next"
        handlePrevClick={prevStep}
      />
    </form>
  )
}

export default observer(SCCommonTermsForm)
