import React from "react"
import { observer } from "mobx-react"
import { LoaderState } from "kui-crm"
import Container from "../../../../components/ui/Container"
import NoteBlock from "../../../../components/common/NoteBlock"
import useTariffStore from "../../store"
import TariffOverviewInfoForm from "../../forms/TariffOverviewInfoForm"
import TariffReplacementsTable from "../TariffReplacementsTable"
import { TariffOverviewFormFields } from "../../forms/TariffOverviewInfoForm/types"

function TariffOverviewTab() {
  const tariffStore = useTariffStore()
  const { overviewStore } = tariffStore

  const handleSubmit = (data: TariffOverviewFormFields) => {
    const tariffId = overviewStore.tariffInfoStore?.id
    const { resource } = tariffStore
    if (tariffId && resource) {
      tariffStore.overviewStore.patchTariffInfo(tariffId, resource, data)
      tariffStore.editor.endEditing()
    }
  }

  return (
    <>
      <Container>
        <TariffOverviewInfoForm handleSubmit={handleSubmit} />
      </Container>

      <TariffReplacementsTable />

      <Container>
        {overviewStore.notesStore && (
          <NoteBlock notesStore={overviewStore.notesStore} />
        )}
      </Container>

      <LoaderState loader={overviewStore.loader} />
    </>
  )
}

export default observer(TariffOverviewTab)
