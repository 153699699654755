import * as React from "react"
import styled from "@emotion/styled"
import { Button } from "kui-basic"
import { DoorIcon } from "kui-icon"
import { ApartmentMarkerProps, IconStylesProps } from "./types"

function ApartmentMarker({
  active,
  apartment,
  ...otherProps
}: ApartmentMarkerProps) {
  return (
    <StyledMarker
      variant={active ? "primary" : "whiteWithGray"}
      isActive={active}
      startIcon={<DoorIcon width={16} height={16} />}
      {...otherProps}
    >
      {apartment.roomsNumber}-комн.
    </StyledMarker>
  )
}

export default ApartmentMarker

const StyledMarker = styled(Button)<IconStylesProps>`
  cursor: pointer;
  z-index: ${({ isActive }) => (isActive ? 1 : 0)};
  position: relative;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  height: 38px;
  border-color: transparent;
  svg {
    path {
      fill: ${({ theme, isActive }) =>
        isActive ? "white" : theme.palette.grey.fiftyP};
    }
  }
`
