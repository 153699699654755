import styled from "@emotion/styled"
import React from "react"
import { Caption, Grid } from "kui-basic"
import { IconWithTextProps } from "./types"

function IconWithText({ icon, text, ...otherProps }: IconWithTextProps) {
  return (
    <StyledWrapper container alignItems="center" {...otherProps}>
      {icon}
      <Caption size="sm" weight={500} color="sixty">
        {text}
      </Caption>
    </StyledWrapper>
  )
}

export default IconWithText

const StyledWrapper = styled(Grid)`
  width: fit-content;
  svg {
    margin-right: 9.5px;
    path {
      fill: ${({ theme }) => theme.palette.grey.fourty};
    }
  }
`
