import * as React from "react"
import { Heading, Box } from "kui-basic"
import Container from "../../ui/Container"
import Table from "../../ui/Table"
import ContractRow from "../ContractRow"
import { ContractsTableProps } from "./types"
import { Routes } from "../../../types/route"

function ContractsTable(props: ContractsTableProps) {
  const { rentalContracts, serviceContracts, withApartment, label } = props

  if (rentalContracts?.length === 0 && serviceContracts?.length === 0) {
    return null
  }

  return (
    <>
      <Container>
        <Box pt={3}>
          <Heading size="h4">{label || "Contracts"}</Heading>
        </Box>
      </Container>
      <Box mt={2}>
        <Table>
          <tbody>
            {rentalContracts?.map((contract, index: number) => (
              <ContractRow
                key={contract.id}
                isDark={index % 2 !== 0}
                contract={contract}
                href={`${Routes.rental_contracts}/${contract.id}`}
                withApartment={withApartment}
              />
            ))}
            {serviceContracts?.map((contract, index: number) => (
              <ContractRow
                key={contract.id}
                isDark={
                  Number(rentalContracts?.length || 0) % 2 === 0
                    ? index % 2 !== 0
                    : index % 2 === 0
                }
                contract={contract}
                href={`${Routes.service_contracts}/${contract.id}`}
                withApartment={withApartment}
              />
            ))}
          </tbody>
        </Table>
      </Box>
    </>
  )
}

export default ContractsTable
