import React, { useEffect, useRef } from "react"
import styled from "@emotion/styled"
import { Caption, Grid } from "kui-basic"
import { useToggle } from "kui-utils"
import { CommentIcon } from "kui-icon"
import { Tooltip } from "kui-crm"
import { CommentStylesProps, TableCellWithCommentProps } from "./types"
import TextOverflow from "../TextOverflow"

function TextOverflowWithComment({
  children,
  className,
  comment,
  content,
  name,
  ...otherProps
}: TableCellWithCommentProps) {
  const [isTooltipOpen, openTooltip, closeTooltip] = useToggle()
  const iconRef = useRef<SVGSVGElement>(null)

  const handleCommentClick = (e: any) => {
    e.stopPropagation()
    openTooltip()
  }

  useEffect(() => {
    const handleCloseTooltip = (e: any) => {
      if (!iconRef.current?.contains(e.target)) {
        closeTooltip()
      }
    }

    document.addEventListener("click", handleCloseTooltip)
    return () => {
      document.removeEventListener("click", handleCloseTooltip)
    }
  }, [])

  return (
    <Grid container alignItems="center" wrap="nowrap">
      <StyledTextOverflow onOpen={closeTooltip} color="fiftyP" {...otherProps}>
        {children}
      </StyledTextOverflow>
      {(comment || content) && (
        <Tooltip
          open={isTooltipOpen}
          content={
            <>
              {name && (
                <>
                  <Caption size="xs" color="fiftyP">
                    {name}
                  </Caption>
                  <br />
                </>
              )}
              <Caption size="xs">{comment}</Caption>
              {content}
            </>
          }
          className={className}
        >
          <StyledCommentIcon
            isTooltipOpen={isTooltipOpen}
            ref={iconRef}
            onClick={handleCommentClick}
          />
        </Tooltip>
      )}
    </Grid>
  )
}

export default TextOverflowWithComment

const StyledCommentIcon = styled(CommentIcon)<CommentStylesProps>`
  margin-left: 8px;
  cursor: pointer;
  margin-bottom: 4px;
  path {
    transition: all ease-out 0.3s;
    fill: ${({ theme, isTooltipOpen }) =>
      isTooltipOpen ? theme.palette.brand.main : theme.palette.grey.thirty};
  }
  &:hover {
    path {
      fill: ${({ theme }) => theme.palette.brand.main};
    }
  }
`

const StyledTextOverflow = styled(TextOverflow)`
  max-width: calc(100% - 22px);
  .KUI-Popper {
    max-width: 400px !important;
  }
  .TextOverflow_content {
    min-width: 30px;
  }
`
