import React from "react"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import { LoaderState } from "kui-crm"
import { ApartmentFilesContentProps } from "./types"
import Loading from "../../../../../../components/common/Loading"
import FileWithSettingsRow from "../../../../../../components/common/FileWithSettingsRow"
import useApartmentStore from "../../../../store"
import ScrollingContainer from "../../../../../../components/common/ScrollingContainer"
import TableBottomLoading from "../../../../../../components/common/TableBottomLoading"

function ApartmentFilesContent({
  attachmentsStore,
  contractType,
}: ApartmentFilesContentProps) {
  const { overviewStore } = useApartmentStore()
  const requestParams = overviewStore.id ? [overviewStore.id] : []
  const { paginator, loader, creationLoader, documents } = attachmentsStore

  if (loader.isLoading && paginator.offset < paginator.limit) {
    return <Loading height="200px" />
  }

  return (
    <StyledScrollingContainer onScrollEnd={paginator.startFetching}>
      <div>
        {documents?.map((file) => (
          <FileWithSettingsRow
            cantBeEdited
            key={file.id}
            file={file}
            contractType={contractType}
            requestParams={requestParams}
          />
        ))}
      </div>
      <TableBottomLoading isLoading={loader.isLoading} />

      <LoaderState loader={creationLoader} />
      <LoaderState loader={loader} onlyError />
    </StyledScrollingContainer>
  )
}

export default observer(ApartmentFilesContent)

const StyledScrollingContainer = styled(ScrollingContainer)`
  max-height: 230px;
`
