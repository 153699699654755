import React from "react"
import { Theme } from "@emotion/react"
import { HalfArrowNextIcon, UpdateIcon, OpenIcon } from "kui-icon"
import { StatusesStylesParams } from "./types"

export const checkServiceStatuses = {
  in_progress: "in progress",
  ready: "ready",
  fail: "failure",
  passed: "passed",
  not_enough_data: "not enough data",
}

export const checkServiceIcons = {
  in_progress: null,
  ready: <HalfArrowNextIcon />,
  fail: <UpdateIcon />,
  passed: <OpenIcon />,
  not_enough_data: null,
}

export const checkStatusStyles = (theme: Theme): StatusesStylesParams => ({
  in_progress: {
    background: theme.palette.brand.background,
    color: theme.palette.brand.main,
  },
  not_enough_data: {
    background: theme.palette.background.light1,
    color: theme.palette.grey.fiftyP,
  },
  ready: {
    background: theme.palette.blue.five,
    color: theme.palette.blue.sixty,
  },
  fail: {
    background: theme.palette.red.five,
    color: theme.palette.red.sixty,
  },
  passed: {
    background: theme.palette.green.five,
    color: theme.palette.green.sixty,
  },
})
