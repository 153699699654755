import { makeAutoObservable } from "mobx"
import { Loader, Paginator, SortingFilter } from "kui-utils"
import to from "await-to-js"
import { DateTime } from "luxon"
import { LandlordAppraisalParams } from "../../types/store/appraisalsStore"
import RegistriesAgent from "../../../../agent/Registries"
import {
  LandlordAppraisalModel,
  LandlordAppraisalsRegistryResponse,
  LandlordAppraisalStatus,
} from "../../types/api/appraisals"
import ApartmentsStore from "../../../../store/lites/ApartmentsStore"

class LandlordAppraisalsRegistryStore {
  appraisals: LandlordAppraisalParams[]

  paginator: Paginator

  filter: SortingFilter

  loader: Loader

  creationLoader: Loader

  constructor() {
    this.appraisals = []
    this.paginator = new Paginator()
    this.filter = new SortingFilter("id")
    this.loader = new Loader()
    this.creationLoader = new Loader()
    makeAutoObservable(this)
  }

  fetchAll = async () => {
    this.loader.startLoading()

    const [err, res] = await to<LandlordAppraisalsRegistryResponse>(
      RegistriesAgent.getLandlordAppraisalsRegistry(
        this.paginator.offset,
        this.paginator.limit,
        this.filter.filterParams
      )
    )

    if (res && !err) {
      const mapper = (appraisal: LandlordAppraisalModel) =>
        LandlordAppraisalsRegistryStore.getAppraisalParams(appraisal)

      this.appraisals = this.paginator.getPageResponse<
        LandlordAppraisalModel,
        LandlordAppraisalParams
      >(res, this.appraisals, mapper)
    } else {
      this.loader.setError("fetch appraisals", err)
    }
    this.loader.endLoading()
  }

  static getAppraisalParams = (
    appraisal: LandlordAppraisalModel
  ): LandlordAppraisalParams => ({
    id: appraisal.id,
    apartment: ApartmentsStore.getApartmentLinkParams(appraisal.apartment),
    contract: appraisal.rental_contract
      ? {
          id: appraisal.rental_contract.id,
          number: appraisal.rental_contract.number,
        }
      : null,
    creationDate: appraisal.creation_date
      ? DateTime.fromISO(appraisal.creation_date)
      : null,
    status: LandlordAppraisalsRegistryStore.getLandlordAppraisalStatus(
      appraisal.status
    ),
    maxPrice: Number(appraisal.max_price),
    minPrice: Number(appraisal.min_price),
  })

  static getLandlordAppraisalStatus = (
    status: LandlordAppraisalStatus | null
  ) => {
    if (status === "accepted") return "signed"
    return status
  }
}

export default LandlordAppraisalsRegistryStore
