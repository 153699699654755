import React, { ChangeEvent, ForwardedRef, forwardRef } from "react"
import { Controller } from "react-hook-form"
import { InputCounterProps } from "./types"
import InputCounterButtons from "../InputCounterButtons"
import InputWithAdornment from "../InputWithAdornment"

const InputCounter = forwardRef(
  <T extends object>(
    props: InputCounterProps<T>,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const { form, name, onChange, disabled } = props

    const updateFormValue = (value: number) => {
      form.setValue(name, value, { shouldValidate: true, shouldDirty: true })
    }

    const increment = () => {
      const counterValue = Number(form.getValues(name))
      updateFormValue(counterValue + 1)
    }

    const decrement = () => {
      const counterValue = Number(form.getValues(name))
      updateFormValue(counterValue > 1 ? counterValue - 1 : 1)
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()
      const { value } = e.target
      const formattedValue = value.replace(/[^\d]|^0*/g, "") || "1"

      e.target.value = formattedValue

      updateFormValue(Number(formattedValue))
      if (onChange) onChange(e)
    }

    return (
      <Controller
        control={form.control}
        name={name}
        render={({ field, fieldState }) => (
          <InputWithAdornment
            ref={ref}
            form={form}
            name={name}
            onChange={handleChange}
            type="number"
            disabled={disabled}
            error={fieldState.error?.message}
            {...props}
            value={Number(field.value) || 1}
            endIcon={
              <InputCounterButtons
                increment={increment}
                decrement={decrement}
                disabled={disabled}
              />
            }
            step={1}
            min={1}
          />
        )}
      />
    )
  }
)

export default InputCounter
