import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { Paginator, SortingFilter, Loader, MultistepForm } from "kui-utils"
import {
  ElectricityMeterModel,
  GetElectricityMetersResponse,
  PostMeterRequest,
} from "../../../types/api/meters"
import MeterStore from "./MeterStore"
import {
  ElectricityMeterCreationFields,
  MeterCreationFormFields,
} from "../../../components/forms/meters/MeterCreationFormFields/types"
import MetersAgent from "../../../agent/Meters"
import { getCommonMeterParams } from "../../../utils/service/apiMapper"

class ElectricityMetersStore {
  meters: MeterStore[]

  paginator: Paginator

  filter: SortingFilter

  loader: Loader

  creationLoader: Loader

  creationForm: MultistepForm<MeterCreationFormFields>

  constructor() {
    this.meters = []
    this.loader = new Loader()
    this.creationLoader = new Loader()
    this.paginator = new Paginator()
    this.filter = new SortingFilter("id")
    this.creationForm = new MultistepForm<MeterCreationFormFields>()
    makeAutoObservable(this)
  }

  fetchAll = async () => {
    this.loader.startLoading()

    const [err, res] = await to<GetElectricityMetersResponse>(
      MetersAgent.all(
        "electricity",
        this.paginator.offset,
        this.paginator.limit,
        this.filter.filterParams
      )
    )
    runInAction(() => {
      if (!err && res) {
        const mapper = (meter: ElectricityMeterModel) =>
          new MeterStore(meter, "electricity")

        this.meters = this.paginator.getPageResponse<
          ElectricityMeterModel,
          MeterStore
        >(res, this.meters, mapper)
      } else {
        this.loader.setError("fetch electricity meters")
      }
      this.loader.endLoading()
    })
  }

  createMeter = async (data: ElectricityMeterCreationFields) => {
    this.creationLoader.startLoading()

    const body = ElectricityMetersStore.getCreationBody(data)

    const [err, res] = await to(MetersAgent.createMeter(body, "electricity"))
    runInAction(() => {
      if (!err && res) {
        this.meters = [...this.meters, new MeterStore(res, "electricity")]
      } else {
        this.creationLoader.setError("meter creation")
      }
      this.creationLoader.endLoading()
    })
  }

  static getCreationBody = (data: ElectricityMeterCreationFields) =>
    ({
      apartment: data.apartment?.id,
      values: {
        T1: data.initialValueT1!,
        T2: data.initialValueT2,
        T3: data.initialValueT3,
      },
      ...getCommonMeterParams(data),
      ...(data.replaceableCounter && {
        replaceable_counter: {
          counter: Number(data.replaceableCounter),
          T1: data.lastValueT1!,
          T2: data.lastValueT2,
          T3: data.lastValueT3,
        },
      }),
    } as PostMeterRequest)
}

export default ElectricityMetersStore
