import React from "react"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { Button, Box, Grid } from "kui-basic"
import { CheckIcon } from "kui-icon"
import useExpensesStore from "../../../../store"

function ServicesConfirmationButton() {
  const { overviewStore, servicesStore, periodsStore } = useExpensesStore()
  const { selectedPeriod } = periodsStore

  const handleClick = async () => {
    if (overviewStore.id && selectedPeriod?.id) {
      servicesStore.approveServices(overviewStore.id, selectedPeriod.id)
    }
  }

  return (
    <Grid container justify="flex-end">
      <Box mt={6}>
        <StyledButton
          size="s"
          disabled={selectedPeriod?.isClosed}
          endIcon={<CheckIcon />}
          onClick={handleClick}
          data-testid="services_confirm_btn"
        >
          Create new invoice
        </StyledButton>
      </Box>
    </Grid>
  )
}

export default observer(ServicesConfirmationButton)

const StyledButton = styled(Button)`
  padding: 0 30px !important;
`
