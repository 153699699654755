import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { Loader, Paginator, SortingFilter } from "kui-utils"
import { ApartmentLiteModel, FetchApartmentsResponse } from "kui-crm/types"
import ApartmentStore from "./ApartmentStore"
import { MultipleEntitiesStoreInterface } from "../../../types/store/pagination"
import { ApartmentForMapModel } from "../types/api/apartmentsAPI"
import ApartmentsAgent from "../../../agent/Apartments"
import { NewApartmentFormParams } from "../../../components/forms/apartment/ApartmentCreationForm/types"
import ApartmentForMapStore from "./ApartmentForMap"
import ApartmentsLiteStore from "../../../store/lites/ApartmentsStore"

class ApartmentsStore implements MultipleEntitiesStoreInterface {
  apartments: ApartmentStore[]

  apartmentsForMap: ApartmentForMapStore[]

  paginator: Paginator

  filter: SortingFilter

  loader: Loader

  creationLoader: Loader

  constructor() {
    this.apartments = []
    this.apartmentsForMap = []
    this.loader = new Loader()
    this.creationLoader = new Loader()
    this.paginator = new Paginator()
    this.filter = new SortingFilter("folder_number", "", "desc")
    makeAutoObservable(this)
  }

  fetchAll = async () => {
    this.loader.startLoading()

    const [err, res] = await to<FetchApartmentsResponse>(
      ApartmentsAgent.all(
        this.paginator.offset,
        this.paginator.limit,
        this.filter.filterParams
      )
    )

    runInAction(() => {
      if (!err && res) {
        const mapper = (apartment: ApartmentLiteModel) =>
          new ApartmentStore(apartment)

        this.apartments = this.paginator.getPageResponse<
          ApartmentLiteModel,
          ApartmentStore
        >(res, this.apartments, mapper)
      } else {
        this.loader.setError("fetch apartments", err)
      }
      this.loader.endLoading()
    })
  }

  getApartmentsForMap = async () => {
    this.loader.startLoading()

    const [err, res] = await to<ApartmentForMapModel[]>(
      ApartmentsAgent.forMap(this.filter.filterParams)
    )

    runInAction(() => {
      if (!err && res) {
        this.apartmentsForMap = res.map(
          (apartment) => new ApartmentForMapStore(apartment)
        )
        this.paginator.stopFetching()
      } else {
        this.loader.setError("fetch apartments for map", err)
      }
      this.loader.endLoading()
    })
  }

  createApartment = async (data: NewApartmentFormParams) => {
    this.creationLoader.startLoading()

    const body = ApartmentsLiteStore.getCreationBody(data)

    const [err, res] = await to<ApartmentLiteModel>(
      ApartmentsAgent.create(body)
    )

    runInAction(() => {
      if (!err && res) {
        this.apartments = [...this.apartments, new ApartmentStore(res)]
      } else {
        this.creationLoader.setError("apartment creation", err)
      }
      this.creationLoader.endLoading()
    })

    return res?.id
  }

  findApartmentById = (apartmentId: number) =>
    this.apartments.find((apartment) => apartment.id === apartmentId)

  get apartmentsWithGeo() {
    return this.apartmentsForMap.filter((apartment) => apartment.geolocation)
  }
}

export default ApartmentsStore
