import React from "react"
import { Button } from "kui-basic"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import { useToggle } from "kui-utils"
import { PlusIcon } from "kui-icon"
import ServiceCreationModal from "../../../../../../../../components/modals/ServiceCreationModal"
import useExpensesStore from "../../../../store"
import useApartmentStore from "../../../../../../store"

const ServiceCreationButton = () => {
  const { isArchived } = useApartmentStore()
  const { periodsStore, servicesStore, apartmentStore, lastOpenPeriod } =
    useExpensesStore()
  const { selectedPeriod } = periodsStore
  const disabledCreation = isArchived || !selectedPeriod?.canBeEdited

  const [isModalOpen, openModal, closeModal] = useToggle()
  const {
    id,
    address,
    payerCode,
    communalServicesAccount,
    actualRentalContract,
  } = apartmentStore.overviewStore
  const apartmentNumber =
    apartmentStore.descriptionStore.fields?.apartmentNumber

  const apartmentFields = id
    ? {
        id,
        address,
        payerCode,
        apartmentNumber,
        communalServicesAccount,
        hasRentalContract: !!actualRentalContract,
      }
    : null

  return (
    <>
      <StyledButton
        isCircle
        size="xs"
        variant="grayLight"
        onClick={openModal}
        disabled={disabledCreation}
      >
        <PlusIcon width={11} height={11} />
      </StyledButton>
      <ServiceCreationModal
        open={isModalOpen}
        handleClose={closeModal}
        handleSubmit={servicesStore.createService}
        formMaker={servicesStore.creationForm}
        apartment={apartmentFields}
        apartmentId={id}
        periodId={selectedPeriod?.id}
        lastOpenPeriod={lastOpenPeriod}
      />
    </>
  )
}

export default observer(ServiceCreationButton)

const StyledButton = styled(Button)`
  margin-right: 13px;
  svg path {
    fill: ${({ theme }) => theme.palette.grey.seventy};
  }
`
