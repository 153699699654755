import React, { useEffect, useState } from "react"
import { Tooltip } from "kui-crm"
import { Button } from "kui-basic"
import styled from "@emotion/styled"
import { HalfArrowDownIcon } from "kui-icon"
import _ from "lodash"
import {
  CalendarIconStylesProps,
  CalendarQuickButtonProps,
  QuickButtonOption,
} from "./types"
import CalendarQuickButtonDropdown from "../CalendarQuickButtonDropdown"

const CalendarQuickButton = (props: CalendarQuickButtonProps) => {
  const { options, isSelected, onChange, type } = props
  const [value, setValue] = useState(_.capitalize(type))
  const [isOpen, setIsOpen] = useState(false)

  const handleChange = (option: QuickButtonOption) => {
    onChange(option.startDate, option.endDate, type)
    setValue(option.label)
    setIsOpen(false)
  }

  useEffect(() => {
    if (!isSelected) setValue(_.capitalize(type))
  }, [isSelected])

  return (
    <StyledTooltip
      open={isOpen}
      toggleMethod="click"
      onChange={setIsOpen}
      content={
        <CalendarQuickButtonDropdown onClick={handleChange} options={options} />
      }
    >
      <StyledButton
        size="xs"
        variant="whiteWithGray"
        endIcon={<StyledIcon isOpen={isOpen} />}
        border={isSelected ? "brand" : undefined}
      >
        {value}
      </StyledButton>
    </StyledTooltip>
  )
}

export default CalendarQuickButton

const StyledIcon = styled(HalfArrowDownIcon)<CalendarIconStylesProps>`
  transition: all ease-out 0.3s;
  transform: rotate(${({ isOpen }) => (isOpen ? 180 : 0)}deg);
`

const StyledButton = styled(Button)`
  border-radius: 8px;
`

const StyledTooltip = styled(Tooltip)`
  .KUI-Popper > div {
    padding: 8px;
  }
`
