import * as React from "react"
import styled from "@emotion/styled"
import { ApartmentLiteParams } from "../../../../types/store/apartments"

const Wrapper = styled.div`
  cursor: pointer;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  padding: 0 8px;
`

const Address = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
`

type ApartmentAutocompleteOptionProps = {
  option: ApartmentLiteParams
}

function ApartmentAutocompleteOption({
  option,
}: ApartmentAutocompleteOptionProps) {
  return (
    <Wrapper>
      <Address>{option.address}</Address>
    </Wrapper>
  )
}

export default ApartmentAutocompleteOption
