export const contractChanges = {
  installment: {
    old: 1,
    new: 2,
  },
  auto_prolongate: {
    old: true,
    new: false,
  },
  day_of_payment: {
    old: 10,
    new: 15,
  },
}

export const pendingContractChanges = [
  {
    id: 1,
    apply_from: "20-10-2023",
    reason_document: {
      pk: 1,
      name: "Document",
      attachment: "https://google.com",
      size: 5,
    },
    created: "10-10-2023",
    created_by: null,
    changes: contractChanges,
  },

  {
    id: 2,
    apply_from: "20-11-2023",
    reason_document: {
      pk: 1,
      name: "Document",
      attachment: "https://google.com",
      size: 5,
    },
    created: "10-11-2023",
    created_by: null,
    changes: contractChanges,
  },
]
