import { createContext, useContext } from "react"
import hostStore from "../../../store"
import ClientPageStore from "./ClientPageStore"

const clientStore = new ClientPageStore(hostStore)
const ClientContext = createContext(clientStore)

const useClientStore = () => useContext(ClientContext)

export default useClientStore
