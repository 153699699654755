import { makeAutoObservable, runInAction } from "mobx"
import { Loader, Paginator, resHandler } from "kui-utils"
import to from "await-to-js"
import { getInspectionFeedbackStatus, InspectionFeedbackModel } from "kui-crm"
import DashboardAgent from "../../../../agent/Dashboard"
import DashboardPageStore from "../DashboardPageStore"
import { UnsignedInspectionModel } from "../../types/api/inspections"
import { NotSignedInspectionParams } from "../../types/cards/inspections"
import ApartmentsStore from "../../../../store/lites/ApartmentsStore"

class UnsignedInspectionsStore {
  value: number | null

  inspections: NotSignedInspectionParams[]

  paginator: Paginator

  loader: Loader

  dashboardStore: DashboardPageStore

  constructor(dashboardStore: DashboardPageStore) {
    this.value = null
    this.inspections = []
    this.paginator = new Paginator(5)
    this.loader = new Loader()
    this.dashboardStore = dashboardStore
    makeAutoObservable(this)
  }

  fetchInspections = async () => {
    this.loader.startLoading()

    const response = await to(
      DashboardAgent.getUnsignedInspections(
        this.paginator.limit,
        this.paginator.offset,
        this.dashboardStore.periodFilter
      )
    )

    runInAction(() => {
      resHandler(response, this.loader, (res) => {
        this.value = res.count
        this.inspections = this.paginator.getPageResponse(
          res,
          this.inspections,
          this.getInspectionParams
        )
      })
    })
  }

  removeFromInspectionsList = (id: number) => {
    this.inspections = this.inspections.filter(
      (inspection) => inspection.id !== id
    )
  }

  getInspectionParams = ({
    inspection,
  }: UnsignedInspectionModel): NotSignedInspectionParams => ({
    id: inspection.id,
    type: inspection.inspection_type,
    apartment: ApartmentsStore.getApartmentLinkParams(inspection.apartment),
    tenantFeedback: UnsignedInspectionsStore.getFeedbackParams(
      inspection.renter_feedback
    ),
    landlordFeedback: UnsignedInspectionsStore.getFeedbackParams(
      inspection.owner_feedback
    ),
  })

  static getFeedbackParams = (feedback: InspectionFeedbackModel | null) => {
    if (!feedback) return null

    return {
      status: getInspectionFeedbackStatus(feedback),
      comment: feedback.comment,
    }
  }
}

export default UnsignedInspectionsStore
