import { InputParams } from "../../../../../../../components/common/InputsGroupWithTitle/types"
import { AppraisalInspectionFormFields } from "../types"

const tenantOfferFields: InputParams<AppraisalInspectionFormFields>[] = [
  {
    label: "Min price",
    name: "managerValuation.minPrice",
    variant: "mask",
    mask: Number,
    thousandsSeparator: " ",
    size: 2,
    cornerLabel: "₽",
    isRequired: true,
  },
  {
    label: "Max price",
    name: "managerValuation.maxPrice",
    variant: "mask",
    mask: Number,
    thousandsSeparator: " ",
    size: 2,
    cornerLabel: "₽",
    isRequired: true,
  },
  {
    label: "Average price",
    name: "managerValuation.averagePrice",
    variant: "mask",
    mask: Number,
    thousandsSeparator: " ",
    size: 2,
    cornerLabel: "₽",
    disabled: true,
  },
  { name: "empty1", variant: "empty", size: 6 },
  {
    label: "Now price",
    name: "offer.nowPrice",
    variant: "mask",
    mask: Number,
    thousandsSeparator: " ",
    size: 2,
    cornerLabel: "₽",
  },
  {
    label: "Later price",
    name: "offer.laterPrice",
    variant: "mask",
    mask: Number,
    thousandsSeparator: " ",
    size: 2,
    cornerLabel: "₽",
  },
  {
    label: "Offer date",
    name: "offer.date",
    variant: "date",
    size: 2,
    disabled: true,
  },
]

export default tenantOfferFields
