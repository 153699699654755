const insuranceHeadCells = [
  { id: "id", label: "ID", width: "5%" },
  { id: "folder_number", label: "Folder", width: "5%" },
  { id: "apartment", label: "Object", width: "27%" },
  { id: "owner", label: "Client", width: "20%" },
  { id: "number", label: "Number", width: "12%" },
  { id: "amount", label: "Price", width: "9%" },
  { id: "expiration_date", label: "Expired date", width: "10%" },
  { id: "status", label: "Status", width: "12%" },
]

export default insuranceHeadCells
