import React, { useEffect } from "react"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { Modal } from "kui-crm"
import ApartmentPhotoModalFooter from "../ApartmentPhotoModalFooter"
import ApartmentPhotoModalBody from "../ApartmentPhotoModalBody"
import { ApartmentPhotoModalProps } from "./types"
import usePromotionStore from "../../../../../store"

function ApartmentPhotoModal(props: ApartmentPhotoModalProps) {
  const { open, handleClose, images, variant, errorMessage, ...footerProps } =
    props
  const {
    contentStore: { imagesStore },
  } = usePromotionStore()
  const title = variant === "public" ? "All photo" : "Public photo"

  useEffect(() => {
    if (open) {
      imagesStore.clearActiveImages()
    }
  }, [open])

  return (
    <StyledModal
      title={title}
      isScrollable
      open={open}
      handleClose={handleClose}
    >
      <ApartmentPhotoModalBody errorMessage={errorMessage} images={images} />
      <ApartmentPhotoModalFooter
        handleClose={handleClose}
        variant={variant}
        hasError={!!errorMessage}
        {...footerProps}
      />
    </StyledModal>
  )
}

export default observer(ApartmentPhotoModal)

const StyledModal = styled(Modal)`
  width: 892px;
  @media (max-width: 892px) {
    width: 100vw;
  }
`
