import to from "await-to-js"
import { makeAutoObservable } from "mobx"
import { Loader } from "kui-utils"
import ApartmentExpensesAgent from "../../../../../../agent/ApartmentExpenses"
import { ApartmentPaymentSummaryModel } from "../../types/api/expensesSummaryAPI"
import ApartmentSummaryStore from "./ApartmentSummary"
import ApartmentBalanceSummaryStore from "./ApartmentBalanceSummary"

class ApartmentBalancesSummaryStore {
  summaryStore: ApartmentSummaryStore

  tenantSummary: ApartmentBalanceSummaryStore | null = null

  landlordSummary: ApartmentBalanceSummaryStore | null = null

  loader: Loader

  constructor(summaryStore: ApartmentSummaryStore) {
    this.summaryStore = summaryStore
    this.loader = new Loader()
    makeAutoObservable(this)
  }

  getPaymentSummary = async (
    apartmentId: number,
    contractId: number | undefined,
    periodId: number,
    withoutLoader?: boolean
  ) => {
    if (!withoutLoader) this.loader.startLoading("payment summary")

    const [err, res] = await to<ApartmentPaymentSummaryModel>(
      ApartmentExpensesAgent.getPaymentSummary(
        apartmentId,
        contractId,
        periodId
      )
    )

    if (res && !err) {
      this.updatePaymentSummary(res)
    } else {
      this.loader.setError("fetch payment summary", err)
    }
    this.loader.endLoading()

    return res?.period_id
  }

  updatePaymentSummary = (data: ApartmentPaymentSummaryModel) => {
    this.tenantSummary = new ApartmentBalanceSummaryStore(data.renter)
    this.landlordSummary = new ApartmentBalanceSummaryStore(data.owner)
  }
}

export default ApartmentBalancesSummaryStore
