import React from "react"
import { observer } from "mobx-react"
import { Modal } from "kui-crm"
import ClientPassportFilesFields from "../../../../forms/ClientPassportFilesFields"
import { PassportUpdatingModalProps } from "./types"

const PassportUpdatingModal = ({
  open,
  handleClose,
  handleSubmit,
}: PassportUpdatingModalProps) => (
  <Modal
    isScrollable
    title="Change a passport"
    open={open}
    handleClose={handleClose}
  >
    <ClientPassportFilesFields
      handleClose={handleClose}
      handleSubmit={handleSubmit}
    />
  </Modal>
)

export default observer(PassportUpdatingModal)
