/** @jsxImportSource @emotion/react */
import * as React from "react"
import _ from "lodash"
import styled from "@emotion/styled"
import { Caption, Heading, Box, Grid } from "kui-basic"
import { AboutPageHeaderProps, InfoParams } from "./types"
import TwoCaptionsBlock from "../TwoCaptionsBlock"
import Container from "../../ui/Container"

const Wrapper = styled.div`
  background: ${(props) => props.theme.palette.background.light1};
`

function AboutPageHeader({
  icon,
  title,
  subtitle,
  info,
  status,
  className,
}: AboutPageHeaderProps) {
  return (
    <Wrapper className={className}>
      <Container>
        <Box pt={2} pb={2} className="PageHeaderWrapper">
          <Grid container alignItems="center" justify="space-between">
            <StyledStartContent item>
              <Grid container>
                {icon && <Box mr={3}>{icon}</Box>}
                <StyledContent
                  css={{ height: "100%" }}
                  container
                  direction="column"
                  justify="center"
                >
                  <Grid container wrap="nowrap" alignItems="center">
                    <Heading size="h2">{title}</Heading>
                    {status}
                  </Grid>
                  <Caption weight={500} color="fiftyP">
                    {subtitle}
                  </Caption>
                </StyledContent>
              </Grid>
            </StyledStartContent>
            <StyledEndContent item container justify="flex-end">
              {info.map((item: InfoParams) => (
                <Grid item key={_.uniqueId()} className="PageHeaderInfoItem">
                  <Box mr={6}>
                    <TwoCaptionsBlock
                      top={item.title || "-"}
                      bottom={item.subtitle}
                    />
                  </Box>
                </Grid>
              ))}
            </StyledEndContent>
          </Grid>
        </Box>
      </Container>
    </Wrapper>
  )
}

export default AboutPageHeader

const StyledStartContent = styled(Grid)`
  flex-grow: 1;
`

const StyledEndContent = styled(Grid)`
  width: fit-content;
`

const StyledContent = styled(Grid)`
  width: auto;
  flex-grow: 1;
`
