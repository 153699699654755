import React from "react"
import { observer } from "mobx-react"
import SortingTable from "../../../../../../components/common/SortingTable"
import useCompanyStore from "../../../../store"
import companyObjectsHeadCellsParams, {
  companyOtherCellsParams,
} from "./headParams"
import CompanyObjectRow from "../CompanyObjectRow"

function CompanyObjectsTable() {
  const { objectsStore } = useCompanyStore()

  return (
    <SortingTable
      headParams={companyObjectsHeadCellsParams}
      otherParams={companyOtherCellsParams}
      store={objectsStore}
      headVariant="grey"
      withFullLoading={false}
    >
      {objectsStore.objects.map((object, key) => (
        <CompanyObjectRow
          key={object.id}
          object={object}
          isDark={key % 2 !== 0}
        />
      ))}
    </SortingTable>
  )
}

export default observer(CompanyObjectsTable)
