import * as React from "react"
import { observer } from "mobx-react"
import { useNavigate } from "react-router-dom"
import { Modal } from "kui-crm"
import { CreateClientModalProps, ClientCreationFields } from "./types"
import { Routes } from "../../../types/route"
import { ClientDocumentsStepFields } from "../../forms/client/ClientDocumentsStep/types"
import ClientCreationForm from "../../forms/client/ClientCreationForm"

function ClientCreationModal(props: CreateClientModalProps) {
  const { open, handleClose, withRoute, handleSubmit, creationForm } = props
  const navigate = useNavigate()

  const handleFormSubmit = async (data: ClientDocumentsStepFields) => {
    handleClose()
    const clientId = await handleSubmit({
      ...creationForm.fields,
      ...data,
    } as ClientCreationFields)
    if (clientId && withRoute) {
      navigate(`${Routes.clients}/${clientId}`)
    }
    creationForm.resetForm()
  }

  return (
    <Modal title="New client" open={open} handleClose={handleClose}>
      <ClientCreationForm
        creationForm={creationForm}
        handleSubmit={handleFormSubmit}
      />
    </Modal>
  )
}

ClientCreationModal.defaultProps = {
  withRoute: true,
}

export default observer(ClientCreationModal)
