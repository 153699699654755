import { DateTime } from "luxon"
import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import _ from "lodash"
import { Loader, resHandler, toNumber } from "kui-utils"
import {
  RentalContractModel,
  ServiceContractModel,
  FileBodyRequest,
} from "kui-crm/types"
import {
  ClosingRentalContractFields,
  ContractStatuses,
  PartialPayerFields,
} from "kui-crm"
import ApartmentSimpleStore from "../../templates/Apartment"
import UserLiteStore from "../../templates/UserLite"
import { CheckParams, ContractPeriodTypes } from "../../../types/common"
import RentalContractAgent from "../../../agent/RentalContract"
import {
  CloseRentalContractRequest,
  DateOfLeavingModel,
  PatchRentalContractParams,
  PatchRentalContractResponse,
} from "../../../types/api/rental_contract"
import RentalContractsAgent from "../../../agent/RentalContracts"
import ServiceContractLiteStore from "../../templates/ServiceContractLite"
import { RentalTypes } from "../../../types/store/contract"
import { RentalContractInfoValues } from "../../../pages/RentalContractPage/types/store/rcInfoStore"
import { RCInfoFormFields } from "../../../pages/RentalContractPage/components/RCInfoFields/types"
import { uploadNewFile } from "../../../utils/agent/uploadFiles"
import { getUtilitiesPayerParams } from "../../../utils/service/apiMapper"
import { CheckoutDateFormFields } from "../../../components/forms/contacts/ContractCheckoutDateForm/types"
import { ContractClosingFormFields } from "../../../components/forms/contacts/ContractClosingForm/types"
import { clearNotValidFields } from "../../../utils/service/mapper"
import { ActivateContractFormFields } from "../../../components/forms/contacts/ActivateContractForm/types"
import { ActivateContractResponse } from "../../../types/api/contracts"
import { EstateAgentParams } from "../../../types/store/estateAgents"

class RentalContractInfoStore {
  id: number | null = null

  apartment: ApartmentSimpleStore | null = null

  number: string = ""

  startDate: DateTime | null = null

  signDate: DateTime | null = null

  endDate: DateTime | null = null

  closeDate: DateTime | null = null

  landlord: UserLiteStore | null = null

  tenant: UserLiteStore | null = null

  type: RentalTypes | null = null

  initialCommission: number | null = null

  rentPrice: number | null = null

  securityPayment: number | null = null

  installmentPeriod: number | null = null

  paymentDay: number | null = null

  showingDays: number | null = null

  penalty: number | null = null

  autoProlongate: boolean = false

  contractPeriodType: ContractPeriodTypes | null = null

  isPending: boolean = false

  dateOfLeaving: DateTime | null = null

  serviceContract: ServiceContractLiteStore | null = null

  loader: Loader

  status: ContractStatuses | null = null

  contractInfoValues: RentalContractInfoValues

  inspectionIntervalMonth: number | null = null

  nextInspectionDate: DateTime | null = null

  allowablePriceDeviation: number | null = null

  petsAllowed: boolean = false

  childrenAllowed: boolean = false

  notifyPeriodMonth: number | null = null

  utilityPayers: PartialPayerFields[] = []

  closingProgress: CheckParams[] = []

  firstAppraisalPeriod: number | null = null

  estateAgent: EstateAgentParams | null = null

  canActivate: boolean = false

  constructor() {
    this.loader = new Loader()
    this.contractInfoValues = _.omitBy(
      this,
      _.isFunction
    ) as RentalContractInfoValues
    makeAutoObservable(this)
  }

  patchRentalContractInfo = async (data: RCInfoFormFields) => {
    this.loader.startLoading("patch rental contract")
    const body = RentalContractInfoStore.getPatchRCFields(data)

    const [err, res] = await to<RentalContractModel>(
      RentalContractAgent.patch(this.id!, body)
    )
    if (!err && res) {
      this.updateRentalContract(res)
    } else {
      this.loader.setError(`patch rental contract`, err)
    }
    this.loader.endLoading()
  }

  addPendingChange = async (data: RCInfoFormFields) => {
    const file = await uploadNewFile(this.loader, data.changesFile)
    const body = RentalContractInfoStore.getBodyForAddPending(data, file)

    const [err, res] = await to<PatchRentalContractResponse>(
      RentalContractAgent.createPendingChange(this.id!, body)
    )
    if (!err && res) {
      this.updateRentalContract(res.changes)
    } else {
      this.loader.setError(`add pending change`, err)
    }
  }

  closeRentalContract = async (data: ContractClosingFormFields) => {
    this.loader.startLoading("closing contract")

    const file = await uploadNewFile(this.loader, data.terminationAgreement)

    const body = RentalContractInfoStore.getCloseContractBody(data, file)

    const response = await to<CloseRentalContractRequest>(
      RentalContractsAgent.close(this.id!, body)
    )

    resHandler(response, this.loader, (res) => {
      this.closeDate = res.end_date ? DateTime.fromISO(res.end_date) : null
      this.status = "Closed"
    })
  }

  activateRentalContract = async (data: ActivateContractFormFields) => {
    const contractId = this.id

    if (contractId) {
      this.loader.startLoading()
      const document = await uploadNewFile(this.loader, data.document)
      const body = { document }

      const response = await to<ActivateContractResponse>(
        RentalContractsAgent.activateContract(contractId, body)
      )

      resHandler(response, this.loader, this.updateContractStatus)
    }
  }

  updateContractStatus = (data: ActivateContractResponse) => {
    this.status = data.status
  }

  saveDateOfLeavingApartment = async (data: CheckoutDateFormFields) => {
    this.loader.startLoading()
    const document = await uploadNewFile(this.loader, data.reason)

    const body = {
      document,
      planned_leave_date: data.checkoutDate?.toISODate() || "",
    }

    const [err, res] = await to<DateOfLeavingModel>(
      RentalContractsAgent.saveDateOfLeaving(this.id!, body)
    )

    runInAction(() => {
      if (!err && res) {
        this.dateOfLeaving = DateTime.fromISO(res.planned_leave_date)
      } else {
        this.loader.setError("save date of leaving")
      }
      this.loader.endLoading()
    })
  }

  updateRentalContract = (contract: Partial<RentalContractModel>) => {
    const contractFields = RentalContractInfoStore.getContractFields(contract)

    if (contract.id) this.id = contract.id
    if (contract.apartment)
      this.apartment = new ApartmentSimpleStore(contract.apartment)
    if (contract.owner)
      this.landlord = UserLiteStore.initFromLiteUserModel(contract.owner)
    if (contract.renter)
      this.tenant = UserLiteStore.initFromLiteUserModel(contract.renter)
    if (contract.planned_leave_date)
      this.dateOfLeaving = DateTime.fromISO(contract.planned_leave_date)
    this.isPending = !(Number(this.startDate?.diffNow("days").days) < 0)
    if (contract.closing_conditions)
      this.closingProgress = RentalContractInfoStore.getClosingProgress(
        contract.closing_conditions
      )
    if (contract.status) this.status = contract.status
    if (contract.can_activate) this.canActivate = contract.can_activate

    if (contractFields.number) this.number = contractFields.number
    if (contractFields.startDate) this.startDate = contractFields.startDate
    if (contractFields.type) this.type = contractFields.type
    if (contractFields.signDate) this.signDate = contractFields.signDate
    if (contractFields.endDate) this.endDate = contractFields.endDate
    if (contractFields.initialCommission)
      this.initialCommission = contractFields.initialCommission
    if (contractFields.rentPrice) this.rentPrice = contractFields.rentPrice
    if (contractFields.securityPayment)
      this.securityPayment = contractFields.securityPayment
    if (contractFields.installmentPeriod)
      this.installmentPeriod = contractFields.installmentPeriod
    if (contractFields.paymentDay) this.paymentDay = contractFields.paymentDay
    if (contractFields.showingDays)
      this.showingDays = contractFields.showingDays
    if (contractFields.penalty) this.penalty = contractFields.penalty
    if (contractFields.utilityPayers)
      this.utilityPayers = contractFields.utilityPayers
    if (contractFields.inspectionIntervalMonth)
      this.inspectionIntervalMonth = contractFields.inspectionIntervalMonth
    if (contractFields.notifyPeriodMonth)
      this.notifyPeriodMonth = contractFields.notifyPeriodMonth
    if (typeof contractFields.autoProlongate === "boolean")
      this.autoProlongate = contractFields.autoProlongate
    if (contractFields.contractPeriodType)
      this.contractPeriodType = contractFields.contractPeriodType
    if (typeof contractFields.childrenAllowed !== "undefined")
      this.childrenAllowed = contractFields.childrenAllowed
    if (typeof contractFields.petsAllowed !== "undefined")
      this.petsAllowed = contractFields.petsAllowed
    if (contractFields.allowablePriceDeviation)
      this.allowablePriceDeviation = contractFields.allowablePriceDeviation
    if (contractFields.firstAppraisalPeriod)
      this.firstAppraisalPeriod = contractFields.firstAppraisalPeriod
    if (contractFields.closeDate) this.closeDate = contractFields.closeDate
    if (contractFields.estateAgent)
      this.estateAgent = contractFields.estateAgent

    this.contractInfoValues = _.omitBy(
      this,
      _.isFunction
    ) as RentalContractInfoValues
  }

  updateServiceContract = (contract: ServiceContractModel) => {
    this.serviceContract = new ServiceContractLiteStore(contract)
  }

  updateAutoprolongate = (value: boolean) => {
    this.autoProlongate = value
  }

  get isClosed() {
    return this.status === "Closed"
  }

  get isArchived() {
    return this.status === "Archived"
  }

  get canBeClosed() {
    return this.closingProgress.filter((step) => !step.checked).length === 0
  }

  static getBodyForAddPending = (
    data: Partial<RCInfoFormFields>,
    file: FileBodyRequest | null
  ) => ({
    apply_from: data.changesDate ? data.changesDate.toISODate() : null,
    reason_document: file,
    changes: RentalContractInfoStore.getPatchRCFields(data),
  })

  static getCloseContractBody = (
    data: ContractClosingFormFields,
    file: FileBodyRequest | null
  ) => ({
    end_date: data.closeDate?.toISODate(),
    termination_agreement: file,
  })

  static getPatchRCFields = (data: Partial<RentalContractInfoStore>) =>
    clearNotValidFields({
      number: data.number,
      sign_date: data.signDate?.toISODate(),
      start_date: data.startDate?.toISODate(),
      end_date: data.endDate ? data.endDate.toISODate() : null,
      initial_commission: data.initialCommission,
      auto_prolongate: data.autoProlongate,
      rental_type: data.type,
      month_payment: toNumber(data.rentPrice),
      security_payment: data.securityPayment,
      installment: data.installmentPeriod,
      day_of_payment: data.paymentDay,
      termination_showing_days: data.showingDays,
      termination_penalty: data.penalty,
      estate_agent_id: data.estateAgent?.id,
    }) as PatchRentalContractParams

  static getContractFields = (contract: Partial<RentalContractModel>) =>
    ({
      number: contract.number,
      startDate: contract.start_date
        ? DateTime.fromISO(contract.start_date)
        : null,
      type: contract.rental_type,
      signDate: contract.sign_date
        ? DateTime.fromISO(contract.sign_date)
        : null,
      endDate: contract.end_date ? DateTime.fromISO(contract.end_date) : null,
      rentPrice: contract.month_payment ? Number(contract.month_payment) : null,
      securityPayment: contract.security_payment
        ? Number(contract.security_payment)
        : null,
      installmentPeriod: contract.installment
        ? Number(contract.installment)
        : null,
      paymentDay: contract.day_of_payment
        ? Number(contract.day_of_payment)
        : null,
      showingDays: contract.termination_showing_days
        ? Number(contract.termination_showing_days)
        : null,
      penalty: contract.termination_penalty
        ? Number(contract.termination_penalty)
        : null,
      initialCommission: contract.initial_commission
        ? Number(contract.initial_commission)
        : null,
      utilityPayers:
        contract.utility_payers?.map((utilityPayer) =>
          getUtilitiesPayerParams(utilityPayer)
        ) || null,
      inspectionIntervalMonth: contract.inspection_interval_month,
      notifyPeriodMonth: contract.notification_period,
      allowablePriceDeviation: contract.allowable_price_deviation,
      autoProlongate:
        typeof contract.auto_prolongate === "boolean"
          ? contract.auto_prolongate
          : null,
      paidStatus: contract.renter_payment_status,
      ...(typeof contract.end_date !== "undefined"
        ? {
            contractPeriodType: contract.end_date ? "fixed" : "openEnded",
          }
        : {}),
      childrenAllowed:
        typeof contract.allowed_children !== "undefined"
          ? !!contract.allowed_children
          : null,
      petsAllowed:
        typeof contract.allowed_pets !== "undefined"
          ? !!contract.allowed_pets
          : null,
      firstAppraisalPeriod: contract.first_appraisal_interval_month
        ? Number(contract.first_appraisal_interval_month)
        : null,
      closeDate: contract.close_date
        ? DateTime.fromISO(contract.close_date)
        : null,
      estateAgent: contract.estate_agent
        ? {
            id: contract.estate_agent.id,
            name: contract.estate_agent.full_name,
          }
        : null,
    } as Partial<RentalContractInfoStore>)

  static getClosingProgress = (fields: ClosingRentalContractFields) => [
    {
      label: "Transfer inspection",
      checked: !!fields.have_closing_transfer_inspection,
    },
    {
      label: "Date of termination",
      checked: !!fields.have_closing_inspection_date_eq_contract_end_date,
    },
    {
      label: "Impairments inspection",
      checked: !!fields.have_closing_impairments,
    },
    {
      label: "Meters&expenses data",
      checked: !!fields.have_last_meters_filled,
    },
    {
      label: "Deposit calculation",
      checked: !!fields.have_calculated_deposit,
    },
    { label: "Closed last period", checked: !!fields.have_closed_last_period },
  ]
}

export default RentalContractInfoStore
