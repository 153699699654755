import React from "react"
import { useWatch } from "react-hook-form"
import { observer } from "mobx-react"
import { Grid } from "kui-basic"
import { MetroBlock } from "kui-crm"
import styled from "@emotion/styled"
import { InitialInspectionInfoFieldsProps } from "./types"
import getInitialInspectionInfoFieldGroup from "./fields"
import MapWrapper from "../../../../../../../components/common/MapWrapper"
import InputsGroupWithTitle from "../../../../../../../components/common/InputsGroupWithTitle"
import useInspectionStore from "../../../store"
import Container from "../../../../../../../components/ui/Container"
import useStore from "../../../../../../../hooks/useStore"

const InitialInspectionInfoFields = ({
  form,
}: InitialInspectionInfoFieldsProps) => {
  const { editor } = useInspectionStore()
  const { mapStore, metroStore } = useStore()
  const [address, location] = useWatch({
    control: form.control,
    name: ["apartment.address", "apartment.location"],
  })
  const [roomsCount, bedroomsCount] = useWatch({
    control: form.control,
    name: ["roomsNumber", "bedroomsNumber"],
  })

  const initialInspectionFieldGroups = getInitialInspectionInfoFieldGroup(
    address,
    roomsCount,
    bedroomsCount
  )

  return (
    <Container>
      <MapWrapper>
        {initialInspectionFieldGroups.map((fieldGroup) =>
          fieldGroup.title === "metro" ? (
            <StyledMetroWrapper item xs={12}>
              <MetroBlock
                coords={location}
                form={form}
                isEditing={editor.isEditing}
                metroStore={metroStore}
                mapStore={mapStore}
              />
            </StyledMetroWrapper>
          ) : (
            <InputsGroupWithTitle
              key={fieldGroup.title}
              form={form}
              isEditing={editor.isEditing}
              {...fieldGroup}
            />
          )
        )}
      </MapWrapper>
    </Container>
  )
}

export default observer(InitialInspectionInfoFields)

const StyledMetroWrapper = styled(Grid)`
  margin-top: 16px;
`
