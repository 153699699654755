import React from "react"
import { Caption } from "kui-basic"
import _ from "lodash"
import styled from "@emotion/styled"
import DashboardListRow from "../../../common/DashboardListRow"
import { MeterWithDeviatingRowProps } from "./types"
import { defaultApartmentImageURL } from "../../../../../../utils/content/constants"
import { DynamicRoutes } from "../../../../../../types/route"
import DashboardArchiveRow from "../../../common/DashboardArchiveRow"
import getMeterLabel from "../../../../../../utils/service/getMeterLabel"
import { meterUnitLabel } from "../../../../../../utils/content/values"

const MeterWithDeviatingRow = (props: MeterWithDeviatingRowProps) => {
  const { meter, isArchive } = props
  const metersLink = DynamicRoutes.metersExpenses(meter.apartment.id)
  const valueLabel = (
    <StyledMeterLabel size="s" weight={600} color="fiftyP">
      +{meter.deviation} {meterUnitLabel[meter.resource]}{" "}
      {meter.resource === "electricity" ? `(${meter.valueType})` : ""}
    </StyledMeterLabel>
  )
  const description = (
    <Caption size="xs" weight={500} color="fiftyP">
      {meter.apartment.address}
    </Caption>
  )
  const meterLabel = _.capitalize(getMeterLabel(meter.resource, meter.type))

  const rowProps = {
    logo: meter.apartment.previewImage || defaultApartmentImageURL,
    label: meterLabel,
    description,
    link: metersLink,
    endComponent: valueLabel,
  }

  return isArchive ? (
    <DashboardArchiveRow {...rowProps} onClick={meter.restoreMeter} />
  ) : (
    <DashboardListRow {...rowProps} onClick={meter.archiveMeter} />
  )
}

export default MeterWithDeviatingRow

const StyledMeterLabel = styled(Caption)`
  white-space: nowrap;
  margin-left: 4px;
`
