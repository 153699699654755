import * as yup from "yup"
import { toNumber } from "kui-utils"

const PaymentEditingFormSchema = yup.object().shape(
  {
    payerId: yup.number().required("Choose a payer"),
    date: yup.mixed().required("Enter the date"),
    amount: yup
      .string()
      .transform((value) => (Number(toNumber(value)) <= 0 ? "" : value))
      .required("Enter the amount"),
  },
  [["amount", "amount"]]
)

export default PaymentEditingFormSchema
