import { DateTime } from "luxon"
import { makeAutoObservable } from "mobx"
import to from "await-to-js"
import _ from "lodash"
import { Loader } from "kui-utils"
import {
  MeterFeaturesFormFields,
  PartialPayerFields,
  TariffGroupAutocompleteFields,
  CompanyAutocompleteFields,
  WaterMeterTypes,
} from "kui-crm"

import {
  APISubjectRoles,
  ResourceTypes,
  MeterPayerModel,
  SingleMeterParams,
} from "kui-crm/types"
import { PatchSingleMeterRequest } from "../../types/api/expensesMetersAPI"
import {
  CounterInterface,
  ExpensesWaterTypes,
  MetersStoreInterface,
} from "../../types/store/expensesMeters"
import ApartmentMeterTariffValues from "./ApartmentMeterTariffValues"
import { SubjectRoles } from "../../../../../../types/common"
import MeterAgent from "../../../../../../agent/Meter"
import {
  matchesAPISubjectRoles,
  matchesSubjectRoles,
} from "../../../../../../utils/content/matches"
import UserLiteStore from "../../../../../../store/templates/UserLite"
import { clearNotValidFields } from "../../../../../../utils/service/mapper"
import {
  getCommonMeterParams,
  getUtilitiesPayerParams,
} from "../../../../../../utils/service/apiMapper"
import ApartmentMetersAgent from "../../../../../../agent/ApartmentMeters"
import { PutMeterResponse } from "../../../../../../types/api/meters"

class SingleMeterStore implements CounterInterface {
  id: number

  isArchived: boolean

  type?: ExpensesWaterTypes

  number: string

  tariff: TariffGroupAutocompleteFields | null

  company: CompanyAutocompleteFields | null

  nextCheck: DateTime | null

  startDate: DateTime | null

  tariffValues: ApartmentMeterTariffValues

  resource: ResourceTypes

  payer: UserLiteStore | null

  paymentsInfo: PartialPayerFields | null

  payerRole: SubjectRoles | null

  loader: Loader

  operationalAccountNumber: string

  metersStore: MetersStoreInterface

  companyIsAdministrative: boolean

  withAutoSending: boolean

  hasAverageValue: boolean

  noTariff: boolean

  price: number | null

  constructor(
    counter: SingleMeterParams,
    resource: ResourceTypes,
    metersStore: MetersStoreInterface
  ) {
    this.isArchived = !!counter.archived
    this.id = counter.id
    this.type = counter.type as WaterMeterTypes
    this.number = counter.passport_number
    this.tariff = counter.tariff
      ? {
          id: counter.tariff.id,
          name: counter.tariff.code_name,
        }
      : null
    this.company = counter.company
      ? {
          id: counter.company.id,
          name: counter.company.name,
        }
      : null
    this.nextCheck = counter.check_date
      ? DateTime.fromISO(counter.check_date)
      : null
    this.startDate = counter.activation_date
      ? DateTime.fromISO(counter.activation_date)
      : null
    this.tariffValues = new ApartmentMeterTariffValues(
      counter,
      counter.tariff?.value,
      this,
      counter.type
    )
    this.resource = resource
    this.payer = counter.payer
      ? UserLiteStore.initFromLiteUserModel(counter.payer)
      : null
    this.paymentsInfo = counter.payer?.payments_info
      ? getUtilitiesPayerParams(counter.payer.payments_info)
      : null
    this.payerRole = counter.payer?.payer_type
      ? (matchesSubjectRoles[counter.payer.payer_type] as SubjectRoles)
      : null
    this.loader = new Loader()
    this.operationalAccountNumber = counter.operating_account_number || ""
    this.metersStore = metersStore
    this.companyIsAdministrative = counter.company_is_administrative
    this.withAutoSending = counter.auto_sending
    this.noTariff = counter.no_tariff
    this.price = counter.cost ? Number(counter.cost) : null
    this.hasAverageValue = counter.is_avg
    makeAutoObservable(this)
  }

  patchCounter = async (data: Partial<MeterFeaturesFormFields>) => {
    const { date } = this.metersStore.expensesStore

    if (date) {
      this.loader.startLoading("meter changes")

      const body: PatchSingleMeterRequest =
        this.getPatchCounterRequestBody(data)

      const [err, res] = await to<SingleMeterParams>(
        MeterAgent.patch(this.id, body, this.resource, date.year, date.month)
      )

      if (!err && res) {
        this.updateMeter(res)
      } else {
        this.loader.setError(`patch meter`, err)
      }
      this.loader.endLoading()

      return !!err
    }

    return false
  }

  postMeterValue = async (
    value: string,
    periodId: number
  ): Promise<[Error | null, PutMeterResponse | undefined]> => {
    const body = {
      value,
      period_id: periodId,
    }
    const [err, res] = await to<PutMeterResponse>(
      MeterAgent.putValue(this.id, body, this.resource)
    )

    if (!err && res) {
      this.metersStore.updateTotalPrice(
        Number(res.total_cost),
        Number(res.sewerage_cost),
        Number(res.sewerage_consumption)
      )
    } else {
      this.loader.setError("edit meter value", err)
    }

    return [err, res]
  }

  postMeterCost = async (apartmentId: number, value: number) => {
    const body = { value }
    const [err] = await to(
      ApartmentMetersAgent.postCost(apartmentId, this.id, body, this.resource)
    )

    if (err) {
      this.loader.setError("edit meter cost", err)
    }
  }

  updateTotalPrice = (total: number, consumption: number) => {
    this.tariffValues.updateTotalPrice(total)
    this.tariffValues.updateMeterConsumption(consumption)
  }

  deactivate = async () => {
    await this.patchCounter({ archived: true })
  }

  activate = async () => {
    await this.patchCounter({ archived: false })
  }

  setArchived = () => {
    this.isArchived = true
  }

  updateMeter = (counter: Partial<SingleMeterParams>) => {
    if (
      counter.operating_account_number &&
      this.operationalAccountNumber !== counter.operating_account_number
    ) {
      this.metersStore.updateOperatingNumber(counter.operating_account_number)
    }
    if (typeof counter.archived !== "undefined")
      this.isArchived = counter.archived
    if (counter.type) {
      this.type = counter.type
    }
    if (counter.passport_number) this.number = counter.passport_number
    if (counter.tariff) {
      this.tariff = {
        id: counter.tariff.id,
        name: counter.tariff.code_name,
      }
    }
    if (counter.company) {
      this.company = {
        id: counter.company.id,
        name: counter.company.name,
      }
    }
    if (counter.check_date)
      this.nextCheck = DateTime.fromISO(counter.check_date)
    if (counter.activation_date)
      this.startDate = DateTime.fromISO(counter.activation_date)
    this.tariffValues.updateMeter(counter, counter.tariff?.value)
    if (typeof counter.company_is_administrative === "boolean")
      this.companyIsAdministrative = counter.company_is_administrative
    if (typeof counter.auto_sending === "boolean")
      this.withAutoSending = counter.auto_sending
    if (typeof counter.no_tariff === "boolean")
      this.noTariff = counter.no_tariff
  }

  updateOperatingNumber = (operatingNumber: string) => {
    this.operationalAccountNumber = operatingNumber
  }

  get title() {
    return this.type ? _.capitalize(this.type) : _.capitalize(this.resource)
  }

  get archivedTitle() {
    return this.type
      ? `${_.capitalize(this.resource)} - ${this.title}`
      : this.title
  }

  get totalPrice() {
    return this.noTariff ? this.price : this.tariffValues.totalPrice
  }

  getPatchCounterRequestBody = (data: Partial<MeterFeaturesFormFields>) =>
    clearNotValidFields(
      {
        ...getCommonMeterParams(data),
        ...(String(data.archived) && {
          archived: data.archived,
        }),
        ...(typeof data.initialValue === "number" && {
          initial_value: data.initialValue,
        }),
      },
      true
    )

  static getDefaultValues = (
    res: SingleMeterParams,
    data: MeterFeaturesFormFields
  ) => ({
    ...res,
    previous_value: data.initialValue!.toString(),
    initial_value: data.initialValue!.toString(),
    edit_date: DateTime.now().toISODate() || "",
    counter_value: data.initialValue!.toString(),
    consumption: 0,
    payer: {
      ...(res.payer as MeterPayerModel),
      payer_type: matchesAPISubjectRoles[
        data.meterPayer?.mainPayer as SubjectRoles
      ] as APISubjectRoles,
    },
  })
}

export default SingleMeterStore
