import React from "react"
import styled from "@emotion/styled"
import { ChangeLineTableRowProps } from "./types"
import TableCell from "../../../../ui/TableCell"

const ChangeLineTableRow = ({ change }: ChangeLineTableRowProps) => {
  const isArray = change.lines?.length

  return (
    <>
      <tr>
        <StyledTableCell>{change.fieldName}</StyledTableCell>
        <StyledTableCell parentWidth={808} width="30%">
          {isArray ? null : change.currentValue || "-"}
        </StyledTableCell>
        <StyledTableCell parentWidth={808} width="30%">
          {isArray ? null : change.newValue || "-"}
        </StyledTableCell>
      </tr>
      {isArray &&
        change.lines?.map((line) => (
          <tr>
            <StyledNestedCell>{line.fieldName}</StyledNestedCell>
            <StyledNestedCell parentWidth={808} width="30%">
              {line.currentValue || "-"}
            </StyledNestedCell>
            <StyledNestedCell parentWidth={808} width="30%">
              {line.newValue || "-"}
            </StyledNestedCell>
          </tr>
        ))}
    </>
  )
}

export default ChangeLineTableRow

const StyledTableCell = styled(TableCell)`
  font-size: 14px;
  padding: 4px 0;
  vertical-align: top;
  div {
    word-break: break-all;
    min-height: unset;
  }
`

const StyledNestedCell = styled(StyledTableCell)`
  div {
    color: ${({ theme }) => theme.palette.grey.fiftyP};
  }
  &:first-of-type {
    padding-left: 16px;
  }
`
