import { DateTime } from "luxon"
import {
  ElectricityMetersResponse,
  MeterFeaturesFormFields,
  WaterMetersResponse,
} from "kui-crm"
import {
  ElectricityMeterParams,
  MeterPayerModel,
  WaterMeterParams,
} from "kui-crm/types"
import { userLiteMock } from "../../user/data"

export const countersIds = {
  archive: 1,
  active: 2,
}

const companyMock = {
  id: 1,
  name: "Company",
}

const waterTariffMock = {
  id: 1,
  code_name: "kjhl",
  value: 11,
}

const T1TariffMock = {
  id: 1,
  code_name: "kjhl",
  T1: 11,
}

const T2TariffMock = {
  id: 1,
  code_name: "kjhl",
  T1: 11,
  T2: 6,
}

const meterPayer: MeterPayerModel = {
  payments_info: {
    utility_type: "utility",
    payment_made_by: "owner",
    refund_from: "renter",
    refund_value: 100,
    value_type: "percent",
  },
  ...userLiteMock,
}

export const coldWaterMeterMock: WaterMeterParams = {
  id: 1,
  tariff: waterTariffMock,
  company: companyMock,
  passport_number: "123456",
  archived: false,
  type: "cold",
  check_date: "2021-10-25",
  activation_date: "2021-10-21",
  initial_value: "100",
  previous_value: "150",
  counter_value: "152",
  edit_date: "2021-10-25",
  consumption: "2",
  payer: meterPayer,
  company_is_administrative: false,
  auto_sending: false,
  no_tariff: false,
  cost: "500",
  is_avg: false,
}

export const hotWaterMeterMock: WaterMeterParams = {
  id: 2,
  tariff: waterTariffMock,
  company: companyMock,
  passport_number: "4356374",
  archived: false,
  type: "hot",
  check_date: "2021-10-25",
  activation_date: "2021-10-21",
  initial_value: "50",
  previous_value: "140",
  counter_value: "144",
  edit_date: "2021-10-25",
  consumption: "4",
  payer: meterPayer,
  company_is_administrative: false,
  auto_sending: false,
  no_tariff: false,
  cost: "",
  is_avg: false,
}

export const t1CounterMock: ElectricityMeterParams = {
  id: 3,
  tariff: T1TariffMock,
  passport_number: "7438434",
  archived: false,
  type: "T1",
  check_date: "2021-10-25",
  activation_date: "2021-10-21",
  company: companyMock,
  values: {
    T1: {
      initial_value: "100",
      previous_value: "150",
      counter_value: "152",
      consumption: "2",
      edit_date: "2021-10-25",
      cost: "22",
    },
  },
  payer: meterPayer,
  company_is_administrative: true,
  auto_sending: false,
  no_tariff: false,
  cost: null,
  is_avg: false,
}

export const t2CounterMock: ElectricityMeterParams = {
  id: 4,
  tariff: T2TariffMock,
  company: companyMock,
  passport_number: "8923788",
  archived: false,
  type: "T2",
  check_date: "2021-10-25",
  activation_date: "2021-10-21",
  values: {
    T1: {
      initial_value: "0",
      previous_value: "13",
      counter_value: "22",
      consumption: "9",
      edit_date: "2021-10-25",
      cost: "99",
    },
    T2: {
      initial_value: "1",
      previous_value: "10",
      counter_value: "23",
      consumption: "13",
      edit_date: "2021-10-25",
      cost: "78",
    },
  },
  payer: meterPayer,
  company_is_administrative: false,
  auto_sending: false,
  no_tariff: false,
  cost: null,
  is_avg: false,
}

export const waterMetersMock: WaterMetersResponse = {
  total_cost: "",
  meters: [coldWaterMeterMock, hotWaterMeterMock],
  sewerage: null,
}

export const waterMetersWithArchivedMock: WaterMetersResponse = {
  total_cost: "",
  meters: [
    coldWaterMeterMock,
    hotWaterMeterMock,
    { ...coldWaterMeterMock, id: 5, archived: true },
  ],
  sewerage: null,
}

export const electricityMetersMock: ElectricityMetersResponse = {
  total_cost: "",
  meters: [t2CounterMock],
  sewerage: null,
}

export const electricityMetersWithArchivedMock: ElectricityMetersResponse = {
  total_cost: "",
  meters: [t2CounterMock, { ...t2CounterMock, id: 6, archived: true }],
  sewerage: null,
}

export const waterMeterFields: MeterFeaturesFormFields = {
  type: "cold",
  number: "6372637",
  nextCheck: DateTime.fromISO("2023-10-15"),
  initialValue: 5,
  startDate: DateTime.fromISO("2022-10-15"),
  operationalAccountNumber: "vkljh",
  tariff: {
    id: 1,
    name: "Tariff",
    type: "water",
  },
  company: {
    id: 1,
    name: "Company",
    logo: "",
  },
}

export const t1CounterFields: MeterFeaturesFormFields = {
  type: "T1",
  number: "6372637",
  nextCheck: DateTime.fromISO("2023-10-15"),
  initialValueT1: 1,
  startDate: DateTime.fromISO("2022-10-15"),
  operationalAccountNumber: "fkdgfd",
  tariff: {
    id: 1,
    name: "Tariff",
    type: "electricity",
  },
  company: {
    id: 1,
    name: "Company",
    logo: "",
  },
}
