import * as React from "react"
import { observer } from "mobx-react"
import { useEffect } from "react"
import MultistepForm from "../../../common/MultistepForm"
import ServiceMainInfoForm from "../ServiceMainInfoForm"
import ServicePaymentInfoForm from "../ServicePaymentInfoForm"
import { ServiceEditingFields, ServiceEditingFormProps } from "./types"
import { ServicePaymentInfoFormFields } from "../ServicePaymentInfoForm/types"

function EditServiceForm(props: ServiceEditingFormProps) {
  const { handleSubmit, service, isPeriodClosed, hasRentalContract, ...other } =
    props
  const { editingForm } = service

  useEffect(() => {
    editingForm.setFormFields({
      ...service,
      name: service.title,
      price: service.value,
      paymentMadeBy: service.paymentMadeBy?.role,
      refundFrom: service.refundFrom?.role,
      chargeTill: service.chargeTill?.toJSDate(),
      chargeFrom: service.chargeFrom?.toJSDate(),
      file: service.invoice,
      hasRentalContract,
    } as ServiceEditingFields)
  }, [])

  const formSubmit = (data: ServicePaymentInfoFormFields) => {
    editingForm.updateFormFields(data)
    handleSubmit({ ...editingForm.fields, ...data } as ServiceEditingFields)
    editingForm.resetForm()
  }

  return (
    <MultistepForm form={service.editingForm}>
      {!isPeriodClosed && (
        <ServiceMainInfoForm formMaker={editingForm} {...other} />
      )}
      <ServicePaymentInfoForm
        formStore={editingForm}
        handleSubmit={formSubmit}
        label="Save"
        isPeriodClosed={isPeriodClosed}
      />
    </MultistepForm>
  )
}

export default observer(EditServiceForm)
