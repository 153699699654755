import React, { SyntheticEvent, useEffect, useRef, useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { Caption, Button, Box } from "kui-basic"
import { CheckIcon, FileIcon, EditIcon, CloseIcon } from "kui-icon"
import { Link } from "kui-crm"
import {
  DisabledStylesProps,
  ErrorStylesProps,
  FileContentStylesProps,
  FileItemProps,
  FileWrapperStylesProps,
} from "./types"

function FileItem({
  file,
  onDelete,
  onChange,
  index,
  selectedFile,
  setSelectedFile,
  limitTitle,
  icon,
  subtitle,
  disabled,
  error,
  className,
  label,
  statusIcon,
  ...otherProps
}: FileItemProps) {
  const formattedFileName = String(
    (label || file.title || file.name || file.file?.name)?.replace(
      /\.[^/.]+$/,
      ""
    )
  )
  const [title, setTitle] = useState(formattedFileName)
  const [isEditing, setIsEditing] = useState(false)
  const inputRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus()
    }
    if (!isEditing && formattedFileName !== title && onChange) {
      onChange(title, Number(index))
    }
  }, [isEditing])

  const toggleEdit = () => {
    setIsEditing((prev) => !prev)
  }

  const handleBlur = () => {
    setIsEditing(false)
  }

  const handleChange = (e: SyntheticEvent<HTMLTextAreaElement>) => {
    setTitle((e.target as HTMLTextAreaElement).value)
  }

  const handleDelete = () => {
    if (onDelete) {
      onDelete(file.index ?? 1, Number(index))
    }
  }

  const handleClick = () => {
    if (setSelectedFile) {
      setSelectedFile(index ?? 1)
    }
  }

  return (
    <>
      <FileWrapper
        selected={(selectedFile ?? -1) === index}
        error={(limitTitle && title.length > limitTitle!) || !!error}
        onClick={handleClick}
        disabled={!!disabled}
        className={`FileWrapper ${className || ""}`}
        {...otherProps}
      >
        <FileIconWrapper className="FileIcon_wrapper">
          {icon || <FileIcon />}
        </FileIconWrapper>
        <ContentWrapper
          withEditing={!!onChange}
          className="FileContent_wrapper"
        >
          <InputWrapper>
            <Link href={file.url} openInNewWindow>
              <InputPlaceholder>{title}</InputPlaceholder>
            </Link>
            <StyledInput
              data-testid="file_name"
              ref={inputRef}
              disabled={!isEditing}
              value={title}
              onChange={handleChange}
              onBlur={handleBlur}
              maxLength={100}
            />
          </InputWrapper>
          {subtitle ||
            (String(file.size) && (
              <Caption data-testid="file_size" size="xs" color="fiftyP">
                {Number(file.size)} mb
              </Caption>
            ))}
        </ContentWrapper>
        <ButtonWrapper className="FileButtons_wrapper">
          {statusIcon ||
            (!disabled && onChange && (
              <StyledButton
                isCircle
                data-testid="edit_file_name_btn"
                size="xs"
                variant="transparentLight"
                onClick={toggleEdit}
              >
                {isEditing ? (
                  <CheckIcon />
                ) : (
                  <EditIcon width={18} height={18} />
                )}
              </StyledButton>
            ))}
          {onDelete && !disabled && (
            <StyledButton
              isCircle
              data-testid="delete_file_btn"
              size="xs"
              variant="transparentLight"
              onClick={handleDelete}
            >
              <CloseIcon />
            </StyledButton>
          )}
        </ButtonWrapper>
      </FileWrapper>
      {((limitTitle && title.length > limitTitle) || error) && (
        <Box mb={1}>
          <Caption size="xs" colorGroup="red" weight={500}>
            {error ||
              "File upload error. The number of characters in the file name should not exceed 100"}
          </Caption>
        </Box>
      )}
    </>
  )
}

export default FileItem

FileItem.defaultProps = {
  limitTitle: 100,
}

const errorCSS = ({ theme, error }: ErrorStylesProps) =>
  error &&
  css`
    border-color: ${theme.palette.red.seventy};
  `

const disabledCSS = ({ theme, disabled }: DisabledStylesProps) =>
  disabled &&
  css`
    background: ${theme.palette.background.light1};
  `

const FileWrapper = styled.div<FileWrapperStylesProps>`
  width: 100%;
  border-radius: 12px;
  padding: 10px 12px;
  border: 1px solid
    ${({ theme, selected }) =>
      selected ? theme.palette.brand.main : theme.palette.grey.fifteenB};
  display: flex;
  margin-bottom: 8px;
  cursor: pointer;
  ${disabledCSS};
  ${errorCSS};
  &:last-child {
    margin-bottom: 0;
  }
`

const FileIconWrapper = styled.div`
  margin-right: 16px;
  min-height: 32px;
  width: 32px;
  border-radius: 12px;
  background: ${({ theme }) => theme.palette.brand.light};
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
`

const ButtonWrapper = styled.div`
  margin-left: auto;
  padding-left: 8px;
  display: flex;
  align-items: center;
  svg {
    path {
      fill: ${({ theme }) => theme.palette.grey.thirty};
    }
  }
`

const ContentWrapper = styled.div<FileContentStylesProps>`
  width: calc(100% - ${({ withEditing }) => (withEditing ? 120 : 64)}px);
  min-height: 34px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const StyledInput = styled.textarea`
  border: none;
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 500;
  background: transparent;
  padding: 0;
  color: ${({ theme }) => theme.palette.grey.seventy};
  caret-color: ${({ theme }) => theme.palette.brand.main};
  height: -webkit-fill-available;
  overflow: hidden;
  resize: none;
  width: 100%;
  &:focus-visible {
    outline: none;
  }
  &:disabled {
    pointer-events: none;
  }
`

const InputPlaceholder = styled.span`
  font-size: 14px;
  font-weight: 500;
  word-break: break-word;
  max-width: 100%;
  color: transparent;
  min-height: 17px;
  display: inline-block;
  white-space: nowrap;
`

const InputWrapper = styled.div`
  position: relative;
  width: fit-content;
  max-width: 100%;
  height: auto;
`

const StyledButton = styled(Button)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  &:last-of-type {
    margin-right: 0px;
  }
`
