import React from "react"
import { observer } from "mobx-react"
import SortingTable from "../../../../components/common/SortingTable"
import useSCsStore from "../../store"
import clientsHeadCellsParams from "./headParams"
import ServiceContractRow from "../ServiceContractRow"

function ServiceContractsTable() {
  const scStore = useSCsStore()

  return (
    <SortingTable
      withOverlaps
      headParams={clientsHeadCellsParams}
      store={scStore}
    >
      {scStore.contracts.map((contract, key) => (
        <ServiceContractRow
          key={contract.id}
          contract={contract}
          isDark={key % 2 !== 0}
        />
      ))}
    </SortingTable>
  )
}

export default observer(ServiceContractsTable)
