import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { Loader, Paginator } from "kui-utils"
import {
  GetNotesResponse,
  NoteModel,
  NotesEntities,
} from "../../types/api/notes"
import NoteStore from "./Note"
import NotesAgent from "../../agent/Notes"
import { NoteFields } from "../../components/forms/note/NoteForm/types"

class NotesStore {
  notes: NoteStore[]

  entity: NotesEntities

  entityId: number

  loader: Loader

  actionLoader: Loader

  paginator: Paginator

  constructor(entity: NotesEntities, entityId: number) {
    this.notes = []
    this.entity = entity
    this.entityId = entityId
    this.loader = new Loader()
    this.actionLoader = new Loader()
    this.paginator = new Paginator()
    makeAutoObservable(this)
  }

  fetchNotes = async () => {
    this.loader.startLoading()

    const [err, res] = await to<GetNotesResponse>(
      NotesAgent.getAll(this.entity, this.entityId)
    )
    runInAction(() => {
      if (!err && res) {
        const mapper = (note: NoteModel) => new NoteStore(note, this)

        this.notes = this.paginator.getPageResponse<NoteModel, NoteStore>(
          res,
          this.notes,
          mapper
        )
      } else {
        this.loader.setError("fetch notes", err)
      }
      this.loader.endLoading()
    })
  }

  createNote = async (data: NoteFields) => {
    this.actionLoader.startLoading("note creation")

    const [err, res] = await to<NoteModel>(
      NotesAgent.create(this.entity, this.entityId, data)
    )
    runInAction(() => {
      if (!err && res) {
        this.notes = [new NoteStore(res, this), ...this.notes]
      } else {
        this.actionLoader.setError("note creation", err)
      }
      this.actionLoader.endLoading()
    })
  }

  deleteNoteFromList = (id: number) => {
    this.notes = this.notes.filter((note) => note.id !== id)
  }
}

export default NotesStore
