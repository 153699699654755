import React from "react"
import { Caption, Grid } from "kui-basic"
import { InputWithController, Switch } from "kui-complex"
import styled from "@emotion/styled"
import { PropertyLinkFieldProps, PropertyLogoStylesProps } from "./types"

const PropertyLinkField = (props: PropertyLinkFieldProps) => {
  const { label, name, form, withShadow, imageSrc, disabled, withLink } = props

  return (
    <Grid item xs={12}>
      <StyledWrapper container alignItems="center">
        <StyledImage
          withShadow={withShadow}
          src={imageSrc}
          alt="company logo"
        />
        <Caption size="s" variant="high" weight={600}>
          {label}
        </Caption>
        <StyledSwitch
          name={`${name}.turnOn`}
          form={form}
          size="m"
          disabled={disabled}
        />
      </StyledWrapper>
      {withLink && (
        <InputWithController
          label="Link to ad"
          name={`${name}.link`}
          form={form}
          disabled={disabled}
        />
      )}
    </Grid>
  )
}

export default PropertyLinkField

const StyledWrapper = styled(Grid)`
  padding: 0 0 8px 8px;
`

const StyledImage = styled.img<PropertyLogoStylesProps>`
  width: 20px;
  height: 20px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: ${({ withShadow }) =>
    withShadow ? "0px 0px 5px rgba(0, 0, 0, 0.10)" : "none"};
  margin-right: 8px;
`

const StyledSwitch = styled(Switch)`
  margin-left: auto;
`
