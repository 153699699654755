import React from "react"
import UploadPhotoModal from "../UploadPhotoModal"
import PhotoSlider from "../PhotoSlider"
import { PhotoSliderAndModalProps } from "./types"
import { PhotoCardParams } from "../PhotoCard/types"

const PhotoSliderAndModal = <T extends PhotoCardParams>(
  props: PhotoSliderAndModalProps<T>
) => {
  const {
    modalProps,
    sliderProps,
    loadImages,
    selectedImage,
    images,
    onDelete,
    ...other
  } = props
  const { open: isSliderOpen } = sliderProps

  return (
    <>
      <UploadPhotoModal {...modalProps} {...other} handleSubmit={loadImages} />
      {isSliderOpen && (
        <PhotoSlider
          isOpen={isSliderOpen}
          active={selectedImage}
          images={images}
          onDelete={onDelete}
          {...sliderProps}
        />
      )}
    </>
  )
}

export default PhotoSliderAndModal
