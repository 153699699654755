import { makeAutoObservable } from "mobx"
import { FileBodyRequest, ImageBodyRequest, FileStore } from "kui-crm"
import { FileVariant } from "../../types/common"

class FilesStore {
  files: FileStore[]

  variant: FileVariant

  constructor(variant: FileVariant) {
    this.files = []
    this.variant = variant
    makeAutoObservable(this)
  }

  addFile = (newFile: FileStore) => {
    this.files = [...this.files, newFile]
    this.files.forEach((file, key) => {
      file.setIndex(key)
    })
  }

  removeFile = (index: number) => {
    this.files = this.files.filter((file, key) => key !== index)
    this.files.forEach((file, key) => {
      file.setIndex(key)
    })
  }

  setFiles = (files: FileStore[]) => {
    this.files = files.map((file) => FileStore.initFromFileStore(file))
  }

  uploadFiles = async () => {
    await Promise.allSettled(
      this.notUploadedFiles.map((file) => file.uploadFile(this.variant))
    )
  }

  get uploadedData() {
    return this.files
      .map((file) => file.uploadedData)
      .filter((data) => data) as (FileBodyRequest | ImageBodyRequest)[]
  }

  get uploadedAllFiles() {
    return !this.notUploadedFiles.length
  }

  get notUploadedFiles() {
    return this.files.filter((file) => file.uploadStatus !== "success")
  }

  get isUploading() {
    return !!this.files.filter((file) => file.uploadStatus === "loading").length
  }
}

export default FilesStore
