import * as React from "react"
import { PropsWithChildren, SyntheticEvent, useEffect } from "react"
import styled from "@emotion/styled"
import { theme, Button } from "kui-basic"
import { useToggle } from "kui-utils"
import { PlusIcon } from "kui-icon"
import ApartmentFillingCard from "../ApartmentFillingCard"
import { ApartmentFillingSectionProps } from "./types"
import AccordionWithSummary from "../../../../common/AccordionWithSummary"
import { AccordionWithSummaryProps } from "../../../../common/AccordionWithSummary/types"
import FillingCreatingModal from "../../../../../pages/ApartmentPage/components/tabs/ApartmentDescriptionTab/FillingCreatingModal"
import { ApartmentFillingVariants } from "../../../../../pages/ApartmentPage/types/store/apartmentFilling"
import { maxWidth } from "../../../../../utils/service/theme"
import { FillingParams } from "../ApartmentFillingCard/types"

function ApartmentFillingSection<T extends FillingParams>(
  props: ApartmentFillingSectionProps<T>
) {
  const { title, fillingList, onAdd, disabled, ...other } = props
  const [isModalOpen, handleModalOpen, handleModalClose] = useToggle()

  const handleClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    handleModalOpen()
  }

  useEffect(() => {
    window.dispatchEvent(new Event("calcPortalPosition"))
  }, [fillingList.length])

  return (
    <Wrapper>
      <StyledAccordionWithSummary
        expanded={fillingList.length > 0}
        title={title}
        endComponent={
          onAdd && (
            <StyledButton
              isCircle
              disabled={disabled}
              size="xs"
              variant="whiteWithGray"
              onClick={handleClick}
            >
              <PlusIcon width={10} height={10} />
            </StyledButton>
          )
        }
      >
        {fillingList.map((elem, index) => (
          <ApartmentFillingCard
            index={index}
            key={elem.id}
            filling={elem}
            disabled={disabled}
            {...other}
          />
        ))}
      </StyledAccordionWithSummary>
      {onAdd && (
        <FillingCreatingModal
          open={isModalOpen}
          handleClose={handleModalClose}
          type={title.toLowerCase() as ApartmentFillingVariants}
          handleSubmit={onAdd}
        />
      )}
    </Wrapper>
  )
}

export default ApartmentFillingSection

const Wrapper = styled.div`
  padding: 0 24px;
  margin-bottom: 24px;
  background-color: ${theme.palette.background.light1};
  border-radius: 8px;
  &:last-of-type {
    margin-bottom: 0;
  }
  ${maxWidth("md")} {
    padding: 0 18px;
  }
`

const StyledSummary = styled(AccordionWithSummary)`
  padding: 20px 0 20px;
`

const StyledAccordionWithSummary = styled(
  ({ className, ...props }: PropsWithChildren<AccordionWithSummaryProps>) => (
    <StyledSummary {...props} detailsProps={{ className }} />
  )
)`
  padding: 0 0 24px;
`

const StyledButton = styled(Button)`
  margin-left: auto;
  border-color: transparent;
`
