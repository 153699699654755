import React from "react"
import styled from "@emotion/styled"
import { Caption, Grid } from "kui-basic"
import { useToggle } from "kui-utils"
import { PlusIcon } from "kui-icon"
import { CreatingEntityFromDropdownProps } from "./types"

function CreatingEntityFromDropdown({
  creatingLabel,
  renderCreatingModal,
}: CreatingEntityFromDropdownProps) {
  const [isModalOpen, handleModalOpen, handleModalClose] = useToggle()

  if (!creatingLabel || !renderCreatingModal) {
    return null
  }

  const handleModalWrapperClick = (e: any) => e.stopPropagation()

  return (
    <>
      <StyledWrapper container onClick={handleModalOpen} alignItems="center">
        <StyledPlusIcon width={12} height={12} />
        <Caption color="fiftyP" size="xs" weight={500}>
          {creatingLabel}
        </Caption>
      </StyledWrapper>
      <StyledModalWrapper onClick={handleModalWrapperClick}>
        {renderCreatingModal({
          open: isModalOpen,
          handleClose: handleModalClose,
        })}
      </StyledModalWrapper>
    </>
  )
}

export default CreatingEntityFromDropdown

const StyledWrapper = styled(Grid)`
  width: 100%;
  position: sticky;
  bottom: 0;
  left: 0;
  border-radius: 0 0 12px 12px;
  border-top: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
  padding: 8px 19px;
  cursor: pointer;
  background: white;
`

const StyledPlusIcon = styled(PlusIcon)`
  margin-right: 14px;
  path {
    fill: ${({ theme }) => theme.palette.grey.fiftyP};
  }
`

const StyledModalWrapper = styled.div``
