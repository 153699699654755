import * as yup from "yup"

const CompanyChangeSchema = yup.object().shape({
  newCompany: yup.object({
    name: yup.string().required("Choose a company"),
  }),
  reason: yup.object({
    file: yup.mixed().required("Choose a reason"),
  }),
})

export default CompanyChangeSchema
