import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { Paginator, SortingFilter, Loader, MultistepForm } from "kui-utils"
import {
  GetHeatingMetersResponse,
  HeatingMeterModel,
  PostCommonMeterParams,
} from "../../../types/api/meters"
import MeterStore from "./MeterStore"
import {
  HeatingMeterCreationFields,
  MeterCreationFormFields,
} from "../../../components/forms/meters/MeterCreationFormFields/types"
import MetersAgent from "../../../agent/Meters"
import { getCommonMeterParams } from "../../../utils/service/apiMapper"

class HeatingMetersStore {
  meters: MeterStore[]

  paginator: Paginator

  filter: SortingFilter

  loader: Loader

  creationLoader: Loader

  creationForm: MultistepForm<MeterCreationFormFields>

  constructor() {
    this.meters = []
    this.loader = new Loader()
    this.creationLoader = new Loader()
    this.paginator = new Paginator()
    this.filter = new SortingFilter("id")
    this.creationForm = new MultistepForm<MeterCreationFormFields>()
    makeAutoObservable(this)
  }

  fetchAll = async () => {
    this.loader.startLoading()

    const [err, res] = await to<GetHeatingMetersResponse>(
      MetersAgent.all(
        "heating",
        this.paginator.offset,
        this.paginator.limit,
        this.filter.filterParams
      )
    )
    runInAction(() => {
      if (!err && res) {
        const mapper = (meter: HeatingMeterModel) =>
          new MeterStore(meter, "heating")

        this.meters = this.paginator.getPageResponse<
          HeatingMeterModel,
          MeterStore
        >(res, this.meters, mapper)
      } else {
        this.loader.setError("fetch heating meters", err)
      }
      this.loader.endLoading()
    })
  }

  createMeter = async (data: HeatingMeterCreationFields) => {
    this.creationLoader.startLoading()

    const body = HeatingMetersStore.getCreationBody(data)

    const [err, res] = await to(MetersAgent.createMeter(body, "heating"))
    runInAction(() => {
      if (!err && res) {
        this.meters = [...this.meters, new MeterStore(res, "heating")]
      } else {
        this.creationLoader.setError("heating meter creation", err)
      }
      this.creationLoader.endLoading()
    })
  }

  static getCreationBody = (data: HeatingMeterCreationFields) => ({
    apartment: data.apartment.id,
    initial_value: data.initialValue,
    ...(getCommonMeterParams(data) as Omit<PostCommonMeterParams, "apartment">),
    ...(data.replaceableCounter && {
      replaceable_counter: {
        counter: Number(data.replaceableCounter),
        last_value: data.lastValue!,
      },
    }),
  })
}

export default HeatingMetersStore
