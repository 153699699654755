/** @jsxImportSource @emotion/react */
import React from "react"
import { css } from "@emotion/react"

const wrapperStyles = css`
  width: 88px;
  height: 88px;
  border-radius: 16px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`

type ApartmentPreviewImageProps = {
  image: string | null | undefined
}

function ApartmentPreviewImage({ image }: ApartmentPreviewImageProps) {
  return (
    <div
      css={[wrapperStyles, !image && { backgroundSize: "contain" }]}
      style={{
        backgroundImage: `url(${image || "/images/LivingAreaSmall.svg"})`,
      }}
    />
  )
}

export default ApartmentPreviewImage
