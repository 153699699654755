import { makeAutoObservable, runInAction } from "mobx"
import { Loader, Paginator, resHandler } from "kui-utils"
import to from "await-to-js"
import { DateTime } from "luxon"
import DashboardAgent from "../../../../agent/Dashboard"
import ApartmentsStore from "../../../../store/lites/ApartmentsStore"
import { ContractTypes } from "../../../../types/common"
import { PendingContractParams } from "../../types/cards/contracts"
import { PendingContractReminderModel } from "../../types/api/contracts"
import DashboardPageStore from "../DashboardPageStore"

class PendingContractsStore {
  value: number | null

  contracts: PendingContractParams[]

  paginator: Paginator

  loader: Loader

  type: ContractTypes

  dashboardStore: DashboardPageStore

  constructor(type: ContractTypes, dashboardStore: DashboardPageStore) {
    this.dashboardStore = dashboardStore
    this.value = null
    this.contracts = []
    this.paginator = new Paginator(5)
    this.loader = new Loader()
    this.type = type
    makeAutoObservable(this)
  }

  fetchContracts = async () => {
    this.loader.startLoading()

    const response = await to(
      DashboardAgent.getPendingContracts(
        this.type,
        this.paginator.limit,
        this.paginator.offset,
        this.dashboardStore.periodFilter
      )
    )

    runInAction(() => {
      resHandler(response, this.loader, (res) => {
        this.value = res.count
        this.contracts = this.paginator.getPageResponse(
          res,
          this.contracts,
          PendingContractsStore.getContractParams
        )
      })
    })
  }

  static getContractParams = ({ contract }: PendingContractReminderModel) => ({
    id: contract.id,
    apartment: ApartmentsStore.getApartmentLinkParams(contract.apartment),
    number: contract.number,
    creationDate: contract.created_at
      ? DateTime.fromISO(contract.created_at)
      : null,
  })
}

export default PendingContractsStore
