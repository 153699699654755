/** @jsxImportSource @emotion/react */
import React from "react"
import TableCell from "../../ui/TableCell"
import { OptionDropdownType } from "../../ui/DropdownMenu/types"
import { TableCellProps } from "../../ui/TableCell/types"
import MenuButton from "../MenuButton"

type TableCellMenuProps = {
  options: OptionDropdownType[]
  disabled?: boolean
} & TableCellProps

function TableCellMenu({
  options,
  disabled,
  ...otherProps
}: TableCellMenuProps) {
  return (
    <TableCell css={{ paddingRight: 10 }} isLast {...otherProps}>
      <MenuButton options={options} disabled={disabled} />
    </TableCell>
  )
}

export default TableCellMenu
