import requests from "./index"

const CatalogsAgent = {
  getEstateAgents: (offset: number, limit: number, params: string) =>
    requests.get(
      `/users/staff?user_role=consultant&limit=${limit}&offset=${offset}${params}`
    ),
}

export default CatalogsAgent
