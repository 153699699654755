import * as yup from "yup"
import { ComparableObjectFieldsSchema } from "../../../../../../components/forms/apartment/InspectionCreationForm/variations/AppraisalInspectionForm/ComparableObjectStep/schema"
import { StatisticalValuationFieldsSchema } from "../../../../../../components/forms/apartment/InspectionCreationForm/variations/AppraisalInspectionForm/StatisticalValuationStep/schema"
import { ManagerValuationFieldsSchema } from "../../../../../../components/forms/apartment/InspectionCreationForm/variations/AppraisalInspectionForm/ManagerValuationStep/schema"

const AppraisalInspectionInfoSchema = yup.object().shape({
  comparableObjects: yup.array().of(yup.object(ComparableObjectFieldsSchema)),
  statisticalValuations: yup
    .array()
    .of(yup.object(StatisticalValuationFieldsSchema)),
  managerValuation: yup.object(ManagerValuationFieldsSchema),
})

export default AppraisalInspectionInfoSchema
