import { DocumentVisibilityVariants } from "kui-crm/types"

export type VisibilitySettings = {
  tenant?: boolean
  landlord?: boolean
}

export const getAPIVisibility = ({ landlord, tenant }: VisibilitySettings) => {
  if (landlord && tenant) return "everybody"
  if (landlord) return "owner"
  if (tenant) return "renter"
  return "nobody"
}

export const getVisibility = ({ landlord, tenant }: VisibilitySettings) => {
  if (landlord && tenant) return "everybody"
  if (landlord) return "landlord"
  if (tenant) return "tenant"
  return "nobody"
}

export const getVisibilitySettings = (
  visibility?: DocumentVisibilityVariants
) => {
  switch (visibility) {
    case "everybody":
      return { tenant: true, landlord: true }
    case "landlord":
      return { tenant: false, landlord: true }
    case "tenant":
      return { tenant: true, landlord: false }
    default:
      return { tenant: false, landlord: false }
  }
}
