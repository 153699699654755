import React from "react"
import { ArrayPath, useFieldArray } from "react-hook-form"
import { observer } from "mobx-react"
import { HeadingWithButton } from "kui-crm"
import { CloseIcon } from "kui-icon"
import { UserContactsProps } from "../types"
import ClientCarFields from "../ClientCarFields"
import useClientStore from "../../../store"

function ClientCarsFields({ form }: UserContactsProps) {
  const { editor } = useClientStore()
  const disabled = !editor.isEditing

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "cars" as ArrayPath<{ mark: string; number: string }>,
  })

  const addCar = () => {
    append({ mark: "", number: "" })
  }

  return (
    <>
      <HeadingWithButton
        showButton={!disabled}
        onClick={addCar}
        title={`Car${fields.length > 0 ? " 1" : ""}`}
        variant="whiteWithGray"
      />
      {fields.length > 0 && (
        <ClientCarFields
          form={form}
          disabled={disabled}
          inputKey={fields[0]?.id}
          index={0}
        />
      )}
      {fields.slice(1).map((car, key) => (
        <>
          <HeadingWithButton
            showButton={!disabled}
            onClick={() => remove(key + 1)}
            title={`Car ${key + 2}`}
            variant="grayLight"
            icon={<CloseIcon width={8} height={8} />}
          />
          <ClientCarFields
            form={form}
            disabled={disabled}
            inputKey={fields[key + 1]?.id}
            index={key + 1}
          />
        </>
      ))}
    </>
  )
}

export default observer(ClientCarsFields)
