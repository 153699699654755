import React from "react"
import { LoaderState, ModalProps, Modal } from "kui-crm"
import useApartmentLiteStore from "../../../../../store/shared/apartment"
import InspectionCreationForm from "../../forms/InspectionCreationForm"

const AppraisalModal = (props: ModalProps) => {
  const { open, handleClose } = props
  const { inspectionsStore } = useApartmentLiteStore()

  return (
    <Modal
      title="New appraisal inspection"
      open={open}
      handleClose={handleClose}
    >
      <InspectionCreationForm type="appraisal" handleClose={handleClose} />
      <LoaderState loader={inspectionsStore.actionLoader} />
    </Modal>
  )
}

export default AppraisalModal
