import to from "await-to-js"
import { makeAutoObservable, runInAction } from "mobx"
import { Loader } from "kui-utils"
import CompanyBankStore from "./CompanyBankStore"
import {
  CompanyBankInfoModel,
  GetCompanyBankInfoResponse,
} from "../types/api/companyAPI"
import CompanyAgent from "../../../agent/Company"
import { CompanyBankInfoFormFields } from "../forms/CompanyBanksInfoForm/types"
import { callCRUDBasedOnFormActions } from "../../../utils/service/functions"
import { clearNotValidFields } from "../../../utils/service/mapper"

class CompanyBanksStore {
  banks: CompanyBankStore[]

  loader: Loader

  constructor(loader: Loader) {
    this.banks = []
    this.loader = loader
    makeAutoObservable(this)
  }

  fetchBanks = async (companyId: number) => {
    const [err, res] = await to<GetCompanyBankInfoResponse>(
      CompanyAgent.getCompanyBanks(companyId)
    )
    runInAction(() => {
      if (res && !err) {
        this.banks = res.map((bank) => new CompanyBankStore(bank, this))
      } else {
        this.loader.setError("fetch banks", err)
      }
    })
  }

  createBank = async (data: CompanyBankInfoFormFields, companyId: number) => {
    const body = CompanyBanksStore.getPostBankBody(data)

    const [err, res] = await to<CompanyBankInfoModel>(
      CompanyAgent.postCompanyBank(companyId, body)
    )
    if (res && !err) {
      this.addBank(res)
    } else {
      this.loader.setError("bank creation", err)
    }
  }

  patchBanks = async (companyId: number, data: CompanyBankInfoFormFields[]) => {
    await callCRUDBasedOnFormActions(
      this.banks,
      data,
      this.createBank,
      companyId
    )
  }

  addBank = (data: CompanyBankInfoModel) => {
    this.banks = [
      ...this.banks,
      new CompanyBankStore(data as CompanyBankInfoModel, this),
    ]
  }

  deleteBank = (id: number) => {
    this.banks = this.banks.filter((bank) => bank.id !== id)
  }

  static getPostBankBody = (data: CompanyBankInfoFormFields) =>
    clearNotValidFields({
      name: data.name,
      bank_name: data.bankName || null,
      SWIFT: data.SWIFT || null,
      BIC: data.BIC || null,
      correspondent_account: data.correspondentAccount || null,
      account: data.account || null,
    })
}

export default CompanyBanksStore
