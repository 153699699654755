import React from "react"
import { observer } from "mobx-react"
import useCompanyStore from "../../../store"
import { CompanyTabsButtonsProps } from "./types"
import CompanyEditButton from "../CompanyEditButton"

const CompanyTabsButtons = ({ activeTab }: CompanyTabsButtonsProps) => {
  const { overviewStore } = useCompanyStore()
  const withTariffs = overviewStore?.type === "administrative"

  return (
    <>
      {!(
        (withTariffs && (activeTab === 2 || activeTab === 4)) ||
        (!withTariffs && activeTab === 4)
      ) && <CompanyEditButton />}
    </>
  )
}

export default observer(CompanyTabsButtons)
