import React from "react"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import { Heading } from "kui-basic"
import ApartmentFilesContent from "../../../../../../components/tabs/ApartmentDocumentsTab/ApartmentFilesContent"
import { ApartmentAttachmentsBlockProps } from "./types"
import useFetchPage from "../../../../../../../../hooks/useFetchPage"

function ApartmentAttachmentsBlock(props: ApartmentAttachmentsBlockProps) {
  const { title, attachmentsStore, apartmentId, date } = props

  const initAttachments = async () => {
    if (apartmentId) {
      attachmentsStore?.fetchDocuments(apartmentId, date)
    } else {
      attachmentsStore?.cleanDocuments()
    }
  }

  useFetchPage(attachmentsStore?.paginator, initAttachments, [date])

  if (!attachmentsStore) return null

  return (
    <>
      <StyledHeading size="h4">{title}</StyledHeading>
      <ApartmentFilesContent attachmentsStore={attachmentsStore} />
    </>
  )
}

export default observer(ApartmentAttachmentsBlock)

const StyledHeading = styled(Heading)`
  margin-bottom: 8px;
`
