import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { Loader, SortingFilter, Paginator } from "kui-utils"
import { ChangeReasonFormFields } from "kui-crm"
import { FileBodyRequest } from "kui-crm/types"
import CompanyObjectStore from "./CompanyObjectStore"
import { PaginationStoreInterface } from "../../../types/store/pagination"
import CompanyAgent from "../../../agent/Company"
import {
  AddObjectsToCompanyRequest,
  GetCompanyObjectsResponse,
} from "../types/api/companyAPI"
import { CompanyObjectConnectionFormFields } from "../forms/CompanyObjectConnectionForm/types"
import { CompanyChangeFormFields } from "../../../components/forms/company/CompanyChangeForm/types"
import { uploadNewFile } from "../../../utils/agent/uploadFiles"

class CompanyObjectsStore implements PaginationStoreInterface {
  loader: Loader

  actionLoader: Loader

  filter: SortingFilter

  paginator: Paginator

  objects: CompanyObjectStore[]

  constructor() {
    this.loader = new Loader()
    this.actionLoader = new Loader()
    this.filter = new SortingFilter()
    this.paginator = new Paginator()
    this.objects = []
    makeAutoObservable(this)
  }

  fetchCompanyObjects = async (companyId: number) => {
    this.loader.startLoading()

    const [err, res] = await to<GetCompanyObjectsResponse>(
      CompanyAgent.getCompanyObjects(companyId)
    )
    runInAction(() => {
      if (!err && res) {
        this.objects = res.map((object) => new CompanyObjectStore(object))
      } else {
        this.loader.setError("fetch objects")
      }
      this.loader.endLoading()
    })
  }

  changeCompanyForSelectedObjects = async (
    companyId: number,
    data: CompanyChangeFormFields
  ) => {
    this.actionLoader.startLoading("company objects connection")

    await this.removeObjectsFromCompany(companyId)
    await this.addObjectsToCompany(data)

    this.actionLoader.endLoading()
  }

  addObjectsToCompany = async (data: CompanyChangeFormFields) => {
    const reasonFile = await uploadNewFile(this.actionLoader, data.reason)

    if (reasonFile) {
      const body = CompanyObjectsStore.getAddObjectsToCompanyBody(
        data,
        reasonFile,
        this.selectedObjects
      )

      const [err, res] = await to<GetCompanyObjectsResponse>(
        CompanyAgent.addObjectsToCompany(data.newCompany!.id, body)
      )

      runInAction(() => {
        if (!err && res) {
          this.objects = res.map((object) => new CompanyObjectStore(object))
        } else {
          this.actionLoader.setError("objects connection")
        }
      })
    }
  }

  addObjectToCompany = async (
    companyId: number,
    data: CompanyObjectConnectionFormFields
  ) => {
    this.actionLoader.startLoading("object connection")

    const reasonFile = await uploadNewFile(this.actionLoader, data.reason)
    if (reasonFile) {
      const body = CompanyObjectsStore.getAddObjectsToCompanyBody(
        data,
        reasonFile,
        [Number(data.apartment?.id)]
      )

      const [err, res] = await to<GetCompanyObjectsResponse>(
        CompanyAgent.addObjectsToCompany(companyId, body)
      )

      runInAction(() => {
        if (!err && res) {
          this.objects = res.map((object) => new CompanyObjectStore(object))
        } else {
          this.actionLoader.setError("object connection", err)
        }
      })
    }
    this.actionLoader.endLoading()
  }

  removeObjectsFromCompany = async (companyId: number) => {
    const body = { apartments: this.selectedObjects }

    const [err] = await to<GetCompanyObjectsResponse>(
      CompanyAgent.deleteObjectsToCompany(companyId, body)
    )

    if (err) this.actionLoader.setError("objects disconnection")
  }

  selectAllObjects = () => {
    this.objects.forEach((object) => object.selectObject())
  }

  unSelectAllObjects = () => {
    this.objects.forEach((object) => object.unSelectObject())
  }

  get selectedObjects() {
    return this.objects
      .filter((object) => object.isSelected)
      .map((object) => object.id)
  }

  static getAddObjectsToCompanyBody = (
    data: ChangeReasonFormFields,
    reasonFile: FileBodyRequest,
    apartments: number[]
  ) =>
    ({
      apartments,
      comment: data.comment,
      reason_document: reasonFile,
    } as AddObjectsToCompanyRequest)
}

export default CompanyObjectsStore
