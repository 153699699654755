import React, { useEffect } from "react"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { Grid } from "kui-basic"
import { CompanyBankInfoFieldsProps } from "./types"
import useCompanyStore from "../../../../store"
import { CompanyBankInfoFormFields } from "../../../../forms/CompanyBanksInfoForm/types"
import getBankInfoFields from "./fields"
import UpdatedFieldsGroup from "../../../../../../components/common/UpdatedFieldsGroup"
import useEditor from "../../../../../../hooks/useEditor"

function CompanyBankInfoFields(props: CompanyBankInfoFieldsProps) {
  const { form, index, removeBankInfo, addBankInfo } = props
  const { editor } = useCompanyStore()
  const disabled = !editor.isEditing
  const bankEditor = useEditor()

  const addBankFields = (data: CompanyBankInfoFormFields) => addBankInfo(data)

  const removeBankFields = () => removeBankInfo(index)

  const bankInfoFields = getBankInfoFields(index)

  useEffect(() => {
    if (!editor.isEditing) {
      bankEditor.endEditing()
    }
  }, [editor.isEditing])

  useEffect(() => {
    const account = form.getValues(`banks.${index}.account`)
    if (!account && index > 0) bankEditor.startEditing()
  }, [])

  return (
    <StyledWrapper container spacing={2}>
      <UpdatedFieldsGroup
        disabled={disabled}
        form={form}
        editor={bankEditor}
        handleAdd={index === 0 && addBankFields}
        handleRemove={index !== 0 && removeBankFields}
        name={`banks.${index}`}
        {...bankInfoFields}
      />
    </StyledWrapper>
  )
}

export default observer(CompanyBankInfoFields)

const StyledWrapper = styled(Grid)`
  &:not(:first-child) {
    margin-top: 8px;
  }
`
