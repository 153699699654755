import * as React from "react"
import { observer } from "mobx-react"
import { Modal, ModalProps, MeterFeaturesFormFields } from "kui-crm"
import EditSingleMeterForm from "../../../../forms/meters/EditSingleMeterForm"
import useExpensesStore from "../../../../store"

function EditGasMeterModal({ open, handleClose }: ModalProps) {
  const { metersStore } = useExpensesStore()
  const gasMeter = metersStore.gasMetersStore.selectedGasMeter

  const handleSubmit = async (data: MeterFeaturesFormFields) => {
    handleClose()
    if (gasMeter?.id) {
      await gasMeter.patchCounter(data)
    }
  }

  if (!gasMeter) {
    return null
  }

  return (
    <Modal title="Edit gas meter" open={open} handleClose={handleClose}>
      <EditSingleMeterForm
        resource="gas"
        meter={gasMeter}
        handleSubmit={handleSubmit}
      />
    </Modal>
  )
}

export default observer(EditGasMeterModal)
