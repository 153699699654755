import React from "react"
import { Box, Grid } from "kui-basic"
import { useFieldArray } from "react-hook-form"
import { InputByType } from "kui-crm"
import styled from "@emotion/styled"
import { ImpairmentsInspectionInfoProps } from "./types"
import getImpairmentInfoFields from "./fields"
import ImagesGroupWithAddButton from "../../../../../../../components/common/ImagesGroupWithAddButton"

const ImpairmentInfoFields = (props: ImpairmentsInspectionInfoProps) => {
  const { form, index, remove, disabled, type } = props
  const {
    fields,
    append,
    remove: removeImage,
  } = useFieldArray({
    control: form.control,
    name: `${type}.${index}.images`,
  })

  const handleDelete = () => remove(index)

  const impairmentFields = getImpairmentInfoFields(
    type,
    index,
    disabled,
    handleDelete
  )

  return (
    <StyledWrapper pt={1} pb={1}>
      <Grid container spacing={3}>
        {impairmentFields.map(({ size, ...field }) => (
          <Grid item xs={size || 4} key={field.name}>
            <InputByType form={form} disabled={disabled} {...field} />
          </Grid>
        ))}
        <Grid item xs={12}>
          <ImagesGroupWithAddButton
            label="Photos of impairment"
            images={fields}
            remove={removeImage}
            append={append}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </StyledWrapper>
  )
}

export default ImpairmentInfoFields

const StyledWrapper = styled(Box)`
  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
  }
`
