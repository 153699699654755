import React from "react"
import styled from "@emotion/styled"
import { Caption, Grid } from "kui-basic"
import ImpairmentsGroup from "../ImpairmentsGroup"
import { ImpairmentsContentProps } from "./types"

const ImpairmentsContent = (props: ImpairmentsContentProps) => {
  const { damages, wear, removeWear, removeDamage, form, disabled } = props

  const groupProps = { form, disabled }

  if (!wear.length && !damages.length)
    return (
      <Caption size="l" weight={600} color="fiftyP">
        No changes
      </Caption>
    )

  return (
    <StyledWrapper container spacing={3}>
      <ImpairmentsGroup
        title="Damages"
        impairments={damages}
        remove={removeDamage}
        type="damages"
        {...groupProps}
      />
      <ImpairmentsGroup
        title="Wear&tear"
        impairments={wear}
        remove={removeWear}
        type="wear"
        {...groupProps}
      />
    </StyledWrapper>
  )
}

export default ImpairmentsContent

const StyledWrapper = styled(Grid)`
  padding-top: 24px;
`
