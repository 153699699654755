import * as yup from "yup"

export const ComparableObjectFieldsSchema = {
  link: yup.string().required("This field is required"),
  screenshot: yup.mixed().required("This field is required"),
  price: yup.string().required("This field is required"),
  differences: yup.string().required("This field is required"),
}

const ComparableObjectStepSchema = yup
  .object()
  .shape(ComparableObjectFieldsSchema)

export default ComparableObjectStepSchema
