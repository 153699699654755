import { rest } from "msw"
import { PatchImageParams } from "../../pages/ApartmentPage/types/api/apartmentPhotoAPI"
import { postImage } from "./data"

const imageHandlers = [
  rest.post<PatchImageParams, PatchImageParams | Error>(
    `${process.env.REACT_APP_API_URL}/apartment_image`,
    (req, resp, ctx) => {
      const data = ctx.json(postImage)
      return resp(data)
    }
  ),
  rest.patch<PatchImageParams, PatchImageParams | Error>(
    `${process.env.REACT_APP_API_URL}/apartment_image/:id`,
    (req, resp, ctx) => {
      if (req.body.id && req.body.apartment_id) {
        const data = ctx.json(postImage)
        return resp(data)
      }
      return resp(ctx.status(400))
    }
  ),
  rest.delete<PatchImageParams | Error>(
    `${process.env.REACT_APP_API_URL}/apartment_image/:id`,
    (req, resp, ctx) => {
      const data = ctx.json(postImage)
      return resp(data)
    }
  ),
]

export default imageHandlers
