import { theme } from "kui-basic"

const { palette } = theme

export const StatusBilletVariants = {
  warning: {
    background: palette.brand.light,
    color: palette.brand.main,
  },
  error: {
    background: palette.red.ten,
    color: palette.red.seventy,
  },
  success: {
    background: palette.green.ten,
    color: palette.green.seventy,
  },
  default: {
    background: palette.background.light3,
    color: palette.grey.fiftyP,
  },
}
