import { makeAutoObservable, runInAction } from "mobx"
import { DateTime } from "luxon"
import to from "await-to-js"
import { Loader, resHandler } from "kui-utils"
import ApartmentPageStore from "../../../../store/ApartmentPageStore"
import { GetChartPricesResponse } from "../../../../types/api/apartmentChartAPI"
import ApartmentChartAgent from "../../../../../../agent/ApartmentChart"
import { PromotionPredictorFormFields } from "../../forms/PromotionPredictorForm/types"

class PromotionPredictorStore {
  predictedPrice: number | null = null

  apartmentStore: ApartmentPageStore

  loader: Loader

  constructor(apartmentStore: ApartmentPageStore) {
    this.apartmentStore = apartmentStore
    this.loader = new Loader(true)
    makeAutoObservable(this)
  }

  predictPrice = async () => {
    const apartmentId = this.apartmentStore.overviewStore.id
    if (apartmentId) {
      this.loader.startLoading("predict price")
      const currentDate = DateTime.now()
      const startDate = currentDate.minus({ month: 1 }).toISODate()!
      const endDate = currentDate.toISODate()!

      const result = await to<GetChartPricesResponse>(
        ApartmentChartAgent.getHistoryCosts(apartmentId, startDate, endDate)
      )

      runInAction(() => {
        resHandler(result, this.loader, (res) => {
          this.predictedPrice = res[0]?.price || null
        })
      })
    }
  }

  updateApartmentInfo = async (data: PromotionPredictorFormFields) => {
    const apartmentId = this.apartmentStore.overviewStore.id

    if (apartmentId) {
      this.loader.startLoading("apartment changes")

      const metroStations =
        this.apartmentStore.descriptionStore.fields?.metroStations.slice(1) ||
        []

      await this.apartmentStore.patchApartment(apartmentId, {
        ...data,
        metroStations: [data.metroStation, ...metroStations],
      })
      await this.predictPrice()
    }
  }

  get metroStation() {
    return this.apartmentStore.descriptionStore.fields?.metroStations[0]
  }
}

export default PromotionPredictorStore
