import React from "react"
import { Box, Caption } from "kui-basic"
import { NextArrowIcon, CheckIcon } from "kui-icon"
import { InputWithController } from "kui-complex"
import styled from "@emotion/styled"
import { UploadFilesWithLabelProps } from "./types"
import UploadFilesForm from "../../ui/UploadFilesForm"

const UploadFilesWithLabel = <T extends object>(
  props: UploadFilesWithLabelProps<T>
) => {
  const {
    form,
    label,
    description,
    onPrevClick,
    withNext,
    withComment,
    name,
    footerProps,
    ...other
  } = props
  return (
    <>
      <Box mb={2} pr={5} pl={5}>
        <Caption size="s" color="fiftyP">
          {description}
        </Caption>
      </Box>
      <StyledUploadFiles
        form={form}
        name={withComment ? `${name}.files` : name!}
        settings={
          withComment && (
            <InputWithController
              form={form}
              isTextArea
              label={label}
              name={`${name}.comment`}
            />
          )
        }
        onPrev={onPrevClick}
        submitTitle={withNext ? "Next" : "Create"}
        submitButtonProps={{
          endIcon: withNext ? <NextArrowIcon /> : <CheckIcon />,
        }}
        footerProps={footerProps}
        {...other}
      />
    </>
  )
}

export default UploadFilesWithLabel

const StyledUploadFiles = styled(UploadFilesForm)`
  padding-top: 0;
  .FileWrapper {
    margin-bottom: 16px;
    &:last-of-type {
      margin-bottom: 16px;
    }
  }
`
