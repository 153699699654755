import React from "react"
import { Grid } from "kui-basic"
import styled from "@emotion/styled"
import { Link, PreviewContent } from "kui-crm"
import TableRow from "../../../../components/ui/TableRow"
import TableCell from "../../../../components/ui/TableCell"
import { ApartmentRowProps } from "./types"
import { Routes } from "../../../../types/route"

import StatusBillet from "../../../../components/common/StatusBillet"
import UserBadge from "../../../../components/common/UserBadge"
import { PaymentStatusLabel } from "../../../../utils/content/values"

function ApartmentRow({ apartment, isDark }: ApartmentRowProps) {
  return (
    <TableRow isDark={isDark}>
      <TableCell isFirst textColor="gray">
        <Grid
          container
          justify="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          {apartment.id}
          {apartment.isImported && (
            <StyledStatusBillet variant="warning" label="i" />
          )}
        </Grid>
      </TableCell>
      <TableCell>{apartment.folderNumber || "-"}</TableCell>
      <TableCell>
        <Link href={`${Routes.apartments}/${apartment.id}`}>
          <PreviewContent
            imageSrc={apartment.previewImage}
            description={apartment.address}
          />
        </Link>
      </TableCell>
      <TableCell>{apartment.status}</TableCell>
      <TableCell>{apartment.formattedRentalType}</TableCell>
      <TableCell>
        {apartment.paymentStatus
          ? PaymentStatusLabel[apartment.paymentStatus]
          : "-"}
      </TableCell>
      <TableCell>{apartment.periodStatus}</TableCell>
      <TableCell width="15%">
        {apartment.landlord && (
          <UserBadge
            user={apartment.landlord}
            name={apartment.landlord.partialName}
            weight={500}
          />
        )}
      </TableCell>
      <TableCell isLast width="15%">
        <UserBadge
          user={apartment.tenant}
          name={apartment.tenant?.partialName}
          weight={500}
        />
      </TableCell>
    </TableRow>
  )
}

export default ApartmentRow

const StyledStatusBillet = styled(StatusBillet)`
  border-radius: 50%;
  margin-left: 4px;
  padding: 5px 10px;
  p {
    text-transform: lowercase;
  }
`
