import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { observer } from "mobx-react"
import { LoaderState } from "kui-crm"
import ErrorBoundary from "../ErrorBoundary"
import Page from "../Page"
import { EntityPageProps, EntityParams } from "./types"
import Loading from "../Loading"

function EntityPage({ store, initPage, children }: EntityPageProps) {
  const { loader, actionLoader } = store
  const { id } = useParams<EntityParams>()

  useEffect(() => {
    if (initPage) {
      initPage(Number(id))
    }
  }, [id])

  return (
    <ErrorBoundary>
      <Page withContainer={false}>
        {loader.isLoading ? <Loading /> : children}

        <LoaderState loader={actionLoader} />
        <LoaderState loader={loader} onlyError />
      </Page>
    </ErrorBoundary>
  )
}
export default observer(EntityPage)
