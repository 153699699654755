import React, { useState } from "react"
import { observer } from "mobx-react"
import { Box } from "kui-basic"
import { LoaderState } from "kui-crm"
import MeteringDevicesTable from "../MetersTable"
import CreatingElectricityMeterModal from "../CreatingElectricityMeterModal"
import useExpensesStore from "../../../../store"
import ElectricityMeterRow from "../ElectricityMeterRow"

function ApartmentElectricityMetersTable() {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const { metersStore } = useExpensesStore()
  const { electricityMetersStore } = metersStore
  const electricityMeters = electricityMetersStore?.activeElectricityMeters

  const handleCloseModal = () => {
    setIsOpenModal(false)
  }

  const handleOpenModal = () => {
    setIsOpenModal(true)
  }

  return (
    <>
      <Box mb={4}>
        <MeteringDevicesTable
          handleAdd={handleOpenModal}
          title="Electricity"
          metersCount={electricityMeters?.length}
          maxMetersCount={electricityMetersStore?.maxCountersCount}
          total={electricityMetersStore?.total}
        >
          {electricityMeters?.map((counter) => (
            <ElectricityMeterRow counter={counter} key={counter.id} />
          ))}
        </MeteringDevicesTable>
      </Box>
      <CreatingElectricityMeterModal
        open={isOpenModal}
        handleClose={handleCloseModal}
      />

      <LoaderState loader={electricityMetersStore.loader} onlyError />
      <LoaderState loader={electricityMetersStore.actionLoader} />
    </>
  )
}

export default observer(ApartmentElectricityMetersTable)
