import { DetailApartmentInfoStepFields } from "kui-crm_actions"
import { LinkField } from "kui-crm"
import { ImpairmentsTypeValues } from "../../../../../../../utils/content/listsOptions"
import { InputParams } from "../../../../../../common/InputsGroupWithTitle/types"

const impairmentInfoFields: InputParams<DetailApartmentInfoStepFields>[] = [
  {
    label: "Type",
    name: "type",
    variant: "select",
    options: ImpairmentsTypeValues,
    isRequired: true,
  },
  {
    label: "Description",
    name: "description",
    isTextArea: true,
    isRequired: true,
  },
  { label: "Solution", name: "solution", isTextArea: true, isRequired: true },
  { label: "Costings file", name: "file", variant: "file" },
  {
    label: "Price",
    name: "price",
    variant: "mask",
    mask: Number,
    thousandsSeparator: " ",
    isRequired: true,
  },
  {
    label: "Link",
    name: "link",
    variant: "custom",
    CustomInput: LinkField,
  },
]

export default impairmentInfoFields
