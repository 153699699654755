import React from "react"
import { observer } from "mobx-react"
import { useForm } from "react-hook-form"
import { Grid } from "kui-basic"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormWrapper, InputByType } from "kui-crm"
import {
  getWaterMeterFields,
  PartialMeterInfoFields,
  WaterMeterFieldsSchema,
} from "kui-crm_actions"
import ModalLabel from "../../../../../common/ModalLabel"
import useApartmentLiteStore from "../../../../../../store/shared/apartment"
import { WaterMeterInfoStepProps } from "./types"

const WaterMeterInfoStep = (props: WaterMeterInfoStepProps) => {
  const { index, withCheckValue } = props
  const { inspectionsStore } = useApartmentLiteStore()
  const formStore = inspectionsStore.creationForm
  const label = "Check and add water meter value"
  const notFilledMeters =
    formStore.fields?.meters?.filter(
      (meter: PartialMeterInfoFields) =>
        meter.resource === "water" && !meter.value
    ) || []
  const meter = formStore.fields?.meters?.[index]
  const fields = getWaterMeterFields(notFilledMeters, meter, withCheckValue)

  const form = useForm({
    values: meter,
    resolver: yupResolver(WaterMeterFieldsSchema),
  })

  const handleSubmit = (data: PartialMeterInfoFields) => {
    formStore.replaceFieldInArray(
      "meters",
      { ...data, resource: "water" },
      index
    )
    formStore.nextStep()
  }

  return (
    <div>
      <ModalLabel label={label} />
      <FormWrapper
        form={form}
        onSubmit={handleSubmit}
        withPrevStep
        handlePrevClick={formStore.prevStep}
        variant="next"
        label="Next"
      >
        <Grid container spacing={2}>
          {fields.map((field) => (
            <Grid item key={field.name} xs={12}>
              <InputByType form={form} {...field} />
            </Grid>
          ))}
        </Grid>
      </FormWrapper>
    </div>
  )
}

export default observer(WaterMeterInfoStep)
