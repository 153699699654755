import React from "react"
import { Caption, Grid } from "kui-basic"
import { Switch } from "kui-complex"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import SectionTitle from "../../../../../../../../../components/common/SectionTitle"
import { PromotionDescriptionHeaderProps } from "./types"
import usePromotionStore from "../../../../../store"

const PromotionDescriptionHeader = ({
  form,
}: PromotionDescriptionHeaderProps) => {
  const { editor } = usePromotionStore()

  return (
    <Grid container justify="space-between" alignItems="center">
      <SectionTitle title="Descriptions" />
      <Grid item>
        <Grid container alignItems="center">
          <StyledLabel size="xs">Show on main page</StyledLabel>
          <Switch
            disabled={!editor.isEditing}
            name="showOnTheMainPage"
            form={form}
            size="m"
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default observer(PromotionDescriptionHeader)

const StyledLabel = styled(Caption)`
  margin-right: 16px;
`
