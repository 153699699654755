import React from "react"
import { ApartmentInspectionParams } from "kui-crm_actions"
import { observer } from "mobx-react"
import { ApartmentInspectionsMapProps } from "./types"
import ObjectsMap from "../ObjectsMap"

const ApartmentInspectionsMap = (props: ApartmentInspectionsMapProps) => {
  const { inspections } = props

  const getLabel = (inspection: ApartmentInspectionParams) =>
    inspection.date?.toFormat("dd.MM.yyyy") ||
    `${Number(inspection.apartment.roomsCount)}-комн.`

  return <ObjectsMap objects={inspections} getLabel={getLabel} />
}

export default observer(ApartmentInspectionsMap)
