import React, { useState } from "react"
import { useToggle } from "kui-utils"
import AdaptiveImagesBlock from "../AdaptiveImagesBlock"
import UploadFilesModal from "../../modals/UploadFilesModal"
import ImagesSlider from "../ImagesSlider"
import { ControlledImagesBlockProps } from "./types"

function ControlledImagesBlock(props: ControlledImagesBlockProps) {
  const { images, onAdd, onDelete, name, disabled, ...other } = props
  const [isModalOpen, handleModalOpen, handleModalClose] = useToggle()
  const [isSliderOpen, handleSliderOpen, handleSliderClose] = useToggle()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const largeImages = images.map((image) => image.largeImageUrl)

  const handleClick = (index?: number) => {
    if (typeof index === "number") {
      setSelectedIndex(index)
    }
    handleSliderOpen()
  }

  return (
    <>
      <AdaptiveImagesBlock
        images={images}
        onAdd={onAdd && handleModalOpen}
        onDelete={!disabled && onDelete}
        onClick={handleClick}
        disabled={disabled}
        {...other}
      />
      {onAdd && (
        <UploadFilesModal
          handleClose={handleModalClose}
          open={isModalOpen}
          handleSubmit={onAdd}
          name={name}
          variant="image"
        />
      )}
      <ImagesSlider
        active={selectedIndex}
        images={largeImages}
        onDelete={!disabled && onDelete}
        isOpen={isSliderOpen}
        handleClose={handleSliderClose}
      />
    </>
  )
}

export default ControlledImagesBlock
