import React, {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  useEffect,
  useState,
} from "react"
import styled from "@emotion/styled"
import { Button, CircularProgress } from "kui-basic"
import { CheckIcon, EditIcon } from "kui-icon"
import { EditValueFieldProps, InputStylesProps } from "./types"

const EditValueField = forwardRef(
  (props: EditValueFieldProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { onSave, placeholder, disabled, isLoading, value, ...other } = props
    const [isEditing, setIsEditing] = useState(false)
    const [inputValue, setInputValue] = useState<any>(value)

    const toggleEditing = () => {
      if (isEditing) {
        onSave(inputValue)
      }
      setIsEditing((prev) => !prev)
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value ? Number(e.target.value) : ""
      setInputValue(newValue)
    }

    useEffect(() => {
      setInputValue(value)
    }, [value])

    return (
      <StyledWrapper>
        <StyledInput
          autoComplete="off"
          ref={ref}
          disabled={!isEditing}
          isActive={isEditing}
          placeholder={isEditing ? placeholder : ""}
          type="number"
          value={inputValue}
          onInput={handleChange}
          {...other}
        />
        <StyledButtonWrapper>
          {isLoading ? (
            <StyledCircularProgress />
          ) : (
            <StyledButton
              isCircle
              variant={isEditing ? "primary" : "gray"}
              onClick={toggleEditing}
              type={isEditing ? "submit" : "button"}
              data-testid="edit_btn"
              disabled={disabled}
            >
              {isEditing ? (
                <CheckIcon width={12} />
              ) : (
                <EditIcon width={14} height={14} />
              )}
            </StyledButton>
          )}
        </StyledButtonWrapper>
      </StyledWrapper>
    )
  }
)

export default EditValueField

const StyledInput = styled("input", {
  shouldForwardProp: (prop) => prop !== "isActive",
})<InputStylesProps>`
  background: ${({ theme, isActive }) =>
    isActive ? theme.palette.grey.zero : theme.palette.background.light1};
  border: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
  border-radius: 66px;
  height: 32px;
  width: 100%;
  padding: 7px 32px 7px 12px;
  transition: all ease-out 0.3s;
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  caret-color: ${({ theme }) => theme.palette.brand.main};
  color: ${({ theme }) => theme.palette.grey.seventy};
  -moz-appearance: textfield;
  &:-webkit-autofill {
    box-shadow: inset 0 0 0 1000px #fff;
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.palette.brand.main};
  }
  &:focus-visible {
    outline: none;
  }
`

const StyledWrapper = styled.div`
  position: relative;
  width: 114px;
`

const StyledButtonWrapper = styled.div`
  position: absolute;
  top: calc(50% - 12px);
  right: 4.5px;
`

const StyledButton = styled(Button)`
  width: 24px;
  height: 24px;
`

const StyledCircularProgress = styled(CircularProgress)`
  width: 24px !important;
  height: 24px !important;
`
