import React from "react"
import { useToggle } from "kui-utils"
import { Button, Grid } from "kui-basic"
import { UpdateIcon } from "kui-icon"
import { observer } from "mobx-react"
import { ContractClosingButtonsProps } from "./types"
import PopupWithCheckList from "../PopupWithCheckList"
import ContractCheckoutDateModal from "../../modals/ContractCheckoutDateModal"
import ContractClosingModal from "../../modals/ContractClosingModal"

const ContractClosingButtons = (props: ContractClosingButtonsProps) => {
  const {
    closeContract,
    setCheckoutDate,
    defaultDate,
    closingProgress,
    canBeClosed,
    label,
  } = props
  const [isCheckOutDateOpen, openCheckOutDate, closeCheckOutDate] = useToggle()
  const [isClosingModalOpen, openClosingModal, closeClosingModal] = useToggle()

  return (
    <>
      <Grid item>
        <Grid container spacing={3}>
          <Grid item>
            <Button
              onClick={openCheckOutDate}
              variant="orange"
              size="xs"
              endIcon={defaultDate ? <UpdateIcon /> : undefined}
            >
              {defaultDate
                ? defaultDate.toFormat("dd.MM.yyyy")
                : `Select ${label} date`}
            </Button>
          </Grid>
          <Grid item>
            <PopupWithCheckList
              label="To close a contract you need:"
              items={closingProgress}
              popupActive={!canBeClosed}
              button={
                <Button
                  onClick={openClosingModal}
                  size="xs"
                  disabled={!canBeClosed}
                >
                  Close contract
                </Button>
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <ContractCheckoutDateModal
        open={isCheckOutDateOpen}
        handleClose={closeCheckOutDate}
        defaultDate={defaultDate}
        setCheckoutDate={setCheckoutDate}
        label={label}
      />
      <ContractClosingModal
        open={isClosingModalOpen}
        handleClose={closeClosingModal}
        closeContract={closeContract}
      />
    </>
  )
}

export default observer(ContractClosingButtons)
