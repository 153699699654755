import React from "react"
import { useForm } from "react-hook-form"
import { Grid } from "kui-basic"
import { observer } from "mobx-react"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormWrapper, InputByType } from "kui-crm"
import {
  getDetailApartmentInfoFields,
  DetailApartmentInfoStepSchema,
  DetailApartmentInfoStepFields,
} from "kui-crm_actions"
import useApartmentLiteStore from "../../../../../../../store/shared/apartment"

const DetailApartmentInfoStep = () => {
  const { inspectionsStore, descriptionStore } = useApartmentLiteStore()
  const formStore = inspectionsStore.creationForm
  const form = useForm<DetailApartmentInfoStepFields>({
    defaultValues: {
      ...descriptionStore.fields,
      ...formStore.fields,
    },
    resolver: yupResolver(DetailApartmentInfoStepSchema),
  })

  const fields = getDetailApartmentInfoFields(
    formStore.fields?.roomsNumber,
    formStore.fields?.bedroomsNumber
  )

  const handleSubmit = (data: DetailApartmentInfoStepFields) => {
    formStore.updateFormFields(data)
    formStore.nextStep()
  }

  return (
    <FormWrapper
      onSubmit={form.handleSubmit(handleSubmit)}
      form={form}
      withPrevStep
      handlePrevClick={formStore.prevStep}
      variant="next"
      label="Next"
    >
      <Grid container spacing={2}>
        {fields.map((field) => (
          <Grid item xs={6} key={field.name}>
            <InputByType form={form} {...field} />
          </Grid>
        ))}
      </Grid>
    </FormWrapper>
  )
}

export default observer(DetailApartmentInfoStep)
