import { ChangeEvent } from "react"
import { DateTime } from "luxon"
import { RadioGroupWithLabel, InputMultiSelect } from "kui-complex"
import {
  PeriodValues,
  RentPeriodTypeValues,
} from "../../../../utils/content/listsOptions"
import { InputParams } from "../../../../components/common/InputsGroupWithTitle/types"
import { ContractPeriodTypes } from "../../../../types/common"
import CheckboxWithController from "../../../../components/ui/CheckboxWithController"
import { ServiceContractBasicInfoFields } from "../CreateServiceContractForm/types"
import ClientAutocomplete from "../../../../components/entityInputs/ClientAutocomplete"
import { ClientLiteParams } from "../../../../types/store/user"

const getSCBasicInfoFields = (
  contractType: ContractPeriodTypes | undefined,
  handleContractTypeChange: (e: ChangeEvent<HTMLInputElement>) => void,
  clients?: ClientLiteParams[]
): InputParams<ServiceContractBasicInfoFields>[] => [
  { label: "Number of contract", name: "number", maxLength: 15, size: 6 },
  { label: "Folder number", name: "folderNumber", size: 6 },
  Number(clients?.length) <= 1
    ? {
        label: "Landlord",
        name: "owner.name",
        size: 12,
        disabled: true,
      }
    : {
        label: "Landlord",
        name: "owner",
        variant: "custom",
        CustomInput: ClientAutocomplete,
        size: 12,
        options: clients,
      },
  {
    label: "Long term type",
    name: "rentPeriodType",
    variant: "custom",
    CustomInput: InputMultiSelect,
    options: RentPeriodTypeValues,
    size: 12,
  },
  {
    label: "Sign date",
    name: "signDate",
    variant: "date",
    max: DateTime.now(),
  },
  { label: "Start date", name: "startDate", variant: "date" },
  {
    title: "Contract type",
    name: "contractType",
    variant: "custom",
    CustomInput: RadioGroupWithLabel,
    options: PeriodValues,
    value: contractType,
    onChange: handleContractTypeChange,
    size: 12,
  },
  ...(contractType === "fixed"
    ? [
        { label: "End date", name: "endDate", variant: "date" },
        {
          label: "Auto prolongation",
          name: "autoProlongate",
          variant: "custom",
          CustomInput: CheckboxWithController,
        },
      ]
    : []),
]

export default getSCBasicInfoFields
