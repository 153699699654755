import { makeAutoObservable } from "mobx"
import { Loader } from "kui-utils"
import ApartmentChartStore from "./chart/ApartmentChart"
import ApartmentPageStore from "../../../../store/ApartmentPageStore"
import PromotionChannelsContentStore from "./PromotionChannelsContentStore"
import PromotionPageStore from "../PromotionPageStore"

class PromotionChannelsStore {
  loader: Loader

  chartStore: ApartmentChartStore

  contentStore: PromotionChannelsContentStore

  apartmentStore: ApartmentPageStore

  constructor(
    apartmentStore: ApartmentPageStore,
    promotionStore: PromotionPageStore
  ) {
    this.loader = new Loader(true)
    this.chartStore = new ApartmentChartStore(promotionStore)
    this.contentStore = new PromotionChannelsContentStore(apartmentStore)
    this.apartmentStore = apartmentStore
    makeAutoObservable(this)
  }

  initChannelsTab = async () => {
    const apartmentId = this.apartmentStore.overviewStore.id
    if (apartmentId) {
      this.loader.startLoading("promotion channels")

      await this.contentStore.fetchChannelsContent()

      this.loader.endLoading()
    }
  }
}

export default PromotionChannelsStore
