import { makeAutoObservable, runInAction } from "mobx"
import { Loader, resHandler } from "kui-utils"
import to from "await-to-js"
import DashboardPageStore from "../DashboardPageStore"
import { UserRole } from "../../../../types/api/cabinet"
import {
  SalesManagerDashboardCounts,
  SalesManagerDashboardSettings,
} from "../../types/store/salesManagerDashboard"
import ObjectsWithoutAppraisalStore from "../entities/ObjectsWithoutAppraisalStore"
import ContractsIncreasedRentStore from "../entities/ContractsIncreasedRentStore"
import { callPromisesBySettings } from "../../../../utils/service/mapper"
import { DashboardSettingsModel } from "../../../../types/api/dashboard"
import DashboardAgent from "../../../../agent/Dashboard"
import NewObjectsListStore from "../entities/NewObjectsListStore"
import { WorkIndicatorType } from "../../types/api/common"
import RejectedAppraisalsStore from "../entities/RejectedAppraisalsStore"

const countTypes = {
  increasedRentPrice: "contracts_increased_rent_price_change",
  increasedRentPercent: "contracts_increased_rent_price_change_percent",
  increasedRentObjects: "contracts_increased_rent",
  newObjectsCount: "first_rental_contracts",
  newObjectsCommission: "first_rental_contracts_agent_fees",
  newObjectsRent: "first_rental_contracts_month_payment",
}

class SalesManagerDashboardStore {
  increasedRentObjects: number | null

  increasedRentPercent: number | null

  increasedRentPrice: number | null

  newObjectsCount: number | null

  newObjectsCommission: number | null

  newObjectsRent: number | null

  objectsWithoutAppraisal: ObjectsWithoutAppraisalStore

  contractsIncreasedRent: ContractsIncreasedRentStore

  newObjectsList: NewObjectsListStore

  rejectedAppraisals: RejectedAppraisalsStore

  settings: SalesManagerDashboardSettings | null

  loader: Loader

  actionLoader: Loader

  dashboardStore: DashboardPageStore

  role: UserRole

  constructor(dashboardStore: DashboardPageStore) {
    this.dashboardStore = dashboardStore
    this.increasedRentObjects = null
    this.increasedRentPercent = null
    this.increasedRentPrice = null
    this.newObjectsCount = null
    this.newObjectsCommission = null
    this.newObjectsRent = null
    this.objectsWithoutAppraisal = new ObjectsWithoutAppraisalStore(
      dashboardStore
    )
    this.contractsIncreasedRent = new ContractsIncreasedRentStore(
      dashboardStore
    )
    this.newObjectsList = new NewObjectsListStore(dashboardStore)
    this.rejectedAppraisals = new RejectedAppraisalsStore(dashboardStore)
    this.settings = null
    this.loader = new Loader()
    this.actionLoader = new Loader()
    this.role = "sales_manager"
    makeAutoObservable(this)
  }

  initDashboard = async () => {
    this.loader.startLoading()

    await this.fetchSettings()
    await this.updateCards()

    this.loader.endLoading()
  }

  loadCards = async () => {
    this.actionLoader.startLoading()

    this.resetCards()
    await this.updateCards()

    this.actionLoader.endLoading()
  }

  updateCards = async () => {
    if (this.settings) {
      await callPromisesBySettings(this.settings, {
        increasedRentObjects: this.fetchIncreasedRentObjects,
        increasedRentPercent: this.fetchIncreasedRentPercent,
        increasedRentPrice: this.fetchIncreasedRentPrice,
        newObjectsCount: this.fetchNewObjectsCount,
        newObjectsCommission: this.fetchNewObjectsCommission,
        newObjectsRent: this.fetchNewObjectsRent,
        newObjectsList: this.newObjectsList.fetchObjects,
        objectsWithoutAppraisal: this.objectsWithoutAppraisal.fetchObjects,
        contractsIncreasedRent: this.contractsIncreasedRent.fetchContracts,
        rejectedAppraisals: this.rejectedAppraisals.fetchInspections,
      })
    }
  }

  fetchSettings = async () => {
    this.loader.startLoading()

    const response = await to<DashboardSettingsModel>(
      DashboardAgent.getSettings()
    )

    resHandler(response, this.loader, this.updateSettings)
  }

  editSettings = async (settings: SalesManagerDashboardSettings) => {
    this.loader.startLoading()

    const body = SalesManagerDashboardStore.getDashboardSettingsBody(settings)
    const response = await to(DashboardAgent.editSettings(body))

    resHandler(response, this.loader, this.updateSettings, "update settings", {
      withEndLoading: false,
    })

    this.resetCards()
    await this.updateCards()

    this.loader.endLoading()
  }

  updateSettings = (settings: DashboardSettingsModel) => {
    const { visible_blocks } = settings
    this.settings = {
      increasedRentObjects: !!visible_blocks.contracts_increased_rent_count,
      increasedRentPercent:
        !!visible_blocks.contracts_increased_rent_price_change_percent,
      increasedRentPrice:
        !!visible_blocks.contracts_increased_rent_price_change,
      objectsWithoutAppraisal: !!visible_blocks.objects_need_appraisal,
      contractsIncreasedRent: !!visible_blocks.contracts_increased_rent,
      newObjectsCount: !!visible_blocks.first_rental_contracts_count,
      newObjectsCommission: !!visible_blocks.first_rental_contracts_agent_fees,
      newObjectsRent: !!visible_blocks.first_rental_contracts_month_payment,
      newObjectsList: !!visible_blocks.first_rental_contracts,
      rejectedAppraisals: !!visible_blocks.rejected_appraisals,
    }
  }

  fetchDashboardCount = async (type: SalesManagerDashboardCounts) => {
    const response = await to(
      DashboardAgent.getWorkIndicators(
        countTypes[type] as WorkIndicatorType,
        this.dashboardStore.periodFilter
      )
    )

    runInAction(() => {
      resHandler(response, this.loader, (res) => {
        this[type] = Number(res.count)
      })
    })
  }

  fetchIncreasedRentObjects = async () =>
    this.fetchDashboardCount("increasedRentObjects")

  fetchIncreasedRentPercent = async () =>
    this.fetchDashboardCount("increasedRentPercent")

  fetchIncreasedRentPrice = async () =>
    this.fetchDashboardCount("increasedRentPrice")

  fetchNewObjectsCount = async () => this.fetchDashboardCount("newObjectsCount")

  fetchNewObjectsCommission = async () =>
    this.fetchDashboardCount("newObjectsCommission")

  fetchNewObjectsRent = async () => this.fetchDashboardCount("newObjectsRent")

  resetSettings = () => {
    this.settings = null
  }

  resetCards = () => {
    this.increasedRentObjects = null
    this.increasedRentPercent = null
    this.increasedRentPrice = null
    this.newObjectsCount = null
    this.newObjectsCommission = null
    this.newObjectsRent = null
    this.objectsWithoutAppraisal = new ObjectsWithoutAppraisalStore(
      this.dashboardStore
    )
    this.contractsIncreasedRent = new ContractsIncreasedRentStore(
      this.dashboardStore
    )
    this.newObjectsList = new NewObjectsListStore(this.dashboardStore)
    this.rejectedAppraisals = new RejectedAppraisalsStore(this.dashboardStore)
  }

  resetDashboard = () => {
    this.resetSettings()
    this.resetCards()
  }

  static getDashboardSettingsBody = (
    settings: SalesManagerDashboardSettings
  ): DashboardSettingsModel => ({
    visible_blocks: {
      contracts_increased_rent_count: settings.increasedRentObjects,
      contracts_increased_rent_price_change_percent:
        settings.increasedRentPercent,
      contracts_increased_rent_price_change: settings.increasedRentPrice,
      objects_need_appraisal: settings.objectsWithoutAppraisal,
      contracts_increased_rent: settings.contractsIncreasedRent,
      first_rental_contracts_count: settings.newObjectsCount,
      first_rental_contracts_agent_fees: settings.newObjectsCommission,
      first_rental_contracts_month_payment: settings.newObjectsRent,
      first_rental_contracts: settings.newObjectsList,
      rejected_appraisals: settings.rejectedAppraisals,
    },
  })
}

export default SalesManagerDashboardStore
