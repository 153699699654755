import React from "react"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import { Link, PreviewContent } from "kui-crm"
import { TariffObjectRowProps } from "./types"
import TableCell from "../../../../components/ui/TableCell"
import TableRow from "../../../../components/ui/TableRow"
import { Routes } from "../../../../types/route"
import TextOverflowWithCommentAndFile from "../../../../components/common/TextOverflowWithCommentAndFile"

const defaultObjectURL = "/images/LivingAreaSmall.svg"

function TariffObjectRow({ object, isDark }: TariffObjectRowProps) {
  return (
    <TableRow isDark={isDark}>
      <TableCell isFirst textColor="gray">
        {object.id}
      </TableCell>
      <TableCell>{object.folderNumber}</TableCell>
      <TableCell isLast>
        <StyledPreviewContent
          imageSrc={object.logo || defaultObjectURL}
          content={
            <TextOverflowWithCommentAndFile
              comment={object.reason?.comment}
              file={object.reason?.file}
            >
              <Link href={`${Routes.apartments}/${object.id}`}>
                {object.address}
              </Link>
            </TextOverflowWithCommentAndFile>
          }
        />
      </TableCell>
    </TableRow>
  )
}

export default observer(TariffObjectRow)

const StyledPreviewContent = styled(PreviewContent)`
  p {
    color: ${({ theme }) => theme.palette.grey.seventy};
    font-weight: 500;
  }
`
