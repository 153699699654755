import { InputFile } from "kui-complex"
import { InputParams } from "../../../../../../common/InputsGroupWithTitle/types"
import { ComparableObjectFormFields } from "./types"

const comparableObjectFields: InputParams<ComparableObjectFormFields>[] = [
  { label: "Link to advertisement", name: "link", isRequired: true },
  {
    label: "Advertisement screenshot",
    name: "screenshot",
    variant: "custom",
    CustomInput: InputFile,
    isRequired: true,
  },
  {
    label: "Object price",
    name: "price",
    variant: "mask",
    mask: Number,
    thousandsSeparator: " ",
    isRequired: true,
  },
  {
    label: "Key object differences",
    name: "differences",
    isTextArea: true,
    isRequired: true,
  },
]

export default comparableObjectFields
