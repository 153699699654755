import React, { useEffect } from "react"
import { Box } from "kui-basic"
import { observer } from "mobx-react"
import getProxyFieldsGroup from "./fields"
import { ApartmentProxyFieldsProps } from "./types"
import useEditor from "../../../../../../hooks/useEditor"
import useApartmentStore from "../../../../store"
import UpdatedFieldsGroup from "../../../../../../components/common/UpdatedFieldsGroup"

function ApartmentProxyFields(props: ApartmentProxyFieldsProps) {
  const { index, remove, form } = props
  const { editor } = useApartmentStore()
  const proxyEditor = useEditor()

  const proxyFieldsGroup = getProxyFieldsGroup(index)

  const removeProxy = () => remove(index)

  useEffect(() => {
    if (!editor.isEditing) {
      proxyEditor.endEditing()
    }
  }, [editor.isEditing])

  useEffect(() => {
    const member = form.getValues(`proxies.${index}.member.id`)
    if (!member) proxyEditor.startEditing()
  }, [])

  return (
    <Box mb={3}>
      <UpdatedFieldsGroup
        form={form}
        handleRemove={removeProxy}
        disabled={!editor.isEditing}
        editor={proxyEditor}
        name={`proxies.${index}`}
        {...proxyFieldsGroup}
      />
    </Box>
  )
}

export default observer(ApartmentProxyFields)
