const scHeadCellsParams = [
  { id: "id", label: "ID", width: "5%" },
  { id: "number", label: "Number", width: "11%" },
  { id: "address", label: "Object", width: "24%" },
  { id: "rental_status", label: "Status", width: "7%" },
  { id: "rental_type", label: "Type", width: "7%" },
  { id: "agent_report_payment_status", label: "Paid", width: "4%" },
  { id: "owner", label: "Landlord", width: "21%" },
  { id: "renter", label: "Tenant", width: "21%" },
]

export default scHeadCellsParams
