import React from "react"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import { Button, Grid } from "kui-basic"
import { TrashIcon, OpenEyeIcon } from "kui-icon"
import { ApartmentPhotoModalFooterProps } from "./types"
import usePromotionStore from "../../../../../store"

function ApartmentPhotoModalFooter(props: ApartmentPhotoModalFooterProps) {
  const { handleClose, variant, hasError } = props
  const {
    contentStore: { imagesStore },
  } = usePromotionStore()
  const disabled = imagesStore.selectedImages.length === 0

  const handleDelete = async () => {
    handleClose()
    if (variant === "preview") {
      await imagesStore.deleteSelectedImagesFromPublic()
    } else {
      await imagesStore.deleteSelectedImages()
    }
  }

  const handleClick = async () => {
    handleClose()
    if (variant === "public") {
      await imagesStore.makeSelectedImagesPublic()
    } else {
      await imagesStore.makeSelectedImagesPreview()
    }
  }

  return (
    <StyledFooter>
      <Grid container justify="flex-end" alignItems="center">
        <StyledDeleteButton
          data-testid="delete_image"
          onClick={handleDelete}
          size="s"
          variant="whiteWithGray"
          endIcon={<TrashIcon />}
          disabled={disabled}
        >
          Delete
        </StyledDeleteButton>
        <Button
          size="s"
          endIcon={<OpenEyeIcon />}
          onClick={handleClick}
          disabled={disabled || hasError}
        >
          {variant === "public" ? "Make it public" : "Make it preview"}
        </Button>
      </Grid>
    </StyledFooter>
  )
}

export default observer(ApartmentPhotoModalFooter)

const StyledFooter = styled.div`
  position: sticky;
  padding: 12px 40px;
  bottom: 0;
  background: ${({ theme }) => theme.palette.grey.zero};
  border-top: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
`

const StyledDeleteButton = styled(Button)`
  margin-right: 24px;
`
