import { RefObject, useEffect, useState } from "react"

const usePortalPosition = (portalParentRef: RefObject<HTMLElement>) => {
  const [coords, setCoords] = useState<DOMRect | null>(null)

  useEffect(() => {
    if (!portalParentRef.current) {
      return
    }

    const updateCoords = () => {
      if (portalParentRef.current) {
        const newCoords = portalParentRef.current!.getBoundingClientRect()

        setCoords({
          ...newCoords,
          right: document.documentElement.clientWidth - newCoords.right,
          top: newCoords.top + window.pageYOffset,
        })
      }
    }

    updateCoords()

    window.addEventListener("calcPortalPosition", updateCoords)
    // eslint-disable-next-line consistent-return
    return () => window.removeEventListener("calcPortalPosition", updateCoords)
  }, [portalParentRef])

  return coords
}

export default usePortalPosition
