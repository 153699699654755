import { makeAutoObservable } from "mobx"
import { BillLineModel } from "../../types/api/expensesSummaryAPI"

class PayoutLineStore {
  id: number | null

  title: string

  value: number | null

  invoice: string | null

  paymentOrder: string | null

  constructor(payoutLine: BillLineModel) {
    this.id = payoutLine.id ?? null
    this.title = payoutLine.name ?? ""
    this.value = payoutLine.amount ? Number(payoutLine.amount) : null
    this.invoice = payoutLine.invoice?.attachment ?? null
    this.paymentOrder = payoutLine.payment_order?.attachment ?? null
    makeAutoObservable(this)
  }
}

export default PayoutLineStore
