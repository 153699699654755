import React from "react"
import { ReactDatePickerCustomHeaderProps } from "react-datepicker"
import { Grid, IconButton } from "kui-basic"
import { PrevArrowIcon, NextArrowIcon } from "kui-icon"
import styled from "@emotion/styled"

const CalendarHeaderControllers = (props: ReactDatePickerCustomHeaderProps) => {
  const {
    increaseMonth,
    decreaseMonth,
    nextMonthButtonDisabled,
    prevMonthButtonDisabled,
  } = props

  return (
    <StyledWrapper container alignItems="center" wrap="nowrap">
      <IconButton disabled={prevMonthButtonDisabled} onClick={decreaseMonth}>
        <PrevArrowIcon width={16} height={16} />
      </IconButton>
      <IconButton disabled={nextMonthButtonDisabled} onClick={increaseMonth}>
        <NextArrowIcon width={16} height={16} />
      </IconButton>
    </StyledWrapper>
  )
}

export default CalendarHeaderControllers

const StyledWrapper = styled(Grid)`
  width: fit-content;
  path {
    fill: ${({ theme }) => theme.palette.grey.fiftyP};
  }
  button:first-of-type {
    margin-right: 16px;
  }
`
