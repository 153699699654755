import { DateTime } from "luxon"
import { callPromises } from "kui-utils"
import { makeAutoObservable } from "mobx"
import { FileBodyRequest, ImageBodyRequest } from "kui-crm"
import {
  ImpairmentModel,
  ImpairmentsInspectionModel,
} from "../../types/api/impairmentsInspection"
import { InspectionBasedOnParams } from "../../../../types/store/apartmentInspections"
import {
  ImpairmentsInspectionFormFields,
  ImpairmentsInspectionInfoFields,
} from "../../variants/ImpairmentsInspectionInfo/types"
import InspectionPageStore from "../InspectionPageStore"
import { ImpairmentParams } from "../../types/store/impairmentsInspection"
import FileStore from "../../../../../../store/templates/File"
import ImageStore from "../../../../../../store/templates/ImageStore"
import ApartmentInspectionsLiteStore from "../../../../../../store/lites/ApartmentInspectionsLiteStore"

class ImpairmentsInspectionStore {
  id: number

  date: DateTime | null

  basedOn: InspectionBasedOnParams | null

  dropboxLink: string

  faq: string

  impairments: ImpairmentParams[]

  inspectionStore: InspectionPageStore

  constructor(
    inspection: ImpairmentsInspectionModel,
    inspectionStore: InspectionPageStore
  ) {
    this.id = inspection.id
    this.date = inspection.date ? DateTime.fromISO(inspection.date) : null
    this.basedOn = InspectionPageStore.getBasedOnInspection(inspection.based_on)
    this.faq = ""
    this.impairments =
      inspection.impairments.map((impairment) =>
        ImpairmentsInspectionStore.getImpairmentParams(impairment)
      ) || []
    this.inspectionStore = inspectionStore
    this.dropboxLink = inspection.dropbox_link || ""
    makeAutoObservable(this)
  }

  patchInspection = async (data: ImpairmentsInspectionInfoFields) => {
    const impairment = {
      ...data,
      impairments: [...data.damages, ...data.wear],
    }
    const impairmentsFiles = impairment.impairments.map((item) => item.file)
    const impairmentsImages = impairment.impairments.map((item) => item.images)

    const files = await callPromises([
      this.inspectionStore.actualizeFiles(impairmentsFiles),
      this.inspectionStore.actualizeImages(impairmentsImages),
    ])
    return ImpairmentsInspectionStore.getPatchInspectionBody(
      impairment,
      files[0],
      files[1]
    )
  }

  get damages() {
    return this.impairments.filter(
      (impairment) => impairment.type === "damages"
    )
  }

  get wear() {
    return this.impairments.filter(
      (impairment) => impairment.type === "wear_tear"
    )
  }

  static getPatchInspectionBody = (
    inspection: ImpairmentsInspectionFormFields,
    files: FileBodyRequest[],
    images: ImageBodyRequest[][]
  ) => ({
    dropbox_link: inspection.dropboxLink,
    faq: inspection.faq,
    impairments: ApartmentInspectionsLiteStore.getImpairmentsInspectionFields(
      inspection,
      files,
      images
    ),
  })

  static getImpairmentParams = (impairment: ImpairmentModel) => ({
    id: impairment.id,
    type: impairment.impairment_type,
    file: impairment.proof_file
      ? FileStore.initFromDocumentModel(impairment.proof_file)
      : null,
    price: impairment.price,
    solution: impairment.solution,
    description: impairment.description,
    images:
      impairment.photos?.map((image) => ImageStore.initFromImageModel(image)) ||
      [],
    link: impairment.item_link,
  })
}

export default ImpairmentsInspectionStore
