import React from "react"
import { Button, Grid } from "kui-basic"
import { useToggle } from "kui-utils"
import { SettingsIcon } from "kui-icon"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { DashboardHeaderProps } from "../DashboardHeader/types"
import { DashboardSettingsStylesProps } from "./types"

const DashboardSettings = (props: DashboardHeaderProps) => {
  const { renderModal, settings } = props
  const [isModalOpen, open, close] = useToggle()
  const withDefaultSettings = settings
    ? !Object.keys(settings).filter(
        (key) => !settings[key as keyof typeof settings]
      ).length
    : true

  return (
    <StyledWrapper item container alignItems="center">
      <StyledButton
        withDefaultSettings={withDefaultSettings}
        size="xs"
        isCircle
        variant="whiteWithGray"
        onClick={open}
      >
        <SettingsIcon />
      </StyledButton>

      {renderModal({ open: isModalOpen, handleClose: close })}
    </StyledWrapper>
  )
}

export default DashboardSettings

const StyledWrapper = styled(Grid)`
  width: fit-content;
`

const withIndicator = ({
  withDefaultSettings,
  theme,
}: DashboardSettingsStylesProps) =>
  !withDefaultSettings &&
  css`
    &::after {
      content: "";
      position: absolute;
      top: 0px;
      right: 0px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      display: block;
      background: ${theme?.palette.brand.main};
    }
  `

const StyledButton = styled(Button)<DashboardSettingsStylesProps>`
  border-radius: 8px;
  position: relative;
  ${withIndicator};
`
