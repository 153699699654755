export const conditionsFields = [
  {
    label: "Rental price",
    name: "rentalPrice",
    cornerLabel: "₽",
    variant: "mask",
    mask: Number,
    thousandsSeparator: " ",
  },
  {
    label: "Initial commission",
    name: "initialCommission",
    type: "number",
    cornerLabel: "%",
  },
  {
    label: "Security payment",
    name: "securityPayment",
    type: "number",
    cornerLabel: "%",
  },
  {
    label: "With delay",
    name: "installment",
    type: "number",
    cornerLabel: "month",
  },
  { label: "Payment date", name: "paymentDate", type: "number", size: 12 },
]

export const terminationFields = [
  {
    label: "Showing duration",
    name: "showing",
    type: "number",
    cornerLabel: "days",
  },
  { label: "Penalty", name: "penalty", type: "number", cornerLabel: "%" },
]
