import React from "react"
import { observer } from "mobx-react"
import {
  ChangeReasonFormFields,
  MeterFeaturesForm,
  MeterFeaturesFormFields,
  ElectricityMeterTypes,
  WaterMeterTypes,
} from "kui-crm"
import { MeterCreationFormFields, MeterCreationFormProps } from "./types"
import MeterApartmentForm from "./MeterApartmentForm"
import ChangingMeterReasonForm from "../ChangingMeterReasonForm"
import useResetMultistepForm from "../../../../hooks/useResetMultistepForm"

function MeterCreationForm(props: MeterCreationFormProps) {
  const {
    handleClose,
    onCreate,
    resource,
    createMeter,
    formStore,
    metersStore,
  } = props
  const defaultResource = resource || "water"
  const { replaceableMeters, fetchReplaceableMeters, apartmentResources } =
    metersStore
  const apartment = formStore.fields?.apartment
  const apartmentId = apartment?.id
  const maxMetersCount = apartment?.maxMetersCount[defaultResource]
  const hasPayer = apartment?.metersPayers[defaultResource]
  const operatingAccount = apartment?.operatingAccounts?.[defaultResource]
  const administrativeCompany = apartment?.administrativeCompany
  const isNewResource =
    !apartmentResources.includes(defaultResource) && !hasPayer

  const getReplaceableMeters = async (
    meterType: ElectricityMeterTypes | WaterMeterTypes | null
  ) => {
    if (apartmentId) {
      fetchReplaceableMeters(apartmentId, defaultResource, meterType)
    }
  }

  const handleSubmit = (
    data: ChangeReasonFormFields | MeterFeaturesFormFields
  ) => {
    const meterFields = {
      ...formStore.fields,
      ...data,
    } as MeterCreationFormFields

    handleClose()
    createMeter(meterFields)
    formStore.resetForm()
    if (onCreate) onCreate(meterFields)
  }

  useResetMultistepForm(formStore)

  switch (formStore.step) {
    case 2:
      return (
        <MeterFeaturesForm
          formStore={formStore}
          resource={defaultResource}
          handleMeterTypeChange={getReplaceableMeters}
          replacements={replaceableMeters}
          handleSubmit={handleSubmit}
          maxMeters={maxMetersCount}
          isNewResource={isNewResource}
          operatingAccount={operatingAccount}
          administrativeCompany={administrativeCompany}
          apartmentId={formStore.fields?.apartment?.id}
        />
      )
    case 3:
      return <ChangingMeterReasonForm handleSubmit={handleSubmit} />
    default:
      return (
        <MeterApartmentForm formStore={formStore} metersStore={metersStore} />
      )
  }
}

export default observer(MeterCreationForm)
