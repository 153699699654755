import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { Grid } from "kui-basic"
import { observer } from "mobx-react"
import PromotionChannelsContentFields from "./PromotionChannelsContentFields"
import { PromotionChannelsFormFields } from "./types"
import PromotionChannelsLinksFields from "./PromotionChannelsLinksFields"
import usePromotionStore from "../../store"

const PromotionChannelsForm = () => {
  const { channelsStore, editor } = usePromotionStore()
  const { contentStore } = channelsStore
  const form = useForm<PromotionChannelsFormFields>({
    defaultValues: {
      minPrice: contentStore.minPrice,
      maxPrice: contentStore.maxPrice,
      stepPrice: contentStore.stepPrice,
      stepFrequency: contentStore.stepFrequency,
      startAdvertisingDate: contentStore.startAdvertisingDate,
      endAdvertisingDate: contentStore.endAdvertisingDate,
      initialCommission: contentStore.initialCommission,
      securityPayment: contentStore.securityPayment,
      ...contentStore.propertyPortalsLinks,
    },
  })

  const handleSubmit = async (data: PromotionChannelsFormFields) => {
    editor.endEditing()
    await contentStore.patchPromotionChannels(data)
  }

  useEffect(() => {
    if (editor.isAllowedToSendForm) {
      form.handleSubmit(handleSubmit, editor.blockToSendForm)()
    }
  }, [editor.isAllowedToSendForm])

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)}>
      <Grid container spacing={9}>
        <PromotionChannelsContentFields
          form={form}
          disabled={!editor.isEditing}
        />
        <PromotionChannelsLinksFields
          form={form}
          disabled={!editor.isEditing}
        />
      </Grid>
    </form>
  )
}

export default observer(PromotionChannelsForm)
