import { DateTime } from "luxon"
import requests from "./index"

const getExpensesAttachmentsAgent = (filter: string) => ({
  getDocuments: (
    offset: number,
    limit: number,
    apartmentId: number,
    date: DateTime | null
  ) =>
    requests.get(
      `/apartments/${apartmentId}/expense-attachments?offset=${offset}&limit=${limit}${
        date ? `&year=${date.year}&month=${date.month}` : ""
      }${filter}`
    ),
  postDocument: (data: FormData, apartmentId: number) =>
    requests.post(`/apartments/${apartmentId}/expense-attachments`, data),
  patchDocument: (documentId: number, data: any, apartmentId: number) =>
    requests.patch(
      `/apartments/${apartmentId}/expense-attachments/${documentId}`,
      data
    ),
  deleteDocument: (documentId: number, apartmentId: number) =>
    requests.delete(
      `/apartments/${apartmentId}/expense-attachments/${documentId}`
    ),
})

const ClientAttachmentsAgent = getExpensesAttachmentsAgent("&is_visible=true")

const InternalAttachmentsAgent =
  getExpensesAttachmentsAgent("&is_visible=false")

export { ClientAttachmentsAgent, InternalAttachmentsAgent }
