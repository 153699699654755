/** @jsxImportSource @emotion/react */
import * as React from "react"
import styled from "@emotion/styled"
import { Caption, Box, Grid, Divider } from "kui-basic"
import { Avatar } from "kui-complex"
import { PhoneIcon, EmailIcon } from "kui-icon"
import { Link } from "kui-crm"
import TextWithStartIcon from "../TextWithStartIcon"
import { ApartmentSummaryPersonalDataProps, StylesLinkProps } from "./types"
import TextOverflow from "../TextOverflow"
import { Routes } from "../../../types/route"

function ClientPersonalDataCell({
  type,
  personalData,
  label,
  withLine,
  ...other
}: ApartmentSummaryPersonalDataProps) {
  return (
    <>
      <Box
        mt={2}
        mb={2}
        pr={type === "right" ? 0 : 3}
        pl={type === "right" ? 3 : 0}
        {...other}
      >
        <Grid
          container
          alignItems="center"
          direction={type === "right" ? "row-reverse" : "row"}
          spacing={2}
          wrap="nowrap"
        >
          <Grid item>
            <Link href={`${Routes.clients}/${personalData?.id}`}>
              <Avatar size="md" fullName={personalData?.fullName} />
            </Link>
          </Grid>

          <StyledContent item>
            <Grid container direction="column">
              <StyledLink
                type={type}
                href={`${Routes.clients}/${personalData?.id}`}
              >
                <TextOverflow size="l" weight={700}>
                  {personalData?.fullName}
                </TextOverflow>
              </StyledLink>

              <Grid
                container
                alignItems="center"
                justify="space-between"
                direction={type === "right" ? "row-reverse" : "row"}
              >
                <Caption size="s" weight={500} color="fiftyP">
                  {label}
                </Caption>
                <Grid item>
                  <Grid
                    container
                    spacing={2}
                    direction={type === "right" ? "row-reverse" : "row"}
                    wrap="nowrap"
                  >
                    <Grid item>
                      <TextWithStartIcon
                        icon={<EmailIcon width={14} height={12} />}
                        text={personalData?.email}
                      />
                    </Grid>
                    <Grid item>
                      <TextWithStartIcon
                        icon={<PhoneIcon width={12} height={15} />}
                        text={personalData?.phoneNumber}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </StyledContent>
        </Grid>
      </Box>
      {withLine && <Divider />}
    </>
  )
}

ClientPersonalDataCell.defaultProps = {
  withLine: true,
}

export default ClientPersonalDataCell

const StyledContent = styled(Grid)`
  width: 100%;
  max-width: calc(100% - 40px - 16px);
`

const StyledLink = styled(Link)<StylesLinkProps>`
  text-align: ${({ type }) => type};
`
