import { createContext, useContext } from "react"
import hostStore from "../../../store"
import ServiceContractPageStore from "./ServiceContractPageStore"

const serviceContractStore = new ServiceContractPageStore(hostStore)
const ServiceContractContext = createContext(serviceContractStore)

const useSCStore = () => useContext(ServiceContractContext)

export default useSCStore
