import { useLocation } from "react-router-dom"

const useQueryParam = (key: string) => {
  const location = useLocation()

  return new URLSearchParams(
    location.search || window.history.state?.route || document.location.search
  ).get(key)
}

export default useQueryParam
