import React from "react"
import { useFormContext } from "react-hook-form"
import { Button, Grid } from "kui-basic"
import styled from "@emotion/styled"
import { CheckIcon } from "kui-icon"
import { observer } from "mobx-react"
import { ChangeLineEditingButtonsProps } from "./types"

const ChangeLineEditingButtons = (props: ChangeLineEditingButtonsProps) => {
  const { contractId, changesGroup, contractEditor } = props
  const form = useFormContext()

  const handleEdit = (data: any) => {
    if (contractId) {
      changesGroup.editPendingChange(contractId, data)
      changesGroup.editor.endEditing()
      contractEditor?.endEditing()
      form.reset()
    }
  }

  const cancelEditing = () => {
    changesGroup.editor.endEditing()
    contractEditor?.endEditing()
    form.reset()
  }

  return (
    <StyledWrapper container wrap="nowrap">
      <StyledSaveButton
        onClick={form.handleSubmit(handleEdit)}
        endIcon={<CheckIcon />}
        data-testid="saveButton"
      >
        Save
      </StyledSaveButton>
      <StyledButton
        variant="whiteWithGray"
        onClick={cancelEditing}
        data-testid="cancelButton"
      >
        Cancel
      </StyledButton>
    </StyledWrapper>
  )
}

export default observer(ChangeLineEditingButtons)

const StyledWrapper = styled(Grid)`
  width: fit-content;
`

const StyledButton = styled(Button)`
  height: 24px;
`

const StyledSaveButton = styled(StyledButton)`
  margin-right: 8px;
`
