import React, { ChangeEvent, useEffect, useState } from "react"
import styled from "@emotion/styled"
import { Checkbox, Caption } from "kui-basic"
import { SettingCheckboxProps } from "./types"

function SettingCheckbox({
  title,
  className,
  checked: defaultChecked,
  handleChange,
  ...checkbox
}: SettingCheckboxProps) {
  const [checked, setChecked] = useState(!!defaultChecked)

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked
    setChecked(value)
    handleChange(value)
  }

  useEffect(() => {
    setChecked(!!defaultChecked)
  }, [defaultChecked])

  return (
    <StyledWrapper className={className}>
      <StyledCheckbox
        label={
          <StyledCaption size="s" weight={500} color="zero">
            {title}
          </StyledCaption>
        }
        checked={checked}
        onChange={onChange}
        {...checkbox}
      />
    </StyledWrapper>
  )
}

export default SettingCheckbox

const StyledWrapper = styled.div`
  background: ${({ theme }) => theme.palette.grey.seventy};
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 66px;
  height: 40px;
  min-width: 161px;
`

const StyledCheckbox = styled(Checkbox)`
  padding: 4px 6px;
  height: 100%;
  width: 100%;
  & .KUI-Checkbox_custom {
    border-radius: 50%;
    background: white;
    &:before {
      border-radius: 50%;
    }
  }
`

const StyledCaption = styled(Caption)`
  height: 100%;
  width: 100%;
`
