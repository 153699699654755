import React from "react"
import { useToggle } from "kui-utils"
import MenuButton from "../../../../../../components/common/MenuButton"
import { MeterReadingMenuCellProps } from "./types"
import MeterReadingModal from "../../../../../../components/modals/MeterReadingModal"

const MeterReadingMenuCell = ({ meter }: MeterReadingMenuCellProps) => {
  const [isEditModalOpen, handleEditModalOpen, handleEditModalClose] =
    useToggle()

  const menuOptions = [
    { label: "Edit", handleClick: handleEditModalOpen },
    { label: "Delete", handleClick: meter.deleteMeterReading },
  ]

  return (
    <>
      <MenuButton options={menuOptions} />

      <MeterReadingModal
        meter={meter}
        open={isEditModalOpen}
        handleClose={handleEditModalClose}
      />
    </>
  )
}

export default MeterReadingMenuCell
