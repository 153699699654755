import { makeAutoObservable } from "mobx"
import { DateTime } from "luxon"
import { ApartmentCostParams } from "../../../../../types/api/apartmentChartAPI"

class ApartmentChartTickStore {
  price?: number | null

  managerPrice?: number | null

  date: DateTime | null

  constructor(tick: ApartmentCostParams) {
    this.price = tick.price
    this.managerPrice = tick.managerPrice
    this.date = tick.time_model ? DateTime.fromISO(tick.time_model) : null
    makeAutoObservable(this)
  }

  setManagerPrice = (price?: number | null) => {
    this.managerPrice = price
  }

  get formattedDate() {
    return this.date?.toFormat("dd.MM.yy")
  }

  get formattedMonth() {
    return this.date?.toFormat("MMMM").toLowerCase()
  }

  get month() {
    return this.date?.month
  }
}

export default ApartmentChartTickStore
