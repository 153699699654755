import React, { useEffect } from "react"
import { Divider } from "kui-basic"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import { LoaderState } from "kui-crm"
import PromotionPredictorForm from "../../../forms/PromotionPredictorForm"
import AccentBlock from "../../../../../../../components/common/AccentBlock"
import PriceTerm from "../../../../../../../components/common/PriceTerm"
import Container from "../../../../../../../components/ui/Container"
import usePromotionStore from "../../../store"
import Loading from "../../../../../../../components/common/Loading"

const PromotionPredictorTab = () => {
  const { predictorStore } = usePromotionStore()

  useEffect(() => {
    predictorStore.predictPrice()
  }, [])

  if (predictorStore.loader.isLoading) {
    return <Loading />
  }

  return (
    <Container>
      <Divider />
      <StyledAccentBlock>
        <PriceTerm
          value={predictorStore.predictedPrice}
          label="Model’s estimated сost"
        />
      </StyledAccentBlock>

      <PromotionPredictorForm />

      <LoaderState loader={predictorStore.loader} />
    </Container>
  )
}

export default observer(PromotionPredictorTab)

const StyledAccentBlock = styled(AccentBlock)`
  margin: 16px 0 8px;
  max-width: 239px;
`
