import React from "react"
import { HomeIcon } from "kui-icon"
import { observer } from "mobx-react"
import { NewObjectsCardProps } from "./types"
import DashboardListCard from "../../common/DashboardListCard"
import NewObjectRow from "./NewObjectRow"

const NewObjectsCard = (props: NewObjectsCardProps) => {
  const { objectsStore } = props
  const { value, objects, loader, fetchObjects, paginator } = objectsStore

  return (
    <DashboardListCard
      value={value}
      label="New objects"
      icon={<HomeIcon />}
      loader={loader}
      paginator={paginator}
      fetchAll={fetchObjects}
      compareVariant="less"
    >
      {objects.map((object) => (
        <NewObjectRow key={object.id} object={object} />
      ))}
    </DashboardListCard>
  )
}

export default observer(NewObjectsCard)
