import * as yup from "yup"

export const StatisticalValuationFieldsSchema = {
  dataSourceName: yup.string().required("This field is required"),
  link: yup.string().required("This field is required"),
  screenshot: yup.mixed().required("This field is required"),
  minPrice: yup.string().required("This field is required"),
  maxPrice: yup.string().required("This field is required"),
}

const StatisticalValuationStepSchema = yup
  .object()
  .shape(StatisticalValuationFieldsSchema)

export default StatisticalValuationStepSchema
