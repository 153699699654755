import React from "react"
import styled from "@emotion/styled"
import { Grid } from "kui-basic"
import Container from "../../../../components/ui/Container"
import MeterEditButton from "../MeterEditButton"
import MeterVerificationButton from "../MeterVerificationButton"

const MeterSettings = () => (
  <StyledBackground>
    <Container>
      <Grid container justify="flex-end" spacing={2}>
        <MeterVerificationButton />
        <MeterEditButton />
      </Grid>
    </Container>
  </StyledBackground>
)

export default MeterSettings

const StyledBackground = styled.div`
  background: ${({ theme }) => theme.palette.background.light1};
  padding: 16px 0;
`
