export const descriptionPlaceholder =
  "Количество комнат, количество спален и санузлов, минимальный срок аренды, дата \n" +
  "возможного заселения\n" +
  "\n" +
  "Описание(свежесть ремонта и мебели) и состав (особенные преимущества - \n" +
  "функциональные комнаты, виды, необычное наполнение типа камина или джакузи, \n" +
  "наличие парковки)\n" +
  "\n" +
  "Место(описание района и жилого комплекса, близость инфраструктур и \n" +
  "общественного транспорта)\n" +
  "\n" +
  "Дополнительные условия\n" +
  "\n"
