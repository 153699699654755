import React from "react"
import styled from "@emotion/styled"
import { Heading, Box } from "kui-basic"
import { ApartmentGroupedAccrualsProps } from "./types"
import ExpensesAccrualsGroup from "../ExpensesAccrualsGroup"

function ExpensesGroupedAccruals({ accruals }: ApartmentGroupedAccrualsProps) {
  return accruals ? (
    <Box mt={3}>
      <StyledHeading size="h4">Accruals</StyledHeading>
      {accruals.utility.length > 0 && (
        <ExpensesAccrualsGroup title="Open-ended" lines={accruals.utility} />
      )}
      {accruals.fixed.length > 0 && (
        <ExpensesAccrualsGroup title="Period" lines={accruals.fixed} />
      )}
      {accruals.variable.length > 0 && (
        <ExpensesAccrualsGroup title="Once" lines={accruals.variable} />
      )}
    </Box>
  ) : null
}

export default ExpensesGroupedAccruals

const StyledHeading = styled(Heading)`
  margin-bottom: 14px;
`
