import React from "react"
import _ from "lodash"
import { InspectionRowProps } from "./types"
import InspectionRowWithStatus from "../../../common/InspectionRowWithStatus"

const InspectionRow = (props: InspectionRowProps) => {
  const { inspection } = props
  const { id, apartment, type, landlordFeedback, tenantFeedback } = inspection
  const label = _.capitalize(`${type} inspection`)

  return (
    <InspectionRowWithStatus
      id={id}
      apartment={apartment}
      label={label}
      landlordFeedback={landlordFeedback}
      tenantFeedback={tenantFeedback}
    />
  )
}

export default InspectionRow
