import React, { useRef } from "react"
import { Box } from "kui-basic"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { observer } from "mobx-react"
import { Loader } from "kui-utils"
import { FormBottom, LoaderState } from "kui-crm"
import SCApartmentFormSchema from "./schema"
import useSCsStore from "../../store"
import SCCommonFields from "../../components/SCCommonFields"
import { ServiceContractApartmentFields } from "../CreateServiceContractForm/types"
import ApartmentsStore from "../../../../store/lites/ApartmentsStore"
import { ApartmentLiteParams } from "../../../../types/store/apartments"

function SCApartmentForm() {
  const { creationForm } = useSCsStore()
  const { fields, updateFormFields, nextStep } = creationForm
  const loaderRef = useRef(new Loader())
  const loader = loaderRef.current
  const form = useForm<ServiceContractApartmentFields>({
    resolver: yupResolver(SCApartmentFormSchema),
    defaultValues: {
      apartment: fields?.apartment,
      hasInventoryInspection: fields?.hasInventoryInspection,
      hasInitialAppraisal: fields?.hasInitialAppraisal,
    },
  })

  const handleSubmit = (data: ServiceContractApartmentFields) => {
    updateFormFields(data)
    nextStep()
  }

  const handleApartmentSelect = async (
    apartment: ApartmentLiteParams | null
  ) => {
    if (apartment) {
      form.setValue("hasInventoryInspection", apartment.hasInventoryInspection)
      form.setValue("hasInitialAppraisal", apartment.hasInitialAppraisal)

      const owners = await ApartmentsStore.getApartmentOwners(
        apartment.id,
        loader
      )

      updateFormFields({ owners: owners || [] })
    }
  }

  return (
    <form data-testid="form_step_1" onSubmit={form.handleSubmit(handleSubmit)}>
      <SCCommonFields form={form} onSelect={handleApartmentSelect} />
      <Box mt={3}>
        <FormBottom label="Next" variant="next" isLoading={loader.isLoading} />
      </Box>
      <LoaderState loader={loader} onlyError />
    </form>
  )
}

export default observer(SCApartmentForm)
