import React from "react"
import { observer } from "mobx-react"
import { SCFieldsHeaderProps } from "../SCFieldsHeader/types"
import ContractEditingButtons from "../../../../components/common/ContractEditingButtons"
import useSCStore from "../../store"

const SCEditingButtons = ({ form }: SCFieldsHeaderProps) => {
  const { editor, addPendingChange, contractChangesStore, contractInfoStore } =
    useSCStore()

  const handleEdit = (data: any) => {
    if (contractInfoStore.status === "Pending")
      contractInfoStore.patchServiceContractInfo(data)
    else addPendingChange(data)
    editor.endEditing()

    if (contractInfoStore.status !== "Pending") form.reset()
  }

  return (
    <ContractEditingButtons
      editor={editor}
      form={form}
      handleEdit={handleEdit}
      handleActivate={contractInfoStore.activateServiceContract}
      status={contractInfoStore.status}
      contractChangesStore={contractChangesStore}
      apartmentId={contractInfoStore.apartment?.id}
    />
  )
}

export default observer(SCEditingButtons)
