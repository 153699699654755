import requests from "./index"
import {
  PatchApartmentImages,
  PatchImageParams,
} from "../pages/ApartmentPage/types/api/apartmentPhotoAPI"

const ApartmentImagesAgent = {
  getAll: (apartmentId: number) =>
    requests.get(`/apartments/${apartmentId}/images`),
  addImagesToApartment: (apartmentId: number, data: PatchApartmentImages) =>
    requests.post(`/apartments/${apartmentId}/images`, data),
  patch: (data: PatchImageParams | any, apartmentId: number, id: number) =>
    requests.patch(`/apartments/${apartmentId}/images/${id}`, data),
  delete: (apartmentId: number, id: number) =>
    requests.delete(`/apartments/${apartmentId}/images/${id}`),
}

export default ApartmentImagesAgent
