import React from "react"
import { observer } from "mobx-react"
import { MeterFeaturesForm, Modal, MeterFeaturesFormFields } from "kui-crm"
import { ApartmentMeterModalProps } from "./types"
import useInspectionStore from "../../../store"

const ApartmentMeterModal = (props: ApartmentMeterModalProps) => {
  const { resource, handleSubmit, handleClose, open } = props
  const { apartmentStore } = useInspectionStore()
  const { overviewStore } = apartmentStore

  const handleFormSubmit = (data: MeterFeaturesFormFields) => {
    handleSubmit(data)
  }

  return (
    <Modal
      title={`Add ${resource} meter`}
      handleClose={handleClose}
      open={open}
    >
      <MeterFeaturesForm
        withPassport
        resource={resource}
        isNewResource={false}
        apartmentId={overviewStore.id}
        administrativeCompany={overviewStore.managementCompany}
        handleSubmit={handleFormSubmit}
      />
    </Modal>
  )
}

export default observer(ApartmentMeterModal)
