import React from "react"
import { createPortal } from "react-dom"
import DropdownMenuBase from "../DropdownMenuBase"
import usePortalPosition from "../../../hooks/usePortalPosition"
import { DropdownMenuProps } from "../DropdownMenu/types"

const menuOffset = 32

const DropdownMenuWithPortal = ({ buttonRef, ...other }: DropdownMenuProps) => {
  const coords = usePortalPosition(buttonRef)
  const menuPosition = {
    right: coords ? coords.right : 0,
    top: coords ? coords.top + menuOffset : 0,
  }

  return createPortal(
    <DropdownMenuBase buttonRef={buttonRef} style={menuPosition} {...other} />,
    document.body
  )
}

export default DropdownMenuWithPortal
