import React, { useState } from "react"
import { useForm } from "react-hook-form"
import styled from "@emotion/styled"
import { Grid } from "kui-basic"
import { yupResolver } from "@hookform/resolvers/yup"
import { observer } from "mobx-react"
import { FormWrapper, InputByType, MetroBlockForModal } from "kui-crm"
import {
  getBasicApartmentInfoFields,
  BasicApartmentInfoStepSchema,
  BasicApartmentInfoStepFields,
} from "kui-crm_actions"
import useApartmentLiteStore from "../../../../../../../store/shared/apartment"
import useStore from "../../../../../../../hooks/useStore"
import InspectionMapBackground from "./InspectionMapBackground"

const BasicApartmentInfoStep = () => {
  const { metroStore, mapStore } = useStore()
  const { inspectionsStore, descriptionStore } = useApartmentLiteStore()
  const [coords, setCoords] = useState<google.maps.LatLngLiteral>({
    lat: -1,
    lng: -1,
  })
  const formStore = inspectionsStore.creationForm
  const form = useForm<BasicApartmentInfoStepFields>({
    defaultValues: {
      ...descriptionStore.fields,
      ...formStore.fields,
      apartment: {
        address:
          formStore.fields?.apartment?.address ||
          descriptionStore.fields?.address,
        location:
          formStore.fields?.apartment?.location ||
          descriptionStore.fields?.geolocation,
      },
    },
    resolver: yupResolver(BasicApartmentInfoStepSchema),
  })

  const fields = getBasicApartmentInfoFields(setCoords)

  const handleSubmit = (data: BasicApartmentInfoStepFields) => {
    formStore.updateFormFields(data)
    formStore.nextStep()
  }

  return (
    <StyledFormWrapper
      onSubmit={form.handleSubmit(handleSubmit)}
      form={form}
      withPrevStep
      handlePrevClick={formStore.prevStep}
      variant="next"
      label="Next"
    >
      <Grid container>
        <InspectionMapBackground coords={coords} />

        <StyledContainer item xs={12} container spacing={1}>
          {fields.map(({ size, ...field }) => (
            <Grid item xs={size || 6} key={field.name}>
              <InputByType form={form} {...field} />
            </Grid>
          ))}

          <MetroBlockForModal
            coords={coords}
            isEditing
            form={form}
            metroStore={metroStore}
            mapStore={mapStore}
          />
        </StyledContainer>
      </Grid>
    </StyledFormWrapper>
  )
}

export default observer(BasicApartmentInfoStep)

const StyledContainer = styled(Grid)`
  position: relative;
  top: -27px;
  padding: 0 40px;
`

const StyledFormWrapper = styled(FormWrapper)`
  form > div {
    padding: 0;
    margin-bottom: 0;
  }
`
