import { makeAutoObservable } from "mobx"
import { DateTime } from "luxon"
import ApartmentPageStore from "../ApartmentPageStore"
import DocumentsWithPaginatorStore from "../../../../store/templates/DocumentsWithPaginatorStore"
import {
  ApartmentAllDocumentsAgent,
  ApartmentDescriptionsDocumentsAgent,
  ApartmentInspectionsDocumentsAgent,
  ApartmentOverviewDocumentsAgent,
} from "../../../../agent/ApartmentDocuments"
import ApartmentOwnershipAgent from "../../../../agent/ApartmentOwnership"
import {
  ClientAttachmentsAgent,
  InternalAttachmentsAgent,
} from "../../../../agent/ApartmentAttachments"
import { ExpensesDocuments } from "../../types/store/apartmentDocuments"

const tabAgents = {
  all: ApartmentAllDocumentsAgent,
  overview: ApartmentOverviewDocumentsAgent,
  ownership: ApartmentOwnershipAgent,
  inspections: ApartmentInspectionsDocumentsAgent,
  descriptions: ApartmentDescriptionsDocumentsAgent,
  expensesInternal: ClientAttachmentsAgent,
  expensesClients: InternalAttachmentsAgent,
}

class ApartmentDocumentsStore {
  apartmentStore: ApartmentPageStore

  date: DateTime | null

  allDocuments: DocumentsWithPaginatorStore

  overviewDocuments: DocumentsWithPaginatorStore

  ownershipDocuments: DocumentsWithPaginatorStore

  inspectionsDocuments: DocumentsWithPaginatorStore

  descriptionsDocuments: DocumentsWithPaginatorStore

  expensesDocuments: ExpensesDocuments

  constructor(apartmentStore: ApartmentPageStore) {
    this.apartmentStore = apartmentStore
    this.date = DateTime.now()
    this.allDocuments = new DocumentsWithPaginatorStore(tabAgents.all)
    this.overviewDocuments = new DocumentsWithPaginatorStore(tabAgents.overview)
    this.ownershipDocuments = new DocumentsWithPaginatorStore(
      tabAgents.ownership
    )
    this.inspectionsDocuments = new DocumentsWithPaginatorStore(
      tabAgents.inspections
    )
    this.descriptionsDocuments = new DocumentsWithPaginatorStore(
      tabAgents.descriptions
    )
    this.expensesDocuments = {
      internalDocuments: new DocumentsWithPaginatorStore(
        tabAgents.expensesInternal
      ),
      clientsDocuments: new DocumentsWithPaginatorStore(
        tabAgents.expensesClients
      ),
    }
    makeAutoObservable(this, { apartmentStore: false })
  }

  setDate = (date: DateTime | null) => {
    this.date = date
  }
}

export default ApartmentDocumentsStore
