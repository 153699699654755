import React from "react"
import styled from "@emotion/styled"
import { Link } from "kui-crm"
import { LinkCardProps } from "./types"
import DashboardCard from "../DashboardCard"
import DashboardCardInfo from "../DashboardCardInfo"

const DashboardLinkCard = (props: LinkCardProps) => {
  const { link, value, ...other } = props

  if (value === null) return null

  return (
    <Link href={link}>
      <StyledDashboardCard>
        <DashboardCardInfo variant="error" value={value} {...other} />
      </StyledDashboardCard>
    </Link>
  )
}

export default DashboardLinkCard

const StyledDashboardCard = styled(DashboardCard)`
  &:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  }
`
