/** @jsxImportSource @emotion/react */
import React from "react"
import styled from "@emotion/styled"
import Loading from "../Loading"

type TableBottomLoadingProps = {
  isLoading: boolean
}

function TableBottomLoading({ isLoading }: TableBottomLoadingProps) {
  return (
    <StyledWrapper
      css={{
        height: isLoading ? 56 : 0,
        paddingTop: isLoading ? 20 : 0,
      }}
    >
      {isLoading && <Loading height="auto" />}
    </StyledWrapper>
  )
}

export default TableBottomLoading

const StyledWrapper = styled.div`
  transition: all ease-out 0.3s;
`
