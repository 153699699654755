import React, { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { Button, Grid } from "kui-basic"
import { CloseIcon, LoupeIcon } from "kui-icon"
import {
  ImageWrapperStylesProps,
  PhotoCardParams,
  PhotoCardProps,
} from "./types"

function PhotoCard<T extends PhotoCardParams>(props: PhotoCardProps<T>) {
  const { image, index, isGrab, onClick, onDelete, children, disabled } = props

  const handleClick = () => {
    if (onClick) onClick(index)
  }

  const handleDelete = (e: SyntheticEvent) => {
    e.stopPropagation()
    if (onDelete) onDelete(image, index)
  }

  return (
    <StyledImageWrapper item isGrab={isGrab} onClick={handleClick}>
      <StyledImage src={image.mediumImageUrl || image.url} alt={image.name} />

      {children}

      <StyledBackdrop>
        <LoupeIcon width={28} height={28} />
        {!disabled && !!onDelete && (
          <StyledRemoveButton
            isCircle
            variant="whiteWithGray"
            onClick={handleDelete}
          >
            <CloseIcon width={8} height={8} />
          </StyledRemoveButton>
        )}
      </StyledBackdrop>
    </StyledImageWrapper>
  )
}

export default observer(PhotoCard)

const cursorCSS = ({ isGrab }: ImageWrapperStylesProps) =>
  typeof isGrab === "boolean" &&
  css`
    cursor: ${isGrab ? "grab" : "grabbing"};
    cursor: ${isGrab ? "-webkit-grab" : "-webkit-grabbing"};
    cursor: ${isGrab ? "-moz-grab" : "-moz-grabbing"};
    &:active {
      cursor: grabbing;
    }
    //&:hover {
    //  cursor: grab !important;
    //}
  `

const StyledImageWrapper = styled(Grid)<ImageWrapperStylesProps>`
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  width: 136px;
  height: 136px;
  cursor: pointer;
  ${cursorCSS};
`

const StyledImage = styled.img`
  min-width: 100%;
  height: 100%;
  object-fit: cover;
  &:hover {
    background-color: rgba(25, 25, 25, 0.4);
  }
`

const StyledBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  background-color: rgba(25, 25, 25, 0.4);
  transition: all ease-out 0.3s;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    opacity: 1;
  }
`

const StyledRemoveButton = styled(Button)`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 6px;
  right: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
`
