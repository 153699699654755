import React from "react"
import { observer } from "mobx-react"
import { Grid } from "kui-basic"
import { UserBankFieldsProps } from "../types"
import userBankInfoFieldsGroup from "./fields"
import UpdatedFieldsGroup from "../../../../../../components/common/UpdatedFieldsGroup"
import useClientStore from "../../../../store"

function ClientBankInfoFields({ form, editor }: UserBankFieldsProps) {
  const { editor: clientEditor } = useClientStore()

  return (
    <Grid container spacing={2}>
      <UpdatedFieldsGroup
        disabled={!clientEditor.isEditing}
        editor={editor}
        form={form}
        {...userBankInfoFieldsGroup}
      />
    </Grid>
  )
}

export default observer(ClientBankInfoFields)
