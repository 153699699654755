import React from "react"
import { observer } from "mobx-react"
import { CompanyCreationModal, ModalProps } from "kui-crm"
import useCompaniesStore from "./store"
import PaginationPage from "../../components/common/PaginationPageWithSearch"
import CompaniesTable from "./components/CompaniesTable"
import Page from "../../components/common/Page"

function CompaniesPage() {
  const companiesStore = useCompaniesStore()

  return (
    <Page withContainer={false}>
      <PaginationPage label="Companies" store={companiesStore}>
        <CompaniesTable />
        {(props: ModalProps) => (
          <CompanyCreationModal
            createCompany={companiesStore.createCompany}
            {...props}
          />
        )}
      </PaginationPage>
    </Page>
  )
}

export default observer(CompaniesPage)
