import { createContext, useContext } from "react"
import ApartmentsStore from "./ApartmentsStore"

const apartmentsStore = new ApartmentsStore()
const ApartmentsContext = createContext(apartmentsStore)

const useApartmentsStore = () => useContext(ApartmentsContext)

export default useApartmentsStore
export { apartmentsStore }
