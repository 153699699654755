import React from "react"
import { observer } from "mobx-react"
import { Box, Heading } from "kui-basic"
import useTariffStore from "../../store"
import Container from "../../../../components/ui/Container"
import Table from "../../../../components/ui/Table"
import TariffReplacementRow from "../TariffReplacementRow"

function TariffReplacementsTable() {
  const { overviewStore } = useTariffStore()
  const { replacements } = overviewStore

  if (replacements.length === 0) {
    return null
  }

  return (
    <Box mt={3}>
      <Container>
        <Box pb={2}>
          <Heading size="h4" weight={700}>
            Replacements
          </Heading>
        </Box>
      </Container>
      <Table>
        <tbody>
          {replacements.map((replacement, key) => (
            <TariffReplacementRow
              key={replacement.id}
              replacement={replacement}
              isDark={key % 2 !== 0}
            />
          ))}
        </tbody>
      </Table>
    </Box>
  )
}

export default observer(TariffReplacementsTable)
