import React from "react"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import ApartmentInspectionRow from "../ApartmentInspectionRow"
import AccordionWithSummary from "../../../../../../../components/common/AccordionWithSummary"
import { ApartmentInspectionsTableProps } from "../ApartmentInspectionsTable/types"

function ApartmentEvaluationInspections(props: ApartmentInspectionsTableProps) {
  const { inspectionsStore } = props
  const { noContractInspections, inspectionsByContract } = inspectionsStore

  if (!noContractInspections.length) {
    return null
  }

  return (
    <StyledAccordion
      title="Between contracts"
      expanded={!inspectionsByContract.length}
    >
      {noContractInspections?.map((inspection) => (
        <ApartmentInspectionRow key={inspection.id} inspection={inspection} />
      ))}
    </StyledAccordion>
  )
}

export default observer(ApartmentEvaluationInspections)

const StyledAccordion = styled(AccordionWithSummary)`
  padding: 16px 0;
  .KUI-Collapse {
    padding-bottom: 16px;
  }
`
