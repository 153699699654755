import * as yup from "yup"
import { defaultRequiredMessage } from "kui-crm"

const getMeterReadingFormSchema = (isInsideMeter?: boolean) =>
  yup.object().shape({
    ...(isInsideMeter
      ? {}
      : {
          apartment: yup.object({
            address: yup.string().required(defaultRequiredMessage),
          }),
          resource: yup.string().required(defaultRequiredMessage),
          meter: yup.object({
            name: yup.string().required(defaultRequiredMessage),
          }),
        }),
    file: yup.object({
      file: yup.mixed().required(defaultRequiredMessage),
    }),
    checkDate: yup.mixed().required(defaultRequiredMessage),
    nextCheck: yup.mixed().required(defaultRequiredMessage),
  })

export default getMeterReadingFormSchema
