import * as React from "react"
import {
  InputSelectWithController,
  InputSelect as InputSelectKUI,
} from "kui-complex"
import { InputSelectBaseProps, OptionParams } from "kui-complex/InputSelectBase"
import { ForwardedRef, forwardRef } from "react"

const InputSelect = forwardRef(
  <TFormValues extends object, TOption extends OptionParams>(
    { form, ...inputProps }: InputSelectBaseProps<TFormValues, TOption>,
    ref: ForwardedRef<HTMLInputElement>
  ) =>
    form ? (
      <InputSelectWithController ref={ref} form={form} {...inputProps} />
    ) : (
      <InputSelectKUI ref={ref} form={form} {...inputProps} />
    )
)

export default InputSelect
