import React from "react"
import styled from "@emotion/styled"
import { Heading } from "kui-basic"
import { GridTableHeadRowProps } from "./types"
import GridTableHeader from "../GridTableHeader"

function GridTableHeadRow(props: GridTableHeadRowProps) {
  const { title, titleWidth, ...other } = props
  return (
    <StyledGridTableHeader
      headCell={
        <Heading data-testid="table_label" size="h4">
          {title}
        </Heading>
      }
      headWidth={titleWidth}
      endWidth="4%"
      data-testid="table_head_row"
      endCell={<div />}
      {...other}
    />
  )
}

export default GridTableHeadRow

const StyledGridTableHeader = styled(GridTableHeader)`
  > div:not(:first-child) {
    padding-left: 22px;
  }
`
