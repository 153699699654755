import React from "react"
import styled from "@emotion/styled"
import TextOverflowWithComment from "../TextOverflowWithComment"
import { TextOverflowWithCommentAndFileProps } from "./types"
import FileItem from "../FileItem"

function TextOverflowWithCommentAndFile(
  props: TextOverflowWithCommentAndFileProps
) {
  const { file, ...other } = props
  return (
    <StyledTooltip
      content={file && <StyledFileItem file={file} />}
      {...other}
    />
  )
}

export default TextOverflowWithCommentAndFile

const StyledTooltip = styled(TextOverflowWithComment)`
  .TextOverflow_content {
    width: 367px;
    max-width: 367px;
  }
`

const StyledFileItem = styled(FileItem)`
  margin-top: 16px;
  & .FileContent_wrapper {
    width: calc(100% - 32px - 16px);
  }
`
