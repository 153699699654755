import { makeAutoObservable } from "mobx"
import to from "await-to-js"
import { Loader } from "kui-utils"
import ContractChangeGroupStore from "./ContractChangeGroupStore"
import { ContractChangesResponse } from "../../types/api/contracts"
import {
  ContractChangesAgent,
  ContractVariants,
} from "../../types/store/contract"
import { RentalContractChangesAgent } from "../../agent/RentalContract"
import { ServiceContractChangesAgent } from "../../agent/ServiceContract"
import RentalContractInfoStore from "../shared/contracts/RentalContractInfoStore"
import ServiceContractInfoStore from "../shared/contracts/ServiceContractInfoStore"

class ContractChangesStore {
  pendingChangesLines: ContractChangeGroupStore[]

  appliedChangesLines: ContractChangeGroupStore[]

  loader: Loader

  variant: ContractVariants

  agent: ContractChangesAgent

  contractInfoStore: RentalContractInfoStore | ServiceContractInfoStore

  constructor(
    variant: ContractVariants,
    loader: Loader,
    contractInfoStore: RentalContractInfoStore | ServiceContractInfoStore
  ) {
    this.loader = loader
    this.variant = variant
    this.agent =
      variant === "rental"
        ? RentalContractChangesAgent
        : ServiceContractChangesAgent
    this.pendingChangesLines = []
    this.appliedChangesLines = []
    this.contractInfoStore = contractInfoStore
    makeAutoObservable(this)
  }

  fetchPendingChanges = async (id: number) => {
    const [err, res] = await to<ContractChangesResponse>(
      this.agent.getChanges(id)
    )

    if (!err && res) {
      this.updatePendingChanges(res)
    } else {
      this.loader.setError("fetch pending changes", err)
    }
  }

  fetchAppliedChanges = async (id: number) => {
    const [err, res] = await to<ContractChangesResponse>(
      this.agent.getChanges(id, true)
    )

    if (!err && res) {
      this.updateAppliedChanges(res)
    } else {
      this.loader.setError("fetch applied changes", err)
    }
  }

  updatePendingChanges = (res: ContractChangesResponse) => {
    this.pendingChangesLines = res.map(
      (changeGroup) =>
        new ContractChangeGroupStore(changeGroup, this.variant, this)
    )
  }

  updateAppliedChanges = (res: ContractChangesResponse) => {
    this.appliedChangesLines = res.map(
      (changeGroup) =>
        new ContractChangeGroupStore(changeGroup, this.variant, this)
    )
  }

  deletePendingFromList = (id: number) => {
    this.pendingChangesLines = this.pendingChangesLines.filter(
      (change) => change.id !== id
    )
  }

  get hasEditingChange() {
    return !!this.pendingChangesLines.filter(
      (change) => change.editor.isEditing
    ).length
  }

  get usedPendingDates() {
    return this.pendingChangesLines
      .map((line) => line.dateOfChange?.toJSDate())
      .filter((date) => date) as Date[]
  }
}

export default ContractChangesStore
