import React from "react"
import { observer } from "mobx-react"
import { InspectionImpairmentsArchiveProps } from "./types"
import InspectionImpairmentsRow from "../InspectionImpairmentsRow"

const InspectionImpairmentsArchive = (
  props: InspectionImpairmentsArchiveProps
) => {
  const { inspectionsStore } = props

  return (
    <div>
      {inspectionsStore.archiveInspections.map((inspection) => (
        <InspectionImpairmentsRow
          key={inspection.id}
          isArchive
          inspection={inspection}
        />
      ))}
    </div>
  )
}

export default observer(InspectionImpairmentsArchive)
