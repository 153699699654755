import * as yup from "yup"
import { defaultRequiredMessage } from "kui-crm"

export const TenantOfferFieldsSchema = {
  minPrice: yup.string().required(defaultRequiredMessage),
  maxPrice: yup.string().required(defaultRequiredMessage),
}

const TenantOfferStepSchema = yup.object().shape(TenantOfferFieldsSchema)

export default TenantOfferStepSchema
