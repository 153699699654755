import _ from "lodash"
import { ChangeParams } from "../../types"

const getFormattedLabel = (label: string) => _.capitalize(_.startCase(label))

const getChangeRowParams = (
  change: ChangeParams,
  label?: string,
  formattingFunc?: (value: any) => any
) => ({
  fieldName: label || getFormattedLabel(change.fieldName),
  currentValue: formattingFunc
    ? formattingFunc(change.currentValue)
    : change.currentValue,
  newValue: formattingFunc ? formattingFunc(change.newValue) : change.newValue,
})

export { getChangeRowParams }
