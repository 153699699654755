import React, { useEffect } from "react"
import { Grid } from "kui-basic"
import styled from "@emotion/styled"
import { useWatch } from "react-hook-form"
import { InputByType } from "kui-crm"
import { PromotionChannelsContentFieldsProps } from "./types"
import promotionChannelsContentFields from "./fields"
import usePromotionStore from "../../../store"

const PromotionChannelsContentFields = (
  props: PromotionChannelsContentFieldsProps
) => {
  const { form, disabled } = props
  const { channelsStore } = usePromotionStore()
  const fields = useWatch({
    control: form.control,
    name: [
      "minPrice",
      "maxPrice",
      "stepFrequency",
      "startAdvertisingDate",
      "endAdvertisingDate",
    ],
  })

  useEffect(() => {
    if (form.formState.isDirty) {
      const stepPrice = channelsStore.contentStore.getStepPriceValue(
        form.getValues()
      )
      if (typeof stepPrice === "number") {
        form.setValue("stepPrice", stepPrice)
      }
    }
  }, [...fields, form.formState])

  return (
    <Grid item xs={5}>
      <StyledWrapper container spacing={3}>
        {promotionChannelsContentFields.map((field) => (
          <Grid key={field.name} item xs={6}>
            <InputByType form={form} disabled={disabled} {...field} />
          </Grid>
        ))}
      </StyledWrapper>
    </Grid>
  )
}

export default PromotionChannelsContentFields

const StyledWrapper = styled(Grid)`
  padding-top: 8px;
`
