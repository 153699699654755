import React from "react"
import { observer } from "mobx-react"
import { Grid } from "kui-basic"
import styled from "@emotion/styled"
import ImageCard from "../../../../../../../../../components/common/ImageCard"
import { ApartmentPhotoModalCardProps, PointStylesProps } from "./types"
import usePromotionStore from "../../../../../store"

function ApartmentPhotoModalCard({
  image,
  changeActiveImage,
}: ApartmentPhotoModalCardProps) {
  const {
    contentStore: { imagesStore },
  } = usePromotionStore()

  const handleClick = () => {
    if (image.activeNumber > 0) {
      imagesStore?.setLastSelectedImageNumber(image.lastSelectedNumber - 1)
      changeActiveImage(image.activeNumber)
      image.setActiveNumber(0)
    } else {
      image.setActiveNumber(image.lastSelectedNumber + 1)
      imagesStore?.setLastSelectedImageNumber(image.lastSelectedNumber + 1)
    }
  }

  return (
    <ImageCard
      data-testid={`image_card_${image.id}`}
      image={image}
      onClick={handleClick}
    >
      <StyledPoint
        container
        justify="center"
        alignItems="center"
        isActive={image.activeNumber > 0}
      >
        {image.activeNumber > 0 ? image.activeNumber : ""}
      </StyledPoint>
    </ImageCard>
  )
}

export default observer(ApartmentPhotoModalCard)

const StyledPoint = styled(Grid)<PointStylesProps>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${({ isActive, theme }) =>
    isActive ? theme.palette.brand.main : theme.palette.grey.zero};
  color: ${({ isActive, theme }) =>
    isActive ? theme.palette.grey.zero : theme.palette.grey.seventy};
  position: absolute;
  top: 8px;
  left: 8px;
  cursor: pointer;
  font-size: 12px;
  line-height: 14px;
`
