import { createContext, useContext } from "react"
import hostStore from "../../../store"
import TariffPageStore from "./TariffPageStore"

const tariffStore = new TariffPageStore(hostStore)
const TariffContext = createContext(tariffStore)

const useTariffStore = () => useContext(TariffContext)

export default useTariffStore
