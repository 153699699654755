import React from "react"
import { TabsPanel as TabsPanelKUI } from "kui-basic"
import { useNavigate } from "react-router-dom"
import styled from "@emotion/styled"
import { TabsPanelProps } from "./types"

const TabsPanel = (props: TabsPanelProps) => {
  const { tabs, ...other } = props
  const navigate = useNavigate()

  return <StyledTabsPanel tabs={tabs || []} navigate={navigate} {...other} />
}

export default TabsPanel

const StyledTabsPanel = styled(TabsPanelKUI)`
  .KUI-TabsContainer {
    position: sticky;
    z-index: 4 !important;
    background: white;
  }
  .KUI-TabsPanelWrapper {
    max-width: 1344px;
    margin: 0 auto;
    padding: 0 24px;
  }
  .KUI-TabsWrapper {
    padding: 0 !important;
    min-width: fit-content;
  }
  .KUI-Tabs_indicator {
    height: 3px;
  }
  .KUI-Tab {
    height: 55px;
    p {
      color: ${({ theme }) => theme.palette.grey.fiftyP};
    }
  }
  .KUI-Tab_selected {
    p {
      color: ${({ theme }) => theme.palette.grey.seventy};
    }
  }
`
