import React from "react"
import styled from "@emotion/styled"
import { Grid } from "kui-basic"
import _ from "lodash"
import { Link, PreviewContent } from "kui-crm"
import { AutoMeterRowProps } from "./types"
import { DynamicRoutes } from "../../../../../../types/route"
import TwoCaptionsBlock from "../../../../../../components/common/TwoCaptionsBlock"

const AutoMeterRow = ({ meter }: AutoMeterRowProps) => {
  const { apartment } = meter
  const apartmentLink = DynamicRoutes.apartment(apartment.id)
  const meterLink = DynamicRoutes.meter(meter.resource, meter.id)
  const meterLabel = _.capitalize(
    `${meter.type ? `${meter.type} ` : ""}${meter.resource}`
  )

  return (
    <StyledRow container alignItems="center" justify="space-between">
      <StyledLink href={apartmentLink}>
        <PreviewContent
          imageSrc={apartment.previewImage}
          description={apartment.address}
        />
      </StyledLink>
      <StyledLink href={meterLink}>
        <StyledMeterInfo
          top={meterLabel}
          bottom={`#${meter.number}`}
          topWeight={500}
          topSize="s"
          bottomSize="xs"
          spacing={2}
        />
      </StyledLink>
    </StyledRow>
  )
}

export default AutoMeterRow

const StyledRow = styled(Grid)`
  padding: 8px 0;
  flex-wrap: nowrap;
  &:not(&:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
  }
`

const StyledMeterInfo = styled(TwoCaptionsBlock)`
  text-align: right;
  min-width: fit-content;
  flex-grow: 1;
`

const StyledLink = styled(Link)`
  width: auto;
`
