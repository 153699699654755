import to from "await-to-js"
import { makeAutoObservable } from "mobx"
import { CompanyBankInfoModel } from "../types/api/companyAPI"
import { CompanyBankInfoFormFields } from "../forms/CompanyBanksInfoForm/types"
import CompanyAgent from "../../../agent/Company"
import CompanyBanksStore from "./CompanyBanksStore"

class CompanyBankStore {
  id: number

  name: string

  bankName: string

  SWIFT: string

  BIC: string

  correspondentAccount: string

  account: string

  banksInfoStore: CompanyBanksStore | null

  constructor(bank: CompanyBankInfoModel, banksInfoStore?: CompanyBanksStore) {
    this.id = bank.id
    this.name = bank.name
    this.bankName = bank.bank_name
    this.SWIFT = bank.SWIFT
    this.BIC = bank.BIC
    this.correspondentAccount = bank.correspondent_account
    this.account = bank.account
    this.banksInfoStore = banksInfoStore || null
    makeAutoObservable(this)
  }

  patch = async (data: CompanyBankInfoFormFields, companyId: number) => {
    const body = CompanyBankStore.getPatchBankBody(data)

    const [err, res] = await to(
      CompanyAgent.patchCompanyBank(companyId, this.id, body)
    )
    if (!err && res) {
      this.updateBankInfo(res)
    } else {
      this.banksInfoStore?.loader.setError("patch company bank info")
    }
  }

  delete = async (companyId: number) => {
    const [err] = await to(CompanyAgent.deleteCompanyBank(companyId, this.id))
    if (!err) {
      this.banksInfoStore?.deleteBank(this.id)
    } else {
      this.banksInfoStore?.loader.setError("bank removal")
    }
  }

  updateBankInfo = (bank: Partial<CompanyBankInfoModel>) => {
    this.name = bank.name ?? this.name
    this.bankName = bank.bank_name ?? this.bankName
    this.SWIFT = bank.SWIFT ?? this.SWIFT
    this.BIC = bank.BIC ?? this.BIC
    this.correspondentAccount =
      bank.correspondent_account ?? this.correspondentAccount
    this.account = bank.account ?? this.account
  }

  static getPatchBankBody = (data: CompanyBankInfoFormFields) => ({
    name: data.name,
    bank_name: data.bankName,
    SWIFT: data.SWIFT,
    BIC: data.BIC,
    correspondent_account: data.correspondentAccount,
    account: data.account,
  })
}

export default CompanyBankStore
