import { makeAutoObservable } from "mobx"
import { UserRole } from "../../../../types/api/cabinet"
import DashboardPageStore from "../DashboardPageStore"

class AdminDashboardStore {
  selectedRole: UserRole

  dashboardPageStore: DashboardPageStore

  constructor(dashboardPageStore: DashboardPageStore) {
    this.selectedRole = "accountant"
    this.dashboardPageStore = dashboardPageStore
    makeAutoObservable(this)
  }

  setSelectedRole = (role: UserRole) => {
    this.selectedRole = role
  }

  resetSettings = () => {
    this.dashboardStore?.resetSettings()
  }

  resetDashboard = () => {
    this.dashboardStore?.resetDashboard()
  }

  get dashboardStore() {
    switch (this.selectedRole) {
      case "accountant":
        return this.dashboardPageStore.accountantDashboard
      case "housing_inspector":
        return this.dashboardPageStore.housingInspectorDashboard
      case "housing_manager":
        return this.dashboardPageStore.housingManagerDashboard
      case "sales_manager":
        return this.dashboardPageStore.salesManagerDashboard
      case "consultant":
        return this.dashboardPageStore.consultantDashboard
      default:
        return null
    }
  }
}

export default AdminDashboardStore
