import React from "react"
import { observer } from "mobx-react"
import useTariffsStore from "../../store"
import ErrorComponent from "../../../../components/common/ErrorComponent"
import GroupedSortedTable from "../../../../components/common/GroupedSortedTable"
import Container from "../../../../components/ui/Container"
import { TariffsTableProps } from "./types"
import TariffRow from "../TariffRow"
import TariffPageStore from "../../store/TariffStore"
import tariffsHeadCellsParams from "./headParams"
import { SortingTableHeadCellParams } from "../../../../components/common/SortingTable/types"

function TariffsTable({ resource }: TariffsTableProps) {
  const { resource: tariffsStore } = useTariffsStore()
  const { loader, tariffs } = tariffsStore
  const headParams = tariffsHeadCellsParams(
    resource
  ) as SortingTableHeadCellParams[]

  if (loader.hasError) {
    return (
      <Container>
        <ErrorComponent withPage={false} />
      </Container>
    )
  }

  return (
    <GroupedSortedTable<TariffPageStore>
      offset={188}
      headParams={headParams}
      store={tariffsStore}
      list={tariffs}
      groupBy="city"
      withOverlaps
      renderRow={(tariff, key) => (
        <TariffRow key={tariff.id} tariff={tariff} isDark={key % 2 !== 0} />
      )}
    />
  )
}

export default observer(TariffsTable)
