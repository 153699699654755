import * as yup from "yup"
import { defaultRequiredMessage } from "kui-crm"

const ServiceApartmentFormSchema = yup.object().shape({
  apartment: yup.object({
    address: yup.string().required(defaultRequiredMessage),
  }),
})

export default ServiceApartmentFormSchema
