import React from "react"
import styled from "@emotion/styled"
import { UpdateIcon } from "kui-icon"
import DashboardListRow from "../DashboardListRow"
import { DashboardListRowProps } from "../DashboardListRow/types"

const DashboardArchiveRow = (props: DashboardListRowProps) => (
  <StyledDashboardRow icon={<UpdateIcon width={18} height={18} />} {...props} />
)

export default DashboardArchiveRow

const StyledDashboardRow = styled(DashboardListRow)`
  padding: 8px;
  border-radius: 12px;
  transition: all ease-out 0.3s;
  &:not(&:last-of-type) {
    border-bottom: none;
  }
  &:hover {
    background: ${({ theme }) => theme.palette.background.light1};
  }
`
