import { makeAutoObservable } from "mobx"
import { DateTime } from "luxon"
import { DistrictParams, ResourceTypes } from "kui-crm/types"
import { TariffModel } from "../types/api/tariffsAPI"
import { ChangeReasonParams } from "../../../types/common"
import { TariffsOfTariffGroup } from "../../TariffPage/types/store/tariffOverview"

class TariffStore {
  id: number

  startDate: DateTime | null

  endDate: DateTime | null

  codeName: string

  name: string

  status: string

  city: string

  district: DistrictParams

  reason: ChangeReasonParams | null

  tariffs: TariffsOfTariffGroup

  resource: ResourceTypes

  constructor(tariff: TariffModel, resource: ResourceTypes) {
    this.id = tariff.id
    this.startDate = tariff.date_from
      ? DateTime.fromISO(tariff.date_from)
      : null
    this.endDate = tariff.date_to ? DateTime.fromISO(tariff.date_to) : null
    this.codeName = tariff.code_name
    this.name = tariff.name
    this.status = tariff.status
    this.city = tariff.city?.name || tariff.region?.name || "Without region"
    this.reason = null
    this.tariffs = tariff
    this.resource = resource
    this.district = tariff.district
    makeAutoObservable(this)
  }
}

export default TariffStore
