import axios, { AxiosRequestConfig } from "axios"
import { getCookie } from "kui-utils"
import stores from "../store"

axios.defaults.withCredentials = true

const APP_URL = process.env.REACT_APP_URL
const API_URL = process.env.REACT_APP_API_URL
const PREDICTOR_API = process.env.REACT_APP_API_URL_PREDICTOR
const ML_API = process.env.REACT_APP_ML_GRAPH_API

const errorHandler = (err: any) => {
  if (err && err.response?.status === 401) {
    stores.authStore.cleanAuthData()
  }
  return Promise.reject(err.response?.data ?? err)
}

const CSRFPlugin = (req: AxiosRequestConfig) => {
  const CSRFToken = getCookie("csrftoken")

  if (CSRFToken) {
    req.headers!["X-CSRFToken"] = CSRFToken
  }

  return req
}

axios.interceptors.request.use(CSRFPlugin)

axios.interceptors.response.use(
  async (res) => res.data,
  (error) => errorHandler(error)
)

const requests = {
  get: async <T = any>(url: string, config?: AxiosRequestConfig): Promise<T> =>
    axios.get(`${API_URL}${url}`, config),
  getMLGraph: async <T = any>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<T> => axios.get(`${ML_API}${url}`, config),
  getStaticVersion: async <T = any>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<T> => axios.get(`${APP_URL}/api${url}`, config),
  updatePredict: async <T = any>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<T> => axios.get(`${PREDICTOR_API}?${url}`, config),
  post: async <T = any>(url: string, data?: any): Promise<T> =>
    axios.post(`${API_URL}${url}`, data),
  authPost: async <T = any>(url: string, data?: any): Promise<T> =>
    axios.post(`${APP_URL}/api${url}`, data),
  patch: async <T = any>(url: string, data?: any): Promise<T> =>
    axios.patch(`${API_URL}${url}`, data),
  put: async <T = any>(url: string, data?: any): Promise<T> =>
    axios.put(`${API_URL}${url}`, data),
  delete: async <T = any>(url: string, data?: any): Promise<T> =>
    axios.delete(`${API_URL}${url}`, data),
  getByUrl: async <T = any>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<T> => axios.get(url, config),
}

export default requests
