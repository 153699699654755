import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { DateTime } from "luxon"
import { Loader, toNumber } from "kui-utils"
import { ApartmentPaymentModel } from "../../types/api/expensesSummaryAPI"
import { PaymentFormFields } from "../../components/tabs/ExpensesSummaryTab/ExpensesPaymentFields/types"
import ApartmentExpensesAgent from "../../../../../../agent/ApartmentExpenses"
import UserLiteStore from "../../../../../../store/templates/UserLite"
import ApartmentPaymentsStore from "./ApartmentPayments"
import { matchesSubjectRoles } from "../../../../../../utils/content/matches"
import {
  ContractLinkParams,
  SubjectRoles,
} from "../../../../../../types/common"
import {
  PaymentInvoiceParams,
  PaymentTransactionTypes,
} from "../../types/store/expensesSummary"

class ApartmentPaymentStore {
  paymentsStore: ApartmentPaymentsStore | null

  id: number

  payer: UserLiteStore

  date: DateTime | null

  amount: number

  loader: Loader

  invoice: PaymentInvoiceParams | null

  contract: ContractLinkParams | null

  transactionType: PaymentTransactionTypes | null

  constructor(
    paymentsStore: ApartmentPaymentsStore | null,
    payment: ApartmentPaymentModel
  ) {
    this.id = payment.id
    this.payer = UserLiteStore.initFromUserNamesModel(
      payment.payer,
      matchesSubjectRoles[payment.payer_type] as SubjectRoles
    )
    this.date = payment.payment_date
      ? DateTime.fromISO(payment.payment_date)
      : null
    this.amount = Number(payment.value)
    this.loader = new Loader()
    this.paymentsStore = paymentsStore
    this.invoice = payment.bill ?? null
    this.transactionType = payment.payment_type ?? null
    this.contract = payment.rental_contract
    makeAutoObservable(this, { paymentsStore: false })
  }

  editPayment = async (apartmentId: number, data: PaymentFormFields) => {
    this.loader.startLoading("payment changes")

    const body = ApartmentPaymentStore.getPatchPaymentBody(data)

    const [err, res] = await to<ApartmentPaymentModel[]>(
      ApartmentExpensesAgent.patchPayment(apartmentId, this.id, body)
    )

    runInAction(() => {
      if (res && !err) {
        this.updatePayments(res)
      } else {
        this.loader.setError(`patch payment`, err)
      }
      this.loader.endLoading()
    })

    const expensesStore = this.paymentsStore?.expensesStore
    const selectedPeriod = expensesStore?.periodsStore.selectedPeriod
    const periodId = selectedPeriod?.id
    const contractId = selectedPeriod?.rentalContract?.id
    const payoutsStore = expensesStore?.summaryStore.payoutsStore

    if (periodId && payoutsStore)
      payoutsStore.fetchPayouts(apartmentId, contractId, periodId)
  }

  updatePayments = (payments: ApartmentPaymentModel[]) => {
    payments.forEach((payment) => {
      if (payment.id === this.id) {
        this.updatePayment(payment)
        this.paymentsStore?.updateSummaryValue()
      } else {
        this.paymentsStore?.addPaymentToList(payment)
      }
    })
  }

  deletePayment = async (apartmentId: number) => {
    this.loader.startLoading("payment removal")

    const [err] = await to(
      ApartmentExpensesAgent.deletePayment(apartmentId, this.id)
    )

    runInAction(() => {
      if (!err) {
        this.paymentsStore?.deletePayment(this.id, this.payer.role)
      } else {
        this.loader.setError("payment removal", err)
      }
      this.loader.endLoading()
    })

    const expensesStore = this.paymentsStore?.expensesStore
    const selectedPeriod = expensesStore?.periodsStore.selectedPeriod
    const periodId = selectedPeriod?.id
    const contractId = selectedPeriod?.rentalContract?.id
    const payoutsStore = expensesStore?.summaryStore.payoutsStore

    if (periodId && payoutsStore)
      payoutsStore.fetchPayouts(apartmentId, contractId, periodId)
  }

  updatePayment = (payment: Partial<ApartmentPaymentModel>) => {
    if (payment.id) this.id = payment.id
    if (payment.payer_type)
      this.payer = UserLiteStore.initFromUserNamesModel(
        payment.payer,
        matchesSubjectRoles[payment.payer_type] as SubjectRoles
      )
    if (payment.payment_date)
      this.date = payment.payment_date
        ? DateTime.fromISO(payment.payment_date)
        : this.date
    if (payment.value) this.amount = Number(payment.value)
    if (payment.payment_type) this.transactionType = payment.payment_type
    this.invoice = payment.bill ?? null
  }

  static getPatchPaymentBody = (data: PaymentFormFields) => ({
    payer: Number(data.payerId),
    value: toNumber(data.amount)?.toString(),
    payment_date: data.date?.toISODate() || null,
    bill: data.invoiceId,
    payment_type: data.transactionType,
  })
}

export default ApartmentPaymentStore
