import React, { useEffect } from "react"
import { Box, Grid } from "kui-basic"
import { useFieldArray, useWatch } from "react-hook-form"
import { RoommatesFieldsProps } from "./types"
import FieldsGroupTitleWithButton from "../../../../../components/common/FieldsGroupTitleWithButton"
import UserWithDeleteField from "../../../../../components/common/UserWithDeleteField"

function RoommatesFields({ form }: RoommatesFieldsProps) {
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "roommates",
  })
  const clientType = useWatch({
    control: form.control,
    name: "clientType",
  })

  const addRoommate = () => {
    append({ name: "" })
  }

  useEffect(() => {
    if (clientType === "company") form.setValue("roommates", [{ name: "" }])
  }, [clientType])

  return (
    <Box pl={5} pr={5} pb={3}>
      <FieldsGroupTitleWithButton
        title={clientType === "company" ? "Tenants" : "Roommates"}
        onClick={addRoommate}
      />
      <Grid container spacing={2}>
        {fields.map((field, index) => (
          <Grid item xs={12} key={fields[index].id}>
            <UserWithDeleteField
              index={index}
              remove={!(index === 0 && clientType === "company") && remove}
              label={clientType === "company" ? "Tenant" : "Roommate"}
              name={`roommates.${index}`}
              form={form}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default RoommatesFields
