import React from "react"
import { observer } from "mobx-react"
import { Divider } from "kui-basic"
import EntityPage from "../../components/common/EntityPage"
import useRCStore from "./store"
import RCHeader from "./components/RCHeader"
import RCOverviewHeader from "./components/RCOverviewHeader"
import RCInfo from "./components/RCInfo"

function RentalContractPage() {
  const rcStore = useRCStore()

  return (
    <EntityPage store={rcStore} initPage={rcStore.initRCPage}>
      <RCHeader />
      <RCOverviewHeader />
      <Divider />
      <RCInfo />
    </EntityPage>
  )
}

export default observer(RentalContractPage)
