import { makeAutoObservable } from "mobx"
import { RentalStatuses, RentalTypes } from "kui-crm/types"
import { LatLngParams } from "../../../components/common/MapOverlay/types"
import UserLiteStore from "../../../store/templates/UserLite"
import { ApartmentForMapModel } from "../types/api/apartmentsAPI"

class ApartmentForMapStore {
  id: number

  address: string

  previewImage: string | null

  geolocation: LatLngParams | null

  tenant: UserLiteStore | null

  landlord: UserLiteStore | null

  rentalStatus: RentalStatuses

  rentalType: RentalTypes

  rentalCost: number | null

  roomsNumber: number

  constructor(apartment: ApartmentForMapModel) {
    this.id = apartment.id
    this.address = apartment.object_name
    this.previewImage =
      apartment.main_image?.image_medium_url ||
      apartment.main_image?.image_url ||
      null
    this.rentalStatus = apartment.rental_status
    this.rentalType = apartment.rental_type
    this.roomsNumber = apartment.rooms_number
    this.rentalCost = apartment.month_payment
      ? Number(apartment.month_payment)
      : null
    this.geolocation =
      apartment.geolocation?.lat && apartment.geolocation?.lon
        ? {
            lat: apartment.geolocation.lat,
            lng: apartment.geolocation.lon,
          }
        : null
    this.tenant = apartment.renter
      ? UserLiteStore.initFromLiteUserModel(apartment.renter)
      : null
    this.landlord = apartment.owner
      ? UserLiteStore.initFromLiteUserModel(apartment.owner)
      : null
    makeAutoObservable(this)
  }
}

export default ApartmentForMapStore
