import * as React from "react"
import { useForm } from "react-hook-form"
import { useEffect } from "react"
import { Box, Grid } from "kui-basic"
import { observer } from "mobx-react"
import { yupResolver } from "@hookform/resolvers/yup"
import { CommonTariffFieldsSchema } from "kui-crm"
import useTariffStore from "../../store"
import { TariffOverviewFormFields, TariffOverviewInfoFormProps } from "./types"
import DocumentsBlockWithForm from "../../../../components/common/DocumentsBlockWithForm"
import TariffOverviewInfoFields from "../../components/TariffOverviewInfoFields"
import TariffOverviewTariffsFields from "../../components/TariffOverviewTariffsFields"

function TariffOverviewInfoForm({ handleSubmit }: TariffOverviewInfoFormProps) {
  const { overviewStore, editor } = useTariffStore()
  const form = useForm<TariffOverviewFormFields>({
    defaultValues: {
      ...overviewStore.tariffInfoStore,
      documents: overviewStore.documentsStore.documents || [],
      country: {
        id: 1,
        name: "Russian Federation",
      },
    },
    resolver: yupResolver(CommonTariffFieldsSchema),
  })

  useEffect(() => {
    if (editor.isAllowedToSendForm) {
      form.handleSubmit(handleSubmit, editor.blockToSendForm)()
    }
  }, [editor.isAllowedToSendForm])

  return (
    <Box mt={2}>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <TariffOverviewInfoFields form={form} />
          <TariffOverviewTariffsFields form={form} />
        </Grid>
        <Grid item xs={4}>
          <DocumentsBlockWithForm
            withScroll
            form={form}
            disabled={!editor.isEditing}
            documents={overviewStore.documentsStore.documents}
            loader={overviewStore.documentsStore.loader}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default observer(TariffOverviewInfoForm)
