import requests from "./index"
import { PostApartmentFillingRequest } from "../pages/ApartmentsPage/types/api/apartmentsAPI"

const FillingAgent = {
  all: (apartmentId: number) =>
    requests.get(`/apartments/${apartmentId}/features`),
  post: (apartmentId: number, data: PostApartmentFillingRequest) =>
    requests.post(`/apartments/${apartmentId}/features`, data),
  edit: (
    apartmentId: number,
    fillingId: number,
    data: PostApartmentFillingRequest
  ) => requests.patch(`/apartments/${apartmentId}/features/${fillingId}`, data),
  delete: (apartmentId: number, fillingId: number) =>
    requests.delete(`/apartments/${apartmentId}/features/${fillingId}`),
  duplicate: (apartmentId: number, fillingId: number) =>
    requests.post(`/apartments/${apartmentId}/features/${fillingId}/copy`),
  deletePhoto: (apartmentId: number, fillingId: number, photoId: number) =>
    requests.delete(
      `/apartments/${apartmentId}/features/${fillingId}/photos/${photoId}`
    ),
}

export default FillingAgent
