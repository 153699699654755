import React, { ReactElement } from "react"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { ProtectedNodeStylesProps, ProtectedTabsProps } from "./types"
import ProtectedNode from "../ProtectedNode"
import useStore from "../../../hooks/useStore"

const ProtectedTabs = (props: ProtectedTabsProps) => {
  const { tabs, children, withSpacing, tabPanels, ...other } = props
  const { cabinetStore } = useStore()
  const tabsLabels = tabs.map((tab) => tab.label)
  const protectedTabPanels = tabPanels.map((tabPanel, index) => (
    <ProtectedNode withMessage permission={tabs[index].permission}>
      {tabPanel}
    </ProtectedNode>
  ))
  const tabsStatuses = tabs.map((tab) =>
    tab.permission ? cabinetStore.isThereAccess(tab.permission) : true
  )
  const firstActiveTab = tabsStatuses.findIndex((isAvailable) => isAvailable)

  const renderTabWrapper = (tabLabel: string, tabComponent: ReactElement) => {
    const permission = tabs.find((tab) => tab.label === tabLabel)?.permission

    return (
      <StyledNode
        withSpacing={withSpacing}
        strategy="fixed"
        withTooltip
        permission={permission}
      >
        {tabComponent}
      </StyledNode>
    )
  }

  return (
    <>
      {React.cloneElement(children, {
        tabs: tabsLabels,
        tabPanels: protectedTabPanels,
        renderTabWrapper,
        firstActiveTab,
        ...other,
      })}
    </>
  )
}

export default observer(ProtectedTabs)

const StyledNode = styled(ProtectedNode)<ProtectedNodeStylesProps>`
  &:not(:last-child) {
    margin-right: ${({ withSpacing }) => (withSpacing ? 24 : 0)}px;
  }
`
