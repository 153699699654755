import { InputByTypeProps } from "kui-crm"
import { PromotionChannelsFormFields } from "../types"
import InputCounter from "../../../../../../../components/ui/InputCounter"

const promotionChannelsContentFields: InputByTypeProps<PromotionChannelsFormFields>[] =
  [
    {
      label: "Minimum advertising price",
      name: "minPrice",
      cornerLabel: "₽",
      variant: "mask",
      mask: Number,
      thousandsSeparator: " ",
    },
    {
      label: "Maximum advertising price",
      name: "maxPrice",
      cornerLabel: "₽",
      variant: "mask",
      mask: Number,
      thousandsSeparator: " ",
    },
    {
      label: "Price step value",
      name: "stepPrice",
      cornerLabel: "₽",
      variant: "mask",
      mask: Number,
      thousandsSeparator: " ",
      disabled: true,
    },
    {
      label: "Steps frequency",
      name: "stepFrequency",
      variant: "custom",
      CustomInput: InputCounter,
    },
    {
      label: "Start advertising",
      name: "startAdvertisingDate",
      variant: "date",
    },
    {
      label: "End advertising",
      name: "endAdvertisingDate",
      variant: "date",
    },
    {
      label: "Initial commission",
      name: "initialCommission",
      type: "number",
      cornerLabel: "%",
    },
    {
      label: "Security payment",
      name: "securityPayment",
      type: "number",
      cornerLabel: "%",
    },
  ]

export default promotionChannelsContentFields
