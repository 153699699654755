const appraisalsHeadCells = [
  { id: "id", label: "ID", width: "5%" },
  { id: "folder_number", label: "Folder", width: "5%" },
  { id: "apartment", label: "Object", width: "28%" },
  { id: "rental_contract", label: "Number", width: "15%" },
  { id: "creation_date", label: "Creation date", width: "10%" },
  { id: "status", label: "Status", width: "15%" },
  { id: "max_price", label: "Max price", width: "11%" },
  { id: "min_price", label: "Min price", width: "11%" },
]

export default appraisalsHeadCells
