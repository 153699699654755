import React from "react"
import styled from "@emotion/styled"
import { Modal } from "kui-basic"
import { FullSliderModalProps } from "./types"
import FullSlider from "../FullSlider"

function FullSliderModal({
  isOpen,
  handleClose,
  ...sliderProps
}: FullSliderModalProps) {
  return (
    <StyledModal isOpen={isOpen} handleClose={handleClose}>
      <FullSlider handleClose={handleClose} {...sliderProps} />
    </StyledModal>
  )
}

export default FullSliderModal

const StyledModal = styled(Modal)`
  border-radius: unset;
  box-shadow: none;
  max-height: unset !important;
  width: 100%;
`
