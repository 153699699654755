import { rest } from "msw"
import {
  paymentSummaryDebtMock,
  paymentSummaryIds,
  paymentSummaryOverpayMock,
  paymentSummaryPaidMock,
} from "./data"
import { ApartmentPaymentSummaryModel } from "../../../../pages/ApartmentPage/pages/ExpensesPage/types/api/expensesSummaryAPI"

const apartmentPaymentsSummaryHandlers = [
  rest.get<ApartmentPaymentSummaryModel | Error>(
    `${process.env.REACT_APP_API_URL}/apartments/:id/expenses/bill-periods/:period_id/summary`,
    (req, resp, ctx) => {
      const apartmentId = Number(req.params.id)
      if (apartmentId === paymentSummaryIds.debt) {
        const data = ctx.json(paymentSummaryDebtMock)
        return resp(data)
      }
      if (apartmentId === paymentSummaryIds.overpay) {
        const data = ctx.json(paymentSummaryOverpayMock)
        return resp(data)
      }
      const data = ctx.json(paymentSummaryPaidMock)
      return resp(data)
    }
  ),
]

export default apartmentPaymentsSummaryHandlers
