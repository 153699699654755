import React from "react"
import styled from "@emotion/styled"
import { Caption } from "kui-basic"
import { ContractStatusProps, ContractStatusStylesProps } from "./types"
import { ContractStatusColor } from "./content"

const ContractStatus = (props: ContractStatusProps) => {
  const { status } = props
  const active = status !== "Closed" && status !== "Pending"

  if (!status) return null

  return (
    <StyledStatusWrapper active={active}>
      <Caption weight={500}>{status}</Caption>
    </StyledStatusWrapper>
  )
}

export default ContractStatus

const StyledStatusWrapper = styled.div<ContractStatusStylesProps>`
  padding: 4px 16px;
  border-radius: 8px;
  margin-left: 16px;
  background: ${({ active }) =>
    active
      ? ContractStatusColor.active.background
      : ContractStatusColor.notActive.background};
  p {
    color: ${({ active }) =>
      active
        ? ContractStatusColor.active.color
        : ContractStatusColor.notActive.color};
  }
`
