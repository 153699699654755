import React from "react"
import { observer } from "mobx-react"
import { useLocation } from "react-router-dom"
import { ResourceTypes } from "kui-crm"
import EntityPage from "../../components/common/EntityPage"
import useTariffStore from "./store"
import { Routes } from "../../types/route"
import TariffHeader from "./components/TariffHeader"
import TariffOverviewHeader from "./components/TariffOverviewHeader"
import TariffTabsPanel from "./components/TariffTabsPanel"

function TariffPage() {
  const tariffStore = useTariffStore()
  const resource = useLocation().pathname.match(
    new RegExp(`(?<=${Routes.tariffsGroups}/)\\w+`)
  )?.[0]

  const initTariffPage = (id: number) => {
    tariffStore.updateTariffStore()
    if (resource) {
      tariffStore.fetchTariffById(id, resource as ResourceTypes)
    }
  }

  return (
    <EntityPage store={tariffStore} initPage={initTariffPage}>
      <TariffHeader />
      <TariffOverviewHeader />
      <TariffTabsPanel />
    </EntityPage>
  )
}

export default observer(TariffPage)
