import _ from "lodash"
import {
  InputParams,
  CheckboxWithHint,
  NumberField,
  MeterTypes,
  ResourceTypes,
} from "kui-crm"

import {
  ElectricityValues,
  WaterValues,
} from "../../../../utils/content/listsOptions"
import { meterAutoSendingHint } from "../../../../utils/content/constants"

type MeterValueVariants = "last" | "initial"

const valueOptions = {
  variant: "custom",
  CustomInput: NumberField,
  maxIntegerPart: 7,
  maxDecimalPart: 3,
}

const getMeterOverviewInfoFields = (
  resource: ResourceTypes | null,
  isClosed: boolean,
  type?: MeterTypes
): InputParams<any>[] => [
  {
    label: "Auto-reading",
    name: "withAutoSending",
    variant: "custom",
    CustomInput: CheckboxWithHint,
    hintMessage: meterAutoSendingHint,
  },
  {
    label: "No tariff",
    name: "noTariff",
    variant: "custom",
    CustomInput: CheckboxWithHint,
    hintMessage:
      "Readings do not directly affect the cost, the expense must be entered from the receipt",
  },
  { variant: "empty", size: 6 },
  ...getMeterTypeField(resource),
  ...getMeterValuesFields(resource, "initial", type),
  { label: "Activation date", name: "startDate", variant: "date" },
  ...(isClosed
    ? []
    : [{ label: "Next check", name: "nextCheck", variant: "date" }]),
  ...(isClosed ? getMeterValuesFields(resource, "last", type) : []),
]

const getMeterTypeField = (resource: ResourceTypes | null) => {
  if (resource === "water" || resource === "electricity") {
    return [
      {
        label: "Type",
        name: "type",
        variant: "select",
        options: resource === "water" ? WaterValues : ElectricityValues,
      },
    ]
  }
  return []
}

const getMeterValuesFields = (
  resource: ResourceTypes | null,
  variant: MeterValueVariants,
  type?: MeterTypes
) => {
  const label = `${_.capitalize(variant)} value`
  const name = `${variant}Values`
  if (resource === "electricity") {
    const fields = [
      { label: `${label} T1`, name: `${name}.T1`, ...valueOptions },
    ]
    if (type !== "T1") {
      fields.push({ label: `${label} T2`, name: `${name}.T2`, ...valueOptions })
    }
    if (type === "T3") {
      fields.push({ label: `${label} T3`, name: `${name}.T3`, ...valueOptions })
    }

    return fields
  }
  return [{ label: `${label}`, name: `${name}.value`, ...valueOptions }]
}

export default getMeterOverviewInfoFields
