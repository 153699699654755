import React from "react"
import _ from "lodash"
import TableCell from "../../../../../../../../components/ui/TableCell"
import UserBadge from "../../../../../../../../components/common/UserBadge"
import { PartialPayerCellProps } from "./types"
import { UnitOfPaymentValues } from "../../../../../../../../utils/content/listsOptions"
import { matchesSubjectRoles } from "../../../../../../../../utils/content/matches"

const PartialPayerCell = (props: PartialPayerCellProps) => {
  const { mainPayer, refundPayer } = props
  const partTypeValue = UnitOfPaymentValues.find(
    ({ value }) => value === refundPayer?.partType
  )?.label
  const refundValue = refundPayer?.mainPayer
    ? `${refundPayer.refundValue}${partTypeValue} ${_.capitalize(
        matchesSubjectRoles[refundPayer.mainPayer!]
      )}`
    : _.capitalize(matchesSubjectRoles[refundPayer?.refundFrom!])

  return (
    <TableCell width="11%">
      {mainPayer ? (
        <UserBadge
          user={mainPayer}
          role={mainPayer.role!}
          weight={500}
          label={refundValue}
          onlyRole
        />
      ) : (
        "-"
      )}
    </TableCell>
  )
}

export default PartialPayerCell
