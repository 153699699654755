/** @jsxImportSource @emotion/react */
import * as React from "react"
import styled from "@emotion/styled"
import { Caption, CaptionColors } from "kui-basic"
import { TwoCaptionsBlockProps } from "./types"

function TwoCaptionsBlock({
  top,
  bottom,
  topWeight,
  topSize,
  bottomSize,
  bottomWeight,
  bottomColor,
  spacing,
  topTextColor,
  ...otherProps
}: TwoCaptionsBlockProps) {
  return (
    <StyledWrapper {...otherProps}>
      <Caption
        weight={topWeight}
        size={topSize}
        color={topTextColor as CaptionColors}
        data-testid="first_caption"
        css={{ marginBottom: spacing }}
      >
        {top}
      </Caption>
      <Caption
        data-testid="second_caption"
        size={bottomSize}
        weight={bottomWeight}
        color={bottomColor as CaptionColors}
      >
        {bottom}
      </Caption>
    </StyledWrapper>
  )
}
TwoCaptionsBlock.defaultProps = {
  topWeight: 600 as const,
  bottomWeight: 500 as const,
  topSize: "m" as const,
  bottomSize: "s" as const,
  spacing: 12,
  topTextColor: "seventy" as const,
  bottomColor: "fiftyP",
}

export default TwoCaptionsBlock

const StyledWrapper = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
`
