/** @jsxImportSource @emotion/react */
import React from "react"
import { css } from "@emotion/react"
import { Heading, Box } from "kui-basic"
import Page from "../Page"

type StylesProps = {
  height: string
}

const wrapperStyles = ({ height }: StylesProps) => css`
  height: ${height};
`

const containerStyles = ({ height }: StylesProps) => css`
  padding: 40px;
  height: ${height};
`

type ErrorComponentProps = {
  height?: string
  withPage?: boolean
}

// TODO убрать withPage
function ErrorComponent({ height, withPage }: ErrorComponentProps) {
  return withPage ? (
    <Page>
      <div css={containerStyles({ height: height! })}>
        <Heading>Something went wrong...</Heading>
        <Box mt={2}>
          <Heading size="hero">
            <span aria-label="sad emoji" role="img">
              😓
            </span>
          </Heading>
        </Box>
      </div>
    </Page>
  ) : (
    <div css={wrapperStyles({ height: height! })}>
      <Heading>Something went wrong...</Heading>
      <Box mt={2}>
        <Heading size="hero">
          <span aria-label="sad emoji" role="img">
            😓
          </span>
        </Heading>
      </Box>
    </div>
  )
}

ErrorComponent.defaultProps = {
  height: "100vh",
  withPage: true,
}

export default ErrorComponent
