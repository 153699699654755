export const getListMetro = [
  {
    id: 1,
    name: "Metro",
    geolocation: "",
    line: {
      id: 1,
      name: "Line",
      type: "",
      hex_color: "",
    },
    status: "",
  },
]
