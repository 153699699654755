import * as React from "react"
import { observer } from "mobx-react"
import { LoaderState } from "kui-crm"
import ExpensesPayoutsHeader from "../ExpensesPayoutsHeader"
import ExpensesClientsCalculation from "../ExpensesClientsCalculation"
import OperatingExpenses from "../OwnExpenses"
import useExpensesStore from "../../../../store"
import Container from "../../../../../../../../components/ui/Container"

function ExpensesSummaryCalculations() {
  const { summaryStore } = useExpensesStore()
  const { payouts, loader } = summaryStore.payoutsStore

  return (
    <Container>
      <ExpensesPayoutsHeader />
      <div>
        {payouts?.map((payout) => (
          <ExpensesClientsCalculation payouts={payout} />
        ))}
      </div>

      <OperatingExpenses />
      <LoaderState loader={loader} />
    </Container>
  )
}

export default observer(ExpensesSummaryCalculations)
