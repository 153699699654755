import { Modal } from "kui-crm"
import React from "react"
import { MeterReadingModalProps } from "./types"
import MeterReadingForm from "../../forms/meters/MeterReadingForm"
import { MeterReadingFormFields } from "../../forms/meters/MeterReadingForm/types"

const MeterReadingModal = (props: MeterReadingModalProps) => {
  const { meter, open, handleClose, createMeterReading, ...other } = props
  const label = `${meter ? "Edit" : "New"} meter reading`

  const handleSubmit = (data: MeterReadingFormFields) => {
    handleClose()
    if (meter) meter.editMeterReading(data)
    else if (createMeterReading) createMeterReading(data)
  }

  return (
    <Modal
      title={label}
      open={open}
      handleClose={handleClose}
      isScrollable
      data-testid="edit_service_modal"
    >
      <MeterReadingForm meter={meter} handleSubmit={handleSubmit} {...other} />
    </Modal>
  )
}

export default MeterReadingModal
