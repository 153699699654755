import React from "react"
import styled from "@emotion/styled"
import { DeepPartial, Path, useForm } from "react-hook-form"
import { UploadFilesFormProps } from "./types"
import UploadFiles from "../../../ui/UploadFilesForm"

function UploadFilesForm<T extends object>(props: UploadFilesFormProps<T>) {
  const { handleSubmit, defaultValues, onPrev, name, ...other } = props

  const form = useForm<T>({
    // @ts-ignore
    defaultValues: {
      [name as Path<T>]: defaultValues,
    } as DeepPartial<T>,
  })

  return (
    <StyledUploadFiles
      name={name!}
      form={form as any}
      onPrev={onPrev}
      handleSubmit={handleSubmit}
      {...other}
    />
  )
}

UploadFilesForm.defaultProps = {
  name: "files",
}

export default UploadFilesForm

const StyledUploadFiles = styled(UploadFiles)`
  padding-top: 0;
`
