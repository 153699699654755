import React, { useState } from "react"
import { observer } from "mobx-react"
import { Grid } from "kui-basic"
import { useToggle } from "kui-utils"
import ApartmentPhotoModal from "../ApartmentPhotoModal"
import ApartmentPhotoSlider from "../ApartmentPhotoSlider"
import ApartmentImageStore from "../../../../../store/content/photo/ApartmentImage"
import ApartmentPhotoDragCard from "../ApartmentPhotoDragCard"
import PhotoContainer from "../../../../../../../../../components/common/PhotoContainer"
import usePromotionStore from "../../../../../store"
import useApartmentStore from "../../../../../../../store"

function ApartmentPreviewPhoto() {
  const { isArchived } = useApartmentStore()
  const {
    contentStore: { imagesStore },
  } = usePromotionStore()
  const [selectedImage, setSelectedImage] = useState(0)
  const [isModalOpen, handleModalOpen, handleModalClose] = useToggle()
  const [isSliderOpen, handleSliderOpen, handleSliderClose] = useToggle()
  const disabled = isArchived

  const hasError =
    imagesStore.selectedImages.length + imagesStore.previewImages.length >
    imagesStore.previewImagesLimit

  const handleImageClick = (index: number) => {
    setSelectedImage(Number(index))
    handleSliderOpen()
  }

  const handleImageDelete = (image: ApartmentImageStore) => {
    image.makeNotPreview()
  }

  return (
    <>
      <PhotoContainer
        label="Preview photo"
        handleClick={handleModalOpen}
        disabled={imagesStore.previewExceededLimit || disabled}
      >
        <Grid container spacing={3}>
          {imagesStore.previewImages.map((image, index: number) => (
            <ApartmentPhotoDragCard
              isMain={image.isMain}
              isPreview
              key={image.id}
              image={image}
              index={index}
              onClick={handleImageClick}
              onDelete={handleImageDelete}
              disabled={disabled}
            />
          ))}
        </Grid>
      </PhotoContainer>
      {isSliderOpen && (
        <ApartmentPhotoSlider
          active={selectedImage}
          handleClose={handleSliderClose}
          isOpen={isSliderOpen}
          images={imagesStore.previewImages}
          disabled={disabled}
        />
      )}
      <ApartmentPhotoModal
        showMainButton={imagesStore.selectedImages.length === 1}
        variant="preview"
        open={isModalOpen}
        handleClose={handleModalClose}
        images={imagesStore.nonPreviewImages ?? []}
        errorMessage={
          hasError && "There must be no more than six preview pictures"
        }
      />
    </>
  )
}

export default observer(ApartmentPreviewPhoto)
