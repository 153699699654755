import { ResourceTypes, PostTariffRequest } from "kui-crm"
import requests from "./index"

const TariffsAgent = {
  all: (tariff: ResourceTypes, offset: number, limit: number, params: string) =>
    requests.get(
      `/tariffs/${tariff}?ordering=region&offset=${offset}&limit=${limit}${params}`
    ),
  getById: (id: number, tariff: ResourceTypes) =>
    requests.get(`/tariffs/${tariff}/${id}`),
  createTariff: (data: PostTariffRequest, tariff: ResourceTypes) =>
    requests.post(`/tariffs/${tariff}`, data),
  delete: (id: number, tariff: ResourceTypes) =>
    requests.delete(`/tariffs/${tariff}/${id}`),
  getConnectedCompanies: (id: number, tariff: ResourceTypes) =>
    requests.get(`/tariffs/${tariff}/${id}/companies_use`),
  getRegions: () => requests.get(`/tariffs/regions/`),
}

export default TariffsAgent
