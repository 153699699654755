import React from "react"
import { observer } from "mobx-react"
import MultistepForm from "../../../common/MultistepForm"
import ClientTypeStep from "../ClientTypeStep"
import ClientInfoStep from "../ClientInfoStep"
import ClientDocumentsStep from "../ClientDocumentsStep"
import { ClientCreationFormProps } from "./types"
import useResetMultistepForm from "../../../../hooks/useResetMultistepForm"

const ClientCreationForm = (props: ClientCreationFormProps) => {
  const { creationForm, handleSubmit } = props

  useResetMultistepForm(creationForm)

  return (
    <MultistepForm form={creationForm}>
      <ClientTypeStep formStore={creationForm} />
      <ClientInfoStep formStore={creationForm} />
      <ClientDocumentsStep
        formStore={creationForm}
        handleSubmit={handleSubmit}
      />
    </MultistepForm>
  )
}

export default observer(ClientCreationForm)
