import React from "react"
import { Button, Grid } from "kui-basic"
import styled from "@emotion/styled"
import { CalendarWithRangeHeaderProps, CurrentPeriodStylesProps } from "./types"
import CalendarMonth from "../CalendarMonth"
import CalendarYear from "../CalendarYear"
import CalendarHeaderControllers from "../CalendarHeaderControllers"

const CalendarWithRangeHeader = (props: CalendarWithRangeHeaderProps) => {
  const { isCurrentSelected, setIsCurrentSelected, ...other } = props

  return (
    <StyledWrapper>
      <Grid container alignItems="center" justify="space-between" wrap="nowrap">
        <Grid container alignItems="center" wrap="nowrap">
          <CalendarMonth {...other} />
          <CalendarYear {...other} />
        </Grid>
        <CalendarHeaderControllers {...other} />
      </Grid>

      <StyledButton
        isCurrentSelected={isCurrentSelected}
        variant={isCurrentSelected ? "primary" : "white"}
        onClick={setIsCurrentSelected}
      >
        current period
      </StyledButton>
    </StyledWrapper>
  )
}

export default CalendarWithRangeHeader

const StyledWrapper = styled.div`
  text-align: left;
  padding-bottom: 24px;
`

const StyledButton = styled(Button)<CurrentPeriodStylesProps>`
  height: 26px;
  border-radius: 8px;
  margin-top: 8px;
  padding: 3px 8px;
  p {
    color: ${({ theme, isCurrentSelected }) =>
      isCurrentSelected
        ? theme.palette.grey.zero
        : theme.palette.grey.sixty} !important;
  }
`
