import { createContext, useContext } from "react"
import CompaniesStore from "./CompaniesStore"

const companiesStore = new CompaniesStore()
const CompaniesContext = createContext(companiesStore)

const useCompaniesStore = () => useContext(CompaniesContext)

export default useCompaniesStore
export { companiesStore }
