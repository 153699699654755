import React from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Grid, Box } from "kui-basic"
import { InputByType, FormBottom } from "kui-crm"
import { ClientPersonalStepSchema, ClientCompanyStepSchema } from "./schema"
import { ClientInfoStepProps, ClientPersonalFormFields } from "./types"
import { clientPersonalStepFields, clientCompanyStepFields } from "./fields"
import { InputParams } from "../../../common/InputsGroupWithTitle/types"
import { ClientCreationFields } from "../../../modals/ClientCreationModal/types"

const ClientTypeVariants = {
  personal: {
    fields: clientPersonalStepFields,
    schema: ClientPersonalStepSchema,
  },
  company: {
    fields: clientCompanyStepFields,
    schema: ClientCompanyStepSchema,
  },
}

const ClientInfoStep = ({ formStore }: ClientInfoStepProps) => {
  const clientType = formStore.fields?.type || "personal"
  const clientParams = ClientTypeVariants[clientType]
  const form = useForm<ClientPersonalFormFields>({
    resolver: yupResolver(clientParams.schema),
  })

  const handleSubmit = (data: ClientPersonalFormFields) => {
    const { updateFormFields, nextStep } = formStore

    updateFormFields(data)
    nextStep()
  }

  return (
    <form data-testid="create_user_form">
      <Box pr={5} pl={5} mb={3}>
        <Grid container spacing={2}>
          {clientParams.fields.map(
            (field: InputParams<ClientCreationFields>) => (
              <Grid item xs={12} key={field.name}>
                <InputByType form={form} {...field} />
              </Grid>
            )
          )}
        </Grid>
      </Box>
      <FormBottom
        withPrevStep
        label="Next"
        variant="next"
        handlePrevClick={formStore.prevStep}
        type="button"
        handleClick={form.handleSubmit(handleSubmit)}
      />
    </form>
  )
}

export default ClientInfoStep
