import React from "react"
import { useForm } from "react-hook-form"
import { Grid } from "kui-basic"
import { observer } from "mobx-react"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormWrapper, InputByType } from "kui-crm"
import {
  getMetersInfoStepFields,
  MetersInfoStepSchema,
  MetersInfoStepFields,
} from "kui-crm_actions"
import ModalLabel from "../../../../../../common/ModalLabel"
import useApartmentLiteStore from "../../../../../../../store/shared/apartment"

const MetersInfoStep = () => {
  const { inspectionsStore, descriptionStore } = useApartmentLiteStore()
  const formStore = inspectionsStore.creationForm
  const form = useForm<MetersInfoStepFields>({
    defaultValues: {
      ...descriptionStore.fields,
      ...formStore.fields,
    },
    resolver: yupResolver(MetersInfoStepSchema),
  })
  const fields = getMetersInfoStepFields()

  const handleSubmit = (data: MetersInfoStepFields) => {
    formStore.updateFormFields(data)
    formStore.nextStep()
  }

  return (
    <>
      <ModalLabel label="How many metering devices can there be" />
      <FormWrapper
        form={form}
        onSubmit={form.handleSubmit(handleSubmit)}
        withPrevStep
        handlePrevClick={formStore.prevStep}
        variant="next"
        label="Next"
      >
        <Grid container spacing={2}>
          {fields.map((field) => (
            <Grid item xs={6} key={field.name}>
              <InputByType form={form} {...field} />
            </Grid>
          ))}
        </Grid>
      </FormWrapper>
    </>
  )
}

export default observer(MetersInfoStep)
