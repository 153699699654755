import React from "react"
import { Box } from "kui-basic"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormBottom } from "kui-crm"
import { NextArrowIcon } from "kui-icon"
import ApartmentAutocomplete from "../../../../entityInputs/ApartmentAutocomplete"
import { MeterApartmentFormFields, MeterApartmentFormProps } from "./types"
import MeterApartmentFormSchema from "./schema"
import { ApartmentLiteParams } from "../../../../../types/store/apartments"

function MeterApartmentForm(props: MeterApartmentFormProps) {
  const { formStore, metersStore } = props
  const { fetchApartmentResources, actionLoader } = metersStore
  const form = useForm<MeterApartmentFormFields>({
    defaultValues: formStore.fields as MeterApartmentFormFields,
    resolver: yupResolver(MeterApartmentFormSchema),
  })

  const handleSubmit = async (data: MeterApartmentFormFields) => {
    await fetchApartmentResources(data.apartment.id)
    formStore.updateFormFields(data)
    formStore.nextStep()
  }

  const handleSelect = (apartment: ApartmentLiteParams) => {
    form.setValue("apartment.maxMetersCount", apartment.maxMetersCount)
    form.setValue("apartment.metersPayers", apartment.metersPayers)
    form.setValue("apartment.operatingAccounts", apartment.operatingAccounts)
    form.setValue(
      "apartment.administrativeCompany",
      apartment.administrativeCompany
    )
  }

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)}>
      <Box pr={5} pl={5} mb={3}>
        <ApartmentAutocomplete
          label="Object"
          form={form}
          onSelect={handleSelect}
        />
      </Box>
      <FormBottom
        isLoading={actionLoader.isLoading}
        label="Next"
        endIcon={<NextArrowIcon />}
      />
    </form>
  )
}

export default MeterApartmentForm
