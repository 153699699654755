import React from "react"
import { useNavigate } from "react-router-dom"
import { observer } from "mobx-react"
import { Modal } from "kui-crm"
import { CreateModalProps } from "../../../../types/ui/modal"
import CreateRentalContractForm from "../../forms/CreateRentalContractForm"
import { Routes } from "../../../../types/route"
import useRCsStore from "../../store"
import { RentalContractCreationFields } from "../../forms/CreateRentalContractForm/types"

function RentalContractCreationModal({
  isOpen,
  handleClose,
}: CreateModalProps) {
  const { createRentalContract, creationForm } = useRCsStore()
  const navigate = useNavigate()

  const handleSubmit = async (data: RentalContractCreationFields) => {
    handleClose()
    const rcId = await createRentalContract(data)

    if (rcId) {
      navigate(`${Routes.rental_contracts}/${rcId}`)
    }

    creationForm.resetForm()
  }

  return (
    <Modal open={isOpen} handleClose={handleClose} title="New rental contract">
      <CreateRentalContractForm handleSubmit={handleSubmit} />
    </Modal>
  )
}

export default observer(RentalContractCreationModal)
