import React from "react"
import { Grid, GridProps } from "kui-basic"
import styled from "@emotion/styled"
import Container from "../Container"

function PageFooter(props: GridProps) {
  return (
    <StyledFooter>
      <Container>
        <StyledContent
          container
          spacing={3}
          justify="space-between"
          {...props}
        />
      </Container>
    </StyledFooter>
  )
}

export default PageFooter

const StyledFooter = styled.div`
  padding: 12px 0;
  border-radius: 12px 0 12px 0;
  position: fixed;
  width: 100vw;
  background-color: ${({ theme }) => theme.palette.background.light1};
  bottom: 0;
  z-index: 5;
`

const StyledContent = styled(Grid)`
  padding: 0 12px;
`
