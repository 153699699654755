import { PostCompaniesRequest, CompanyByTinRequestBody } from "kui-crm/types"
import requests from "./index"

const CompaniesAgent = {
  all: (offset: number, limit: number, params: string) =>
    requests.get(`/companies?limit=${limit}&offset=${offset}${params}`),
  create: (data: PostCompaniesRequest) => requests.post("/companies", data),
  createLogo: (companyId: number, logo: FormData) =>
    requests.put(`/companies/${companyId}/logo`, logo),
  findCompanyByINN: (body: CompanyByTinRequestBody) =>
    requests.post("/companies/info-by-new-tin", body),
}

export default CompaniesAgent
