import React, { useEffect, useRef, useState } from "react"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import { Button } from "kui-basic"
import { css } from "@emotion/react"
import { ObjectForMapOverlay, ObjectForMapParams } from "kui-crm_actions"
import { InspectionsMapProps, MapStylesProps } from "./types"
import SmallScreenIcon from "../../../../../../components/icons/SmallScreenIcon"
import FullScreenIcon from "../../../../../../components/icons/FullScreenIcon"
import { DynamicRoutes } from "../../../../../../types/route"

const InspectionsMap = <T extends ObjectForMapParams>(
  props: InspectionsMapProps<T>
) => {
  const { objects, getLabel } = props
  const [isFullMap, setIsFullMap] = useState(false)
  const [map, setMap] = useState<google.maps.Map>()
  const mapRef = useRef<HTMLDivElement>(null)
  const centerMoscow = { lat: 55.753559, lng: 37.609218 }

  const toggleFullMap = () => setIsFullMap(!isFullMap)

  useEffect(() => {
    if (mapRef.current) {
      const initialMap = new window.google.maps.Map(mapRef.current, {
        center: centerMoscow,
        zoom: 10,
        disableDefaultUI: true,
        clickableIcons: false,
      })
      setMap(initialMap)
    }
  }, [mapRef])

  return (
    <StyledWrapper isFullSize={isFullMap}>
      <StyledMapWrapper ref={mapRef} id="map">
        {objects.map((object) => (
          <ObjectForMapOverlay
            label={getLabel(object)}
            map={map}
            inspection={object}
            key={object.id}
            getClientLink={DynamicRoutes.client}
            getInspectionLink={DynamicRoutes.inspections}
          />
        ))}
      </StyledMapWrapper>
      <StyledMapSizeButton
        isCircle
        variant="whiteWithGray"
        size="xs"
        onClick={toggleFullMap}
      >
        {isFullMap ? <SmallScreenIcon /> : <FullScreenIcon />}
      </StyledMapSizeButton>
    </StyledWrapper>
  )
}

export default observer(InspectionsMap)

const fullMapStyles = ({ isFullSize }: MapStylesProps) =>
  isFullSize &&
  css`
    position: absolute;
    top: 152px;
    left: 0;
    height: calc(100vh - 152px);
    width: 100%;
    > div {
      border-radius: 0;
    }
  `

const StyledWrapper = styled.div<MapStylesProps>`
  position: relative;
  overflow: hidden;
  height: 450px;
  transition: all ease-out 0.3s;
  ${fullMapStyles};
`

const StyledMapWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 12px;
`

const StyledMapSizeButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 16px;
  border-radius: 8px;
  border-color: transparent;
  box-shadow: 0px 12px 16px 0px rgba(0, 0, 0, 0.08);
  z-index: 1;
`
