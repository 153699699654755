import React from "react"
import styled from "@emotion/styled"
import Header from "../Header"
import Container from "../../ui/Container"
import { PageProps } from "./types"

function Page(props: PageProps) {
  const { children, withContainer, className } = props

  return (
    <StyledWrapper>
      <Header />
      {withContainer ? (
        <StyledContentBlock className={className}>
          <Container>{children}</Container>
        </StyledContentBlock>
      ) : (
        <StyledContentBlock>{children}</StyledContentBlock>
      )}
    </StyledWrapper>
  )
}

Page.defaultProps = {
  withContainer: true,
}

export default Page

const StyledContentBlock = styled.main`
  flex-grow: 1;
  background-color: ${({ theme }) => theme.palette.grey.zero};
  min-height: calc(100vh - 63px);
  overflow: visible;
  display: flex;
  flex-direction: column;
`

const StyledWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.grey.seventy};
`
