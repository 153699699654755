import React from "react"
import { observer } from "mobx-react"
import { useToggle } from "kui-utils"
import { LoaderState } from "kui-crm"
import ErrorBoundary from "../ErrorBoundary"
import SearchBlock from "../SearchBlock"
import { PaginationPageProps } from "./types"
import usePaginationPage from "../../../hooks/usePaginationPage"

function PaginationPageWithSearch(props: PaginationPageProps) {
  const { store, label, children } = props
  const [isModalOpen, handleModalOpen, handleModalClose] = useToggle()
  const { fetchAll, paginator, filter, loader, creationLoader } = store
  const modal = children[1]

  usePaginationPage(paginator, fetchAll, filter)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    filter.updateSearchQuery(e.currentTarget.value)
    if (e.target.value.length === 0) {
      paginator.clearOffset()
      paginator.startFetching()
    }
  }

  return (
    <ErrorBoundary>
      <SearchBlock
        label={label}
        buttonHandler={handleModalOpen}
        onChange={handleChange}
        value={filter.searchQuery}
      />
      {children[0]}
      {modal({
        open: isModalOpen,
        handleClose: handleModalClose,
      })}

      <LoaderState loader={creationLoader} />
      <LoaderState loader={loader} onlyError />
    </ErrorBoundary>
  )
}

export default observer(PaginationPageWithSearch)
