import styled from "@emotion/styled"
import { GridTableBodyProps } from "./types"

const GridTableBody = styled.div<GridTableBodyProps>`
  max-width: 1344px;
  margin: 0 auto;
  .GridRow {
    padding-right: 24px;
    padding-left: 24px;
    border-radius: 8px;
    > div:not(:first-child) {
      padding-left: ${({ withPadding }) => (withPadding ? 22 : 0)}px;
    }
  }
  .GridRow:nth-child(even) {
    background: ${({ theme }) => theme.palette.background.light2};
  }
`

export default GridTableBody
