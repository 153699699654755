import React from "react"
import styled from "@emotion/styled"
import { InputDropdown } from "kui-complex"
import Loading from "../../common/Loading"
import { DropdownProps, OptionParams } from "./types"
import CreatingEntityFromDropdown from "./CreatingEntityFromDropdown"
import AutocompleteOption from "./AutocompleteOption"

function Dropdown<T extends OptionParams>(props: DropdownProps<T>) {
  const { options, renderOption, creatingLabel, renderCreatingModal } = props
  const { inputRef, isOpenDropdown, isLoading, input } = props
  const { handleCloseDropdown, handleSelect, handleScrollEnd } = props

  const handleScroll = (e: any) => {
    if (
      e.target.scrollHeight - (e.target.scrollTop + e.target.offsetHeight) <
      100
    ) {
      if (handleScrollEnd) handleScrollEnd()
    }
  }

  return (
    <StyledDropdown
      input={input}
      buttonRef={inputRef}
      isOpen={isOpenDropdown}
      handleClose={handleCloseDropdown}
      onScroll={handleScroll}
      endComponent={
        <CreatingEntityFromDropdown
          creatingLabel={creatingLabel}
          renderCreatingModal={renderCreatingModal}
        />
      }
    >
      {options.map((option, key: number) => (
        <AutocompleteOption
          key={key}
          option={option}
          renderOption={renderOption}
          handleSelect={handleSelect}
          index={key}
        />
      ))}
      {isLoading && <StyledLoading height="auto" />}
    </StyledDropdown>
  )
}

export default Dropdown

const StyledDropdown = styled(InputDropdown)`
  width: 100%;
  > div {
    width: 100%;
    > div {
      width: 100%;
      > div {
        width: 100%;
      }
    }
  }
  .KUI-InputDropdown_scroll {
    max-height: 142px;
    padding: 0 4px;
  }
`

const StyledLoading = styled(Loading)`
  position: sticky;
  bottom: 30px;
`
