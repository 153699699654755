import { createContext, useContext } from "react"
import ServiceContractsStore from "./ServiceContractsStore"

const SCsStore = new ServiceContractsStore()
const SCsContext = createContext(SCsStore)

const useSCsStore = () => useContext(SCsContext)

export default useSCsStore
export { SCsStore }
