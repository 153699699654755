import React from "react"
import { Caption, Grid } from "kui-basic"
import styled from "@emotion/styled"
import { Link, PreviewContent } from "kui-crm"
import { DynamicRoutes } from "../../../../../../types/route"
import { PendingImpairmentsProps } from "./types"
import { defaultApartmentImageURL } from "../../../../../../utils/content/constants"

const PendingImpairmentsRow = (props: PendingImpairmentsProps) => {
  const { impairment } = props
  const { apartment, damagesCount, wearCount, date } = impairment
  const objectLink = DynamicRoutes.inspection(apartment.id, impairment.id)
  const label =
    !damagesCount && !wearCount
      ? "Empty"
      : `${damagesCount} damages and ${wearCount} wear&tear`

  return (
    <StyledLink href={objectLink}>
      <StyledRow container alignItems="center" justify="space-between">
        <StyledPreviewContent
          imageSrc={apartment.previewImage || defaultApartmentImageURL}
          description={apartment.address}
          label={
            <Caption size="xs" color="fiftyP">
              {label}
            </Caption>
          }
        />

        <Caption size="s" weight={500}>
          {date?.toFormat("dd.MM.yyyy")}
        </Caption>
      </StyledRow>
    </StyledLink>
  )
}

export default PendingImpairmentsRow

const StyledLink = styled(Link)`
  &:not(&:last-of-type) > div {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
  }
`

const StyledRow = styled(Grid)`
  padding: 8px 0;
  flex-wrap: nowrap;
`

const StyledPreviewContent = styled(PreviewContent)`
  max-width: calc(100% - 100px);
  margin-right: 8px;
  p {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
`
