import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { Paginator, SortingFilter, Loader, MultistepForm } from "kui-utils"
import {
  GetWaterMetersResponse,
  PostCommonMeterParams,
  WaterMeterModel,
} from "../../../types/api/meters"
import MeterStore from "./MeterStore"
import {
  MeterCreationFormFields,
  WaterMeterCreationFields,
} from "../../../components/forms/meters/MeterCreationFormFields/types"
import MetersAgent from "../../../agent/Meters"
import { getCommonMeterParams } from "../../../utils/service/apiMapper"

class WaterMetersStore {
  meters: MeterStore[]

  paginator: Paginator

  filter: SortingFilter

  loader: Loader

  creationLoader: Loader

  creationForm: MultistepForm<MeterCreationFormFields>

  constructor() {
    this.meters = []
    this.loader = new Loader()
    this.creationLoader = new Loader()
    this.paginator = new Paginator()
    this.filter = new SortingFilter("id")
    this.creationForm = new MultistepForm<MeterCreationFormFields>()
    makeAutoObservable(this)
  }

  fetchAll = async () => {
    this.loader.startLoading()

    const [err, res] = await to<GetWaterMetersResponse>(
      MetersAgent.all(
        "water",
        this.paginator.offset,
        this.paginator.limit,
        this.filter.filterParams
      )
    )
    runInAction(() => {
      if (!err && res) {
        const mapper = (meter: WaterMeterModel) =>
          new MeterStore(meter, "water")

        this.meters = this.paginator.getPageResponse<
          WaterMeterModel,
          MeterStore
        >(res, this.meters, mapper)
      } else {
        this.loader.setError("fetch water meters")
      }
      this.loader.endLoading()
    })
  }

  createMeter = async (data: WaterMeterCreationFields) => {
    this.creationLoader.startLoading()

    const body = WaterMetersStore.getCreationBody(data)

    const [err, res] = await to(MetersAgent.createMeter(body, "water"))
    runInAction(() => {
      if (!err && res) {
        this.meters = [...this.meters, new MeterStore(res, "water")]
      } else {
        this.creationLoader.setError("water meter creation")
      }
      this.creationLoader.endLoading()
    })
  }

  static getCreationBody = (data: WaterMeterCreationFields) => ({
    apartment: data.apartment?.id,
    initial_value: data.initialValue,
    ...(getCommonMeterParams(data) as Omit<PostCommonMeterParams, "apartment">),
    ...(data.replaceableCounter && {
      replaceable_counter: {
        counter: Number(data.replaceableCounter),
        last_value: data.lastValue!,
      },
    }),
  })
}

export default WaterMetersStore
