import React from "react"
import { Box, BoxSizing, Caption, Grid } from "kui-basic"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import { Link } from "kui-crm"
import { css } from "@emotion/react"
import {
  ApartmentFillingInfoProps,
  FillingCardStylesProps,
  FillingStylesProps,
} from "./types"
import { maxWidth } from "../../../../../utils/service/theme"
import { FillingParams } from "../ApartmentFillingCard/types"
import ApartmentFillingStatus from "../ApartmentFillingStatus"
import TextOverflow from "../../../../common/TextOverflow"

function ApartmentFillingInfo<T extends FillingParams>(
  props: ApartmentFillingInfoProps<T>
) {
  const { filling, disabled } = props
  const hasBottomContent =
    !!filling.serialNumber ||
    !!filling.instruction ||
    !!filling.numberOfSubjects ||
    !!filling.description

  return (
    <StyledWrapper container>
      <StyledCheckWrapper container alignItems="center" wrap="nowrap">
        <ApartmentFillingStatus disabled={disabled} {...props} />
        <StyledTagWrapper baseline={hasBottomContent}>
          <Caption data-testid="filling_type" weight={500}>
            {filling.tag.name}
          </Caption>
          {filling.serialNumber && (
            <StyledSeries data-testid="filling_series" size="xs" color="fourty">
              {filling.serialNumber}
            </StyledSeries>
          )}
        </StyledTagWrapper>
      </StyledCheckWrapper>
      <StyledTitleWrapper baseline={hasBottomContent}>
        <Box mb={(filling.serialNumber ? 2 : 0) as BoxSizing}>
          <StyledLabel wasMissing={filling.isMissing} size="s">
            {filling.name || "-"}
          </StyledLabel>
        </Box>
        <Grid container>
          {Number(filling.numberOfSubjects) > 0 && (
            <StyledFillingCount weight={600} size="s">
              {filling.numberOfSubjects} pieces
            </StyledFillingCount>
          )}
          {!!filling.instruction && (
            <StyledLink href={filling.instruction.url} openInNewWindow>
              <Caption colorGroup="brand" color="main" size="s" weight={600}>
                INSTRUCTIONS
              </Caption>
            </StyledLink>
          )}
          {!!filling.description && (
            <TextOverflow size="s" weight={500} color="fiftyP">
              {filling.description}
            </TextOverflow>
          )}
        </Grid>
      </StyledTitleWrapper>
    </StyledWrapper>
  )
}

export default observer(ApartmentFillingInfo)

const StyledWrapper = styled(Grid)`
  align-items: stretch;
  align-content: stretch;
`

const FullHeightWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const StyledTagWrapper = styled(FullHeightWrapper)<FillingStylesProps>`
  max-width: 100%;
  padding-right: 16px;
  align-self: ${({ baseline }) => (baseline ? "baseline" : "center")};
  ${maxWidth("sm")} {
    width: calc(100% - 48px);
    padding-right: 0;
  }
`

const StyledCheckWrapper = styled(Grid)`
  width: 185px;
`

const StyledTitleWrapper = styled(FullHeightWrapper)<FillingStylesProps>`
  width: calc(100% - 185px - 360px - 32px);
  min-width: 495px;
  min-height: ${({ baseline }) => (baseline ? "56px" : "unset")};
  align-self: ${({ baseline }) => (baseline ? "baseline" : "center")};
  @media (max-width: 1199px) {
    width: calc(100% - 185px - 72px - 32px);
  }
  ${maxWidth("sm")} {
    min-width: unset;
    width: 100%;
  }
`

const StyledSeries = styled(Caption)`
  margin-top: 14px;
`

const StyledFillingCount = styled(Caption)`
  margin-right: 14px;
`

const StyledLink = styled(Link)`
  width: fit-content;
  margin-right: 14px;
`

const withCrossLine = ({ wasMissing, theme }: FillingCardStylesProps) =>
  wasMissing &&
  css`
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0px;
      height: 1px;
      width: 100%;
      background: ${theme?.palette.red.fiftyP};
    }
  `

const StyledLabel = styled(Caption)<FillingCardStylesProps>`
  position: relative;
  width: fit-content;
  ${withCrossLine};
`
