import { PartialPayerFields } from "kui-crm"

const normalizePayers = (payers: PartialPayerFields[]): PartialPayerFields[] =>
  payers.map((payer) =>
    payer.mainPayer
      ? payer
      : {
          ...payer,
          partType: null,
          refundValue: null,
          payer: payer.refundFrom,
        }
  )

export { normalizePayers }
