import React from "react"
import { observer } from "mobx-react"
import SortingTable from "../../../../components/common/SortingTable"
import useRCsStore from "../../store"
import clientsHeadCellsParams from "./headParams"
import RentalContractRow from "../RentalContractRow"

function RentalContractsTable() {
  const rcStore = useRCsStore()

  return (
    <SortingTable
      withOverlaps
      headParams={clientsHeadCellsParams}
      store={rcStore}
    >
      {rcStore.contracts.map((contract, key) => (
        <RentalContractRow
          key={contract.id}
          contract={contract}
          isDark={key % 2 !== 0}
        />
      ))}
    </SortingTable>
  )
}

export default observer(RentalContractsTable)
