const companyObjectsHeadCellsParams = [
  { id: "id", label: "ID", width: "5%" },
  { id: "address", label: "Address", width: "32%" },
  { id: "next_check", label: "Next check", width: "52.5%" },
]

const companyOtherCellsParams = [{ label: "Select", width: "11%" }]

export default companyObjectsHeadCellsParams
export { companyOtherCellsParams }
