import React, { useEffect } from "react"
import { observer } from "mobx-react"
import EntityPage from "../../components/common/EntityPage"
import useCompanyStore from "./store"
import CompanyHeader from "./components/common/CompanyHeader"
import CompanyOverviewHeader from "./components/common/CompanyOverviewHeader"
import CompanyTabsPanel from "./components/common/CompanyTabsPanel"

function CompanyPage() {
  const companyStore = useCompanyStore()

  useEffect(() => companyStore.updateCompanyPage(), [])

  return (
    <EntityPage store={companyStore} initPage={companyStore.fetchCompanyById}>
      <CompanyHeader />
      <CompanyOverviewHeader />
      <CompanyTabsPanel />
    </EntityPage>
  )
}

export default observer(CompanyPage)
