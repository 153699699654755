const clientsHeadCellsParams = [
  { id: "id", label: "ID", width: "5%" },
  { id: "last_name%2Cfirst_name%2Cmiddle_name", label: "Name", width: "18%" },
  { id: "role", label: "Role", width: "10%", withoutSort: true },
  {
    id: "apartment_address",
    label: "Object",
    width: "23%",
    withoutSort: true,
  },
  {
    id: "contract_number",
    label: "Contract",
    width: "14%",
    withoutSort: true,
  },
  { id: "email", label: "Email", width: "14%" },
  { id: "phone", label: "Phone", width: "15%" },
]

export default clientsHeadCellsParams
