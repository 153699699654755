import React from "react"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { Heading, Grid } from "kui-basic"
import useExpensesStore from "../../../../store"
import TableRowExpanses from "../TableRowExpanses"
import { maroomAvatar } from "../../../../../../../../utils/content/constants"

function OwnExpenses() {
  const { summaryStore } = useExpensesStore()
  const {
    payoutsStore: { ownExpansesStore },
  } = summaryStore

  if (!ownExpansesStore) return null

  return (
    <Grid container>
      <Grid item xs={6}>
        <StyledWrapper container alignItems="center">
          <StyledAvatar src={maroomAvatar} alt="maroom logo" />
          <Heading size="h4">Own expenses</Heading>
        </StyledWrapper>

        {ownExpansesStore.operatingExpenses?.map((item) => (
          <TableRowExpanses key={`${item.title}_${item.id}`} line={item} />
        ))}
      </Grid>
    </Grid>
  )
}

export default observer(OwnExpenses)

const StyledWrapper = styled(Grid)`
  padding-top: 24px;
  padding-bottom: 16px;
`

const StyledAvatar = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 12px;
`
