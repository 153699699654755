import React, { useEffect } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { Box, Grid } from "kui-basic"
import { observer } from "mobx-react"
import { FieldArrayPath } from "react-hook-form/dist/types"
import { yupResolver } from "@hookform/resolvers/yup"
import DocumentsBlockWithForm from "../../../../../components/common/DocumentsBlockWithForm"
import { ApartmentOwnershipFields } from "./types"
import ApartmentOwnersFields from "../../../components/tabs/ApartmentOwnershipTab/ApartmentOwnersFields"
import ApartmentProxiesFields from "../../../components/tabs/ApartmentOwnershipTab/ApartmentProxiesFields"
import useApartmentStore from "../../../store"
import ApartmentOwnershipFormSchema from "./schema"

function ApartmentOwnershipForm() {
  const { ownershipStore, overviewStore, editor } = useApartmentStore()

  const form = useForm<ApartmentOwnershipFields>({
    defaultValues: {
      owners: ownershipStore?.owners || [],
      proxies: ownershipStore?.proxies || [],
      cadastralNumber: ownershipStore?.cadastralNumber,
      documents: ownershipStore?.documentsStore.documents || [],
    },
    resolver: yupResolver(ApartmentOwnershipFormSchema),
  })
  const owners = useFieldArray<
    ApartmentOwnershipFields,
    FieldArrayPath<ApartmentOwnershipFields>,
    "key"
  >({
    control: form.control,
    name: "owners",
    keyName: "key",
  })
  const proxies = useFieldArray<
    ApartmentOwnershipFields,
    FieldArrayPath<ApartmentOwnershipFields>,
    "key"
  >({
    control: form.control,
    name: "proxies",
    keyName: "key",
  })

  const handleSubmit = (data: ApartmentOwnershipFields) => {
    if (overviewStore.id) {
      ownershipStore?.patchOwnershipInfo(overviewStore.id, data)
      editor.endEditing()
    }
  }

  useEffect(() => {
    if (editor.isAllowedToSendForm) {
      form.handleSubmit(handleSubmit, editor.blockToSendForm)()
    }
  }, [editor.isAllowedToSendForm])

  return (
    <Box pt={1}>
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={8}>
          <ApartmentOwnersFields
            fields={owners.fields}
            remove={owners.remove}
            appendOwner={owners.append}
            appendProxy={proxies.append}
            form={form}
          />
          <ApartmentProxiesFields
            fields={proxies.fields}
            remove={proxies.remove}
            form={form}
          />
        </Grid>
        <Grid item xs={4}>
          <DocumentsBlockWithForm
            form={form}
            disabled={!editor.isEditing}
            documents={ownershipStore?.documentsStore.documents}
            withScroll
            loader={ownershipStore?.documentsStore.loader}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default observer(ApartmentOwnershipForm)
