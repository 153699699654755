import React from "react"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { CustomMonthContentProps } from "./types"
import useExpensesStore from "../../../../store"

const CustomMonthContent = (props: CustomMonthContentProps) => {
  const { monthIndex, monthName } = props
  const { date, lastOpenPeriod } = useExpensesStore()

  if (
    date?.year !== lastOpenPeriod?.year ||
    monthIndex + 1 !== lastOpenPeriod?.month
  )
    return <>{monthName}</>

  return (
    <StyledWrapper>
      {monthName}

      <StyledIndicator />
    </StyledWrapper>
  )
}

export default observer(CustomMonthContent)

const StyledWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledIndicator = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  top: 6px;
  right: 6px;
  background: ${({ theme }) => theme.palette.blue.fiftyP};
`
