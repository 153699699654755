import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import Table from "../../../../../../components/ui/Table"
import TableHeadRow from "../../../../../../components/ui/TableHeadRow"
import CompanyTariffRow from "../CompanyTariffRow"
import {
  CompanyTariffsTableProps,
  CompanyTariffsTableStylesProps,
} from "../types"

const tableHeadCells = [
  { label: "Type", width: "15%" },
  { label: "Status", width: "18%" },
  { label: "Start date", width: "17%" },
  { label: "End date", width: "17%" },
]

function CompanyTariffsTable({
  title,
  tariffs,
  variant,
  type,
}: CompanyTariffsTableProps) {
  if (!type && tariffs.length === 0) {
    return null
  }

  return (
    <StyledTable variant={variant || "active"}>
      <TableHeadRow
        titleWidth="33%"
        title={title}
        cells={tableHeadCells}
        withButton={false}
      />
      <tbody>
        {tariffs?.map((tariff) => (
          <CompanyTariffRow key={tariff.id} tariff={tariff} />
        ))}
      </tbody>
    </StyledTable>
  )
}

export default CompanyTariffsTable

const disabledTableCSS = ({ theme, variant }: CompanyTariffsTableStylesProps) =>
  variant === "disable" &&
  css`
    p,
    h4,
    td {
      color: ${theme!.palette.grey.fiftyP} !important;
    }
  `

const StyledTable = styled(Table)`
  ${disabledTableCSS};
`
