import { InputParams } from "../../../../../../../components/common/InputsGroupWithTitle/types"
import { AppraisalInspectionFormFields } from "../types"

const managerValuationFields: InputParams<AppraisalInspectionFormFields>[] = [
  {
    label: "Min price",
    name: "managerValuation.minPrice",
    variant: "mask",
    mask: Number,
    thousandsSeparator: " ",
    size: 2,
    cornerLabel: "₽",
    isRequired: true,
  },
  {
    label: "Max price",
    name: "managerValuation.maxPrice",
    variant: "mask",
    mask: Number,
    thousandsSeparator: " ",
    size: 2,
    cornerLabel: "₽",
    isRequired: true,
  },
  {
    label: "Average price",
    name: "managerValuation.averagePrice",
    variant: "mask",
    mask: Number,
    thousandsSeparator: " ",
    size: 2,
    cornerLabel: "₽",
    disabled: true,
  },
  {
    label: "Advertising period",
    name: "managerValuation.advertisingPeriod",
    type: "number",
    size: 4,
  },
  { name: "empty1", variant: "empty", size: 2 },
  {
    label: "Recommendations",
    name: "managerValuation.recommendations",
    isTextArea: true,
    size: 4,
  },
  {
    label: "Owner's wishes",
    name: "managerValuation.ownerWishes",
    isTextArea: true,
    size: 4,
  },
  { name: "empty2", variant: "empty", size: 4 },
  {
    label: "Free of charge when preparing",
    name: "managerValuation.freeServices",
    isTextArea: true,
    size: 4,
  },
  {
    label: "Examples of tours and our objects",
    name: "managerValuation.examplesOfTours",
    isTextArea: true,
    size: 4,
  },
]

export default managerValuationFields
