/** @jsxImportSource @emotion/react */
import * as React from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

type AvatarSizes = "sm" | "md" | "lg"

type Props = {
  label: string | null | undefined
  size?: AvatarSizes
}

type AvatarProps = {
  size: AvatarSizes
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

function Avatar({ size, ...otherProps }: AvatarProps) {
  return <div {...otherProps} />
}

const avatarSizes = {
  sm: css`
    width: 32px;
    height: 32px;
    font-size: 12px;
  `,
  md: css`
    width: 40px;
    height: 40px;
    font-size: 14px;
  `,
  lg: css`
    width: 88px;
    height: 88px;
    font-size: 32px;
  `,
}

const StyledAvatar = styled(Avatar)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.brand.background};
  color: ${({ theme }) => theme.palette.brand.main};
  border-radius: 66px;
  font-weight: 600;
  line-height: 14px;
  ${(props) => avatarSizes[props.size]}
`

function UndefinedAvatar({ size, label, ...otherProps }: Props) {
  const formattedLabel = (label || "Unknown user")
    .split(" ")
    .map((el: string) => el[0])
    .slice(0, 3)
    .join("")
  return (
    <StyledAvatar size={size!} {...otherProps}>
      {formattedLabel}
    </StyledAvatar>
  )
}

UndefinedAvatar.defaultProps = {
  size: "sm" as const,
}

export default UndefinedAvatar
