import { useEffect, useState } from "react"

const useDashboardFilter = (filter: string, fetchAll?: () => Promise<void>) => {
  const [isFirstMount, setIsFirstMount] = useState(true)

  useEffect(() => {
    setIsFirstMount(false)

    return () => {
      setIsFirstMount(true)
    }
  }, [fetchAll, filter])

  useEffect(() => {
    if (!isFirstMount && fetchAll) fetchAll()
  }, [filter, fetchAll])
}

export default useDashboardFilter
