import * as yup from "yup"
import { isValidWithMaskExp } from "../../../../utils/content/regexp"

export const ClientPersonalStepSchema = yup.object().shape({
  firstName: yup.string().trim().required("This field is required"),
  phone: yup.object({
    phoneNumber: yup
      .string()
      .matches(isValidWithMaskExp, "Enter the correct phone number")
      .required("This field is required"),
  }),
  email: yup.string().trim().email(),
})

export const ClientCompanyStepSchema = yup.object().shape({
  companyName: yup.string().trim().required("This field is required"),
  firstName: yup.string().trim().required("This field is required"),
  phone: yup.object({
    phoneNumber: yup
      .string()
      .matches(isValidWithMaskExp, "Enter the correct phone number")
      .required("This field is required"),
  }),
  email: yup.string().trim().email(),
})
