import React, { ChangeEvent, ForwardedRef, forwardRef } from "react"
import { Controller, Path } from "react-hook-form"
import { ControllerRenderProps } from "react-hook-form/dist/types/controller"
import { FieldValues } from "react-hook-form/dist/types/fields"
import { Checkbox, Caption } from "kui-basic"
import { CheckboxWithControllerProps } from "./types"

const CheckboxWithController = forwardRef(
  <T extends object>(
    props: CheckboxWithControllerProps<T>,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const { form, name, label, labelProps, ...inputProps } = props

    const handleChange = (
      e: ChangeEvent<HTMLInputElement>,
      field: ControllerRenderProps<FieldValues, Path<T>>
    ) => {
      field.onChange(e)
      if (inputProps.onChange) {
        inputProps.onChange(e)
      }
    }

    return (
      <Controller
        control={form.control}
        name={String(name) as Path<T>}
        render={({ field }: any) => (
          <Checkbox
            {...inputProps}
            {...field}
            checked={field.value || false}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChange(e, field)
            }
            label={<Caption {...labelProps}>{label}</Caption>}
          />
        )}
      />
    )
  }
)

export default CheckboxWithController
