import { ServiceContractModel } from "kui-crm/types"

export const linkUserMock = {
  id: 1,
  email: "test@mail.ru",
  first_name: "Ivan",
  last_name: "Ivanov",
  middle_name: "Ivanovich",
  full_name: "Ivan Ivanov Ivanovich",
  phone: "79112003050",
}

export const linkApartmentMock = {
  id: 1,
  address: "",
  main_image: null,
  object_name: "",
}

export const serviceContractMock: ServiceContractModel = {
  id: 30,
  first_appraisal_interval_month: 6,
  apartment: linkApartmentMock,
  owner: linkUserMock,
  renter: linkUserMock,
  storage_id: 29,
  auto_prolongate: true,
  renter_notify_on_no_prolongate: false,
  agent_report_payment_status: "paid",
  inspection_interval_month: 3,
  notification_period: 3,
  archive: false,
  number: "02-09/2016",
  sign_date: "2020-10-02",
  start_date: "2016-09-18",
  end_date: "",
  status: "Open",
  apartment_phone: "",
  phone_paid_by: "owner",
  utilities_paid_by: "renter",
  beneficiary: "",
  taxes_type: "resident",
  initial_commission: "30.00",
  maintenance_commission: "10.00",
  contr_agent_company: "",
  penalty_in_preparation: "15000.00",
  penalty_with_rental: "100.00",
  advertising_period: "1",
  min_rent_price: 10000,
  max_rent_price: 30000,
  renter_payment_status: "paid",
  rental_type: "long",
  tax_residence: "resident",
  rental_contracts: [],
  closing_conditions: {
    have_closing_transfer_inspection: false,
    have_rental_contracts_closed: false,
    have_closing_inspection_date_eq_contract_end_date: false,
    have_closing_impairments: false,
    have_closed_last_period: false,
  },
}

export const serviceContracts = [
  {
    id: 14,
    apartment: linkApartmentMock,
    storage_id: null,
    auto_prolongate: true,
    renter_notify_on_no_prolongate: false,
    inspection_interval_month: 3,
    notification_period: 3,
    archive: false,
    number: "invalid-378",
    sign_date: "1970-01-01",
    start_date: "1990-01-01",
    end_date: null,
    apartment_phone: null,
    phone_paid_by: "owner",
    utilities_paid_by: "renter",
    beneficiary: null,
    taxes_type: "P",
    first_rent_payment_commission: "30.00",
    rent_percent_commission: "0.00",
    rent_deposit_percent: "100.00",
    contr_agent_company: null,
    penalty_in_preparation: "15000.00",
    penalty_with_rental: "100.00",
    min_rent_price: 10000,
    max_rent_price: 30000,
    renter_payment_status: "paid",
    rental_type: "long",
  },
  {
    id: 88,
    apartment: linkApartmentMock,
    storage_id: 545465,
    auto_prolongate: true,
    renter_notify_on_no_prolongate: false,
    inspection_interval_month: 20,
    notification_period: 20,
    archive: false,
    number: "fert34",
    sign_date: "2021-11-17",
    start_date: "2021-11-17",
    end_date: null,
    apartment_phone: null,
    phone_paid_by: "owner",
    utilities_paid_by: "owner",
    beneficiary: null,
    taxes_type: "P",
    first_rent_payment_commission: "20.00",
    rent_percent_commission: "20.00",
    rent_deposit_percent: "20.00",
    contr_agent_company: null,
    penalty_in_preparation: "20.00",
    penalty_with_rental: "20.00",
    min_rent_price: 10000,
    max_rent_price: 30000,
    renter_payment_status: "paid",
    rental_type: "long",
  },
]

export const fetchServiceContracts = {
  count: 2,
  next: null,
  previous: null,
  results: serviceContracts,
}

export const listServiceContracts = [
  {
    id: 14,
    number: "invalid-378",
    apartment: {
      id: 378,
      address: "13-я Парковая улица, 30 к1",
      rooms_number: 2,
      rental_contracts: [
        {
          id: 14,
          number: "invalid-378",
          start_date: "1990-01-01",
          end_date: null,
          renter: {
            id: 692,
            email: "oxaha2008@rambler.ru",
            first_name: "Оксана",
            last_name: "Геращенко",
            middle_name: "Николаевна",
            full_name: "Геращенко Оксана Николаевна",
            phone: "+79104457483",
          },
        },
        {
          id: 94,
          number: "564546",
          start_date: "2021-10-19",
          end_date: null,
          renter: {
            id: 672,
            email: "indbleta@yahoo.com",
            first_name: "Indrit",
            last_name: "Bleta",
            middle_name: "Shqiptare",
            full_name: "Bleta Indrit Shqiptare",
            phone: "+79258763941",
          },
        },
      ],
      owner: {
        id: 675,
        email: "nadya.petrushina@gmail.com",
        first_name: "Надежда",
        last_name: "Королькова",
        middle_name: "Владимировна",
        full_name: "Королькова Надежда Владимировна",
        phone: "+79262221218",
      },
      promotions: [],
    },
  },
  {
    id: 88,
    number: "fert34",
    apartment: {
      id: 431,
      address: "Байкальская улица, 30 корпус 2",
      rooms_number: 2,
      rental_contracts: [
        {
          id: 30,
          number: "02-09/2016-05",
          start_date: "2019-08-29",
          end_date: "2021-06-24",
          renter: {
            id: 785,
            email: "fochenkova@mail.ru",
            first_name: "Екатерина",
            last_name: "Фоченкова",
            middle_name: "Евгеньевна",
            full_name: "Фоченкова Екатерина Евгеньевна",
            phone: "+79060431723",
          },
        },
        {
          id: 86,
          number: "02-09/2016-06",
          start_date: "2021-07-15",
          end_date: null,
          renter: {
            id: 28,
            email: "catenok00@mail.ru",
            first_name: "Екатерина",
            last_name: "Максимова",
            middle_name: "Васильевна",
            full_name: "Максимова Екатерина Васильевна",
            phone: "+79373909792",
          },
        },
        {
          id: 102,
          number: ".klklkl",
          start_date: "2021-11-10",
          end_date: null,
          renter: null,
        },
      ],
      owner: {
        id: 776,
        email: "Alexei.mgtu@gmail.com",
        first_name: "Алексей",
        last_name: "Залогин",
        middle_name: "Александрович",
        full_name: "Залогин Алексей Александрович",
        phone: "+79262381018",
      },
      promotions: [
        {
          id: 28,
          current_promotion_price: "46000.00",
          status: "R",
          free_date: "2021-06-24",
        },
      ],
    },
  },
]

export const fetchListServiceContracts = {
  count: 2,
  next: null,
  previous: null,
  results: listServiceContracts,
}

export const serviceContractReject = {
  non_field_errors: ["Failed"],
}
