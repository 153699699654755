import React from "react"
import { useForm } from "react-hook-form"
import { Box } from "kui-basic"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormBottom, ChangeReasonFields, ChangeReasonFormFields } from "kui-crm"
import ChangingMeterReasonFormSchema from "./schema"
import { ChangingMeterReasonFormProps } from "./types"

function ChangingMeterReasonForm({
  handleSubmit,
}: ChangingMeterReasonFormProps) {
  const form = useForm<ChangeReasonFormFields>({
    resolver: yupResolver(ChangingMeterReasonFormSchema),
  })

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)}>
      <Box pl={5} pr={5} mb={3}>
        <ChangeReasonFields form={form} />
      </Box>
      <FormBottom label="Create meter" />
    </form>
  )
}

export default ChangingMeterReasonForm
