import React from "react"
import styled from "@emotion/styled"
import { Grid } from "kui-basic"
import { FilesWithDotProps } from "./types"
import FileLink from "../FileLink"

const FilesWithDot = (props: FilesWithDotProps) => {
  const { firstFile, secondFile, align } = props
  if (!firstFile.link && !secondFile.link) return null

  return (
    <StyledFilesWrapper
      container
      alignItems="center"
      justify={align || "center"}
    >
      <FileLink label={firstFile?.label} link={firstFile?.link} />
      {firstFile.link && secondFile.link && <FilesDivider />}
      <FileLink label={secondFile?.label} link={secondFile?.link} />
    </StyledFilesWrapper>
  )
}

export default FilesWithDot

const FilesDivider = styled.div`
  margin: 0 8px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.grey.thirty};
`

const StyledFilesWrapper = styled(Grid)`
  margin-top: 4px;
  text-align: left;
`
