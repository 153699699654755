/** @jsxImportSource @emotion/react */
import * as React from "react"
import { Grid } from "kui-basic"
import { TwoCaptionsWithImageBlockProps } from "./types"
import TwoCaptionsBlock from "../TwoCaptionsBlock"

function TwoCaptionsWithImageBlock({
  image,
  testId,
  ...captionsProps
}: TwoCaptionsWithImageBlockProps) {
  return (
    <Grid container alignItems="center" data-testid={testId}>
      <Grid item css={{ paddingRight: 12 }}>
        {image}
      </Grid>
      <Grid item>
        <TwoCaptionsBlock {...captionsProps} />
      </Grid>
    </Grid>
  )
}

export default TwoCaptionsWithImageBlock
