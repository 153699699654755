import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { Loader, Editor } from "kui-utils"
import HostStore from "../../../store/Root"
import CompanyOverviewStore from "./CompanyOverviewStore"
import { EntityStoreInterface } from "../../../types/store/entity"
import CompanyOfficesStore from "./CompanyOfficesStore"
import CompanyTariffsStore from "./CompanyTariffsStore"
import CompanyBanksInfoStore from "./CompanyBanksInfoStore"
import CompanyObjectsStore from "./CompanyObjectsStore"
import { GetCompanyResponse } from "../types/api/companyAPI"
import CompanyAgent from "../../../agent/Company"

class CompanyPageStore implements EntityStoreInterface {
  loader: Loader

  actionLoader: Loader

  editor: Editor

  overviewStore: CompanyOverviewStore | null

  officesStore: CompanyOfficesStore

  tariffsStore: CompanyTariffsStore

  bankInfoStore: CompanyBanksInfoStore

  objectsStore: CompanyObjectsStore

  private hostStore: HostStore

  constructor(hostStore: HostStore) {
    this.loader = new Loader(true)
    this.actionLoader = new Loader()
    this.editor = new Editor()
    this.overviewStore = null
    this.officesStore = new CompanyOfficesStore(this)
    this.tariffsStore = new CompanyTariffsStore()
    this.bankInfoStore = new CompanyBanksInfoStore(this)
    this.objectsStore = new CompanyObjectsStore()
    this.hostStore = hostStore
    makeAutoObservable(this)
  }

  fetchCompanyById = async (id: number) => {
    this.loader.startLoading()

    const [err, res] = await to<GetCompanyResponse>(CompanyAgent.getById(id))

    runInAction(() => {
      if (res && !err) {
        this.overviewStore = new CompanyOverviewStore(res)
        this.officesStore?.setOffices(res)
      } else {
        this.loader.setError("fetch company", err)
      }
      this.loader.endLoading()
    })
  }

  deleteCompany = async (id: number) => {
    this.actionLoader.startLoading("company removal")

    const [err] = await to(CompanyAgent.delete(id))

    if (err) {
      this.actionLoader.setError("company removal", err)
    }
    this.actionLoader.endLoading()
  }

  updateCompanyPage = () => {
    this.loader = new Loader(true)
    this.actionLoader = new Loader()
    this.editor = new Editor()
    this.overviewStore = null
    this.officesStore = new CompanyOfficesStore(this)
    this.tariffsStore = new CompanyTariffsStore()
    this.bankInfoStore = new CompanyBanksInfoStore(this)
    this.objectsStore = new CompanyObjectsStore()
  }
}

export default CompanyPageStore
