import React from "react"
import { Grid } from "kui-basic"
import { observer } from "mobx-react"
import { Loading } from "kui-complex"
import useDashboardStore from "../../../store"
import { getListCards } from "./content"

const HousingInspectorDashboardCards = () => {
  const { housingInspectorDashboard } = useDashboardStore()
  const { loader } = housingInspectorDashboard

  if (loader.isLoading) return <Loading height="80vh" />

  const cards = getListCards(housingInspectorDashboard)

  return (
    <Grid container spacing={4}>
      {cards.map(({ card, key, size }) => (
        <Grid item xs={size || 4} key={key}>
          {card}
        </Grid>
      ))}
    </Grid>
  )
}

export default observer(HousingInspectorDashboardCards)
