import React from "react"
import { Box } from "kui-basic"
import ApartmentAttachmentsBlock from "../../../pages/ExpensesPage/components/tabs/ExpensesAttachmentsTab/ApartmentAttachmentsBlock"
import Container from "../../../../../components/ui/Container"
import { ExpensesAttachmentsProps } from "./types"

function ExpensesAttachments(props: ExpensesAttachmentsProps) {
  const { clientsAttachments, internalAttachments, apartmentId, date } = props

  return (
    <Container>
      <Box pt={3}>
        <ApartmentAttachmentsBlock
          title="Clients"
          attachmentsStore={clientsAttachments}
          apartmentId={apartmentId}
          date={date}
        />
        <Box mt={3}>
          <ApartmentAttachmentsBlock
            title="Internal"
            attachmentsStore={internalAttachments}
            apartmentId={apartmentId}
            date={date}
          />
        </Box>
      </Box>
    </Container>
  )
}

export default ExpensesAttachments
