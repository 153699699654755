import React, { useState } from "react"
import styled from "@emotion/styled"
import { Checkbox, Caption, Box } from "kui-basic"
import { Modal, FormBottom } from "kui-crm"
import { ConfirmOfDeleteModalProps } from "./types"

function ConfirmationModal({
  handleSubmit,
  handleClose,
  open,
  label,
  name,
  title,
  ...props
}: ConfirmOfDeleteModalProps) {
  const [confirmStatus, setConfirmStatus] = useState(false)
  const isDeleteConfirmed = localStorage.getItem(name!)

  const confirmOfDelete = () => {
    if (confirmStatus) {
      setConfirmToLS()
    }
    if (handleSubmit) {
      handleSubmit()
    }
    handleClose()
  }

  const setConfirmToLS = () => {
    localStorage.setItem(name!, "true")
  }

  const handleChange = () => {
    setConfirmStatus((prev) => !prev)
  }

  if (open && isDeleteConfirmed) {
    if (handleSubmit) {
      handleSubmit()
    }
    handleClose()
  }

  return (
    <Modal
      data-testid="confirmation_modal"
      open={open}
      title={title}
      handleClose={handleClose}
      {...props}
    >
      <Box pr={5} pl={5} pb={3}>
        <StyledDescription size="s" color="fiftyP">
          {label || "Are you sure you want to permanently delete the document?"}
        </StyledDescription>
        <Checkbox
          label={
            <Caption size="xs" color="fiftyP">
              Don&apos;t ask again
            </Caption>
          }
          checked={confirmStatus}
          onChange={handleChange}
        />
      </Box>
      <FormBottom
        fullWidth
        label="Confirm"
        type="button"
        handleClick={confirmOfDelete}
      />
    </Modal>
  )
}

ConfirmationModal.defaultProps = {
  name: "confirmOfDeleteFiles",
  title: "Delete document",
}

export default ConfirmationModal

const StyledDescription = styled(Caption)`
  margin-top: -4px;
  margin-bottom: 32px;
`
