import { makeAutoObservable } from "mobx"
import { DateTime } from "luxon"
import { Loader, resHandler } from "kui-utils"
import to from "await-to-js"
import { CompanyDashboardModal } from "../../types/api/companies"
import CompaniesStore from "./CompaniesStore"
import DashboardAgent from "../../../../agent/Dashboard"

class CompanyStore {
  notifyId: number

  id: number

  name: string

  logo: string | null

  notificationDate: DateTime | null

  companiesStore: CompaniesStore

  loader: Loader

  constructor(
    companyNotify: CompanyDashboardModal,
    companiesStore: CompaniesStore
  ) {
    const { company, created, id } = companyNotify
    this.notifyId = id
    this.id = company.id
    this.name = company.name
    this.logo = company.logo
    this.notificationDate = created ? DateTime.fromISO(created) : null
    this.companiesStore = companiesStore
    this.loader = new Loader()
    makeAutoObservable(this)
  }

  archiveCompany = async () => {
    this.loader.startLoading()

    const response = await to(
      DashboardAgent.archiveDashboardItem(this.notifyId)
    )

    resHandler(response, this.loader, () => {
      this.companiesStore.removeFromCompaniesList(this.notifyId)
    })
  }

  restoreCompany = async () => {
    this.loader.startLoading()

    const response = await to(
      DashboardAgent.unArchiveDashboardItem(this.notifyId)
    )

    resHandler(response, this.loader, () => {
      this.companiesStore.removeFromArchiveList(this.notifyId)
    })
  }
}

export default CompanyStore
