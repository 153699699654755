import React from "react"
import { QuestionIcon } from "kui-icon"
import styled from "@emotion/styled"
import PopupWithCheckList from "../../PopupWithCheckList"
import { CheckDataPopupProps } from "./types"

const CheckDataPopup = (props: CheckDataPopupProps) => {
  const { dataList, label: title } = props
  const checkList = dataList.map((label) => ({ label, checked: false }))

  if (!dataList.length) return null

  return (
    <StyledPopup
      label={title}
      items={checkList}
      popupActive
      button={<StyledIcon />}
      strategy="fixed"
      placement="bottom-end"
    />
  )
}

export default CheckDataPopup

const StyledIcon = styled(QuestionIcon)`
  margin: 0 4px;
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.palette.grey.fiftyP};
  }
`

const StyledPopup = styled(PopupWithCheckList)`
  > .KUI-Popper {
    > div {
      padding: 8px 16px;
      border-radius: 8px;
    }
  }
`
