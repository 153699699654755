import React from "react"
import { Caption } from "kui-basic"
import { ErrorMessageProps } from "./types"

const ErrorMessage = (props: ErrorMessageProps) => {
  const { message } = props

  if (!message) return null

  return (
    <Caption colorGroup="red" weight={500} size="s">
      {message}
    </Caption>
  )
}

export default ErrorMessage
