import React from "react"
import { observer } from "mobx-react"
import { ChangeReasonFormFields, Modal } from "kui-crm"
import { UpdatedFieldsModalProps } from "./types"
import ChangeReasonForm from "../../forms/common/ChangeReasonForm"

function UpdatedFieldsModal(props: UpdatedFieldsModalProps) {
  const { title, open, handleClose, customReasons } = props
  const { handleSubmit } = props

  const handleFormSubmit = (data: ChangeReasonFormFields) => {
    handleSubmit(data)
    if (handleClose) handleClose()
  }

  return (
    <Modal title={title} open={open} handleClose={handleClose}>
      <ChangeReasonForm
        customReasons={customReasons}
        handleSubmit={handleFormSubmit}
        label="Start editing"
      />
    </Modal>
  )
}

export default observer(UpdatedFieldsModal)
