import { RadioGroupWithLabel } from "kui-complex"
import { InputParams } from "../../../common/InputsGroupWithTitle/types"
import { ServicePaymentInfoFormFields } from "./types"
import {
  PayerValues,
  PayerWithoutTenantValues,
  PaymentTypeValues,
} from "../../../../utils/content/listsOptions"

const getServicePaymentInfoFields = (
  hasRentalContract?: boolean
): InputParams<ServicePaymentInfoFormFields>[] => {
  const payerOptions = hasRentalContract
    ? PayerValues
    : PayerWithoutTenantValues

  return [
    {
      name: "paymentMadeBy",
      title: "Payment made by",
      options: payerOptions,
      direction: "vertical",
      variant: "custom",
      CustomInput: RadioGroupWithLabel,
    },
    {
      name: "refundFrom",
      title: "Refund from",
      options: payerOptions,
      direction: "vertical",
      variant: "custom",
      CustomInput: RadioGroupWithLabel,
    },
    {
      name: "paymentType",
      title: "Payment type",
      options: PaymentTypeValues,
      variant: "custom",
      CustomInput: RadioGroupWithLabel,
    },
    {
      name: "comment",
      label: "Comment",
      isTextArea: true,
    },
  ]
}

export default getServicePaymentInfoFields
