import styled from "@emotion/styled"

const DashboardCard = styled.div`
  background: ${({ theme }) => theme.palette.grey.zero};
  border-radius: 12px;
  transition: all ease-out 0.3s;
  padding: 16px;
  height: 100%;
`

export default DashboardCard
