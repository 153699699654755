import React from "react"
import styled from "@emotion/styled"
import { Caption } from "kui-basic"
import { Tooltip } from "kui-crm"
import { css } from "@emotion/react"
import { DropdownOptionProps, OptionStylesProps } from "./types"
import ProtectedNode from "../../../common/ProtectedNode"

const DropdownOption = (props: DropdownOptionProps) => {
  const { option, menuItemStyles, handleOptionClick } = props

  const handleClick = () => {
    if (!option.disabledReason) handleOptionClick(option.handleClick)
  }

  const Option = (optionProps: any) => (
    <StyledMenuItem
      role="button"
      className={menuItemStyles}
      onClick={handleClick}
      onKeyDown={handleClick}
      tabIndex={0}
      key={option.label}
      disabled={!!option.disabledReason}
      {...optionProps}
    >
      <Caption size="xs" weight={500}>
        {option.label}
      </Caption>
    </StyledMenuItem>
  )

  if (option.disabledReason) {
    return (
      <Tooltip
        content={
          <Caption size="xs" color="fiftyP">
            {option.disabledReason}
          </Caption>
        }
        placement="bottom"
      >
        <Option />
      </Tooltip>
    )
  }

  return (
    <ProtectedNode withTooltip permission={option.permission}>
      <Option />
    </ProtectedNode>
  )
}

export default DropdownOption

const notDisabledStyles = ({ disabled, theme }: OptionStylesProps) =>
  !disabled &&
  css`
    cursor: pointer;
    &:hover {
      background-color: ${theme?.palette.background.light1};
    }
  `

const StyledMenuItem = styled.button<OptionStylesProps>`
  border-radius: 12px;
  padding: 13px 8px;
  transition: all ease-out 0.2s;
  text-align: left;
  border: 0;
  background: unset;
  width: 100%;
  ${notDisabledStyles};
  &:disabled {
    p {
      color: ${({ theme }) => theme?.palette.grey.fiftyP};
    }
  }
`
