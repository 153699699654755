import requests from "."
import { NotesEntities, PatchNoteRequest } from "../types/api/notes"

const NotesAgent = {
  getAll: (entity: NotesEntities, entityId: number) =>
    requests.get(`/${entity}/${entityId}/notes`),
  create: (entity: NotesEntities, entityId: number, data: PatchNoteRequest) =>
    requests.post(`/${entity}/${entityId}/notes`, data),
  delete: (entity: NotesEntities, entityId: number, noteId: number) =>
    requests.delete(`/${entity}/${entityId}/notes/${noteId}`),
  patch: (
    entity: NotesEntities,
    entityId: number,
    noteId: number,
    data: PatchNoteRequest
  ) => requests.patch(`/${entity}/${entityId}/notes/${noteId}`, data),
}

export default NotesAgent
