import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { Loader, Paginator, SortingFilter } from "kui-utils"
import {
  CompanyCreationFields,
  CompaniesStore as CompaniesLiteStore,
} from "kui-crm"
import {
  GetCompaniesResponse,
  CompanyLogoModel,
  CompanyModel,
} from "kui-crm/types"
import CompanyStore from "./CompanyStore"
import CompaniesAgent from "../../../agent/Companies"
import { MultipleEntitiesStoreInterface } from "../../../types/store/pagination"

class CompaniesStore implements MultipleEntitiesStoreInterface {
  companies: CompanyStore[]

  paginator: Paginator

  filter: SortingFilter

  loader: Loader

  creationLoader: Loader

  constructor() {
    this.companies = []
    this.loader = new Loader()
    this.creationLoader = new Loader()
    this.paginator = new Paginator()
    this.filter = new SortingFilter("id")
    makeAutoObservable(this)
  }

  fetchAll = async () => {
    this.loader.startLoading()

    const [err, res] = await to<GetCompaniesResponse>(
      CompaniesAgent.all(
        this.paginator.offset,
        this.paginator.limit,
        this.filter.filterParams
      )
    )

    runInAction(() => {
      if (!err && res) {
        const mapper = (company: CompanyModel) => new CompanyStore(company)

        this.companies = this.paginator.getPageResponse<
          CompanyModel,
          CompanyStore
        >(res, this.companies, mapper)
      } else {
        this.loader.setError("fetch companies", err)
      }
      this.loader.endLoading()
    })
  }

  createCompany = async (data: CompanyCreationFields) => {
    this.creationLoader.startLoading()

    let logo: string | null | undefined
    const body = CompaniesLiteStore.getCreationBody(data)

    const [err, res] = await to<CompanyModel>(CompaniesAgent.create(body))
    if (res && data.logo?.[0]) {
      logo = await this.createLogo(res.id, data.logo[0])
    }

    runInAction(() => {
      if (!err && res) {
        this.companies = [...this.companies, new CompanyStore({ ...res, logo })]
      } else {
        this.creationLoader.setError("company creation")
      }
      this.creationLoader.endLoading()
    })

    return res?.id
  }

  createLogo = async (companyId: number, logo: File) => {
    const body = new FormData()
    body.append("logo", logo, "logo")

    const [err, res] = await to<CompanyLogoModel>(
      CompaniesAgent.createLogo(companyId, body)
    )

    runInAction(() => {
      if (err) {
        this.creationLoader.setError("logo creation")
      }
    })

    return res?.logo
  }
}

export default CompaniesStore
