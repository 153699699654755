import React from "react"
import { Grid } from "kui-basic"
import styled from "@emotion/styled"
import RowWithApartment from "../RowWithApartment"
import InspectionFeedbackStatus from "../../../../../components/common/InspectionFeedbackStatus"
import { InspectionRowWithStatusProps } from "./types"
import { DynamicRoutes } from "../../../../../types/route"

const InspectionRowWithStatus = (props: InspectionRowWithStatusProps) => {
  const { apartment, label, id, endComponent } = props
  const { landlordFeedback, tenantFeedback } = props
  const link = DynamicRoutes.inspection(apartment.id, id)

  return (
    <RowWithApartment label={label} apartment={apartment} link={link}>
      <StyledWrapper container>
        <InspectionFeedbackStatus
          landlordFeedback={landlordFeedback}
          tenantFeedback={tenantFeedback}
        />
        {endComponent}
      </StyledWrapper>
    </RowWithApartment>
  )
}

export default InspectionRowWithStatus

const StyledWrapper = styled(Grid)`
  width: fit-content;
`
