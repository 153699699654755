import { DateTime } from "luxon"
import {
  ApartmentPaymentModel,
  PostApartmentPaymentModel,
} from "../../../../pages/ApartmentPage/pages/ExpensesPage/types/api/expensesSummaryAPI"
import { NewPaymentFormInputs } from "../../../../pages/ApartmentPage/pages/ExpensesPage/forms/summary/PaymentCreationForm/types"

export const paymentIds = {
  renter: 1,
  owner: 2,
  all: 3,
}

export const paymentBill = {
  id: 1,
  number: "787435",
}

export const paymentRenterMock: ApartmentPaymentModel = {
  id: paymentIds.renter,
  payer: {
    id: 1,
    first_name: "Иван",
    last_name: "Иванов",
    middle_name: "Иванович",
    full_name: "Иван Иванов Иванович",
  },
  payer_type: "tenant",
  value: "3000",
  payment_date: "2022-10-15",
  bill: paymentBill,
  payment_type: "pay-in",
  rental_contract: null,
}

export const paymentOwnerMock: ApartmentPaymentModel = {
  id: paymentIds.owner,
  payer: {
    id: 2,
    first_name: "Петр",
    last_name: "Петров",
    middle_name: "Петрович",
    full_name: "Петр Петров Петрович",
  },
  payer_type: "owner",
  value: "15000",
  payment_date: "2022-10-23",
  bill: paymentBill,
  payment_type: "pay-in",
  rental_contract: null,
}

export const postPaymentMock: PostApartmentPaymentModel = {
  payer: 1,
  value: "1500",
  payment_date: "2022-10-16",
  period_id: 1,
  bill: 1,
}

export const postRenterFormPaymentMock: NewPaymentFormInputs = {
  invoiceId: 1,
  payerId: paymentIds.renter,
  date: DateTime.fromISO("2020-10-16"),
  amount: 1500,
  transactionType: "pay-in",
}

export const postOwnerFormPaymentMock: NewPaymentFormInputs = {
  invoiceId: 1,
  payerId: paymentIds.owner,
  date: DateTime.fromISO("2020-10-16"),
  amount: 1500,
  transactionType: "pay-in",
}

export const paymentsMock = [
  paymentRenterMock,
  { ...paymentRenterMock, id: 3, value: "3000" },
  paymentOwnerMock,
  { ...paymentOwnerMock, id: 4, value: "6000" },
]
