import React from "react"
import { observer } from "mobx-react"
import { MeterInfoStepProps, InventoryMeterInfoFields } from "kui-crm_actions"
import ModalLabel from "../../../../../common/ModalLabel"
import useApartmentLiteStore from "../../../../../../store/shared/apartment"

const FullMeterInfoStep = (props: MeterInfoStepProps) => {
  const { resource, index } = props
  const { inspectionsStore } = useApartmentLiteStore()
  const formStore = inspectionsStore.creationForm
  const label = `Add info about ${resource} meter`

  return (
    <div>
      <ModalLabel label={label} />
      <InventoryMeterInfoFields
        resource={resource}
        formStore={formStore}
        index={index}
      />
    </div>
  )
}

export default observer(FullMeterInfoStep)
