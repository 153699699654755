import React from "react"
import { Grid, IconButton } from "kui-basic"
import styled from "@emotion/styled"
import { CloseIcon, EditIcon } from "kui-icon"
import { useToggle } from "kui-utils"
import { observer } from "mobx-react"
import { useFormContext } from "react-hook-form"
import { ChangeLineControlButtonsProps } from "./types"
import EditingChangeModal from "../EditingChangeModal"
import ChangeLineEditingButtons from "../ChangeLineEditingButtons"

const ChangeLineControlButtons = (props: ChangeLineControlButtonsProps) => {
  const { contractId, variant, changesGroup, contractEditor } = props
  const [isModalOpen, openModal, closeModal] = useToggle()
  const { editor, deletePendingChange, changesStore } = changesGroup
  const form = useFormContext()

  if (variant === "applied") return null

  const handleDelete = () => {
    if (contractId) deletePendingChange(contractId)
    form.reset()
  }

  if (editor.isEditing) {
    return (
      <ChangeLineEditingButtons
        changesGroup={changesGroup}
        contractId={contractId}
        contractEditor={contractEditor}
      />
    )
  }

  return (
    <StyledWrapper container wrap="nowrap">
      {!changesStore.hasEditingChange && (
        <StyledEditButton onClick={openModal} data-testid="editButton">
          <EditIcon width={18} height={18} />
        </StyledEditButton>
      )}
      <StyledButton onClick={handleDelete} data-testid="deleteButton">
        <CloseIcon />
      </StyledButton>

      <EditingChangeModal
        open={isModalOpen}
        handleClose={closeModal}
        changesGroup={changesGroup}
        contractEditor={contractEditor}
        form={form}
      />
    </StyledWrapper>
  )
}

export default observer(ChangeLineControlButtons)

const StyledButton = styled(IconButton)`
  path {
    fill: ${({ theme }) => theme.palette.grey.fiftyP};
  }
`

const StyledEditButton = styled(StyledButton)`
  margin-right: 16px;
`

const StyledWrapper = styled(Grid)`
  width: fit-content;
`
