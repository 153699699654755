import { observer } from "mobx-react"
import React from "react"
import styled from "@emotion/styled"
import { Heading } from "kui-basic"
import TableRow from "../../../../../../../../components/ui/TableRow"
import TableCell from "../../../../../../../../components/ui/TableCell"
import ElectricityArchiveMeterRow from "../ElectricityArchiveMeterRow"
import ComplexArchiveMeterRow from "../ComplexArchiveMeterRow"
import Table from "../../../../../../../../components/ui/Table"
import { CellStylesProps } from "../../../../../../../../components/ui/TableHeadRow/types"
import useExpensesStore from "../../../../store"

const tableHeadParams = [
  { title: "", width: "8%" },
  { title: "", width: "11%" },
  { title: "Tariff group", width: "15%", withPadding: true },
  { title: "Last", width: "6%" },
  { title: "", width: "10%" },
  { title: "", width: "9%" },
  { title: "", width: "6%" },
  { title: "Tariff", width: "8%" },
  { title: "Cost", width: "10%" },
]

function ApartmentArchivedMetersTable() {
  const { metersStore, periodsStore } = useExpensesStore()
  const singleArchiveCounters = [
    ...metersStore.waterMetersStore.archiveWaterMeters,
    ...metersStore.heatingMetersStore.archiveHeatingMeters,
    ...metersStore.gasMetersStore.archiveGasMeters,
  ]
  const electricityArchiveCounters =
    metersStore.electricityMetersStore.archiveElectricityMeters
  const { selectedPeriod } = periodsStore
  const disabled = !selectedPeriod?.canBeEdited

  if (
    singleArchiveCounters?.length === 0 &&
    electricityArchiveCounters?.length === 0
  ) {
    return null
  }

  return (
    <Table data-testid="archived_table">
      <thead>
        <StyledRow>
          <TableCell isFirst width="13%">
            <Heading color="fiftyP" size="h4">
              Deactived
            </Heading>
          </TableCell>
          {tableHeadParams.map((item) => (
            <StyledCell
              key={item.title}
              width={item.width}
              textColor="gray"
              withPadding={!!item.withPadding}
            >
              {item.title}
            </StyledCell>
          ))}
          <TableCell width="4%" isLast />
        </StyledRow>
      </thead>
      <tbody data-testid="archived_table_body">
        {electricityArchiveCounters?.map((counter) => (
          <ElectricityArchiveMeterRow key={counter.id} counter={counter} />
        ))}
        {singleArchiveCounters?.map((counter) => (
          <ComplexArchiveMeterRow
            key={counter.id}
            counter={counter}
            tariff={counter.tariffValues}
            disabled={disabled}
          />
        ))}
      </tbody>
    </Table>
  )
}

export default observer(ApartmentArchivedMetersTable)

const StyledRow = styled(TableRow)`
  &.MuiTableRow-root {
    background-color: ${({ theme }) => theme.palette.background.light1};
  }
  td:last-child {
    padding-right: 15px;
  }
  td:first-child {
    padding-left: 27px;
  }
`

const StyledCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "withPadding",
})<CellStylesProps>`
  ${({ withPadding }) => withPadding && "padding-left: 34px"};
`
