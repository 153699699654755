import requests from "./index"
import { PostApartmentInspectionRequest } from "../pages/ApartmentPage/types/api/apartmentInspectionsAPI"
import { InspectionListType } from "../pages/ApartmentPage/types/store/apartmentInspections"

const ApartmentInspectionsAgent = {
  getAll: (
    type: InspectionListType,
    apartmentId: number,
    limit: number,
    offset: number,
    rentalContractId?: number | null
  ) =>
    requests.get(
      `/apartments/${apartmentId}/inspections${
        type === "inspections" ? "" : `/${type}`
      }?limit=${limit}&offset=${offset}${
        rentalContractId ? `&rental_contract=${rentalContractId}` : ""
      }`
    ),
  create: (apartmentId: number, data: PostApartmentInspectionRequest) =>
    requests.post(`/apartments/${apartmentId}/inspections`, data),
}

export default ApartmentInspectionsAgent
