import React from "react"
import { Heading, Box, Grid } from "kui-basic"
import { InputByType } from "kui-crm"
import { InputsGroupWithTitleProps } from "./types"

function InputsGroupWithTitle<T extends object>(
  props: InputsGroupWithTitleProps<T>
) {
  const {
    fields,
    customInputs,
    form,
    title,
    isEditing,
    header,
    size: defaultSize,
    ...other
  } = props

  return (
    <>
      {header || (
        <Box pt={3} mb={2}>
          <Heading size="h4">{title}</Heading>
        </Box>
      )}
      <Grid container spacing={3} alignItems="center" {...other}>
        {fields.map(({ size, ...inputProps }) => (
          <Grid item xs={size || defaultSize || 6} key={inputProps.name}>
            <InputByType
              testId={inputProps.name}
              disabled={!isEditing}
              form={form}
              {...inputProps}
            />
          </Grid>
        ))}
        {customInputs}
      </Grid>
    </>
  )
}

export default InputsGroupWithTitle
