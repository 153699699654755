import { toNumber } from "kui-utils"
import {
  APISubjectRoles,
  MeterFeaturesFormFields,
  PartialPayerFields,
} from "kui-crm"
import { UtilityPayerModel } from "kui-crm/types"
import { PostCommonMeterParams } from "../../types/api/meters"

export const getApiUtilitiesPayerParams = (payer: PartialPayerFields) => ({
  utility_type:
    payer.utilityType === "invoice"
      ? "utility"
      : payer.utilityType || "utility",
  payment_made_by: payer.mainPayer,
  refund_from: (payer.refundFrom || payer.payer!) as APISubjectRoles,
  refund_value: toNumber(payer.refundValue) || 100,
  value_type: payer.partType || "percent",
})

export const getUtilitiesPayerParams = (
  payer: UtilityPayerModel
): PartialPayerFields => ({
  payer: payer.payment_made_by ? "partial" : payer.refund_from,
  mainPayer: payer.payment_made_by,
  refundFrom: payer.refund_from,
  partType: payer.value_type,
  refundValue: Number(payer.refund_value).toLocaleString(),
  utilityType:
    payer.utility_type === "utility" ? "invoice" : payer.utility_type,
})

export const getCommonMeterParams = (
  meter: Partial<MeterFeaturesFormFields>
): Omit<Partial<PostCommonMeterParams>, "initial_value" | "apartment"> => ({
  auto_sending: meter.withAutoSending,
  no_tariff: meter.noTariff,
  passport_number: meter.number,
  tariff:
    typeof meter.tariff === "undefined" ? undefined : meter.tariff?.id || null,
  activation_date:
    typeof meter.startDate === "undefined"
      ? undefined
      : meter.startDate?.toISODate() || null,
  company: meter.company?.id,
  type: meter.type,
  operating_account_number: meter.operationalAccountNumber,
  ...(meter.nextCheck && { check_date: meter.nextCheck.toISODate() }),
  ...(meter.meterPayer && {
    paid_by: getApiUtilitiesPayerParams(meter.meterPayer),
  }),
})
