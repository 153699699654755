import React from "react"
import { Modal } from "kui-crm"
import { observer } from "mobx-react"
import ImpairmentConfirmForm from "../ImpairmentConfirmForm"
import { ImpairmentConfirmModalProps } from "./types"
import useInspectionStore from "../../../store"

const ImpairmentConfirmModal = (props: ImpairmentConfirmModalProps) => {
  const { open, handleClose } = props
  const { activateInspection } = useInspectionStore()

  const handleSubmit = () => {
    activateInspection()
    handleClose()
  }

  return (
    <Modal
      data-testid="confirmModal"
      title="Activate impairments"
      open={open}
      handleClose={handleClose}
    >
      <ImpairmentConfirmForm handleSubmit={handleSubmit} />
    </Modal>
  )
}

export default observer(ImpairmentConfirmModal)
