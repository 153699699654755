import React, { useState } from "react"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import { LoaderState, Link } from "kui-crm"
import TableRow from "../../../../../../../../components/ui/TableRow"
import TableCell from "../../../../../../../../components/ui/TableCell"
import TableCellMenu from "../../../../../../../../components/common/TableCellMenu"
import { MeterRowProps } from "./types"
import LinkButton from "../../../../../../../../components/common/LinkButton"
import TableRowDivider from "../../../../../../../../components/common/TableRowDivider"
import TwoCaptionsBlock from "../../../../../../../../components/common/TwoCaptionsBlock"
import { Routes } from "../../../../../../../../types/route"
import PartialPayerCell from "../PartialPayerCell"
import MeterLabel from "../MeterLabel"
import MeterCostCell from "../MeterCostCell"

function MeterRow(props: MeterRowProps) {
  const { counter, onModalOpen, disabled, ModalComponent } = props
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)

  const handleDeactivate = () => {
    counter.deactivate()
  }

  const handleModalOpen = async () => {
    await onModalOpen(counter.id)
    setIsEditModalOpen(true)
  }

  const handleModalClose = () => {
    setIsEditModalOpen(false)
  }

  return (
    <>
      <StyledRow data-testid="counter_row">
        <TableCell isFirst>
          <Link
            href={`${Routes.meteringDevices}/${counter.resource}/${counter.id}`}
          >
            <TwoCaptionsBlock
              top={<MeterLabel meter={counter} />}
              bottom={`#${counter.number}`}
              topSize="s"
              bottomSize="xs"
              topWeight={500}
              spacing={2}
              data-testid="counter_title"
            />
          </Link>
        </TableCell>
        <TableCell data-testid="check_date">
          {counter.nextCheck?.toFormat("dd.MM.yy")}
        </TableCell>
        <PartialPayerCell
          mainPayer={counter.payer}
          refundPayer={counter.paymentsInfo}
        />
        <TableCell data-testid="tariff_group" width="15%">
          <LinkButton
            href={`${Routes.tariffsGroups}/${counter.resource}/${counter.tariff?.id}`}
          >
            {counter.tariff?.name}
          </LinkButton>
        </TableCell>
        <TableCell colSpan={3} />
        <TableCell data-testid="total_consumption">
          {counter.totalConsumption?.toLocaleString()}
        </TableCell>
        <TableCell />
        <TableCell data-testid="total_price">
          <MeterCostCell meter={counter} disabled={disabled} />
        </TableCell>
        <TableCellMenu
          align="right"
          disabled={disabled}
          options={[
            {
              label: "Edit metering devices",
              handleClick: handleModalOpen,
            },
            {
              label: "Deactivate",
              handleClick: handleDeactivate,
            },
          ]}
        />
      </StyledRow>
      <TableRowDivider />
      <ModalComponent open={isEditModalOpen} handleClose={handleModalClose} />

      <LoaderState loader={counter.loader} />
    </>
  )
}

export default observer(MeterRow)

const StyledRow = styled(TableRow)`
  td:first-child {
    padding-left: 27px;
  }
  td:last-child {
    padding-right: 15px;
  }
`
