import React, { useState } from "react"
import { Grid } from "kui-basic"
import { observer } from "mobx-react"
import ApartmentPhotoSlider from "../ApartmentPhotoSlider"
import VisibleImagesBlock from "../VisibleImagesBlock"
import InvisibleImagesBlock from "../InvisibleImagesBlock"
import useModalOpen from "../../../../../../../../../hooks/useModalOpen"
import usePromotionStore from "../../../../../store"
import useApartmentStore from "../../../../../../../store"

function AllPhotoContainer() {
  const { isArchived } = useApartmentStore()
  const {
    contentStore: { imagesStore },
  } = usePromotionStore()
  const [selectedImage, setSelectedImage] = useState(0)
  const [isSliderOpen, handleSliderOpen, handleSliderClose] = useModalOpen()
  const disabled = isArchived

  const handleVisibleImagesClick = (index: number) => {
    setSelectedImage(Number(index))
    handleSliderOpen()
  }

  const handleInvisibleImagesClick = () => {
    setSelectedImage(5)
    handleSliderOpen()
  }

  return (
    <>
      <Grid container spacing={3}>
        <VisibleImagesBlock
          disabled={disabled}
          handleClick={handleVisibleImagesClick}
        />
        <InvisibleImagesBlock handleClick={handleInvisibleImagesClick} />
      </Grid>
      {isSliderOpen && (
        <ApartmentPhotoSlider
          active={selectedImage}
          handleClose={handleSliderClose}
          isOpen={isSliderOpen}
          images={imagesStore.images}
          disabled={disabled}
        />
      )}
    </>
  )
}

export default observer(AllPhotoContainer)
