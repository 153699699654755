import { createContext, useContext } from "react"
import ApartmentPageLiteStore from "../../lites/ApartmentPageLiteStore"
import { ApartmentPageLiteStoreInterface } from "../../../types/store/apartments"

const apartmentStore = new ApartmentPageLiteStore()
const ApartmentLiteContext =
  createContext<ApartmentPageLiteStoreInterface>(apartmentStore)

const useApartmentLiteStore = () => useContext(ApartmentLiteContext)

export default useApartmentLiteStore
export { apartmentStore, ApartmentLiteContext }
