import React from "react"
import { observer } from "mobx-react"
import { LoaderState } from "kui-crm"
import { FillingFormFields } from "../../../../forms/filling/types"
import useApartmentStore from "../../../../store"
import FillingList from "../../../../../../components/forms/apartmentFilling/FillingList"
import { FillingInfoFormFields } from "../../../../forms/filling/FillingInfoForm/types"
import ApartmentFillingStore from "../../../../store/description/ApartmentFilling"
import { UploadFillingPhotoFields } from "../../../../forms/filling/UploadFillingPhoto/types"

const ApartmentFillingList = () => {
  const { descriptionStore, overviewStore, editor } = useApartmentStore()
  const { fillingStore } = descriptionStore

  const handleAdd = (data: FillingFormFields) => {
    if (overviewStore.id) {
      fillingStore?.createFilling(overviewStore.id, data)
    }
  }

  const handleEdit = (
    data: FillingInfoFormFields,
    filling: ApartmentFillingStore
  ) => {
    if (overviewStore.id) {
      filling.editFilling(overviewStore.id, data)
    }
  }

  const handleDuplicate = (filling: ApartmentFillingStore) => {
    if (overviewStore.id) filling.duplicateFilling(overviewStore.id)
  }

  const handleDelete = (filling: ApartmentFillingStore) => {
    if (overviewStore.id) filling.deleteFilling(overviewStore.id)
  }

  const deletePhoto = (
    filling: ApartmentFillingStore,
    fillingIndex: number,
    imageIndex?: number
  ) => {
    if (overviewStore.id && typeof imageIndex === "number") {
      filling.deleteImage(overviewStore.id, filling.images[imageIndex].id!)
    }
  }

  const addPhoto = (
    filling: ApartmentFillingStore,
    data: UploadFillingPhotoFields
  ) => {
    if (overviewStore.id) {
      filling.addImages(overviewStore.id, data)
    }
  }

  return (
    <>
      <FillingList
        fillingList={fillingStore.fillingList}
        disabled={!editor.isEditing}
        onAdd={handleAdd}
        onDelete={handleDelete}
        onEdit={handleEdit}
        onDuplicate={handleDuplicate}
        onAddPhoto={addPhoto}
        onDeletePhoto={deletePhoto}
      />
      <LoaderState loader={fillingStore.loader} />
      <LoaderState loader={fillingStore.actionLoader} />
    </>
  )
}

export default observer(ApartmentFillingList)
