const apartmentsHeadCellsParams = [
  { id: "id", label: "ID", width: "4%" },
  { id: "folder_number", label: "Folder", width: "5%" },
  { id: "address", label: "Address", width: "24%" },
  { id: "rental_status", label: "Status", width: "6%" },
  { id: "rental_type", label: "Type", width: "6%" },
  { id: "renter_payment_status", label: "Paid", width: "6%" },
  { id: "period_status", label: "Period", width: "6%" },
  {
    id: "owner",
    label: "Landlord",
    width: "19%",
  },
  {
    id: "renter",
    label: "Tenant",
    width: "19%",
  },
]

export default apartmentsHeadCellsParams
