import React from "react"
import { Grid } from "kui-basic"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import useInspectionStore from "../../store"
import InspectionFeedbackStatus from "./InspectionFeedbackStatus"
import getStatusLabel from "./content"

const InspectionFeedback = () => {
  const { tenantFeedback, landlordFeedback, resignInspection } =
    useInspectionStore()

  if (!tenantFeedback && !landlordFeedback) return null

  const tenantLabel = getStatusLabel("Tenant", tenantFeedback)
  const landlordLabel = getStatusLabel("Landlord", landlordFeedback)

  return (
    <StyledWrapper container spacing={2} alignItems="center">
      <InspectionFeedbackStatus
        resend={resignInspection}
        resendLabel="Resend for signature"
        feedback={tenantFeedback}
        label={tenantLabel}
      />
      <InspectionFeedbackStatus
        resend={resignInspection}
        resendLabel="Resend for signature"
        feedback={landlordFeedback}
        label={landlordLabel}
      />
    </StyledWrapper>
  )
}

export default observer(InspectionFeedback)

const StyledWrapper = styled(Grid)`
  padding-top: 8px;
`
