import React from "react"
import styled from "@emotion/styled"
import { Caption } from "kui-basic"
import { StatusBilletProps, StatusStylesProps } from "./types"
import { StatusBilletVariants } from "./variants"

function StatusBillet({ label, variant, ...otherProps }: StatusBilletProps) {
  return (
    <StyledWrapper variant={variant} {...otherProps}>
      <Caption size="xs" weight={600}>
        {label?.toUpperCase()}
      </Caption>
    </StyledWrapper>
  )
}

export default StatusBillet

const StyledWrapper = styled.span<StatusStylesProps>`
  border-radius: 8px;
  padding: 5px 16px;
  background: ${({ variant }) => StatusBilletVariants[variant].background};
  border: 1px solid
    ${({ variant, theme }) =>
      variant === "default" ? theme.palette.grey.fifteenB : "transparent"};
  display: inline-block;
  p {
    color: ${({ variant }) => StatusBilletVariants[variant].color};
    white-space: nowrap;
  }
`
