import React from "react"
import { observer } from "mobx-react"
import { useNavigate } from "react-router-dom"
import PageHeader from "../../../../components/common/PageHeader"
import { Routes } from "../../../../types/route"
import useRCStore from "../../store"
import { OptionDropdownType } from "../../../../components/ui/DropdownMenu/types"

function RCHeader() {
  const { deleteRentalContract, contractInfoStore } = useRCStore()
  const navigate = useNavigate()
  const routes = [
    { link: Routes.rental_contracts, title: "Rental Contracts" },
    { link: null, title: contractInfoStore.apartment?.address },
  ]

  const handleOnBackClick = () => {
    navigate(Routes.rental_contracts)
  }

  const handleDelete = async () => {
    const confirmation = window.confirm(
      "Are you sure you want to delete the contract?"
    )
    if (confirmation) {
      await deleteRentalContract(contractInfoStore.id!)
      handleOnBackClick()
    }
  }

  const menuOptions: OptionDropdownType[] = [
    { label: "Delete", handleClick: handleDelete },
  ]

  return (
    <PageHeader
      handleClick={handleOnBackClick}
      options={menuOptions}
      breadcrumbs={routes}
    />
  )
}

export default observer(RCHeader)
