import React from "react"
import { observer } from "mobx-react"
import useDashboardStore from "../../store"
import AccountantDashboardCards from "./AccountantDashboardCards"
import DashboardPageWrapper from "../../components/common/DashboardPageWrapper"

const AccountantDashboard = () => {
  const { accountantDashboard } = useDashboardStore()

  return (
    <DashboardPageWrapper dashboardStore={accountantDashboard}>
      <AccountantDashboardCards />
    </DashboardPageWrapper>
  )
}

export default observer(AccountantDashboard)
