import React, { useRef } from "react"
import { Button } from "kui-basic"
import { HalfArrowIcon, OpenEyeIcon } from "kui-icon"
import styled from "@emotion/styled"
import { useToggle } from "kui-utils"
import CheckServicesDropdown from "./CheckServicesDropdown"
import { CheckButtonProps, CheckButtonStylesProps } from "./types"

const CheckButton = (props: CheckButtonProps) => {
  const [isDropdownOpen, handleOpenDropdown, handleCloseDropdown] = useToggle()
  const buttonRef = useRef(null)

  return (
    <>
      <CheckServicesDropdown
        isOpen={isDropdownOpen}
        handleClose={handleCloseDropdown}
        buttonRef={buttonRef}
        input={
          <StyledButton
            size="xs"
            onClick={handleOpenDropdown}
            startIcon={<OpenEyeIcon />}
            endIcon={<HalfArrowIcon width={10} height={10} />}
            isDropdownOpen={isDropdownOpen}
            ref={buttonRef}
          >
            Check
          </StyledButton>
        }
        {...props}
      />
    </>
  )
}

export default CheckButton

const StyledButton = styled(Button)<CheckButtonStylesProps>`
  width: 150px;
  margin-right: 16px;
  .KUI-Button_icon-container {
    justify-content: flex-start;
  }
  .KUI-Button_end-icon {
    margin-left: auto;
    svg {
      transition: all ease-out 0.3s;
      transform: rotate(
        ${({ isDropdownOpen }) => (isDropdownOpen ? 180 : 0)}deg
      );
    }
  }
`
