import React from "react"
import { ClosingContractRowProps } from "./types"
import { DynamicRoutes } from "../../../../../../types/route"
import RowWithDate from "../../../common/RowWithDate"

const ClosingContractRow = (props: ClosingContractRowProps) => {
  const { contract, contractType } = props
  const { id, apartment, number, closingDate } = contract
  const contractLink = DynamicRoutes.contract(id, contractType)

  return (
    <RowWithDate
      link={contractLink}
      date={closingDate}
      label={`#${number}`}
      apartment={apartment}
    />
  )
}

export default ClosingContractRow
