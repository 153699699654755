import React, { useEffect } from "react"
import { observer } from "mobx-react"
import EntityPage from "../../components/common/EntityPage"
import useApartmentStore from "./store"
import ApartmentHeader from "./components/common/ApartmentHeader"
import ApartmentOverviewHeader from "./components/common/ApartmentOverviewHeader"
import ApartmentTabsPanel from "./components/common/ApartmentTabsPanel"

function ApartmentPage() {
  const apartmentStore = useApartmentStore()

  useEffect(() => () => apartmentStore.updateApartmentPage(), [])

  return (
    <EntityPage
      store={apartmentStore}
      initPage={apartmentStore.fetchApartmentById}
    >
      <ApartmentHeader />
      <ApartmentOverviewHeader />
      <ApartmentTabsPanel />
    </EntityPage>
  )
}

export default observer(ApartmentPage)
