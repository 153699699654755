import React from "react"
import { ImpairmentInfoStepFields, ImpairmentInfoStepProps } from "./types"
import ImpairmentInfoForm from "../ImpairmentInfoForm"
import useApartmentLiteStore from "../../../../../../../store/shared/apartment"

const ImpairmentInfoStep = (props: ImpairmentInfoStepProps) => {
  const { index, removeImpairment } = props
  const { inspectionsStore } = useApartmentLiteStore()
  const formStore = inspectionsStore.creationForm
  const defaultValues = formStore.fields?.impairments?.[index]

  const handleSubmit = (data: ImpairmentInfoStepFields) => {
    formStore.replaceFieldInArray("impairments", data, index)
    formStore.nextStep()
  }

  return (
    <ImpairmentInfoForm
      defaultValues={defaultValues}
      formStore={formStore}
      handleSubmit={handleSubmit}
      withPrevStep
      removeImpairment={removeImpairment}
    />
  )
}

export default ImpairmentInfoStep
