import * as React from "react"
import { observer } from "mobx-react"
import { Modal } from "kui-crm"
import ServiceCreationForm from "../../forms/apartmentExpenses/ServiceCreationForm"
import { ServiceCreationFields } from "../../forms/apartmentExpenses/ServiceCreationForm/types"
import { ServiceCreationModalProps } from "./types"

function ServiceCreationModal({
  open,
  handleClose,
  handleSubmit,
  ...other
}: ServiceCreationModalProps) {
  const handleFormSubmit = async (data: ServiceCreationFields) => {
    handleClose()
    handleSubmit(data)
  }

  return (
    <Modal
      title="New utilities"
      open={open}
      handleClose={handleClose}
      isScrollable
      data-testid="new_service_modal"
    >
      <ServiceCreationForm handleSubmit={handleFormSubmit} {...other} />
    </Modal>
  )
}

export default observer(ServiceCreationModal)
