/** @jsxImportSource @emotion/react */
import * as React from "react"
import { ChangeEvent, RefObject, useEffect, useRef, useState } from "react"
import styled from "@emotion/styled"
import { Grid, Box } from "kui-basic"
import { InputWithController } from "kui-complex"
import { useForkRef } from "kui-utils"
import { HalfArrowDownIcon } from "kui-icon"
import Dropdown from "../AutocompleteDropdown"
import Loading from "../../common/Loading"
import { InputStylesProps, InputWithAutoCompleteProps } from "./types"
import { OptionParams } from "../AutocompleteDropdown/types"

function InputWithAutocomplete<T extends object, U extends OptionParams>({
  renderOption,
  options,
  name,
  hideName,
  form,
  isLoading,
  onSelectItem,
  handleScrollEnd,
  onSearchItem,
  isLabelLoading,
  disabled,
  creatingLabel,
  renderCreatingModal,
  endComponent,
  inputRef: inputRefProps,
  onOpen,
  ...inputProps
}: InputWithAutoCompleteProps<T, U>) {
  const [isOpenDropdown, setIsOpenDropDown] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  if (hideName) form.register(hideName)

  const handleOpenDropdown = () => {
    setIsOpenDropDown(true)
  }

  const handleCloseDropdown = () => {
    setIsOpenDropDown(false)
  }

  const handleDropdownToggle = () => {
    setIsOpenDropDown((prev) => !prev)
  }

  const handleSelect = (option: any) => {
    onSelectItem(option)
    handleCloseDropdown()
    const error = form.getFieldState(name).error?.message
    if (error) {
      form.clearErrors(name)
    }
  }

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    if (onSearchItem) {
      onSearchItem(e.target.value)
      if (hideName) form.setValue(hideName, null)
    }
    if (!e.target.value) {
      if (hideName) form.setValue(hideName, null)
      form.setValue(name, null)
      onSelectItem(null)
    }
    handleOpenDropdown()
  }

  useEffect(() => {
    if (onOpen) onOpen(isOpenDropdown)
  }, [isOpenDropdown])

  const forwardRef = useForkRef(inputRef, inputRefProps)

  return (
    <StyledWrapper>
      {isLabelLoading && (
        <StyledLoadingWrapper>
          <Loading height="100%" />
        </StyledLoadingWrapper>
      )}
      <Dropdown<U>
        renderOption={renderOption}
        options={options}
        inputRef={
          {
            current: inputRef.current?.parentElement,
          } as RefObject<HTMLDivElement>
        }
        isOpenDropdown={isOpenDropdown}
        handleCloseDropdown={handleCloseDropdown}
        handleSelect={handleSelect}
        isLoading={isLoading}
        handleScrollEnd={handleScrollEnd}
        creatingLabel={creatingLabel}
        renderCreatingModal={renderCreatingModal}
        input={
          <StyledInput
            form={form}
            onInput={handleSearch}
            onFocus={handleOpenDropdown}
            name={name}
            disabled={disabled}
            ref={forwardRef}
            isDropdownOpen={isOpenDropdown}
            {...inputProps}
            endIcon={
              !disabled && (
                <Grid
                  container
                  alignItems="center"
                  onClick={handleDropdownToggle}
                >
                  <Box mr={2}>{endComponent}</Box>
                  {inputProps.endIcon ||
                    (!disabled && (
                      <StyledIcon
                        width={12}
                        height={13}
                        css={[
                          isOpenDropdown && { transform: "rotate(180deg)" },
                        ]}
                      />
                    ))}
                </Grid>
              )
            }
          />
        }
      />
    </StyledWrapper>
  )
}

export default InputWithAutocomplete

const StyledLoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
`

const StyledWrapper = styled.div`
  position: relative;
`

const StyledInput = styled(InputWithController)<InputStylesProps>`
  .KUI-InputMessage {
    opacity: ${({ isDropdownOpen }) => (isDropdownOpen ? 0 : 1)};
  }
`

const StyledIcon = styled(HalfArrowDownIcon)`
  transition: all ease-out 0.3s;
  cursor: pointer;
`
