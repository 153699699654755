import React from "react"
import { observer } from "mobx-react"
import { LoaderState } from "kui-crm"
import useTariffsStore from "./store"
import TariffsTabPanel from "./components/TariffsTabsPanel"
import usePaginationPage from "../../hooks/usePaginationPage"

function TariffsPage() {
  const { resource } = useTariffsStore()
  const { paginator, filter, fetchAll } = resource

  usePaginationPage(paginator, fetchAll, filter, [resource])

  return (
    <>
      <TariffsTabPanel />
      <LoaderState loader={resource.creationLoader} />
    </>
  )
}

export default observer(TariffsPage)
