import React from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormWrapper, InputByType } from "kui-crm"
import { Grid } from "kui-basic"
import { ContractClosingFormFields, ContractClosingFormProps } from "./types"
import ContractClosingSchema from "./schema"
import closeContractInputs from "./fields"
import useResetForm from "../../../../hooks/useResetForm"

const ContractClosingForm = (props: ContractClosingFormProps) => {
  const { handleSubmit } = props
  const form = useForm<ContractClosingFormFields>({
    resolver: yupResolver(ContractClosingSchema),
  })

  useResetForm(form)

  return (
    <FormWrapper
      form={form}
      onSubmit={form.handleSubmit(handleSubmit)}
      label="Close"
    >
      <Grid container spacing={2}>
        {closeContractInputs.map(({ name, ...inputProps }) => (
          <Grid item key={name} xs={12}>
            <InputByType form={form} name={name} {...inputProps} />
          </Grid>
        ))}
      </Grid>
    </FormWrapper>
  )
}

export default ContractClosingForm
