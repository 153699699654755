import React from "react"
import { observer } from "mobx-react"
import useClientStore from "../../../store"
import Container from "../../../../../components/ui/Container"
import ContractsTable from "../../../../../components/common/ContractsTable"
import NotesBlock from "../../../../../components/common/NoteBlock"

function ClientOverview() {
  const { overviewStore } = useClientStore()

  return (
    <Container>
      <ContractsTable
        rentalContracts={overviewStore?.rentalContracts || []}
        serviceContracts={overviewStore?.serviceContracts || []}
        withApartment
      />
      {overviewStore?.notesStore && (
        <NotesBlock notesStore={overviewStore.notesStore} />
      )}
    </Container>
  )
}

export default observer(ClientOverview)
