import { createContext, useContext } from "react"
import DashboardPageStore from "./DashboardPageStore"

const dashboardStore = new DashboardPageStore()
const DashboardContext = createContext(dashboardStore)

const useDashboardStore = () => useContext(DashboardContext)

export default useDashboardStore
export { dashboardStore }
