import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react"
import styled from "@emotion/styled"
import { useForkRef } from "kui-utils"
import { css } from "@emotion/react"
import { ScrollingContainerProps, ScrollingContainerStylesProps } from "./types"
import useScrollWithBottomSpacing from "../../../hooks/useScrollWithBottomSpacing"

const ScrollingContainer = forwardRef(
  (props: ScrollingContainerProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { onScrollEnd, maxHeight, ...otherProps } = props
    const containerRef = useRef<HTMLDivElement | null>(null)
    const [isScrollable, setIsScrollable] = useState(false)
    // eslint-disable-next-line
    const withScrollListener = onScrollEnd
      ? useScrollWithBottomSpacing(10, 200, onScrollEnd, containerRef)
      : null

    useEffect(() => {
      if (containerRef.current) {
        const hasScroll = maxHeight
          ? containerRef.current?.clientHeight >= maxHeight
          : containerRef.current.scrollHeight >
            containerRef.current.clientHeight
        setIsScrollable(hasScroll)
      }
    })

    const forwardedRef = useForkRef(ref, containerRef)

    return (
      <StyledWrapper
        isScrollable={isScrollable}
        ref={forwardedRef}
        {...otherProps}
      />
    )
  }
)

export default ScrollingContainer

// @ts-ignore
const withScroll = ({ isScrollable, theme }: ScrollingContainerStylesProps) =>
  isScrollable &&
  // @ts-ignore
  css`
    overflow-y: auto;
    position: relative;
    padding-right: 8px;
    ::-webkit-scrollbar {
      width: 8px;
      position: absolute;
      right: 8px;
    }
    ::-webkit-scrollbar-track,
    ::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 4px;
      transition: all ease-out 0.3s;
    }

    ::-webkit-resizer,
    ::-webkit-scrollbar-button,
    ::-webkit-scrollbar-corner {
      display: none;
    }
    &:hover {
      ::-webkit-scrollbar-track {
        background: ${theme.palette.grey.fifteenB};
      }

      ::-webkit-scrollbar-thumb {
        background: ${theme.palette.grey.thirty};
      }
    }
  `

const StyledWrapper = styled.div<ScrollingContainerStylesProps>`
  ${withScroll};
`
