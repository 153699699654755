import { InputFileWithVisibilityFields } from "kui-crm"
import FileStore from "../../store/templates/File"
import { matchesAPIVisibilityVariants } from "../content/matches"

export const setFileFromFileWithVisibility = (
  formData: FormData,
  document: InputFileWithVisibilityFields,
  fileField: string,
  nameField?: string,
  visibilityField?: string,
  withUrl?: boolean
) => {
  if (document && "file" in document && document.file instanceof File) {
    formData.append(fileField, document.file)
  }

  if (document instanceof FileStore && withUrl) {
    formData.append(fileField, document.url)
  }

  if (document && nameField && "name" in document) {
    const name = document.name.split(".")
    if (name.length > 1) name.pop()
    formData.append(nameField, name.join(""))
  }

  if (visibilityField && document?.visibility) {
    formData.append(
      visibilityField,
      matchesAPIVisibilityVariants[document.visibility]
    )
  }

  // if (document?.visibility) {
  //   formData.append(
  //     "visibility",
  //     matchesAPIVisibilityVariants[document.visibility]
  //   )
  // }
}

export const getChangedValue = (
  newValue: any,
  oldValue: any,
  formattedValue?: any
) => {
  if (JSON.stringify(newValue) === JSON.stringify(oldValue)) return undefined
  return formattedValue || newValue
}
