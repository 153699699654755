import { FieldGroupsParams } from "../../../../components/common/UpdatedFieldsGroup/types"
import { ClientCompanyInfoParams } from "../../types/store/clientInfo"

const clientCompanyFieldsGroups: FieldGroupsParams<
  Partial<ClientCompanyInfoParams>
>[] = [
  {
    title: "Representative",
    fields: [
      {
        label: "Surname",
        name: "representative.lastName",
        size: 4,
      },
      {
        label: "Name",
        name: "representative.firstName",
        size: 4,
        isRequired: true,
      },
      {
        label: "Middle name",
        name: "representative.middleName",
        size: 4,
      },
      {
        label: "Job title",
        name: "representative.jobTitle",
        size: 6,
      },
      {
        label: "Phone",
        name: "representative.phone",
        variant: "phone",
        size: 6,
      },
      {
        label: "Email",
        name: "representative.email",
        size: 6,
      },
    ],
  },
  {
    title: "Legal entity",
    fields: [
      {
        label: "Name",
        name: "company.name",
        size: 4,
      },
      {
        name: "empty",
        variant: "empty",
        size: 8,
      },
      {
        label: "TIN",
        name: "company.TIN",
        size: 4,
      },
      {
        label: "PSRN",
        name: "company.PSRN",
        size: 4,
      },
      {
        label: "TRRC",
        name: "company.TRRC",
        size: 4,
      },
      {
        label: "Site",
        name: "company.site",
        size: 4,
      },
      {
        label: "Phone",
        name: "company.phone",
        variant: "phone",
        size: 4,
      },
      {
        label: "Email",
        name: "company.email",
        size: 4,
      },
      {
        label: "Physical adress",
        name: "company.physicalAddress",
        size: 6,
      },
      {
        label: "Mailing address",
        name: "company.mailingAddress",
        size: 6,
      },
      {
        label: "Legal address",
        name: "company.legalAddress",
        size: 6,
      },
      {
        label: "Postal index",
        name: "company.postalIndex",
        size: 3,
      },
    ],
  },
]

export default clientCompanyFieldsGroups
