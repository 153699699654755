import { createContext, useContext } from "react"
import TariffsStore from "./TariffsStore"

const tariffsStore = new TariffsStore()
const TariffsContext = createContext(tariffsStore)

const useTariffsStore = () => useContext(TariffsContext)

export default useTariffsStore
export { tariffsStore }
