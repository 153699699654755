import React from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { InputWithMonthPicker } from "kui-complex"
import { FormWrapper } from "kui-crm"
import { AbortServiceFormProps } from "./types"
import AbortServiceFormSchema from "./schema"

const AbortServiceForm = ({ handleSubmit }: AbortServiceFormProps) => {
  const form = useForm({
    resolver: yupResolver(AbortServiceFormSchema),
  })

  return (
    <FormWrapper form={form} onSubmit={handleSubmit} label="Abort">
      <InputWithMonthPicker
        label="Abort date"
        form={form}
        name="abortDate"
        datePickerProps={{ minDate: new Date() }}
      />
    </FormWrapper>
  )
}

export default AbortServiceForm
