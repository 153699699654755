import React, { forwardRef } from "react"
import styled from "@emotion/styled"
import { TooltipProps } from "kui-basic"
import { Tooltip } from "kui-crm"

const TooltipWithArrow = styled(
  forwardRef((props: TooltipProps, ref) => (
    <Tooltip
      ref={ref}
      withArrow
      placement="bottom"
      spacing="0 0 8px 0"
      {...props}
    />
  ))
)`
  .KUI-Popper {
    margin-top: 0;
    > div {
      background: #191919;
      border-radius: 12px;
      padding: 8px;
      min-width: 220px;
    }
  }
`

export default TooltipWithArrow
