import React, { useEffect } from "react"
import { Box, Heading } from "kui-basic"
import { observer } from "mobx-react"
import useTariffStore from "../../store"
import Container from "../../../../components/ui/Container"
import Loading from "../../../../components/common/Loading"
import TariffObjectsTable from "../TariffObjectsTable"

function TariffObjectsTab() {
  const { objectsStore, overviewStore, resource } = useTariffStore()
  const tariffId = overviewStore.tariffInfoStore?.id

  useEffect(() => {
    if (tariffId && resource) {
      objectsStore.fetchTariffObjects(tariffId, resource)
    }
  }, [tariffId, resource])

  if (objectsStore.loader.isLoading) {
    return <Loading />
  }

  return (
    <>
      <Container>
        <Box pt={2} pb={2}>
          <Heading size="h4" weight={700}>
            Objects
          </Heading>
        </Box>
      </Container>
      <TariffObjectsTable />
    </>
  )
}

export default observer(TariffObjectsTab)
