import { DateTime } from "luxon"
import requests from "./index"

const ApartmentAllDocumentsAgent = {
  getDocuments: (
    offset: number,
    limit: number,
    apartmentId: number,
    date: DateTime | null
  ) =>
    requests.get(
      `/apartments/${apartmentId}/all-attachments?limit=${limit}&offset=${offset}${
        date ? `&year=${date.year}&month=${date.month}` : ""
      }`
    ),
  postDocument: (data: any, apartmentId: number) =>
    requests.post(`/apartments/${apartmentId}/all-attachments`, data),
  patchDocument: (documentId: number, data: any, apartmentId: number) =>
    requests.patch(
      `/apartments/${apartmentId}/all-attachments/${documentId}`,
      data
    ),
  deleteDocument: (documentId: number, apartmentId: number) =>
    requests.delete(`/apartments/${apartmentId}/all-attachments/${documentId}`),
}

const ApartmentOverviewDocumentsAgent = {
  getDocuments: (
    offset: number,
    limit: number,
    apartmentId: number,
    date: DateTime | null
  ) =>
    requests.get(
      `/apartments/${apartmentId}/attachments?limit=${limit}&offset=${offset}${
        date ? `&year=${date.year}&month=${date.month}` : ""
      }`
    ),
  postDocument: (data: any, apartmentId: number) =>
    requests.post(`/apartments/${apartmentId}/attachments`, data),
  patchDocument: (documentId: number, data: any, apartmentId: number) =>
    requests.patch(
      `/apartments/${apartmentId}/attachments/${documentId}`,
      data
    ),
  deleteDocument: (documentId: number, apartmentId: number) =>
    requests.delete(`/apartments/${apartmentId}/attachments/${documentId}`),
}

const ApartmentInspectionsDocumentsAgent = {
  getDocuments: (
    offset: number,
    limit: number,
    apartmentId: number,
    date: DateTime | null
  ) =>
    requests.get(
      `/apartments/${apartmentId}/inspection-attachments?limit=${limit}&offset=${offset}${
        date ? `&year=${date.year}&month=${date.month}` : ""
      }`
    ),
  postDocument: (data: any, apartmentId: number) =>
    requests.post(`/apartments/${apartmentId}/inspection-attachments`, data),
  patchDocument: (documentId: number, data: any, apartmentId: number) =>
    requests.patch(
      `/apartments/${apartmentId}/inspection-attachments/${documentId}`,
      data
    ),
  deleteDocument: (documentId: number, apartmentId: number) =>
    requests.delete(
      `/apartments/${apartmentId}/inspection-attachments/${documentId}`
    ),
}

const ApartmentDescriptionsDocumentsAgent = {
  getDocuments: (
    offset: number,
    limit: number,
    apartmentId: number,
    date: DateTime | null
  ) =>
    requests.get(
      `/apartments/${apartmentId}/description-attachments?limit=${limit}&offset=${offset}${
        date ? `&year=${date.year}&month=${date.month}` : ""
      }`
    ),
  postDocument: (data: any, apartmentId: number) =>
    requests.post(`/apartments/${apartmentId}/description-attachments`, data),
  patchDocument: (documentId: number, data: any, apartmentId: number) =>
    requests.patch(
      `/apartments/${apartmentId}/description-attachments/${documentId}`,
      data
    ),
  deleteDocument: (documentId: number, apartmentId: number) =>
    requests.delete(
      `/apartments/${apartmentId}/description-attachments/${documentId}`
    ),
}

export {
  ApartmentAllDocumentsAgent,
  ApartmentOverviewDocumentsAgent,
  ApartmentDescriptionsDocumentsAgent,
  ApartmentInspectionsDocumentsAgent,
}
