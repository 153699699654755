import React from "react"
import { observer } from "mobx-react"
import { useLocation } from "react-router-dom"
import { ResourceTypes } from "kui-crm"
import EntityPage from "../../components/common/EntityPage"
import useMeterStore from "./store"
import { Routes } from "../../types/route"
import MeterHeader from "./components/MeterHeader"
import MeterOverviewHeader from "./components/MeterOverviewHeader"
import MeterInfo from "./components/MeterInfo"
import MeterSettings from "./components/MeterSettings"

function MeterPage() {
  const meterStore = useMeterStore()
  const resource = useLocation().pathname.match(
    new RegExp(`(?<=${Routes.meteringDevices}/)\\w+`)
  )?.[0]

  const initMeterPage = (id: number) => {
    meterStore.updateMeterPage()
    if (resource) {
      meterStore.fetchMeterById(id, resource as ResourceTypes)
    }
  }

  return (
    <EntityPage store={meterStore} initPage={initMeterPage}>
      <MeterHeader />
      <MeterOverviewHeader />
      <MeterSettings />
      <MeterInfo />
    </EntityPage>
  )
}

export default observer(MeterPage)
