import React from "react"
import styled from "@emotion/styled"
import { InputSelectBaseProps, OptionParams } from "kui-complex/InputSelectBase"
import InputSelect from "../InputSelect"

function ButtonSelect<T extends object, O extends OptionParams>(
  props: InputSelectBaseProps<T, O>
) {
  const { options, selectedValue, ...otherProps } = props

  return (
    <StyledInputSelect
      value={selectedValue || options[0].value}
      options={options}
      iconProps={{ width: 9, height: 5 }}
      {...otherProps}
    />
  )
}

export default ButtonSelect

const StyledInputSelect = styled(InputSelect)`
  height: 32px !important;
  margin-right: 16px;
  input {
    padding: 0 24px 0 16px !important;
    border-radius: 66px;
    height: 32px;
    color: ${({ theme }) => theme.palette.grey.fiftyP};
    transform: none;
    font-size: 12px;
    line-height: 14px;
    margin: 0 -16px 0 0 !important;
    width: auto !important;
  }
  > div:last-of-type {
    right: 0;
  }
  & + div {
    top: 19px;
    padding-top: 19px;
  }
`
