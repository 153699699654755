import requests from "./index"

const ApartmentChartAgent = {
  getHistoryCosts: (apartmentId: number, startDate: string, endDate: string) =>
    requests.get(
      `/ml-price?apartment=${apartmentId}&start_date=${startDate}&end_date=${endDate}`
    ),
}

export default ApartmentChartAgent
