import React, { SyntheticEvent, useEffect, useRef, useState } from "react"
import { ArrayPath, useFieldArray } from "react-hook-form"
import styled from "@emotion/styled"
import { Caption } from "kui-basic"
import _ from "lodash"
import { observer } from "mobx-react"
import { CheckIcon, UploadIcon } from "kui-icon"
import { FileStore, FormBottom } from "kui-crm"
import { UploadFilesFields, UploadFilesFormProps } from "./types"
import HiddenInputFile from "../HiddenInputFile"
import UploadedFilesGroup from "./UploadedFilesGroup"
import { imageExtensions } from "../../../utils/content/constants"
import UploadFilesLabel from "../../common/UploadFilesLabel"
import ErrorMessage from "../ErrorMessage"
import FilesStore from "../../../store/templates/FilesStore"
import setNestedNameValue from "../../../utils/service/setNestedNameValue"
import useResetForm from "../../../hooks/useResetForm"

const limitTitle = 100

const UploadFilesForm = (props: UploadFilesFormProps) => {
  const { name, handleSubmit, disabled, required, form, onPrev } = props
  const { onAdd, withUpload, submitButtonProps, footerProps, onRemove } = props
  const { label, variant, settings, className, submitTitle, testId } = props
  const { selectedFile, setSelectedFile } = props

  const filesStoreRef = useRef(new FilesStore(variant))
  const ref = useRef<HTMLInputElement>(null)
  const filesStore = filesStoreRef.current
  const [hasToBigTitle, setHasToBigTitle] = useState(false)
  const [error, setErrorMessage] = useState("")
  const uploadedState = filesStore.uploadedAllFiles || !withUpload

  const { fields, append, remove, update } = useFieldArray<UploadFilesFields>({
    control: form.control,
    name: name as ArrayPath<any>,
  })
  const submitDisabled = !!(
    (required && fields.length === 0) ||
    hasToBigTitle ||
    disabled
  )

  const handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement
    const filesUploaded = target.files?.length ? target.files : null

    if (filesUploaded) {
      let toBigTitle = false
      setErrorMessage("")

      _.forEach(filesUploaded, (file: File, index: number) => {
        const fileExtensions = file.name.split(".").pop()?.toLocaleLowerCase()
        if (variant === "image" && !imageExtensions.includes(fileExtensions!)) {
          setErrorMessage("Only images have been uploaded")
        } else {
          const newFile = FileStore.initFromFile(file, fields.length + index)
          append(newFile)
          filesStore.addFile(newFile)
          if (Number(file.name?.length) > limitTitle) {
            toBigTitle = true
          }
        }
      })

      setHasToBigTitle(toBigTitle)
      if (onAdd) onAdd(filesUploaded)
    }
  }

  const handleDelete = (index: number) => {
    if (fields.length === 1 && ref.current) {
      ref.current.value = ""
    }
    remove(index)
    filesStore.removeFile(index)
    if (onRemove) onRemove(index)
  }

  const handleClick = () => {
    if (ref.current) ref.current.click()
  }

  const changeName = (index: number, newName: string) => {
    if (index) {
      const currentFile = fields[index]
      currentFile.updateName(newName)
      update(index, currentFile)
    }
  }

  const handleFormSubmit = (data: UploadFilesFields) => {
    if (withUpload) {
      if (filesStore.uploadedAllFiles) {
        handleSubmit({
          ...data,
          ...setNestedNameValue(name, filesStore.files, data),
        })
      } else filesStore.uploadFiles()
    } else handleSubmit(data)
  }

  useEffect(() => {
    filesStore.setFiles(fields)
  }, [])

  useResetForm(form)

  return (
    <form onSubmit={form.handleSubmit(handleFormSubmit)} data-testid={testId}>
      <ContentWrapper className={className}>
        <HiddenInputFile ref={ref} onChange={handleChange} />
        {!!label && (
          <StyledLabel size="s" color="fiftyP">
            {label}
          </StyledLabel>
        )}
        {fields.length ? (
          <>
            <UploadedFilesGroup
              variant={variant}
              files={filesStore.files}
              onChange={changeName}
              onDelete={handleDelete}
              onAdd={handleClick}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
            />
            {settings}
          </>
        ) : (
          <UploadFilesLabel variant={variant} handleChange={handleChange} />
        )}
        <ErrorMessage message={error} />
      </ContentWrapper>
      <FormBottom
        disabled={submitDisabled || filesStore.isUploading}
        endIcon={
          uploadedState ? (
            submitButtonProps?.endIcon || <CheckIcon />
          ) : (
            <UploadIcon />
          )
        }
        label={uploadedState ? submitTitle : "Upload files"}
        withPrevStep={!!onPrev}
        handlePrevClick={onPrev}
        {...footerProps}
      />
    </form>
  )
}

export default observer(UploadFilesForm)

UploadFilesForm.defaultValues = {
  name: "files",
}

const StyledLabel = styled(Caption)`
  margin-bottom: 16px;
`

const ContentWrapper = styled.div`
  padding: 16px 40px;
`
