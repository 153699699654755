import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { LoaderState } from "kui-crm"
import { ApartmentDescriptionFields, ApartmentDescriptionProps } from "./types"
import ApartmentDescriptionSchema from "./schema"
import DescriptionFieldsWithMap from "../DescriptionFieldsWithMap"
import useApartmentStore from "../../../store"

function ApartmentDescriptionForm({ handleSubmit }: ApartmentDescriptionProps) {
  const { descriptionStore, overviewStore, editor } = useApartmentStore()

  const form = useForm<ApartmentDescriptionFields>({
    defaultValues: {
      apartment: {
        address: overviewStore.address,
      },
      ...descriptionStore.fields,
    },
    resolver: yupResolver(ApartmentDescriptionSchema),
  })
  const disabled = !editor.isEditing

  useEffect(() => {
    if (editor.isAllowedToSendForm) {
      form.handleSubmit(handleSubmit, editor.blockToSendForm)()
    }
  }, [editor.isAllowedToSendForm])

  return (
    <>
      <DescriptionFieldsWithMap form={form} disabled={disabled} />
      <LoaderState loader={descriptionStore.loader} />
    </>
  )
}

export default observer(ApartmentDescriptionForm)
