import React from "react"
import { Heading } from "kui-basic"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import _ from "lodash"
import useExpensesStore from "../../../../store"
import ServicesTable from "../ServicesTable"

const PendingServices = () => {
  const { periodsStore, servicesStore } = useExpensesStore()
  const groupedServices = _.groupBy(servicesStore.pendingServices, "period")
  const { selectedPeriod } = periodsStore

  if (selectedPeriod?.isClosed || !servicesStore.pendingServices.length)
    return null

  return (
    <>
      <StyledHeading size="h3">Pending services</StyledHeading>
      {Object.keys(groupedServices).map((serviceGroup) => (
        <ServicesTable
          key={serviceGroup}
          label={serviceGroup}
          type="pending"
          services={groupedServices[serviceGroup]}
        />
      ))}
    </>
  )
}

export default observer(PendingServices)

const StyledHeading = styled(Heading)`
  margin: 16px 0 24px;
  padding-left: 26px;
`
