import React, { SyntheticEvent } from "react"
import { Grid } from "kui-basic"
import { useController, useWatch } from "react-hook-form"
import { InputWithController } from "kui-complex"
import { ApartmentShareFieldsProps } from "./types"
import InputSelect from "../../../../../../components/ui/InputSelect"
import { OwnershipShareTypesValues } from "../../../../../../utils/content/listsOptions"
import { OwnershipShareTypes } from "../../../../types/api/apartmentOwnershipAPI"

function ApartmentShareFields(props: ApartmentShareFieldsProps) {
  const { index, form, disabled } = props
  const typeOfShare: OwnershipShareTypes = useWatch({
    control: form.control,
    name: `owners.${index}.typeOfShare` as any,
  })
  const { field } = useController({
    control: form.control,
    name: `owners.${index}.shareOfOwnership` as any,
  })
  const disabledShareOfOwnership =
    disabled ||
    !typeOfShare ||
    typeOfShare === "full" ||
    typeOfShare === "non_dedicated_share"

  const handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    if (typeOfShare === "proportion") {
      const input = e.target as HTMLInputElement
      input.value = input.value.replace(/^\/|[^\d|\/]|(?<=\d*\/\d*)\//g, "")
      field.onChange({
        target: {
          value: input.value,
          name: `owners.${index}.shareOfOwnership`,
        },
        type: "change",
      })
    }
  }

  const handleTypeChange = (value: OwnershipShareTypes) => {
    if (value === "full" || value === "non_dedicated_share") {
      field.onChange({
        target: {
          value: "",
          name: `owners.${index}.shareOfOwnership`,
        },
        type: "change",
      })
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <InputSelect
          label="Type of share"
          name={`owners.${index}.typeOfShare`}
          options={OwnershipShareTypesValues}
          form={form}
          handleChange={handleTypeChange}
          disabled={disabled}
          isRequired
        />
      </Grid>
      <Grid item xs={6}>
        <InputWithController
          label="Share of ownership"
          name={`owners.${index}.shareOfOwnership`}
          form={form}
          disabled={disabledShareOfOwnership}
          type={typeOfShare === "percent" ? "number" : "text"}
          cornerLabel={typeOfShare === "percent" && "%"}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  )
}

export default ApartmentShareFields
