import { makeAutoObservable } from "mobx"
import { Loader } from "kui-utils"
import { OperatingExpenseModel } from "../../types/api/expensesOperatingExpensesAPI"
import ApartmentOperatingExpense from "./ApartmentOperatingExpense"

class ApartmentOperatingExpensesStore {
  operatingExpenses: ApartmentOperatingExpense[]

  periodId: number

  loader: Loader

  constructor(expenses: OperatingExpenseModel[], periodId: number) {
    this.operatingExpenses =
      expenses?.map(
        (expense) => new ApartmentOperatingExpense(expense, this)
      ) ?? []
    this.periodId = periodId
    this.loader = new Loader()
    makeAutoObservable(this)
  }
}

export default ApartmentOperatingExpensesStore
