import React from "react"
import { observer } from "mobx-react"
import { Modal, ModalProps } from "kui-crm"
import useCompanyStore from "../../../../store"
import CompanyObjectConnectionForm from "../../../../forms/CompanyObjectConnectionForm"
import { CompanyObjectConnectionFormFields } from "../../../../forms/CompanyObjectConnectionForm/types"

function CompanyObjectConnectionModal({ open, handleClose }: ModalProps) {
  const { objectsStore, overviewStore } = useCompanyStore()

  const handleSubmit = (data: CompanyObjectConnectionFormFields) => {
    if (overviewStore?.id) {
      objectsStore.addObjectToCompany(overviewStore.id, data)
      handleClose()
    }
  }

  return (
    <Modal title="Add object" open={open} handleClose={handleClose}>
      <CompanyObjectConnectionForm handleSubmit={handleSubmit} />
    </Modal>
  )
}

export default observer(CompanyObjectConnectionModal)
