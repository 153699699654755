import { FileBodyRequest, uploadNewFile } from "kui-crm"
import { clearNotValidFields, Loader, resHandler } from "kui-utils"
import to from "await-to-js"
import {
  MeterReadingModel,
  MeterReadingRequestModel,
} from "../../../pages/RegistriesPage/types/api/metersReadings"
import { MeterReadingFormFields } from "../../../components/forms/meters/MeterReadingForm/types"
import RegistriesAgent from "../../../agent/Registries"

class MetersReadingStore {
  static createMeterReading = async (
    loader: Loader,
    data: MeterReadingFormFields,
    callback?: (data: MeterReadingModel) => void
  ) => {
    loader.startLoading()

    const file = await uploadNewFile(loader, data.file)
    const body = MetersReadingStore.getMeterReadingRequestBody(data, file)

    const response = await to(RegistriesAgent.createMeterReading(body))

    resHandler(response, loader, callback)
  }

  static getMeterReadingRequestBody = (
    data: MeterReadingFormFields,
    document: FileBodyRequest | null
  ): MeterReadingRequestModel =>
    clearNotValidFields({
      apartment: data.apartment.id,
      check_date: data.checkDate?.toISODate() || "",
      next_check_date: data.nextCheck?.toISODate() || "",
      document,
      meter: {
        id: data.meter!.id,
        type: data.meter!.type || data.resource,
        values: MetersReadingStore.getMeterReadingValue(data),
      },
    }) as MeterReadingRequestModel

  static getMeterReadingValue = (data: MeterReadingFormFields) =>
    clearNotValidFields({
      T1: data.T1,
      T2: data.T2,
      T3: data.T3,
      ...(data.resource === "water" && data.meter!.type
        ? {
            [data.meter!.type]: data.value,
          }
        : {
            [data.resource!]: data.value,
          }),
    })
}

export default MetersReadingStore
