import { rest } from "msw"
import { ApartmentModel, FetchApartmentsResponse } from "kui-crm/types"
import { PatchApartmentParams } from "../../pages/ApartmentsPage/types/api/apartmentsAPI"
import {
  fetchApartments,
  fullApartmentMock,
  liteApartmentContractsMock,
  postApartment,
} from "./data"
import { RentalContractLiteModel } from "../../types/api/rental_contract"

const apartmentHandlers = [
  rest.get<FetchApartmentsResponse | Error>(
    `${process.env.REACT_APP_API_URL}/apartments`,
    (req, resp, ctx) => {
      const data = ctx.json(fetchApartments)
      return resp(data)
    }
  ),
  rest.get<ApartmentModel | Error>(
    `${process.env.REACT_APP_API_URL}/apartments/:id`,
    (req, resp, ctx) => {
      const data = ctx.json(fullApartmentMock)
      return resp(data)
    }
  ),
  rest.post<PatchApartmentParams, PatchApartmentParams | Error>(
    `${process.env.REACT_APP_API_URL}/apartments`,
    (req, resp, ctx) => {
      if (
        req.body.area &&
        req.body.address &&
        req.body.rooms_number &&
        req.body.floor &&
        req.body.owner_id &&
        req.body.geolocation
      ) {
        const data = ctx.json(postApartment)
        return resp(data)
      }
      return resp(ctx.status(400))
    }
  ),
  rest.patch<PatchApartmentParams, PatchApartmentParams | Error>(
    `${process.env.REACT_APP_API_URL}/apartments/:id`,
    (req, resp, ctx) => {
      if (
        req.body.area &&
        req.body.address &&
        req.body.rooms_number &&
        req.body.floor &&
        req.body.owner_id &&
        req.body.geolocation
      ) {
        const data = ctx.json(postApartment)
        return resp(data)
      }
      return resp(ctx.status(400))
    }
  ),
  rest.get<RentalContractLiteModel[] | Error>(
    `${process.env.REACT_APP_API_URL}/rental-contracts/lite`,
    (req, resp, ctx) => {
      const data = ctx.json(liteApartmentContractsMock)
      return resp(data)
    }
  ),
  rest.get<any | Error>(
    `${process.env.REACT_APP_API_URL}/apartments/:id/checking`,
    (req, resp, ctx) => resp(ctx.json({}))
  ),
]

export default apartmentHandlers
