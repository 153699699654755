import React from "react"
import { observer } from "mobx-react"
import { Grid } from "kui-basic"
import ApartmentPhotoCard from "../../../../../../../../../components/common/PhotoCard"
import usePromotionStore from "../../../../../store"
import { ImageStoreInterface } from "../../../../../../../../../types/store/image"

type VisibleImagesBlockProps = {
  handleClick: (index: number) => void
  disabled?: boolean
}

function VisibleImagesBlock(props: VisibleImagesBlockProps) {
  const { handleClick, disabled } = props
  const {
    contentStore: { imagesStore },
  } = usePromotionStore()

  const handleDelete = (image: ImageStoreInterface) => {
    image.delete()
  }

  if (!imagesStore.visibleImages?.length) {
    return null
  }

  return (
    <>
      {imagesStore.visibleImages.map((image, i: number) => (
        <Grid item>
          <ApartmentPhotoCard
            disabled={disabled}
            key={image.id}
            image={image}
            onClick={handleClick}
            onDelete={handleDelete}
            index={i}
          />
        </Grid>
      ))}
    </>
  )
}

export default observer(VisibleImagesBlock)
