import { DateTime } from "luxon"
import { makeAutoObservable } from "mobx"
import to from "await-to-js"
import { resHandler } from "kui-utils"
import UserLiteStore from "../../../../store/templates/UserLite"
import {
  ApartmentOwnerModel,
  OwnershipShareTypes,
  OwnershipTransferTypes,
} from "../../types/api/apartmentOwnershipAPI"
import { ApartmentOwnerFormFields } from "../../forms/ownership/ApartmentOwnershipForm/types"
import ApartmentOwnershipAgent from "../../../../agent/ApartmentOwnership"
import { ParentStoreInterface } from "../../../../types/common"
import { ApartmentOwnershipMembers } from "../../components/tabs/ApartmentOwnershipTab/OwnershipMemberModal/types"
import { clearNotValidFields } from "../../../../utils/service/mapper"

class ApartmentOwnerStore {
  id: number

  ownerId: number

  memberType: ApartmentOwnershipMembers

  member: UserLiteStore

  shareOfOwnership: string | number | null

  ownershipTransferType: OwnershipTransferTypes | null

  isRegistered: boolean

  confirmationDocument: string

  documentNumber: string

  numberOfForm: string

  dateOfIssue: DateTime | null

  dateOfTransfer: DateTime | null

  ownershipTransferDocument: string

  certificationAuthority: string

  dateOfEntry: DateTime | null

  registrationNumber: string

  typeOfShare: OwnershipShareTypes | null

  ownersStore: ParentStoreInterface

  constructor(owner: ApartmentOwnerModel, ownersStore: ParentStoreInterface) {
    this.id = owner.id
    this.ownerId = owner.id
    this.member = UserLiteStore.initFromLiteUserModel(owner.user)
    this.shareOfOwnership = owner.ownership_share
    // this.typeOfShare =
    //   (owner.ownership_share_type as OwnershipShareTypes) || null
    this.typeOfShare = owner.ownership_share_type
    this.ownershipTransferType = owner.transfer_type
    this.isRegistered = owner.registered
    this.confirmationDocument = owner.confirmation_document
    this.documentNumber = owner.ownership_transfer_document_number
    this.numberOfForm = owner.form_number
    this.dateOfIssue = owner.date_of_issue
      ? DateTime.fromISO(owner.date_of_issue)
      : null
    this.dateOfTransfer = owner.date_of_transfer
      ? DateTime.fromISO(owner.date_of_transfer)
      : null
    this.dateOfEntry = owner.date_of_entry
      ? DateTime.fromISO(owner.date_of_entry)
      : null
    this.ownershipTransferDocument = owner.ownership_transfer_document
    this.certificationAuthority = owner.certyfing_author
    this.registrationNumber = owner.registration_number
    this.ownersStore = ownersStore
    this.memberType = "owner"
    makeAutoObservable(this)
  }

  delete = async (apartmentId: number) => {
    this.ownersStore.loader.cleanError()
    const [err] = await to(
      ApartmentOwnershipAgent.deleteOwner(this.id, apartmentId)
    )
    if (err) {
      this.ownersStore.loader.setError("owner removal", err)
    } else {
      this.ownersStore.delete(this.id)
    }
  }

  patch = async (
    data: ApartmentOwnerFormFields,
    apartmentId: number,
    cadastralNumber?: string
  ) => {
    this.ownersStore.loader.cleanError()

    const body = ApartmentOwnerStore.getBodyForPatchOwner(data, cadastralNumber)

    const response = await to(
      ApartmentOwnershipAgent.patchOwner(this.id, body, apartmentId)
    )

    resHandler(
      response,
      this.ownersStore.loader,
      this.updateOwner,
      "patch owner",
      {
        withEndLoading: false,
      }
    )
  }

  updateOwner = (owner: ApartmentOwnerModel) => {
    this.member = owner.user
      ? UserLiteStore.initFromLiteUserModel(owner.user)
      : this.member
    this.shareOfOwnership = owner.ownership_share || this.shareOfOwnership
    this.ownershipTransferType =
      owner.transfer_type || this.ownershipTransferType
    this.typeOfShare = owner.ownership_share_type || this.typeOfShare
    this.isRegistered =
      typeof owner.registered === "boolean"
        ? owner.registered
        : this.isRegistered
    this.confirmationDocument =
      owner.confirmation_document || this.confirmationDocument
    this.documentNumber =
      owner.ownership_transfer_document_number || this.documentNumber
    this.numberOfForm = owner.form_number || this.numberOfForm
    this.dateOfIssue = owner.date_of_issue
      ? DateTime.fromISO(owner.date_of_issue)
      : this.dateOfIssue
    this.dateOfTransfer = owner.date_of_transfer
      ? DateTime.fromISO(owner.date_of_transfer)
      : this.dateOfTransfer
    this.dateOfEntry = owner.date_of_entry
      ? DateTime.fromISO(owner.date_of_entry)
      : this.dateOfEntry
    this.ownershipTransferDocument =
      owner.ownership_transfer_document || this.ownershipTransferDocument
    this.certificationAuthority =
      owner.certyfing_author || this.certificationAuthority
    this.registrationNumber =
      owner.registration_number || this.registrationNumber
  }

  static getBodyForPatchOwner = (
    data: ApartmentOwnerFormFields,
    cadastralNumber?: string
  ) =>
    clearNotValidFields({
      user_id: data.member.id,
      certyfing_author: data.certificationAuthority || null,
      confirmation_document: data.confirmationDocument || null,
      ownership_transfer_document: data.ownershipTransferDocument || null,
      registration_number: data.registrationNumber || null,
      ownership_transfer_document_number: data.documentNumber || null,
      form_number: data.numberOfForm || null,
      cadastral_number: cadastralNumber || null,
      transfer_type: data.ownershipTransferType || null,
      ownership_share: data.shareOfOwnership || null,
      ownership_share_type: data.typeOfShare || null,
      registered: Boolean(data.isRegistered),
      date_of_issue: data.dateOfIssue ? data.dateOfIssue.toISODate() : null,
      date_of_transfer: data.dateOfTransfer
        ? data.dateOfTransfer.toISODate()
        : null,
      date_of_entry: data.dateOfEntry ? data.dateOfEntry.toISODate() : null,
    })
}

export default ApartmentOwnerStore
