import { ApartmentFillingModel } from "../../pages/ApartmentsPage/types/api/apartmentsAPI"

const imageURL = "/mock/images/companyLogo.png"
const imageMock = {
  id: 1,
  image_name: "Фоточка",
  image_url: imageURL,
  image_small_url: imageURL,
  image_medium_url: imageURL,
  image_large_url: imageURL,
  size: 50000,
}

const electroFillingMock: ApartmentFillingModel = {
  id: 1,
  model: "LG",
  description: 'LED телевизор LG 43LM5500 Диагональ 43" (109 см) со Smart TV',
  feature: {
    name: "Телевизор",
    id: 1,
    site_image: "",
    inform_if_absent: true,
    feature_type: "appliance",
  },
  photos: [imageMock],
  serial_code: "S/N 2098HLPC902542 N",
  amount: 2,
  instruction: {
    pk: 1,
    name: "Инструкция.pdf",
    size: 5000,
    attachment: "",
  },
}

const finishFillingMock: ApartmentFillingModel = {
  id: 2,
  description: "Окно двухгамное подвесное",
  model: "tyyu",
  feature: {
    name: "Окно",
    id: 2,
    site_image: "",
    inform_if_absent: true,
    feature_type: "finish",
  },
  photos: [imageMock, imageMock, imageMock, imageMock, imageMock, imageMock],
}

const fillingListMock = {
  finish: [finishFillingMock],
  furniture: [],
  electronics: [electroFillingMock],
  accessories: [],
}

export { fillingListMock, electroFillingMock, finishFillingMock }
