import React from "react"
import { Box } from "kui-basic"
import { observer } from "mobx-react"
import InputsGroupWithTitle from "../../../../../../../components/common/InputsGroupWithTitle"
import { TenantOfferFieldsProps } from "./types"
import tenantOfferFields from "./fields"
import useInspectionStore from "../../../store"

const TenantOfferFields = ({ form }: TenantOfferFieldsProps) => {
  const { editor } = useInspectionStore()
  const { isEditing } = editor

  return (
    <Box pt={1} pb={3}>
      <InputsGroupWithTitle
        title="Offer"
        form={form}
        fields={tenantOfferFields}
        isEditing={isEditing}
      />
    </Box>
  )
}

export default observer(TenantOfferFields)
