import React from "react"
import { observer } from "mobx-react"
import useDashboardStore from "../../store"
import DashboardPageWrapper from "../../components/common/DashboardPageWrapper"
import AdminDashboardTabs from "./AdminDashboardTabs"

const AdminDashboard = () => {
  const { adminDashboard } = useDashboardStore()
  const { dashboardStore } = adminDashboard

  return (
    <DashboardPageWrapper dashboardStore={dashboardStore}>
      <AdminDashboardTabs />
    </DashboardPageWrapper>
  )
}

export default observer(AdminDashboard)
