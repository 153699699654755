import * as yup from "yup"
import { isValidWithMaskExp } from "../../../../utils/content/regexp"

const ClientEmploymentSchema = yup.object().shape(
  {
    companyITN: yup.string().when("companyITN", {
      is: (value: string) => value,
      then: (rule) =>
        rule.min(10, "Enter a valid ITN").max(12, "Enter a valid ITN"),
    }),
    income: yup
      .number()
      .when("income", {
        is: (value: number) => value,
        then: (rule) => rule.positive(),
      })
      .nullable(),
    workPhone: yup.object({
      phoneNumber: yup
        .string()
        .when("workPhone.phoneNumber", {
          is: (value: string) => value,
          then: (rule) =>
            rule.matches(isValidWithMaskExp, "Enter a valid phone"),
        })
        .nullable(),
    }),
    workEmail: yup.string().email("Enter a valid email"),
  },
  [
    ["companyITN", "companyITN"],
    ["income", "income"],
  ]
)

export default ClientEmploymentSchema
