import { DateTime } from "luxon"
import { makeAutoObservable } from "mobx"
import { callPromises, resHandler, toNumber } from "kui-utils"
import {
  FileBodyRequest,
  AppraisalInspectionModel,
  ComparableObjectModel,
  StatisticalEvaluationModel,
  AppraisalTenantOfferModel,
  AppraisalInspectionFields,
} from "kui-crm"
import to from "await-to-js"
import {
  AppraisalManagerValuationParams,
  AppraisalOfferParams,
  ComparableObjectParams,
  StatisticalEvaluationParams,
} from "../../types/store/appraisalInspection"
import {
  AppraisalOfferStatusParams,
  InspectionBasedOnParams,
} from "../../../../types/store/apartmentInspections"
import FileStore from "../../../../../../store/templates/File"
import { AppraisalInspectionFormFields } from "../../variants/AppraisalInspectionInfo/types"
import InspectionPageStore from "../InspectionPageStore"
import ApartmentInspectionsLiteStore from "../../../../../../store/lites/ApartmentInspectionsLiteStore"
import { ClientRoles } from "../../../../../../types/common"
import ApartmentInspectionStore from "../../../../store/inspections/ApartmentInspectionStore"
import ApartmentInspectionAgent from "../../../../../../agent/ApartmentInspection"

class AppraisalInspectionStore {
  id: number

  date: DateTime | null

  basedOn: InspectionBasedOnParams | null

  clientRole: ClientRoles

  dropboxLink: string

  comparableObjects: ComparableObjectParams[]

  statisticalEvaluations: StatisticalEvaluationParams[]

  managerValuation: AppraisalManagerValuationParams

  tenantOffer: AppraisalOfferParams | null

  inspectionStore: InspectionPageStore

  tenantOfferStatus: AppraisalOfferStatusParams | null

  constructor(
    inspection: AppraisalInspectionModel,
    inspectionStore: InspectionPageStore
  ) {
    this.id = inspection.id
    this.date = inspection.date ? DateTime.fromISO(inspection.date) : null
    this.basedOn = InspectionPageStore.getBasedOnInspection(inspection.based_on)
    this.comparableObjects = AppraisalInspectionStore.getComparableObjects(
      inspection.appraisal.comparable_objects
    )
    this.statisticalEvaluations =
      AppraisalInspectionStore.getStatisticalEvaluations(
        inspection.appraisal.statistical_valuations
      )
    this.managerValuation = AppraisalInspectionStore.getManagerValuation(
      inspection.appraisal
    )
    this.tenantOffer = AppraisalInspectionStore.getTenantOffer(
      inspection.appraisal
    )
    this.inspectionStore = inspectionStore
    this.dropboxLink = inspection.dropbox_link || ""
    this.clientRole = inspection.appraisal.appraisal_for
    this.tenantOfferStatus = AppraisalInspectionStore.getTenantOfferStatus(
      inspection.appraisal.conclusion
    )
    makeAutoObservable(this)
  }

  resendOffer = async () => {
    const apartmentId = this.inspectionStore.apartmentStore.overviewStore.id
    const inspectionId = this.inspectionStore.inspectionInfo?.id

    if (apartmentId && inspectionId) {
      this.inspectionStore.actionLoader.startLoading()

      const response = await to(
        ApartmentInspectionAgent.resendOffer(apartmentId, inspectionId)
      )

      resHandler(
        response,
        this.inspectionStore.actionLoader,
        this.updateOfferInfo
      )
    }
  }

  updateOfferInfo = () => {
    this.tenantOfferStatus = this.tenantOfferStatus
      ? {
          offer: null,
          signStatus: {
            ...this.tenantOfferStatus.signStatus,
            status: "notSigned",
          },
        }
      : null
  }

  updateInspectionInfo = (inspection: AppraisalInspectionModel) => {
    this.date = inspection.date ? DateTime.fromISO(inspection.date) : null
    this.basedOn = InspectionPageStore.getBasedOnInspection(inspection.based_on)
    this.comparableObjects = AppraisalInspectionStore.getComparableObjects(
      inspection.appraisal.comparable_objects
    )
    this.statisticalEvaluations =
      AppraisalInspectionStore.getStatisticalEvaluations(
        inspection.appraisal.statistical_valuations
      )
    this.managerValuation = AppraisalInspectionStore.getManagerValuation(
      inspection.appraisal
    )
    this.tenantOffer = AppraisalInspectionStore.getTenantOffer(
      inspection.appraisal
    )
    this.tenantOfferStatus = AppraisalInspectionStore.getTenantOfferStatus(
      inspection.appraisal.conclusion
    )
  }

  patchInspection = async (data: AppraisalInspectionFormFields) => {
    const objectFiles = data.comparableObjects.map(
      (object) => object.screenshot || null
    )
    const valuationsFiles = data.statisticalValuations.map(
      (valuation) => valuation.screenshot || null
    )
    const files = await callPromises([
      this.inspectionStore.actualizeFiles(objectFiles),
      this.inspectionStore.actualizeFiles(valuationsFiles),
    ])

    return AppraisalInspectionStore.getPatchInspectionBody(
      data,
      files[0],
      files[1]
    )
  }

  static getPatchInspectionBody = (
    inspection: AppraisalInspectionFormFields,
    objectsFiles: (FileBodyRequest | null)[],
    valuationsFiles: (FileBodyRequest | null)[]
  ) => ({
    dropbox_link: inspection.dropboxLink,
    appraisal: {
      free_of_charge: inspection.managerValuation.freeServices,
      examples_of_tours: inspection.managerValuation.examplesOfTours,
      ...AppraisalInspectionStore.getAppraisalOfferBody(inspection),
      ...ApartmentInspectionsLiteStore.getAppraisalInspectionFields(
        { ...inspection, ...inspection.managerValuation },
        objectsFiles,
        valuationsFiles
      ),
    },
  })

  static getAppraisalOfferBody = (
    inspection: AppraisalInspectionFormFields
  ) => {
    if (inspection.offer?.nowPrice && inspection.offer.laterPrice) {
      return {
        offer: {
          now_price: toNumber(inspection.offer.nowPrice),
          later_price: toNumber(inspection.offer.laterPrice),
        },
      }
    }
    return {
      offer: null,
    }
  }

  static getComparableObjects = (
    objects: ComparableObjectModel[]
  ): ComparableObjectParams[] =>
    objects?.map((object) => ({
      id: object.id,
      link: object.advertisement_link,
      differences: object.key_object_differences,
      price: Number(object.object_price),
      screenshot: object.advertisement_screenshot
        ? FileStore.initFromImageModel(
            object.advertisement_screenshot,
            "Screenshot"
          )
        : null,
    })) || []

  static getStatisticalEvaluations = (
    evaluations: StatisticalEvaluationModel[]
  ): StatisticalEvaluationParams[] =>
    evaluations?.map((evaluation) => ({
      ...evaluation,
      minPrice: Number(evaluation.min_price),
      maxPrice: Number(evaluation.max_price),
      dataSourceName: evaluation.source_name,
      screenshot: evaluation.screenshot
        ? FileStore.initFromImageModel(evaluation.screenshot, "Screenshot")
        : null,
    })) || []

  static getManagerValuation = (
    inspection: AppraisalInspectionFields
  ): AppraisalManagerValuationParams => ({
    minPrice: Number(inspection.min_price),
    maxPrice: Number(inspection.max_price),
    advertisingPeriod: Number(inspection.advertising_period),
    recommendations: inspection.recommendations || "",
    ownerWishes: inspection.owner_wishes || "",
    freeServices: inspection.free_of_charge || "",
    examplesOfTours: inspection.examples_of_tours || "",
    averagePrice: Number(inspection.average_price),
  })

  static getTenantOffer = (
    inspection: AppraisalInspectionFields
  ): AppraisalOfferParams | null => {
    if (!inspection.offer) return null

    return {
      nowPrice: Number(inspection.offer?.now_price),
      laterPrice: Number(inspection.offer?.later_price),
      date: inspection.offer?.date
        ? DateTime.fromISO(inspection.offer?.date)
        : null,
    }
  }

  static getTenantOfferStatus = (
    offer: AppraisalTenantOfferModel | null
  ): AppraisalOfferStatusParams | null => {
    if (!offer) return null

    return {
      offer: offer.new_price
        ? {
            date: offer.new_price_valid_from
              ? DateTime.fromISO(offer.new_price_valid_from)
              : null,
            price: Number(offer.new_price),
          }
        : null,
      signStatus: ApartmentInspectionStore.getFeedbackParams(offer)!,
    }
  }
}

export default AppraisalInspectionStore
