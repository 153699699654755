import React from "react"
import { observer } from "mobx-react"
import useRegistriesStore from "../../../store"
import ServicesRegistryStore from "../../../store/variants/ServicesRegistryStore"
import { ApartmentServiceModalProps } from "./types"
import ServiceCreationModal from "../../../../../components/modals/ServiceCreationModal"

const ApartmentServiceModal = (props: ApartmentServiceModalProps) => {
  const { activeRegistryStore } = useRegistriesStore()
  const expensesRegistry = activeRegistryStore as ServicesRegistryStore

  return (
    <ServiceCreationModal
      withApartment
      handleSubmit={expensesRegistry.createService}
      formMaker={expensesRegistry.creationForm}
      {...props}
    />
  )
}

export default observer(ApartmentServiceModal)
