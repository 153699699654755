import React from "react"
import { Caption } from "kui-basic"
import DashboardListRow from "../../../common/DashboardListRow"
import { CompanyRequisitesRowProps } from "./types"
import { defaultApartmentImageURL } from "../../../../../../utils/content/constants"
import { DynamicRoutes } from "../../../../../../types/route"
import DashboardArchiveRow from "../../../common/DashboardArchiveRow"

const CompanyRequisitesRow = (props: CompanyRequisitesRowProps) => {
  const { company, isArchive } = props
  const companyRequisitesLink = `${DynamicRoutes.company(company.id)}?tab=bank`
  const description = isArchive ? (
    <Caption size="xs" weight={500} color="fiftyP">
      {company.notificationDate?.toFormat("dd.MM.yyyy")}
    </Caption>
  ) : (
    ""
  )

  const rowProps = {
    logo: company.logo || defaultApartmentImageURL,
    label: company.name,
    description,
    link: companyRequisitesLink,
  }

  return isArchive ? (
    <DashboardArchiveRow {...rowProps} onClick={company.restoreCompany} />
  ) : (
    <DashboardListRow {...rowProps} onClick={company.archiveCompany} />
  )
}

export default CompanyRequisitesRow
