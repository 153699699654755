import React from "react"
import { observer } from "mobx-react"
import { LoaderState } from "kui-crm"
import useMetersStore from "../../store"
import ErrorComponent from "../../../../components/common/ErrorComponent"
import Container from "../../../../components/ui/Container"
import { MetersTableProps } from "./types"
import MeterRow from "../MeterRow"
import metersHeadCellsParams from "./headParams"
import { SortingTableHeadCellParams } from "../../../../components/common/SortingTable/types"
import SortingTable from "../../../../components/common/SortingTable"

function MetersTable({ resource }: MetersTableProps) {
  const { resource: metersStore } = useMetersStore()
  const { loader, meters, creationLoader } = metersStore
  const headParams = metersHeadCellsParams(
    resource
  ) as SortingTableHeadCellParams[]

  if (loader.hasError) {
    return (
      <Container>
        <ErrorComponent withPage={false} />
      </Container>
    )
  }

  return (
    <>
      <SortingTable
        withOverlaps
        offset={188}
        headParams={headParams}
        store={metersStore}
      >
        {meters.map((meter, key) => (
          <MeterRow key={meter.id} meter={meter} isDark={key % 2 !== 0} />
        ))}
      </SortingTable>

      <LoaderState loader={creationLoader} />
    </>
  )
}

export default observer(MetersTable)
