import React from "react"
import { Grid } from "kui-basic"
import PriceTerm from "../../../../../../../../../components/common/PriceTerm"
import AccentBlock from "../../../../../../../../../components/common/AccentBlock"
import usePromotionStore from "../../../../../store"

const PriceTerms = () => {
  const { channelsStore } = usePromotionStore()
  return (
    <AccentBlock>
      <Grid container spacing={7} wrap="nowrap">
        <PriceTerm
          value={channelsStore.contentStore.minPrice}
          secondValue={channelsStore.contentStore.maxPrice}
          label="Manager's estimated price"
        />
        <PriceTerm
          value={channelsStore.contentStore.predictorPrice}
          label="Model’s estimated price"
        />
        <PriceTerm
          value={channelsStore.contentStore.currentPrice}
          label="Current advertising price"
        />
      </Grid>
    </AccentBlock>
  )
}

export default PriceTerms
