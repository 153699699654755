import React, { useEffect } from "react"
import { observer } from "mobx-react"
import SectionTitle from "../../../../../../components/common/SectionTitle"
import PromotionDescriptionFields from "./PromotionDescriptionFields"
import PromotionLinksFields from "./PromotionLinksFields"
import {
  PromotionDescriptionFormFields,
  PromotionDescriptionFormProps,
} from "./types"
import usePromotionStore from "../../store"

const PromotionDescriptionForm = ({ form }: PromotionDescriptionFormProps) => {
  const { contentStore, editor } = usePromotionStore()

  const handleSubmit = async (data: PromotionDescriptionFormFields) => {
    editor.endEditing()
    await contentStore.descriptionStore.patchPromotionContent(data)
  }

  useEffect(() => {
    if (editor.isAllowedToSendForm) {
      form.handleSubmit(handleSubmit, editor.blockToSendForm)()
    }
  }, [editor.isAllowedToSendForm])

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)}>
      <PromotionDescriptionFields form={form} disabled={!editor.isEditing} />

      <SectionTitle title="Links" />
      <PromotionLinksFields form={form} disabled={!editor.isEditing} />
    </form>
  )
}

export default observer(PromotionDescriptionForm)
