import * as React from "react"
import styled from "@emotion/styled"
import { Modal } from "kui-crm"
import { NoteModalProps } from "./types"
import { NoteFields } from "../../forms/note/NoteForm/types"
import NoteForm from "../../forms/note/NoteForm"

function NoteEditingModal({ open, handleClose, note }: NoteModalProps) {
  const defaultValues = { text: note.text }

  const handleSubmit = (data: NoteFields) => {
    note.edit(data)
    handleClose()
  }

  return (
    <Modal open={open} handleClose={handleClose} title="Edit note">
      <StyledWrapper>
        <NoteForm
          handleSubmit={handleSubmit}
          variant="edit"
          defaultValues={defaultValues}
        />
      </StyledWrapper>
    </Modal>
  )
}

export default NoteEditingModal

const StyledWrapper = styled.div`
  padding: 0 40px 12px;
`
