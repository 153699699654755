import React, { useEffect } from "react"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { Box } from "kui-basic"
import { LoaderState } from "kui-crm"
import ClientEmploymentForm from "../../../forms/ClientEmploymentForm"
import Loading from "../../../../../components/common/Loading"
import Container from "../../../../../components/ui/Container"
import useClientStore from "../../../store"

function ClientEmployment() {
  const { employmentStore, overviewStore } = useClientStore()

  useEffect(() => {
    if (overviewStore?.id) {
      employmentStore.fetchClientEmployment(overviewStore.id)
      employmentStore.documentsStore.fetchDocuments(overviewStore.id)
    }
  }, [overviewStore?.id])

  if (employmentStore.loader.isLoading) {
    return <Loading />
  }

  return (
    <Container>
      <StyledWrapper pt={3}>
        <ClientEmploymentForm />
      </StyledWrapper>

      <LoaderState loader={employmentStore.loader} onlyError />
      <LoaderState loader={employmentStore.actionLoader} />
    </Container>
  )
}

export default observer(ClientEmployment)

const StyledWrapper = styled(Box)`
  border-top: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
`
