import React, { ChangeEvent } from "react"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { Checkbox, Caption, Heading, Button, Box, Grid } from "kui-basic"
import { useToggle } from "kui-utils"
import { PlusIcon } from "kui-icon"
import useCompanyStore from "../../../../store"
import Container from "../../../../../../components/ui/Container"
import CompanyObjectConnectionModal from "../CompanyObjectConnectionModal"

function CompanyObjectsHeader() {
  const { objectsStore } = useCompanyStore()
  const [isModalOpen, handleModalOpen, handleModalClose] = useToggle()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) objectsStore.selectAllObjects()
    else objectsStore.unSelectAllObjects()
  }

  return (
    <Container>
      <Box pt={2} mb={2}>
        <Grid container justify="space-between">
          <Heading size="h4">Objects</Heading>
          <Grid item>
            <Grid container>
              <StyledCheckbox
                label={
                  <Caption size="xs" weight={500} color="fiftyP">
                    Select all
                  </Caption>
                }
                onChange={handleChange}
              />
              <Button
                isCircle
                variant="whiteWithGray"
                size="xs"
                onClick={handleModalOpen}
              >
                <PlusIcon width={10} height={10} />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <CompanyObjectConnectionModal
        open={isModalOpen}
        handleClose={handleModalClose}
      />
    </Container>
  )
}

export default observer(CompanyObjectsHeader)

const StyledCheckbox = styled(Checkbox)`
  margin-right: 24px;
  flex-direction: row-reverse;
  .KUI-Label_checkbox {
    margin-left: 0;
    margin-right: 10px;
  }
`
