import requests from "./index"
import {
  PatchRentalContractParams,
  PatchRentalContractRequest,
} from "../types/api/rental_contract"

const RentalContractAgent = {
  getById: (id: number) => requests.get(`/rental-contracts/${id}`),
  patch: (id: number, data: PatchRentalContractParams) =>
    requests.patch(`/rental-contracts/${id}`, data),
  createPendingChange: (id: number, data: PatchRentalContractRequest) =>
    requests.post(`/rental-contracts/${id}/changes`, data),
  delete: (id: number) => requests.delete(`/rental-contracts/${id}`),
  getDocuments: (id: number) =>
    requests.get(`/rental-contracts/${id}/documents`),
  postDocument: (data: FormData, id: number) =>
    requests.post(`/rental-contracts/${id}/documents`, data),
  patchDocument: (documentId: number, data: FormData, id: number) =>
    requests.patch(`/rental-contracts/${id}/documents/${documentId}`, data),
  deleteDocument: (documentId: number, id: number) =>
    requests.delete(`/rental-contracts/${id}/documents/${documentId}`),
}

const RentalContractChangesAgent = {
  getChanges: (id: number, isApplied?: boolean) =>
    requests.get(`/rental-contracts/${id}/changes?applied=${!!isApplied}`),
  deleteChange: (rcId: number, id: number) =>
    requests.delete(`/rental-contracts/${rcId}/changes/${id}`),
  editChange: (rcId: number, id: number, data: PatchRentalContractRequest) =>
    requests.put(`/rental-contracts/${rcId}/changes/${id}`, data),
}

export default RentalContractAgent
export { RentalContractChangesAgent }
