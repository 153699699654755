import { makeAutoObservable } from "mobx"
import { CompanyModel } from "kui-crm/types"
import { CompanyTypes } from "../../CompanyPage/types/store/companyOverview"
import { matchesAPICompanyTypes } from "../../../utils/content/matches"

class CompanyStore {
  id: number

  name: string

  logo?: string | null

  objectsCount: number

  address: string

  email: string

  phone: string

  type: CompanyTypes

  constructor(company: CompanyModel) {
    this.id = company.id
    this.name = company.name
    this.logo = company.logo
    this.objectsCount = company.apartments_count
    this.address = company.main_office?.address || "-"
    this.email = company.main_office?.email || "-"
    this.phone = company.main_office?.phone || "-"
    this.type = matchesAPICompanyTypes[company.provider_type] as CompanyTypes
    makeAutoObservable(this)
  }
}

export default CompanyStore
