import React from "react"
import { MeterInfoStepProps } from "kui-crm_actions"
import { observer } from "mobx-react"
import InspectionImagesStep from "../../InspectionImagesStep"
import useApartmentLiteStore from "../../../../../../store/shared/apartment"
import getMeterLabel from "../../../../../../utils/service/getMeterLabel"

const MeterPhotoStep = (props: MeterInfoStepProps) => {
  const { index, resource } = props
  const { inspectionsStore } = useApartmentLiteStore()
  const formStore = inspectionsStore.creationForm
  const type = formStore.fields?.meters?.[index].type
  const label = `Add ${getMeterLabel(resource, type)} photos`

  return (
    <InspectionImagesStep
      withComment={false}
      description={label}
      entityName={`meters.${index}`}
      name="images"
    />
  )
}

export default observer(MeterPhotoStep)
