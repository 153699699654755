import React from "react"
import { observer } from "mobx-react"
import { MeterFeaturesFormFields, MeterFeaturesForm } from "kui-crm"
import { GasMeterFormProps } from "./types"
import useExpensesStore from "../../../store"

function HeatingMeterForm({
  handleClose,
  handleSubmit: onSubmit,
}: GasMeterFormProps) {
  const { metersStore, overviewStore } = useExpensesStore()
  const heatingStore = metersStore.heatingMetersStore
  const activeHeatingMeters = heatingStore?.activeHeatingMeters
  const hasPayer = overviewStore.actualServiceContract?.metersPayers.heating
  const operatingAccount = overviewStore.operatingAccounts?.heating
  const isNewResource = heatingStore?.heatingMeters.length === 0 && !hasPayer

  const handleSubmit = (data: MeterFeaturesFormFields) => {
    const creationForm = heatingStore?.creationForm
    if (creationForm) {
      onSubmit(data)
      creationForm.resetForm()
      handleClose()
    }
  }

  return (
    <MeterFeaturesForm
      resource="heating"
      formStore={heatingStore?.creationForm}
      replacements={activeHeatingMeters}
      handleSubmit={handleSubmit}
      isNewResource={isNewResource}
      maxMeters={heatingStore?.maxCountersCount}
      operatingAccount={operatingAccount}
      administrativeCompany={overviewStore.managementCompany}
      apartmentId={overviewStore.id}
    />
  )
}

export default observer(HeatingMeterForm)
