import { rest } from "msw"
import { ClientModel, FetchClientsParams } from "../../types/api/clients"
import {
  partialUserMock,
  usersMock,
  postUser,
  userEmploymentMock,
  userBankAccMock,
} from "./data"
import {
  PatchUserParams,
  UserBankAccModel,
  UserEmploymentModel,
} from "../../pages/ClientPage/types/api/clientAPI"

const userHandlers = [
  rest.post<PatchUserParams, PatchUserParams | Error>(
    `${process.env.REACT_APP_API_URL}/users`,
    (req, resp, ctx) => {
      if (req.body.username && req.body.phone) {
        const data = ctx.json(postUser)
        return resp(data)
      }
      return resp(ctx.status(400))
    }
  ),
  rest.get<FetchClientsParams | Error>(
    `${process.env.REACT_APP_API_URL}/users`,
    (req, resp, ctx) => {
      let data
      if (req.url.searchParams.get("search")) {
        data = ctx.json({
          ...usersMock,
          results: usersMock.results.filter((user) =>
            user.full_name
              .toLowerCase()
              .includes(req.url.searchParams.get("search")!.toLowerCase())
          ),
        })
      } else {
        data = ctx.json(usersMock)
      }
      return resp(data)
    }
  ),
  rest.get<ClientModel | Error>(
    `${process.env.REACT_APP_API_URL}/users/:id`,
    (req, resp, ctx) => {
      const data = ctx.json(partialUserMock)
      return resp(data)
    }
  ),
  rest.patch<PatchUserParams, PatchUserParams | Error>(
    `${process.env.REACT_APP_API_URL}/users/:id`,
    (req, resp, ctx) => {
      if (req.body.middle_name) {
        const data = ctx.json(postUser)
        return resp(data)
      }
      return resp(ctx.status(400))
    }
  ),
  rest.get<UserEmploymentModel | Error>(
    `${process.env.REACT_APP_API_URL}/users/:id/employment`,
    (req, resp, ctx) => {
      const data = ctx.json(userEmploymentMock)
      return resp(data)
    }
  ),
]

const userTabsHandlers = [
  rest.get<UserEmploymentModel | Error>(
    `${process.env.REACT_APP_API_URL}/users/:id/employment`,
    (req, resp, ctx) => {
      const data = ctx.json(userEmploymentMock)
      return resp(data)
    }
  ),
  rest.get<UserBankAccModel | Error>(
    `${process.env.REACT_APP_API_URL}/users/:id/bank`,
    (req, resp, ctx) => {
      const data = ctx.json(userBankAccMock)
      return resp(data)
    }
  ),
]

export default userHandlers

export { userTabsHandlers }
