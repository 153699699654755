import * as React from "react"
import styled from "@emotion/styled"
import { Caption } from "kui-basic"
import { ImageCardWithBackdropProps } from "./types"
import ImageCardWithLoupe from "../../ImageCardWithLoupe"

function ImageCardWithBackdrop({
  children,
  className,
  ...imageCardProps
}: ImageCardWithBackdropProps) {
  return (
    <StyledContainer className={className}>
      <StyledImageCard {...imageCardProps} />
      <StyledBackdrop className="ImageCard-backdrop">
        <Caption weight={600} color="zero" disableUserSelect>
          {children}
        </Caption>
      </StyledBackdrop>
    </StyledContainer>
  )
}

export default ImageCardWithBackdrop

const StyledImageCard = styled(ImageCardWithLoupe)`
  svg {
    display: none;
  }
`

const StyledContainer = styled.div`
  position: relative;
`

const StyledBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 56px;
  height: 56px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`
