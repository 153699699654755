import React from "react"
import { observer } from "mobx-react"
import { Avatar } from "kui-complex"
import { getPhoneNumberFromPhoneParams } from "kui-utils"
import AboutPageHeader from "../../../../../components/common/AboutPageHeader"
import useClientStore from "../../../store"

function ClientOverviewHeader() {
  const { overviewStore } = useClientStore()

  const clientInfo = [
    { title: overviewStore?.email, subtitle: "Email" },
    {
      title: overviewStore?.phoneNumber
        ? getPhoneNumberFromPhoneParams(overviewStore?.phoneNumber, " ")
        : "-",
      subtitle: "Phone",
    },
  ]

  return (
    <AboutPageHeader
      icon={
        <Avatar
          size="lg"
          imageUrl={overviewStore?.avatar}
          fullName={overviewStore?.name}
        />
      }
      title={overviewStore?.name}
      subtitle={`${overviewStore?.type === "company" ? "Company " : ""}${
        overviewStore?.role || "-"
      }`}
      info={clientInfo}
    />
  )
}

export default observer(ClientOverviewHeader)
