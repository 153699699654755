import React from "react"
import { observer } from "mobx-react"
import useExpensesStore from "../../../store"
import ExpensesAttachments from "../../../../../components/common/ExpensesAttachments"

function ExpensesAttachmentsTab() {
  const {
    clientsAttachmentsStore,
    internalAttachmentsStore,
    overviewStore,
    date,
  } = useExpensesStore()

  if (!overviewStore.id) return null

  return (
    <ExpensesAttachments
      clientsAttachments={clientsAttachmentsStore}
      internalAttachments={internalAttachmentsStore}
      apartmentId={overviewStore.id}
      date={date}
    />
  )
}

export default observer(ExpensesAttachmentsTab)
