import to from "await-to-js"
import { makeAutoObservable, runInAction } from "mobx"
import { Loader } from "kui-utils"
import { DocumentModel } from "kui-crm/types"
import {
  DocumentsAgentInterface,
  DocumentsStoreInterface,
} from "../../types/store/file"
import DocumentStore from "./DocumentStore"
import { UploadDocumentParams } from "../../components/common/DocumentsBlockWithForm/types"
import { uploadDocument } from "../../utils/agent/uploadFiles"

class DocumentsStore implements DocumentsStoreInterface {
  documents: DocumentStore[]

  loader: Loader

  agent: DocumentsAgentInterface

  constructor(agent: DocumentsAgentInterface, documents?: DocumentModel[]) {
    this.documents =
      documents?.map(
        (document) =>
          new DocumentStore(
            document,
            {
              loader: this.loader,
              deleteDocument: this.deleteDocument,
            },
            agent
          )
      ) || []
    this.loader = new Loader()
    this.agent = agent
    makeAutoObservable(this)
  }

  fetchDocuments = async (...params: any) => {
    if (this.agent.getDocuments) {
      const [err, res] = await to<DocumentModel[]>(
        this.agent.getDocuments(...params)
      )

      runInAction(() => {
        if (res && !err) {
          this.documents = res.map(
            (document) =>
              new DocumentStore(
                document,
                {
                  loader: this.loader,
                  deleteDocument: this.deleteDocument,
                },
                this.agent
              )
          )
        } else {
          this.loader.setError("fetch documents")
        }
      })
    }
  }

  createDocuments = async (data: UploadDocumentParams[], ...params: any) => {
    const promises: Promise<any>[] = []

    data.forEach((documentFields) => {
      if (!documentFields.id) {
        promises.push(this.createDocument(documentFields, ...params))
      }
    })

    await Promise.allSettled(promises)
  }

  createDocument = async (data: UploadDocumentParams, ...params: any) => {
    const document = await uploadDocument(this.loader, data)

    if (document) {
      const [err, res] = await to<DocumentModel>(
        this.agent.postDocument(document, ...params)
      )
      if (res && !err) {
        this.addDocument(res)
      } else {
        this.loader.setError("document creation")
      }
    }
  }

  patchDocuments = async (data: UploadDocumentParams[], ...params: any) => {
    const initialDocuments = [...this.documents]
    const promises: Promise<any>[] = []
    initialDocuments.forEach((documentStore) => {
      const documentFields = data.find(
        (document) => document.id === documentStore.id
      )

      if (!documentFields)
        promises.push(documentStore.deleteDocument(...params))
      else promises.push(documentStore.patchDocument(documentFields, ...params))
    })
    data.forEach((documentFields) => {
      if (!documentFields.id) {
        promises.push(this.createDocument(documentFields, ...params))
      }
    })

    await Promise.allSettled(promises)
  }

  deleteDocument = (data: number) => {
    this.documents = this.documents.filter((document) => document.id !== data)
  }

  addDocument = (data: DocumentModel) => {
    this.documents = [
      ...this.documents,
      new DocumentStore(
        data as DocumentModel,
        {
          loader: this.loader,
          deleteDocument: this.deleteDocument,
        },
        this.agent
      ),
    ]
  }
}

export default DocumentsStore
