import * as yup from "yup"

const CompanyObjectConnectionSchema = yup.object().shape({
  apartment: yup.object({
    address: yup.string().required("Choose an apartment"),
  }),
  reason: yup.object({
    file: yup.mixed().required("Choose a reason file"),
  }),
})

export default CompanyObjectConnectionSchema
