import to from "await-to-js"
import { makeAutoObservable } from "mobx"
import { Paginator, SortingFilter, Loader, resHandler } from "kui-utils"
import {
  CatalogsAgent,
  CountyModel,
  GetCountiesResponse,
  CountyParams,
} from "kui-crm"

class CountiesStore {
  counties: CountyParams[]

  paginator: Paginator

  filter: SortingFilter

  loader: Loader

  constructor() {
    this.counties = []
    this.loader = new Loader()
    this.paginator = new Paginator()
    this.filter = new SortingFilter()
    makeAutoObservable(this)
  }

  fetchCounties = async () => {
    this.loader.startLoading()

    const response = await to<GetCountiesResponse>(
      CatalogsAgent.getCounties(
        this.paginator.offset,
        this.paginator.limit,
        this.filter.filterParams
      )
    )

    const mapper = (country: CountyModel) => country

    resHandler(response, this.loader, (res) => {
      this.counties = this.paginator.getPageResponse(res, this.counties, mapper)
    })
  }
}

export default CountiesStore
