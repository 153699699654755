import * as yup from "yup"

const ClientCompanyRepresentativeFieldsSchema = yup.object({
  firstName: yup.string().required("This field is required"),
})

const ClientCompanyFieldsSchema = yup.object({
  name: yup.string().required("This field is required"),
})

const ClientCompanyInfoFormSchema = yup.object({
  representative: ClientCompanyRepresentativeFieldsSchema,
  company: ClientCompanyFieldsSchema,
})

export default ClientCompanyInfoFormSchema
