import { makeAutoObservable } from "mobx"
import to from "await-to-js"
import { Loader } from "kui-utils"
import { CompanyBanksInfoFields } from "../forms/CompanyBanksInfoForm/types"
import CompanyBanksStore from "./CompanyBanksStore"
import DocumentsStore from "../../../store/templates/DocumentsStore"
import CompanyAgent from "../../../agent/Company"
import CompanyPageStore from "./CompanyPageStore"
import { CompanyParseBankInfoModel } from "../types/api/companyAPI"
import UploadAgent from "../../../agent/Upload"
import { CompanyBankInfoParams } from "../types/store/companyBank"

class CompanyBanksInfoStore {
  loader: Loader

  actionLoader: Loader

  banksStore: CompanyBanksStore

  documentsStore: DocumentsStore

  companyStore: CompanyPageStore

  constructor(companyStore: CompanyPageStore) {
    this.loader = new Loader()
    this.actionLoader = new Loader()
    this.banksStore = new CompanyBanksStore(this.loader)
    this.documentsStore = new DocumentsStore(CompanyAgent)
    this.companyStore = companyStore
    makeAutoObservable(this)
  }

  fetchBanksInfo = async (companyId: number) => {
    this.loader.startLoading()

    await Promise.allSettled([
      this.banksStore.fetchBanks(companyId),
      this.documentsStore.fetchDocuments(companyId),
    ])

    this.loader.endLoading()
  }

  patchBanksInfo = async (companyId: number, data: CompanyBanksInfoFields) => {
    this.actionLoader.startLoading("company info changes")

    await Promise.allSettled([
      this.banksStore.patchBanks(companyId, data.banks),
      this.documentsStore.patchDocuments(data.documents, companyId),
    ])

    this.actionLoader.endLoading()
  }

  parseCompanyRequisites = async (file: File) => {
    const companyId = this.companyStore.overviewStore?.id

    if (companyId) {
      this.actionLoader.startLoading("read qr")

      const body = new FormData()
      body.append("upload", file)

      const [err, res] = await to<CompanyParseBankInfoModel>(
        UploadAgent.parseRequisites(body)
      )

      if (res && !err) {
        this.actionLoader.endLoading()
        return CompanyBanksInfoStore.getBankInfoParams(res)
      }
      this.actionLoader.endLoading()
      this.actionLoader.setError("", err)
      return null
    }
    return null
  }

  static getBankInfoParams = (
    res: CompanyParseBankInfoModel
  ): CompanyBankInfoParams => ({
    id: res.id,
    name: res.name,
    bankName: res.entity,
    SWIFT: res.swift,
    BIC: res.bic,
    correspondentAccount: res.correspondent_account,
    account: res.checking_account,
  })
}

export default CompanyBanksInfoStore
