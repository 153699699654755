import * as yup from "yup"
import {
  defaultRequiredMessage,
  InspectionBasedOnParams,
  InspectionTypes,
} from "kui-crm"

const InspectionCommonStepSchema = yup.object().shape({
  type: yup.string().required("This field is required"),
  date: yup.mixed().required("This field is required"),
  clientRole: yup.string().when(["type", "basedOn"], {
    is: (type: InspectionTypes, basedOn: InspectionBasedOnParams) =>
      type === "appraisal" && basedOn,
    then: (rule) => rule.required(defaultRequiredMessage),
  }),
  transferType: yup.string().when("type", {
    is: (value: InspectionTypes) => value === "transfer",
    then: (rule) => rule.required(defaultRequiredMessage),
  }),
})

export default InspectionCommonStepSchema
