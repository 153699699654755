import React from "react"
import styled from "@emotion/styled"
import { Caption, Grid } from "kui-basic"
import { Link, PreviewContent } from "kui-crm"
import { ApartmentInspectionRowProps } from "./types"
import { DynamicRoutes } from "../../../../../../types/route"

const ApartmentInspectionRow = (props: ApartmentInspectionRowProps) => {
  const { inspection } = props
  const { apartment } = inspection
  const apartmentLink = `${DynamicRoutes.apartment(
    apartment.id
  )}?tab=inspections`

  return (
    <StyledRow container alignItems="center" justify="space-between">
      <StyledLink href={apartmentLink}>
        <PreviewContent
          imageSrc={apartment.previewImage}
          description={apartment.address}
        />
      </StyledLink>
      <Caption size="s" weight={500}>
        {inspection.date?.toFormat("dd.MM.yyyy")}
      </Caption>
    </StyledRow>
  )
}

export default ApartmentInspectionRow

const StyledRow = styled(Grid)`
  padding: 8px 0;
  flex-wrap: nowrap;
  &:not(&:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
  }
`

const StyledLink = styled(Link)`
  width: auto;
  max-width: calc(100% - 88px);
`
