import React from "react"
import { observer } from "mobx-react"
import { ObjectForMapParams } from "kui-crm_actions"
import { ApartmentInspectionsMapProps } from "./types"
import MapWrapper from "../../../../../components/common/MapWrapper"
import InspectionsMap from "./InspectionsMap"

const ObjectsMap = <T extends ObjectForMapParams>(
  props: ApartmentInspectionsMapProps<T>
) => (
  <MapWrapper>
    <InspectionsMap {...props} />
  </MapWrapper>
)

export default observer(ObjectsMap)
