import { PropertyLinkFieldProps } from "../PropertyLinkField/types"

const promotionChannelsLinksFields: Omit<
  PropertyLinkFieldProps,
  "form" | "disabled"
>[] = [
  {
    label: "Cian",
    name: "cian",
    imageSrc: "/images/propertyPortalsLogos/cian.png",
    withLink: true,
  },
  {
    label: "Avito",
    name: "avito",
    imageSrc: "/images/propertyPortalsLogos/avito.png",
    withLink: true,
  },
  {
    label: "Яндекс.Недвижимость",
    name: "yandex",
    imageSrc: "/images/propertyPortalsLogos/yandex.png",
    withShadow: true,
    withLink: true,
  },
  {
    label: "Maroom",
    name: "maroom",
    imageSrc: "/images/propertyPortalsLogos/maroom.png",
  },
]

export default promotionChannelsLinksFields
