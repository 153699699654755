import React from "react"
import { ObjectNeedInitialRowProps } from "./types"
import { DynamicRoutes } from "../../../../../../types/route"
import ObjectRowWithDetails from "../../../common/ObjectRowWithDetails"
import DaysWithOverdue from "../../../common/DaysWithOverdue"

const ObjectNeedInitialRow = (props: ObjectNeedInitialRowProps) => {
  const { object } = props
  const { apartment, daysWithoutInitial, isOverdue } = object
  const link = DynamicRoutes.inspections(apartment.id)

  return (
    <ObjectRowWithDetails
      withoutImage
      link={link}
      apartment={apartment}
      label="since object creation"
      details={
        <DaysWithOverdue days={daysWithoutInitial} isOverdue={isOverdue} />
      }
    />
  )
}

export default ObjectNeedInitialRow
