import React from "react"
import styled from "@emotion/styled"
import { Grid } from "kui-basic"
import { useWatch } from "react-hook-form"
import { MapComponent } from "kui-crm"
import { DescriptionMapProps } from "./types"
import ApartmentDescriptionFields from "../../../../../components/forms/apartment/ApartmentDescriptionFields"
import MapWrapper from "../../../../../components/common/MapWrapper"
import useStore from "../../../../../hooks/useStore"

function DescriptionFieldsWithMap(props: DescriptionMapProps) {
  const { mapStore } = useStore()
  const { form, disabled } = props
  const coords = useWatch({
    control: form.control,
    name: "geolocation",
  })

  return (
    <>
      <StyledMapWrapper>
        <MapWrapper>
          <MapComponent setMap={mapStore.setMap} center={coords} withMarker />
        </MapWrapper>
      </StyledMapWrapper>
      <StyledWrapper container spacing={3}>
        <ApartmentDescriptionFields form={form} disabled={disabled} />
      </StyledWrapper>
    </>
  )
}
export default DescriptionFieldsWithMap

const StyledWrapper = styled(Grid)`
  position: relative;
  top: -27px;
`

const StyledMapWrapper = styled.div`
  height: 354px;
`
