import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { Loader, getPhoneNumberFromPhoneParams } from "kui-utils"
import { CompanyInfoModel } from "kui-crm"
import CompanyOfficeStore from "./CompanyOfficeStore"
import { CompanyOfficeCreationFields } from "../forms/CompanyOfficeCreationForm/types"
import CompanyPageStore from "./CompanyPageStore"
import CompanyAgent from "../../../agent/Company"
import { CompanyOfficesFields } from "../forms/CompanyOfficesForm/types"
import { clearNotValidFields } from "../../../utils/service/mapper"
import { PostCompanyOfficeRequest } from "../types/api/companyAPI"

class CompanyOfficesStore {
  mainOffice: CompanyOfficeStore | null

  offices: CompanyOfficeStore[]

  loader: Loader

  companyStore: CompanyPageStore

  constructor(companyStore: CompanyPageStore) {
    this.loader = new Loader()
    this.mainOffice = null
    this.offices = []
    this.companyStore = companyStore
    makeAutoObservable(this)
  }

  setOffices = (company: CompanyInfoModel) => {
    this.mainOffice = company.main_office
      ? new CompanyOfficeStore({ id: null, ...company.main_office })
      : null
    this.offices = CompanyOfficesStore.getCompanyOffices(company)
  }

  createCompanyOffice = async (
    id: number,
    data: CompanyOfficeCreationFields
  ) => {
    this.loader.startLoading("company office creation")

    const body = CompanyOfficesStore.getOfficeBody(data)

    const [err, res] = await to(CompanyAgent.postOffice(id, body))
    runInAction(() => {
      if (!err && res) {
        this.offices = [...this.offices, new CompanyOfficeStore(res)]
      } else {
        this.loader.setError("office creation", err)
      }
      this.loader.endLoading()
    })
  }

  patchCompanyOffices = async (
    companyId: number,
    data: CompanyOfficesFields
  ) => {
    this.loader.startLoading("company offices changes")

    const body = CompanyOfficesStore.getPatchInfoBody(data)
    const [err, res] = await to(CompanyAgent.patchCompany(companyId, body))
    await this.patchCompanyBranches(companyId, data)

    runInAction(() => {
      if (!err && res) {
        this.mainOffice?.updateCompanyOffice(res.main_office)
        this.companyStore.overviewStore?.updateOverviewInfo(res)
      } else {
        this.loader.setError("patch offices", err)
      }
      this.loader.endLoading()
    })
  }

  patchCompanyBranches = async (
    companyId: number,
    data: CompanyOfficesFields
  ) => {
    this.offices.forEach((office) => {
      const officeInfo = data.offices.find(
        (officeFields) => office.id && office.id === officeFields.id
      )
      if (officeInfo) {
        office.patchCompanyOffice(companyId, officeInfo)
      } else {
        this.deleteOffice(companyId, office)
      }
    })
  }

  deleteOffice = async (companyId: number, office: CompanyOfficeStore) => {
    const err = await office.deleteCompanyOffice(companyId)
    runInAction(() => {
      if (!err) {
        this.offices = this.offices.filter(
          (officeStore) => officeStore.id !== office.id
        )
      }
    })
  }

  static getPatchInfoBody = (data: CompanyOfficesFields) => ({
    main_office: data.mainOffice
      ? CompanyOfficesStore.getOfficeBody(data.mainOffice)
      : null,
  })

  static getOfficeBody = (data: CompanyOfficeCreationFields) =>
    clearNotValidFields({
      email: data.email,
      address: data.office?.address,
      phone: data.phone ? getPhoneNumberFromPhoneParams(data.phone) : null,
      geolocation: data.office?.location,
    }) as PostCompanyOfficeRequest

  static getCompanyOffices = (company: CompanyInfoModel) =>
    company.offices.map((office) => new CompanyOfficeStore(office))
}

export default CompanyOfficesStore
