import { InspectionSignParams } from "../../../../types/store/apartmentInspections"
import feedbackLabel from "./InspectionFeedbackBillet/content"

const getStatusLabel = (
  prefix: string,
  feedback: InspectionSignParams | null
) => {
  if (!feedback) return ""

  return `${prefix} ${feedbackLabel[feedback.status]}`
}

export default getStatusLabel
