import React from "react"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { MeteringDevicesTableProps } from "./types"
import Table from "../../../../../../../../components/ui/Table"
import TableHeadRow from "../../../../../../../../components/ui/TableHeadRow"
import useExpensesStore from "../../../../store"
import MeterTotalRow from "../MeterTotalRow"
import useApartmentStore from "../../../../../../store"

function MetersTable({
  title,
  handleAdd,
  children,
  metersCount,
  maxMetersCount,
  total,
}: MeteringDevicesTableProps) {
  const { isArchived } = useApartmentStore()
  const { date, periodsStore } = useExpensesStore()
  const { selectedPeriod } = periodsStore
  const nowMonth = date?.toFormat("LLL")
  const prevMonth = date?.minus({ month: 1 }).toFormat("LLL")
  const disabledCreation = isArchived || !selectedPeriod?.canBeEdited

  const tableHeadParams = [
    { label: "Next check", width: "8%" },
    { label: "Payer", width: "11%" },
    { label: "Tariff group", width: "15%", withPadding: true },
    { label: prevMonth || "", width: "6%" },
    { label: nowMonth || "", width: "10%", withPadding: true },
    { label: "Editing date", width: "9%" },
    { label: "Use", width: "6%" },
    { label: "Tariff", width: "8%" },
    { label: "Cost", width: "10%" },
  ]

  if (maxMetersCount === 0 && metersCount === 0) {
    return null
  }

  return (
    <Table>
      <TableHeadRow
        title={
          <>
            {title}
            <StyledAccent>
              {metersCount}/{maxMetersCount || "-"}
            </StyledAccent>
          </>
        }
        titleWidth="13%"
        cells={tableHeadParams}
        disabled={disabledCreation}
        onClick={handleAdd}
      />
      <tbody data-testid="counter_table_body">
        {children}
        {!!(typeof total !== "undefined" && metersCount) && (
          <MeterTotalRow totalPrice={Number(total)} />
        )}
      </tbody>
    </Table>
  )
}

export default observer(MetersTable)

const StyledAccent = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.palette.grey.fiftyP};
  padding-left: 8px;
`
