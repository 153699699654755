import { createContext, useContext } from "react"
import hostStore from "../../../store"
import MeterPageStore from "./MeterPageStore"

const meterStore = new MeterPageStore(hostStore)
const MeterPageContext = createContext(meterStore)

const useMeterStore = () => useContext(MeterPageContext)

export default useMeterStore
export { MeterPageContext }
