import { UpdatedFieldsWithTitleProps } from "../../../../../../components/forms/common/UpdatedFieldsForm/UpdatedFieldsWithTitle/types"
import ClientAutocomplete from "../../../../../../components/entityInputs/ClientAutocomplete"

const getProxyFieldsGroup = (index: number): UpdatedFieldsWithTitleProps => ({
  title: "Proxy",
  fields: [
    {
      label: "Client",
      name: `proxies.${index}.member`,
      variant: "custom",
      CustomInput: ClientAutocomplete,
      size: 8,
      isRequired: true,
    },
    {
      label: "Date of transfer",
      name: `proxies.${index}.dateOfTransfer`,
      variant: "date",
      size: 4,
    },
    {
      label: "Confirmation document",
      name: `proxies.${index}.confirmationDocument`,
      size: 12,
    },
    {
      label: "Certyfing authority",
      name: `proxies.${index}.certificationAuthority`,
      size: 8,
    },
    {
      label: "Document number",
      name: `proxies.${index}.documentNumber`,
      size: 4,
    },
    {
      label: "Number of the form",
      name: `proxies.${index}.numberOfForm`,
      size: 4,
    },
    {
      label: "Date of issue",
      name: `proxies.${index}.dateOfIssue`,
      variant: "date",
      size: 3,
    },
    {
      label: "Date of entry",
      name: `proxies.${index}.dateOfEntry`,
      variant: "date",
      size: 3,
    },
    {
      label: "Registration number",
      name: `proxies.${index}.registrationNumber`,
      size: 3,
    },
  ],
})

export default getProxyFieldsGroup
