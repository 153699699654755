import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Box, Grid } from "kui-basic"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormBottom, InputByType } from "kui-crm"
import { getFillingInfoFields } from "kui-crm_actions"
import { CheckIcon, NextArrowIcon } from "kui-icon"
import { FillingInfoFieldsProps, FillingInfoFormFields } from "./types"
import FillingInfoFieldsSchema from "./schema"

function FillingInfoForm(props: FillingInfoFieldsProps) {
  const {
    type: defaultType,
    formMaker,
    variant,
    handleSubmit,
    defaultValues,
    withType,
    formBottomProps,
  } = props
  const [type, setType] = useState(defaultType)
  const isEditing = variant === "edit"
  const submitLabel = isEditing ? "Save" : "Next"
  const submitIcon = isEditing ? <CheckIcon /> : <NextArrowIcon />

  const form = useForm<FillingInfoFormFields>({
    defaultValues: {
      type,
      ...(defaultValues ?? formMaker?.fields),
    },
    resolver: yupResolver(FillingInfoFieldsSchema),
  })

  const fields = getFillingInfoFields(!!withType, type!, setType)

  const handleFormSubmit = (data: FillingInfoFormFields) => {
    if (handleSubmit) {
      handleSubmit(data)
    } else {
      formMaker?.updateFormFields(data)
      formMaker?.nextStep()
    }
  }

  useEffect(() => {
    setType(defaultType)
  }, [defaultType])

  return (
    <form onSubmit={form.handleSubmit(handleFormSubmit)}>
      <Box pr={5} pl={5} mb={3}>
        <Grid container spacing={2}>
          {fields.map(({ size, ...field }) => (
            <Grid item xs={size || 12} key={field.name}>
              <InputByType form={form} {...field} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <FormBottom
        label={submitLabel}
        endIcon={submitIcon}
        {...formBottomProps}
      />
    </form>
  )
}

export default FillingInfoForm
