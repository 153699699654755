import React from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Grid } from "kui-basic"
import { FormWrapper, InputByType } from "kui-crm"
import {
  ApartmentDocumentFormFields,
  ApartmentDocumentFormProps,
} from "./types"
import ApartmentDocumentFormSchema from "./schema"
import getApartmentDocumentFormFields from "./fields"
import useResetForm from "../../../../../hooks/useResetForm"

const ApartmentDocumentForm = (props: ApartmentDocumentFormProps) => {
  const { handleSubmit, variant } = props
  const form = useForm<ApartmentDocumentFormFields>({
    resolver: yupResolver(ApartmentDocumentFormSchema),
  })

  const fields = getApartmentDocumentFormFields(variant)

  useResetForm(form)

  return (
    <FormWrapper form={form} onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        {fields.map(({ size, ...field }) => (
          <Grid item xs={size} key={field.name}>
            <InputByType form={form} {...field} />
          </Grid>
        ))}
      </Grid>
    </FormWrapper>
  )
}

export default ApartmentDocumentForm
