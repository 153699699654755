import React from "react"
import ComplexMeterRow from "../ComplexMeterRow"
import EditElectricityMeterModal from "../EditElectricityMeterModal"
import MeterRow from "../MeterRow"
import CounterTariffRow from "../MeterTariffRow"
import { ElectricityMeterRowProps } from "./types"
import useExpensesStore from "../../../../store"
import useApartmentStore from "../../../../../../store"

function ElectricityMeterRow({ counter }: ElectricityMeterRowProps) {
  const { isArchived } = useApartmentStore()
  const { metersStore, periodsStore } = useExpensesStore()
  const { electricityMetersStore } = metersStore
  const { selectedPeriod } = periodsStore
  const disabled = !selectedPeriod?.canBeEdited || isArchived

  const handleEditModalOpen = async (counterId: number) =>
    electricityMetersStore?.getElectricityMeterById(counterId)

  return counter.type === "T1" ? (
    <ComplexMeterRow
      counter={counter}
      tariff={counter.t1}
      disabled={disabled}
      onModalOpen={handleEditModalOpen}
      ModalComponent={EditElectricityMeterModal}
    />
  ) : (
    <>
      <MeterRow
        counter={counter}
        disabled={disabled}
        onModalOpen={handleEditModalOpen}
        ModalComponent={EditElectricityMeterModal}
      />
      <CounterTariffRow tariff={counter.t1} disabled={disabled} />
      <CounterTariffRow
        isLast={counter.type === "T2"}
        tariff={counter.t2!}
        disabled={disabled}
      />
      {counter.type === "T3" && (
        <CounterTariffRow isLast tariff={counter.t3!} disabled={disabled} />
      )}
    </>
  )
}

export default ElectricityMeterRow
