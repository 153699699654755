import React from "react"
import { useFieldArray } from "react-hook-form"
import { observer } from "mobx-react"
import ApartmentFillingList from "../../../../../../components/forms/apartmentFilling/FillingList"
import { InspectionNotEditableFillingListProps } from "./types"
import useInspectionStore from "../../store"

const InspectionNotEditableFillingList = (
  props: InspectionNotEditableFillingListProps
) => {
  const { form } = props
  const { editor } = useInspectionStore()
  const { fields } = useFieldArray({
    control: form.control,
    name: "fillingList",
  })

  return (
    <ApartmentFillingList
      disabled={!editor.isEditing}
      form={form}
      fillingList={fields}
    />
  )
}

export default observer(InspectionNotEditableFillingList)
