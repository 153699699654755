import React, { useState } from "react"
import { Grid } from "kui-basic"
import { observer } from "mobx-react"
import PhotoCard from "../PhotoCard"
import UndisplayedPhoto from "../UndisplayedPhoto"
import { PhotoCardsProps } from "./types"
import { PhotoCardParams } from "../PhotoCard/types"
import PhotoSliderAndModal from "../PhotoSliderAndModal"
import useModalProps from "../../../hooks/useModalProps"
import Loading from "../Loading"

const PhotoCards = <T extends PhotoCardParams>(props: PhotoCardsProps<T>) => {
  const {
    images,
    loadImages,
    displayImagesCount,
    onDelete,
    modalProps,
    disabled,
    isLoading,
    ...other
  } = props
  const sliderProps = useModalProps()
  const [selectedImage, setSelectedImage] = useState(0)
  const displayedImages = images.slice(0, displayImagesCount)
  const undisplayedImagesCount = images.slice(displayImagesCount + 1).length
  const blurImage = images[displayImagesCount]

  const handleImageClick = (index: number) => {
    setSelectedImage(Number(index))
    sliderProps.handleOpen()
  }

  const handleUndisplayedClick = () => {
    setSelectedImage(displayImagesCount)
    sliderProps.handleOpen()
  }

  if (isLoading)
    return (
      <Grid item>
        <Loading height={136} />
      </Grid>
    )

  return (
    <>
      {displayedImages.map((image, i: number) => (
        <Grid item>
          <PhotoCard
            key={image.mediumImageUrl}
            image={image}
            onClick={handleImageClick}
            onDelete={onDelete}
            index={i}
            disabled={disabled}
          />
        </Grid>
      ))}
      {!!undisplayedImagesCount && (
        <Grid item>
          <UndisplayedPhoto
            imageUrl={blurImage?.mediumImageUrl}
            undisplayedImagesCount={undisplayedImagesCount}
            handleClick={handleUndisplayedClick}
          />
        </Grid>
      )}

      <PhotoSliderAndModal
        sliderProps={sliderProps}
        modalProps={modalProps}
        loadImages={loadImages}
        images={images}
        onDelete={onDelete}
        selectedImage={selectedImage}
        {...other}
      />
    </>
  )
}

export default observer(PhotoCards)
