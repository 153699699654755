import React from "react"
import { Grid } from "kui-basic"
import { InputByType } from "kui-crm"
import { CloseIcon } from "kui-icon"
import { StatisticalValuationFieldsProps } from "./types"
import getStatisticalValuationFields from "./fields"
import FieldsGroupTitleWithButton from "../../../../../../../components/common/FieldsGroupTitleWithButton"

const StatisticalValuationFields = (props: StatisticalValuationFieldsProps) => {
  const { index, remove, form, disabled } = props

  const fields = getStatisticalValuationFields(index)

  const handleDelete = () => remove(index)

  return (
    <Grid item xs={4}>
      <FieldsGroupTitleWithButton
        title={`statistical valuation ${index + 1}`}
        icon={<CloseIcon width={8} height={8} />}
        onClick={handleDelete}
        variant="grey"
        disabled={disabled}
      />
      <Grid container spacing={3}>
        {fields.map(({ size, ...field }) => (
          <Grid item xs={size || 12} key={field.name}>
            <InputByType form={form} disabled={disabled} {...field} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default StatisticalValuationFields
