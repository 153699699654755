import { createContext, useContext } from "react"
import { apartmentStore } from "../../../store"
import hostStore from "../../../../../store"
import ExpensesStore from "./ApartmentExpenses"

const expensesStore = new ExpensesStore(hostStore, apartmentStore)
const ExpensesContext = createContext(expensesStore)

const useExpensesStore = () => useContext(ExpensesContext)

export default useExpensesStore
export { expensesStore, ExpensesContext }
