import React from "react"
import styled from "@emotion/styled"
import { theme } from "kui-basic"
import { AppraisalStatusProps, AppraisalStatusStylesProps } from "./types"
import { DynamicRoutes } from "../../../../../types/route"
import LinkButton from "../../../../../components/common/LinkButton"
import { appraisalStatusColors, appraisalStatusLabels } from "./content"

const AppraisalStatus = (props: AppraisalStatusProps) => {
  const { appraisal } = props
  const appraisalLink = DynamicRoutes.inspection(
    appraisal.apartment.id,
    appraisal.id
  )

  return (
    <StyledButton
      href={appraisalLink}
      labelColor={null}
      status={appraisal.status}
      variant="transparent"
    >
      {appraisal.status
        ? appraisalStatusLabels[appraisal.status]
        : "Without offer"}
    </StyledButton>
  )
}

export default AppraisalStatus

const StyledButton = styled(LinkButton)<AppraisalStatusStylesProps>`
  border: none;
  .KUI-Button_icon-container {
    justify-content: space-between;
  }
  background: ${({ status }) =>
    appraisalStatusColors[status || "notSigned"].background};
  p {
    color: ${({ status }) =>
      appraisalStatusColors[status || "notSigned"].color};
  }
  path {
    fill: ${({ status }) => appraisalStatusColors[status || "notSigned"].color};
  }
  &:hover {
    box-shadow: ${theme.shadows.shadow1};
  }
`
