const companiesHeadCellsParams = [
  { id: "id", label: "ID", width: "5%" },
  { id: "name", label: "Name", width: "20%" },
  { id: "provider_type", label: "Provider type", width: "12%" },
  { id: "managed_objects_count", label: "Objects", width: "12%" },
  { id: "address", label: "Address", width: "20%" },
  { id: "email", label: "Email", width: "16%" },
  { id: "phone", label: "Phone", width: "15%" },
]

export default companiesHeadCellsParams
