import React from "react"
import { observer } from "mobx-react"
import ContractClosingButtons from "../../../../components/common/ContractClosingButtons"
import useSCStore from "../../store"

const SCClosingButtons = () => {
  const { contractInfoStore } = useSCStore()
  const {
    tentativeCloseDate,
    canBeClosed,
    closingProgress,
    closeServiceContract,
    saveTentativeCloseDate,
  } = contractInfoStore

  return (
    <ContractClosingButtons
      defaultDate={tentativeCloseDate}
      canBeClosed={canBeClosed}
      closingProgress={closingProgress}
      closeContract={closeServiceContract}
      setCheckoutDate={saveTentativeCloseDate}
      label="end"
    />
  )
}

export default observer(SCClosingButtons)
