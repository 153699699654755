import React from "react"
import { Tooltip } from "kui-crm"
import styled from "@emotion/styled"
import { Grid } from "kui-basic"
import { observer } from "mobx-react"
import useInspectionStore from "../../../store"
import AppraisalInspectionStore from "../../../store/variations/AppraisalInspectionStore"
import InspectionFeedbackStatus from "../../../components/InspectionFeedback/InspectionFeedbackStatus"
import tenantOfferLabel from "./content"
import TenantOfferInfo from "../TenantOfferInfo"

const TenantOfferStatus = () => {
  const { inspectionInfo } = useInspectionStore()
  const inspection = inspectionInfo as AppraisalInspectionStore
  const tenantOfferStatus = inspection?.tenantOfferStatus

  if (!tenantOfferStatus) return null
  const { signStatus, offer } = tenantOfferStatus

  return (
    <StyledWrapper>
      {signStatus.status === "signed" && offer ? (
        <Tooltip content={<TenantOfferInfo offer={offer} />}>
          <InspectionFeedbackStatus
            resendLabel="Resend offer"
            resend={inspection.resendOffer}
            feedback={signStatus}
            label={tenantOfferLabel.signed}
          />
        </Tooltip>
      ) : (
        <InspectionFeedbackStatus
          resendLabel="Resend offer"
          resend={inspection.resendOffer}
          feedback={signStatus}
          label={tenantOfferLabel[signStatus.status]}
        />
      )}
    </StyledWrapper>
  )
}

export default observer(TenantOfferStatus)

const StyledWrapper = styled(Grid)`
  padding-top: 8px;
`
