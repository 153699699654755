import { InputFile } from "kui-complex"
import { InputParams } from "../../../../../../components/common/InputsGroupWithTitle/types"
import { CompanyInfoFields } from "../../../../forms/CompanyInfoForm/types"
import { CompanyTypesValues } from "../../../../../../utils/content/listsOptions"

const companyInfoFields: InputParams<CompanyInfoFields>[] = [
  { label: "TIN", name: "inn" },
  { label: "Name", name: "name" },
  {
    label: "Provider type",
    name: "type",
    variant: "select",
    options: CompanyTypesValues,
  },
  {
    label: "Logo",
    name: "logo",
    fileName: "Logo",
    variant: "custom",
    CustomInput: InputFile,
    withEdit: false,
  },
]

export default companyInfoFields
