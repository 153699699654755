import React from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { Link } from "kui-crm"
import { useLocation } from "react-router-dom"
import { Caption } from "kui-basic"
import { HeaderNavLinkStylesProps } from "../types"
import { HeaderNavButtonProps } from "./types"

const HeaderNavButton = (props: HeaderNavButtonProps) => {
  const { route, disabled } = props
  const location = useLocation()
  const pathname =
    location.pathname.match(/\/(\w|-)+/)?.[0] || location.pathname

  return (
    <StyledButton
      disabled={disabled}
      type="button"
      isActive={pathname === route.path}
    >
      {disabled ? (
        <Caption size="s" color="disabled">
          {route.title}
        </Caption>
      ) : (
        <Link href={route.path} stopPropagation>
          {route.title}
        </Link>
      )}
    </StyledButton>
  )
}

export default HeaderNavButton

const activeCSS = ({ isActive, disabled, theme }: HeaderNavLinkStylesProps) =>
  isActive &&
  css`
    color: white;
  &::after {
    content: "";
    height: 3px;
    display: block;
    position: absolute;
    border-radius: 0px 0px 4px 4px;
    background-color: ${
      disabled ? theme?.palette.grey.fiftyP : "rgb(245, 124, 0)"
    };
    width: 100%;
    top: -23px;
    left: 1px;
  },
`

const StyledButton = styled.button<HeaderNavLinkStylesProps>`
  display: flex;
  align-items: center;
  margin-right: 28px;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.palette.grey.fourty};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  user-select: none;
  a {
    font-size: 14px;
  }
  ${activeCSS};
`
