import React from "react"
import styled from "@emotion/styled"
import { Caption, Grid } from "kui-basic"
import { CloseIcon } from "kui-icon"
import { FullSliderHeaderProps } from "./types"

function FullSliderHeader(props: FullSliderHeaderProps) {
  const { handleClose, slidesCount, currentSlide, renderEndComponent, slider } =
    props

  return (
    <StyledHeader container justify="space-between" alignItems="center">
      <Caption weight={500} color="zero">
        {currentSlide + 1} <StyledSlash>/</StyledSlash> {slidesCount}
      </Caption>

      <Grid item>
        <Grid container justify="flex-end" alignItems="center">
          {renderEndComponent &&
            renderEndComponent(currentSlide, slider, handleClose)}

          <StyledCloseButton
            role="button"
            tabIndex={0}
            onClick={handleClose}
            onKeyDown={handleClose}
          >
            <CloseIcon />
          </StyledCloseButton>
        </Grid>
      </Grid>
    </StyledHeader>
  )
}

export default FullSliderHeader

const StyledHeader = styled(Grid)`
  padding: 12px 24px;
  background: rgba(25, 25, 25, 0.9);
  backdrop-filter: blur(3.5px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
`

const StyledCloseButton = styled.div`
  width: 25px;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const StyledSlash = styled.span`
  color: ${({ theme }) => theme.palette.grey.fiftyP};
`
