import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { Paginator, SortingFilter, Loader } from "kui-utils"
import { WaterTariffCreationFields, TariffsLiteStore } from "kui-crm"
import {
  GetWaterTariffsResponse,
  WaterTariffModel,
} from "../types/api/tariffsAPI"
import TariffsAgent from "../../../agent/Tariffs"
import TariffStore from "./TariffStore"

class WaterTariffsStore {
  tariffs: TariffStore[]

  paginator: Paginator

  filter: SortingFilter

  loader: Loader

  creationLoader: Loader

  constructor() {
    this.tariffs = []
    this.loader = new Loader()
    this.creationLoader = new Loader()
    this.paginator = new Paginator()
    this.filter = new SortingFilter("id")
    makeAutoObservable(this)
  }

  fetchAll = async () => {
    this.loader.startLoading()

    const [err, res] = await to<GetWaterTariffsResponse>(
      TariffsAgent.all(
        "water",
        this.paginator.offset,
        this.paginator.limit,
        this.filter.filterParams
      )
    )
    runInAction(() => {
      if (!err && res) {
        const mapper = (tariff: WaterTariffModel) =>
          new TariffStore(tariff, "water")

        this.tariffs = this.paginator.getPageResponse<
          WaterTariffModel,
          TariffStore
        >(res, this.tariffs, mapper)
      } else {
        this.loader.setError("fetch water tariffs", err)
      }
      this.loader.endLoading()
    })
  }

  createTariff = async (data: WaterTariffCreationFields) => {
    this.creationLoader.startLoading()

    const body = TariffsLiteStore.getCreationBody(data)

    const [err, res] = await to(TariffsAgent.createTariff(body, "water"))
    runInAction(() => {
      if (!err && res) {
        this.tariffs = [...this.tariffs, new TariffStore(res, "water")]
      } else {
        this.creationLoader.setError("water tariff creation", err)
      }
      this.creationLoader.endLoading()
    })
  }
}

export default WaterTariffsStore
