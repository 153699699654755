import React, { useEffect, useState } from "react"
import { observer } from "mobx-react"
import { DropdownMenuProps } from "./types"
import DropdownMenuBase from "../DropdownMenuBase"
import DropdownMenuWithPortal from "../DropdownMenuWithPortal"

function DropdownMenu({ buttonRef, ...other }: DropdownMenuProps) {
  const [isParentSticky, setIsParentSticky] = useState(true)

  useEffect(() => {
    function checkParentIsSticky() {
      let el: HTMLElement | null = buttonRef.current
      while (el) {
        const { position } = window.getComputedStyle(el)
        if (position === "sticky" || position === "fixed") return true
        el = el.parentElement
      }
      return false
    }

    const isSticky = checkParentIsSticky()
    setIsParentSticky(!!isSticky)
  }, [buttonRef])

  return isParentSticky ? (
    <DropdownMenuBase buttonRef={buttonRef} {...other} />
  ) : (
    <DropdownMenuWithPortal buttonRef={buttonRef} {...other} />
  )
}

export default observer(DropdownMenu)
