import React from "react"
import styled from "@emotion/styled"
import { HeadingWithButton } from "kui-crm"
import { PhotoContainerProps } from "./types"
import Container from "../../ui/Container"

const PhotoContainer = (props: PhotoContainerProps) => {
  const { label, children, handleClick, disabled, className } = props

  return (
    <StyledContainer className={className}>
      <StyledHeadingWithButton
        title={label}
        onClick={handleClick}
        variant="whiteWithGray"
        disabled={disabled}
      />
      {children}
    </StyledContainer>
  )
}

export default PhotoContainer

const StyledContainer = styled(Container)`
  background: ${({ theme }) => theme.palette.background.light1};
  border-radius: 12px;
  padding: 0px 24px 24px !important;
  margin-bottom: 16px;
  @media (min-width: 1200px) {
    max-width: 1344px;
  }
`

const StyledHeadingWithButton = styled(HeadingWithButton)`
  path {
    fill: ${({ theme }) => theme.palette.grey.fiftyP} !important;
  }
  button {
    border-color: transparent;
  }
`
