import { DateTime } from "luxon"
import _ from "lodash"
import { Loader } from "kui-utils"
import {
  ContractStatuses,
  PaymentStatuses,
  RentalContractModel,
  RentalTypes,
} from "kui-crm"
import { ContractInterface } from "../../../types/store/contract"
import UserLiteStore from "../../../store/templates/UserLite"
import ApartmentSimpleStore from "../../../store/templates/Apartment"
import NotesStore from "../../../store/notes/Notes"

const defaultDayPayment = 15

class RentalContractStore implements ContractInterface {
  id: number

  serviceContractId: number | null

  apartment: ApartmentSimpleStore | null

  number: string

  landlord: UserLiteStore

  tenant: UserLiteStore | null

  status?: ContractStatuses

  rentCost: number

  installment: number

  dayPayment: number

  startDate: DateTime

  signDate: DateTime

  endDate: DateTime | null

  initialCommission: number | string

  securityPayment: number | string

  showingDays: number | string

  penalty: number | string

  autoProlongation: boolean

  type: RentalTypes

  dateOfLeaving: DateTime | null

  nextInspectionDate: DateTime | null

  inspectionsInterval: number | null

  paidStatus: PaymentStatuses

  notesStore: NotesStore

  loader: Loader

  constructor(contract: RentalContractModel) {
    this.id = contract.id
    this.serviceContractId = contract.service_contract_id ?? null
    this.apartment = contract.apartment
      ? new ApartmentSimpleStore(contract.apartment)
      : null
    this.number = contract.number
    this.landlord = UserLiteStore.initFromLiteUserModel(contract.owner)
    this.tenant = contract.renter
      ? UserLiteStore.initFromLiteUserModel(contract.renter)
      : null
    this.rentCost = Number(contract.month_payment)
    this.installment = contract.installment
    this.startDate = DateTime.fromISO(contract.start_date)
    this.type = contract.rental_type
    this.dayPayment = Number(contract.day_of_payment) || defaultDayPayment
    this.signDate = DateTime.fromISO(contract.sign_date)
    this.endDate = contract.end_date
      ? DateTime.fromISO(contract.end_date)
      : null
    this.initialCommission = Number(contract.initial_commission) || ""
    this.securityPayment = Number(contract.security_payment) || ""
    this.showingDays = Number(contract.termination_showing_days) || ""
    this.penalty = Number(contract.termination_penalty) || ""
    this.autoProlongation = contract.auto_prolongate
    this.paidStatus = contract.renter_payment_status
    this.status = contract.status
    this.dateOfLeaving = contract.planned_leave_date
      ? DateTime.fromISO(contract.planned_leave_date)
      : null
    this.nextInspectionDate = contract.next_inspection
      ? DateTime.fromISO(contract.next_inspection)
      : null
    this.inspectionsInterval = contract.inspection_interval_month || null
    this.notesStore = new NotesStore("rental-contracts", contract.id)
    this.loader = new Loader()
  }

  get formattedType() {
    return this.type ? _.capitalize(this.type) : "-"
  }
}

export default RentalContractStore
