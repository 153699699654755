import React from "react"
import { Tooltip } from "kui-crm"
import styled from "@emotion/styled"
import {
  CheckWithCircleIcon,
  CrossWithCircleIcon,
  QuestionIcon,
} from "kui-icon"
import InspectionFeedbackComment from "../InspectionFeedbackComment"
import { InspectionFeedbackIndicatorProps } from "./types"

const InspectionFeedbackIndicator = (
  props: InspectionFeedbackIndicatorProps
) => {
  const { feedback, className, labels } = props

  return (
    <div className={className}>
      <Tooltip
        content={
          <InspectionFeedbackComment feedback={feedback} labels={labels} />
        }
      >
        {indicatorIcon[feedback.status]}
      </Tooltip>
    </div>
  )
}

export default InspectionFeedbackIndicator

const StyledQuestionIcon = styled(QuestionIcon)`
  path {
    fill: ${({ theme }) => theme.palette.grey.fiftyP};
  }
`

const StyledCheckIcon = styled(CheckWithCircleIcon)`
  path {
    fill: ${({ theme }) => theme.palette.green.fiftyP};
  }
`

const StyledErrorIcon = styled(CrossWithCircleIcon)`
  width: 14px;
  height: 14px;
  path {
    fill: ${({ theme }) => theme.palette.red.fiftyP};
  }
`

const indicatorIcon = {
  notSigned: <StyledQuestionIcon data-testid="questionIcon" />,
  signed: <StyledCheckIcon />,
  rejected: <StyledErrorIcon />,
}
