import { FormBottom } from "kui-crm"
import React from "react"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { Caption } from "kui-basic"
import { ImpairmentConfirmFormProps } from "./types"

const ImpairmentConfirmForm = (props: ImpairmentConfirmFormProps) => {
  const { handleSubmit } = props

  return (
    <>
      <StyledWrapper>
        <Caption weight={600}>
          After creating the impairments, an invoice will be generated that
          includes the lines from this report.
        </Caption>
      </StyledWrapper>
      <FormBottom label="Confirm" handleClick={handleSubmit} />
    </>
  )
}

export default observer(ImpairmentConfirmForm)

const StyledWrapper = styled.div`
  padding: 0 40px 24px;
  text-align: center;
`
