import * as React from "react"
import styled from "@emotion/styled"
import { TableCellContractProps } from "./types"
import TableCell from "../../ui/TableCell"

function TableCellWithLabel({
  title,
  label,
  ...otherProps
}: TableCellContractProps) {
  return (
    <TableCell {...otherProps}>
      <div>
        {typeof title === "string" || typeof title === "number" ? (
          <StyledTopSignature>{title}</StyledTopSignature>
        ) : (
          title
        )}
        <StyledBottomSignature>{label}</StyledBottomSignature>
      </div>
    </TableCell>
  )
}

export default TableCellWithLabel

const StyledTopSignature = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.grey.seventy};
`

const StyledBottomSignature = styled.div`
  font-family: "Inter", sans-serif;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.grey.fiftyP};
  margin-top: 4px;
`
