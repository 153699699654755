import { Button, Tooltip } from "kui-basic"
import { ArchiveIcon } from "kui-icon"
import React, { useState } from "react"
import styled from "@emotion/styled"
import { DashboardArchiveButtonProps } from "./types"

const DashboardArchiveButton = (props: DashboardArchiveButtonProps) => {
  const { renderArchive } = props
  const [isOpen, setIsOpen] = useState(false)
  const archive = renderArchive(isOpen)

  return (
    <StyledTooltip
      toggleMethod="click"
      placement="bottom-end"
      content={archive}
      onChange={setIsOpen}
    >
      <Button isCircle size="xs" variant="grayLight">
        <ArchiveIcon />
      </Button>
    </StyledTooltip>
  )
}

export default DashboardArchiveButton

const StyledTooltip = styled(Tooltip)`
  .KUI-Popper {
    padding-top: 8px;
    min-width: 325px;
    > div {
      padding: 16px 8px;
    }
  }
`
