import { FormWrapper, InputFileWithVisibility } from "kui-crm"
import React from "react"
import { useForm } from "react-hook-form"
import { Grid } from "kui-basic"
import { yupResolver } from "@hookform/resolvers/yup"
import { ClientDocumentsStepFields, ClientDocumentsStepProps } from "./types"
import {
  clientDocumentCompanyFields,
  clientDocumentPersonalFields,
} from "./fields"
import {
  ClientDocumentsCompanySchema,
  ClientDocumentsPersonalSchema,
} from "./schema"

const clientTypeVariants = {
  personal: {
    fields: clientDocumentPersonalFields,
    schema: ClientDocumentsPersonalSchema,
  },
  company: {
    fields: clientDocumentCompanyFields,
    schema: ClientDocumentsCompanySchema,
  },
}

const ClientDocumentsStep = (props: ClientDocumentsStepProps) => {
  const { formStore, handleSubmit } = props
  const clientType = formStore.fields?.type || "personal"
  const clientParams = clientTypeVariants[clientType]
  const form = useForm<ClientDocumentsStepFields>({
    resolver: yupResolver(clientParams.schema),
  })

  return (
    <FormWrapper form={form} onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        {clientParams.fields.map((field) => (
          <Grid key={field.name} item xs={12}>
            <InputFileWithVisibility form={form} {...field} />
          </Grid>
        ))}
      </Grid>
    </FormWrapper>
  )
}

export default ClientDocumentsStep
