import React, { useEffect } from "react"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { Box } from "kui-basic"
import { LoaderState } from "kui-crm"
import Container from "../../../../../components/ui/Container"
import Loading from "../../../../../components/common/Loading"
import useClientStore from "../../../store"
import ClientCompanyInfoForm from "../../../forms/ClientCompanyInfoForm"

function ClientCompanyInfoTab() {
  const { companyInfoStore, overviewStore } = useClientStore()

  useEffect(() => {
    if (overviewStore?.id) {
      companyInfoStore.fetchClientCompanyInfo(overviewStore.id)
      companyInfoStore.documentsStore.fetchDocuments(overviewStore.id)
    }
  }, [overviewStore?.id])

  if (companyInfoStore?.loader.isLoading) {
    return <Loading />
  }

  return (
    <Container>
      <StyledWrapper pt={3}>
        <ClientCompanyInfoForm />
      </StyledWrapper>

      <LoaderState loader={companyInfoStore?.loader} onlyError />
      <LoaderState loader={companyInfoStore?.actionLoader} />
    </Container>
  )
}

export default observer(ClientCompanyInfoTab)

const StyledWrapper = styled(Box)`
  border-top: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
`
