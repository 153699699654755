import React, { useEffect, useRef, useState } from "react"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import ApartmentOverlay from "../ApartmentOverlay"
import MapOverlay from "../../../../components/common/MapOverlay"
import Loading from "../../../../components/common/Loading"
import useApartmentsStore from "../../store"

function ApartmentsMap() {
  const { apartmentsWithGeo, getApartmentsForMap, loader } =
    useApartmentsStore()
  const [map, setMap] = useState<google.maps.Map>()
  const mapRef = useRef<HTMLDivElement>(null)
  const centerMoscow = { lat: 55.753559, lng: 37.609218 }
  const { isLoading } = loader

  useEffect(() => {
    if (apartmentsWithGeo.length === 0) {
      getApartmentsForMap()
    }
    if (mapRef.current) {
      const initialMap = new google.maps.Map(mapRef.current, {
        center: centerMoscow,
        zoom: 11,
        disableDefaultUI: true,
        clickableIcons: false,
      })
      setMap(initialMap)
    }
  }, [])

  return (
    <StyledWrapper>
      <StyledMapWrapper ref={mapRef} id="map">
        {apartmentsWithGeo?.map((apartment) => (
          <MapOverlay
            map={map}
            position={apartment.geolocation!}
            key={apartment.id}
          >
            <ApartmentOverlay map={map} apartment={apartment} />
          </MapOverlay>
        ))}
      </StyledMapWrapper>
      {isLoading && (
        <StyledBackdrop>
          <Loading />
        </StyledBackdrop>
      )}
    </StyledWrapper>
  )
}

export default observer(ApartmentsMap)

const StyledWrapper = styled.div`
  position: relative;
  overflow: hidden;
`

const StyledMapWrapper = styled.div`
  height: calc(100vh - 63px - 56px);
  position: relative;
`

const StyledBackdrop = styled.div`
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
`
