import * as React from "react"
import { useEffect, useRef, useState } from "react"
import { observer } from "mobx-react"
import { useWatch } from "react-hook-form"
import { CityAutocompleteFields, useAutocomplete, CountyParams } from "kui-crm"

import { InputWithAutocomplete } from "kui-complex"
import { CountyAutocompleteProps } from "./types"
import AutocompleteOption from "../../common/AutocompleteOption"
import CountiesStore from "../../../store/lites/CountiesStore"

function CountyAutocomplete<TFormValues extends object>(
  props: CountyAutocompleteProps<TFormValues>
) {
  const [isOpen, setIsOpen] = useState(false)
  const { form, name, label, onSelect, cityName, ...otherProps } = props
  const countiesStoreRef = useRef(new CountiesStore())
  const [isMounted, setIsMounted] = useState(false)

  const { counties, fetchCounties, paginator, filter, loader } =
    countiesStoreRef.current
  const { isLoading } = loader
  const city: CityAutocompleteFields = useWatch({
    control: form.control,
    name: cityName,
  })

  const { onSearchItem, onScrollEnd } = useAutocomplete(
    filter,
    paginator,
    fetchCounties,
    isOpen,
    city?.id ? `&city=${city.id}` : ""
  )

  const onSelectItem = (item: CountyParams | null) => {
    form.setValue(`${name}.id`, item?.id)
    form.setValue(`${name}.name`, item?.name, { shouldValidate: true })
    if (onSelect) onSelect(item)
  }

  useEffect(() => {
    if (isMounted) {
      form.setValue(`${name}.id`, null)
      form.setValue(`${name}.name`, null)
    }
    if (city?.id) {
      filter.updateFilterQuery(`&city=${city.id}`)
      paginator.clearOffset()
      paginator.startFetching()
    }
    setIsMounted(true)
  }, [city?.id])

  return (
    <InputWithAutocomplete
      renderOption={(option: CountyParams) => (
        <AutocompleteOption label={option.name} />
      )}
      isLoading={isLoading}
      options={counties}
      label={label}
      form={form}
      name={`${name}.name`}
      hideName={`${name}.id`}
      onSelectItem={onSelectItem}
      onSearchItem={onSearchItem}
      handleScrollEnd={onScrollEnd}
      onOpen={setIsOpen}
      {...otherProps}
    />
  )
}

CountyAutocomplete.defaultProps = {
  label: "County",
  name: "county",
  cityName: "city",
}

export default observer(CountyAutocomplete)
