import * as yup from "yup"
import { defaultRequiredMessage } from "kui-crm"
import addKeyByCondition from "../../../../../../../utils/service/addKeyByCondition"

const getClosePeriodFormSchema = (
  isLastMonthOfContract: boolean,
  withoutLandlordPayOut: boolean,
  withoutTenantPayIn: boolean,
  withTenant: boolean
) =>
  yup.object().shape({
    ...addKeyByCondition(!isLastMonthOfContract && !withoutLandlordPayOut, {
      paymentOrder: yup.object({
        file: yup.mixed().required(defaultRequiredMessage),
      }),
    }),
    ...addKeyByCondition(withoutLandlordPayOut, {
      withoutLandlordPayOut: yup.boolean().required(defaultRequiredMessage),
    }),
    ...addKeyByCondition(withoutTenantPayIn && withTenant, {
      withoutTenantPayIn: yup.boolean().required(defaultRequiredMessage),
    }),
  })

export default getClosePeriodFormSchema
