import React from "react"
import { observer } from "mobx-react"
import SortingTable from "../../../../components/common/SortingTable"
import useApartmentsStore from "../../store"
import apartmentsHeadCellsParams from "./content"
import ApartmentRow from "../ApartmentRow"

function ApartmentsTable() {
  const apartmentsStore = useApartmentsStore()
  const { apartments } = apartmentsStore

  return (
    <SortingTable
      withOverlaps
      headParams={apartmentsHeadCellsParams}
      store={apartmentsStore}
    >
      {apartments.map((company, key) => (
        <ApartmentRow
          key={company.id}
          apartment={company}
          isDark={key % 2 !== 0}
        />
      ))}
    </SortingTable>
  )
}

export default observer(ApartmentsTable)
