import { createContext, useContext } from "react"
import hostStore from "../../../store"
import CompanyPageStore from "./CompanyPageStore"

const companyStore = new CompanyPageStore(hostStore)
const CompanyContext = createContext(companyStore)

const useCompanyStore = () => useContext(CompanyContext)

export default useCompanyStore
