import React from "react"
import { Button, Grid } from "kui-basic"
import { useToggle } from "kui-utils"
import { PlusIcon } from "kui-icon"
import { observer } from "mobx-react"
import MeterReadingModal from "../../../../components/modals/MeterReadingModal"
import useMeterStore from "../../store"

const MeterVerificationButton = () => {
  const [isModalOpen, openModal, closeModal] = useToggle()
  const { createMeterReading, meterInfoStore } = useMeterStore()
  const meterParams = meterInfoStore
    ? {
        id: meterInfoStore.id!,
        name: meterInfoStore.passport,
        type: meterInfoStore.type,
      }
    : null

  return (
    <Grid item>
      <Button size="xs" startIcon={<PlusIcon />} onClick={openModal}>
        Meter verification
      </Button>

      <MeterReadingModal
        isInsideMeter
        open={isModalOpen}
        handleClose={closeModal}
        createMeterReading={createMeterReading}
        meterParams={meterParams}
        meterResource={meterInfoStore.resource}
        apartmentId={meterInfoStore.apartment?.id}
      />
    </Grid>
  )
}

export default observer(MeterVerificationButton)
