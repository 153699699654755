import { rest } from "msw"
import { ApiTokenAuthResponse, SignInParams } from "kui-crm/types"
import { authResponse } from "./data"

const authHandlers = [
  rest.post<SignInParams, ApiTokenAuthResponse | Error>(
    `${process.env.REACT_APP_URL}/api/api-token-auth/`,
    (req, resp, ctx) => {
      if (req.body.password === "maroom" && req.body.phone === "maroom") {
        const data = ctx.json(authResponse)
        return resp(data)
      }
      return resp(ctx.status(400))
    }
  ),
]

export default authHandlers
