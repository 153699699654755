import React from "react"
import { observer } from "mobx-react"
import useDashboardStore from "../../store"
import SalesManagerDashboardCards from "./SalesManagerDashboardCards"
import DashboardPageWrapper from "../../components/common/DashboardPageWrapper"

const SalesManagerDashboard = () => {
  const { salesManagerDashboard } = useDashboardStore()

  return (
    <DashboardPageWrapper dashboardStore={salesManagerDashboard}>
      <SalesManagerDashboardCards />
    </DashboardPageWrapper>
  )
}

export default observer(SalesManagerDashboard)
