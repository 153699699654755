import React from "react"
import styled from "@emotion/styled"
import Container from "../../../../../../../components/ui/Container"
import { InspectionsTabWrapperProps } from "./types"

const InspectionsTabWrapper = (props: InspectionsTabWrapperProps) => {
  const { children } = props

  return (
    <StyledBackground>
      <Container>{children}</Container>
    </StyledBackground>
  )
}

export default InspectionsTabWrapper

const StyledBackground = styled.div`
  padding: 16px 0;
  background: ${({ theme }) => theme.palette.background.light1};
  min-height: calc(100vh - 405px);
`
