import { addToArrayByCondition } from "kui-utils"
import {
  BooleanValues,
  OwnershipTransferValues,
} from "../../../../../../utils/content/listsOptions"
import { ApartmentOwnerFormFields } from "../../../../forms/ownership/ApartmentOwnershipForm/types"
import { FieldGroupsParams } from "../../../../../../components/common/UpdatedFieldsGroup/types"
import ClientAutocomplete from "../../../../../../components/entityInputs/ClientAutocomplete"
import ApartmentShareFields from "../ApartmentShareFields"

const getOwnerFieldsGroup = (
  index: number
): FieldGroupsParams<ApartmentOwnerFormFields> => ({
  title: index === 0 ? "Owner • Landlord" : "Owner",
  modalProps: {
    updatedTitle: "Edit owner",
    addingTitle: "Add member",
  },
  fields: [
    {
      label: "Client",
      name: `owners.${index}.member`,
      variant: "custom",
      CustomInput: ClientAutocomplete,
      size: 6,
      isRequired: true,
    },
    {
      variant: "custom",
      CustomInput: ApartmentShareFields,
      index,
      size: 6,
    },
    {
      label: "Registered",
      name: `owners.${index}.isRegistered`,
      variant: "select",
      options: BooleanValues,
      size: 4,
    },
    {
      label: "Transfer type",
      name: `owners.${index}.ownershipTransferType`,
      variant: "select",
      options: OwnershipTransferValues,
      size: 4,
    },
    {
      label: "Date of transfer",
      name: `owners.${index}.dateOfTransfer`,
      variant: "date",
      size: 4,
    },
    {
      label: "Ownership transfer document",
      name: `owners.${index}.ownershipTransferDocument`,
      size: 12,
    },
    {
      label: "Confirmation document",
      name: `owners.${index}.confirmationDocument`,
      size: 8,
    },
    {
      label: "Document number",
      name: `owners.${index}.documentNumber`,
      size: 4,
    },
    {
      label: "Certyfing authority",
      name: `owners.${index}.certificationAuthority`,
      size: 8,
    },
    {
      label: "Number of the form",
      name: `owners.${index}.numberOfForm`,
      size: 4,
    },
    {
      label: "Date of issue",
      name: `owners.${index}.dateOfIssue`,
      variant: "date",
      size: 3,
    },
    {
      label: "Date of entry",
      name: `owners.${index}.dateOfEntry`,
      variant: "date",
      size: 3,
    },
    {
      label: "Registration number",
      name: `owners.${index}.registrationNumber`,
      size: 3,
    },
    ...addToArrayByCondition(index === 0, {
      label: "Cadastral number",
      name: "cadastralNumber",
      size: 3,
    }),
  ],
})

export default getOwnerFieldsGroup
