import * as React from "react"
import { observer } from "mobx-react"
import { useEffect } from "react"
import useExpensesStore from "../../../store"
import ExpensesMetersTables from "./ExpensesMetersTables"

function ExpensesMetersTab() {
  const { metersStore, date, overviewStore, periodsStore } = useExpensesStore()
  const { selectedPeriod } = periodsStore

  useEffect(() => {
    if (overviewStore.id && date) {
      metersStore.initCountersPage(
        overviewStore.id,
        date!,
        selectedPeriod?.rentalContract?.id
      )
    } else {
      metersStore.cleanMetersTab()
    }
    return () => {
      metersStore.loader.startLoading()
    }
  }, [date, selectedPeriod])

  return <ExpensesMetersTables />
}

export default observer(ExpensesMetersTab)
