import React from "react"
import { observer } from "mobx-react"
import _ from "lodash"
import styled from "@emotion/styled"
import AboutPageHeader from "../../../../components/common/AboutPageHeader"
import useMeterStore from "../../store"

function MeterOverviewHeader() {
  const { meterInfoStore } = useMeterStore()

  const meterInfo = [
    {
      title: meterInfoStore?.startDate?.toFormat("dd.MM.yyyy") || "-",
      subtitle: "Activation date",
    },
    {
      title: meterInfoStore?.nextCheck?.toFormat("dd.MM.yyyy") || "-",
      subtitle: "Next check",
    },
  ]

  return (
    <StyledAboutPageHeader
      title={meterInfoStore?.passport}
      subtitle={`${meterInfoStore.id} • ${_.capitalize(
        String(meterInfoStore.type || meterInfoStore.resource)
      )}`}
      info={meterInfo}
    />
  )
}

export default observer(MeterOverviewHeader)

const StyledAboutPageHeader = styled(AboutPageHeader)`
  .PageHeaderWrapper {
    padding-top: 30px;
  }
  .PageHeaderInfoItem:last-child > div {
    margin-right: 0;
  }
`
