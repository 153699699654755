import React from "react"
import { observer } from "mobx-react"
import { Button } from "kui-basic"
import styled from "@emotion/styled"
import { UploadIcon } from "kui-icon"
import { UploadedFilesGroupProps } from "./types"
import UploadedFileRow from "../UploadedFileRow"

const UploadedFilesGroup = (props: UploadedFilesGroupProps) => {
  const { files, onAdd, variant, ...other } = props

  return (
    <div data-testid="files_wrapper">
      <StyledButton
        size="s"
        variant="orange"
        fullWidth
        onClick={onAdd}
        endIcon={<UploadIcon />}
      >
        Upload more {variant === "image" ? "photos" : "files"}
      </StyledButton>
      {files.map((file) => (
        <UploadedFileRow
          key={`${file.file?.name}_${file.index}`}
          file={file}
          variant={variant}
          {...other}
        />
      ))}
    </div>
  )
}

export default observer(UploadedFilesGroup)

const StyledButton = styled(Button)`
  margin-bottom: 16px;
`
