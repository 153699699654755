import requests from "./index"
import { PatchApartmentParams } from "../pages/ApartmentsPage/types/api/apartmentsAPI"

const ApartmentAgent = {
  getById: (id: number) => requests.get(`/apartments/${id}`),
  patch: (id: number, data: PatchApartmentParams) =>
    requests.patch(`/apartments/${id}`, data),
  archive: (id: number) => requests.delete(`/apartments/${id}`),
  getApartmentResources: (apartmentId: number) =>
    requests.get(`/apartments/${apartmentId}/used_resources`),
  getApartmentServices: (apartmentId: number) =>
    requests.get(`/apartments/${apartmentId}/checking`),
}

export default ApartmentAgent
