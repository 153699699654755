import { makeAutoObservable, runInAction } from "mobx"
import { Loader, Paginator, resHandler } from "kui-utils"
import to from "await-to-js"
import { DateTime } from "luxon"
import DashboardPageStore from "../DashboardPageStore"
import DashboardAgent from "../../../../agent/Dashboard"
import ApartmentsStore from "../../../../store/lites/ApartmentsStore"
import { PendingImpairmentsParams } from "../../types/cards/inspections"
import { PendingImpairmentsModel } from "../../types/api/inspections"

class PendingImpairmentsStore {
  value: number | null

  impairments: PendingImpairmentsParams[]

  paginator: Paginator

  loader: Loader

  dashboardStore: DashboardPageStore

  constructor(dashboardStore: DashboardPageStore) {
    this.value = null
    this.impairments = []
    this.paginator = new Paginator(5)
    this.loader = new Loader()
    this.dashboardStore = dashboardStore
    makeAutoObservable(this)
  }

  fetchImpairments = async () => {
    this.loader.startLoading()

    const response = await to(
      DashboardAgent.getPendingImpairments(
        this.paginator.limit,
        this.paginator.offset,
        this.dashboardStore.periodFilter
      )
    )

    runInAction(() => {
      resHandler(response, this.loader, (res) => {
        this.value = res.count
        this.impairments = this.paginator.getPageResponse(
          res,
          this.impairments,
          PendingImpairmentsStore.getImpairmentParams
        )
      })
    })
  }

  static getImpairmentParams = ({
    inspection,
    damages_count,
    wear_tear_count,
  }: PendingImpairmentsModel) => ({
    id: inspection.id,
    apartment: ApartmentsStore.getApartmentLinkParams(inspection.apartment),
    date: inspection.date ? DateTime.fromISO(inspection.date) : null,
    damagesCount: damages_count,
    wearCount: wear_tear_count,
  })
}

export default PendingImpairmentsStore
