import React from "react"
import { Grid } from "kui-basic"
import styled from "@emotion/styled"
import DashboardCard from "../DashboardCard"
import DashboardCardInfo from "../DashboardCardInfo"
import { ListCardProps } from "./types"
import DashboardArchiveButton from "../DashboardArchiveButton"
import PaginationList from "../PaginationList"

const DashboardListCard = (props: ListCardProps) => {
  const {
    children,
    value,
    renderArchive,
    loader,
    paginator,
    fetchAll,
    className,
    ...other
  } = props

  if (value === null) return null

  return (
    <DashboardCard className={className}>
      <StyledHeaderWrapper container justify="space-between" wrap="nowrap">
        <DashboardCardInfo variant="brand" value={value} {...other} />
        {!!renderArchive && (
          <DashboardArchiveButton renderArchive={renderArchive} />
        )}
      </StyledHeaderWrapper>
      <PaginationList paginator={paginator} loader={loader} fetchAll={fetchAll}>
        {children}
      </PaginationList>
    </DashboardCard>
  )
}

export default DashboardListCard

const StyledHeaderWrapper = styled(Grid)`
  padding-bottom: 16px;
`
