import React from "react"
import { RubleIcon } from "kui-icon"
import { observer } from "mobx-react"
import DashboardListCard from "../../common/DashboardListCard"
import InspectionImpairmentsRow from "./InspectionImpairmentsRow"
import { InspectionImpairmentsCardProps } from "./types"
import InspectionImpairmentsArchive from "./InspectionImpairmentsArchive"

const InspectionImpairmentsCard = (props: InspectionImpairmentsCardProps) => {
  const { inspectionsStore } = props
  const { value, inspections, loader, paginator, fetchInspections } =
    inspectionsStore

  return (
    <DashboardListCard
      value={value}
      label="Inspections require impairments"
      icon={<RubleIcon />}
      renderArchive={(isOpen) => (
        <InspectionImpairmentsArchive
          isOpen={isOpen}
          inspectionsStore={inspectionsStore}
        />
      )}
      loader={loader}
      paginator={paginator}
      fetchAll={fetchInspections}
    >
      {inspections.map((inspection) => (
        <InspectionImpairmentsRow key={inspection.id} inspection={inspection} />
      ))}
    </DashboardListCard>
  )
}

export default observer(InspectionImpairmentsCard)
