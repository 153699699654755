/* eslint-disable */
import React, { ForwardedRef, forwardRef } from "react"
import styled from "@emotion/styled"
import { CalendarStandardContainer, MonthPickerContainer } from "kui-complex"
import { CalendarWithRangeBodyProps } from "./types"

const CalendarWithRangeBody = forwardRef(
  (props: CalendarWithRangeBodyProps, ref: ForwardedRef<HTMLDivElement>) => (
    <StyledContainer ref={ref} {...props} />
  )
)

export default CalendarWithRangeBody

const StyledContainer = styled(CalendarStandardContainer)`
  box-shadow: 0px 26px 34px rgba(0, 0, 0, 0.1);
  border-radius: 12px !important;
  border: none !important;
  background: #fff !important;
  overflow: hidden;
  z-index: 5;
  padding: 16px;
  width: 325px;
  
  .react-datepicker__day--in-range, .react-datepicker__day--in-selecting-range {
    position: relative;
    background: ${({ theme }) => theme.palette.background.light2};
    color: ${({ theme }) => theme.palette.grey.seventy};
    p {
      color: ${({ theme }) => theme.palette.grey.seventy};
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 50px;
      height: calc(100% + 2px);
      top: -1px;
      background: ${({ theme }) => theme.palette.background.light2};
      z-index: -1;
    }
    &:last-child {
      &:after {
        border-radius: 0 66px 66px 0;
      }
    }
    &:first-child {
      &:after {
        border-radius: 66px 0 0 66px;
      }
    }
  }
  
  .react-datepicker__day--range-start, .react-datepicker__day--range-end, .react-datepicker__day--selected {
    border: none;
    background: ${({ theme }) => theme.palette.brand.main};
    color: ${({ theme }) => theme.palette.grey.zero};
    p {
      color: ${({ theme }) => theme.palette.grey.zero};
    }
    &:hover {
      background: ${({ theme }) => theme.palette.brand.hover};
    }
  }

  .react-datepicker__day--range-start, .react-datepicker__day--selecting-range-start {
    &:after {
      left: 10px;
      height: 100%;
      top: 0;
    }
  }

  .react-datepicker__day--range-end, .react-datepicker__day--selecting-range-end {
    &:after {
      right: 10px;
      height: 100%;
      top: 0;
    }
  }

  .react-datepicker__day--range-start.react-datepicker__day--range-end, .react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-end {
    &:after {
      content: none;
    }
  }
`
