import React from "react"
import DatePicker, { ReactDatePickerCustomHeaderProps } from "react-datepicker"
import { Caption, Grid } from "kui-basic"
import { DateTime } from "luxon"
import { MonthPickerContainer } from "kui-complex"
import styled from "@emotion/styled"

const CalendarMonth = (props: ReactDatePickerCustomHeaderProps) => {
  const { changeMonth, date, ...other } = props
  const label = DateTime.fromJSDate(date).toFormat("MMM")

  const handleChange = (newDate: Date) => {
    changeMonth(newDate.getMonth())
  }

  return (
    <StyledWrapper>
      <DatePicker
        selected={date}
        onChange={handleChange}
        customInput={<StyledLabel weight={600}>{label}</StyledLabel>}
        showMonthYearPicker
        dateFormat="MM.yyyy"
        popperPlacement="bottom-end"
        calendarContainer={StyledContainer}
        renderCustomHeader={() => <StyledHeader />}
        {...other}
      />
    </StyledWrapper>
  )
}

export default CalendarMonth

const StyledLabel = styled(Caption)`
  cursor: pointer;
  width: fit-content;
`

const StyledHeader = styled(Grid)`
  background: #fff;
  padding: 0;
  border-radius: 12px 12px 0 0;
`

const StyledContainer = styled(MonthPickerContainer)`
  .react-datepicker__month-container {
    padding-top: 8px;
  }
`

const StyledWrapper = styled.div`
  margin-right: 4px;
`
