import { Grid } from "kui-basic"
import React from "react"
import TwoCaptionsWithImageBlock from "../../../../../../../../components/common/TwoCaptionsWithImageBlock"
import TypographyMoney from "../../../../../../../../components/common/TypographyMoney"
import { ExpensesPaymentSummaryCellProps } from "./types"

function ExpensesPaymentSummaryCell(props: ExpensesPaymentSummaryCellProps) {
  const { icon, value, subtitle, valueTestId, ...otherProps } = props

  return (
    <Grid item>
      <TwoCaptionsWithImageBlock
        image={icon}
        testId={valueTestId}
        top={
          value ? <TypographyMoney size="s" weight={600} value={value} /> : "-"
        }
        bottom={subtitle}
        bottomSize="xs"
        topSize="s"
        bottomWeight={400}
        spacing={2}
        {...otherProps}
      />
    </Grid>
  )
}

export default ExpensesPaymentSummaryCell
