import React from "react"
import _ from "lodash"
import styled from "@emotion/styled"
import { Link, PreviewContent } from "kui-crm"
import { MeterRowProps } from "./types"
import { Routes } from "../../../../types/route"
import TableRow from "../../../../components/ui/TableRow"
import TableCell from "../../../../components/ui/TableCell"
import LinkButton from "../../../../components/common/LinkButton"

const defaultImageURL = "/images/LivingAreaSmall.svg"

function MeterRow({ meter, isDark }: MeterRowProps) {
  return (
    <TableRow isDark={isDark}>
      <TableCell isFirst textColor="gray">
        {meter.id}
      </TableCell>
      <TableCell>
        <Link href={`${Routes.meteringDevices}/${meter.resource}/${meter.id}`}>
          {meter.number}
        </Link>
      </TableCell>
      {meter.type && <TableCell>{_.capitalize(meter.type)}</TableCell>}
      <TableCell>
        {meter.tariffGroup?.id ? (
          <StyledLinkButton
            href={
              meter.tariffGroup &&
              `${Routes.tariffsGroups}/${meter.resource}/${meter.tariffGroup?.id}`
            }
          >
            {meter.tariffGroup?.name || "-"}
          </StyledLinkButton>
        ) : (
          "-"
        )}
      </TableCell>
      <TableCell>
        <Link href={`${Routes.apartments}/${meter.apartment.id}`}>
          <PreviewContent
            imageSrc={meter.apartment.mainImageURL || defaultImageURL}
            description={meter.apartment.address}
          />
        </Link>
      </TableCell>
      <TableCell>{meter.startDate?.toFormat("dd.MM.yyyy") || "-"}</TableCell>
      <TableCell isLast>
        {meter.nextCheck?.toFormat("dd.MM.yyyy") || "-"}
      </TableCell>
    </TableRow>
  )
}

export default MeterRow

const StyledLinkButton = styled(LinkButton)`
  width: 170px;
`
