/** @jsxImportSource @emotion/react */
import * as React from "react"
import styled from "@emotion/styled"
import { Button } from "kui-basic"
import { CheckIcon, EditIcon } from "kui-icon"
import { EditButtonProps } from "./types"

function EditButton({
  isEditing,
  onEditHandler,
  onSaveHandler,
  ...other
}: EditButtonProps) {
  return (
    <StyledButton
      data-testid="edit_button"
      size="xs"
      css={{ width: 87 }}
      variant={isEditing ? "primary" : "transparentWithBorder"}
      startIcon={
        isEditing ? <CheckIcon /> : <EditIcon width={14} height={14} />
      }
      onClick={isEditing ? onSaveHandler : onEditHandler}
      type={isEditing ? "submit" : "button"}
      {...other}
    >
      {isEditing ? "Save" : "Edit"}
    </StyledButton>
  )
}

export default EditButton

const StyledButton = styled(Button)`
  width: 87px;
  white-space: nowrap;
  &:disabled {
    border-color: ${({ theme }) => theme.palette.grey.fifteenB} !important;
  }
`
