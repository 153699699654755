import React from "react"
import { Heading, Box } from "kui-basic"
import { SectionTitleProps } from "./types"

const SectionTitle = ({ title }: SectionTitleProps) => (
  <Box pt={3} mb={2}>
    <Heading size="h4">{title}</Heading>
  </Box>
)

export default SectionTitle
