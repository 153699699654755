import React from "react"
import { observer } from "mobx-react"
import useCompanyStore from "../../../store"
import EditButton from "../../../../../components/ui/EditButton"

function CompanyEditButton() {
  const { editor } = useCompanyStore()

  return (
    <EditButton
      isEditing={editor.isEditing}
      onSaveHandler={editor.allowToSendForm}
      onEditHandler={editor.startEditing}
    />
  )
}

export default observer(CompanyEditButton)
