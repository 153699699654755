import React from "react"
import { observer } from "mobx-react"
import { DateTime } from "luxon"
import _ from "lodash"
import CalendarWithMonthButton from "../../../../../../components/common/CalendarWithMonthButton"
import useApartmentStore from "../../../../store"

function ApartmentDocumentsCalendar() {
  const { documentsStore, overviewStore } = useApartmentStore()

  const handleChangeDate = (newDate: Date) => {
    const isSelectedMonth =
      documentsStore.date &&
      Math.floor(
        documentsStore.date
          .diff(DateTime.fromJSDate(newDate), ["months"])
          .toObject().months || 0
      ) === 0

    if (isSelectedMonth) {
      documentsStore.setDate(null)
    } else {
      documentsStore.setDate(DateTime.fromJSDate(newDate))
    }
  }

  return (
    <CalendarWithMonthButton
      initialDate={documentsStore?.date?.toJSDate()}
      minDate={overviewStore.startDate}
      handleChange={handleChangeDate}
      title={
        documentsStore?.date
          ? _.capitalize(documentsStore?.date?.toFormat("LLL yyyy"))
          : "All time"
      }
    />
  )
}

export default observer(ApartmentDocumentsCalendar)
