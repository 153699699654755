import React from "react"
import { Caption } from "kui-basic"
import { observer } from "mobx-react"
import DashboardListRow from "../../../common/DashboardListRow"
import { ApartmentServicesRowProps } from "./types"
import { defaultApartmentImageURL } from "../../../../../../utils/content/constants"
import { DynamicRoutes } from "../../../../../../types/route"
import DashboardArchiveRow from "../../../common/DashboardArchiveRow"

const ApartmentServicesRow = (props: ApartmentServicesRowProps) => {
  const { apartment, isArchive } = props
  const apartmentRequisitesLink = `${DynamicRoutes.apartment(
    apartment.id
  )}?tab=expenses&subtab=lines`
  const description = isArchive ? (
    <Caption size="xs" weight={500} color="fiftyP">
      {apartment.notificationDate?.toFormat("dd.MM.yyyy")}
    </Caption>
  ) : (
    ""
  )

  const rowProps = {
    logo: apartment.logo || defaultApartmentImageURL,
    label: apartment.name,
    description,
    link: apartmentRequisitesLink,
  }

  return isArchive ? (
    <DashboardArchiveRow {...rowProps} onClick={apartment.restoreApartment} />
  ) : (
    <DashboardListRow {...rowProps} onClick={apartment.archiveApartment} />
  )
}

export default observer(ApartmentServicesRow)
