import React from "react"
import { observer } from "mobx-react"
import { LoaderState } from "kui-crm"
import ApartmentInspectionsHeader from "../ApartmentInspectionsHeader"
import useApartmentStore, { apartmentStore } from "../../../../../store"
import InspectionsTabs from "../InspectionsTabs"
import { ApartmentLiteContext } from "../../../../../../../store/shared/apartment"

function ApartmentInspectionsInfo() {
  const { inspectionsStore } = useApartmentStore()

  return (
    <ApartmentLiteContext.Provider value={apartmentStore}>
      <ApartmentInspectionsHeader />
      <InspectionsTabs />

      <LoaderState loader={inspectionsStore.actionLoader} />
    </ApartmentLiteContext.Provider>
  )
}

export default observer(ApartmentInspectionsInfo)
