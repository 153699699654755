import React, {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react"
import { InputWithAdornments } from "kui-basic"
import styled from "@emotion/styled"
import { useForkRef } from "kui-utils"
import { InputWithAdornmentsProps } from "./types"

const InputWithAdornment = forwardRef(
  <TFormValues extends object>(
    props: InputWithAdornmentsProps<TFormValues>,
    ref: ForwardedRef<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { form, name, autoHeight, onInput, ...inputProps } = props
    const [isMount, setIsMount] = useState(false)
    const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null)

    const autoHeightInput = () => {
      const element = inputRef.current
      if (element && element.value && element.scrollHeight > 48) {
        element.style.height = "5px"
        element.style.height = `${element.scrollHeight}px`
      }
    }

    useEffect(() => {
      if (autoHeight && isMount) autoHeightInput()
    }, [autoHeight, isMount])

    useEffect(() => {
      if (autoHeight) setIsMount(true)
    }, [])

    useEffect(() => {
      if (autoHeight) {
        const element = inputRef.current
        if (autoHeight && element && !element.value) {
          element.style.height = "48px"
        }
      }
    }, [inputRef.current?.value])

    const handleInput = (e: ChangeEvent) => {
      if (onInput) onInput(e)
      if (autoHeight) autoHeightInput()
    }

    const forwardedRef = useForkRef(ref, inputRef)

    return (
      <StyledInput
        ref={forwardedRef}
        name={name}
        onInput={handleInput}
        rows={3}
        {...form?.register(name)}
        {...inputProps}
      />
    )
  }
)

export default InputWithAdornment

const StyledInput = styled(InputWithAdornments)`
  .KUI-InputAdornment_end-icon {
    right: 15px;
  }
  textarea {
    transform: none;
    margin-right: 0;
    width: 100%;
    font-size: 14px;
  }
  > svg {
    bottom: 9px;
  }
`
