import React from "react"
import { observer } from "mobx-react"
import { Modal } from "kui-crm"
import MeterCreationForm from "../../forms/meters/MeterCreationFormFields"
import { MeterCreationModalProps } from "./types"

function MeterCreationModal(props: MeterCreationModalProps) {
  const { open, handleClose, resource, ...other } = props

  return (
    <Modal
      title={`New ${resource} meter`}
      open={open}
      handleClose={handleClose}
    >
      <MeterCreationForm
        resource={resource}
        handleClose={handleClose}
        {...other}
      />
    </Modal>
  )
}

export default observer(MeterCreationModal)
