import React from "react"
import { observer } from "mobx-react"
import useDashboardStore from "../../store"
import DashboardPageWrapper from "../../components/common/DashboardPageWrapper"
import ConsultantDashboardCards from "./ConsultantDashboardCards"

const ConsultantDashboard = () => {
  const { consultantDashboard } = useDashboardStore()

  return (
    <DashboardPageWrapper dashboardStore={consultantDashboard}>
      <ConsultantDashboardCards />
    </DashboardPageWrapper>
  )
}

export default observer(ConsultantDashboard)
