import React from "react"
import styled from "@emotion/styled"
import { Grid } from "kui-basic"
import { UndisplayedPhotoProps } from "./types"
import ImageCardWithBackdrop from "../AdaptiveImagesBlock/ImageCardWithBackdrop"

const UndisplayedPhoto = (props: UndisplayedPhotoProps) => {
  const { imageUrl, undisplayedImagesCount, handleClick } = props

  if (!undisplayedImagesCount || !imageUrl) {
    return null
  }

  return (
    <Grid item>
      <StyledImageCard url={imageUrl} onClick={handleClick}>
        +{undisplayedImagesCount}
      </StyledImageCard>
    </Grid>
  )
}

export default UndisplayedPhoto

const StyledImageCard = styled(ImageCardWithBackdrop)`
  &,
  .ImageCard-backdrop,
  .ImageCard-wrapper {
    width: 136px;
    height: 136px;
  }
`
