import React from "react"
import { useToggle } from "kui-utils"
import { observer } from "mobx-react"
import AllPhotoContainer from "../AllPhotoContainer"
import UploadPhotoModal from "../UploadPhotoModal"
import PhotoContainer from "../../../../../../../../../components/common/PhotoContainer"
import useApartmentStore from "../../../../../../../store"

function ApartmentAllPhoto() {
  const { isArchived } = useApartmentStore()
  const [isModalOpen, handleModalOpen, handleModalClose] = useToggle(false)

  return (
    <>
      <PhotoContainer
        disabled={isArchived}
        label="All photo"
        handleClick={handleModalOpen}
      >
        <AllPhotoContainer />
      </PhotoContainer>
      <UploadPhotoModal open={isModalOpen} handleClose={handleModalClose} />
    </>
  )
}

export default observer(ApartmentAllPhoto)
