import React from "react"
import { Grid } from "kui-basic"
import { observer } from "mobx-react"
import InputsGroupWithTitle from "../../../../components/common/InputsGroupWithTitle"
import { SCInfoEditableFieldsProps } from "./types"
import useSCStore from "../../store"
import { FieldGroupsParams } from "../../../../components/common/UpdatedFieldsGroup/types"
import ServiceContractInfoStore from "../../../../store/shared/contracts/ServiceContractInfoStore"
import EditableFixedCostBlock from "../EditableFixedCostBlock"
import { InputParams } from "../../../../components/common/InputsGroupWithTitle/types"
import getSCInfoFieldsGroups from "../SCInfoFields/fields"

function SCInfoEditableFields({ form }: SCInfoEditableFieldsProps) {
  const { editor, contractInfoStore } = useSCStore()
  const scFields = getSCInfoFieldsGroups(contractInfoStore.contractInfoValues)

  const formattedFields = scFields
    .map((field) => (field.fieldsGroups ? field.fieldsGroups : field))
    .flat() as FieldGroupsParams<Partial<ServiceContractInfoStore>>[]

  return (
    <Grid container spacing={3}>
      {formattedFields.map(({ size, ...fieldGroup }) => (
        <Grid item xs={size || 12}>
          <InputsGroupWithTitle
            title={fieldGroup.title}
            fields={fieldGroup.fields as InputParams<any>[]}
            form={form}
            isEditing={editor.isEditing}
          />
        </Grid>
      ))}
      <Grid item xs={12}>
        <EditableFixedCostBlock form={form} />
      </Grid>
    </Grid>
  )
}

export default observer(SCInfoEditableFields)
