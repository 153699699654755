export const dashboardLinksFields = [
  { name: "increasedRentObjects", label: "Objects increased rent" },
  { name: "increasedRentPercent", label: "Increased rent in percent" },
  { name: "increasedRentPrice", label: "Increased rent in money" },
  { name: "newObjectsCount", label: "New objects count" },
  { name: "newObjectsCommission", label: "New objects rent in percent" },
  { name: "newObjectsRent", label: "New objects rent in money" },
]

export const dashboardCardsFields = [
  { name: "objectsWithoutAppraisal", label: "Objects need appraisal" },
  { name: "contractsIncreasedRent", label: "Contracts increased rent" },
  { name: "rejectedAppraisals", label: "Rejected appraisals" },
  { name: "newObjectsList", label: "New objects list" },
]
