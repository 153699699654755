import React from "react"

const FullScreenIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.50492 1.51262C1.50141 1.52388 1.5 1.53552 1.5 1.54328V4.24869C1.5 4.52483 1.27614 4.74869 1 4.74869C0.723858 4.74869 0.5 4.52483 0.5 4.24869V1.54328C0.5 1.34202 0.558443 1.09014 0.727506 0.877251C0.911438 0.645635 1.19426 0.502197 1.54427 0.502197H4.26563C4.54177 0.502197 4.76563 0.726055 4.76563 1.0022C4.76563 1.27834 4.54177 1.5022 4.26563 1.5022H1.54427C1.52625 1.5022 1.51445 1.50384 1.50757 1.50529C1.50675 1.50724 1.50585 1.50966 1.50492 1.51262Z"
      fill="#7F8285"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.463 1.50745C12.4509 1.50373 12.4384 1.5022 12.4297 1.5022H9.70834C9.4322 1.5022 9.20834 1.27834 9.20834 1.0022C9.20834 0.726055 9.4322 0.502197 9.70834 0.502197H12.4297C12.6303 0.502197 12.8821 0.559717 13.0956 0.727234C13.3282 0.909817 13.474 1.19219 13.474 1.54328V4.24869C13.474 4.52483 13.2501 4.74869 12.974 4.74869C12.6978 4.74869 12.474 4.52483 12.474 4.24869V1.54328C12.474 1.52762 12.4727 1.51702 12.4714 1.51048C12.4692 1.50955 12.4664 1.50851 12.463 1.50745Z"
      fill="#7F8285"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4728 12.4877C12.4764 12.4765 12.4779 12.4649 12.478 12.4571L12.5 9.75179C12.5023 9.47565 12.7279 9.2536 13.0041 9.25583C13.2802 9.25805 13.5022 9.4837 13.5 9.75983L13.478 12.4652C13.4763 12.6664 13.4158 12.9178 13.2451 13.1293C13.0592 13.3595 12.7753 13.5006 12.4253 13.4978L9.70399 13.4759C9.42786 13.4737 9.20583 13.248 9.20808 12.9719C9.21032 12.6958 9.43599 12.4737 9.71213 12.476L12.4334 12.4978C12.4514 12.498 12.4632 12.4964 12.4701 12.495C12.471 12.4931 12.4719 12.4907 12.4728 12.4877Z"
      fill="#7F8285"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.51506 12.4048C1.5271 12.4086 1.53964 12.4102 1.54832 12.4103L4.26959 12.4322C4.54572 12.4344 4.76775 12.6601 4.76551 12.9362C4.76326 13.2123 4.53759 13.4344 4.26145 13.4321L1.54019 13.4103C1.3396 13.4087 1.08823 13.3491 0.87616 13.1799C0.645019 12.9954 0.501562 12.7119 0.504419 12.3608L0.526433 9.65549C0.52868 9.37936 0.754352 9.15731 1.03049 9.15953C1.30662 9.16175 1.52865 9.3874 1.5264 9.66354L1.50439 12.3689C1.50426 12.3845 1.50547 12.3951 1.50666 12.4017C1.50886 12.4026 1.51165 12.4037 1.51506 12.4048Z"
      fill="#7F8285"
    />
  </svg>
)

export default FullScreenIcon
