import React from "react"
import { Box, Button, Caption } from "kui-basic"
import styled from "@emotion/styled"
import { Modal, FormBottom } from "kui-crm"
import { CloseIcon } from "kui-icon"
import { LockerModalProps } from "./types"
import { DynamicRoutes } from "../../../../types/route"

const LockerModal = (props: LockerModalProps) => {
  const { open, handleClose, handleSubmit, apartment } = props

  const moveLocker = () => {
    handleSubmit()
    handleClose()
  }

  if (!apartment) return null

  return (
    <Modal
      title="Changing the locker object"
      open={open}
      handleClose={handleClose}
    >
      <Box pr={5} pl={5} mb={3}>
        <Caption color="fiftyP" size="s" variant="high">
          This locker is already used in{" "}
          <StyledLink href={DynamicRoutes.apartment(apartment.id)}>
            {apartment.address}
          </StyledLink>
          , do you want to move it?{" "}
        </Caption>
      </Box>
      <FormBottom
        label="Move"
        type="button"
        handleClick={moveLocker}
        endButton={
          <Button
            size="s"
            variant="orange"
            endIcon={<CloseIcon />}
            onClick={handleClose}
          >
            Cancel
          </Button>
        }
      />
    </Modal>
  )
}

export default LockerModal

const StyledLink = styled.a`
  transition: all 0.3s ease-out;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey.fiftyP};
  &:hover {
    color: ${({ theme }) => theme.palette.grey.fourty};
  }
`
