import { RadioGroupWithLabel } from "kui-complex"
import { ResourceTypes, ResourceValues } from "kui-crm"
import { InputParams } from "../../../common/InputsModalGroupWithTitle/types"
import ApartmentAutocomplete from "../../../entityInputs/ApartmentAutocomplete"
import MeterAutocomplete from "../../../entityInputs/MeterAutocomplete"
import MeterReadingValuesFields from "./MeterReadingValuesFields"

const getMeterReadingFields = (
  resource: ResourceTypes | null,
  isInsideMeter?: boolean,
  apartmentId?: number
): InputParams<any>[] => [
  ...(isInsideMeter
    ? []
    : [
        {
          label: "Object",
          name: "apartment",
          variant: "custom",
          CustomInput: ApartmentAutocomplete,
        },
        {
          name: "resource",
          variant: "custom",
          CustomInput: RadioGroupWithLabel,
          options: ResourceValues,
        },
        {
          label: "Meter",
          name: "meter",
          variant: "custom",
          CustomInput: MeterAutocomplete,
          resource: resource || "electricity",
          apartmentId,
        },
      ]),
  {
    name: "values",
    variant: "custom",
    CustomInput: MeterReadingValuesFields,
  },
  {
    label: "Document",
    name: "file",
    variant: "file",
  },
  {
    label: "Check date",
    name: "checkDate",
    variant: "date",
    size: 6,
  },
  {
    label: "Next check",
    name: "nextCheck",
    variant: "date",
    size: 6,
  },
]

export default getMeterReadingFields
