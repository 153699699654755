import React from "react"
import { DashboardNumberCardProps } from "./types"
import DashboardCard from "../DashboardCard"
import DashboardCardInfo from "../DashboardCardInfo"

const DashboardNumberCard = (props: DashboardNumberCardProps) => {
  const { value, ...other } = props

  if (value === null) return null

  return (
    <DashboardCard>
      <DashboardCardInfo
        variant="error"
        value={value}
        compareVariant="less"
        {...other}
      />
    </DashboardCard>
  )
}

export default DashboardNumberCard
