import React from "react"
import { observer } from "mobx-react"
import SortingTable from "../../../../components/common/SortingTable"
import useCompaniesStore from "../../store"
import companiesHeadCellsParams from "./headParams"
import CompanyRow from "../CompanyRow"

function CompaniesTable() {
  const companiesStore = useCompaniesStore()

  return (
    <SortingTable
      withOverlaps
      headParams={companiesHeadCellsParams}
      store={companiesStore}
    >
      {companiesStore.companies.map((company, key) => (
        <CompanyRow key={company.id} company={company} isDark={key % 2 !== 0} />
      ))}
    </SortingTable>
  )
}

export default observer(CompaniesTable)
