import React from "react"
import { useNavigate } from "react-router-dom"
import { observer } from "mobx-react"
import _ from "lodash"
import PageHeader from "../../../../components/common/PageHeader"
import { Routes } from "../../../../types/route"
import useMeterStore from "../../store"

function MeterHeader() {
  const { meterInfoStore, deleteMeter, resource } = useMeterStore()

  const navigate = useNavigate()

  const handleOnBackClick = () => {
    navigate(`${Routes.meteringDevices}/${resource}`)
  }

  const handleDelete = async () => {
    if (meterInfoStore.id) {
      const confirmation = window.confirm(
        "Are you sure you want to delete the counter?"
      )
      if (confirmation) {
        await deleteMeter(meterInfoStore.id)
        handleOnBackClick()
      }
    }
  }

  const breadcrumbs = [
    { link: Routes.meteringDevices, title: "Metering devices" },
    {
      link: `${Routes.meteringDevices}/${resource}`,
      title: resource ? _.capitalize(resource) : "",
    },
    { link: null, title: meterInfoStore.passport },
  ]

  const menuOptions = [
    { label: "Remove metering device", handleClick: handleDelete },
  ]

  return (
    <PageHeader
      handleClick={handleOnBackClick}
      options={menuOptions}
      breadcrumbs={breadcrumbs}
    />
  )
}

export default observer(MeterHeader)
