import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { DateTime } from "luxon"
import { MultistepForm, Loader } from "kui-utils"
import { MeterFeaturesFormFields } from "kui-crm"
import { GasMetersResponse, SingleMeterParams } from "kui-crm/types"
import ApartmentMetersAgent from "../../../../../../agent/ApartmentMeters"
import SingleMeterStore from "./SingleMeterStore"
import MetersAgent from "../../../../../../agent/Meters"
import {
  PostCommonMeterParams,
  PostMeterRequest,
} from "../../../../../../types/api/meters"
import ApartmentExpensesStore from "../ApartmentExpenses"
import { getCommonMeterParams } from "../../../../../../utils/service/apiMapper"

class ApartmentGasMetersStore {
  gasMeters: SingleMeterStore[]

  selectedGasMeter: SingleMeterStore | null

  creationForm: MultistepForm<any>

  maxCountersCount: number | null

  loader: Loader

  actionLoader: Loader

  expensesStore: ApartmentExpensesStore

  total: number | null

  constructor(expensesStore: ApartmentExpensesStore) {
    this.gasMeters = []
    this.selectedGasMeter = null
    this.creationForm = new MultistepForm<any>()
    this.maxCountersCount = null
    this.loader = new Loader()
    this.actionLoader = new Loader()
    this.expensesStore = expensesStore
    this.total = null
    makeAutoObservable(this)
  }

  getGasMeters = async (
    apartmentId: number,
    date: DateTime,
    contractId?: number
  ) => {
    this.loader.startLoading()

    const [err, res] = await to<GasMetersResponse>(
      ApartmentMetersAgent.getMeters(
        apartmentId,
        "gas",
        date.year,
        date.month,
        contractId
      )
    )

    runInAction(() => {
      if (!err && res) {
        this.total = Number(res.total_cost)
        this.gasMeters = res.meters.map(
          (counter) => new SingleMeterStore(counter, "gas", this)
        )
      } else {
        this.loader.setError("fetch gas meters")
      }
      this.loader.endLoading()
    })
  }

  postMeter = async (data: MeterFeaturesFormFields, apartmentId: number) => {
    this.actionLoader.startLoading("gas meter creation")

    const body: PostMeterRequest = ApartmentGasMetersStore.getPostRequestBody(
      data,
      apartmentId
    )

    const [err, res] = await to<SingleMeterParams>(
      MetersAgent.createMeter(body, "gas")
    )

    runInAction(() => {
      if (!err && res) {
        const defaultValues = SingleMeterStore.getDefaultValues(res, data)
        this.gasMeters.unshift(new SingleMeterStore(defaultValues, "gas", this))
      } else {
        this.actionLoader.setError("gas meter creation")
      }
      this.actionLoader.endLoading()
    })
  }

  updateTotalPrice = (total: number) => {
    this.total = total
  }

  archiveById = (id: number) => {
    this.gasMeters.find((counter) => counter.id === id)?.setArchived()
  }

  getGasMeterById = (id: number) => {
    this.selectedGasMeter =
      this.gasMeters.find((counter) => counter.id === id) || null
  }

  updateCountersCount = (count: number | null) => {
    this.maxCountersCount = count
  }

  updateOperatingNumber = (operatingNumber: string) => {
    this.gasMeters.forEach((meter) => {
      meter.updateOperatingNumber(operatingNumber)
    })
  }

  get activeGasMeters() {
    return this.gasMeters.filter((counter) => !counter.isArchived)
  }

  get archiveGasMeters() {
    return this.gasMeters.filter((counter) => counter.isArchived)
  }

  static getPostRequestBody = (
    data: MeterFeaturesFormFields,
    apartmentId: number
  ) => ({
    initial_value: data.initialValue,
    apartment: apartmentId,
    ...(getCommonMeterParams(data) as Omit<PostCommonMeterParams, "apartment">),
    ...(data.replaceableCounter && {
      replaceable_counter: {
        counter: Number(data.replaceableCounter),
        last_value: data.lastValue!,
      },
    }),
  })
}

export default ApartmentGasMetersStore
