import * as yup from "yup"
import { defaultRequiredMessage } from "kui-crm"
import { checkCurrentDateOlderTargetDate } from "../../../../utils/service/schema"

const SCBasicInfoFormSchema = yup.object().shape(
  {
    signDate: yup.mixed().required("This field is required"),
    startDate: checkCurrentDateOlderTargetDate(
      "startDate",
      "signDate"
    ).required("This field is required"),
    contractType: yup.string().required("This field is required"),
    endDate: yup.mixed().when("contractType", {
      is: (value: string) => value === "fixed",
      then: (rule) => rule.required("This field is required"),
    }),
    rentPeriodType: yup.array().of(yup.string()).min(1),
    owner: yup.object({
      name: yup.string().required("This field is required"),
    }),
    folderNumber: yup.string().trim().required(defaultRequiredMessage),
  },
  [
    ["endDate", "contractType"],
    ["startDate", "startDate"],
  ]
)

export default SCBasicInfoFormSchema
