import { makeAutoObservable } from "mobx"
import to from "await-to-js"
import { Loader } from "kui-utils"
import { ResourceTypes } from "kui-crm"
import { TariffOverviewModel } from "../types/api/tariffAPI"
import NotesStore from "../../../store/notes/Notes"
import TariffInfoStore from "./TariffInfoStore"
import DocumentsStore from "../../../store/templates/DocumentsStore"
import TariffAgent from "../../../agent/Tariff"
import { TariffOverviewFormFields } from "../forms/TariffOverviewInfoForm/types"

class TariffOverviewStore {
  tariffInfoStore: TariffInfoStore | null

  notesStore: NotesStore | null

  replacements: TariffInfoStore[]

  documentsStore: DocumentsStore

  loader: Loader

  constructor() {
    this.notesStore = null
    this.tariffInfoStore = null
    this.documentsStore = new DocumentsStore(TariffAgent)
    this.replacements = []
    this.loader = new Loader()
    makeAutoObservable(this)
  }

  patchTariffInfo = async (
    tariffId: number,
    resource: ResourceTypes,
    data: TariffOverviewFormFields
  ) => {
    this.loader.startLoading("tariff info changes")

    await Promise.allSettled([
      this.patchTariffOverview(tariffId, resource, data),
      this.documentsStore.patchDocuments(data.documents, tariffId, resource),
    ])

    this.loader.endLoading()
  }

  patchTariffOverview = async (
    tariffId: number,
    resource: ResourceTypes,
    data: TariffOverviewFormFields
  ) => {
    const body = TariffOverviewStore.getPatchTariffBody(data)

    const [err, res] = await to(TariffAgent.patch(tariffId, body, resource))

    if (res && !err) {
      this.updateTariffOverview(res, resource)
    } else {
      this.loader.setError(`patch tariff`, err)
    }
  }

  updateTariffOverview = (
    tariff: TariffOverviewModel,
    resource: ResourceTypes
  ) => {
    this.tariffInfoStore = new TariffInfoStore(tariff, resource)
    this.replacements = tariff.replaceable_tariffs.map(
      (replacement) => new TariffInfoStore(replacement, resource)
    )
    this.notesStore = new NotesStore(`tariffs/${resource}`, tariff.id)
  }

  static getPatchTariffBody = (data: TariffOverviewFormFields) => ({
    date_from: data.startDate?.toISODate() || "",
    description: data.name,
    district: data.district?.id || null,
    city: data.city?.id || null,
    region: data.region?.id || null,
    residential_complex_name: data.residentialComplex,
    ...data.tariffs,
  })
}

export default TariffOverviewStore
