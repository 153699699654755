import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { observer } from "mobx-react"
import { Grid } from "kui-basic"
import { yupResolver } from "@hookform/resolvers/yup"
import { UserBankAccFields } from "./types"
import ClientBankTaxFields from "../../components/tabs/ClientBankAcc/ClientBankTaxFields"
import DocumentsBlockWithForm from "../../../../components/common/DocumentsBlockWithForm"
import ClientBankInfoFields from "../../components/tabs/ClientBankAcc/ClientBankInfoFields"
import useClientStore from "../../store"
import { UserBankInfoSchema } from "./schema"
import useEditor from "../../../../hooks/useEditor"

function ClientBankAccForm() {
  const { bankStore, overviewStore, editor } = useClientStore()
  const bankEditor = useEditor()
  const form = useForm<UserBankAccFields>({
    defaultValues: {
      bankInfo: { ...bankStore.fields },
      documents: bankStore.documentsStore.documents,
    },
    resolver: yupResolver(UserBankInfoSchema),
  })

  const handleSubmit = (data: UserBankAccFields) => {
    if (overviewStore) {
      bankStore.patchClientBankInfo(overviewStore.id, data)
      editor.endEditing()
      bankEditor.endEditing()
    }
  }

  useEffect(() => {
    if (editor.isAllowedToSendForm) {
      form.handleSubmit(handleSubmit, editor.blockToSendForm)()
    }
  }, [editor.isAllowedToSendForm])

  return (
    <Grid container spacing={3}>
      <Grid item xs={8}>
        {overviewStore?.type === "personal" && (
          <ClientBankTaxFields form={form} />
        )}
        <ClientBankInfoFields editor={bankEditor} form={form} />
      </Grid>
      <Grid item xs={4}>
        <DocumentsBlockWithForm
          form={form}
          disabled={!editor.isEditing}
          documents={bankStore.documentsStore.documents}
          loader={bankStore.documentsStore.loader}
        />
      </Grid>
    </Grid>
  )
}

export default observer(ClientBankAccForm)
