import { observer } from "mobx-react"
import React from "react"
import { MultistepFormProps } from "./types"

function MultistepForm(props: MultistepFormProps) {
  const { form, children, stepShift } = props
  const childrenList = React.Children.toArray(children)

  return <div>{childrenList[form.step - (1 + (stepShift || 0))]}</div>
}

export default observer(MultistepForm)
