import React from "react"
import styled from "@emotion/styled"
import InspectionsTab from "../../tabs/InspectionsTab"
import AppraisalsTab from "../../tabs/AppraisalsTab"
import ImpairmentsTab from "../../tabs/ImpairmentsTab"
import PageTabsPanel from "../../../../../../../components/ui/PageTabsPanel"
import useTabIndex from "../../../../../../../hooks/useTabIndex"
import ProtectedTabs from "../../../../../../../components/common/ProtectedTabs"
import { ProtectedTabParams } from "../../../../../../../components/common/ProtectedTabs/types"

const tabs: ProtectedTabParams[] = [
  { label: "Inspections", permission: "objectInspectionsTab" },
  { label: "Impairments", permission: "objectImpairmentsTab" },
  { label: "Appraisals", permission: "objectAppraisalsTab" },
]

const paths = [
  `?tab=inspections&subtab=inspections`,
  `?tab=inspections&subtab=impairments`,
  `?tab=inspections&subtab=appraisals`,
]

const tabPanels = [<InspectionsTab />, <ImpairmentsTab />, <AppraisalsTab />]

const InspectionsTabs = () => {
  const activeTab = useTabIndex(paths, "subtab")

  return (
    <ProtectedTabs withSpacing tabs={tabs} tabPanels={tabPanels}>
      <StyledTabsPanel activeTab={Number(activeTab)} paths={paths} />
    </ProtectedTabs>
  )
}

export default InspectionsTabs

const StyledTabsPanel = styled(PageTabsPanel)`
  .KUI-TabsContainer {
    background: white;
    top: 121px;
    border: none;
  }
`
