import { makeAutoObservable } from "mobx"
import { CurrencyCodes } from "../../types/common"
import RootStore from "../Root"

class AppStore {
  rootStore: RootStore

  currencyCode: CurrencyCodes

  initialRoute: string

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.currencyCode = "₽"
    this.initialRoute = ""

    makeAutoObservable(this, { rootStore: false })
  }

  setInitialRoute = (route: string) => {
    this.initialRoute = route
  }

  reset = () => {}
}

export default AppStore
