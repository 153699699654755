import * as React from "react"
import { useForm } from "react-hook-form"
import { useEffect } from "react"
import { Box, Grid } from "kui-basic"
import { observer } from "mobx-react"
import useMeterStore from "../../store"
import { MeterOverviewFormFields, MeterOverviewInfoFormProps } from "./types"
import DocumentsBlockWithForm from "../../../../components/common/DocumentsBlockWithForm"
import MeterOverviewInfoFields from "../../components/MeterOverviewInfoFields"

function MeterOverviewInfoForm({ handleSubmit }: MeterOverviewInfoFormProps) {
  const { meterInfoStore, documentsStore, editor } = useMeterStore()
  const form = useForm<MeterOverviewFormFields>({
    defaultValues: {
      ...meterInfoStore,
      tariffChangeDate: null,
      documents: documentsStore.documents || [],
    },
  })

  useEffect(() => {
    if (editor.isAllowedToSendForm) {
      form.handleSubmit(handleSubmit, editor.blockToSendForm)()
    }
  }, [editor.isAllowedToSendForm])

  useEffect(() => {
    if (meterInfoStore.nextCheck)
      form.setValue("nextCheck", meterInfoStore.nextCheck)
  }, [meterInfoStore.nextCheck])

  return (
    <Box mt={2}>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <MeterOverviewInfoFields form={form} />
        </Grid>
        <Grid item xs={4}>
          <DocumentsBlockWithForm
            withScroll
            form={form}
            disabled={!editor.isEditing}
            documents={documentsStore.documents}
            loader={documentsStore.loader}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default observer(MeterOverviewInfoForm)
