import { ResourceTypes, PostTariffRequest } from "kui-crm/types"
import requests from "./index"

const TariffAgent = {
  getById: (id: number, tariff: ResourceTypes) =>
    requests.get(`/tariffs/${tariff}/${id}`),
  patch: (
    id: number,
    data: Partial<PostTariffRequest>,
    tariff: ResourceTypes
  ) => requests.patch(`/tariffs/${tariff}/${id}`, data),
  delete: (id: number, tariff: ResourceTypes) =>
    requests.delete(`/tariffs/${tariff}/${id}`),
  getDocuments: (id: number, tariff: ResourceTypes) =>
    requests.get(`/tariffs/${tariff}/${id}/documents`),
  postDocument: (data: FormData, id: number, tariff: ResourceTypes) =>
    requests.post(`/tariffs/${tariff}/${id}/documents`, data),
  patchDocument: (
    id: number,
    data: FormData,
    tariffId: number,
    tariff: ResourceTypes
  ) => requests.patch(`/tariffs/${tariff}/${tariffId}/documents/${id}`, data),
  deleteDocument: (id: number, tariffId: number, tariff: ResourceTypes) =>
    requests.delete(`/tariffs/${tariff}/${tariffId}/documents/${id}`),
  getTariffObjects: (id: number, tariff: ResourceTypes) =>
    requests.get(`/tariffs/${tariff}/${id}/apartments_use`),
  getTariffCompanies: (id: number, tariff: ResourceTypes) =>
    requests.get(`/tariffs/${tariff}/${id}/companies_use`),
}

export default TariffAgent
