import { Modal } from "kui-crm"
import React from "react"
import { TariffGroupReplaceModalProps } from "./types"
import TariffGroupReplaceForm from "../TariffGroupReplaceForm"
import { TariffGroupReplaceFormFields } from "../TariffGroupReplaceForm/types"

const TariffGroupReplaceModal = (props: TariffGroupReplaceModalProps) => {
  const { form, open, handleClose, setIsDisabled } = props

  const handleSubmit = (data: TariffGroupReplaceFormFields) => {
    form.setValue("tariffChangeDate", data.changeDate)
    if (data.changeDate) setIsDisabled(false)
    else setIsDisabled(true)
    handleClose()
  }

  return (
    <Modal
      data-testid="tariffEditModal"
      title="Tariff change"
      open={open}
      handleClose={handleClose}
    >
      <TariffGroupReplaceForm handleSubmit={handleSubmit} />
    </Modal>
  )
}

export default TariffGroupReplaceModal
