import React from "react"
import { Box, Caption, Grid } from "kui-basic"
import { PriceTermProps } from "./types"

const PriceTerm = (props: PriceTermProps) => {
  const { value, secondValue, label } = props
  return (
    <Grid item>
      <Box mb={1}>
        <Caption size="l" weight={600}>
          {value ? value.toLocaleString() : "-"}{" "}
          {!!secondValue && `- ${secondValue.toLocaleString()} `}₽
        </Caption>
      </Box>
      <Caption color="fiftyP">{label}</Caption>
    </Grid>
  )
}

export default PriceTerm
