import React from "react"
import { ObjectNeedTenantRowProps } from "./types"
import ObjectRowWithDetails from "../../../common/ObjectRowWithDetails"
import { DynamicRoutes } from "../../../../../../types/route"
import DaysWithOverdue from "../../../common/DaysWithOverdue"

const ObjectNeedTenantRow = (props: ObjectNeedTenantRowProps) => {
  const { object } = props
  const { apartment, daysWithoutTenant, isOverdue } = object
  const link = DynamicRoutes.apartment(apartment.id)

  return (
    <ObjectRowWithDetails
      link={link}
      apartment={apartment}
      label="without tenant"
      details={
        <DaysWithOverdue days={daysWithoutTenant} isOverdue={isOverdue} />
      }
    />
  )
}

export default ObjectNeedTenantRow
