import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { DateTime } from "luxon"
import { MultistepForm, Loader } from "kui-utils"
import { MeterFeaturesFormFields } from "kui-crm"
import {
  ElectricityMeterParams,
  ElectricityMetersResponse,
  MeterPayerModel,
  APISubjectRoles,
} from "kui-crm/types"
import ApartmentMetersAgent from "../../../../../../agent/ApartmentMeters"
import ApartmentElectricityMeterStore from "./ApartmentElectricityMeter"
import ElectricityMetersStore from "../../../../../MetersPage/store/ElectricityMetersStore"
import { ElectricityMeterCreationFields } from "../../../../../../components/forms/meters/MeterCreationFormFields/types"
import { matchesAPISubjectRoles } from "../../../../../../utils/content/matches"
import { SubjectRoles } from "../../../../../../types/common"
import ApartmentExpensesStore from "../ApartmentExpenses"

class ApartmentElectricityMetersStore {
  electricityMeters: ApartmentElectricityMeterStore[]

  selectedElectricityMeter: ApartmentElectricityMeterStore | null

  creationForm: MultistepForm<any>

  maxCountersCount: number | null

  loader: Loader

  actionLoader: Loader

  expensesStore: ApartmentExpensesStore

  total: number | null

  constructor(expensesStore: ApartmentExpensesStore) {
    this.electricityMeters = []
    this.selectedElectricityMeter = null
    this.creationForm = new MultistepForm<any>()
    this.maxCountersCount = null
    this.loader = new Loader()
    this.actionLoader = new Loader()
    this.expensesStore = expensesStore
    this.total = null
    makeAutoObservable(this)
  }

  getElectricityMeters = async (
    apartment_id: number,
    date: DateTime,
    contractId?: number
  ) => {
    this.loader.startLoading()

    const [err, res] = await to<ElectricityMetersResponse>(
      ApartmentMetersAgent.getMeters(
        apartment_id,
        "electricity",
        date.year,
        date.month,
        contractId
      )
    )

    runInAction(() => {
      if (!err && res) {
        this.total = Number(res.total_cost)
        this.electricityMeters = res.meters.map(
          (counter) => new ApartmentElectricityMeterStore(counter, this)
        )
      } else {
        this.loader.setError("fetch electricity meters")
      }
      this.loader.endLoading()
    })
  }

  postMeter = async (apartmentId: number, data: MeterFeaturesFormFields) => {
    this.actionLoader.startLoading("electricity meter creation")

    const body = {
      ...ElectricityMetersStore.getCreationBody(
        data as ElectricityMeterCreationFields
      ),
      apartment: apartmentId,
    }

    const [err, res] = await to<ElectricityMeterParams>(
      ApartmentMetersAgent.postElectricityMeter(body)
    )

    runInAction(() => {
      if (!err && res) {
        const defaultValues = ApartmentElectricityMetersStore.getDefaultValues(
          res,
          data
        )
        this.electricityMeters.unshift(
          new ApartmentElectricityMeterStore(defaultValues, this)
        )
      } else {
        this.actionLoader.setError("electricity meter creation")
      }
      this.actionLoader.endLoading()
    })
  }

  updateTotalPrice = (total: number) => {
    this.total = total
  }

  archiveById = (id: number) => {
    this.electricityMeters.find((counter) => counter.id === id)?.setArchived()
  }

  getElectricityMeterById = (id: number) => {
    this.selectedElectricityMeter =
      this.electricityMeters.find((counter) => counter.id === id) || null
  }

  updateOperatingNumber = (operatingNumber: string) => {
    this.electricityMeters.forEach((meter) => {
      meter.updateOperatingNumber(operatingNumber)
    })
  }

  updateCountersCount = (count: number | null) => {
    this.maxCountersCount = count
  }

  get activeElectricityMeters() {
    return this.electricityMeters.filter((counter) => !counter.isArchived)
  }

  get archiveElectricityMeters() {
    return this.electricityMeters.filter((counter) => counter.isArchived)
  }

  static getDefaultValues = (
    res: ElectricityMeterParams,
    fields: MeterFeaturesFormFields
  ) => ({
    ...res,
    payer: {
      ...(res.payer as MeterPayerModel),
      payer_type: matchesAPISubjectRoles[
        fields.meterPayer?.mainPayer as SubjectRoles
      ] as APISubjectRoles,
    },
    values: {
      T1: {
        previous_value: fields.initialValueT1!.toString(),
        initial_value: fields.initialValueT1!.toString(),
        edit_date: DateTime.now().toISODate() || "",
        counter_value: fields.initialValueT1!.toString(),
        consumption: 0,
        cost: "0",
      },
      ...(fields.initialValueT2 && {
        T2: {
          previous_value: fields.initialValueT2?.toString(),
          initial_value: fields.initialValueT2?.toString(),
          edit_date: DateTime.now().toISODate() || "",
          counter_value: fields.initialValueT2?.toString(),
          consumption: 0,
          cost: "0",
        },
      }),
      ...(fields.initialValueT3 && {
        T3: {
          previous_value: fields.initialValueT3?.toString(),
          initial_value: fields.initialValueT3?.toString(),
          edit_date: DateTime.now().toISODate() || "",
          counter_value: fields.initialValueT3?.toString(),
          consumption: 0,
          cost: "0",
        },
      }),
    },
  })
}

export default ApartmentElectricityMetersStore
