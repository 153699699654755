import React from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box } from "kui-basic"
import { FormBottom } from "kui-crm"
import { ClientTypeStepFields, ClientTypeStepProps } from "./types"
import ClientTypeStepSchema from "./schema"
import InputSelect from "../../../ui/InputSelect"
import { ClientTypeValues } from "../../../../utils/content/listsOptions"

const ClientTypeStep = ({ formStore }: ClientTypeStepProps) => {
  const form = useForm<ClientTypeStepFields>({
    resolver: yupResolver(ClientTypeStepSchema),
    defaultValues: {
      type: formStore.fields?.type,
    },
  })

  const handleSubmit = (data: ClientTypeStepFields) => {
    const { updateFormFields, nextStep } = formStore

    updateFormFields(data)
    nextStep()
  }
  return (
    <form>
      <Box pr={5} pl={5} mb={3}>
        <InputSelect
          isRequired
          label="Type account"
          name="type"
          options={ClientTypeValues}
          form={form}
        />
      </Box>
      <FormBottom
        label="Next"
        variant="next"
        type="button"
        handleClick={form.handleSubmit(handleSubmit)}
      />
    </form>
  )
}

export default ClientTypeStep
