import React from "react"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { Caption, Button, Grid } from "kui-basic"
import { useToggle } from "kui-utils"
import useCompanyStore from "../../../../store"
import { PopupStylesProps } from "./types"
import Container from "../../../../../../components/ui/Container"
import CompanyObjectsConnectionModal from "../CompanyObjectsConnectionModal"

function CompanySelectedObjectsPopup() {
  const [isModalOpen, handleModalOpen, handleModalClose] = useToggle()
  const { objectsStore } = useCompanyStore()
  const selectedObjectsCount = objectsStore.selectedObjects?.length
  const isHidden = selectedObjectsCount === 0

  return (
    <>
      <StyledWrapper isHidden={isHidden}>
        <StyledContainer>
          <StyledPopup>
            <Grid container justify="space-between" alignItems="center">
              <Caption weight={500}>
                Select {selectedObjectsCount} objects
              </Caption>
              <Button size="s" variant="orange" onClick={handleModalOpen}>
                Change company
              </Button>
            </Grid>
          </StyledPopup>
        </StyledContainer>
      </StyledWrapper>
      <CompanyObjectsConnectionModal
        open={isModalOpen}
        handleClose={handleModalClose}
      />
    </>
  )
}

export default observer(CompanySelectedObjectsPopup)

const StyledPopup = styled.div`
  padding: 12px 28px;
  box-shadow: 0px 0px 1px rgba(10, 31, 68, 0.08),
    0px -3px 4px rgba(10, 31, 68, 0.03);
  border-radius: 12px 12px 0 0;
  background: white;
`

const StyledWrapper = styled.div<PopupStylesProps>`
  position: fixed;
  left: 0;
  bottom: ${({ isHidden }) => (isHidden ? -64 : 0)}px;
  transition: all ease-out 0.3s;
  width: 100%;
`

const StyledContainer = styled(Container)`
  max-width: 1344px !important;
`
