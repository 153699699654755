import React from "react"
import styled from "@emotion/styled"
import { Grid, Caption } from "kui-basic"
import { FileIcon } from "kui-icon"
import { FileLinkProps, FileLinkStylesProps } from "./types"

const FileLink = (props: FileLinkProps) => {
  const { label, link, color, className, ...other } = props
  if (!link) return null

  return (
    <a href={link} rel="noreferrer" target="_blank" className={className}>
      <Grid container alignItems="center" wrap="nowrap">
        <StyledFileIcon textColor={color} width={12} height={14} />
        <Caption color={color || "fiftyP"} size="xs" weight={500} {...other}>
          {label}
        </Caption>
      </Grid>
    </a>
  )
}

export default FileLink

const StyledFileIcon = styled(FileIcon)<FileLinkStylesProps>`
  margin-right: 4px;
  path {
    fill: ${({ theme }) => theme.palette.grey.zero};
    stroke: ${({ theme }) => theme.palette.grey.zero};
    &:first-child {
      stroke: ${({ theme, textColor }) =>
        theme.palette.grey[textColor || "thirty"]};
    },
  }
`
