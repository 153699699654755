import { DateTime } from "luxon"
import { makeAutoObservable } from "mobx"
import to from "await-to-js"
import { resHandler } from "kui-utils"
import UserLiteStore from "../../../../store/templates/UserLite"
import { ApartmentProxyModel } from "../../types/api/apartmentOwnershipAPI"
import { ApartmentProxyFormFields } from "../../forms/ownership/ApartmentOwnershipForm/types"
import ApartmentOwnershipAgent from "../../../../agent/ApartmentOwnership"
import { ParentStoreInterface } from "../../../../types/common"
import { ApartmentOwnershipMembers } from "../../components/tabs/ApartmentOwnershipTab/OwnershipMemberModal/types"
import { clearNotValidFields } from "../../../../utils/service/mapper"

class ApartmentProxyStore {
  id: number

  memberType: ApartmentOwnershipMembers

  member: UserLiteStore

  documentNumber: string

  numberOfForm: string

  dateOfIssue: DateTime | null

  dateOfTransfer: DateTime | null

  certificationAuthority: string

  dateOfEntry: DateTime | null

  registrationNumber: string

  proxiesStore: ParentStoreInterface

  constructor(proxy: ApartmentProxyModel, proxiesStore: ParentStoreInterface) {
    this.id = proxy.id
    this.member = UserLiteStore.initFromLiteUserModel(proxy.user)
    this.documentNumber = proxy.document_number
    this.numberOfForm = proxy.form_number
    this.dateOfIssue = proxy.date_of_issue
      ? DateTime.fromISO(proxy.date_of_issue)
      : null
    this.dateOfTransfer = proxy.date_of_transfer
      ? DateTime.fromISO(proxy.date_of_transfer)
      : null
    this.dateOfEntry = proxy.date_of_entry
      ? DateTime.fromISO(proxy.date_of_entry)
      : null
    this.certificationAuthority = proxy.certyfing_author
    this.registrationNumber = proxy.registration_number
    this.proxiesStore = proxiesStore
    this.memberType = "proxy"
    makeAutoObservable(this)
  }

  delete = async (apartmentId: number) => {
    this.proxiesStore.loader.cleanError()
    const [err] = await to(
      ApartmentOwnershipAgent.deleteProxy(this.id, apartmentId)
    )
    if (err) {
      this.proxiesStore.loader.setError("proxy removal", err)
    } else {
      this.proxiesStore.delete(this.id)
    }
  }

  patch = async (data: ApartmentProxyFormFields, apartmentId: number) => {
    this.proxiesStore.loader.cleanError()

    const body = ApartmentProxyStore.getBodyForPatchProxy(data)

    const response = await to(
      ApartmentOwnershipAgent.patchProxy(this.id, body, apartmentId)
    )

    resHandler(
      response,
      this.proxiesStore.loader,
      this.updateProxy,
      "patch proxy",
      {
        withEndLoading: false,
      }
    )
  }

  updateProxy = (proxy: ApartmentProxyModel) => {
    this.member = proxy.user
      ? UserLiteStore.initFromLiteUserModel(proxy.user)
      : this.member
    this.documentNumber = proxy.document_number || this.documentNumber
    this.numberOfForm = proxy.form_number || this.numberOfForm
    this.dateOfIssue = proxy.date_of_issue
      ? DateTime.fromISO(proxy.date_of_issue)
      : this.dateOfIssue
    this.dateOfTransfer = proxy.date_of_transfer
      ? DateTime.fromISO(proxy.date_of_transfer)
      : this.dateOfTransfer
    this.dateOfEntry = proxy.date_of_entry
      ? DateTime.fromISO(proxy.date_of_entry)
      : this.dateOfEntry
    this.certificationAuthority =
      proxy.certyfing_author || this.certificationAuthority
    this.registrationNumber =
      proxy.registration_number || this.registrationNumber
  }

  static getBodyForPatchProxy = (data: ApartmentProxyFormFields) =>
    clearNotValidFields({
      user_id: data.member.id,
      certyfing_author: data.certificationAuthority || null,
      registration_number: data.registrationNumber || null,
      document_number: data.documentNumber || null,
      form_number: data.numberOfForm || null,
      date_of_issue: data.dateOfIssue ? data.dateOfIssue.toISODate() : null,
      date_of_transfer: data.dateOfTransfer
        ? data.dateOfTransfer.toISODate()
        : null,
      date_of_entry: data.dateOfEntry ? data.dateOfEntry.toISODate() : null,
    })
}

export default ApartmentProxyStore
