import React from "react"
import { Modal } from "kui-crm"
import AbortServiceForm from "../../../../forms/services/AbortServiceForm"
import { AbortServiceFormFields } from "../../../../forms/services/AbortServiceForm/types"
import { AbortServiceModalProps } from "./types"

const AbortServiceModal = (props: AbortServiceModalProps) => {
  const { service, open, handleClose } = props

  const handleSubmit = (data: AbortServiceFormFields) => {
    service.abortService(data.abortDate)
    handleClose()
  }

  return (
    <Modal title="Abort service expense" open={open} handleClose={handleClose}>
      <AbortServiceForm handleSubmit={handleSubmit} />
    </Modal>
  )
}

export default AbortServiceModal
