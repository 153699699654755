import React from "react"
import styled from "@emotion/styled"
import { Button } from "kui-basic"
import { CloseIcon } from "kui-icon"
import { useMapCards } from "kui-crm"
import ApartmentMarker from "../ApartmentMarker"
import ApartmentCard from "../ApartmentCard"
import { ApartmentOverlayProps } from "./types"

function ApartmentOverlay({ apartment, map }: ApartmentOverlayProps) {
  const { ref, isOpen, closeCard, toggleCard } = useMapCards(
    apartment.geolocation,
    map
  )

  return (
    <StyledWrapper ref={ref}>
      <ApartmentMarker
        active={isOpen}
        apartment={apartment}
        onClick={toggleCard}
      />
      {isOpen && (
        <StyledCardWrapper>
          <ApartmentCard apartment={apartment} />
          <StyledCloseButton
            isCircle
            size="xs"
            variant="whiteWithGray"
            onClick={closeCard}
            aria-label="закрыть"
          >
            <CloseIcon width={8} height={8} />
          </StyledCloseButton>
        </StyledCardWrapper>
      )}
    </StyledWrapper>
  )
}

export default ApartmentOverlay

const StyledCloseButton = styled(Button)`
  position: absolute;
  top: -16px;
  right: -16px;
  z-index: 1;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-color: transparent;
  & svg {
    & path {
      fill: ${({ theme }) => theme.palette.grey.fiftyP};
    }
  }
`

const StyledWrapper = styled.div`
  position: relative;
`

const StyledCardWrapper = styled.div`
  position: absolute;
  bottom: 46px;
  left: -110px;
  z-index: 1;
`
