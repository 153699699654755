import { makeAutoObservable } from "mobx"
import { Loader, resHandler } from "kui-utils"
import to from "await-to-js"
import { MeterTypes, ResourceTypes } from "kui-crm"
import DashboardAgent from "../../../../agent/Dashboard"
import { ApartmentLinkParams } from "../../../../types/store/apartments"
import MetersWithDeviatingValuesStore from "./MetersWithDeviatingValuesStore"
import { MeterWithDeviatingValueModel } from "../../types/api/meters"
import ApartmentsStore from "../../../../store/lites/ApartmentsStore"

class MeterWithDeviatingValueStore {
  notifyId: number

  id: number

  apartment: ApartmentLinkParams

  resource: ResourceTypes

  type: MeterTypes

  valueType: MeterTypes

  deviation: number

  metersStore: MetersWithDeviatingValuesStore

  loader: Loader

  constructor(
    meterNotify: MeterWithDeviatingValueModel,
    metersStore: MetersWithDeviatingValuesStore
  ) {
    const { apartment, id, meter, deviation, value_type } = meterNotify
    this.notifyId = id
    this.id = meter.id
    this.apartment = ApartmentsStore.getApartmentLinkParams(apartment)
    this.resource = meter.resource_type
    this.type = meter.meter_type
    this.valueType = value_type
    this.deviation = Number(deviation)
    this.metersStore = metersStore
    this.loader = new Loader()
    makeAutoObservable(this)
  }

  archiveMeter = async () => {
    this.loader.startLoading()

    const response = await to(
      DashboardAgent.archiveDashboardItem(this.notifyId)
    )

    resHandler(response, this.loader, () => {
      this.metersStore.removeFromMetersList(this.notifyId)
    })
  }

  restoreMeter = async () => {
    this.loader.startLoading()

    const response = await to(
      DashboardAgent.unArchiveDashboardItem(this.notifyId)
    )

    resHandler(response, this.loader, () => {
      this.metersStore.removeFromArchiveList(this.notifyId)
    })
  }
}

export default MeterWithDeviatingValueStore
