import * as React from "react"
import styled from "@emotion/styled"
import { Caption } from "kui-basic"
import { AutocompleteOptionProps } from "./types"

function AutocompleteOption({ label }: AutocompleteOptionProps) {
  return (
    <StyledWrapper>
      <Caption size="s" weight={500}>
        {label}
      </Caption>
    </StyledWrapper>
  )
}

export default AutocompleteOption

const StyledWrapper = styled.div`
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.palette.background.light2};
  },
`
