import React, { useRef } from "react"
import { observer } from "mobx-react"
import { MultistepForm as MultistepFormStore } from "kui-utils"
import { CreatingFillingFormProps } from "./types"
import FillingInfoForm from "../FillingInfoForm"
import UploadFillingPhoto from "../UploadFillingPhoto"
import { FillingFormFields } from "../types"
import { UploadFillingPhotoFields } from "../UploadFillingPhoto/types"
import MultistepForm from "../../../../../components/common/MultistepForm"
import useResetMultistepForm from "../../../../../hooks/useResetMultistepForm"

function FillingCreatingForm({ type, handleSubmit }: CreatingFillingFormProps) {
  const formStoreRef = useRef(new MultistepFormStore<FillingFormFields, any>())
  const formStore = formStoreRef.current
  const { fields } = formStore

  const handleFormSubmit = (data: UploadFillingPhotoFields) => {
    handleSubmit({ ...fields, ...data } as FillingFormFields)
    formStore.resetForm()
  }

  useResetMultistepForm(formStore)

  return (
    <MultistepForm form={formStore}>
      <FillingInfoForm type={type} formMaker={formStore} />
      <UploadFillingPhoto
        formMaker={formStore}
        handleSubmit={handleFormSubmit}
      />
    </MultistepForm>
  )
}

export default observer(FillingCreatingForm)
