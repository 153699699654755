import * as yup from "yup"

const PaymentCreationFormSchema = yup.object().shape({
  payerId: yup.number().required("Choose a payer"),
  date: yup.mixed().required("Enter the date"),
  amount: yup
    .string()
    .required("Enter the amount")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
})

export default PaymentCreationFormSchema
