import { rest } from "msw"
import {
  ElectricityMeterParams,
  ElectricityMetersResponse,
  MeterTariffModel,
  WaterMeterParams,
  WaterMetersResponse,
} from "kui-crm/types"
import {
  coldWaterMeterMock,
  countersIds,
  electricityMetersMock,
  electricityMetersWithArchivedMock,
  hotWaterMeterMock,
  t1CounterMock,
  t2CounterMock,
  waterMetersMock,
  waterMetersWithArchivedMock,
} from "./data"
import {
  PatchElectricityMeterParams,
  PatchWaterMeterParams,
  PutElectricityMeterValues,
  PutWaterMeterValues,
} from "../../../pages/ApartmentPage/pages/ExpensesPage/types/api/expensesMetersAPI"

const apartmentCountersHandlers = [
  rest.get<ElectricityMetersResponse | Error>(
    `${process.env.REACT_APP_API_URL}/apartments/:apartment_id/expenses/meters/electricity`,
    (req, resp, ctx) => {
      const apartmentId = Number(req.params.apartment_id)
      if (apartmentId === countersIds.active) {
        const data = ctx.json(electricityMetersMock)
        return resp(data)
      }
      const data = ctx.json(electricityMetersWithArchivedMock)
      return resp(data)
    }
  ),
  rest.get<WaterMetersResponse | Error>(
    `${process.env.REACT_APP_API_URL}/apartments/:apartment_id/expenses/meters/water`,
    (req, resp, ctx) => {
      const apartmentId = Number(req.params.apartment_id)
      if (apartmentId === countersIds.active) {
        const data = ctx.json(waterMetersMock)
        return resp(data)
      }
      const data = ctx.json(waterMetersWithArchivedMock)
      return resp(data)
    }
  ),
  rest.post<PatchElectricityMeterParams, ElectricityMeterParams | Error>(
    `${process.env.REACT_APP_API_URL}/meters/electricity`,
    (req, resp, ctx) => {
      if (
        req.body.passport_number &&
        req.body.activation_date &&
        req.body.check_date &&
        req.body.values?.T1 &&
        req.body.type
      ) {
        if (req.body.type === "T1") {
          const t1Value = {
            ...t1CounterMock.values.T1,
            initial_value: req.body.values.T1,
          } as MeterTariffModel

          const data = ctx.json({
            ...t1CounterMock,
            ...req.body,
            company: t1CounterMock.company,
            values: { T1: t1Value },
            tariff: {
              ...t1CounterMock.tariff!,
              value: req.body.tariff,
            },
          })
          return resp(data)
        }
        const t1Value = {
          ...t2CounterMock.values.T1,
          initial_value: req.body.values.T1,
        } as MeterTariffModel

        const t2Value = {
          ...t2CounterMock.values.T2,
          initial_value: req.body.values.T2,
        } as MeterTariffModel

        const data = ctx.json({
          ...t2CounterMock,
          ...req.body,
          values: { T1: t1Value, T2: t2Value },
          company: t2CounterMock.company,
          tariff: {
            ...t2CounterMock.tariff!,
            value: req.body.tariff,
          },
        })
        return resp(data)
      }
      return resp(ctx.status(400))
    }
  ),
  rest.post<PatchWaterMeterParams, WaterMeterParams | Error>(
    `${process.env.REACT_APP_API_URL}/meters/water`,
    (req, resp, ctx) => {
      if (
        req.body.passport_number &&
        req.body.activation_date &&
        req.body.check_date &&
        req.body.type
      ) {
        if (req.body.type === "cold") {
          const data = ctx.json({
            ...coldWaterMeterMock,
            ...req.body,
            company: coldWaterMeterMock.company,
            tariff: {
              ...coldWaterMeterMock.tariff!,
              value: Number(req.body.tariff),
            },
          })
          return resp(data)
        }
        const data = ctx.json({
          ...hotWaterMeterMock,
          ...req.body,
          company: coldWaterMeterMock.company,
          tariff: {
            ...hotWaterMeterMock.tariff!,
            value: Number(req.body.tariff),
          },
        })
        return resp(data)
      }
      return resp(ctx.status(400))
    }
  ),
  rest.patch<PatchElectricityMeterParams, ElectricityMeterParams | Error>(
    `${process.env.REACT_APP_API_URL}/meters/electricity/:id`,
    (req, resp, ctx) => {
      if (req.body.type === "T1") {
        const t1Value = {
          ...t1CounterMock.values.T1,
          initial_value:
            req.body.values?.T1 || t1CounterMock.values.T1.initial_value,
          counter_value:
            req.body.values?.T1 || t1CounterMock.values.T1.counter_value,
        } as MeterTariffModel

        const data = ctx.json({
          ...t1CounterMock,
          ...req.body,
          values: { T1: t1Value },
          company: t1CounterMock.company,
          tariff: {
            ...t1CounterMock.tariff!,
            value: req.body.tariff,
          },
        })
        return resp(data)
      }
      const t1Value = {
        ...t2CounterMock.values.T1,
        initial_value:
          req.body.values?.T1 || t2CounterMock.values.T1.initial_value,
        counter_value:
          req.body.values?.T1 || t2CounterMock.values.T1.counter_value,
      } as MeterTariffModel

      const t2Value = {
        ...t2CounterMock.values.T2,
        initial_value:
          req.body.values?.T2 || t2CounterMock.values.T2!.initial_value,
        counter_value:
          req.body.values?.T2 || t2CounterMock.values.T2!.counter_value,
      } as MeterTariffModel

      const data = ctx.json({
        ...t2CounterMock,
        ...req.body,
        values: { T1: t1Value, T2: t2Value },
        company: t2CounterMock.company,
        tariff: {
          ...t2CounterMock.tariff!,
          value: req.body.tariff,
        },
      })
      return resp(data)
    }
  ),
  rest.patch<PatchWaterMeterParams, WaterMeterParams | Error>(
    `${process.env.REACT_APP_API_URL}/meters/water/:id`,
    (req, resp, ctx) => {
      if (req.body.type === "cold") {
        const data = ctx.json({
          ...coldWaterMeterMock,
          ...req.body,
          company: coldWaterMeterMock.company,
          tariff: {
            ...coldWaterMeterMock.tariff!,
            value: Number(req.body.tariff),
          },
        })

        return resp(data)
      }
      const data = ctx.json({
        ...hotWaterMeterMock,
        ...req.body,
        company: hotWaterMeterMock.company,
        tariff: {
          ...hotWaterMeterMock.tariff!,
          value: Number(req.body.tariff),
        },
      })
      return resp(data)
    }
  ),
  rest.post<PutWaterMeterValues, Partial<PutWaterMeterValues> | Error>(
    `${process.env.REACT_APP_API_URL}/meters/water/:id/values`,
    (req, resp, ctx) => {
      if (req.body.value) {
        const data = ctx.json({
          value: req.body.value,
        })
        return resp(data)
      }
      return resp(ctx.status(400), ctx.json(new Error("Error")))
    }
  ),
  rest.post<
    PutElectricityMeterValues,
    Partial<PutElectricityMeterValues> | Error
  >(
    `${process.env.REACT_APP_API_URL}/meters/electricity/:id/values`,
    (req, resp, ctx) => {
      const data = ctx.json({
        ...(req.body.T1 && {
          T1: req.body.T1,
        }),
        ...(req.body.T2 && {
          T2: req.body.T2,
        }),
        ...(req.body.T3 && {
          T3: req.body.T3,
        }),
      })
      return resp(data)
    }
  ),
]

export default apartmentCountersHandlers
