import React from "react"
import { observer } from "mobx-react"
import SortingTable from "../../../../../components/common/SortingTable"
import serviceHeadCells from "./content"
import useRegistriesStore from "../../../store"
import ServiceRegistryRow from "./ServiceRegistryRow"
import ServicesRegistryStore from "../../../store/variants/ServicesRegistryStore"
import PaginationPage from "../../../../../components/common/PaginationPage"

const ExpensesRegistryTable = () => {
  const { activeRegistryStore } = useRegistriesStore()

  if (!activeRegistryStore) return null

  const { services } = activeRegistryStore as ServicesRegistryStore

  return (
    <PaginationPage store={activeRegistryStore}>
      <SortingTable
        headParams={serviceHeadCells}
        store={activeRegistryStore}
        offset={188}
      >
        {services?.map((service, key) => (
          <ServiceRegistryRow
            key={service.id}
            service={service}
            isDark={key % 2 !== 0}
          />
        ))}
      </SortingTable>
    </PaginationPage>
  )
}

export default observer(ExpensesRegistryTable)
