/** @jsxImportSource @emotion/react */
import * as React from "react"
import {
  CartesianGrid,
  ComposedChart,
  Area,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  Line,
  ResponsiveContainer,
} from "recharts"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { LoaderState } from "kui-crm"
import { useEffect } from "react"
import ApartmentChartAxisLabel from "./ApartmentChartAxisLabel"
import ApartmentChartYTick from "./ApartmentChartYTick"
import ApartmentChartXTick from "./ApartmentChartXTick"
import usePromotionStore from "../../../../store"
import ApartmentChartTooltip from "./ApartmentChartTooltip"
import ApartmentChartLegends from "./ApartmentChartLegends"
import Loading from "../../../../../../../../components/common/Loading"

function ApartmentChart() {
  const {
    channelsStore: { chartStore },
  } = usePromotionStore()

  useEffect(() => {
    chartStore.getChartInfo()
  }, [])

  if (chartStore.loader.isLoading) {
    return <Loading height="100px" />
  }

  return (
    <>
      <ApartmentChartLegends />

      <Wrapper>
        <YAxisLabel>
          <ApartmentChartAxisLabel>cost</ApartmentChartAxisLabel>
        </YAxisLabel>
        <XAxisLabel>
          <ApartmentChartAxisLabel>period</ApartmentChartAxisLabel>
        </XAxisLabel>
        <BarBackground>
          <BarLabel>
            <ApartmentChartAxisLabel variant="grey">
              offers
            </ApartmentChartAxisLabel>
          </BarLabel>
        </BarBackground>
        <ResponsiveContainer width="100%" height={400}>
          <ComposedChart
            data={chartStore?.ticks}
            margin={{ top: 50, bottom: 10, left: 16 }}
          >
            <XAxis hide dataKey="formattedDate" />
            <YAxis
              type="number"
              dataKey="price"
              domain={["dataMin", "dataMax"]}
              ticks={chartStore?.costsTicks}
              tick={<ApartmentChartYTick />}
              tickSize={0}
              axisLine={false}
            />

            <Tooltip
              content={<ApartmentChartTooltip termination="₽" />}
              filterNull={false}
            />

            <Area
              type="monotone"
              dataKey="price"
              stroke="#ED713C"
              strokeWidth={2}
              fillOpacity={1}
              fill="#FEF7F3"
            />

            {chartStore.managerEstimatedPrices.length > 0 && (
              <Line
                data={chartStore.managerEstimatedPrices}
                strokeWidth={2}
                type="monotone"
                dataKey="managerPrice"
                stroke="#0098B6"
                dot={false}
              />
            )}

            <CartesianGrid vertical={false} />
          </ComposedChart>
        </ResponsiveContainer>
        <ResponsiveContainer width="100%" height={110}>
          <ComposedChart
            data={chartStore?.volumeOfApartments}
            margin={{ left: 10, top: 40 }}
          >
            <Tooltip content={<ApartmentChartTooltip />} filterNull={false} />
            <Bar dataKey="count" fill="#C4C4C4" barSize={4} />
            <YAxis tick={<div />} axisLine={false} tickLine={false} />
            <XAxis
              dataKey="formattedDate"
              tick={<ApartmentChartXTick monthTicks={chartStore?.monthTicks} />}
              axisLine={false}
              tickLine={false}
              interval={0}
              scale="point"
              padding={{ left: 10, right: 5 }}
            />
          </ComposedChart>
        </ResponsiveContainer>

        <LoaderState loader={chartStore.loader} onlyError />
      </Wrapper>
    </>
  )
}

export default observer(ApartmentChart)

const Wrapper = styled.div`
  border-radius: 8px;
  background: ${({ theme }) => theme.palette.background.light1};
  padding: 18px 0 16px 12px;
  position: relative;
`

const YAxisLabel = styled.div`
  position: absolute;
  top: 18px;
  left: 12px;
`

const XAxisLabel = styled.div`
  position: absolute;
  left: 12px;
  bottom: 10px;
`

const BarLabel = styled.div`
  position: absolute;
  left: 16px;
  top: 8px;
`

const BarBackground = styled.div`
  position: absolute;
  bottom: 46px;
  width: calc(100% - 89px);
  height: 90px;
  right: 1px;
  border-radius: 0px 0px 3px 3px;
  background: white;
`
