import React from "react"
import { Box } from "kui-basic"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { observer } from "mobx-react"
import { FormBottom } from "kui-crm"
import SCApartmentFormSchema from "./schema"
import useRCsStore from "../../store"
import SCCommonFields from "../../components/RCCommonFields"
import { RentalContractApartmentFields } from "../CreateRentalContractForm/types"
import { ApartmentLiteParams } from "../../../../types/store/apartments"

function RCApartmentForm() {
  const { creationForm } = useRCsStore()
  const { fields, updateFormFields, nextStep } = creationForm
  const form = useForm<RentalContractApartmentFields>({
    resolver: yupResolver(SCApartmentFormSchema),
    defaultValues: {
      ...fields,
    },
  })

  const handleSubmit = (data: RentalContractApartmentFields) => {
    updateFormFields(data)
    nextStep()
  }

  const handleSelect = (apartment: ApartmentLiteParams) => {
    form.setValue(
      "serviceContractStartDate",
      apartment?.serviceContractStartDate
    )
    form.setValue("initialCommission", apartment?.initialCommission)
  }

  return (
    <form data-testid="form_step_1" onSubmit={form.handleSubmit(handleSubmit)}>
      <SCCommonFields form={form} onSelect={handleSelect} />
      <Box mt={3}>
        <FormBottom label="Next" variant="next" />
      </Box>
    </form>
  )
}

export default observer(RCApartmentForm)
