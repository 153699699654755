import requests from "./index"

const ApartmentOverviewAgent = {
  getDocuments: (offset: number, limit: number, id: number) =>
    requests.get(
      `/apartments/${id}/attachments?offset=${offset}&limit=${limit}`
    ),
  postDocument: (data: FormData, id: number) =>
    requests.post(`/apartments/${id}/attachments`, data),
  deleteDocument: (documentId: number, userId: number) =>
    requests.delete(`/apartments/${userId}/attachments/${documentId}`),
  patchDocument: (documentId: number, data: FormData, userId: number) =>
    requests.patch(`/apartments/${userId}/attachments/${documentId}`, data),
}

export default ApartmentOverviewAgent
