import React from "react"
import { PersonIcon } from "kui-icon"
import { observer } from "mobx-react"
import { PendingContractsCardProps } from "./types"
import DashboardListCard from "../../common/DashboardListCard"
import PendingContractRow from "./PendingContractRow"

const PendingContractsCard = (props: PendingContractsCardProps) => {
  const { contractsStore } = props
  const { value, contracts, loader, fetchContracts, paginator, type } =
    contractsStore

  return (
    <DashboardListCard
      value={value}
      label={`Pending ${type} contracts`}
      icon={<PersonIcon />}
      loader={loader}
      paginator={paginator}
      fetchAll={fetchContracts}
    >
      {contracts.map((contract) => (
        <PendingContractRow
          key={contract.id}
          contract={contract}
          contractType={type}
        />
      ))}
    </DashboardListCard>
  )
}

export default observer(PendingContractsCard)
