import React from "react"
import { Grid } from "kui-basic"
import { observer } from "mobx-react"
import { Loading } from "kui-complex"
import useDashboardStore from "../../../store"
import { getConsultantCards, getMapCards } from "./content"
import DashboardCardsGroup from "../../../components/common/DashboardCardsGroup"

const ConsultantDashboardCards = () => {
  const { consultantDashboard } = useDashboardStore()
  const { loader } = consultantDashboard

  if (loader.isLoading) return <Loading height="80vh" />

  const cards = getConsultantCards(consultantDashboard)
  const mapCards = getMapCards(consultantDashboard)

  return (
    <Grid container spacing={4}>
      <DashboardCardsGroup cards={cards} />
      <Grid item xs={12} container spacing={4}>
        {mapCards.map(({ card, key, size }) => (
          <Grid item xs={size || 4} key={key}>
            {card}
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default observer(ConsultantDashboardCards)
