import { ResourceTypes } from "kui-crm/types"

const getResourceCells = (resource: ResourceTypes | null) => {
  switch (resource) {
    case "electricity":
      return [
        { id: "t1", label: "One-part", width: "10%", align: "center" },
        { id: "t2", label: "Two-part", width: "10%", align: "center" },
        { id: "t3", label: "Three-part", width: "10%", align: "center" },
      ]
    case "water":
      return [
        { id: "cold", label: "Cold", width: "10%", align: "center" },
        { id: "hot", label: "Hot", width: "10%", align: "center" },
        { id: "sewerage", label: "Sewerage", width: "10%", align: "center" },
      ]
    case "gas":
      return [{ id: "gas", label: "Gas", width: "30%" }]
    default:
      return [{ id: "heating", label: "Heating", width: "30%" }]
  }
}

const tariffsHeadCellsParams = (resource: ResourceTypes) => [
  { id: "id", label: "ID", width: "6%" },
  { id: "district__name", label: "District", width: "14%" },
  { id: "description", label: "Name", width: "22%" },
  { id: "status", label: "Status", width: "8%" },
  ...getResourceCells(resource),
  { id: "date_from", label: "Start date", width: "10%" },
  { id: "date_to", label: "End date", width: "10%" },
]

export default tariffsHeadCellsParams
