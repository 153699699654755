import React from "react"
import { observer } from "mobx-react"
import { LoaderState } from "kui-crm"
import { PaginationPageProps } from "./types"
import usePaginationPage from "../../../hooks/usePaginationPage"

const PaginationPage = (props: PaginationPageProps) => {
  const { store, children } = props
  const { fetchAll, paginator, filter, loader, creationLoader } = store

  usePaginationPage(paginator, fetchAll, filter)

  return (
    <>
      {children}
      <LoaderState loader={creationLoader} />
      <LoaderState loader={loader} onlyError />
    </>
  )
}

export default observer(PaginationPage)
