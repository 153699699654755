import { makeAutoObservable, runInAction } from "mobx"
import { Loader, Paginator, resHandler } from "kui-utils"
import to from "await-to-js"
import DashboardAgent from "../../../../agent/Dashboard"
import DashboardPageStore from "../DashboardPageStore"
import MeterWithDeviatingValueStore from "./MeterWithDeviatingValueStore"
import { MeterWithDeviatingValueModel } from "../../types/api/meters"

class MetersWithDeviatingValuesStore {
  value: number | null

  meters: MeterWithDeviatingValueStore[]

  paginator: Paginator

  loader: Loader

  archiveMeters: MeterWithDeviatingValueStore[]

  archivePaginator: Paginator

  archiveLoader: Loader

  dashboardStore: DashboardPageStore

  constructor(dashboardStore: DashboardPageStore) {
    this.dashboardStore = dashboardStore
    this.value = null
    this.meters = []
    this.archiveMeters = []
    this.paginator = new Paginator(5)
    this.loader = new Loader()
    this.archivePaginator = new Paginator(5)
    this.archiveLoader = new Loader()
    makeAutoObservable(this)
  }

  fetchMeters = async () => {
    this.loader.startLoading()

    const response = await to(
      DashboardAgent.getMetersWithDeviating(
        this.paginator.limit,
        this.paginator.offset,
        this.dashboardStore.periodFilter
      )
    )

    runInAction(() => {
      resHandler(response, this.loader, (res) => {
        this.value = res.count
        this.meters = this.paginator.getPageResponse(
          res,
          this.meters,
          this.getMeterParams
        )
      })
    })
  }

  fetchArchive = async () => {
    this.archiveLoader.startLoading()

    const response = await to(
      DashboardAgent.getMetersWithDeviating(
        this.archivePaginator.limit,
        this.archivePaginator.offset,
        this.dashboardStore.periodFilter,
        true
      )
    )

    runInAction(() => {
      resHandler(response, this.archiveLoader, (res) => {
        this.archiveMeters = this.archivePaginator.getPageResponse(
          res,
          this.archiveMeters,
          this.getMeterParams
        )
      })
    })
  }

  removeFromMetersList = (notifyId: number) => {
    this.meters = this.meters.filter((meter) => meter.notifyId !== notifyId)
    if (this.value) {
      this.value -= 1
      if (this.meters.length < 4) {
        this.fetchMeters()
      }
    }
  }

  removeFromArchiveList = (notifyId: number) => {
    this.archiveMeters = this.archiveMeters.filter(
      (meter) => meter.notifyId !== notifyId
    )
    this.fetchMeters()
  }

  getMeterParams = (meter: MeterWithDeviatingValueModel) =>
    new MeterWithDeviatingValueStore(meter, this)
}

export default MetersWithDeviatingValuesStore
