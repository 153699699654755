import * as React from "react"
import styled from "@emotion/styled"
import { Caption, Box, Grid, Divider } from "kui-basic"
import { TableRowExpansesProps } from "./types"
import FilesWithDot from "../../../../../../../../components/common/FilesWithDot"

function TableRowExpanses(props: TableRowExpansesProps) {
  const { line, endComponent, ...otherProps } = props
  const formatSum = `${Number(line.value).toLocaleString()} ₽`
  const invoiceParams = { label: "Invoice", link: line.invoice }
  const orderParams = { label: "Payment order", link: line.paymentOrder }

  return (
    <div {...otherProps}>
      <Box pt={1} pb={1}>
        <Grid
          container
          alignItems="center"
          justify="space-between"
          wrap="nowrap"
        >
          <Grid item>
            <Caption size="s" weight={500}>
              {line.title}
            </Caption>
            <FilesWithDot
              firstFile={invoiceParams}
              secondFile={orderParams}
              align="flex-start"
            />
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              <StyledPrice size="s" weight={600}>
                {formatSum}
              </StyledPrice>
              {endComponent}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Divider />
    </div>
  )
}

export default TableRowExpanses

const StyledPrice = styled(Caption)`
  white-space: nowrap;
`
