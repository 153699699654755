import { DateTime } from "luxon"
import { makeAutoObservable } from "mobx"
import { Loader } from "kui-utils"
import { ResourceTypes } from "kui-crm"
import {
  CompanyTariffModel,
  CompanyTariffStatuses,
  CompanyTariffTypes,
} from "../types/api/companyAPI"
import { CompanyParams } from "../types/store/companyTariff"
import CompanyTariffsStore from "./CompanyTariffsStore"

class CompanyTariffStore {
  id: number

  name: string

  type: CompanyTariffTypes

  resourceType: ResourceTypes

  status: CompanyTariffStatuses

  startDate: DateTime | null

  endDate: DateTime | null

  companies: CompanyParams[]

  loader: Loader

  actionLoader: Loader

  tariffsStore: CompanyTariffsStore

  constructor(
    tariff: CompanyTariffModel,
    resourceType: ResourceTypes,
    tariffsStore: CompanyTariffsStore
  ) {
    this.id = tariff.id
    this.name = tariff.code_name
    this.type = tariff.type
    this.status = tariff.status || "active"
    this.startDate = tariff.date_from
      ? DateTime.fromISO(tariff.date_from)
      : null
    this.endDate = tariff.date_to ? DateTime.fromISO(tariff.date_to) : null
    this.companies = []
    this.loader = new Loader()
    this.actionLoader = new Loader()
    this.resourceType = resourceType
    this.tariffsStore = tariffsStore
    makeAutoObservable(this, { tariffsStore: false })
  }
}

export default CompanyTariffStore
