/** @jsxImportSource @emotion/react */
import * as React from "react"
import { Text } from "recharts"
import { css } from "@emotion/react"
import { useWindowWidth } from "kui-utils"
import { MonthTicks } from "../../../../../../../types/store/apartmentChart"
import { shortMonths } from "../../../../../../../../../utils/content/date"
import { maxWidth } from "../../../../../../../../../utils/service/theme"

const textStyles = css`
  ${maxWidth("sm")} {
    font-size: 12px;
  }
`

type ApartmentChartXTickProps = {
  x?: number
  y?: number
  index?: number
  width?: number
  visibleTicksCount?: number
  payload?: { value: number }
  monthTicks: MonthTicks
}

function ApartmentChartXTick({
  x,
  y,
  monthTicks,
  index,
  width,
  payload,
  visibleTicksCount,
}: ApartmentChartXTickProps) {
  const windowWidth = useWindowWidth()
  const isMobile = windowWidth < 900
  const tickWidth = Number(Number(width) / Number(visibleTicksCount))
  let dx = 0
  let xAxis = x
  let label = ""

  if (monthTicks?.[index!]) {
    label = isMobile ? shortMonths[payload!.value] : monthTicks[index!].label
    const tickTitleLength = label?.length
    if (index !== 0) {
      if (monthTicks[index!].ticksCount) {
        dx =
          (Number(monthTicks[index!]?.ticksCount) * tickWidth) / 2 -
          tickWidth / 2
      } else {
        xAxis = width
        const mobileDx =
          tickTitleLength === 3 ? tickTitleLength * 19 : tickTitleLength * 13
        const desktopDx =
          tickTitleLength > 6 ? tickTitleLength * 3 : tickTitleLength * 6
        dx = isMobile ? mobileDx : desktopDx
      }
    } else {
      dx = isMobile ? tickTitleLength * 3 : tickTitleLength * 5
    }
  }

  return (
    <g transform={`translate(${xAxis},${Number(y) - 3})`}>
      {monthTicks?.[index!] && (
        <Text
          dx={dx}
          dy={20}
          fontWeight={600}
          fill="#18191F"
          textAnchor="middle"
          css={textStyles}
        >
          {label}
        </Text>
      )}
    </g>
  )
}

export default ApartmentChartXTick
