import { FormWrapper, InputByType } from "kui-crm"
import React from "react"
import { Grid } from "kui-basic"
import { useForm, useWatch } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import getMeterReadingFields from "./fields"
import { MeterReadingFormFields, ModalReadingFormProps } from "./types"
import getMeterReadingFormSchema from "./schema"
import useResetForm from "../../../../hooks/useResetForm"

const MeterReadingForm = (props: ModalReadingFormProps) => {
  const {
    meter,
    handleSubmit,
    isInsideMeter,
    meterParams,
    meterResource,
    apartmentId,
  } = props

  const schema = getMeterReadingFormSchema(isInsideMeter)
  const form = useForm<MeterReadingFormFields>({
    defaultValues: {
      apartment: apartmentId ? { id: apartmentId } : meter?.apartment,
      resource: meter?.resource || meterResource,
      meter: meter?.meter || meterParams,
      checkDate: meter?.checkDate,
      file: meter?.file,
      value: meter?.resource !== "electricity" ? meter?.values?.[0] : undefined,
      T1: meter?.values?.[0],
      T2: meter?.values?.[1],
      T3: meter?.values?.[2],
    },
    resolver: yupResolver(schema),
  })
  const [resource, apartment] = useWatch({
    control: form.control,
    name: ["resource", "apartment"],
  })
  const fields = getMeterReadingFields(resource, isInsideMeter, apartment?.id)

  useResetForm(form)

  return (
    <FormWrapper
      label={meter ? "Save" : "Create"}
      form={form}
      onSubmit={form.handleSubmit(handleSubmit)}
    >
      <Grid container spacing={2}>
        {fields.map(({ size, ...field }) => (
          <Grid item xs={size || 12} key={field.name}>
            <InputByType form={form} {...field} />
          </Grid>
        ))}
      </Grid>
    </FormWrapper>
  )
}

export default MeterReadingForm
