import React from "react"
import _ from "lodash"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { ErrorPopup } from "kui-crm"
import { CompanyTariffRowProps } from "./types"
import TableRow from "../../../../../../components/ui/TableRow"
import TableCell from "../../../../../../components/ui/TableCell"
import LinkButton from "../../../../../../components/common/LinkButton"
import { Routes } from "../../../../../../types/route"

function CompanyTariffRow({ tariff }: CompanyTariffRowProps) {
  return (
    <StyledRow>
      <TableCell isFirst>
        <StyledLinkButton
          href={`${Routes.tariffsGroups}/${tariff.resourceType}/${tariff.id}`}
        >
          {tariff.name}
        </StyledLinkButton>
      </TableCell>
      <TableCell>{_.capitalize(tariff.type)}</TableCell>
      <TableCell>{_.capitalize(tariff.status)}</TableCell>
      <TableCell>{tariff.startDate?.toFormat("dd.MM.yy")}</TableCell>
      <TableCell isLast>{tariff.endDate?.toFormat("dd.MM.yy")}</TableCell>
      {tariff.actionLoader.hasError && <ErrorPopup />}
    </StyledRow>
  )
}

export default observer(CompanyTariffRow)

const StyledRow = styled(TableRow)`
  &:nth-child(even) {
    background: ${({ theme }) => theme.palette.background.light2};
  }
`

const StyledLinkButton = styled(LinkButton)`
  width: 196px;
`
