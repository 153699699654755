export const imageReject = {
  non_field_errors: ["Failed to create image."],
}

export const postImage = {
  id: 1,
  image_name: "Image 3",
  apartment_id: 1,
  upload: new File([""], "url"),
  priority: 3,
  preview: true,
}
