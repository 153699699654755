import React from "react"
import styled from "@emotion/styled"
import {
  serviceLabels,
  serviceTableCellsWidth,
  tableHeadCells,
} from "./content"
import GridTableHeadRow from "../../../../../../../../components/common/GridTableHeadRow"
import { ServiceTableHeaderProps } from "./types"
import InfoTooltip from "../../../../../../../../components/common/InfoTooltip"

const ServiceTableHeader = ({ type, label }: ServiceTableHeaderProps) => {
  const title =
    label ||
    (type !== "own" ? (
      serviceLabels[type as keyof typeof serviceLabels]
    ) : (
      <>
        {serviceLabels[type]}{" "}
        <StyledTooltip content="These expenses are not included in the summary calculations" />
      </>
    ))

  return (
    <StyledHeadRow
      type={type}
      title={title}
      cells={tableHeadCells}
      titleWidth={serviceTableCellsWidth[0]}
    />
  )
}

export default ServiceTableHeader

const StyledHeadRow = styled(GridTableHeadRow)<ServiceTableHeaderProps>`
  background: ${({ theme, type }) =>
    type === "own"
      ? theme.palette.brand.background
      : theme.palette.background.light1};
  h4 {
    align-items: center;
    display: flex;
  }
`

const StyledTooltip = styled(InfoTooltip)`
  margin-left: 8px;
`
