import React from "react"
import { Caption, Grid } from "kui-basic"
import { Switch } from "kui-complex"
import styled from "@emotion/styled"
import { DashboardSettingRowProps } from "./types"

const DashboardSettingRow = (props: DashboardSettingRowProps) => {
  const { name, label, form } = props

  return (
    <Grid item xs={12}>
      <StyledSwitch
        form={form}
        name={name}
        label={<Caption weight={500}>{label}</Caption>}
      />
    </Grid>
  )
}

export default DashboardSettingRow

const StyledSwitch = styled(Switch)`
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
  .KUI-Label_switch {
    margin-left: 0;
    margin-right: 14px;
  }
`
