import React from "react"
import { observer } from "mobx-react"
import { Grid, Heading } from "kui-basic"
import styled from "@emotion/styled"
import { InputWithController } from "kui-complex"
import { InputByType, CompanyAutocomplete } from "kui-crm"
import { MeterOverviewInfoFieldsProps } from "./types"
import useMeterStore from "../../store"
import ApartmentAutocomplete from "../../../../components/entityInputs/ApartmentAutocomplete"
import getMeterOverviewInfoFields from "./fields"
import ReplaceableTariffGroupField from "../ReplaceableTariffGroupField"

function MeterOverviewInfoFields({ form }: MeterOverviewInfoFieldsProps) {
  const { editor, meterInfoStore } = useMeterStore()
  const isMeterClosed = meterInfoStore.status === "closed"
  const disabled = !editor.isEditing || isMeterClosed
  const { companyIsAdministrative } = meterInfoStore
  const meterFields = getMeterOverviewInfoFields(
    meterInfoStore.resource,
    isMeterClosed,
    meterInfoStore.type
  )

  return (
    <>
      <StyledHeading size="h4" weight={700}>
        Info
      </StyledHeading>
      <Grid container spacing={3}>
        {meterFields.map(({ size, ...field }) => (
          <Grid key={field.name} item xs={size || 3}>
            <InputByType form={form} disabled={disabled} {...field} />
          </Grid>
        ))}
        <Grid item xs={6}>
          <ReplaceableTariffGroupField form={form} />
        </Grid>
        <Grid item xs={6}>
          <CompanyAutocomplete form={form} disabled={disabled} />
        </Grid>
        <Grid item xs={6}>
          <InputWithController
            label="Operational account number"
            form={form}
            name="operationalAccountNumber"
            disabled={disabled || companyIsAdministrative}
          />
        </Grid>
        <Grid item xs={6}>
          <InputWithController
            label="Number"
            name="passport"
            form={form}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <ApartmentAutocomplete
            label="Object"
            form={form}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default observer(MeterOverviewInfoFields)

const StyledHeading = styled(Heading)`
  padding-bottom: 16px;
  padding-top: 4px;
`
