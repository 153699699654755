import React from "react"
import { DropIcon } from "kui-icon"
import { observer } from "mobx-react"
import { MetersVerificationsCardProps } from "./types"
import DashboardListCard from "../../common/DashboardListCard"
import MeterVerificationRow from "./MeterVerificationRow"

const MetersVerificationsCard = (props: MetersVerificationsCardProps) => {
  const { metersStore } = props
  const { meters, value, paginator, loader, fetchMetersVerifications } =
    metersStore

  return (
    <DashboardListCard
      value={value}
      label="Meters require verification"
      icon={<DropIcon />}
      paginator={paginator}
      loader={loader}
      fetchAll={fetchMetersVerifications}
    >
      {meters.map((meter) => (
        <MeterVerificationRow key={meter.notifyId} meter={meter} />
      ))}
    </DashboardListCard>
  )
}

export default observer(MetersVerificationsCard)
