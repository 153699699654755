import React from "react"
import { observer } from "mobx-react"
import { ApartmentsServicesArchiveProps } from "./types"
import ApartmentServicesRow from "../ApartmentServicesRow"
import PaginationList from "../../../common/PaginationList"

const ApartmentsServicesArchive = (props: ApartmentsServicesArchiveProps) => {
  const { apartmentsStore, isOpen } = props
  const { archiveApartments, fetchArchive, archivePaginator, archiveLoader } =
    apartmentsStore

  return (
    <PaginationList
      paginator={archivePaginator}
      loader={archiveLoader}
      fetchAll={fetchArchive}
      isOpen={isOpen}
    >
      {archiveApartments.map((apartment) => (
        <ApartmentServicesRow
          key={apartment.id}
          isArchive
          apartment={apartment}
        />
      ))}
    </PaginationList>
  )
}

export default observer(ApartmentsServicesArchive)
