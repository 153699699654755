import React from "react"
import { LoaderPopup } from "kui-crm"
import { FileLoaderProps } from "./types"

function FileLoader({ filesCount, reserveMessage }: FileLoaderProps) {
  return (
    <LoaderPopup
      label={
        filesCount
          ? `${filesCount} file${filesCount > 1 ? "s" : ""}`
          : reserveMessage
      }
    />
  )
}

export default FileLoader
