import React from "react"
import { Grid } from "kui-basic"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import ApartmentDocumentRow from "../ApartmentDocumentRow"
import useApartmentStore from "../../../../store"

const ApartmentDocumentsRow = () => {
  const { overviewStore, isArchived } = useApartmentStore()
  const { insurance, proxy } = overviewStore

  return (
    <StyledWrapper>
      <Grid container alignItems="center" spacing={3}>
        <ApartmentDocumentRow
          disabled={isArchived}
          document={insurance}
          variant="insurance"
        />
        <ApartmentDocumentRow
          disabled={isArchived}
          document={proxy}
          variant="proxy"
        />
      </Grid>
    </StyledWrapper>
  )
}

export default observer(ApartmentDocumentsRow)

const StyledWrapper = styled.div`
  margin-top: 24px;
  border-top: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
`
