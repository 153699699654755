import React from "react"
import { useToggle } from "kui-utils"
import { ApartmentFillingMenuProps } from "./types"
import MenuButton from "../../../../common/MenuButton"
import { OptionDropdownType } from "../../../../ui/DropdownMenu/types"
import FillingEditingModal from "../../../../../pages/ApartmentPage/components/tabs/ApartmentDescriptionTab/FillingEditingModal"
import { FillingParams } from "../ApartmentFillingCard/types"

function ApartmentFillingMenu<T extends FillingParams>(
  props: ApartmentFillingMenuProps<T>
) {
  const { index, filling, onEdit, onDelete, onDuplicate, disabled } = props
  const [isModalOpen, handleModalOpen, handleModalClose] = useToggle()

  const handleDuplicate = () => onDuplicate && onDuplicate(filling, index)

  const handleDelete = () => onDelete && onDelete(filling, index)

  const menuOptions: OptionDropdownType[] = [
    { label: "Duplicate subject ", handleClick: handleDuplicate },
    { label: "Edit subject", handleClick: handleModalOpen },
    { label: "Delete subject", handleClick: handleDelete },
  ]

  return (
    <>
      <MenuButton disabled={disabled} options={menuOptions} />
      <FillingEditingModal
        index={index}
        filling={filling}
        open={isModalOpen}
        handleClose={handleModalClose}
        handleSubmit={onEdit}
      />
    </>
  )
}

export default ApartmentFillingMenu
