import * as React from "react"
import { observer } from "mobx-react"
import { useRef } from "react"
import { ModalProps, LoaderState } from "kui-crm"
import InputWithAutocomplete from "../../ui/InputWithAutocomplete"
import ClientAutocompleteOption from "./ClientAutocompleteOption"
import { ClientAutocompleteProps } from "./types"
import ClientsLiteStore from "../../../store/lites/ClientsLiteStore"
import useAutocomplete from "../../../hooks/useAutocomplete"
import { ClientLiteParams } from "../../../types/store/user"
import ClientCreationModal from "../../modals/ClientCreationModal"
import { ClientCreationFields } from "../../modals/ClientCreationModal/types"

function ClientAutocomplete<TFormValues extends object>(
  props: ClientAutocompleteProps<TFormValues>
) {
  const { form, name, label, searchFilter, options, onSelect, ...otherProps } =
    props
  const clientsStore = useRef(new ClientsLiteStore(options))
  const { clients, paginator, fetchAll, loader, filter } = clientsStore.current
  const { creationForm, createClient, creationLoader } = clientsStore.current

  const { onSearchItem, onScrollEnd } = useAutocomplete(
    filter,
    paginator,
    fetchAll,
    searchFilter,
    !!options
  )

  const onSelectItem = (item: ClientLiteParams | null) => {
    form.setValue(`${name}.id`, item?.id)
    form.setValue(`${name}.name`, item?.name, { shouldValidate: true })
    form.setValue(`${name}.logo`, item?.avatar)
    if (onSelect) onSelect(item)
  }

  const onCreate = async (data: ClientCreationFields) => {
    const client = await createClient(data)
    if (client) onSelectItem(client)

    return client?.id
  }

  return (
    <>
      <InputWithAutocomplete
        renderOption={(option: ClientLiteParams) => (
          <ClientAutocompleteOption option={option} />
        )}
        options={clients}
        label={label}
        form={form}
        name={`${name}.name`}
        hideName={`${name}.id`}
        isLoading={loader.isLoading}
        onSelectItem={onSelectItem}
        onSearchItem={onSearchItem}
        handleScrollEnd={onScrollEnd}
        creatingLabel="Add new client"
        renderCreatingModal={(modalProps: ModalProps) => (
          <ClientCreationModal
            {...modalProps}
            withRoute={false}
            creationForm={creationForm}
            handleSubmit={onCreate}
          />
        )}
        {...otherProps}
      />
      <LoaderState loader={creationLoader} />
    </>
  )
}

ClientAutocomplete.defaultProps = {
  label: "Owner",
  name: "owner",
}

export default observer(ClientAutocomplete)
