import React from "react"
import { Box, Caption } from "kui-basic"
import { Tooltip } from "kui-crm"
import { observer } from "mobx-react"
import { ServiceDocumentsTooltipProps } from "./types"
import FileItem from "../../../../../../../../components/common/FileItem"
import useApartmentStore from "../../../../../../store"

const ServiceDocumentsTooltip = ({ service }: ServiceDocumentsTooltipProps) => {
  const { isArchived } = useApartmentStore()

  const handleInvoiceDelete = () => service.deleteDocument("invoice")

  const handleOrderDelete = () => service.deleteDocument("paymentOrder")

  return (
    <Tooltip
      placement="bottom"
      content={
        <div>
          <Box mb={2}>
            <FileItem
              file={service.invoice!}
              label="Invoice"
              onDelete={handleInvoiceDelete}
              disabled={isArchived}
            />
          </Box>
          <FileItem
            file={service.paymentOrder!}
            label="Payment order"
            onDelete={handleOrderDelete}
            disabled={isArchived}
          />
        </div>
      }
    >
      <Caption size="s">2 files</Caption>
    </Tooltip>
  )
}

export default observer(ServiceDocumentsTooltip)
