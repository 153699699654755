import * as yup from "yup"

export const ApartmentOverviewSchemaParams = {
  lockerNumber: yup.string().nullable(),
}

const ApartmentOverviewInfoSchema = yup
  .object()
  .shape(ApartmentOverviewSchemaParams)

export default ApartmentOverviewInfoSchema
