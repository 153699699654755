import { DetailApartmentInfoStepFields } from "kui-crm_actions"
import { InputFile } from "kui-complex"
import { InputParams } from "../../../../../../common/InputsGroupWithTitle/types"

const statisticalValuationFields: InputParams<DetailApartmentInfoStepFields>[] =
  [
    { label: "Name of data source", name: "dataSourceName", isRequired: true },
    { label: "Link of statistical valuation", name: "link", isRequired: true },
    {
      label: "Screenshot statistical valuation",
      name: "screenshot",
      variant: "custom",
      CustomInput: InputFile,
      isRequired: true,
    },
    {
      label: "Min price",
      name: "minPrice",
      variant: "mask",
      mask: Number,
      thousandsSeparator: " ",
      size: 6,
      isRequired: true,
    },
    {
      label: "Max price",
      name: "maxPrice",
      variant: "mask",
      mask: Number,
      thousandsSeparator: " ",
      size: 6,
      isRequired: true,
    },
  ]

export default statisticalValuationFields
