import React from "react"
import { Grid } from "kui-basic"
import { DashboardCardsGroupProps } from "./types"
import DashboardLinkCard from "../DashboardLinkCard"
import DashboardNumberCard from "../DasboardNumberCard"

const DashboardCardsGroup = (props: DashboardCardsGroupProps) => {
  const { cards } = props

  return (
    <>
      <Grid item xs={12} container spacing={4}>
        {cards.linkCards?.map((card) => (
          <Grid item key={card.label}>
            <DashboardLinkCard {...card} />
          </Grid>
        ))}
        {cards.numberCards?.map((card) => (
          <Grid item key={`${card.value}_${card.unit}`}>
            <DashboardNumberCard {...card} />
          </Grid>
        ))}
      </Grid>
      {cards.listCards?.map(({ card, key }) => (
        <Grid item xs={4} key={key}>
          {card}
        </Grid>
      ))}
    </>
  )
}

export default DashboardCardsGroup
