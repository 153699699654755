import React, { useState } from "react"
import { Box } from "kui-basic"
import MenuPanel from "../../../../../components/ui/MenuPanel"
import useTabIndex from "../../../../../hooks/useTabIndex"
import ApartmentDocumentsBlock from "./ApartmentDocumentsBlock"
import DocumentsTabSettings from "./DocumentsTabSettings"
import ExpensesAttachmentsTab from "./ExpensesAttachmentsTab"

const tabs = [
  "All",
  "Overview",
  "Ownership",
  "Inspections",
  "Descriptions",
  "Expenses",
]

function ApartmentDocumentsTab() {
  const tabPanels = [
    <ApartmentDocumentsBlock tab="all" />,
    <ApartmentDocumentsBlock tab="overview" />,
    <ApartmentDocumentsBlock tab="ownership" />,
    <ApartmentDocumentsBlock tab="inspections" />,
    <ApartmentDocumentsBlock tab="descriptions" />,
    <ExpensesAttachmentsTab />,
  ]
  const paths = [
    "?tab=documents&subtab=all",
    "?tab=documents&subtab=overview",
    "?tab=documents&subtab=ownership",
    "?tab=documents&subtab=inspections",
    "?tab=documents&subtab=descriptions",
    "?tab=documents&subtab=expenses",
  ]
  const defaultActiveTab = useTabIndex(paths, "subtab")
  const [activeTab, setActiveTab] = useState(+defaultActiveTab)

  return (
    <Box pt={3} pb={3}>
      <MenuPanel
        activeTab={+defaultActiveTab}
        paths={paths}
        tabs={tabs}
        tabPanels={tabPanels}
        label="Documents"
        handleChange={setActiveTab}
        endComponent={<DocumentsTabSettings activeTab={activeTab} />}
      />
    </Box>
  )
}

export default ApartmentDocumentsTab
