import React from "react"
import { Caption, Grid } from "kui-basic"
import styled from "@emotion/styled"
import { GridTableHeaderCellStylesProps, GridTableHeaderProps } from "./types"

function GridTableHeader(props: GridTableHeaderProps) {
  const { headCell, headWidth, cells, endCell, endWidth, ...other } = props

  return (
    <StyledHeader
      container
      alignItems="center"
      justify="space-between"
      {...other}
    >
      {headCell && (
        <StyledCell width={headWidth} item>
          {headCell}
        </StyledCell>
      )}
      {cells.map((cell) => (
        <StyledCell
          width={cell.width}
          key={cell.label}
          withSpacing={cell.withSpacing}
          item
        >
          <Caption size="s" weight={500} color="fiftyP">
            {cell.label}
          </Caption>
        </StyledCell>
      ))}
      {endCell && (
        <StyledCell width={endWidth} align="right" item>
          {endCell}
        </StyledCell>
      )}
    </StyledHeader>
  )
}

export default GridTableHeader

const StyledHeader = styled(Grid)`
  height: 48px;
  background: ${({ theme }) => theme.palette.background.light1};
  border-radius: 8px;
  padding: 0 24px;
  max-width: 1344px;
  margin: 0 auto;
`

const StyledCell = styled(Grid)<GridTableHeaderCellStylesProps>`
  width: ${({ width }) => width || "auto"};
  text-align: ${({ align }) => align || "auto"};
  padding-left: ${({ withSpacing }) => (withSpacing ? 12 : 0)}px;
`
