import { rest } from "msw"
import { MetroStationParams } from "../../types/api/metro"
import { getListMetro } from "./data"

const metroHandlers = [
  rest.get<MetroStationParams[] | Error>(
    `${process.env.REACT_APP_API_URL}/list/metro`,
    (req, resp, ctx) => {
      const data = ctx.json(getListMetro)
      return resp(data)
    }
  ),
]

export default metroHandlers
