import * as React from "react"

type ApartmentChartYTickProps = {
  x?: number
  y?: number
  payload?: { value: string }
}

function ApartmentChartYTick({ x, y, payload }: ApartmentChartYTickProps) {
  let formattedPrice = String(payload?.value)
  if (formattedPrice.length >= 4 && formattedPrice.length < 7) {
    formattedPrice = `${formattedPrice.slice(
      0,
      formattedPrice.length - 3
    )} т.р.`
  } else if (formattedPrice.length > 7) {
    formattedPrice = `${formattedPrice.slice(
      0,
      formattedPrice.length - 6
    )} м.р.`
  }

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dx={-12}
        dy={5}
        fontWeight={600}
        textAnchor="end"
        fill="#18191F"
      >
        {formattedPrice}
      </text>
    </g>
  )
}

export default ApartmentChartYTick
