import { DetailApartmentInfoStepFields } from "kui-crm_actions"
import { InputParams } from "../../../../../../common/InputsGroupWithTitle/types"

const managerValuationFields: InputParams<DetailApartmentInfoStepFields>[] = [
  {
    label: "Min price",
    name: "minPrice",
    variant: "mask",
    mask: Number,
    thousandsSeparator: " ",
    size: 6,
    isRequired: true,
  },
  {
    label: "Max price",
    name: "maxPrice",
    variant: "mask",
    mask: Number,
    thousandsSeparator: " ",
    size: 6,
    isRequired: true,
  },
  {
    label: "Advertising period",
    name: "advertisingPeriod",
    type: "number",
    cornerLabel: "weeks",
  },
  { label: "Recommendations", name: "recommendations", isTextArea: true },
  { label: "Owner's wishes", name: "ownerWishes", isTextArea: true },
]

export default managerValuationFields
