import React from "react"
import styled from "@emotion/styled"
import { ServicesTableProps } from "./types"
import ServiceRow from "../ServiceRow"
import GridTableBody from "../../../../../../../../components/common/GridTableBody"
import ServiceTableHeader from "../ServiceTableHeader"

function ServicesTable({ services, type, label }: ServicesTableProps) {
  return (
    <StyledWrapper>
      <ServiceTableHeader type={type} label={label} />
      <GridTableBody withPadding data-testid={type}>
        {services?.map((service, key) => (
          <ServiceRow
            isPending={type === "pending"}
            key={service.id}
            service={service}
          />
        ))}
      </GridTableBody>
    </StyledWrapper>
  )
}

export default ServicesTable

const StyledWrapper = styled.div`
  margin-bottom: 10px;
`
