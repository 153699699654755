import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { Paginator, SortingFilter, Loader, MultistepForm } from "kui-utils"
import {
  GasMeterModel,
  GetGasMetersResponse,
  PostCommonMeterParams,
  PostGasMeterRequest,
} from "../../../types/api/meters"
import MeterStore from "./MeterStore"
import {
  GasMeterCreationFields,
  MeterCreationFormFields,
} from "../../../components/forms/meters/MeterCreationFormFields/types"
import MetersAgent from "../../../agent/Meters"
import { getCommonMeterParams } from "../../../utils/service/apiMapper"

class GasMetersStore {
  meters: MeterStore[]

  paginator: Paginator

  filter: SortingFilter

  loader: Loader

  creationLoader: Loader

  creationForm: MultistepForm<MeterCreationFormFields>

  constructor() {
    this.meters = []
    this.loader = new Loader()
    this.creationLoader = new Loader()
    this.paginator = new Paginator()
    this.filter = new SortingFilter("id")
    this.creationForm = new MultistepForm<MeterCreationFormFields>()
    makeAutoObservable(this)
  }

  fetchAll = async () => {
    this.loader.startLoading()

    const [err, res] = await to<GetGasMetersResponse>(
      MetersAgent.all(
        "gas",
        this.paginator.offset,
        this.paginator.limit,
        this.filter.filterParams
      )
    )
    runInAction(() => {
      if (!err && res) {
        const mapper = (meter: GasMeterModel) => new MeterStore(meter, "gas")

        this.meters = this.paginator.getPageResponse<GasMeterModel, MeterStore>(
          res,
          this.meters,
          mapper
        )
      } else {
        this.loader.setError("fetch gas meters")
      }
      this.loader.endLoading()
    })
  }

  createMeter = async (data: GasMeterCreationFields) => {
    this.creationLoader.startLoading()

    const body: PostGasMeterRequest = GasMetersStore.getCreationBody(data)

    const [err, res] = await to(MetersAgent.createMeter(body, "gas"))
    runInAction(() => {
      if (!err && res) {
        this.meters = [...this.meters, new MeterStore(res, "gas")]
      } else {
        this.creationLoader.setError("meter creation")
      }
      this.creationLoader.endLoading()
    })
  }

  static getCreationBody = (data: GasMeterCreationFields) => ({
    apartment: data.apartment.id,
    initial_value: data.initialValue,
    ...(getCommonMeterParams(data) as Omit<PostCommonMeterParams, "apartment">),
    ...(data.replaceableCounter && {
      replaceable_counter: {
        counter: Number(data.replaceableCounter),
        last_value: data.lastValue!,
      },
    }),
  })
}

export default GasMetersStore
