import React from "react"
import styled from "@emotion/styled"
import { Modal } from "kui-crm"
import { ContractClosingModalProps } from "./types"
import ContractClosingForm from "../../forms/contacts/ContractClosingForm"
import { ContractClosingFormFields } from "../../forms/contacts/ContractClosingForm/types"

const ContractClosingModal = (props: ContractClosingModalProps) => {
  const { closeContract, handleClose, open } = props

  const handleSubmit = (data: ContractClosingFormFields) => {
    handleClose()
    closeContract(data)
  }

  return (
    <StyledModal title="Close contract" open={open} handleClose={handleClose}>
      <ContractClosingForm handleSubmit={handleSubmit} />
    </StyledModal>
  )
}

export default ContractClosingModal

const StyledModal = styled(Modal)`
  h2 {
    padding-bottom: 12px;
  }
`
