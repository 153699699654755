import { useEffect, useRef, useState } from "react"
import { UseFormReturn } from "react-hook-form"
import { GeolocationParams } from "kui-crm/types"

const useAddressAutocomplete = (
  form: UseFormReturn<any>,
  name: string,
  onlyAddress?: boolean,
  handlePlaceSelectProp?: (
    coords: GeolocationParams | null,
    place: google.maps.places.PlaceResult
  ) => void
) => {
  const [place, setPlace] = useState<google.maps.places.PlaceResult | null>(
    null
  )
  const inputRef = useRef<HTMLInputElement>(null)

  async function handlePlaceSelect(newPlace: google.maps.places.PlaceResult) {
    if (newPlace) {
      const toCoords = newPlace.geometry?.location
        ? {
            lat: newPlace.geometry.location.lat(),
            lng: newPlace.geometry.location.lng(),
          }
        : null
      const lat = toCoords?.lat
      const lng = toCoords?.lng

      if (handlePlaceSelectProp) {
        handlePlaceSelectProp(lat && lng ? { lat, lng } : null, newPlace)
      }

      if (!onlyAddress) {
        form?.setValue(`${name}.location.lat`, lat, { shouldValidate: true })
        form?.setValue(`${name}.location.lon`, lng, { shouldValidate: true })
        form.setValue(`${name}.addressAutoComplete`, newPlace, {
          shouldValidate: true,
        })
      }

      const zipCode = newPlace.address_components?.find((elem) =>
        elem.types.includes("postal_code")
      )?.short_name

      const formattedAddress = newPlace.name

      if (onlyAddress) {
        form?.setValue(name, formattedAddress, { shouldValidate: true })
      } else {
        form?.setValue(`${name}.address`, formattedAddress, {
          shouldValidate: true,
        })
        form.setValue("zipCode", zipCode)
      }
      setPlace(newPlace)
    }
  }

  const addAutocomplete = () => {
    if (inputRef.current) {
      const autoComplete = new window.google.maps.places.Autocomplete(
        inputRef.current
      )
      autoComplete.addListener("place_changed", () => {
        const newPlace = autoComplete.getPlace()
        handlePlaceSelect(newPlace)
      })
    }
  }

  const cleanLocation = () => {
    setPlace(null)
    if (onlyAddress) {
      form?.setValue(name, "", { shouldValidate: true })
    } else {
      form?.setValue(`${name}.location.lat`, null, { shouldValidate: true })
      form?.setValue(`${name}.location.lon`, null, { shouldValidate: true })
      form?.setValue(`${name}.addressAutoComplete`, null, {
        shouldValidate: true,
      })
      form?.setValue(`${name}.address`, "", { shouldValidate: true })
    }
  }

  const checkMapLoaded = () => {
    if (!window.google || !inputRef.current) {
      setTimeout(checkMapLoaded, 1)
    } else addAutocomplete()
  }

  const setDefaultPlace = () => {
    const address = onlyAddress
      ? form.getValues(name)
      : form.getValues(`${name}.address`)
    const lat = form.getValues(`${name}.location.lat`)
    const lng = form.getValues(`${name}.location.lon`)
    const defaultPlace = {
      formatted_address: address,
      geometry:
        lat && lng
          ? {
              location: { lat, lng } as google.maps.LatLng,
            }
          : undefined,
    }
    if (address && lat && lng) setPlace(defaultPlace)
  }

  useEffect(() => {
    checkMapLoaded()
    setDefaultPlace()
  }, [])

  return { inputRef, handlePlaceSelect, place, cleanLocation }
}

export default useAddressAutocomplete
