import React from "react"
import { AccordionDetails } from "kui-basic"
import { Table } from "kui-complex"
import styled from "@emotion/styled"
import ChangeLineTableHeader from "../ChangeLineTableHeader"
import { ChangeLineDetailsProps } from "./types"
import ChangeLineTableRow from "../ChangeLineTableRow"

const ChangeLineDetails = ({ changesGroup }: ChangeLineDetailsProps) => (
  <StyledDetails>
    <Table>
      <ChangeLineTableHeader />
      {changesGroup.changes.map((change) => (
        <ChangeLineTableRow key={change.fieldName} change={change} />
      ))}
    </Table>
  </StyledDetails>
)

export default ChangeLineDetails

const StyledDetails = styled(AccordionDetails)`
  background: white;
  border-radius: 12px;
  padding: 8px;
`
