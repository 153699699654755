import React from "react"
import { observer } from "mobx-react"
import SortingTable from "../../../../../components/common/SortingTable"
import proxyHeadCells from "./content"
import useRegistriesStore from "../../../store"
import ApartmentDocumentsRegistryStore from "../../../store/variants/ApartmentDocumentsRegistryStore"
import ProxyRegistryRow from "./ProxyRegistryRow"
import PaginationPage from "../../../../../components/common/PaginationPage"

const ProxyRegistryTable = () => {
  const { activeRegistryStore } = useRegistriesStore()

  if (!activeRegistryStore) return null

  const proxies = (activeRegistryStore as ApartmentDocumentsRegistryStore).list

  return (
    <PaginationPage store={activeRegistryStore}>
      <SortingTable
        headParams={proxyHeadCells}
        store={activeRegistryStore}
        offset={188}
      >
        {proxies?.map((insurance, key) => (
          <ProxyRegistryRow
            key={insurance.id}
            insurance={insurance}
            isDark={key % 2 !== 0}
          />
        ))}
      </SortingTable>
    </PaginationPage>
  )
}

export default observer(ProxyRegistryTable)
