import React from "react"
import { observer } from "mobx-react"
import { MeterFeaturesForm } from "kui-crm"
import { FormProps } from "../../../../../../../types/ui/form"
import useExpensesStore from "../../../store"

function EditElectricityMeterForm({ handleSubmit }: FormProps) {
  const { metersStore, overviewStore } = useExpensesStore()
  const electricityMeter =
    metersStore.electricityMetersStore.selectedElectricityMeter

  const defaultValues = {
    type: electricityMeter?.type,
    tariff: electricityMeter?.tariff,
    company: electricityMeter?.company,
    number: electricityMeter?.number,
    startDate: electricityMeter?.startDate,
    nextCheck: electricityMeter?.nextCheck,
    initialValueT1: Number(electricityMeter?.t1?.initialValue),
    initialValueT2: Number(electricityMeter?.t2?.initialValue),
    initialValueT3: Number(electricityMeter?.t3?.initialValue),
    operationalAccountNumber: electricityMeter?.operationalAccountNumber,
    noTariff: electricityMeter?.noTariff,
    withAutoSending: electricityMeter?.withAutoSending,
  }

  return (
    <MeterFeaturesForm
      resource="electricity"
      handleSubmit={handleSubmit}
      operatingAccount={electricityMeter?.operationalAccountNumber}
      administrativeCompany={overviewStore.managementCompany}
      apartmentId={overviewStore.id}
      defaultValues={defaultValues}
      formBottomProps={{
        label: "Save",
      }}
    />
  )
}

export default observer(EditElectricityMeterForm)
