import { createContext, useContext } from "react"
import PromotionPageStore from "./PromotionPageStore"
import { apartmentStore } from "../../../store"
import hostStore from "../../../../../store"

const promotionStore = new PromotionPageStore(hostStore, apartmentStore)
const PromotionContext = createContext(promotionStore)

const usePromotionStore = () => useContext(PromotionContext)

export default usePromotionStore
export { promotionStore, PromotionContext }
