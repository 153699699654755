import { makeAutoObservable } from "mobx"
import { DateTime } from "luxon"
import { MeterTypes, ResourceTypes } from "kui-crm"
import { MeterModel } from "../../../types/api/meters"
import ApartmentSimpleStore from "../../../store/templates/Apartment"
import { TariffLinkParams } from "../../../types/common"

class MeterStore {
  id: number

  startDate: DateTime | null

  nextCheck: DateTime | null

  number: string

  status: string

  resource: ResourceTypes

  type?: MeterTypes

  apartment: ApartmentSimpleStore

  tariffGroup: TariffLinkParams | null

  constructor(meter: MeterModel, resource: ResourceTypes) {
    this.id = meter.id
    this.startDate = meter.activation_date
      ? DateTime.fromISO(meter.activation_date)
      : null
    this.nextCheck = meter.check_date
      ? DateTime.fromISO(meter.check_date)
      : null
    this.number = meter.passport_number
    this.status = "open"
    this.resource = resource
    this.type = meter.type || null
    this.apartment = new ApartmentSimpleStore(meter.apartment)
    this.tariffGroup = meter.tariff
      ? {
          id: meter.tariff?.id,
          name: meter.tariff?.code_name,
        }
      : null
    makeAutoObservable(this)
  }
}

export default MeterStore
