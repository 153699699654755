import React from "react"
import { observer } from "mobx-react"
import SortingTable from "../../../../components/common/SortingTable"
import useClientsStore from "../../store"
import clientsHeadCellsParams from "./headParams"
import ClientRow from "../ClientRow"

function ClientsTable() {
  const clientsStore = useClientsStore()

  return (
    <SortingTable
      withOverlaps
      headParams={clientsHeadCellsParams}
      store={clientsStore}
    >
      {clientsStore.clients.map((client, key) => (
        <ClientRow key={client.id} client={client} isDark={key % 2 !== 0} />
      ))}
    </SortingTable>
  )
}

export default observer(ClientsTable)
