import React from "react"
import { observer } from "mobx-react"
import SortingTable from "../../../../components/common/SortingTable"
import tariffObjectsHeadCellsParams from "./headParams"
import useTariffStore from "../../store"
import TariffObjectRow from "../TariffObjectRow"

function TariffObjectsTable() {
  const { objectsStore } = useTariffStore()

  return (
    <SortingTable
      headParams={tariffObjectsHeadCellsParams}
      store={objectsStore}
      headVariant="grey"
      withFullLoading={false}
    >
      {objectsStore.objects.map((object, key) => (
        <TariffObjectRow
          key={object.id}
          object={object}
          isDark={key % 2 !== 0}
        />
      ))}
    </SortingTable>
  )
}

export default observer(TariffObjectsTable)
