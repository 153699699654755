import React from "react"
import { Grid, Heading } from "kui-basic"
import styled from "@emotion/styled"
import { ImpairmentsGroupProps } from "./types"
import ImpairmentInfoFields from "../ImpairmentInfoFields"

const ImpairmentsGroup = (props: ImpairmentsGroupProps) => {
  const { title, impairments, ...others } = props

  return (
    <Grid item>
      <StyledTitle size="h3">{title}</StyledTitle>
      {impairments.map((field, index) => (
        <ImpairmentInfoFields key={field.id} index={index} {...others} />
      ))}
    </Grid>
  )
}

export default ImpairmentsGroup

const StyledTitle = styled(Heading)`
  margin-bottom: 8px;
`
