import React from "react"
import styled from "@emotion/styled"
import { Heading, Button } from "kui-basic"
import { PlusIcon } from "kui-icon"
import {
  CellStylesProps,
  TableHeadCellParams,
  TableHeadRowProps,
} from "./types"
import TableCell from "../TableCell"
import TableRow from "../TableRow"

function TableHeadRow(props: TableHeadRowProps) {
  const { cells, title, titleWidth, withButton, ...buttonProps } = props
  return (
    <thead data-testid="table_head_row">
      <StyledRow isDark>
        <TableCell data-testid="table_label" width={titleWidth} isFirst>
          <Heading size="h4">{title}</Heading>
        </TableCell>
        {cells.map(
          (
            { label, withPadding, ...cellProps }: TableHeadCellParams,
            key: number
          ) => (
            <StyledCell
              key={label}
              textColor="gray"
              withPadding={!!withPadding}
              isLast={!withButton && key === cells.length - 1}
              {...cellProps}
            >
              {label}
            </StyledCell>
          )
        )}
        {withButton && (
          <StyledMenuCell width="4%" isLast align="right">
            <StyledButton
              isCircle
              size="xs"
              variant="whiteWithGray"
              data-testid="add_table_row_btn"
              {...buttonProps}
            >
              <PlusIcon width={10} height={10} />
            </StyledButton>
          </StyledMenuCell>
        )}
      </StyledRow>
    </thead>
  )
}

export default TableHeadRow

TableHeadRow.defaultProps = {
  withButton: true,
}

const StyledRow = styled(TableRow)`
  min-height: 48px;
  &.MuiTableRow-root {
    background-color: ${({ theme }) => theme.palette.background.light1};
  }
  td {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  td:last-child {
    padding-right: 15px;
  }
  td:first-child {
    padding-left: 27px;
  }
`

const StyledCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "withPadding",
})<CellStylesProps>`
  ${({ withPadding }) => withPadding && "padding-left: 34px"};
`

const StyledMenuCell = styled(TableCell)`
  max-width: unset;
`

const StyledButton = styled(Button)`
  border-color: transparent;
`
