import { LinkField } from "kui-crm"
import { InputParams } from "../../../../../../../components/common/InputsGroupWithTitle/types"
import { ImpairmentInfoFormFields } from "./types"
import { ImpairmentsTypeValues } from "../../../../../../../utils/content/listsOptions"
import ImpairmentDeleteButton from "../ImpairmentDeleteButton"
import { ImpairmentsGroupType } from "../ImpairmentsGroup/types"

const getImpairmentInfoFields = (
  type: ImpairmentsGroupType,
  index: number,
  disabled: boolean,
  handleDelete: () => void
): InputParams<ImpairmentInfoFormFields>[] => [
  {
    label: "Description",
    name: `${type}.${index}.description`,
    isTextArea: true,
    isRequired: true,
  },
  {
    label: "Solution",
    name: `${type}.${index}.solution`,
    isTextArea: true,
    isRequired: true,
  },
  {
    name: "",
    variant: "custom",
    CustomInput: ImpairmentDeleteButton,
    disabled,
    onClick: handleDelete,
  },
  {
    label: "Type",
    name: `${type}.${index}.type`,
    variant: "select",
    options: ImpairmentsTypeValues,
    size: 2,
    isRequired: true,
  },
  {
    label: "Price",
    name: `${type}.${index}.price`,
    variant: "mask",
    mask: Number,
    thousandsSeparator: " ",
    cornerLabel: "₽",
    size: 2,
    isRequired: true,
  },
  {
    label: "Link",
    name: `${type}.${index}.link`,
    variant: "custom",
    CustomInput: LinkField,
  },
  {
    label: "Costings file",
    name: `${type}.${index}.file`,
    variant: "file",
  },
]

export default getImpairmentInfoFields
