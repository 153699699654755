import styled from "@emotion/styled"

const Table = styled.table`
  width: 100%;
  max-width: 1344px;
  margin: 0 auto;
  border-collapse: collapse;
  border-spacing: 0px;
`

export default Table
