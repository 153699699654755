import * as yup from "yup"

const SCFixedCostsFormSchema = yup.object().shape({
  fixedCosts: yup.array().of(
    yup.object({
      name: yup.string().required("This field is required"),
      cost: yup.string().required("This field is required"),
      payer: yup.string().required("This field is required"),
    })
  ),
})

export default SCFixedCostsFormSchema
