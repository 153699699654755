import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { observer } from "mobx-react"
import { yupResolver } from "@hookform/resolvers/yup"
import InspectionTitle from "../../components/InspectionTitle"
import ApartmentDescriptionFields from "../../../../../../components/forms/apartment/ApartmentDescriptionFields"
import useInspectionStore from "../../store"
import { TransferInspectionInfoFields } from "./types"
import TransferInspectionStore from "../../store/variations/TransferInspectionStore"
import InspectionRoomsPhoto from "../../components/InspectionRoomsPhoto"
import TransferInspectionInfoSchema from "./schema"
import { transferInspectionTypeLabels } from "./content"
import ApartmentPartialMetersFields from "../../components/ApartmentPartialMetersFields"
import InspectionNotEditableFillingList from "../../components/InspectionNotEditableFillingList"

const TransferInspectionInfo = () => {
  const { editor, inspectionInfo, patchInspection } = useInspectionStore()
  const inspection = inspectionInfo as TransferInspectionStore
  const form = useForm<TransferInspectionInfoFields>({
    defaultValues: {
      ...inspection?.descriptionFields,
      ...inspection?.metersInfo,
      apartment: {
        address: inspection?.descriptionFields?.address,
      },
      roomsImages: inspection?.roomsImages,
      fillingList: inspection?.fillingList,
      dropboxLink: inspection?.dropboxLink,
    },
    resolver: yupResolver(TransferInspectionInfoSchema),
  })
  const disabled = !editor.isEditing

  useEffect(() => {
    if (editor.isAllowedToSendForm) {
      form.handleSubmit(patchInspection, editor.blockToSendForm)()
    }
  }, [editor.isAllowedToSendForm])

  if (!inspection) return null

  return (
    <>
      <InspectionTitle
        form={form}
        label={transferInspectionTypeLabels[inspection.type]}
      />
      <ApartmentDescriptionFields form={form as any} disabled={disabled} />
      <ApartmentPartialMetersFields form={form as any} />
      <InspectionNotEditableFillingList form={form as any} />
      <InspectionRoomsPhoto form={form} />
    </>
  )
}

export default observer(TransferInspectionInfo)
