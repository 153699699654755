import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { Paginator, SortingFilter, Loader } from "kui-utils"
import { HeatingTariffCreationFields, TariffsLiteStore } from "kui-crm"
import {
  GetHeatingTariffsResponse,
  HeatingTariffModel,
} from "../types/api/tariffsAPI"
import TariffsAgent from "../../../agent/Tariffs"
import TariffStore from "./TariffStore"

class HeatingTariffsStore {
  tariffs: TariffStore[]

  paginator: Paginator

  filter: SortingFilter

  loader: Loader

  creationLoader: Loader

  constructor() {
    this.tariffs = []
    this.loader = new Loader()
    this.creationLoader = new Loader()
    this.paginator = new Paginator()
    this.filter = new SortingFilter("id")
    makeAutoObservable(this)
  }

  fetchAll = async () => {
    this.loader.startLoading()

    const [err, res] = await to<GetHeatingTariffsResponse>(
      TariffsAgent.all(
        "heating",
        this.paginator.offset,
        this.paginator.limit,
        this.filter.filterParams
      )
    )
    runInAction(() => {
      if (!err && res) {
        const mapper = (tariff: HeatingTariffModel) =>
          new TariffStore(tariff, "heating")

        this.tariffs = this.paginator.getPageResponse<
          HeatingTariffModel,
          TariffStore
        >(res, this.tariffs, mapper)
      } else {
        this.loader.setError("fetch heating tariffs")
      }
      this.loader.endLoading()
    })
  }

  createTariff = async (data: HeatingTariffCreationFields) => {
    this.creationLoader.startLoading()

    const body = TariffsLiteStore.getCreationBody(data)

    const [err, res] = await to(TariffsAgent.createTariff(body, "heating"))
    runInAction(() => {
      if (!err && res) {
        this.tariffs = [...this.tariffs, new TariffStore(res, "heating")]
      } else {
        this.creationLoader.setError("heating tariff creation")
      }
      this.creationLoader.endLoading()
    })
  }
}

export default HeatingTariffsStore
