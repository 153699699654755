import React from "react"
import _ from "lodash"
import { DynamicRoutes } from "../../../../../../types/route"
import RowWithDate from "../../../common/RowWithDate"
import { MeterVerificationRowProps } from "./types"

const MeterVerificationRow = (props: MeterVerificationRowProps) => {
  const { meter } = props
  const { id, apartment, resource, type, date } = meter
  const meterLink = DynamicRoutes.meter(resource, id)
  const label = _.capitalize(`${type ? `${type} ` : ""}${resource}`)

  return (
    <RowWithDate
      link={meterLink}
      date={date}
      label={label}
      apartment={apartment}
    />
  )
}

export default MeterVerificationRow
