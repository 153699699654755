import * as React from "react"
import { observer } from "mobx-react"
import { Modal, ModalProps, MeterFeaturesFormFields } from "kui-crm"
import useExpensesStore from "../../../../store"
import EditSingleMeterForm from "../../../../forms/meters/EditSingleMeterForm"

function EditHeatingMeterModal({ open, handleClose }: ModalProps) {
  const { metersStore } = useExpensesStore()
  const heatingMeter = metersStore.heatingMetersStore.selectedHeatingMeter

  const handleSubmit = async (data: MeterFeaturesFormFields) => {
    if (heatingMeter?.id) {
      handleClose()
      await heatingMeter.patchCounter(data)
    }
  }

  if (!heatingMeter) {
    return null
  }

  return (
    <Modal title="Edit heating meter" open={open} handleClose={handleClose}>
      <EditSingleMeterForm
        resource="heating"
        meter={heatingMeter}
        handleSubmit={handleSubmit}
      />
    </Modal>
  )
}

export default observer(EditHeatingMeterModal)
