import * as React from "react"
import { EmailIcon } from "kui-icon"

export const clientPersonalStepFields = [
  { label: "Surname", name: "lastName", testId: "last_name" },
  { label: "Name", name: "firstName", testId: "first_name", isRequired: true },
  { label: "Middle name", name: "middleName", testId: "middle_name" },
  { name: "phone", testId: "phone", variant: "phone", isRequired: true },
  { label: "Email", name: "email", testId: "email", endIcon: <EmailIcon /> },
  { label: "Note", name: "note", testId: "note", isTextArea: true },
]

export const clientCompanyStepFields = [
  { label: "Legal entity name", name: "companyName", isRequired: true },
  { label: "Representative surname", name: "lastName" },
  { label: "Representative name", name: "firstName", isRequired: true },
  { label: "Representative middle name", name: "middleName" },
  { label: "Representative job title", name: "jobTitle" },
  {
    label: "Representative phone",
    name: "phone",
    variant: "phone",
    isRequired: true,
  },
  { label: "Representative email", name: "email", endIcon: <EmailIcon /> },
  { label: "Note", name: "note", isTextArea: true },
]
