import React from "react"
import { Grid, Button } from "kui-basic"
import styled from "@emotion/styled"
import {
  InputWithMask,
  RadioGroupWithLabel,
  InputWithController,
} from "kui-complex"
import { CloseIcon } from "kui-icon"
import { FixedCostItemProps } from "./types"
import { PayerValues } from "../../../../utils/content/listsOptions"

function FixedCostItem(props: FixedCostItemProps) {
  const { index, onDelete, form } = props

  const handleDelete = () => onDelete(index)

  return (
    <>
      <Grid
        container
        item
        xs={12}
        wrap="nowrap"
        alignItems="center"
        justify="space-between"
      >
        <StyledInputWrapper>
          <InputWithController
            label="Name"
            name={`fixedCosts.${index}.name`}
            form={form}
          />
        </StyledInputWrapper>
        <Button isCircle size="xs" variant="grayLight" onClick={handleDelete}>
          <CloseIcon width={8} height={8} />
        </Button>
      </Grid>
      <Grid item xs={12}>
        <InputWithMask
          label="Cost"
          name={`fixedCosts.${index}.cost`}
          form={form}
          cornerLabel="P"
          mask={Number}
          thousandsSeparator=" "
        />
      </Grid>
      <Grid item xs={12}>
        <RadioGroupWithLabel
          testId="payer"
          title="Payer"
          form={form}
          options={PayerValues}
          name={`fixedCosts.${index}.payer`}
        />
      </Grid>
    </>
  )
}

export default FixedCostItem

const StyledInputWrapper = styled.div`
  width: 100%;
  max-width: calc(100% - 16px - 32px);
`
