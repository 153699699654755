import React, { useEffect, useRef, useState } from "react"
import Swiper, { SwiperProps, SwiperRef, SwiperContainer } from "kui-swiper"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import { Button } from "kui-basic"
import { HalfArrowNextIcon, HalfPrevArrowIcon } from "kui-icon"
import { ButtonStylesProps, FullSliderProps } from "./types"
import FullSliderHeader from "./FullSliderHeader"
import SliderThumbnails from "../SliderThumbnails"

function FullSlider(props: FullSliderProps) {
  const { handleClose, active, images, renderHeaderComponent, onChange } = props
  const [currentSlide, setCurrentSlide] = useState(active)
  const swiperRef = useRef<SwiperRef | null>(null)

  const sliderParams = {
    initialSlide: active,
    centeredSlides: true,
    grabCursor: true,
    loop: true,
    lazy: true,
  }

  const next = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper!.slideNext()
    }
  }

  const prev = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper!.slidePrev()
    }
  }

  const updateSlide = (swiper: SwiperContainer) => {
    if (swiper) {
      setCurrentSlide(swiper.realIndex)
      if (onChange) {
        onChange(swiper?.realIndex)
      }
    }
  }

  const handleChange = (index: number) => {
    swiperRef.current?.swiper?.slideTo(index + 1)
  }

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.keyCode === 37) prev()
    if (e.keyCode === 39) next()
  }

  useEffect(() => {
    const swiperInstance = swiperRef.current?.swiper
    document.addEventListener("keydown", handleKeyPress)

    if (swiperInstance) {
      swiperInstance.on("slideChange", updateSlide)
    }
    return () => {
      swiperInstance?.off("slideChange", updateSlide)
      document.removeEventListener("keydown", handleKeyPress)
    }
  }, [])

  return (
    <div>
      <FullSliderHeader
        currentSlide={currentSlide}
        handleClose={handleClose}
        slidesCount={images.length}
        slider={swiperRef}
        renderEndComponent={renderHeaderComponent}
      />
      <StyledSwiper {...sliderParams} ref={swiperRef}>
        {images.map((image) => (
          <StyledImageWrapper key={image}>
            <StyledImage src={image} alt="" />
          </StyledImageWrapper>
        ))}
      </StyledSwiper>
      <SliderThumbnails
        images={images}
        activeSlide={currentSlide}
        handleChange={handleChange}
      />
      <StyledButton
        isCircle
        navType="prev"
        onClick={prev}
        size="s"
        variant="blur"
      >
        <HalfPrevArrowIcon />
      </StyledButton>
      <StyledButton
        isCircle
        navType="next"
        onClick={next}
        size="s"
        variant="blur"
      >
        <HalfArrowNextIcon />
      </StyledButton>
    </div>
  )
}

export default observer(FullSlider)

const StyledImageWrapper = styled.div`
  width: 100%;
  padding: 0px;
  margin: 0px;
  text-align: center;
`

const StyledButton = styled(Button)<ButtonStylesProps>`
  position: absolute;
  top: 50%;
  z-index: 10;
  ${({ navType }) => (navType === "prev" ? "left: 24px" : "right: 24px")};
  svg {
    margin-left: ${({ navType }) => (navType === "prev" ? 0 : "2px")};
  }
`

const StyledSwiper = styled(Swiper)<SwiperProps>`
  width: 100%;
  height: calc(100vh - 112px);
  overflow: hidden;
  background: rgba(25, 25, 25, 0.9);
  border-radius: 0 0 12px 12px;
`

const StyledImage = styled.img`
  height: 100vh;
`
