import { makeAutoObservable } from "mobx"
import { ChangeReasonParams } from "../../../types/common"
import { TariffObjectModel } from "../types/api/tariffAPI"

class TariffObjectStore {
  id: number

  logo: string | null

  address: string

  folderNumber: string

  reason: ChangeReasonParams | null

  constructor(object: TariffObjectModel) {
    this.id = object.id
    this.logo = object.main_image
      ? object.main_image?.image_small_url ?? object.main_image.image_url
      : null
    this.address = object.object_name || object.address
    this.folderNumber = object.folder_number
    this.reason = null
    makeAutoObservable(this)
  }
}

export default TariffObjectStore
