import to from "await-to-js"
import { resHandler, Loader } from "kui-utils"
import { getBodyForPostFile } from "../../../../utils/agent/uploadFiles"
import UploadAgent from "../../../../agent/Upload"
import { ParsedServiceModel } from "../../../../types/api/apartmentExpenses"

export const parseFile = async (
  apartmentId: number,
  loader: Loader,
  file: File,
  callback: (data: ParsedServiceModel) => void
) => {
  loader.startLoading()

  const body = getBodyForPostFile(file)
  body.append("apartment", String(apartmentId))

  const response = await to(UploadAgent.uploadExpense(body))

  resHandler(response, loader, callback)

  loader.endLoading()
}
