import { makeAutoObservable } from "mobx"
import { getPhoneParamsFromString } from "kui-utils"
import NotesStore from "../../../store/notes/Notes"
import { ClientModel, ClientTypes } from "../../../types/api/clients"
import { PhoneParams } from "../../../types/common"
import RentalContractLiteStore from "../../../store/templates/RentalContractLite"
import ServiceContractLiteStore from "../../../store/templates/ServiceContractLite"

class ClientOverviewStore {
  id: number

  type: ClientTypes

  email: string

  phoneNumber: PhoneParams | null

  firstName: string

  lastName: string

  middleName: string

  avatar?: string

  notesStore: NotesStore

  rentalContracts: RentalContractLiteStore[]

  serviceContracts: ServiceContractLiteStore[]

  constructor(client: ClientModel) {
    this.id = client.id
    this.type = client.client_type
    this.email = client.email
    this.phoneNumber = client.phone
      ? getPhoneParamsFromString(client.phone)
      : null
    this.firstName = client.first_name
    this.lastName = client.last_name
    this.middleName = client.middle_name
    this.notesStore = new NotesStore("users", client.id)
    this.rentalContracts = client.rental_contracts?.map(
      (contract) => new RentalContractLiteStore(contract)
    )
    this.serviceContracts = client.service_contracts?.map(
      (contract) => new ServiceContractLiteStore(contract)
    )
    makeAutoObservable(this)
  }

  updateClientOverviewInfo = (client: Partial<ClientModel>) => {
    this.firstName = client.first_name || this.firstName
    this.lastName = client.last_name || this.lastName
    this.middleName = client.middle_name || this.middleName

    this.email = client.email || this.email
    this.phoneNumber = client.phone
      ? getPhoneParamsFromString(client.phone)
      : this.phoneNumber
  }

  get name() {
    return `${this.firstName || ""} ${this.lastName || ""}`
  }

  get role() {
    const serviceContractStatus = this.serviceContracts[0]?.status
    const rentalContractStatus = this.rentalContracts[0]?.status
    const isLandlord =
      serviceContractStatus &&
      !["Closed", "Pending", "Archived"].includes(serviceContractStatus)
    const isTenant =
      rentalContractStatus &&
      !["Closed", "Pending", "Archived"].includes(rentalContractStatus || "")

    if (isLandlord && isTenant) return "Landlord, Tenant"
    if (isLandlord) return "Landlord"
    if (isTenant) return "Tenant"
    return "No active contracts"
  }
}

export default ClientOverviewStore
