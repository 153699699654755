import { FormBottom } from "kui-crm"
import React from "react"
import { Box, Button } from "kui-basic"
import { PlusIcon } from "kui-icon"
import { AddImpairmentStepProps } from "./types"

const AddImpairmentStep = (props: AddImpairmentStepProps) => {
  const { addStep, handleSubmit } = props

  return (
    <>
      <Box pl={5} pr={5} mb={3}>
        <Button onClick={addStep} size="s" startIcon={<PlusIcon />}>
          Add impairment
        </Button>
      </Box>
      <FormBottom handleClick={handleSubmit} label="Create empty impairment" />
    </>
  )
}

export default AddImpairmentStep
