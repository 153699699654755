import React from "react"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { Grid } from "kui-basic"
import { InvisibleImagesBlockProps } from "./types"
import ImageCardWithBackdrop from "../../../../../../../../../components/common/AdaptiveImagesBlock/ImageCardWithBackdrop"
import usePromotionStore from "../../../../../store"

function InvisibleImagesBlock({ handleClick }: InvisibleImagesBlockProps) {
  const {
    contentStore: { imagesStore },
  } = usePromotionStore()

  if (!imagesStore?.unVisibleImagesCount || !imagesStore.unVisibleImage) {
    return null
  }

  return (
    <Grid item>
      <StyledImageCard
        url={imagesStore.unVisibleImage.url}
        onClick={handleClick}
      >
        +{imagesStore.unVisibleImagesCount}
      </StyledImageCard>
    </Grid>
  )
}

export default observer(InvisibleImagesBlock)

const StyledImageCard = styled(ImageCardWithBackdrop)`
  &,
  .ImageCard-backdrop,
  .ImageCard-wrapper {
    width: 136px;
    height: 136px;
  }
`
