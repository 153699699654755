import { useEffect } from "react"
import { SortingFilter, Paginator, useDebounce } from "kui-utils"

const useAutocomplete = (
  filter: SortingFilter,
  paginator: Paginator,
  fetchAll: () => void,
  defaultFilter?: string,
  withoutRequest?: boolean
) => {
  useEffect(() => {
    if (defaultFilter) filter.updateFilterQuery(defaultFilter)
    filter.updateSearchQuery("")
    paginator.clearOffset()
    paginator.startFetching()
  }, [])

  useEffect(() => {
    if (paginator.isFetching && !paginator.isFinishPage && !withoutRequest) {
      fetchAll()
    }
  }, [fetchAll, paginator.isFetching])

  useDebounce(filter.searchQuery, 500, () => {
    paginator.clearOffset()
    paginator.startFetching()
  })

  useDebounce(filter.filterQuery, 500, () => {
    paginator.clearOffset()
    paginator.startFetching()
  })

  const onSearchItem = (value: string) => {
    filter.updateSearchQuery(value)
    if (value.length === 0) {
      paginator.clearOffset()
      paginator.startFetching()
    }
  }

  const onScrollEnd = () => {
    if (!paginator.isFinishPage) {
      paginator.startFetching()
    }
  }

  return { onSearchItem, onScrollEnd }
}

export default useAutocomplete
