import { makeAutoObservable } from "mobx"
import { DateTime } from "luxon"
import { BillLinesModel, BillModel } from "../../types/api/expensesSummaryAPI"
import PayoutLineStore from "./PayoutLine"
import { AccrualsParams } from "../../../../types/store/apartment"
import { PayoutPaymentParams } from "../../types/store/expensesSummary"

class PayoutStore {
  accrualsLines: AccrualsParams

  compensationLines: PayoutLineStore[]

  payments: PayoutPaymentParams[]

  incomeLines: PayoutLineStore[]

  totalPrice: number | null

  number: string

  date: DateTime | null

  invoice: string | null

  deposit: string | null

  constructor(lines: BillLinesModel, payout: BillModel) {
    this.accrualsLines = PayoutStore.getAccruals(lines)
    this.compensationLines = lines.compensations
      .filter((line) => line.type !== "income")
      .map((line) => new PayoutLineStore(line))
    this.incomeLines = lines.compensations
      .filter((line) => line.type === "income")
      .map((line) => new PayoutLineStore(line))
    this.totalPrice = Number(lines.total)
    this.number = payout.number
    this.date = payout.invoice_date
      ? DateTime.fromISO(payout.invoice_date)
      : null
    this.payments =
      lines.payments?.map((payment) => ({
        ...payment,
        title: payment.name,
        value: Number(payment.amount),
      })) || []
    this.invoice = payout.invoice_preview || null
    this.deposit = payout.deposit_preview || null
    makeAutoObservable(this)
  }

  static getAccruals = (payout: BillLinesModel) => {
    const accruals: AccrualsParams = {
      fixed: [],
      variable: [],
      utility: [],
    }
    payout.accruals.forEach((line) => {
      const lineStore = new PayoutLineStore(line)
      switch (line.type) {
        case "period":
          accruals.fixed.push(lineStore)
          break
        case "open_ended":
          accruals.utility.push(lineStore)
          break
        default:
          accruals.variable.push(lineStore)
      }
    })
    return accruals
  }
}

export default PayoutStore
