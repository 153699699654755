import { makeAutoObservable } from "mobx"
import { DateTime } from "luxon"
import {
  ResourceTypes,
  CityParams,
  CountryParams,
  DistrictParams,
  RegionParams,
} from "kui-crm"
import {
  ElectricityTariffInfoModel,
  GasTariffInfoModel,
  HeatingTariffInfoModel,
  TariffInfoModel,
  WaterTariffInfoModel,
} from "../types/api/tariffAPI"
import { TariffsOfTariffGroup } from "../types/store/tariffOverview"

class TariffInfoStore {
  id: number

  name: string

  codeName: string

  country: CountryParams | null

  region: RegionParams | null

  city: CityParams | null

  residentialComplex: string

  district: DistrictParams | null

  startDate: DateTime | null

  endDate: DateTime | null

  tariffs: TariffsOfTariffGroup

  status: string

  resource: ResourceTypes

  constructor(tariff: TariffInfoModel, resource: ResourceTypes) {
    this.id = tariff.id
    this.country = tariff.country
    this.region = tariff.region
    this.city = tariff.city
    this.district = tariff.district
    this.name = tariff.description
    this.codeName = tariff.code_name
    this.startDate = tariff.date_from
      ? DateTime.fromISO(tariff.date_from)
      : null
    this.endDate = tariff.date_to ? DateTime.fromISO(tariff.date_to) : null
    this.tariffs = TariffInfoStore.getTariffValues(tariff, resource)
    this.status = tariff.status
    this.resource = resource
    this.residentialComplex = tariff.residential_complex_name || ""
    makeAutoObservable(this)
  }

  static getTariffValues = (
    tariff: TariffInfoModel,
    resource: ResourceTypes
  ) => {
    switch (resource) {
      case "electricity":
        return {
          T1: (tariff as ElectricityTariffInfoModel).T1,
          T2: (tariff as ElectricityTariffInfoModel).T2,
          T3: (tariff as ElectricityTariffInfoModel).T3,
        }
      case "water":
        return {
          cold: (tariff as WaterTariffInfoModel).cold,
          hot: (tariff as WaterTariffInfoModel).hot,
          sewerage: (tariff as WaterTariffInfoModel).sewerage,
        }
      case "gas":
        return { gas: (tariff as GasTariffInfoModel).gas }
      default:
        return { heating: (tariff as HeatingTariffInfoModel).heating }
    }
  }
}

export default TariffInfoStore
