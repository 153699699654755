import * as React from "react"
import styled from "@emotion/styled"
import Swiper, { SwiperProps } from "kui-swiper"
import { theme } from "kui-basic"
import { SliderThumbnailsProps, ImageStylesProps } from "./types"

function SliderThumbnails({
  images,
  activeSlide,
  handleChange,
}: SliderThumbnailsProps) {
  const params: SwiperProps = {
    slidesPerView: "auto" as const,
    spaceBetween: 12,
    grabCursor: true,
    slideToClickedSlide: true,
  }

  if (!images) return null

  return (
    <StyledWrapper>
      <Swiper {...params}>
        {images.map((image, index) => (
          <StyledSlide key={index} onClick={() => handleChange(index)}>
            <StyledImage
              active={index === activeSlide}
              src={image}
              alt="slide"
            />
          </StyledSlide>
        ))}
      </Swiper>
    </StyledWrapper>
  )
}

export default SliderThumbnails

const StyledWrapper = styled.div`
  padding: 16px;
  background-color: ${theme.palette.grey.zero};
  @media (max-width: 601px) {
    padding: 14px 16px 16px;
  }
`

const StyledImage = styled.img<ImageStylesProps>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  box-sizing: border-box;
  border: 2px solid
    ${({ active }) => (active ? theme.palette.brand.main : "transparent")};
`

const StyledSlide = styled.div`
  width: 80px;
  height: 80px;
  //border-radius: 6px;
  @media (max-width: 601px) {
    width: 48px;
    height: 48px;
  }
`
