import { DateTime } from "luxon"
import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import _ from "lodash"
import { Loader, resHandler, toNumber } from "kui-utils"
import { ClosingServiceContractFields, PartialPayerFields } from "kui-crm"
import {
  FileBodyRequest,
  TaxResidenceType,
  PaymentStatuses,
  TaxesTypes,
  ServiceContractModel,
  RentalTypes,
  RentPeriodTypes,
  ContractStatuses,
} from "kui-crm/types"
import ApartmentSimpleStore from "../../templates/Apartment"
import UserLiteStore from "../../templates/UserLite"
import {
  CheckParams,
  ContractPeriodTypes,
  FixedCostParams,
  SubjectRoles,
} from "../../../types/common"
import {
  CloseServiceContractRequest,
  PatchServiceContractParams,
  PatchServiceContractResponse,
  PlannedEndDateModel,
} from "../../../types/api/service_contracts"
import {
  matchesAPISubjectRoles,
  matchesSubjectRoles,
} from "../../../utils/content/matches"
import ServiceContractAgent from "../../../agent/ServiceContract"
import { ServiceContractInfoValues } from "../../../pages/ServiceContractPage/types/store/scInfoStore"
import { SCInfoFormFields } from "../../../pages/ServiceContractPage/components/SCInfoFields/types"
import RentalContractLiteStore from "../../templates/RentalContractLite"
import {
  getApiUtilitiesPayerParams,
  getUtilitiesPayerParams,
} from "../../../utils/service/apiMapper"
import { uploadNewFile } from "../../../utils/agent/uploadFiles"
import { ContractClosingFormFields } from "../../../components/forms/contacts/ContractClosingForm/types"
import { CheckoutDateFormFields } from "../../../components/forms/contacts/ContractCheckoutDateForm/types"
import { clearNotValidFields } from "../../../utils/service/mapper"
import { getChangedValue } from "../../../utils/agent/reqBodyFormat"
import { normalizePayers } from "../../../utils/content/scUtilityPayers"
import { ActivateContractFormFields } from "../../../components/forms/contacts/ActivateContractForm/types"
import { ActivateContractResponse } from "../../../types/api/contracts"

class ServiceContractInfoStore {
  id: number | null = null

  apartment: ApartmentSimpleStore | null = null

  number: string = ""

  startDate: DateTime | null = null

  signDate: DateTime | null = null

  endDate: DateTime | null = null

  closeDate: DateTime | null = null

  status: ContractStatuses | null = null

  landlord: UserLiteStore | null = null

  tenant: UserLiteStore | null = null

  type: RentalTypes | null = null

  advertisingPeriod: number | null = null

  maintenanceCommission: number | null = null

  initialCommission: number | null = null

  utilityPayers: PartialPayerFields[] = []

  inspectionIntervalMonth: number | null = null

  notifyPeriodMonth: number | null = null

  penaltyWithRental: number | null = null

  penaltyInPreparation: number | null = null

  minRentPrice: number | null = null

  maxRentPrice: number | null = null

  agentType: TaxesTypes | null = null

  autoProlongate: boolean = false

  paidStatus: PaymentStatuses | null = null

  contractPeriodType: ContractPeriodTypes | null = null

  rentPeriodType: RentPeriodTypes[] = []

  allowablePriceDeviation: number | null = null

  petsAllowed: boolean = false

  childrenAllowed: boolean = false

  additionalTerms: string = ""

  isPending: boolean = false

  loader: Loader

  contractInfoValues: ServiceContractInfoValues

  fixedCosts: FixedCostParams[] = []

  taxResidence: TaxResidenceType | null = null

  rentalContracts: RentalContractLiteStore[] = []

  firstAppraisalPeriod: number | null = null

  tentativeCloseDate: DateTime | null = null

  closingProgress: CheckParams[] = []

  constructor() {
    this.loader = new Loader()
    this.contractInfoValues = _.omitBy(
      this,
      _.isFunction
    ) as ServiceContractInfoValues
    makeAutoObservable(this)
  }

  patchServiceContractInfo = async (data: SCInfoFormFields) => {
    this.loader.startLoading("patch service contract")
    const body = ServiceContractInfoStore.getPatchSCFields(data, this)

    const [err, res] = await to<ServiceContractModel>(
      ServiceContractAgent.patch(this.id!, body)
    )
    if (!err && res) {
      this.updateServiceContract(res)
    } else {
      this.loader.setError(`patch service contract`, err)
    }
    this.loader.endLoading()
  }

  addPendingChange = async (data: SCInfoFormFields) => {
    const file = await uploadNewFile(this.loader, data.changesFile)
    const body = ServiceContractInfoStore.getBodyForAddPending(data, file, this)

    const [err, res] = await to<PatchServiceContractResponse>(
      ServiceContractAgent.createPendingChange(this.id!, body)
    )
    if (!err && res) {
      this.updateServiceContract(res.changes)
    } else {
      this.loader.setError(`add pending change`, err)
    }
  }

  closeServiceContract = async (data: ContractClosingFormFields) => {
    this.loader.startLoading("closing contract")

    const file = await uploadNewFile(this.loader, data.terminationAgreement)

    const body = ServiceContractInfoStore.getCloseContractBody(data, file)

    const response = await to<CloseServiceContractRequest>(
      ServiceContractAgent.close(this.id!, body)
    )

    resHandler(response, this.loader, (res) => {
      this.closeDate = res.end_date ? DateTime.fromISO(res.end_date) : null
      this.status = "Closed"
    })
  }

  activateServiceContract = async (data: ActivateContractFormFields) => {
    const contractId = this.id

    if (contractId) {
      this.loader.startLoading()
      const document = await uploadNewFile(this.loader, data.document)
      const body = { document }

      const response = await to<ActivateContractResponse>(
        ServiceContractAgent.activateContract(contractId, body)
      )

      resHandler(response, this.loader, this.updateContractStatus)
    }
  }

  updateContractStatus = (data: ActivateContractResponse) => {
    this.status = data.status
  }

  saveTentativeCloseDate = async (data: CheckoutDateFormFields) => {
    this.loader.startLoading()
    const document = await uploadNewFile(this.loader, data.reason)

    const body: PlannedEndDateModel = {
      document,
      planned_end_date: data.checkoutDate?.toISODate() || "",
    }

    const [err, res] = await to<PlannedEndDateModel>(
      ServiceContractAgent.saveTentativeCloseDate(this.id!, body)
    )

    runInAction(() => {
      if (!err && res) {
        this.tentativeCloseDate = DateTime.fromISO(res.planned_end_date)
      } else {
        this.loader.setError("save close date")
      }
      this.loader.endLoading()
    })
  }

  updateServiceContract = (contract: Partial<ServiceContractModel>) => {
    const contractFields = ServiceContractInfoStore.getContractFields(contract)

    if (contract.id) this.id = contract.id
    if (contract.apartment)
      this.apartment = new ApartmentSimpleStore(contract.apartment)
    if (contract.owner)
      this.landlord = UserLiteStore.initFromLiteUserModel(contract.owner)
    if (contract.renter)
      this.tenant = UserLiteStore.initFromLiteUserModel(contract.renter)
    this.isPending = !(Number(this.startDate?.diffNow("days").days) < 0)
    if (contract.rental_contracts)
      this.rentalContracts = contract.rental_contracts?.map(
        (rentalContract) => new RentalContractLiteStore(rentalContract)
      )
    if (contract.status) this.status = contract.status
    if (contract.closing_conditions)
      this.closingProgress = ServiceContractInfoStore.getClosingProgress(
        contract.closing_conditions
      )
    if (contract.planned_end_date)
      this.tentativeCloseDate = DateTime.fromISO(contract.planned_end_date)

    if (contractFields.number) this.number = contractFields.number
    if (contractFields.startDate) this.startDate = contractFields.startDate
    if (contractFields.type) this.type = contractFields.type
    if (contractFields.signDate) this.signDate = contractFields.signDate
    if (contractFields.endDate) this.endDate = contractFields.endDate
    if (contractFields.maintenanceCommission)
      this.maintenanceCommission = contractFields.maintenanceCommission
    if (contractFields.initialCommission)
      this.initialCommission = contractFields.initialCommission
    if (contractFields.utilityPayers)
      this.utilityPayers = contractFields.utilityPayers
    if (contractFields.advertisingPeriod)
      this.advertisingPeriod = contractFields.advertisingPeriod
    if (contractFields.inspectionIntervalMonth)
      this.inspectionIntervalMonth = contractFields.inspectionIntervalMonth
    if (contractFields.notifyPeriodMonth)
      this.notifyPeriodMonth = contractFields.notifyPeriodMonth
    if (contractFields.penaltyWithRental)
      this.penaltyWithRental = contractFields.penaltyWithRental
    if (contractFields.penaltyInPreparation)
      this.penaltyInPreparation = contractFields.penaltyInPreparation
    if (contractFields.minRentPrice)
      this.minRentPrice = contractFields.minRentPrice
    if (contractFields.maxRentPrice)
      this.maxRentPrice = contractFields.maxRentPrice
    if (contractFields.agentType) this.agentType = contractFields.agentType
    if (typeof contractFields.autoProlongate === "boolean")
      this.autoProlongate = contractFields.autoProlongate
    if (contractFields.paidStatus) this.paidStatus = contractFields.paidStatus
    if (contractFields.contractPeriodType)
      this.contractPeriodType = contractFields.contractPeriodType
    if (typeof contractFields.childrenAllowed !== "undefined")
      this.childrenAllowed = contractFields.childrenAllowed
    if (typeof contractFields.petsAllowed !== "undefined")
      this.petsAllowed = contractFields.petsAllowed
    if (contractFields.additionalTerms)
      this.additionalTerms = contractFields.additionalTerms
    if (contractFields.allowablePriceDeviation)
      this.allowablePriceDeviation = contractFields.allowablePriceDeviation
    if (contractFields.taxResidence)
      this.taxResidence = contractFields.taxResidence
    if (contractFields.fixedCosts instanceof Array)
      this.fixedCosts = contractFields.fixedCosts
    if (contractFields.rentPeriodType)
      this.rentPeriodType = contractFields.rentPeriodType
    if (contractFields.firstAppraisalPeriod)
      this.firstAppraisalPeriod = contractFields.firstAppraisalPeriod
    if (contractFields.closeDate) this.closeDate = contractFields.closeDate

    this.contractInfoValues = _.omitBy(
      this,
      _.isFunction
    ) as ServiceContractInfoValues
  }

  updateAutoprolongate = (value: boolean) => {
    this.autoProlongate = value
  }

  get isClosed() {
    return this.status === "Closed"
  }

  get canBeClosed() {
    return this.closingProgress.filter((step) => !step.checked).length === 0
  }

  static getBodyForAddPending = (
    data: Partial<SCInfoFormFields>,
    file: FileBodyRequest | null,
    contractInfo: ServiceContractInfoStore
  ) => ({
    apply_from: data.changesDate ? data.changesDate.toISODate() : null,
    reason_document: file,
    changes: ServiceContractInfoStore.getPatchSCFields(data, contractInfo),
  })

  static getCloseContractBody = (
    data: ContractClosingFormFields,
    file: FileBodyRequest | null
  ) => ({
    end_date: data.closeDate?.toISODate(),
    termination_agreement: file,
  })

  static getPatchSCFields = (
    data: Partial<ServiceContractInfoStore>,
    contractInfo: ServiceContractInfoStore
  ) =>
    clearNotValidFields({
      number: data.number,
      sign_date: data.signDate?.toISODate(),
      start_date: data.startDate?.toISODate(),
      end_date: data.endDate ? data.endDate.toISODate() : null,
      // advertising_period: data.advertising_period,
      maintenance_commission: data.maintenanceCommission,
      initial_commission: data.initialCommission,
      inspection_interval_month: data.inspectionIntervalMonth,
      notification_period: data.notifyPeriodMonth,
      penalty_with_rental: data.penaltyWithRental,
      penalty_in_preparation: toNumber(data.penaltyInPreparation),
      min_rent_price: toNumber(data.minRentPrice),
      max_rent_price: toNumber(data.maxRentPrice),
      auto_prolongate: data.autoProlongate,
      taxes_type: data.agentType,
      long_term_type: data.rentPeriodType?.includes("any")
        ? (["short", "medium", "long"] as RentPeriodTypes[])
        : data.rentPeriodType,
      allowed_pets: data.petsAllowed,
      allowed_children: data.childrenAllowed,
      additional_terms: data.additionalTerms,
      allowable_price_deviation: data.allowablePriceDeviation,
      fixed_costs: data.fixedCosts?.map((expense) => ({
        name: expense.name,
        value: toNumber(expense.cost),
        payer: matchesAPISubjectRoles[expense.payer],
      })),
      utility_payers: getChangedValue(
        data.utilityPayers,
        normalizePayers(contractInfo.utilityPayers),
        data.utilityPayers?.map((payer) => getApiUtilitiesPayerParams(payer)) ||
          []
      ),
      first_appraisal_interval_month: data.firstAppraisalPeriod,
    }) as PatchServiceContractParams

  static getContractFields = (contract: Partial<ServiceContractModel>) =>
    ({
      number: contract.number,
      startDate: contract.start_date
        ? DateTime.fromISO(contract.start_date)
        : null,
      type: contract.rental_type,
      signDate: contract.sign_date
        ? DateTime.fromISO(contract.sign_date)
        : null,
      endDate: contract.end_date ? DateTime.fromISO(contract.end_date) : null,
      maintenanceCommission: contract.maintenance_commission
        ? Number(contract.maintenance_commission)
        : null,
      initialCommission: contract.initial_commission
        ? Number(contract.initial_commission)
        : null,
      utilityPayers:
        contract.utility_payers?.map((utilityPayer) =>
          getUtilitiesPayerParams(utilityPayer)
        ) || null,
      advertisingPeriod: contract.advertising_period
        ? Number(contract.advertising_period)
        : null,
      inspectionIntervalMonth: contract.inspection_interval_month,
      notifyPeriodMonth: contract.notification_period,
      penaltyWithRental: contract.penalty_with_rental
        ? Number(contract.penalty_with_rental)
        : null,
      penaltyInPreparation: contract.penalty_in_preparation
        ? Number(contract.penalty_in_preparation)
        : null,
      minRentPrice: contract.min_rent_price,
      maxRentPrice: contract.max_rent_price,
      agentType: contract.taxes_type,
      autoProlongate:
        typeof contract.auto_prolongate === "boolean"
          ? contract.auto_prolongate
          : null,
      paidStatus: contract.renter_payment_status,
      ...(typeof contract.end_date !== "undefined"
        ? {
            contractPeriodType: contract.end_date ? "fixed" : "openEnded",
          }
        : {}),
      childrenAllowed:
        typeof contract.allowed_children !== "undefined"
          ? !!contract.allowed_children
          : null,
      petsAllowed:
        typeof contract.allowed_pets !== "undefined"
          ? !!contract.allowed_pets
          : null,
      additionalTerms: contract.additional_terms,
      allowablePriceDeviation: contract.allowable_price_deviation,
      taxResidence: contract.tax_residence,
      fixedCosts:
        contract.fixed_costs instanceof Array
          ? contract.fixed_costs?.map((expense) => ({
              name: expense.name,
              cost: Number(expense.value),
              payer: matchesSubjectRoles[expense.payer] as SubjectRoles,
            }))
          : null,
      rentPeriodType: contract.long_term_type,
      firstAppraisalPeriod: contract.first_appraisal_interval_month
        ? Number(contract.first_appraisal_interval_month)
        : null,
      closeDate: contract.close_date
        ? DateTime.fromISO(contract.close_date)
        : null,
    } as Partial<ServiceContractInfoStore>)

  static getClosingProgress = (fields: ClosingServiceContractFields) => [
    {
      label: "Closed rental contract",
      checked: !!fields.have_rental_contracts_closed,
    },
    {
      label: "Transfer inspection to Landlord",
      checked: !!fields.have_closing_transfer_inspection,
    },
    {
      label: "Impairments inspection",
      checked: !!fields.have_closing_impairments,
    },
    {
      label: "Date of termination",
      checked: !!fields.have_closing_inspection_date_eq_contract_end_date,
    },
    { label: "Closed last period", checked: !!fields.have_closed_last_period },
  ]
}

export default ServiceContractInfoStore
