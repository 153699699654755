import React from "react"
import { useWatch } from "react-hook-form"
import { InputWithController } from "kui-complex"
import { DependentInputProps } from "./types"

const DependentInput = <T extends object>(props: DependentInputProps<T>) => {
  const { dependentInputName, form, disabled, Input, ...other } = props
  const dependentInputValue = useWatch({
    control: form.control,
    name: dependentInputName,
  })
  const inputProps = {
    form,
    disabled: disabled || !dependentInputValue,
    ...other,
  }

  return Input ? (
    <Input {...inputProps} />
  ) : (
    <InputWithController {...inputProps} />
  )
}

export default DependentInput
