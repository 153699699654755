import React from "react"
import { observer } from "mobx-react"
import { Modal } from "kui-crm"
import {
  ApartmentOwnershipMembersFields,
  ApartmentOwnershipModalProps,
} from "./types"
import OwnershipMemberForm from "../../../../forms/ownership/OwnershipMemberForm"

function OwnershipMemberModal(props: ApartmentOwnershipModalProps) {
  const { open, handleClose, handleSubmit, title } = props

  const handleFormSubmit = (data: ApartmentOwnershipMembersFields) => {
    handleSubmit(data)
    handleClose()
  }

  return (
    <Modal title={title} open={open} handleClose={handleClose}>
      <OwnershipMemberForm handleSubmit={handleFormSubmit} />
    </Modal>
  )
}

export default observer(OwnershipMemberModal)
