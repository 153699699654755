import React from "react"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import { css } from "@emotion/react"
import { theme } from "kui-basic"
import FileItem from "../../../common/FileItem"
import { FileItemStylesProps, UploadedFileRowProps } from "./types"
import UploadFileStatus from "../UploadFileStatus"

const UploadedFileRow = (props: UploadedFileRowProps) => {
  const { file, onDelete, onChange, variant, ...other } = props
  const { uploadStatus, index } = file

  const handleDelete = (fileKey: number | string, fileIndex: number) => {
    onDelete(fileIndex)
  }

  const handleChange = (name: string, fileIndex?: number) => {
    if (fileIndex) {
      onChange(fileIndex, name)
    }
  }

  return (
    <StyledFileItem
      file={file}
      onDelete={handleDelete}
      onChange={handleChange}
      index={index}
      withError={uploadStatus === "failure"}
      statusIcon={
        uploadStatus !== "default" && (
          <UploadFileStatus file={file} variant={variant} />
        )
      }
      {...other}
    />
  )
}

export default observer(UploadedFileRow)

const withErrorCSS = ({ withError }: FileItemStylesProps) =>
  withError &&
  css`
    border-color: ${theme.palette.red.fiftyP};
  `

const StyledFileItem = styled(FileItem)<FileItemStylesProps>`
  ${withErrorCSS};
`
