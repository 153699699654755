import React from "react"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { Modal } from "kui-crm"
import { onInspectionModalClose } from "kui-crm_actions"
import InspectionCreationForm from "../../../../../forms/inspections/InspectionCreationForm"
import useApartmentStore from "../../../../../store"
import { InspectionCreationModalProps } from "./types"
import { inspectionFieldsConstants } from "../../../../../../../utils/content/constants"

const InspectionCreationModal = (props: InspectionCreationModalProps) => {
  const { open, handleClose, variant, basedOn } = props
  const apartmentStore = useApartmentStore()
  const { inspectionsStore } = apartmentStore
  const inspectionType = inspectionsStore.creationForm.fields?.type
  const label =
    inspectionType === "appraisal" || inspectionType === "impairments"
      ? inspectionType
      : `${inspectionType || ""} inspection`

  const onClose = () => {
    onInspectionModalClose(
      apartmentStore,
      inspectionFieldsConstants,
      handleClose
    )
  }

  return (
    <StyledModal
      title={`New ${variant || label}`}
      open={open}
      handleClose={onClose}
    >
      <InspectionCreationForm
        type={variant}
        basedOn={basedOn}
        handleClose={handleClose}
      />
    </StyledModal>
  )
}

export default observer(InspectionCreationModal)

const StyledModal = styled(Modal)`
  h2 {
    padding-bottom: 8px;
  }
`
