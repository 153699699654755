import { makeAutoObservable } from "mobx"
import { Loader } from "kui-utils"
import { OperatingExpenseModel } from "../../types/api/expensesOperatingExpensesAPI"
import ApartmentOperatingExpensesStore from "./ApartmentOperatingExpenses"

class ApartmentOperatingExpense {
  id: number

  title: string

  value: number

  loader: Loader

  expensesStore: ApartmentOperatingExpensesStore

  constructor(
    expense: OperatingExpenseModel,
    expensesStore: ApartmentOperatingExpensesStore
  ) {
    this.id = expense.id
    this.title = expense.name
    this.value = Number(expense.value)
    this.loader = new Loader()
    this.expensesStore = expensesStore
    makeAutoObservable(this, { expensesStore: false })
  }
}

export default ApartmentOperatingExpense
