import React from "react"
import styled from "@emotion/styled"
import _ from "lodash"
import { useNavigate } from "react-router-dom"
import { Caption, Grid, Divider } from "kui-basic"
import { ApartmentCardProps } from "./types"
import { Routes } from "../../../../types/route"
import TextOverflow from "../../../../components/common/TextOverflow"
import UserBadge from "../../../../components/common/UserBadge"
import TypographyMoney from "../../../../components/common/TypographyMoney"

const defaultImageURL = "/images/LivingAreaSmall.svg"

function ApartmentCard({ apartment }: ApartmentCardProps) {
  const navigate = useNavigate()

  const routeToApartment = () => {
    navigate(`${Routes.apartments}/${apartment.id}`)
  }

  return (
    <StyledSection aria-label="информация о квартире">
      <StyledImage
        src={apartment.previewImage || defaultImageURL}
        onClick={routeToApartment}
      />
      <StyledContent>
        <TextOverflow size="l" weight={700} onClick={routeToApartment}>
          {apartment.address}
        </TextOverflow>
        <UserBadge
          user={apartment.landlord}
          avatarSize="xs"
          weight={400}
          color="sixty"
          spacing={6}
        />
        {apartment.tenant && (
          <StyledUserBadge
            user={apartment.tenant}
            avatarSize="xs"
            weight={400}
            color="sixty"
            spacing={6}
          />
        )}

        <StyledDivider />

        <Grid container alignItems="center" justify="space-between">
          <Caption color="sixty" weight={500} size="sm">
            {apartment.id} • {apartment.rentalStatus} •{" "}
            {apartment.rentalType
              ? _.capitalize(apartment.rentalType)
              : "Unknown"}
          </Caption>
          {!!apartment.rentalCost && (
            <TypographyMoney
              weight={600}
              color="sixty"
              value={apartment.rentalCost}
            />
          )}
        </Grid>
      </StyledContent>
    </StyledSection>
  )
}

export default ApartmentCard

const StyledSection = styled.section`
  width: 328px;
  border-radius: 8px;
  position: relative;
  background: ${({ theme }) => theme.palette.grey.zero};
  transition: all ease-out 0.3s;
  &:hover {
    box-shadow: 0 12px 16px rgb(0 0 0 / 8%);
  }
`

const StyledImage = styled.img`
  height: 196px;
  width: 328px;
  cursor: pointer;
`

const StyledContent = styled.div`
  padding: 8px 16px 16px;
`

const StyledDivider = styled(Divider)`
  margin: 6px 0 11px;
`

const StyledUserBadge = styled(UserBadge)`
  margin-top: 4px;
`
