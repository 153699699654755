import * as yup from "yup"
import { checkCurrentDateOlderTargetDate } from "../../../../utils/service/schema"
import { ClientTypes } from "../../../../types/api/clients"

const RentalContractInfoFormSchema = yup.object().shape(
  {
    clientType: yup.string(),
    tenant: yup.object({
      name: yup.string().required("This field is required"),
    }),
    signDate: yup.mixed().required("This field is required"),
    rentalType: yup.string().required("This field is required"),
    startDate: checkCurrentDateOlderTargetDate(
      "startDate",
      "signDate"
    ).required("This field is required"),
    contractType: yup.string().required("This field is required"),
    endDate: yup.mixed().when("contractType", {
      is: (value: string) => value === "period",
      then: (rule) => rule.required("This field is required"),
    }),
    roommates: yup
      .array()
      .of(
        yup.object({
          name: yup.string().required("This field is required"),
        })
      )
      .when("clientType", {
        is: (value?: ClientTypes) => value === "company",
        then: (rule) => rule.min(1).required("This field is required"),
      }),
  },
  [["startDate", "startDate"]]
)

export default RentalContractInfoFormSchema
