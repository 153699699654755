import React from "react"
import { useFieldArray } from "react-hook-form"
import { observer } from "mobx-react"
import { ApartmentMetersFieldsProps } from "./types"
import useInspectionStore from "../../store"
import ApartmentPartialMeterFields from "./ApartmentPartialMeterFields"
import Container from "../../../../../../components/ui/Container"

const ApartmentPartialMetersFields = ({ form }: ApartmentMetersFieldsProps) => {
  const { editor } = useInspectionStore()
  const { fields } = useFieldArray({
    control: form.control,
    name: "meters",
  })

  return (
    <Container>
      {fields.map((field, index) => (
        <ApartmentPartialMeterFields
          key={field.id}
          index={index}
          resource={field.resource}
          form={form}
          disabled={!editor.isEditing}
        />
      ))}
    </Container>
  )
}

export default observer(ApartmentPartialMetersFields)
