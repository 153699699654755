import React, { useState } from "react"
import { observer } from "mobx-react"
import { LoaderState } from "kui-crm"
import useApartmentsStore from "./store"
import Page from "../../components/common/Page"
import { ApartmentsViewVariants } from "./components/ApartmentsContentView/types"
import usePaginationPage from "../../hooks/usePaginationPage"
import ApartmentsSearchPanel from "./components/ApartmentsSearchPanel"
import ApartmentsContentView from "./components/ApartmentsContentView"

function ApartmentsPage() {
  const [view, setView] = useState<ApartmentsViewVariants>("table")
  const apartmentsStore = useApartmentsStore()
  const { paginator, filter, loader, creationLoader } = apartmentsStore
  const { fetchAll, getApartmentsForMap } = apartmentsStore

  const getApartments = async () => {
    if (view === "map") await getApartmentsForMap()
    else await fetchAll()
  }

  usePaginationPage(paginator, getApartments, filter)

  return (
    <Page withContainer={false}>
      <ApartmentsSearchPanel view={view} setView={setView} />
      <ApartmentsContentView view={view} />

      <LoaderState loader={creationLoader} />
      <LoaderState loader={loader} onlyError />
    </Page>
  )
}

export default observer(ApartmentsPage)
