import React, { useState } from "react"
import { observer } from "mobx-react"
import useTabIndex from "../../../../hooks/useTabIndex"
import useTariffStore from "../../store"
import TariffEditButton from "../TariffEditButton"
import TariffOverviewTab from "../TariffOverviewTab"
import TariffObjectsTab from "../TariffObjectsTab"
import TariffCompaniesTab from "../TariffCompaniesTab"
import PageTabsPanel from "../../../../components/ui/PageTabsPanel"

function TariffTabsPanel() {
  const { editor } = useTariffStore()

  const paths = ["?tab=overview", "?tab=objects", "?tab=companies"]

  const activeTab = useTabIndex(paths)
  const [currentTab, setCurrentTab] = useState(activeTab)

  const tabs = ["Overview", "Objects", "Companies"]

  const tabPanels = [
    <TariffOverviewTab />,
    <TariffObjectsTab />,
    <TariffCompaniesTab />,
  ]

  const handleTabChange = (e: any, tabIndex: number) => {
    setCurrentTab(tabIndex)
    editor.endEditing()
  }

  return (
    <PageTabsPanel
      activeTab={Number(activeTab)}
      paths={paths}
      tabs={tabs}
      tabPanels={tabPanels}
      onChange={handleTabChange}
      endComponent={Number(currentTab) === 0 && <TariffEditButton />}
    />
  )
}

export default observer(TariffTabsPanel)
