import { theme } from "kui-basic"

export const statusesStyles = {
  active: {
    label: "Active",
    color: theme.palette.green.seventy,
  },
  expires: {
    label: "Expire soon",
    color: theme.palette.brand.main,
  },
  expired: {
    label: "Expired",
    color: theme.palette.red.seventy,
  },
  pending: {
    label: "Pending",
    color: theme.palette.grey.fiftyP,
  },
  archived: {
    label: "Archive",
    color: theme.palette.grey.fiftyP,
  },
}
