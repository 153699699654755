import React from "react"
import { Grid } from "kui-basic"
import styled from "@emotion/styled"
import { InputWithController } from "kui-complex"
import { PromotionDescriptionFieldsProps } from "../types"
import { descriptionPlaceholder } from "./content"

const PromotionDescriptionFields = (props: PromotionDescriptionFieldsProps) => {
  const { form, disabled } = props

  return (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <StyledInput
          isTextArea
          label="Description for other sites"
          name="otherSitesDescription"
          form={form}
          rows={19}
          disabled={disabled}
          placeholder={descriptionPlaceholder}
        />
      </Grid>
      <Grid item xs={6}>
        <StyledInput
          isTextArea
          label="Description for service"
          name="serviceDescription"
          form={form}
          rows={19}
          disabled={disabled}
          placeholder={descriptionPlaceholder}
        />
      </Grid>
      <StyledLastInputWrapper item xs={6}>
        <StyledInput
          isTextArea
          label="Description about service"
          name="aboutServiceDescription"
          form={form}
          disabled={disabled}
        />
      </StyledLastInputWrapper>
    </Grid>
  )
}

export default PromotionDescriptionFields

const StyledInput = styled(InputWithController)`
  white-space: pre-line;
  textarea {
    white-space: pre-line;
    max-height: 450px !important;
  }
`

const StyledLastInputWrapper = styled(Grid)`
  padding-top: 24px !important;
`
