import * as React from "react"
import { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { Caption, Grid } from "kui-basic"
import { copyInfo } from "kui-utils"
import { CopyIcon } from "kui-icon"
import { CardStylesProps, CopyButtonProps } from "./types"
import { maxWidth } from "../../../utils/service/theme"

function CopyButton({ text, ...otherProps }: CopyButtonProps) {
  const [isCopied, setIsCopied] = useState(false)

  const handleClick = () => {
    copyInfo(text)
    setIsCopied(true)
  }

  useEffect(() => {
    let intervalId: number

    if (isCopied) {
      const timeout = setTimeout(() => {
        setIsCopied(false)
      }, 1000 * 1.5)
      intervalId = Number(timeout)
    }

    return () => clearInterval(intervalId)
  }, [isCopied])

  return (
    <Wrapper container alignItems="center">
      <CopyIconStyled
        onClick={handleClick}
        width={20}
        height={20}
        {...otherProps}
      />
      <CardWrapper isCopied={isCopied}>
        <StyledCaption size="xs" disableUserSelect>
          Copied to Clipboard
        </StyledCaption>
      </CardWrapper>
    </Wrapper>
  )
}

export default CopyButton

const CopyIconStyled = styled(CopyIcon)`
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.palette.grey.thirty};
    transition: all ease-out 0.3s;
  }
  &:hover {
    path {
      fill: ${({ theme }) => theme.palette.grey.seventy};
    }
  }
`

const StyledCaption = styled(Caption)`
  -webkit-text-fill-color: ${({ theme }) => theme.palette.grey.seventy};
`

const CardWrapper = styled.div<CardStylesProps>`
  opacity: ${({ isCopied }) => (isCopied ? 1 : 0)};
  background: #ffffff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  height: 48px;
  width: 217px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  position: absolute;
  top: -2px;
  right: -227px;
  transition: opacity ease-out 0.3s;
  pointer-events: none;
  box-sizing: border-box;
  z-index: 10;
  @media (max-width: 1750px) {
    right: 0;
    top: unset;
    bottom: -50px;
    z-index: 10;
  }
  ${maxWidth("sm")} {
    position: fixed;
    bottom: 24px;
    right: calc(50% - 217px / 2);
  }
`

const Wrapper = styled(Grid)`
  width: auto;
  position: relative;
`
