/** @jsxImportSource @emotion/react */
import * as React from "react"
import { observer } from "mobx-react"
import { SignInPage as SignInPageKUI } from "kui-crm"
import ErrorBoundary from "../../components/common/ErrorBoundary"
import useStore from "../../hooks/useStore"

const SignInPage: React.FC = () => {
  const {
    authStore: { signIn, err, isLoading },
  } = useStore()

  return (
    <ErrorBoundary>
      <SignInPageKUI handleSubmit={signIn} err={!!err} isLoading={isLoading} />
    </ErrorBoundary>
  )
}

export default observer(SignInPage)
