import React from "react"
import { Grid } from "kui-basic"
import styled from "@emotion/styled"
import ApartmentChartLegend from "../ApartmentChartLegend"

const ApartmentChartLegends = () => (
  <StyledWrapper container spacing={5} wrap="nowrap">
    <ApartmentChartLegend
      variant="brand"
      label="cost calculation result based on historical data using ML model"
    />
    <ApartmentChartLegend variant="blue" label="cost in advertising" />
  </StyledWrapper>
)

export default ApartmentChartLegends

const StyledWrapper = styled(Grid)`
  padding-left: 88px;
  margin-bottom: 14px;
`
