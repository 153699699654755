import { ChangeReasonFields, FormWrapper } from "kui-crm"
import React from "react"
import { useForm } from "react-hook-form"
import { Grid } from "kui-basic"
import InputSelect from "../../../../../components/ui/InputSelect"
import { OwnershipMembersValues } from "../../../../../utils/content/listsOptions"
import { OwnershipMemberFormProps } from "./types"
import useResetForm from "../../../../../hooks/useResetForm"

const OwnershipMemberForm = (props: OwnershipMemberFormProps) => {
  const { handleSubmit } = props
  const form = useForm()

  useResetForm(form)

  return (
    <FormWrapper form={form} onSubmit={handleSubmit} label="Start editing">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputSelect
            options={OwnershipMembersValues}
            label="Type member"
            name="memberType"
            form={form}
          />
        </Grid>
        <Grid item xs={12}>
          <ChangeReasonFields form={form} />
        </Grid>
      </Grid>
    </FormWrapper>
  )
}

export default OwnershipMemberForm
