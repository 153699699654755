import React from "react"
import { Caption, Grid } from "kui-basic"
import { WarningIcon } from "kui-icon"
import styled from "@emotion/styled"
import { Tooltip } from "kui-crm"
import { ServicePeriodCellProps } from "./types"

const ServicePeriodCell = ({ service }: ServicePeriodCellProps) => (
  <Grid container alignItems="center">
    <span>{service.periodDate?.toFormat("MM.yyyy") || "-"}</span>
    {service.periodIsClosed && (
      <StyledTooltip
        placement="bottom"
        content={
          <Caption size="xs" weight={500}>
            Period closed
          </Caption>
        }
      >
        <StyledWarningIcon />
      </StyledTooltip>
    )}
  </Grid>
)

export default ServicePeriodCell

const StyledWarningIcon = styled(WarningIcon)`
  margin-left: 8px;
  width: 18px;
  height: 18px;
  min-width: 18px;
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.palette.red.seventy};
  }
`

const StyledTooltip = styled(Tooltip)`
  .KUI-Popper {
    z-index: 2;
  }
`
