import React, { useEffect, useState } from "react"
import { Grid } from "kui-basic"
import { useWatch } from "react-hook-form"
import { InputWithMonthPicker } from "kui-complex"
import { DateTime } from "luxon"
import InputSelect from "../../../ui/InputSelect"
import { DurationBlockProps } from "./types"
import { DurationType } from "../../../../types/api/apartmentExpenses"
import { ServiceMainInfoFormFields } from "../ServiceMainInfoForm/types"
import useLoader from "../../../../hooks/useLoader"
import { getLastOpenedPeriod } from "./service"
import { durationTypeOptions } from "./content"

function DurationBlock(props: DurationBlockProps<ServiceMainInfoFormFields>) {
  const { form, handleDateChange, lastOpenPeriod } = props
  const [minDate, setMinDate] = useState<DateTime | null>(
    lastOpenPeriod || null
  )
  const loader = useLoader()
  const durationVariant = useWatch({
    control: form.control,
    name: "durationType",
  })
  const dateProps = {
    datePickerProps: { minDate: minDate?.toJSDate() },
  }

  const handleChangeDurationVariant = (value: DurationType) => {
    if (value === "open-ended") {
      form.setValue("chargeTill", null)
    }
  }

  useEffect(() => {
    if (lastOpenPeriod) {
      setMinDate(lastOpenPeriod)
    } else {
      const apartment = form.getValues("apartment")
      if (apartment?.id) {
        getLastOpenedPeriod(apartment.id, loader, setMinDate)
      }
    }
  }, [lastOpenPeriod])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <InputSelect
          options={durationTypeOptions}
          handleChange={handleChangeDurationVariant}
          label="Duration"
          name="durationType"
          form={form}
          data-testid="duration"
          isRequired
        />
      </Grid>
      <Grid item xs={6}>
        <InputWithMonthPicker
          isRequired
          label="Charge from"
          name="chargeFrom"
          form={form}
          testId="date"
          onChange={handleDateChange}
          {...dateProps}
        />
      </Grid>
      <Grid item xs={6}>
        <InputWithMonthPicker
          isRequired={durationVariant === "period"}
          label="Charge till"
          name="chargeTill"
          form={form}
          disabled={durationVariant !== "period"}
          {...dateProps}
        />
      </Grid>
    </Grid>
  )
}

export default DurationBlock
