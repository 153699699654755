import { ResourceTypes } from "kui-crm"
import requests from "./index"
import {
  PatchElectricityMeterParams,
  PatchWaterMeterParams,
} from "../pages/ApartmentPage/pages/ExpensesPage/types/api/expensesMetersAPI"
import { PostMeterCost } from "../types/api/meters"

const ApartmentMetersAgent = {
  postElectricityMeter: (data: any) =>
    requests.post(`/meters/electricity`, data),
  patchElectricityMeter: (
    id: number,
    data: PatchElectricityMeterParams,
    year: number,
    month: number
  ) =>
    requests.patch(
      `/meters/electricity/${id}?year=${year}&month=${month}`,
      data
    ),
  patchWaterMeter: (
    id: number,
    data: PatchWaterMeterParams,
    year: number,
    month: number
  ) => requests.patch(`/meters/water/${id}?year=${year}&month=${month}`, data),
  postWaterMeter: (data: any) => requests.post(`/meters/water`, data),
  getMeters: (
    apartmentId: number,
    resource: ResourceTypes,
    year: number,
    month: number,
    contractId?: number | null,
    queryParams?: string
  ) =>
    requests.get(
      `/apartments/${apartmentId}/expenses/meters/${resource}?year=${year}&month=${month}${
        contractId ? `&rental_contract=${contractId}` : ""
      }${queryParams || ""}`
    ),
  postCost: (
    apartmentId: number,
    id: number,
    data: PostMeterCost,
    mater: ResourceTypes
  ) =>
    requests.put(
      `/apartments/${apartmentId}/expenses/meters/${mater}/${id}/set_cost`,
      data
    ),
}

export default ApartmentMetersAgent
