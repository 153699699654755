import { createContext, useContext } from "react"
import RegistriesPageStore from "./RegistriesPageStore"

const registriesStore = new RegistriesPageStore()
const RegistriesContext = createContext(registriesStore)

const useRegistriesStore = () => useContext(RegistriesContext)

export default useRegistriesStore
export { registriesStore }
