import React from "react"
import { Grid } from "kui-basic"
import { ApartmentDocumentRowProps } from "./types"
import EmptyApartmentDocumentRow from "../EmptyApartmentDocumentRow"
import FilledApartmentDocumentRow from "../FilledApartmentDocumentRow"

const ApartmentDocumentRow = (props: ApartmentDocumentRowProps) => {
  const { variant, document, disabled } = props

  return (
    <Grid item xs={6}>
      {document ? (
        <FilledApartmentDocumentRow
          disabled={disabled}
          document={document}
          variant={variant}
        />
      ) : (
        <EmptyApartmentDocumentRow disabled={disabled} variant={variant} />
      )}
    </Grid>
  )
}

export default ApartmentDocumentRow
