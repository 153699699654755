import to from "await-to-js"
import { makeAutoObservable, runInAction } from "mobx"
import { Loader, Paginator } from "kui-utils"
import { DocumentModel } from "kui-crm"
import {
  DocumentsPaginationRes,
  DocumentsStoreInterface,
  DocumentWithPaginatorAgentInterface,
} from "../../types/store/file"
import DocumentStore from "./DocumentStore"
import { UploadDocumentParams } from "../../components/common/DocumentsBlockWithForm/types"
import { uploadDocument } from "../../utils/agent/uploadFiles"

class DocumentsWithPaginatorStore implements DocumentsStoreInterface {
  documents: DocumentStore[]

  agent: DocumentWithPaginatorAgentInterface

  paginator: Paginator

  loader: Loader

  creationLoader: Loader

  constructor(agent: DocumentWithPaginatorAgentInterface) {
    this.documents = []
    this.loader = new Loader()
    this.creationLoader = new Loader()
    this.paginator = new Paginator(10)
    this.agent = agent
    makeAutoObservable(this)
  }

  fetchDocuments = async (...params: any) => {
    if (this.agent.getDocuments) {
      this.loader.startLoading()

      const [err, res] = await to<DocumentsPaginationRes>(
        this.agent.getDocuments(
          this.paginator.offset,
          this.paginator.limit,
          ...params
        )
      )
      runInAction(() => {
        if (res && !err) {
          const mapper = (document: DocumentModel) =>
            new DocumentStore(
              document,
              {
                loader: this.loader,
                deleteDocument: this.deleteDocument,
              },
              this.agent
            )

          this.documents = this.paginator.getPageResponse<
            DocumentModel,
            DocumentStore
          >(res, this.documents, mapper)
        } else {
          this.loader.setError()
        }
        this.loader.endLoading()
      })
    }
  }

  createDocument = async (data: UploadDocumentParams, ...params: any) => {
    this.creationLoader.startLoading()

    const document = await uploadDocument(this.creationLoader, data)

    if (document) {
      const [err, res] = await to<DocumentModel>(
        this.agent.postDocument(document, ...params)
      )
      if (res && !err) {
        this.addDocument(res)
      } else {
        this.creationLoader.setError()
      }
    }

    this.creationLoader.endLoading()
  }

  patchDocuments = async (data: UploadDocumentParams[], ...params: any) => {
    const initialDocuments = [...this.documents]
    initialDocuments.forEach((documentStore) => {
      const documentFields = data.find(
        (document) => document.id === documentStore.id
      )

      if (!documentFields) documentStore.deleteDocument(...params)
      else documentStore.patchDocument(documentFields, ...params)
    })
    data.forEach((documentFields) => {
      if (!documentFields.id) {
        this.createDocument(documentFields, ...params)
      }
    })
  }

  deleteDocument = (data: number) => {
    this.documents = this.documents.filter((document) => document.id !== data)
  }

  addDocument = (data: DocumentModel) => {
    this.documents = [
      new DocumentStore(
        data as DocumentModel,
        {
          loader: this.loader,
          deleteDocument: this.deleteDocument,
        },
        this.agent
      ),
      ...this.documents,
    ]
  }

  cleanDocuments = () => {
    this.documents = []
  }
}

export default DocumentsWithPaginatorStore
