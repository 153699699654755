import React, { useEffect } from "react"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { Box } from "kui-basic"
import { LoaderState } from "kui-crm"
import ClientPersonalInfoForm from "../../../forms/ClientPersonalInfoForm"
import Container from "../../../../../components/ui/Container"
import Loading from "../../../../../components/common/Loading"
import useClientStore from "../../../store"

function ClientPersonalInfoTab() {
  const { personalInfoStore, overviewStore } = useClientStore()

  useEffect(() => {
    if (overviewStore?.id) {
      personalInfoStore.fetchClientPersonalInfo(overviewStore.id)
      personalInfoStore.documentsStore.fetchDocuments(overviewStore.id)
    }
  }, [overviewStore?.id])

  if (personalInfoStore?.loader.isLoading) {
    return <Loading />
  }

  return (
    <Container>
      <StyledWrapper pt={3}>
        <ClientPersonalInfoForm />
      </StyledWrapper>

      <LoaderState loader={personalInfoStore.loader} onlyError />
      <LoaderState loader={personalInfoStore.actionLoader} />
    </Container>
  )
}

export default observer(ClientPersonalInfoTab)

const StyledWrapper = styled(Box)`
  border-top: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
`
