import to from "await-to-js"
import { makeAutoObservable, runInAction } from "mobx"
import { DateTime } from "luxon"
import { NoteModel, NoteTypes } from "../../types/api/notes"
import NotesStore from "./Notes"
import UserLiteStore from "../templates/UserLite"
import NotesAgent from "../../agent/Notes"
import { NoteFields } from "../../components/forms/note/NoteForm/types"

class NoteStore {
  notesStore: NotesStore

  id: number

  text: string

  type: NoteTypes

  createdAt: DateTime | null

  createdBy: UserLiteStore | null

  constructor(note: NoteModel, notesStore: NotesStore) {
    this.notesStore = notesStore
    this.id = note.id
    this.text = note.text
    this.type = note.note_type
    this.createdAt = note.created_at ? DateTime.fromISO(note.created_at) : null
    this.createdBy = note.created_by
      ? UserLiteStore.initFromLiteUserModel(note.created_by)
      : null
    makeAutoObservable(this, { notesStore: false })
  }

  delete = async () => {
    this.notesStore.actionLoader.startLoading("note removal")

    const [err] = await to(
      NotesAgent.delete(this.entity, this.entityId, this.id)
    )
    if (!err) {
      this.notesStore.deleteNoteFromList(this.id!)
    } else {
      this.notesStore.actionLoader.setError("note removal", err)
    }
    this.notesStore.actionLoader.endLoading()
  }

  edit = async (data: NoteFields) => {
    this.notesStore.actionLoader.startLoading("note changes")

    const [err] = await to(
      NotesAgent.patch(this.entity, this.entityId, this.id, data)
    )
    runInAction(() => {
      if (!err) {
        this.text = data.text
      } else {
        this.notesStore.actionLoader.setError(`patch note`, err)
      }
      this.notesStore.actionLoader.endLoading()
    })
  }

  get entity() {
    return this.notesStore.entity
  }

  get entityId() {
    return this.notesStore.entityId
  }
}

export default NoteStore
