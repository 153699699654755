import React from "react"
import { observer } from "mobx-react"
import { CompaniesRequisitesArchiveProps } from "./types"
import CompanyRequisitesRow from "../CompanyRequisitesRow"
import PaginationList from "../../../common/PaginationList"

const CompaniesRequisitesArchive = (props: CompaniesRequisitesArchiveProps) => {
  const { companiesStore, isOpen } = props
  const { archiveCompanies, archivePaginator, archiveLoader, fetchArchive } =
    companiesStore

  return (
    <PaginationList
      paginator={archivePaginator}
      loader={archiveLoader}
      fetchAll={fetchArchive}
      isOpen={isOpen}
    >
      {archiveCompanies.map((company) => (
        <CompanyRequisitesRow key={company.id} isArchive company={company} />
      ))}
    </PaginationList>
  )
}

export default observer(CompaniesRequisitesArchive)
