import React from "react"
import { observer } from "mobx-react"
import { Box } from "kui-basic"
import ExpensesContracts from "../ExpensesContracts"
import ExpensesCalendar from "../ExpensesCalendar"
import useExpensesStore from "../../../store"
import { ExpensesTabsSettingsProps } from "./types"
import ServiceCreationButton from "../../tabs/ExpensesServicesTab/ServiceCreationButton"
import ExpensesPeriodWarning from "../ExpensesPeriodWarning"

function ExpensesTabsSettings({ activeTab }: ExpensesTabsSettingsProps) {
  const { periodsStore } = useExpensesStore()
  const { selectedPeriod, availablePeriodsOptions } = periodsStore

  return (
    <>
      {!selectedPeriod?.isPrevPeriodsClosed && <ExpensesPeriodWarning />}
      {activeTab === 2 && <ServiceCreationButton />}
      {availablePeriodsOptions.length > 1 && (
        <Box mr={2}>
          <ExpensesContracts />
        </Box>
      )}
      <ExpensesCalendar />
    </>
  )
}

export default observer(ExpensesTabsSettings)
