import { makeAutoObservable } from "mobx"
import { MultistepForm } from "kui-utils"
import { TariffCreationFormFields } from "kui-crm"
import { ResourceTypes } from "kui-crm/types"
import WaterTariffsStore from "./WaterTariffsStore"
import ElectricityTariffsStore from "./ElectricityTariffsStore"
import GasTariffsStore from "./GasTariffsStore"
import { TariffsStoreInterface } from "../types/store/tariffs"
import HeatingTariffsStore from "./HeatingTariffsStore"

class TariffsStore {
  waterTariffsStore: WaterTariffsStore

  electricityTariffsStore: ElectricityTariffsStore

  gasTariffsStore: GasTariffsStore

  heatingTariffsStore: HeatingTariffsStore

  activeResource: ResourceTypes

  creationForm: MultistepForm<TariffCreationFormFields>

  constructor() {
    this.waterTariffsStore = new WaterTariffsStore()
    this.electricityTariffsStore = new ElectricityTariffsStore()
    this.gasTariffsStore = new GasTariffsStore()
    this.heatingTariffsStore = new HeatingTariffsStore()
    this.activeResource = "electricity"
    this.creationForm = new MultistepForm<TariffCreationFormFields>()
    makeAutoObservable(this)
  }

  createTariff = async (data: TariffCreationFormFields) => {
    await this.resource.createTariff(data)
  }

  setActiveResource = (resource: ResourceTypes) => {
    this.activeResource = resource
  }

  get resource(): TariffsStoreInterface {
    return this[
      `${this.activeResource}TariffsStore` as keyof TariffsStore
    ] as TariffsStoreInterface
  }
}

export default TariffsStore
