import React from "react"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import AboutPageHeader from "../../../../components/common/AboutPageHeader"
import useTariffStore from "../../store"

function TariffOverviewHeader() {
  const { overviewStore } = useTariffStore()
  const tariffGroup = overviewStore.tariffInfoStore

  const tariffInfo = [
    {
      title: tariffGroup?.startDate?.toFormat("dd.MM.yyyy") || "-",
      subtitle: "Start date",
    },
    {
      title: tariffGroup?.endDate?.toFormat("dd.MM.yyyy") || "-",
      subtitle: "End date",
    },
  ]

  return (
    <StyledAboutPageHeader
      title={tariffGroup?.codeName}
      subtitle={tariffGroup?.name}
      info={tariffInfo}
    />
  )
}

export default observer(TariffOverviewHeader)

const StyledAboutPageHeader = styled(AboutPageHeader)`
  .PageHeaderWrapper {
    padding-top: 30px;
  }
  .PageHeaderInfoItem:last-child > div {
    margin-right: 0;
  }
`
