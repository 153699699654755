import { ResourceTypes } from "kui-crm/types"
import requests from "./index"
import { PostMeterRequest } from "../types/api/meters"

const MetersAgent = {
  all: (tariff: ResourceTypes, offset: number, limit: number, params: string) =>
    requests.get(`/meters/${tariff}?offset=${offset}&limit=${limit}${params}`),
  getById: (id: number, tariff: ResourceTypes) =>
    requests.get(`/meters/${tariff}/${id}`),
  createMeter: (data: PostMeterRequest, resource: ResourceTypes) =>
    requests.post(`/meters/${resource}`, data),
  patchMeter: (id: number, data: PostMeterRequest, tariff: ResourceTypes) =>
    requests.patch(`/meters/${tariff}/${id}`, data),
  delete: (id: number, tariff: ResourceTypes) =>
    requests.delete(`/meters/${tariff}/${id}`),
  getApartmentResources: (apartmentId: number) =>
    requests.get(`/apartments/${apartmentId}/used_resources`),
  getReplaceableByApartmentId: (
    apartmentId: number,
    resource: ResourceTypes,
    params: string
  ) =>
    requests.get(
      `/meters/${resource}?apartment=${apartmentId}&archived=false${params}`
    ),
}

export default MetersAgent
