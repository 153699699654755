const changeHeaderCells = [
  {
    label: "Editable field",
    width: "40%",
  },
  {
    label: "Current value",
    width: "30%",
  },
  {
    label: "New value",
    width: "30%",
  },
]

export default changeHeaderCells
