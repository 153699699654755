import React from "react"
import { observer } from "mobx-react"
import { Divider } from "kui-basic"
import EntityPage from "../../components/common/EntityPage"
import useSCStore from "./store"
import SCHeader from "./components/SCHeader"
import SCOverviewHeader from "./components/SCOverviewHeader"
import SCInfo from "./components/SCInfo"

function ServiceContractPage() {
  const scStore = useSCStore()

  const initSCPage = (id: number) => scStore.initSCPage(id)

  return (
    <EntityPage store={scStore} initPage={initSCPage}>
      <SCHeader />
      <SCOverviewHeader />
      <Divider />
      <SCInfo />
    </EntityPage>
  )
}

export default observer(ServiceContractPage)
