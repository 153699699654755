import React, { useEffect } from "react"
import { Grid } from "kui-basic"
import { useForm } from "react-hook-form"
import { observer } from "mobx-react"
import promotionPredictorFormFields from "./fields"
import InputsGroupWithTitle from "../../../../../../components/common/InputsGroupWithTitle"
import { PromotionPredictorFormFields } from "./types"
import usePromotionStore from "../../store"
import useStore from "../../../../../../hooks/useStore"
import MapWrapper from "../../../../../../components/common/MapWrapper"

const PromotionPredictorForm = () => {
  const { descriptionStore, predictorStore, editor } = usePromotionStore()
  const {
    metroStore,
    metroStore: { getMetroParams },
  } = useStore()
  const descriptionFields = descriptionStore.fields
  const form = useForm<PromotionPredictorFormFields>({
    defaultValues: {
      area: descriptionFields?.area,
      roomsNumber: descriptionFields?.roomsNumber,
      floorCount: descriptionFields?.floorCount,
      distanceToCenter: descriptionFields?.distanceToCenter,
      buildingYear: descriptionFields?.buildingYear,
      wallMaterial: descriptionFields?.wallMaterial,
      overlapType: descriptionFields?.overlapType,
      gasLeads: descriptionFields?.gasLeads,
      metroStation: predictorStore.metroStation,
    },
  })

  const handleSubmit = (data: PromotionPredictorFormFields) => {
    editor.endEditing()
    predictorStore.updateApartmentInfo(data)
  }

  const handleMetroChange = async (name: string, id: number) => {
    const location = descriptionFields?.geolocation
    if (location) {
      const metroFields = await getMetroParams(
        location as google.maps.LatLngLiteral,
        name,
        id
      )

      form.setValue("metroStation", metroFields)
    }
  }

  const predictorFields = promotionPredictorFormFields(
    handleMetroChange,
    metroStore
  )

  useEffect(() => {
    if (editor.isAllowedToSendForm) {
      form.handleSubmit(handleSubmit, editor.blockToSendForm)()
    }
  }, [editor.isAllowedToSendForm])

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)}>
      <MapWrapper>
        <Grid container spacing={4}>
          {predictorFields.map((fieldGroup) => (
            <Grid item xs={12}>
              <InputsGroupWithTitle
                key={fieldGroup.title}
                form={form}
                isEditing={editor.isEditing}
                {...fieldGroup}
              />
            </Grid>
          ))}
        </Grid>
      </MapWrapper>
    </form>
  )
}

export default observer(PromotionPredictorForm)
