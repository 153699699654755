import React from "react"
import styled from "@emotion/styled"
import { CircularProgress, IconButton } from "kui-basic"
import { CheckIcon, UpdateIcon } from "kui-icon"
import { observer } from "mobx-react"
import { UploadFileStatusProps } from "./types"

const UploadFileStatus = (props: UploadFileStatusProps) => {
  const { file, variant } = props
  const { uploadStatus } = file

  const uploadFile = () => file.uploadFile(variant)

  switch (uploadStatus) {
    case "loading":
      return <StyledCircularProgress />
    case "success":
      return <StyledCheckIcon width={18} height={20} />
    case "failure":
      return (
        <StyledReloadButton onClick={uploadFile}>
          <StyledReloadIcon width={18} height={18} />
        </StyledReloadButton>
      )
    default:
      return null
  }
}

export default observer(UploadFileStatus)

const StyledCircularProgress = styled(CircularProgress)`
  width: 18px !important;
  height: 18px !important;
  margin-right: 8px;
`

const StyledCheckIcon = styled(CheckIcon)`
  margin-right: 8px;
  path {
    fill: ${({ theme }) => theme.palette.green.fiftyP}!important;
  }
`

const StyledReloadButton = styled(IconButton)`
  margin-right: 8px;
`

const StyledReloadIcon = styled(UpdateIcon)`
  path {
    fill: ${({ theme }) => theme.palette.red.fiftyP}!important;
  }
`
