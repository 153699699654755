import { makeAutoObservable } from "mobx"
import to from "await-to-js"
import { DateTime } from "luxon"
import { resHandler } from "kui-utils"
import {
  ResourceTypes,
  ElectricityMeterParams,
  MeterModel,
  MetersResponse,
  MeterTariffModel,
  SingleMeterParams,
} from "kui-crm"
import {
  ApartmentMeterParams,
  ApartmentMeterValues,
} from "../../../../types/store/apartmentMeters"
import ApartmentMetersAgent from "../../../../agent/ApartmentMeters"
import { ApartmentPageLiteStoreInterface } from "../../../../types/store/apartments"

class MetersStore {
  resource: ResourceTypes

  meters: ApartmentMeterParams[]

  apartmentStore: ApartmentPageLiteStoreInterface

  constructor(
    resource: ResourceTypes,
    apartmentStore: ApartmentPageLiteStoreInterface
  ) {
    this.resource = resource
    this.meters = []
    this.apartmentStore = apartmentStore
    makeAutoObservable(this)
  }

  fetchMeters = async () => {
    const apartmentId = this.apartmentStore.overviewStore.id
    const rcId = this.apartmentStore.overviewStore.actualRentalContractId

    if (apartmentId) {
      const currentDate = DateTime.now()
      const response = await to<MetersResponse>(
        ApartmentMetersAgent.getMeters(
          apartmentId,
          this.resource,
          currentDate.year,
          currentDate.month,
          rcId,
          "&archived=false"
        )
      )

      resHandler(
        response,
        this.apartmentStore.metersStore.loader,
        this.updateMeters,
        "apartment meters",
        { withEndLoading: false }
      )
    }
  }

  updateMeters = (res: MetersResponse) => {
    this.meters = res.meters.map((meter) =>
      MetersStore.getMeterParams(meter, this.resource)
    )
  }

  static getMeterParams = (
    meter: MeterModel,
    resource: ResourceTypes
  ): ApartmentMeterParams => ({
    id: meter.id,
    type: meter.type,
    number: meter.passport_number,
    nextCheck: meter.check_date ? DateTime.fromISO(meter.check_date) : null,
    startDate: meter.activation_date
      ? DateTime.fromISO(meter.activation_date)
      : null,
    resource,
    operationalAccountNumber: meter.operating_account_number || "",
    tariff: meter.tariff
      ? {
          id: meter.tariff.id,
          name: meter.tariff.code_name,
        }
      : null,
    company: null,
    passportFile: null,
    ...MetersStore.getMeterValues(meter, resource),
  })

  static getMeterValues = (
    meter: MeterModel,
    resource: ResourceTypes
  ): ApartmentMeterValues => {
    if (resource === "electricity") {
      const electricityMeter = meter as ElectricityMeterParams
      return {
        T1: MetersStore.getMeterValue(electricityMeter.values.T1),
        T2: MetersStore.getMeterValue(electricityMeter.values.T2),
        T3: MetersStore.getMeterValue(electricityMeter.values.T3),
      }
    }
    const singleMeter = meter as SingleMeterParams
    return MetersStore.getMeterValue(singleMeter)!
  }

  static getMeterValue = (meter?: MeterTariffModel) =>
    meter
      ? {
          initialValue: meter.initial_value,
          lastValue: meter.previous_value,
          maxValue: meter.max_possible_value,
        }
      : undefined
}

export default MetersStore
