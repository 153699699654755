import * as yup from "yup"

export const ManagerValuationFieldsSchema = {
  minPrice: yup.string().required("This field is required"),
  maxPrice: yup.string().required("This field is required"),
}

const ManagerValuationStepSchema = yup
  .object()
  .shape(ManagerValuationFieldsSchema)

export default ManagerValuationStepSchema
