/** @jsxImportSource @emotion/react */
import * as React from "react"
import { CircularProgress, Grid } from "kui-basic"

type LoadingProps = {
  className?: string
  height?: string | number
}

function Loading({ className, height }: LoadingProps) {
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      css={{ height }}
      className={className}
    >
      <CircularProgress />
    </Grid>
  )
}

Loading.defaultProps = {
  className: "",
  height: "90vh",
}

export default Loading
