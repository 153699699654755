import React from "react"
import _ from "lodash"
import { Link, PreviewContent } from "kui-crm"
import { Routes } from "../../../../types/route"
import TableRow from "../../../../components/ui/TableRow"
import TableCell from "../../../../components/ui/TableCell"
import UserBadge from "../../../../components/common/UserBadge"
import { ServiceContractRowProps } from "./types"
import { defaultApartmentImageURL } from "../../../../utils/content/constants"
import { PaymentStatusLabel } from "../../../../utils/content/values"

function ServiceContractRow({ contract, isDark }: ServiceContractRowProps) {
  return (
    <TableRow key={contract.id} isDark={isDark}>
      <TableCell isFirst textColor="gray">
        {contract.id}
      </TableCell>
      <TableCell isFirst>
        <Link href={`${Routes.service_contracts}/${contract.id}`}>
          {contract.number}
        </Link>
      </TableCell>
      <TableCell>
        <Link
          href={
            contract.apartment &&
            `${Routes.apartments}/${contract.apartment?.id}`
          }
        >
          <PreviewContent
            imageSrc={
              contract.apartment?.mainImageURL || defaultApartmentImageURL
            }
            description={contract.apartment?.address}
          />
        </Link>
      </TableCell>
      <TableCell>{contract.status}</TableCell>
      <TableCell>{_.capitalize(contract.type)}</TableCell>
      <TableCell>
        {contract.paidStatus ? PaymentStatusLabel[contract.paidStatus] : "-"}
      </TableCell>
      <TableCell width="21%">
        <UserBadge
          user={contract.landlord}
          name={contract.landlord?.partialName}
          weight={500}
        />
      </TableCell>
      <TableCell width="21%" isLast>
        <UserBadge
          user={contract.tenant}
          name={contract.tenant?.partialName}
          weight={500}
        />
      </TableCell>
    </TableRow>
  )
}

export default ServiceContractRow
