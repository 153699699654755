import React from "react"
import { RowWithDateProps } from "./types"
import DateWithDot from "./DateWithDot"
import RowWithApartment from "../RowWithApartment"

const RowWithDate = (props: RowWithDateProps) => {
  const { date, link, label, apartment } = props

  return (
    <RowWithApartment label={label} apartment={apartment} link={link}>
      {!!date && <DateWithDot date={date} />}
    </RowWithApartment>
  )
}

export default RowWithDate
