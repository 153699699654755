import React from "react"
import styled from "@emotion/styled"
import changeHeaderCells from "./content"
import TableCell from "../../../../ui/TableCell"

const ChangeLineTableHeader = () => (
  <tr>
    {changeHeaderCells.map((cell) => (
      <StyledTableCell width={cell.width} textColor="gray">
        {cell.label || "-"}
      </StyledTableCell>
    ))}
  </tr>
)

export default ChangeLineTableHeader

const StyledTableCell = styled(TableCell)`
  font-size: 12px;
  line-height: 14px;
  padding: 0 0 8px 0;
  div {
    min-height: unset;
  }
`
