import React from "react"
import { PendingContractRowProps } from "./types"
import RowWithDate from "../../../common/RowWithDate"
import { DynamicRoutes } from "../../../../../../types/route"

const PendingContractRow = (props: PendingContractRowProps) => {
  const { contract, contractType } = props
  const { id, apartment, number, creationDate } = contract
  const contractLink = DynamicRoutes.contract(id, contractType)

  return (
    <RowWithDate
      link={contractLink}
      date={creationDate}
      label={`#${number}`}
      apartment={apartment}
    />
  )
}

export default PendingContractRow
