import React from "react"
import { observer } from "mobx-react"
import { useNavigate } from "react-router-dom"
import PageHeader from "../../../../components/common/PageHeader"
import { Routes } from "../../../../types/route"
import useSCStore from "../../store"

function SCHeader() {
  const { deleteServiceContract, contractInfoStore } = useSCStore()
  const navigate = useNavigate()

  const handleOnBackClick = () => {
    navigate(Routes.service_contracts)
  }

  const handleDelete = async () => {
    const confirmation = window.confirm(
      "Вы уверенны, что хотите удалить контракт?"
    )
    if (confirmation && contractInfoStore.id) {
      const err = await deleteServiceContract(contractInfoStore.id)
      if (!err) handleOnBackClick()
    }
  }

  const routes = [
    { link: Routes.service_contracts, title: "Service Contracts" },
    { link: null, title: contractInfoStore.apartment?.address },
  ]

  const menuOptions = [{ label: "Delete", handleClick: handleDelete }]

  return (
    <PageHeader
      handleClick={handleOnBackClick}
      options={menuOptions}
      breadcrumbs={routes}
    />
  )
}

export default observer(SCHeader)
