import React from "react"
import { observer } from "mobx-react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Divider } from "kui-basic"
import { FormBottom } from "kui-crm"
import RentalContractInfoFormSchema from "./schema"
import BasicConditionsFields from "./BasicConditionsFields"
import RoommatesFields from "./RoommatesFields"
import useRCsStore from "../../store"
import SCCommonFields from "../../components/RCCommonFields"
import { RentalContractInfoFields } from "../CreateRentalContractForm/types"

function RCInfoForm() {
  const { creationForm } = useRCsStore()
  const { fields, nextStep, prevStep, updateFormFields } = creationForm

  const form = useForm<RentalContractInfoFields>({
    resolver: yupResolver(RentalContractInfoFormSchema),
    defaultValues: {
      ...fields,
    },
  })

  const handleSubmit = (data: RentalContractInfoFields) => {
    nextStep()
    updateFormFields(data)
  }

  return (
    <form data-testid="form_step_2" onSubmit={form.handleSubmit(handleSubmit)}>
      <SCCommonFields form={form} />

      <BasicConditionsFields form={form} />
      <Divider />
      <RoommatesFields form={form} />
      <FormBottom
        withPrevStep
        variant="next"
        label="Next"
        handlePrevClick={prevStep}
      />
    </form>
  )
}

export default observer(RCInfoForm)
