import * as React from "react"
import { ChangeEvent, useEffect, useState } from "react"
import { css, Theme } from "@emotion/react"
import styled from "@emotion/styled"
import { Button, CircularProgress } from "kui-basic"
import { CheckIcon, EditIcon } from "kui-icon"
import { FormProps } from "../../../../../../../types/ui/form"

type StyleProps = {
  theme?: Theme
  disable?: boolean
  isEditing?: boolean
}

type WrapperProps = {
  isEditing: boolean
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

function Wrapper({ isEditing, ...otherProps }: WrapperProps) {
  return <div {...otherProps} />
}

const inputStyle = ({ theme }: StyleProps) => css`
  border: none;
  background: transparent;
  width: 100%;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  caret-color: ${theme?.palette.brand.main};
  color: ${theme?.palette.grey.seventy};
`

const StyledInput = styled.input`
  ${inputStyle};
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`

const StyledWrapper = styled(Wrapper)`
  display: flex;
  background: ${({ theme }) => theme.palette.background.light1};
  border-radius: 66px;
  border: 1px solid
    ${({ theme, isEditing }) =>
      isEditing ? theme.palette.brand.main : theme.palette.grey.fifteenB};
  position: relative;
  max-width: 200px;
`

const StyledDate = styled(Wrapper)`
  display: inline;
  ${inputStyle};
  width: auto;
`

const DataWrapper = styled.div`
  width: 50%;
  padding: 6px 10px 6px 12px;
  border-right: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
`

const DateWrapper = styled.div`
  width: 50%;
  padding: 6px 12px 6px 32px;
`

const ButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  left: calc(50% - 12px);
  top: 4px;
`

type EditMetersDataProps = {
  value: number
  date?: string | null
  isLoading: boolean
  disabled?: boolean
  type: any
} & FormProps

function EditMetersData({
  isLoading,
  value,
  date,
  handleSubmit,
  disabled,
  type,
  ...other
}: EditMetersDataProps) {
  const [isEditing, setIsEditing] = React.useState(false)
  const [inputValue, setInputValue] = useState<number | string>(value)
  const buttonHandler = () => {
    if (isEditing) {
      handleSubmit(inputValue)
    }
    setIsEditing((prev) => !prev)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value ? Number(e.target.value) : ""
    setInputValue(newValue)
  }

  useEffect(() => {
    setInputValue(value)
  }, [value])

  return (
    <StyledWrapper isEditing={isEditing} {...other}>
      <DataWrapper>
        <StyledInput
          data-testid={`counter_value_${type}`}
          type="number"
          disabled={!isEditing}
          value={inputValue}
          onInput={handleChange}
          placeholder={String(value)}
        />
      </DataWrapper>
      <ButtonWrapper>
        {isLoading ? (
          <StyledCircularProgress />
        ) : (
          <StyledButton
            isCircle
            data-testid={`counter_btn_${type}`}
            onClick={buttonHandler}
            variant={isEditing && !isLoading ? "primary" : "gray"}
            disabled={disabled}
          >
            {isEditing ? <CheckIcon /> : <EditIcon width={14} height={14} />}
          </StyledButton>
        )}
      </ButtonWrapper>
      <DateWrapper>
        <StyledDate data-testid={`counter_date_${type}`} isEditing={!isEditing}>
          {date}
        </StyledDate>
      </DateWrapper>
    </StyledWrapper>
  )
}

export default EditMetersData

const StyledButton = styled(Button)`
  width: 24px;
  height: 24px;
`

const StyledCircularProgress = styled(CircularProgress)`
  width: 26px !important;
  height: 26px !important;
`
