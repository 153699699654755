import styled from "@emotion/styled"
import MenuPanel from "../../ui/MenuPanel"

const MenuPanelOfList = styled(MenuPanel)`
  padding: 16px 0 12px 0;
  position: sticky;
  top: 120px;
  background: white;
  h2 {
    font-size: 18px;
    line-height: 24px;
  }
`

export default MenuPanelOfList
