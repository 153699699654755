import React from "react"
import { useWatch } from "react-hook-form"
import { Grid } from "kui-basic"
import { PlusIcon, CloseIcon } from "kui-icon"
import { observer } from "mobx-react"
import {
  InputByType,
  HeadingWithButton,
  MeterFeaturesFormFields,
} from "kui-crm"
import { getInventoryMeterFields } from "kui-crm_actions"
import _ from "lodash"
import { ApartmentMeterFieldsProps } from "./types"
import useModalProps from "../../../../../../../hooks/useModalProps"
import ApartmentMeterModal from "../ApartmentMeterModal"
import InspectionMeterPhoto from "../../InspectionMeterPhoto"
import getMeterLabel from "../../../../../../../utils/service/getMeterLabel"

const ApartmentMeterFields = (props: ApartmentMeterFieldsProps) => {
  const { resource, index, remove, append, form, disabled } = props
  const modalProps = useModalProps()
  const meterType = useWatch({
    control: form.control,
    name: `meters.${index}.type`,
  })
  const label = _.capitalize(getMeterLabel(resource, meterType))

  const meterFields = getInventoryMeterFields({ resource, meterType })

  const handleAdd = () => {
    modalProps.handleOpen()
  }

  const handleRemove = () => {
    remove(index)
  }

  const addMeter = (data: MeterFeaturesFormFields) => {
    append({
      ...data,
      resource,
      images: [],
    })
  }

  return (
    <>
      <HeadingWithButton
        title={label}
        onClick={index === 0 ? handleAdd : handleRemove}
        icon={
          index === 0 ? (
            <PlusIcon width={10} height={10} />
          ) : (
            <CloseIcon width={8} height={8} />
          )
        }
        disabled={disabled}
      />
      <Grid container spacing={3} alignItems="center">
        {meterFields.map(({ name, ...field }) => (
          <Grid item key={name} xs={2}>
            <InputByType
              name={`meters.${index}.${name}`}
              form={form}
              {...field}
              disabled={disabled}
            />
          </Grid>
        ))}
        <Grid item xs={2}>
          <InspectionMeterPhoto
            disabled={disabled}
            name={`meters.${index}.images`}
            form={form}
          />
        </Grid>
      </Grid>
      <ApartmentMeterModal
        resource={resource}
        handleSubmit={addMeter}
        {...modalProps}
      />
    </>
  )
}

export default observer(ApartmentMeterFields)
