import { makeAutoObservable } from "mobx"
import { DateTime } from "luxon"
import to from "await-to-js"
import { Loader, resHandler, toNumber } from "kui-utils"
import { PromotionChannelsLinksFormFields } from "../../forms/PromotionChannelsForm/PromotionChannelsLinksFields/types"
import ApartmentPromotionAgent from "../../../../../../agent/ApartmentPromotion"
import ApartmentPageStore from "../../../../store/ApartmentPageStore"
import {
  GetChannelsBodyResponse,
  PatchChannelsBodyRequest,
} from "../../types/api/promotionChannelsAPI"
import { PromotionChannelsFormFields } from "../../forms/PromotionChannelsForm/types"

class PromotionChannelsContentStore {
  minPrice: number | null = null

  maxPrice: number | null = null

  currentPrice: number | null = null

  predictorPrice: number | null = null

  stepPrice: number | null = null

  stepFrequency: number = 1

  startAdvertisingDate: DateTime | null = null

  endAdvertisingDate: DateTime | null = null

  initialCommission: number | null = null

  securityPayment: number | null = null

  propertyPortalsLinks: PromotionChannelsLinksFormFields | null = null

  loader: Loader

  apartmentStore: ApartmentPageStore

  constructor(apartmentStore: ApartmentPageStore) {
    this.loader = new Loader()
    this.apartmentStore = apartmentStore
    makeAutoObservable(this)
  }

  fetchChannelsContent = async () => {
    const apartmentId = this.apartmentStore.overviewStore.id
    if (apartmentId) {
      const response = await to(
        ApartmentPromotionAgent.getPromotionChannels(apartmentId)
      )

      resHandler(response, this.loader, this.updateChannelsFromResponse)
    }
  }

  patchPromotionChannels = async (data: PromotionChannelsFormFields) => {
    const apartmentId = this.apartmentStore.overviewStore.id
    if (apartmentId) {
      this.loader.startLoading("edit promotion Channels")

      const body = PromotionChannelsContentStore.getBodyForPatchChannels(data)
      const response = await to(
        ApartmentPromotionAgent.patchPromotionChannels(apartmentId, body)
      )

      resHandler(response, this.loader, this.updateChannelsFromResponse)
    }
  }

  updateChannelsFromResponse = (data: GetChannelsBodyResponse) => {
    this.minPrice = Number(data.price_estimate_min)
    this.maxPrice = Number(data.price_estimate_max)
    this.currentPrice = Number(data.current_promotion_price)
    this.stepPrice = Number(data.price_step_value)
    this.stepFrequency = data.step_frequency || 1
    this.startAdvertisingDate = data.start_advertising_date
      ? DateTime.fromISO(data.start_advertising_date)
      : null
    this.endAdvertisingDate = data.end_advertising_date
      ? DateTime.fromISO(data.end_advertising_date)
      : null
    this.initialCommission = data.commission_percent
    this.securityPayment = data.deposit_percent
    this.propertyPortalsLinks = {
      cian: { turnOn: data.cian_tern_on, link: data.cian_link },
      avito: { turnOn: data.avito_tern_on, link: data.avito_link },
      yandex: { turnOn: data.yandex_tern_on, link: data.yandex_link },
      maroom: { turnOn: data.maroom_tern_on },
    }
  }

  updatePredictorPrice = (price: number) => {
    this.predictorPrice = price
  }

  getStepPriceValue = (fields: PromotionChannelsFormFields) => {
    const { minPrice, maxPrice, startAdvertisingDate, endAdvertisingDate } =
      fields
    const { stepFrequency } = fields
    const minPriceValue = toNumber(minPrice)
    const maxPriceValue = toNumber(maxPrice)

    if (
      minPriceValue &&
      maxPriceValue &&
      startAdvertisingDate &&
      endAdvertisingDate
    ) {
      const daysDelta = endAdvertisingDate.diff(
        startAdvertisingDate,
        "days"
      ).days
      const priceDelta = maxPriceValue - minPriceValue

      if (priceDelta <= 0 || daysDelta < (stepFrequency || 1)) return 0

      const stepCount = Math.floor(daysDelta / (stepFrequency || 1))

      return Math.ceil(priceDelta / stepCount / 100) * 100
    }
    return null
  }

  static getBodyForPatchChannels = (data: PromotionChannelsFormFields) =>
    ({
      price_estimate_max: toNumber(data.maxPrice)?.toString(),
      price_estimate_min: toNumber(data.minPrice)?.toString(),
      step_frequency: data.stepFrequency,
      start_advertising_date: data.startAdvertisingDate?.toISODate(),
      end_advertising_date: data.endAdvertisingDate?.toISODate(),
      commission_percent: data.initialCommission,
      deposit_percent: data.securityPayment,
      cian_link: data.cian?.link,
      cian_tern_on: data.cian?.turnOn,
      avito_link: data.avito?.link,
      avito_tern_on: data.avito?.turnOn,
      yandex_link: data.yandex?.link,
      yandex_tern_on: data.yandex?.turnOn,
      maroom_tern_on: data.maroom?.turnOn,
    } as PatchChannelsBodyRequest)
}

export default PromotionChannelsContentStore
