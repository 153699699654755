import React from "react"
import { CleanIcon } from "kui-icon"
import { observer } from "mobx-react"
import { PendingImpairmentsCardProps } from "./types"
import DashboardListCard from "../../common/DashboardListCard"
import PendingImpairmentsRow from "./PendingImpairmentsRow"

const PendingImpairmentsCard = (props: PendingImpairmentsCardProps) => {
  const { impairmentsStore } = props
  const { impairments, value, paginator, loader, fetchImpairments } =
    impairmentsStore

  return (
    <DashboardListCard
      value={value}
      label="Pending impairments"
      icon={<CleanIcon />}
      paginator={paginator}
      loader={loader}
      fetchAll={fetchImpairments}
    >
      {impairments.map((impairment) => (
        <PendingImpairmentsRow key={impairment.id} impairment={impairment} />
      ))}
    </DashboardListCard>
  )
}

export default observer(PendingImpairmentsCard)
