import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { Loader, resHandler } from "kui-utils"
import ApartmentOwnerStore from "./ApartmentOwner"
import ApartmentProxyStore from "./ApartmentProxy"
import ApartmentOwnershipAgent from "../../../../agent/ApartmentOwnership"
import {
  ApartmentOwnerModel,
  ApartmentProxyModel,
  GetApartmentOwnersResponse,
  GetApartmentProxiesResponse,
} from "../../types/api/apartmentOwnershipAPI"
import {
  ApartmentOwnerFormFields,
  ApartmentOwnershipFields,
  ApartmentProxyFormFields,
} from "../../forms/ownership/ApartmentOwnershipForm/types"
import { callCRUDBasedOnFormActions } from "../../../../utils/service/functions"
import DocumentsWithPaginatorStore from "../../../../store/templates/DocumentsWithPaginatorStore"

class ApartmentOwnershipStore {
  owners: ApartmentOwnerStore[]

  proxies: ApartmentProxyStore[]

  documentsStore: DocumentsWithPaginatorStore

  cadastralNumber: string | null

  loader: Loader

  actionLoader: Loader

  constructor() {
    this.owners = []
    this.proxies = []
    this.cadastralNumber = null
    this.documentsStore = new DocumentsWithPaginatorStore(
      ApartmentOwnershipAgent
    )
    this.loader = new Loader()
    this.actionLoader = new Loader()
    makeAutoObservable(this)
  }

  getOwnershipInfo = async (apartmentId: number) => {
    this.loader.startLoading()

    await Promise.allSettled([
      this.fetchOwners(apartmentId),
      this.fetchProxies(apartmentId),
      this.documentsStore.fetchDocuments(apartmentId),
    ])

    this.loader.endLoading()
  }

  fetchOwners = async (apartmentId: number) => {
    const response = await to<GetApartmentOwnersResponse>(
      ApartmentOwnershipAgent.getOwners(apartmentId)
    )
    runInAction(() => {
      resHandler(
        response,
        this.loader,
        (resp) => {
          this.owners = resp.map(
            (owner) =>
              new ApartmentOwnerStore(owner, {
                loader: this.loader,
                delete: this.deleteOwner,
              })
          )
          this.cadastralNumber = resp[0]?.cadastral_number
        },
        "fetch owners",
        { withEndLoading: false }
      )
    })
  }

  fetchProxies = async (apartmentId: number) => {
    const response = await to<GetApartmentProxiesResponse>(
      ApartmentOwnershipAgent.getProxies(apartmentId)
    )
    runInAction(() => {
      resHandler(
        response,
        this.loader,
        (resp) => {
          this.proxies = resp.map(
            (proxy) =>
              new ApartmentProxyStore(proxy, {
                loader: this.loader,
                delete: this.deleteProxy,
              })
          )
        },
        "fetch proxies",
        { withEndLoading: false }
      )
    })
  }

  patchOwnershipInfo = async (
    apartmentId: number,
    data: ApartmentOwnershipFields
  ) => {
    this.actionLoader.startLoading("ownership info changes")

    await Promise.allSettled([
      this.patchOwnersInfo(apartmentId, data),
      this.patchProxiesInfo(apartmentId, data.proxies),
      this.documentsStore.patchDocuments(data.documents, apartmentId),
    ])

    this.actionLoader.endLoading()
  }

  patchOwnersInfo = async (
    apartmentId: number,
    data: ApartmentOwnershipFields
  ) => {
    await callCRUDBasedOnFormActions(
      this.owners,
      data.owners,
      this.createOwner,
      apartmentId,
      data.cadastralNumber
    )
  }

  patchProxiesInfo = async (
    apartmentId: number,
    data: ApartmentProxyFormFields[]
  ) => {
    await callCRUDBasedOnFormActions(
      this.proxies,
      data,
      this.createProxy,
      apartmentId
    )
  }

  createOwner = async (
    data: ApartmentOwnerFormFields,
    apartmentId: number,
    cadastralNumber?: string
  ) => {
    const body = ApartmentOwnerStore.getBodyForPatchOwner(data, cadastralNumber)
    const response = await to(
      ApartmentOwnershipAgent.postOwner(body, apartmentId)
    )

    resHandler(response, this.actionLoader, this.addOwner, "owner creation", {
      withEndLoading: false,
    })
  }

  deleteOwner = (id: number) => {
    this.owners = this.owners.filter((owner) => owner.id !== id)
  }

  addOwner = (owner: ApartmentOwnerModel) => {
    this.owners = [
      ...this.owners,
      new ApartmentOwnerStore(owner, {
        loader: this.actionLoader,
        delete: this.deleteOwner,
      }),
    ]
  }

  createProxy = async (data: ApartmentProxyFormFields, apartmentId: number) => {
    const body = ApartmentProxyStore.getBodyForPatchProxy(data)
    const response = await to(
      ApartmentOwnershipAgent.postProxy(body, apartmentId)
    )

    resHandler(response, this.actionLoader, this.addProxy, "proxy creation", {
      withEndLoading: false,
    })
  }

  deleteProxy = (id: number) => {
    this.proxies = this.proxies.filter((proxy) => proxy.id !== id)
  }

  addProxy = (proxy: ApartmentProxyModel) => {
    this.proxies = [
      ...this.proxies,
      new ApartmentProxyStore(proxy, {
        loader: this.actionLoader,
        delete: this.deleteProxy,
      }),
    ]
  }
}

export default ApartmentOwnershipStore
