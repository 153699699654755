import React from "react"
import _ from "lodash"
import { Caption, Grid } from "kui-basic"
import styled from "@emotion/styled"
import { Link, PreviewContent } from "kui-crm"
import { DynamicRoutes } from "../../../../../../types/route"
import { ObjectWithoutAppraisalRowProps } from "./types"
import { defaultApartmentImageURL } from "../../../../../../utils/content/constants"
import DateWithDot from "../../../common/RowWithDate/DateWithDot"

const ObjectWithoutAppraisalRow = (props: ObjectWithoutAppraisalRowProps) => {
  const { object } = props
  const { apartment, type, date } = object
  const objectLink = DynamicRoutes.inspections(apartment.id)
  const label = _.capitalize(`${type} inspection`)

  return (
    <StyledLink href={objectLink}>
      <StyledRow container alignItems="center" justify="space-between">
        <StyledPreviewContent
          imageSrc={apartment.previewImage || defaultApartmentImageURL}
          description={apartment.address}
          label={
            <Caption size="xs" color="fiftyP">
              {label}
            </Caption>
          }
        />
        {!!date && <DateWithDot date={date} />}
      </StyledRow>
    </StyledLink>
  )
}

export default ObjectWithoutAppraisalRow

const StyledLink = styled(Link)`
  &:not(&:last-of-type) > div {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
  }
`

const StyledRow = styled(Grid)`
  padding: 8px 0;
  flex-wrap: nowrap;
`

const StyledPreviewContent = styled(PreviewContent)`
  max-width: calc(100% - 100px);
  p {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
`
