import { InputParams } from "kui-crm"

const userBankTaxInfoFieldsGroup: InputParams<any>[] = [
  {
    label: "Full name",
    name: "bankInfo.fullName",
    size: 6,
    disabled: true,
  },
  {
    label: "Individual tax number",
    name: "bankInfo.ITN",
    size: 6,
  },
]

export default userBankTaxInfoFieldsGroup
