import { DateTime } from "luxon"
import { RentalTypes, ServiceContractModel, ContractStatuses } from "kui-crm"
import ApartmentSimpleStore from "./Apartment"
import UserLiteStore from "./UserLite"
import { MetersPayersParams } from "../../types/store/apartments"
import { RentPeriodTypes } from "../../pages/ServiceContractsPage/forms/CreateServiceContractForm/types"

class ServiceContractLiteStore {
  id: number

  number: string

  apartment: ApartmentSimpleStore

  appraisal: number | null

  inspectionPeriod: number

  landlord: UserLiteStore

  type: RentalTypes

  rentPeriodType: RentPeriodTypes = []

  status: ContractStatuses

  startDate: DateTime

  metersPayers: MetersPayersParams

  constructor(contract: ServiceContractModel) {
    this.id = contract.id
    this.number = contract.number
    this.apartment = new ApartmentSimpleStore(contract.apartment)
    this.appraisal = null
    this.inspectionPeriod = Number(contract.inspection_interval_month)
    this.landlord = UserLiteStore.initFromLiteUserModel(contract.owner)
    this.type = contract.rental_type
    this.startDate = DateTime.fromISO(contract.start_date)
    this.status = contract.status
    this.metersPayers = {
      ...(contract?.gas_counters_paid_by && {
        gas: contract.gas_counters_paid_by,
      }),
      ...(contract.electricity_counters_paid_by && {
        electricity: contract.electricity_counters_paid_by,
      }),
      ...(contract.heating_counters_paid_by && {
        heating: contract.heating_counters_paid_by,
      }),
      ...(contract.water_counters_paid_by && {
        water: contract.water_counters_paid_by,
      }),
    }
    this.rentPeriodType = contract.long_term_type || []
  }
}

export default ServiceContractLiteStore
