import * as yup from "yup"

const ClientDocumentsPersonalSchema = yup.object().shape({})

const ClientDocumentsCompanySchema = yup.object().shape({
  companyInfo: yup.object({
    file: yup.mixed().required("This field is required"),
  }),
  confirmationOfAuthority: yup.object({
    file: yup.mixed().required("This field is required"),
  }),
})

export { ClientDocumentsPersonalSchema, ClientDocumentsCompanySchema }
