import React from "react"
import { Grid } from "kui-basic"
import { FormWrapper, ModalProps, Modal } from "kui-crm"
import { observer } from "mobx-react"
import { useForm } from "react-hook-form"
import { dashboardCardsFields } from "./content"
import DashboardSettingRow from "../../../components/common/DashboardSettingRow"
import useDashboardStore from "../../../store"
import { HousingInspectorDashboardSettings } from "../../../types/store/housingInspectorDashboard"

const HousingInspectorSettingsModal = (props: ModalProps) => {
  const { handleClose } = props
  const { housingInspectorDashboard } = useDashboardStore()
  const form = useForm<HousingInspectorDashboardSettings>({
    values: { ...housingInspectorDashboard?.settings },
  })

  const handleSubmit = (data: HousingInspectorDashboardSettings) => {
    housingInspectorDashboard?.editSettings(data)
    handleClose()
  }

  return (
    <Modal title="Dashboard settings" {...props}>
      <FormWrapper
        form={form}
        onSubmit={form.handleSubmit(handleSubmit)}
        label="Save"
      >
        <Grid container spacing={2}>
          {dashboardCardsFields.map((field) => (
            <DashboardSettingRow key={field.name} form={form} {...field} />
          ))}
        </Grid>
      </FormWrapper>
    </Modal>
  )
}

export default observer(HousingInspectorSettingsModal)
