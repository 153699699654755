import React from "react"
import { observer } from "mobx-react"
import useRCStore from "../../store"
import ContractClosingButtons from "../../../../components/common/ContractClosingButtons"

const RCClosingButtons = () => {
  const { contractInfoStore } = useRCStore()
  const {
    dateOfLeaving,
    canBeClosed,
    closingProgress,
    closeRentalContract,
    saveDateOfLeavingApartment,
  } = contractInfoStore

  return (
    <ContractClosingButtons
      defaultDate={dateOfLeaving}
      canBeClosed={canBeClosed}
      closingProgress={closingProgress}
      closeContract={closeRentalContract}
      setCheckoutDate={saveDateOfLeavingApartment}
      label="check-out"
    />
  )
}

export default observer(RCClosingButtons)
