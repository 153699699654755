import { makeAutoObservable } from "mobx"
import { ChangeReasonParams } from "../../../types/common"
import { TariffCompanyModel } from "../types/api/tariffAPI"

class TariffCompanyStore {
  id: number

  logo: string | null

  name: string

  reason: ChangeReasonParams | null

  constructor(object: TariffCompanyModel) {
    this.id = object.id
    this.logo = object.logo || null
    this.name = object.name
    this.reason = null
    makeAutoObservable(this)
  }
}

export default TariffCompanyStore
