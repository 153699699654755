import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { DateTime } from "luxon"
import { Loader, MultistepForm, toNumber } from "kui-utils"
import {
  FileBodyRequest,
  CompanyLiteParams,
  InputFileWithVisibilityFields,
} from "kui-crm"
import { ServiceEditingFields } from "../../../components/forms/apartmentExpenses/ServiceEditingForm/types"
import { PaymentTypes } from "../../../types/common"
import ApartmentServicesAgent from "../../../agent/ApartmentServices"
import UserLiteStore from "../../templates/UserLite"
import { matchesAPISubjectRoles } from "../../../utils/content/matches"
import FileStore from "../../templates/File"
import {
  getChangedValue,
  setFileFromFileWithVisibility,
} from "../../../utils/agent/reqBodyFormat"
import { ApartmentLinkParams } from "../../../types/store/apartments"
import ApartmentsStore from "../../lites/ApartmentsStore"
import {
  DurationType,
  PatchServiceRequest,
  ServiceDocumentVariants,
  ServiceModel,
  ServiceRegistryModel,
  ServicesAPITypes,
} from "../../../types/api/apartmentExpenses"
import {
  ApartmentServicesInterface,
  ApartmentServiceStatuses,
} from "../../../types/store/apartmentExpenses"
import {
  getUpdatedFileParams,
  uploadNewFile,
} from "../../../utils/agent/uploadFiles"

export const ApartmentServiceFormStages = ["info", "photo", "manuals"] as const

class ApartmentServiceStore {
  servicesStore: ApartmentServicesInterface | null

  id: number

  value: number | string

  title: string

  purpose: string

  company: CompanyLiteParams | null

  type: ServicesAPITypes | null

  paymentMadeBy: UserLiteStore | null

  refundFrom: UserLiteStore | null

  paymentType: PaymentTypes | null

  durationType: DurationType | null

  invoice: FileStore | null

  paymentOrder: FileStore | null

  chargeFrom: DateTime | null

  chargeTill: DateTime | null

  periodDate: DateTime | null

  comment: string

  commentAuthor: UserLiteStore | null

  documentNumber: string = ""

  loader: Loader

  editingForm: MultistepForm<
    ServiceEditingFields,
    typeof ApartmentServiceFormStages
  >

  reportNumber: string

  apartmentId?: number | null

  apartment?: ApartmentLinkParams | null

  periodIsClosed?: boolean

  status: ApartmentServiceStatuses | null

  constructor(
    servicesStore: ApartmentServicesInterface | null,
    service: ServiceModel | ServiceRegistryModel,
    apartmentId?: number | null,
    status?: ApartmentServiceStatuses
  ) {
    const fromAPIRoles = {
      owner: UserLiteStore.initFromLiteUserModel(service.owner, "landlord"),
      renter: UserLiteStore.initFromLiteUserModel(service.renter, "tenant"),
      maroom: UserLiteStore.initForMaroom(),
    }
    const chargeFrom = service.period_info?.charge_from
    const periodDate = "period_date" in service ? service.period_date : null

    this.reportNumber = service.report_number || ""
    this.apartmentId = apartmentId || service.apartment?.id
    this.apartment = service.apartment
      ? ApartmentsStore.getApartmentLinkParams(
          service.apartment,
          !!service.renter
        )
      : null
    this.servicesStore = servicesStore
    this.id = service.id
    this.value = Number(service.value)
    this.title = service.name
    this.purpose = service.purpose || ""
    this.company = service.company
      ? {
          id: service.company.id,
          name: service.company.name,
        }
      : null
    this.type = service.line_type || null
    this.durationType = service.period_info?.duration_type || null
    this.paymentMadeBy = service.payment_made_by
      ? fromAPIRoles[service.payment_made_by as keyof typeof fromAPIRoles]
      : null
    this.refundFrom = service.refund_from
      ? fromAPIRoles[service.refund_from as keyof typeof fromAPIRoles]
      : null
    this.paymentType = service.payment_type || null
    this.chargeFrom = chargeFrom ? DateTime.fromISO(chargeFrom) : null
    this.chargeTill = service.period_info?.charge_till
      ? DateTime.fromISO(service.period_info.charge_till)
      : null
    this.periodDate = periodDate ? DateTime.fromISO(periodDate) : null
    this.comment = service.comment ?? ""
    this.commentAuthor = service.comment_author
      ? UserLiteStore.initFromLiteUserModel(service.comment_author)
      : null
    this.loader = new Loader()
    this.invoice = service.invoice
      ? FileStore.initFromDocumentModel(service.invoice)
      : null
    this.paymentOrder = service.payment_order
      ? FileStore.initFromDocumentModel(service.payment_order)
      : null
    this.documentNumber = service.file_number || ""
    this.editingForm = new MultistepForm<
      ServiceEditingFields,
      typeof ApartmentServiceFormStages
    >(null, ApartmentServiceFormStages)
    this.periodIsClosed = service.period_is_closed
    this.status = status || null
    this.updateFormFields()
    makeAutoObservable(this, { servicesStore: false })
  }

  patchService = async (data: Partial<ServiceEditingFields>) => {
    this.loader.startLoading("service changes")
    this.servicesStore?.serviceLoader?.startLoading()

    if (this.apartmentId) {
      const file =
        typeof data.file === "undefined"
          ? undefined
          : await getUpdatedFileParams(this.loader, data.file)
      const body = this.getPatchServiceBody(data, file)

      const [err, res] = await to(
        ApartmentServicesAgent.patch(this.apartmentId, this.id, body)
      )

      if (res && !err) {
        this.updateService(res)
      } else {
        this.loader.setError(`patch service`, err)
        this.servicesStore?.serviceLoader?.setError("patch service", err)
      }
    }

    this.loader.endLoading()
    this.servicesStore?.serviceLoader?.endLoading()
  }

  updateValue = async (price: number) => {
    await this.patchService({ price })
    this.value = price
  }

  updateReport = async (reportNumber: string) => {
    await this.patchService({ reportNumber })
    this.reportNumber = reportNumber
  }

  updateFormFields = () => {
    this.editingForm.setFormFields({
      ...this,
      price: this.value,
      name: this.title,
      paymentMadeBy: this.paymentMadeBy?.role,
      refundFrom: this.refundFrom?.role,
      chargeTill: this.chargeTill?.toJSDate(),
      chargeFrom: this.chargeFrom?.toJSDate(),
      servicesStore: null,
      editingForm: null,
      file: this.invoice,
    })
  }

  deleteService = async () => {
    if (this.apartmentId) {
      this.loader.startLoading("service removal")

      const [err] = await to(
        ApartmentServicesAgent.delete(this.apartmentId, this.id)
      )

      if (err) {
        this.loader.setError("service removal", err)
        this.servicesStore?.serviceLoader?.setError("service removal", err)
      } else {
        this.servicesStore?.deleteService(this.id, this.type)
      }
      this.loader.endLoading()
    }
  }

  abortService = async (date: Date) => {
    if (this.apartmentId) {
      const formattedDate = DateTime.fromJSDate(date)
      this.loader.startLoading("service aborting")

      const body = { abort_after: formattedDate.toFormat("MM.yyyy")! }
      const [err] = await to(
        ApartmentServicesAgent.abortService(this.apartmentId, this.id, body)
      )

      runInAction(() => {
        if (err) {
          this.loader.setError("service aborting", err)
          this.servicesStore?.serviceLoader?.setError("service aborting", err)
        } else {
          this.chargeTill = formattedDate
          if (this.chargeTill.diffNow("months").months > -1) {
            this.servicesStore?.deleteService(this.id, "pending")
          }
        }
        this.loader.endLoading()
      })
    }
  }

  addPaymentOrder = async (data: InputFileWithVisibilityFields) => {
    this.loader.startLoading("payment order")

    if (this.apartmentId) {
      const file = await uploadNewFile(this.loader, data)
      const body = { payment_order: file }

      const [err, res] = await to(
        ApartmentServicesAgent.patch(this.apartmentId, this.id, body)
      )

      if (res && !err) {
        this.updateService(res)
      } else {
        this.loader.setError(`patch service`, err)
      }
      this.loader.endLoading()
    }
  }

  deleteDocument = async (variant: ServiceDocumentVariants) => {
    if (this.apartmentId) {
      this.loader.startLoading("remove document")

      const body =
        variant === "invoice" ? { invoice: null } : { payment_order: null }

      const [err, res] = await to(
        ApartmentServicesAgent.patch(this.apartmentId, this.id, body)
      )

      if (res && !err) {
        this.updateService(res)
      } else {
        this.loader.setError(`patch service`, err)
      }
      this.loader.endLoading()
    }
  }

  updateService = (service: ServiceModel) => {
    this.value = Number(service.value)
    this.title = service.name || this.title
    this.purpose = service.purpose || this.purpose
    this.company = service.company
      ? {
          id: service.company.id,
          name: service.company.name,
        }
      : null
    const fromAPIRoles = {
      owner: service.owner
        ? UserLiteStore.initFromLiteUserModel(service.owner, "landlord")
        : null,
      renter: service.renter
        ? UserLiteStore.initFromLiteUserModel(service.renter, "tenant")
        : null,
      maroom: UserLiteStore.initForMaroom(),
    }
    this.paymentMadeBy =
      fromAPIRoles[service.payment_made_by as keyof typeof fromAPIRoles] ||
      this.paymentMadeBy
    this.refundFrom =
      fromAPIRoles[service.refund_from as keyof typeof fromAPIRoles] ||
      this.refundFrom
    this.paymentType = service.payment_type || this.paymentType
    this.chargeFrom = service.period_info?.charge_from
      ? DateTime.fromISO(service.period_info.charge_from)
      : this.chargeFrom
    this.chargeTill = service.period_info?.charge_till
      ? DateTime.fromISO(service.period_info.charge_till)
      : null
    this.comment = String(service.comment)
    this.documentNumber = service.file_number
    this.invoice = service.invoice
      ? FileStore.initFromDocumentModel(service.invoice)
      : null
    this.paymentOrder = service.payment_order
      ? FileStore.initFromDocumentModel(service.payment_order)
      : null
    this.type = service.line_type || null
    this.reportNumber = service.report_number || ""
    this.durationType = service.period_info?.duration_type || null
    this.periodIsClosed = service.period_is_closed
    this.updateFormFields()
  }

  getPatchServiceBody = (
    data: Partial<ServiceEditingFields>,
    invoice?: FileBodyRequest | null
  ) =>
    ({
      company: getChangedValue(data.company?.id, this.company?.id),
      name: getChangedValue(data.name, this.title),
      purpose: getChangedValue(data.purpose, this.purpose),
      value: getChangedValue(
        data.price,
        this.value,
        toNumber(data.price)?.toString()
      ),
      comment: getChangedValue(data.comment, this.comment),
      payment_made_by: getChangedValue(
        data.paymentMadeBy,
        this.paymentMadeBy?.role,
        matchesAPISubjectRoles[data.paymentMadeBy!]
      ),
      refund_from: getChangedValue(
        data.refundFrom,
        this.refundFrom?.role,
        matchesAPISubjectRoles[data.refundFrom!]
      ),
      payment_type: getChangedValue(data.paymentType, this.paymentType),
      period_info: this.getPatchServicePeriodBody(data),
      file_number: getChangedValue(data.documentNumber, this.documentNumber),
      report_number: getChangedValue(data.reportNumber, this.reportNumber),
      invoice,
    } as PatchServiceRequest)

  getPatchServicePeriodBody = (data: Partial<ServiceEditingFields>) => {
    const newPeriodInfo = [
      data.chargeFrom ? DateTime.fromJSDate(data.chargeFrom) : null,
      data.durationType,
      data.chargeTill ? DateTime.fromJSDate(data.chargeTill) : null,
    ]
    const oldPeriodInfo = [this.chargeFrom, this.durationType, this.chargeTill]
    const formattedPeriodInfo = {
      charge_from: data.chargeFrom
        ? DateTime.fromJSDate(data.chargeFrom).toFormat("MM.yyyy")
        : undefined,
      duration_type: data.durationType,
      charge_till: data.chargeTill
        ? DateTime.fromJSDate(data.chargeTill).toFormat("MM.yyyy")
        : undefined,
    }

    const value = getChangedValue(
      newPeriodInfo,
      oldPeriodInfo,
      formattedPeriodInfo
    )

    return data.chargeFrom || data.durationType || data.chargeTill
      ? value
      : undefined
  }

  get period() {
    if (this.type === "open_ended") {
      return "Open-ended"
    }
    if (this.chargeFrom && this.chargeTill) {
      return `${this.chargeFrom.toFormat("MM.yyyy")}-${this.chargeTill.toFormat(
        "MM.yyyy"
      )}`
    }
    return this.chargeFrom?.toFormat("MM.yyyy")
  }

  static getServiceFileBody = (data: InputFileWithVisibilityFields) => {
    const formData = new FormData()

    setFileFromFileWithVisibility(
      formData,
      data,
      "attachment",
      "name",
      "",
      true
    )
    return formData
  }
}

export default ApartmentServiceStore
