import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { observer } from "mobx-react"
import { ModalProps, ResourceTypes } from "kui-crm"

import { Routes } from "../../../../types/route"
import MeterCreationModal from "../../../../components/modals/MeterCreationModal"
import TableSearchWithCreating from "../../../../components/common/TableSearchWithCreating"
import useMetersStore from "../../store"
import MetersTable from "../MetersTable"
import MenuPanelOfList from "../../../../components/common/MenuPanelOfList"

const tabs = ["Electricity", "Water", "Gas", "Heating"]
const tabPanels = [
  <MetersTable resource="electricity" />,
  <MetersTable resource="water" />,
  <MetersTable resource="gas" />,
  <MetersTable resource="heating" />,
]
const paths = [
  Routes.electricityMeteringDevices,
  Routes.waterMeteringDevices,
  Routes.gasMeteringDevices,
  Routes.heatingMeteringDevices,
]

function MetersTabPanel() {
  const metersStore = useMetersStore()
  const { resource, setActiveResource, activeResource } = metersStore
  const { paginator, filter } = resource

  const location = useLocation().pathname
  const activeTab = Math.max(
    paths.findIndex((name) => name === location),
    0
  )

  useEffect(() => {
    setActiveResource(tabs[activeTab].toLowerCase() as ResourceTypes)
  }, [])

  const handleTabChange = (index: number) => {
    setActiveResource(tabs[index].toLowerCase() as ResourceTypes)
  }

  return (
    <MenuPanelOfList
      label="Metering devices"
      activeTab={Number(activeTab)}
      tabs={tabs}
      tabPanels={tabPanels}
      paths={paths}
      handleChange={handleTabChange}
      endComponent={
        <TableSearchWithCreating filter={filter} paginator={paginator}>
          {(props: ModalProps) => (
            <MeterCreationModal
              // @ts-ignore
              resource={activeResource as ResourceTypes}
              formStore={resource.creationForm}
              createMeter={resource.createMeter}
              metersStore={metersStore}
              {...props}
            />
          )}
        </TableSearchWithCreating>
      }
    />
  )
}

export default observer(MetersTabPanel)
