import * as yup from "yup"
import { numberRegExp } from "../../../../utils/content/regexp"

const UserBankInfoSchemaParams = {
  bankName: yup.string().required("This field is required"),
  SWIFT: yup
    .string()
    .min(8, "SWIFT must be at least 8 characters long")
    .max(11, "SWIFT must be no more 11 characters long")
    .required("This field is required"),
  BIC: yup
    .string()
    .min(8, "Must be at least 8 characters")
    .max(10, "Must be no more 10 characters")
    .matches(numberRegExp, "Only numbers are allowed for input")
    .required("This field is required"),
  correspondentAccount: yup
    .string()
    .min(20, "Must be 20 characters")
    .max(20, "Must be 20 characters")
    .matches(numberRegExp, "Only numbers are allowed for input")
    .required("This field is required"),
  account: yup
    .string()
    .min(20, "Must be 20 characters")
    .max(20, "Must be 20 characters")
    .matches(numberRegExp, "Only numbers are allowed for input")
    .required("This field is required"),
}

const UserBankTaxInfoSchemaParams = {
  fullName: yup.string().required("This field is required"),
  ITN: yup
    .string()
    .matches(numberRegExp, "Only numbers are allowed for input")
    .min(10, "ITN must be at least 10 characters long")
    .max(12, "ITN must be no more 12 characters long")
    .required("This field is required"),
}

export const UserBankInfoSchema = yup.object().shape({
  bankInfo: yup.object(UserBankInfoSchemaParams),
})

export const UserBankTaxInfoSchema = yup.object().shape({
  bankInfo: yup.object(UserBankTaxInfoSchemaParams),
})
