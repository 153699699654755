import React from "react"
import { observer } from "mobx-react"
import { MeterFeaturesForm, WaterMeterTypes } from "kui-crm"
import useExpensesStore from "../../../store"
import { EditSingleMeterFormProps } from "./types"

function EditSingleMeterForm(props: EditSingleMeterFormProps) {
  const { handleSubmit, resource, meter } = props
  const { overviewStore } = useExpensesStore()

  const defaultValues = {
    type: meter?.type as WaterMeterTypes,
    tariff: meter?.tariff,
    company: meter?.company,
    number: meter?.number,
    startDate: meter?.startDate,
    nextCheck: meter?.nextCheck,
    initialValue: Number(meter?.tariffValues?.initialValue),
    operationalAccountNumber: meter?.operationalAccountNumber,
    noTariff: meter?.noTariff,
    withAutoSending: meter?.withAutoSending,
  }

  return (
    <MeterFeaturesForm
      resource={resource}
      handleSubmit={handleSubmit}
      operatingAccount={meter?.operationalAccountNumber}
      administrativeCompany={overviewStore.managementCompany}
      apartmentId={overviewStore.id}
      defaultValues={defaultValues}
      formBottomProps={{
        label: "Save",
      }}
    />
  )
}

export default observer(EditSingleMeterForm)
