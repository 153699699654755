import React from "react"
import { observer } from "mobx-react"
import ApartmentProxyFields from "../ApartmentProxyFields"
import { ApartmentProxiesFieldsProps } from "./types"

function ApartmentProxiesFields(props: ApartmentProxiesFieldsProps) {
  const { fields, ...other } = props

  return (
    <>
      {fields.map((proxy, index) => (
        <ApartmentProxyFields
          key={fields[index].key}
          index={index}
          {...other}
        />
      ))}
    </>
  )
}

export default observer(ApartmentProxiesFields)
