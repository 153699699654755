import React from "react"

const AverageValueIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.36641 13.7838C7.95163 13.7838 7.57521 13.7057 7.23715 13.5494C6.89908 13.3903 6.63061 13.1616 6.43175 12.8633C6.23289 12.5622 6.13345 12.1986 6.13345 11.7724C6.13345 11.3974 6.20732 11.0934 6.35504 10.8605C6.50277 10.6247 6.70021 10.44 6.94737 10.3065C7.19453 10.173 7.46726 10.0736 7.76556 10.0082C8.06669 9.94004 8.36925 9.88606 8.67323 9.84629C9.07095 9.79515 9.3934 9.7568 9.64056 9.73123C9.89056 9.70282 10.0724 9.65594 10.186 9.5906C10.3025 9.52526 10.3607 9.41163 10.3607 9.24969V9.2156C10.3607 8.79515 10.2457 8.46844 10.0156 8.23549C9.78828 8.00254 9.44311 7.88606 8.98004 7.88606C8.49993 7.88606 8.12351 7.99117 7.85078 8.2014C7.57806 8.41163 7.38629 8.63606 7.2755 8.87469L6.32095 8.53378C6.49141 8.13606 6.71868 7.8264 7.00277 7.60481C7.2897 7.38038 7.6022 7.22413 7.94027 7.13606C8.28118 7.04515 8.61641 6.99969 8.94595 6.99969C9.15618 6.99969 9.39766 7.02526 9.67039 7.0764C9.94595 7.12469 10.2116 7.22555 10.4673 7.37896C10.7258 7.53237 10.9403 7.7639 11.1107 8.07356C11.2812 8.38322 11.3664 8.79799 11.3664 9.31788V13.6304H10.3607V12.744H10.3096C10.2414 12.8861 10.1278 13.038 9.96868 13.2C9.80959 13.3619 9.59794 13.4997 9.33374 13.6133C9.06953 13.727 8.74709 13.7838 8.36641 13.7838ZM8.51982 12.8804C8.91754 12.8804 9.25277 12.8023 9.5255 12.646C9.80107 12.4898 10.0085 12.288 10.1477 12.0409C10.2897 11.7937 10.3607 11.5338 10.3607 11.2611V10.3406C10.3181 10.3917 10.2244 10.4386 10.0795 10.4812C9.93743 10.521 9.77266 10.5565 9.58516 10.5878C9.4005 10.6162 9.2201 10.6417 9.04396 10.6645C8.87067 10.6844 8.73004 10.7014 8.62209 10.7156C8.36073 10.7497 8.11641 10.8051 7.88914 10.8818C7.6647 10.9557 7.48289 11.0679 7.34368 11.2184C7.20732 11.3662 7.13914 11.5679 7.13914 11.8236C7.13914 12.173 7.2684 12.4372 7.52692 12.6162C7.78828 12.7923 8.11925 12.8804 8.51982 12.8804Z"
      fill="#ED713C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.24993 5.61273C6.24993 5.33659 6.47379 5.11273 6.74993 5.11273H10.7499C11.0261 5.11273 11.2499 5.33659 11.2499 5.61273C11.2499 5.88887 11.0261 6.11273 10.7499 6.11273H6.74993C6.47379 6.11273 6.24993 5.88887 6.24993 5.61273Z"
      fill="#ED713C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.74993 16.0697C12.6546 16.0697 15.8199 12.9043 15.8199 8.99969C15.8199 5.09503 12.6546 1.92969 8.74993 1.92969C4.84528 1.92969 1.67993 5.09503 1.67993 8.99969C1.67993 12.9043 4.84528 16.0697 8.74993 16.0697ZM8.74993 17.5697C13.483 17.5697 17.3199 13.7328 17.3199 8.99969C17.3199 4.26661 13.483 0.429688 8.74993 0.429688C4.01685 0.429688 0.179932 4.26661 0.179932 8.99969C0.179932 13.7328 4.01685 17.5697 8.74993 17.5697Z"
      fill="#ED713C"
    />
  </svg>
)

export default AverageValueIcon
