import { DropIcon } from "kui-icon"
import React from "react"
import { observer } from "mobx-react"
import DashboardListCard from "../../common/DashboardListCard"
import { AutoMetersCardProps } from "./types"
import AutoMeterRow from "./AutoMeterRow"

const AutoMetersCard = (props: AutoMetersCardProps) => {
  const { metersStore } = props
  const { meters, value, paginator, loader, fetchAutoMeters } = metersStore

  return (
    <DashboardListCard
      value={value}
      label="Auto metering devices"
      icon={<DropIcon />}
      paginator={paginator}
      loader={loader}
      fetchAll={fetchAutoMeters}
    >
      {meters.map((meter) => (
        <AutoMeterRow key={meter.id} meter={meter} />
      ))}
    </DashboardListCard>
  )
}

export default observer(AutoMetersCard)
