import * as React from "react"
import styled from "@emotion/styled"
import { LoupeIcon } from "kui-icon"
import { ImageCardProps } from "./types"

function ImageCardWithLoupe({
  url,
  onClick,
  index,
  className,
  ...otherProps
}: ImageCardProps) {
  const handleClick = () => {
    onClick(index)
  }

  return (
    <Wrapper
      className={
        className ? `${className} ImageCard-wrapper` : "ImageCard-wrapper"
      }
      onClick={handleClick}
      {...otherProps}
    >
      <StyledImage src={url} alt="image" />
      <Backdrop>
        <LoupeIcon />
      </Backdrop>
    </Wrapper>
  )
}

export default ImageCardWithLoupe

const Wrapper = styled.div`
  overflow: hidden;
  border-radius: 8px;
  position: relative;
  width: 56px;
  height: 56px;
  cursor: pointer;
  user-select: none;
`

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  background-color: rgba(25, 25, 25, 0.4);
  transition: all ease-out 0.3s;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    opacity: 1;
  }
`
