import { makeAutoObservable, runInAction } from "mobx"
import _ from "lodash"
import to from "await-to-js"
import { Loader, resHandler } from "kui-utils"
import {
  ApartmentPhotoModel,
  PatchImageParams,
} from "../../../../../types/api/apartmentPhotoAPI"
import ApartmentImagesAgent from "../../../../../../../agent/ApartmentImages"
import { DragImageParams } from "../../../../../types/store/apartment"
import ApartmentImagesStore from "./ApartmentImages"
import { ImageStoreInterface } from "../../../../../../../types/store/image"

class ApartmentImageStore implements ImageStoreInterface {
  isPreviewLoading: boolean

  isPublicLoading: boolean

  id: number

  activeNumber: number

  priority: number

  previewPriority: number

  url: string

  name: string

  smallImageUrl: string

  mediumImageUrl: string

  largeImageUrl: string

  lastSelectedNumber: number

  dragImage: DragImageParams | null

  isPublic: boolean

  isPreview: boolean

  isMain: boolean

  imagesStore: ApartmentImagesStore

  loader: Loader

  constructor(image: ApartmentPhotoModel, imagesStore: ApartmentImagesStore) {
    this.activeNumber = 0
    this.id = image.id
    this.priority = image.priority
    this.previewPriority = image.preview_priority
    this.url = image.image_medium_url
    this.name = image.image_name
    this.smallImageUrl = image.image_small_url
    this.mediumImageUrl = image.image_medium_url
    this.largeImageUrl = image.image_large_url
    this.lastSelectedNumber = 0
    this.dragImage = null
    this.isPublic = image.public
    this.isPreview = image.preview
    this.isMain = !!image.is_main
    this.isPreviewLoading = false
    this.isPublicLoading = false
    this.imagesStore = imagesStore
    this.loader = new Loader()
    makeAutoObservable(this, { imagesStore: false })
  }

  setLastSelectedNumber = (num: number) => {
    this.lastSelectedNumber = num
  }

  setActiveNumber = (num: number) => {
    this.activeNumber = num
  }

  setDragImage = (id: number, index: number) => {
    this.dragImage = { id, index }
  }

  setPriority = (priority: number, isPreview: boolean) => {
    if (isPreview) {
      this.previewPriority = priority
    } else {
      this.priority = priority
    }
  }

  patchImage = async (
    data: Partial<PatchImageParams>,
    withLoading: boolean = true
  ) => {
    const apartmentId = this.imagesStore.apartmentStore.overviewStore.id

    if (apartmentId) {
      if (withLoading) this.loader.startLoading("image changes")
      this.loader.cleanError()

      const formData = new FormData()
      _.forOwn(data, (value, key) => formData.append(key, String(value)))

      const response = await to(
        ApartmentImagesAgent.patch(formData, apartmentId, this.id)
      )
      runInAction(() => {
        resHandler(
          response,
          this.loader,
          this.updateImageStore,
          "patch image",
          {
            withEndLoading: withLoading,
          }
        )
      })
    }
  }

  changePreviewPriority = async (priority: number) => {
    this.isPreviewLoading = true
    if (priority === 1) {
      await this.makeMain()
    } else {
      await this.patchImage(
        {
          preview_priority: priority || 10,
        },
        false
      )
    }
    runInAction(() => {
      this.isPreviewLoading = false
    })
  }

  changePublicPriority = async (priority: number) => {
    this.isPublicLoading = true
    await this.patchImage(
      {
        priority,
      },
      false
    )
    runInAction(() => {
      this.isPublicLoading = false
    })
  }

  delete = async (withoutActualization?: boolean) => {
    const apartmentId = this.imagesStore.apartmentStore.overviewStore.id

    if (apartmentId) {
      const isMainPhoto = this.isMain

      this.loader.startLoading("image removal")
      const [err] = await to(ApartmentImagesAgent.delete(apartmentId, this.id))

      if (err) {
        this.loader.setError("photo removal", err)
      } else {
        this.imagesStore.deleteImage(this.id)
        if (isMainPhoto) {
          this.imagesStore.makeMainNextImage()
        }
        if (!withoutActualization) {
          this.imagesStore.actualizePublicPriority()
          this.imagesStore.actualizePreviewPriority()
        }
      }
      this.loader.endLoading()

      return err
    }
    return null
  }

  makeMain = async () => {
    this.imagesStore.makeAllImagesNotMain()
    await this.patchImage(
      {
        preview: true,
        public: true,
        preview_priority: 1,
      },
      false
    )
    this.imagesStore.updatePreviewImage(this.smallImageUrl)
  }

  makePreview = async (withLoading: boolean = true) => {
    const priority =
      this.imagesStore.previewImages.length + (this.activeNumber || 1)
    await this.patchImage(
      {
        preview: true,
        preview_priority: priority,
      },
      withLoading
    )

    if (priority === 1) {
      this.imagesStore.updatePreviewImage(this.smallImageUrl)
    }
  }

  makeNotPreview = async () => {
    const isMainPhoto = this.isMain
    await this.patchImage({ preview: false })
    if (isMainPhoto) {
      this.imagesStore.makeMainNextImage()
    }
    this.imagesStore.actualizePreviewPriority()
  }

  makePublic = async (withLoading: boolean = true) => {
    await this.patchImage(
      {
        public: true,
        priority:
          this.imagesStore.publicImages.length + (this.activeNumber || 1),
      },
      withLoading
    )
  }

  makeNotPublic = async () => {
    const isMainPhoto = this.isMain

    await this.patchImage({
      public: false,
      preview: false,
    })
    if (isMainPhoto) {
      this.imagesStore.makeMainNextImage()
    }
    this.imagesStore.actualizePublicPriority()
    this.imagesStore.actualizePreviewPriority()
  }

  setMain = (isMain: boolean) => {
    this.isMain = isMain
  }

  updateImageStore = (res: ApartmentPhotoModel) => {
    this.activeNumber = 0
    this.id = res.id ?? this.id
    this.priority = res.priority ?? this.priority
    this.previewPriority = res.preview_priority ?? this.previewPriority
    this.url = res.image_medium_url ?? this.url
    this.name = res.image_name ?? this.name
    this.smallImageUrl = res.image_small_url ?? this.smallImageUrl
    this.mediumImageUrl = res.image_medium_url ?? this.mediumImageUrl
    this.largeImageUrl = res.image_large_url ?? this.largeImageUrl
    this.lastSelectedNumber = 0
    this.dragImage = null
    this.isPublic = res.public
    this.isPreview = res.preview
    this.isMain = !!res.is_main
  }
}

export default ApartmentImageStore
