import { DependencyList, RefObject, useEffect } from "react"
import _ from "lodash"

const useScrollWithBottomSpacing = (
  spacingToBottom: number,
  delay: number,
  callback: () => void,
  element?: RefObject<HTMLElement> | null,
  deps?: DependencyList
) => {
  const handleScroll = (e: any) => {
    const target = element ? e.target : e.target.documentElement
    const targetHeight = element ? e.target.clientHeight : window.innerHeight

    if (
      target.scrollHeight - (target.scrollTop + targetHeight) <
      spacingToBottom
    ) {
      callback()
    }
  }

  useEffect(() => {
    const throttle = _.throttle(handleScroll, delay)
    const target = element?.current || document

    target.addEventListener("scroll", throttle)

    return () => {
      target.removeEventListener("scroll", throttle)
    }
  }, [delay, ...(deps || [])])
}

export default useScrollWithBottomSpacing
