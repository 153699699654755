import { makeAutoObservable } from "mobx"
import { MeterTypes, ResourceTypes, uploadNewFile } from "kui-crm"
import { DateTime } from "luxon"
import { Loader, resHandler } from "kui-utils"
import to from "await-to-js"
import { ApartmentLinkParams } from "../../../../types/store/apartments"
import FileStore from "../../../../store/templates/File"
import {
  MeterLinkModel,
  MeterLinkValuesModel,
  MeterReadingModel,
} from "../../types/api/metersReadings"
import ApartmentsStore from "../../../../store/lites/ApartmentsStore"
import { MeterReadingFormFields } from "../../../../components/forms/meters/MeterReadingForm/types"
import RegistriesAgent from "../../../../agent/Registries"
import MetersReadingRegistryStore from "./MetersReadingRegistryStore"
import { MeterLiteParams } from "../../../../types/store/meters"
import MetersReadingStore from "../../../../store/shared/meters/MetersReadingStore"

class MeterReadingStore {
  id: number

  apartment: ApartmentLinkParams

  meter: MeterLiteParams

  resource: ResourceTypes

  checkDate: DateTime | null

  values?: number[]

  file: FileStore | null

  loader: Loader

  metersReadingStore: MetersReadingRegistryStore

  constructor(
    meterReading: MeterReadingModel,
    metersReadingStore: MetersReadingRegistryStore
  ) {
    this.id = meterReading.id
    this.apartment = ApartmentsStore.getApartmentLinkParams(
      meterReading.apartment
    )
    this.meter = MeterReadingStore.getMeterParams(meterReading.meter)
    this.resource = meterReading.meter.resource_type
    this.checkDate = meterReading.check_date
      ? DateTime.fromISO(meterReading.check_date)
      : null
    this.values = Object.keys(meterReading.meter.values).map((key) =>
      Number(meterReading.meter.values[key as keyof MeterLinkValuesModel])
    )
    this.file = meterReading.document
      ? FileStore.initFromDocumentModel(meterReading.document)
      : null
    this.loader = new Loader()
    this.metersReadingStore = metersReadingStore
    makeAutoObservable(this)
  }

  editMeterReading = async (data: MeterReadingFormFields) => {
    this.loader.startLoading()

    const file = await uploadNewFile(this.loader, data.file)
    const body = MetersReadingStore.getMeterReadingRequestBody(data, file)

    const response = await to(RegistriesAgent.editMeterReading(this.id, body))

    resHandler(response, this.loader, this.updateMeterReading)
  }

  deleteMeterReading = async () => {
    this.loader.startLoading()

    const response = await to(RegistriesAgent.deleteMeterReading(this.id))

    resHandler(response, this.loader, () => {
      this.metersReadingStore.removeMeterReadingFromTable(this.id)
    })
  }

  updateMeterReading = (meterReading: Partial<MeterReadingModel>) => {
    if (meterReading.apartment)
      this.apartment = ApartmentsStore.getApartmentLinkParams(
        meterReading.apartment
      )
    if (meterReading.meter)
      this.meter = MeterReadingStore.getMeterParams(meterReading.meter)
    if (meterReading.meter?.resource_type)
      this.resource = meterReading.meter.resource_type
    if (meterReading.check_date)
      this.checkDate = DateTime.fromISO(meterReading.check_date)
    if (meterReading.meter?.values)
      this.values = Object.keys(meterReading.meter.values).map((key) =>
        Number(meterReading.meter?.values[key as keyof MeterLinkValuesModel])
      )
    if (meterReading.document)
      this.file = FileStore.initFromDocumentModel(meterReading.document)
  }

  static getMeterParams = (meter: MeterLinkModel) => ({
    id: meter.id,
    name: meter.passport_number,
    type: meter.type as MeterTypes,
  })
}

export default MeterReadingStore
