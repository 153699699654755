import React from "react"
import { Modal } from "kui-crm"
import ActivateContractForm from "../../forms/contacts/ActivateContractForm"
import { ActivateContractModalProps } from "./types"
import { ActivateContractFormFields } from "../../forms/contacts/ActivateContractForm/types"

const ActivateContractModal = (props: ActivateContractModalProps) => {
  const { open, handleClose, handleSubmit } = props

  const onConfirm = (data: ActivateContractFormFields) => {
    if (handleClose) handleClose()
    handleSubmit(data)
  }

  return (
    <Modal
      data-testid="activateModal"
      title="Activate contract"
      open={open}
      handleClose={handleClose}
    >
      <ActivateContractForm handleSubmit={onConfirm} />
    </Modal>
  )
}

export default ActivateContractModal
