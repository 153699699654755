import React from "react"
import _ from "lodash"
import { Link, PreviewContent } from "kui-crm"
import TableCell from "../../ui/TableCell"
import TableCellWithLabel from "../TableCellWithLabel"
import UserBadge from "../UserBadge"
import TableRow from "../../ui/TableRow"
import { ContractRowProps } from "./types"
import { Routes } from "../../../types/route"
import TwoCaptionsBlock from "../TwoCaptionsBlock"
import RentalContractLiteStore from "../../../store/templates/RentalContractLite"
import TextOverflow from "../TextOverflow"

const defaultImageURL = "/images/LivingAreaSmall.svg"

function ContractRow({
  isDark,
  contract,
  href,
  withApartment,
}: ContractRowProps) {
  const rentType =
    "rentPeriodType" in contract
      ? contract.rentPeriodType?.map((type) => _.capitalize(type)).join(", ") ||
        "-"
      : _.capitalize(contract.type || "-")

  return (
    <TableRow isDark={isDark} key={_.uniqueId()}>
      <TableCell isFirst textColor="gray" width="3%">
        {contract.id}
      </TableCell>
      <TableCell width={withApartment ? "10%" : "34%"}>
        <Link href={href}>
          <TextOverflow weight={500}>{contract.number}</TextOverflow>
        </Link>
      </TableCell>
      {withApartment && contract.apartment && (
        <TableCell width="24%">
          <Link href={`${Routes.apartments}/${contract.apartment.id}`}>
            <PreviewContent
              imageSrc={contract.apartment.mainImageURL || defaultImageURL}
              content={
                <TwoCaptionsBlock
                  bottom="Object"
                  spacing={4}
                  top={contract.apartment.address}
                  topSize="s"
                  topWeight={500}
                  bottomSize="xs"
                  bottomWeight={400}
                />
              }
            />
          </Link>
        </TableCell>
      )}
      <TableCellWithLabel
        title={
          (contract as RentalContractLiteStore).rentCost?.toLocaleString() ||
          "-"
        }
        label="Cost"
        width="6%"
      />
      <TableCellWithLabel title="10%" label="Rate" width="4%" />
      <TableCellWithLabel title={rentType} label="Type" width="8%" />
      <TableCellWithLabel title={contract.status} label="Status" width="5%" />
      <TableCell width="20%">
        <UserBadge
          weight={500}
          user={contract.landlord}
          name={contract.landlord.partialName}
          role="landlord"
        />
      </TableCell>
      <TableCell width="20%" isLast>
        {contract instanceof RentalContractLiteStore && contract.tenant && (
          <UserBadge
            weight={500}
            name={contract.tenant.partialName}
            user={contract.tenant}
            role="tenant"
          />
        )}
      </TableCell>
    </TableRow>
  )
}

export default ContractRow
