import { makeAutoObservable, runInAction } from "mobx"
import { Loader, Paginator, resHandler } from "kui-utils"
import to from "await-to-js"
import ApartmentStore from "./ApartmentStore"
import DashboardAgent from "../../../../agent/Dashboard"
import { ApartmentDashboardModel } from "../../types/api/apartments"
import DashboardPageStore from "../DashboardPageStore"

class ApartmentsStore {
  value: number | null

  apartments: ApartmentStore[]

  paginator: Paginator

  loader: Loader

  archiveApartments: ApartmentStore[]

  archivePaginator: Paginator

  archiveLoader: Loader

  dashboardStore: DashboardPageStore

  constructor(dashboardStore: DashboardPageStore) {
    this.dashboardStore = dashboardStore
    this.value = null
    this.apartments = []
    this.archiveApartments = []
    this.paginator = new Paginator(5)
    this.loader = new Loader()
    this.archivePaginator = new Paginator(5)
    this.archiveLoader = new Loader()
    makeAutoObservable(this)
  }

  fetchApartments = async () => {
    this.loader.startLoading()

    const response = await to(
      DashboardAgent.getApartmentsServices(
        this.paginator.limit,
        this.paginator.offset,
        this.dashboardStore.periodFilter
      )
    )

    runInAction(() => {
      resHandler(response, this.loader, (res) => {
        this.value = res.count
        this.apartments = this.paginator.getPageResponse(
          res,
          this.apartments,
          this.getApartmentParams
        )
      })
    })
  }

  fetchArchive = async () => {
    this.archiveLoader.startLoading()

    const response = await to(
      DashboardAgent.getApartmentsServices(
        this.archivePaginator.limit,
        this.archivePaginator.offset,
        this.dashboardStore.periodFilter,
        true
      )
    )

    runInAction(() => {
      resHandler(response, this.archiveLoader, (res) => {
        this.archiveApartments = this.archivePaginator.getPageResponse(
          res,
          this.archiveApartments,
          this.getApartmentParams
        )
      })
    })
  }

  removeFromApartmentsList = (notifyId: number) => {
    this.apartments = this.apartments.filter(
      (apartment) => apartment.notifyId !== notifyId
    )
    if (this.value) {
      this.value -= 1
      if (this.apartments.length < 4) {
        this.fetchApartments()
      }
    }
  }

  removeFromArchiveList = (notifyId: number) => {
    this.archiveApartments = this.archiveApartments.filter(
      (apartment) => apartment.notifyId !== notifyId
    )
    this.fetchApartments()
  }

  getApartmentParams = (apartment: ApartmentDashboardModel) =>
    new ApartmentStore(apartment, this)
}

export default ApartmentsStore
