import { DateTime } from "luxon"
import { resHandler, Loader } from "kui-utils"
import ApartmentServicesStore from "../../../../store/shared/apartmentService/ApartmentServicesStore"

export const getLastOpenedPeriod = async (
  apartmentId: number,
  loader: Loader,
  callback: (date: DateTime) => void
) => {
  loader.startLoading("last open period")
  const response = await ApartmentServicesStore.getLastOpenedPeriod(apartmentId)
  resHandler(response, loader, ({ period }) => {
    if (period) callback(period)
  })

  loader.endLoading()
}
