import { makeAutoObservable } from "mobx"
import { Loader, resHandler } from "kui-utils"
import to from "await-to-js"
import {
  AppraisalType,
  getInspectionFeedbackStatus,
  InspectionFeedbackModel,
} from "kui-crm"
import DashboardAgent from "../../../../agent/Dashboard"
import { ApartmentLinkParams } from "../../../../types/store/apartments"
import { RejectedAppraisalModel } from "../../types/api/inspections"
import RejectedAppraisalsStore from "./RejectedAppraisalsStore"
import ApartmentsStore from "../../../../store/lites/ApartmentsStore"

class RejectedAppraisalStore {
  notifyId: number

  id: number

  apartment: ApartmentLinkParams

  type: AppraisalType

  rejectedComment: string

  inspectionsStore: RejectedAppraisalsStore

  loader: Loader

  constructor(
    inspectionNotify: RejectedAppraisalModel,
    inspectionsStore: RejectedAppraisalsStore
  ) {
    const { id, appraisal } = inspectionNotify
    this.notifyId = id
    this.id = appraisal.id
    this.apartment = ApartmentsStore.getApartmentLinkParams(appraisal.apartment)
    this.type = appraisal.appraisal_for
    this.rejectedComment = appraisal.comment
    this.inspectionsStore = inspectionsStore
    this.loader = new Loader()
    makeAutoObservable(this)
  }

  archiveInspection = async () => {
    this.loader.startLoading()

    const response = await to(
      DashboardAgent.archiveDashboardItem(this.notifyId)
    )

    resHandler(response, this.loader, () => {
      this.inspectionsStore.removeFromInspectionsList(this.id)
    })
  }

  restoreInspection = async () => {
    this.loader.startLoading()

    const response = await to(
      DashboardAgent.unArchiveDashboardItem(this.notifyId)
    )

    resHandler(response, this.loader, () => {
      this.inspectionsStore.removeFromArchiveList(this.id)
    })
  }

  static getFeedbackParams = (feedback: InspectionFeedbackModel | null) => {
    if (!feedback) return null

    return {
      status: getInspectionFeedbackStatus(feedback),
      comment: feedback.comment,
    }
  }
}

export default RejectedAppraisalStore
