import React from "react"
import styled from "@emotion/styled"
import TableRow from "../../../../../../../../components/ui/TableRow"
import TableCell from "../../../../../../../../components/ui/TableCell"
import { ArchiveCounterTariffRowProps } from "./types"
import TableRowDivider from "../../../../../../../../components/common/TableRowDivider"

function ArchiveCounterTariffRow({
  tariff,
  isLast,
}: ArchiveCounterTariffRowProps) {
  if (!tariff) return null

  return (
    <>
      <StyledRow data-testid="archive_counter_tariff_row">
        <StyledCell data-testid="counter_title" align="right">
          {tariff.title}
        </StyledCell>
        <TableCell colSpan={3} />
        <StyledCell data-testid="counter_value">{tariff.value}</StyledCell>
        <TableCell colSpan={3} />
        <StyledCell data-testid="tariff_value">
          {tariff.tariffValue
            ? `${tariff.tariffValue.toLocaleString()} ₽`
            : "-"}
        </StyledCell>
        <StyledCell data-testid="total_price">
          {tariff.totalPrice ? `${tariff.totalPrice.toLocaleString()} ₽` : "-"}
        </StyledCell>
        <TableCell />
      </StyledRow>
      {isLast && <TableRowDivider />}
    </>
  )
}

export default ArchiveCounterTariffRow

const StyledRow = styled(TableRow)`
  td:first-child {
    padding-left: 27px;
    padding-right: 42px;
  }
  td:last-child {
    padding-right: 15px;
  }
`

const StyledCell = styled(TableCell)`
  color: ${({ theme }) => theme.palette.grey.fiftyP};
`
