import React, { useEffect } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { Box, Grid } from "kui-basic"
import { InputWithController } from "kui-complex"
import { observer } from "mobx-react"
import { yupResolver } from "@hookform/resolvers/yup"
import ImpairmentsInspectionHeader from "./ImpairmentsInspectionHeader"
import { ImpairmentInfoFormFields } from "./ImpairmentInfoFields/types"
import { ImpairmentsInspectionInfoFields } from "./types"
import Container from "../../../../../../components/ui/Container"
import useInspectionStore from "../../store"
import ImpairmentsInspectionStore from "../../store/variations/ImpairmentsInspectionStore"
import ImpairmentsInspectionInfoSchema from "./schema"
import ImpairmentsContent from "./ImpairmentsContent"

const ImpairmentsInspectionInfo = () => {
  const { inspectionInfo, editor, patchInspection } = useInspectionStore()
  const inspection = inspectionInfo as ImpairmentsInspectionStore
  const disabled = !editor.isEditing

  const form = useForm<ImpairmentsInspectionInfoFields>({
    defaultValues: {
      dropboxLink: inspection?.dropboxLink,
      damages: inspection.damages,
      wear: inspection.wear,
    },
    resolver: yupResolver(ImpairmentsInspectionInfoSchema),
  })
  const {
    fields: damages,
    append: appendDamage,
    remove: removeDamage,
  } = useFieldArray({
    control: form.control,
    name: "damages",
  })
  const {
    fields: wear,
    append: appendWear,
    remove: removeWear,
  } = useFieldArray({
    control: form.control,
    name: "wear",
  })

  const addImpairment = (data: ImpairmentInfoFormFields) => {
    if (data.type === "damages") appendDamage(data)
    else appendWear(data)
  }

  useEffect(() => {
    if (editor.isAllowedToSendForm) {
      form.handleSubmit(patchInspection, editor.blockToSendForm)()
    }
  }, [editor.isAllowedToSendForm])

  return (
    <>
      <ImpairmentsInspectionHeader form={form} handleSubmit={addImpairment} />
      <Container>
        <ImpairmentsContent
          wear={wear}
          damages={damages}
          removeWear={removeWear}
          removeDamage={removeDamage}
          form={form}
          disabled={disabled}
        />

        <Box pt={3} pb={3}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <InputWithController
                isTextArea
                label="FAQ"
                name="faq"
                form={form}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  )
}

export default observer(ImpairmentsInspectionInfo)
