import React from "react"
import { Grid } from "kui-basic"
import { observer } from "mobx-react"
import InputsGroupWithTitle from "../../../../components/common/InputsGroupWithTitle"
import { SCInfoEditableFieldsProps } from "./types"
import useRCStore from "../../store"
import getRCInfoFieldsGroups from "../RCInfoFields/fields"

function RCInfoEditableFields({ form }: SCInfoEditableFieldsProps) {
  const { editor, contractInfoStore } = useRCStore()

  const rcFields = getRCInfoFieldsGroups(contractInfoStore.contractInfoValues)

  return (
    <Grid container spacing={1}>
      {rcFields.map(({ size, ...fieldGroup }) => (
        <Grid item xs={size || 12}>
          <InputsGroupWithTitle
            title={fieldGroup.title}
            fields={fieldGroup.fields!}
            form={form}
            isEditing={editor.isEditing}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default observer(RCInfoEditableFields)
