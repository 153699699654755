import * as React from "react"
import DatePicker from "react-datepicker"
import { ForwardedRef, forwardRef, useEffect } from "react"
import { MonthPickerContainer, MonthPickerHeader } from "kui-complex"
import { CalendarWithMonthProps } from "./types"

const CalendarWithMonth = forwardRef(
  (props: CalendarWithMonthProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { customInput, initialDate, handleChangeDate, minDate, maxDate } =
      props
    const { renderMonthContent } = props
    const [startDate, setStartDate] = React.useState<Date | undefined>(
      initialDate
    )

    const setCalendarDate = (date: Date) => {
      setStartDate(date)
    }

    useEffect(() => {
      setStartDate(initialDate)
    }, [initialDate])

    return (
      <div className="month_calendar" ref={ref}>
        <DatePicker
          selected={startDate}
          onSelect={handleChangeDate}
          onChange={(newDate: Date) => setCalendarDate(newDate)}
          showMonthYearPicker
          disabledKeyboardNavigation
          dateFormat="LLL yyy"
          customInput={customInput}
          calendarContainer={MonthPickerContainer}
          maxDate={maxDate || new Date()}
          minDate={minDate}
          renderCustomHeader={(headerProps) => (
            <MonthPickerHeader {...headerProps} />
          )}
          renderMonthContent={renderMonthContent}
        />
      </div>
    )
  }
)

export default CalendarWithMonth
