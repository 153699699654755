import { makeAutoObservable } from "mobx"
import { DateTime } from "luxon"
import { EntityLinkParams, ResourceTypes, MeterTypes } from "kui-crm"
import {
  ElectricityMeterInfoModel,
  MeterInfoModel,
  SingleMeterInfoModel,
} from "../types/api/meterAPI"
import { MeterValues } from "../types/store/meterInfo"
import ApartmentSimpleStore from "../../../store/templates/Apartment"

class MeterInfoStore {
  id: number | null = null

  withAutoSending: boolean = false

  noTariff: boolean = false

  passport: string = ""

  startDate: DateTime | null = null

  nextCheck: DateTime | null = null

  initialValues: MeterValues | null = null

  lastValues: MeterValues | null = null

  status: string = ""

  resource: ResourceTypes | null = null

  type?: MeterTypes

  tariffGroup: EntityLinkParams | null = null

  apartment: ApartmentSimpleStore | null = null

  company: EntityLinkParams | null = null

  operationalAccountNumber: string = ""

  companyIsAdministrative: boolean = false

  constructor(meter?: MeterInfoModel, resource?: ResourceTypes) {
    if (meter && resource) {
      this.updateMeterInfo(meter, resource)
    }
    makeAutoObservable(this)
  }

  updateMeterInfo = (meter: MeterInfoModel, resource: ResourceTypes) => {
    this.id = meter.id
    this.passport = meter.passport_number
    this.startDate = meter.activation_date
      ? DateTime.fromISO(meter.activation_date)
      : null
    this.nextCheck = meter.check_date
      ? DateTime.fromISO(meter.check_date)
      : null
    this.initialValues = MeterInfoStore.getMeterValues(
      meter,
      resource
    ) as MeterValues
    this.lastValues = null
    this.status = "open"
    this.resource = resource
    this.type = meter.type || null
    this.tariffGroup = meter.tariff
      ? {
          id: meter.tariff.id,
          name: meter.tariff.code_name,
        }
      : this.tariffGroup
    this.apartment = meter.apartment
      ? new ApartmentSimpleStore(meter.apartment)
      : this.apartment
    this.company = meter.company
    this.operationalAccountNumber = meter.operating_account_number || ""
    this.companyIsAdministrative = meter.company_is_administrative
    this.withAutoSending = meter.auto_sending
    this.noTariff = meter.no_tariff
  }

  updateNextCheckDate = (date?: DateTime | null) => {
    if (date) this.nextCheck = date
  }

  static getMeterValues = (meter: MeterInfoModel, resource: ResourceTypes) => {
    if (resource !== "electricity") {
      return {
        value: (meter as SingleMeterInfoModel).initial_value || "",
      }
    }
    return {
      T1: (meter as ElectricityMeterInfoModel).values?.T1?.initial_value,
      ...(meter.type !== "T1" && {
        T2:
          (meter as ElectricityMeterInfoModel).values?.T2?.initial_value || "",
      }),
      ...(meter.type === "T3" && {
        T3:
          (meter as ElectricityMeterInfoModel).values?.T3?.initial_value || "",
      }),
    }
  }
}

export default MeterInfoStore
