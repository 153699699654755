import { rest } from "msw"
import { fillingListMock, finishFillingMock } from "./data"
import {
  ApartmentFillingListModel,
  ApartmentFillingModel,
} from "../../pages/ApartmentsPage/types/api/apartmentsAPI"

const apartmentFilingHandlers = [
  rest.get<ApartmentFillingListModel | Error>(
    `${process.env.REACT_APP_API_URL}/apartments/:apartment_id/features`,
    (req, resp, ctx) => {
      const data = ctx.json(fillingListMock)
      return resp(data)
    }
  ),
  rest.post<FormData, ApartmentFillingModel | Error>(
    `${process.env.REACT_APP_API_URL}/apartments/:apartment_id/features`,
    (req, resp, ctx) => {
      const data = ctx.json(finishFillingMock)
      return resp(data)
    }
  ),
]

export default apartmentFilingHandlers
