import to from "await-to-js"
import { makeAutoObservable } from "mobx"
import { Loader, Paginator, resHandler } from "kui-utils"
import ApartmentInspectionStore from "./ApartmentInspectionStore"
import {
  ApartmentInspectionListModel,
  GetApartmentInspectionsResponse,
} from "../../types/api/apartmentInspectionsAPI"
import ApartmentInspectionsAgent from "../../../../agent/ApartmentInspections"
import ContractInspectionsStore from "./ContractInspectionsStore"
import ApartmentPageStore from "../ApartmentPageStore"
import { InspectionListType } from "../../types/store/apartmentInspections"

class ApartmentInspectionsListStore {
  inspectionsByContract: ContractInspectionsStore[]

  noContractInspections: ApartmentInspectionStore[]

  loader: Loader

  paginator: Paginator

  apartmentStore: ApartmentPageStore

  type: InspectionListType

  constructor(apartmentStore: ApartmentPageStore, type: InspectionListType) {
    this.inspectionsByContract = []
    this.noContractInspections = []
    this.loader = new Loader()
    this.paginator = new Paginator(5)
    this.apartmentStore = apartmentStore
    this.type = type
    makeAutoObservable(this, { apartmentStore: false })
  }

  fetchEvaluationInspections = async (apartmentId: number) => {
    this.loader.startLoading()

    const response = await to<GetApartmentInspectionsResponse>(
      ApartmentInspectionsAgent.getAll(
        this.type,
        apartmentId,
        this.paginator.limit,
        this.paginator.offset,
        null
      )
    )

    resHandler(
      response,
      this.loader,
      this.updateInspections,
      "fetch inspections"
    )
  }

  updateInspections = (res: GetApartmentInspectionsResponse) => {
    const mapper = (inspection: ApartmentInspectionListModel) =>
      new ApartmentInspectionStore(inspection)

    const result = this.paginator.getPageResponse<
      ApartmentInspectionListModel,
      ApartmentInspectionStore
    >(res, this.noContractInspections, mapper)

    this.noContractInspections = result
    this.inspectionsByContract = this.allApartmentContracts.map(
      (contract) =>
        new ContractInspectionsStore(this.apartmentStore, contract, this.type)
    )
  }

  deleteInspectionFromList = (id: number) => {
    this.noContractInspections = this.noContractInspections.filter(
      (inspection) => inspection.id !== id
    )
  }

  reset = () => {
    this.inspectionsByContract = []
    this.noContractInspections = []
    this.loader.endLoading()
    this.paginator.refresh()
  }

  get allApartmentContracts() {
    return this.apartmentStore.overviewStore.rentalContracts
  }
}

export default ApartmentInspectionsListStore
