import React from "react"
import { Link, PreviewContent } from "kui-crm"
import { ApartmentLinkCellProps } from "./types"
import { Routes } from "../../../types/route"
import { defaultApartmentImageURL } from "../../../utils/content/constants"

const ApartmentLinkCell = ({ apartment }: ApartmentLinkCellProps) =>
  apartment ? (
    <Link href={`${Routes.apartments}/${apartment.id}`}>
      <PreviewContent
        imageSrc={apartment?.previewImage || defaultApartmentImageURL}
        description={apartment?.address}
      />
    </Link>
  ) : (
    <span>-</span>
  )

export default ApartmentLinkCell
