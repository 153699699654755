import * as yup from "yup"
import {
  HouseServicesInfoFieldsSchema,
  TelecommunicationsInfoFieldsSchema,
  BasicApartmentInfoFieldsSchema,
  DetailApartmentInfoFieldsSchema,
  RoomsApartmentInfoFieldsSchema,
  SecurityApartmentInfoFieldsSchema,
  MetersInfoFieldsSchema,
} from "kui-crm_actions"

const TransferInspectionInfoSchema = yup.object().shape(
  {
    ...BasicApartmentInfoFieldsSchema,
    ...DetailApartmentInfoFieldsSchema,
    ...RoomsApartmentInfoFieldsSchema,
    ...SecurityApartmentInfoFieldsSchema,
    ...HouseServicesInfoFieldsSchema,
    ...MetersInfoFieldsSchema,
    ...TelecommunicationsInfoFieldsSchema,
  },
  [["homePhone", "homePhone"]]
)

export default TransferInspectionInfoSchema
