import React from "react"
import styled from "@emotion/styled"
import { Link, PreviewContent } from "kui-crm"
import { Caption, Grid } from "kui-basic"
import { ObjectRowWithDetailsProps } from "./types"
import { defaultApartmentImageURL } from "../../../../../utils/content/constants"

const ObjectRowWithDetails = (props: ObjectRowWithDetailsProps) => {
  const { apartment, withoutImage, details, label, link } = props

  return (
    <StyledLink href={link}>
      <StyledRow container alignItems="center" justify="space-between">
        <PreviewContent
          withoutImage={withoutImage}
          imageSrc={apartment.previewImage || defaultApartmentImageURL}
          description={apartment.address}
        />
        <StyledDetailsWrapper>
          {details}
          <Caption size="xs" weight={500} color="fiftyP">
            {label}
          </Caption>
        </StyledDetailsWrapper>
      </StyledRow>
    </StyledLink>
  )
}

export default ObjectRowWithDetails

const StyledLink = styled(Link)`
  &:not(&:last-of-type) > div {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
  }
`

const StyledRow = styled(Grid)`
  padding: 8px 0;
  flex-wrap: nowrap;
`

const StyledDetailsWrapper = styled.div`
  min-width: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
