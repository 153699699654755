import React from "react"
import { addToArrayByCondition } from "kui-utils"
import {
  PlusWithCircleIcon,
  CheckWithCircleIcon,
  MoneyTransferIcon,
} from "kui-icon"
import { PaymentSummaryPartParams } from "./types"
import ApartmentBalanceSummaryStore from "../../../../store/summary/ApartmentBalanceSummary"

const getPaymentSummaryParts = (
  summaryStore: ApartmentBalanceSummaryStore | null
): PaymentSummaryPartParams[] => [
  {
    icon: <PlusWithCircleIcon />,
    name: "accruedPrice",
    subtitle: "Accrued",
    value: summaryStore?.accruedPrice,
  },
  {
    icon: <CheckWithCircleIcon />,
    name: "paidPrice",
    subtitle: "Paid",
    value: summaryStore?.paidPrice,
  },
  ...addToArrayByCondition(!!summaryStore?.paymentBalance, {
    icon: <MoneyTransferIcon />,
    name: "paymentBalance",
    subtitle: Number(summaryStore?.paymentBalance) > 0 ? "Debt" : "Overpay",
    value: Number(Math.abs(summaryStore?.paymentBalance || 0)),
  }),
]

export default getPaymentSummaryParts
