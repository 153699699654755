import { makeAutoObservable } from "mobx"
import to from "await-to-js"
import { Loader, resHandler } from "kui-utils"
import ApartmentPageStore from "../../../../store/ApartmentPageStore"
import ApartmentPromotionAgent from "../../../../../../agent/ApartmentPromotion"
import { GetPromotionContentBodyResponse } from "../../types/api/promotionContentAPI"
import { PromotionDescriptionFormFields } from "../../forms/PromotionDescriptionForm/types"

class PromotionDescriptionStore {
  rentPrice: number | null = null

  deposit: number | null = null

  commission: number | null = null

  serviceDescription: string = ""

  otherSitesDescription: string = ""

  aboutServiceDescription: string = ""

  videoLink: string = ""

  overview3DLink: string = ""

  apartmentStore: ApartmentPageStore

  loader: Loader

  showOnTheMainPage: boolean = false

  constructor(apartmentStore: ApartmentPageStore) {
    this.apartmentStore = apartmentStore
    this.loader = new Loader()
    makeAutoObservable(this)
  }

  fetchPromotionContent = async () => {
    const apartmentId = this.apartmentStore.overviewStore.id
    if (apartmentId) {
      const response = await to(
        ApartmentPromotionAgent.getPromotionContent(apartmentId)
      )

      resHandler(response, this.loader, this.updateContentFromResponse)
    }
  }

  patchPromotionContent = async (data: PromotionDescriptionFormFields) => {
    const apartmentId = this.apartmentStore.overviewStore.id
    if (apartmentId) {
      this.loader.startLoading("edit promotion content")

      const body = PromotionDescriptionStore.getBodyForPatchContent(data)
      const response = await to(
        ApartmentPromotionAgent.patchPromotionContent(apartmentId, body)
      )

      resHandler(response, this.loader, this.updateContentFromResponse)
    }
  }

  updateContentFromResponse = (data: GetPromotionContentBodyResponse) => {
    this.serviceDescription = data.text_internal
    this.otherSitesDescription = data.text_external
    this.videoLink = data.youtube_iframe_src
    this.overview3DLink = data.matterport_iframe_src
    this.showOnTheMainPage = data.maroom_main_page
    this.aboutServiceDescription = data.text_about_service
    this.updateContentFromApartmentContract()
  }

  updateContentFromApartmentContract = () => {
    const rentalContract =
      this.apartmentStore.overviewStore.rentalContracts.find(
        (contract) => contract.status === "Open"
      )

    if (rentalContract) {
      this.rentPrice = rentalContract.rentCost
      this.commission = rentalContract.commission
      this.deposit = rentalContract.deposit
    }
  }

  static getBodyForPatchContent = (data: PromotionDescriptionFormFields) => ({
    youtube_iframe_src: data.videoLink,
    matterport_iframe_src: data.overview3DLink,
    text_internal: data.serviceDescription,
    text_external: data.otherSitesDescription,
    maroom_main_page: data.showOnTheMainPage,
    text_about_service: data.aboutServiceDescription,
  })
}

export default PromotionDescriptionStore
