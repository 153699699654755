import { DateTime } from "luxon"
import { ChangeParams } from "../../components/common/ChangesTable/types"
import { RentalTypesValues } from "./listsOptions"
import { getChangeRowParams } from "../../components/common/ChangesTable/ChangeLine/ChangeLineTableRow/content"

const getRCChangeRowParams = (change: ChangeParams): ChangeParams => {
  switch (change.fieldName) {
    case "number": {
      return getChangeRowParams(change)
    }
    case "rental_type": {
      return getChangeRowParams(
        change,
        "Rental type",
        (value: string) =>
          RentalTypesValues.find((option) => option.value === value)?.label
      )
    }
    case "auto_prolongate": {
      return getChangeRowParams(change, "Auto prolongation", (value: boolean) =>
        value ? "Yes" : "No"
      )
    }
    case "sign_date": {
      return getChangeRowParams(change, "", (value: string) =>
        DateTime.fromISO(value)?.toFormat("dd.MM.yyyy")
      )
    }
    case "start_date": {
      return getChangeRowParams(change, "", (value: string) =>
        DateTime.fromISO(value)?.toFormat("dd.MM.yyyy")
      )
    }
    case "end_date": {
      return getChangeRowParams(change, "", (value: string) =>
        DateTime.fromISO(value)?.toFormat("dd.MM.yyyy")
      )
    }
    case "close_date": {
      return getChangeRowParams(change, "", (value: string) =>
        DateTime.fromISO(value)?.toFormat("dd.MM.yyyy")
      )
    }
    case "inspection_interval_month": {
      return getChangeRowParams(change, "Inspection")
    }
    case "next_inspection": {
      return getChangeRowParams(change, "", (value: string) =>
        DateTime.fromISO(value)?.toFormat("dd.MM.yyyy")
      )
    }
    case "month_payment": {
      return getChangeRowParams(
        change,
        "Rent price",
        (value: number) => `${Number(value).toLocaleString()} ₽`
      )
    }
    case "initial_commission": {
      return getChangeRowParams(
        change,
        "",
        (value: number) => `${Number(value).toLocaleString()} %`
      )
    }
    case "security_payment": {
      return getChangeRowParams(
        change,
        "",
        (value: number) => `${Number(value).toLocaleString()} %`
      )
    }
    case "installment": {
      return getChangeRowParams(
        change,
        "With delay",
        (value: number) => `${value} month`
      )
    }
    case "day_of_payment": {
      return getChangeRowParams(change, "Payment date")
    }
    case "termination_showing_days": {
      return getChangeRowParams(
        change,
        "Showing duration",
        (value: number) => `${value} days`
      )
    }
    case "termination_penalty": {
      return getChangeRowParams(
        change,
        "",
        (value: number) => `${Number(value).toLocaleString()} %`
      )
    }
    case "planned_leave_date": {
      return getChangeRowParams(change, "Check-out date", (value: string) =>
        DateTime.fromISO(value)?.toFormat("dd.MM.yyyy")
      )
    }
    default: {
      return {
        fieldName: "",
        currentValue: "",
        newValue: "",
      }
    }
  }
}

export default getRCChangeRowParams
