import React from "react"
import { observer } from "mobx-react"
import Container from "../../../../components/ui/Container"
import NoteBlock from "../../../../components/common/NoteBlock"
import useMeterStore from "../../store"
import MeterOverviewInfoForm from "../../forms/MeterOverviewInfoForm"
import MeterReplacementsTable from "../MeterReplacementsTable"
import { MeterOverviewFormFields } from "../../forms/MeterOverviewInfoForm/types"

function MeterInfo() {
  const meterStore = useMeterStore()
  const { meterInfoStore } = meterStore

  const handleSubmit = (data: MeterOverviewFormFields) => {
    const meterId = meterInfoStore.id
    const { resource } = meterStore
    if (meterId && resource) {
      meterStore.patchMeterInfo(meterId, resource, data)
      meterStore.editor.endEditing()
    }
  }

  return (
    <>
      <Container>
        <MeterOverviewInfoForm handleSubmit={handleSubmit} />
      </Container>

      <MeterReplacementsTable />

      <Container>
        {meterStore.notesStore && (
          <NoteBlock notesStore={meterStore.notesStore} />
        )}
      </Container>
    </>
  )
}

export default observer(MeterInfo)
