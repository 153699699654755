import React from "react"
import InspectionImagesStep from "../../../InspectionImagesStep"
import { ImpairmentImagesStepProps } from "./types"

const FillingImagesStep = (props: ImpairmentImagesStepProps) => {
  const { index, addStep } = props

  return (
    <InspectionImagesStep
      withComment={false}
      description="Add filling photos"
      entityName={`fillingList.${index}`}
      name="images"
      addEntityLabel="Add filling"
      handleAddEntity={addStep}
    />
  )
}

export default FillingImagesStep
