import { makeAutoObservable } from "mobx"
import to from "await-to-js"
import { Loader } from "kui-utils"
import {
  CheckServiceModel,
  CheckServiceStatuses,
} from "../../types/api/check_service"
import longPolling from "../../utils/agent/longPolling"
import requests from "../../agent"
import { checkDataLabel } from "../../utils/content/values"

class CheckServiceStore {
  name: string

  status: CheckServiceStatuses

  result: string

  apiUrl: string

  loader: Loader

  requiredFields: string[]

  constructor(service: CheckServiceModel, apiUrl: string) {
    this.name = service.service
    this.status = service.status
    this.result = service.result
    this.requiredFields =
      service.need_data?.map(
        (field) => checkDataLabel[field as keyof typeof checkDataLabel] || field
      ) || []

    this.apiUrl = apiUrl
    this.loader = new Loader()
    makeAutoObservable(this)
  }

  runCheck = async () => {
    this.loader.startLoading()

    const body = { service: this.name }
    const [err, res] = await to<CheckServiceModel>(
      requests.post(this.apiUrl, body)
    )

    if (res) {
      this.updateService(res)

      if (res.status === "in_progress") {
        const service = await longPolling<CheckServiceModel>(
          () => requests.get(`${this.apiUrl}/${this.name}`),
          (error) => this.loader.setError(error),
          (response) => response.status !== "in_progress",
          20000
        )

        if (service) this.updateService(service)
      }
    } else {
      this.loader.setError("checking", err)
    }

    this.loader.endLoading()
  }

  updateService = (service: CheckServiceModel) => {
    this.status = service.status
    this.result = service.result
  }
}

export default CheckServiceStore
