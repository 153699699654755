import { DateTime } from "luxon"
import { makeAutoObservable } from "mobx"
import { InspectionBasedOnParams, InspectionTypes } from "kui-crm"
import {
  InspectionSignParams,
  InspectionSignStatus,
} from "../../types/store/apartmentInspections"
import {
  ApartmentInspectionListModel,
  InspectionFeedbackModelInterface,
} from "../../types/api/apartmentInspectionsAPI"

class ApartmentInspectionStore {
  id: number

  type: InspectionTypes

  date: DateTime | null

  impairmentsId: number | null

  appraisalId: number | null

  basedOn: InspectionBasedOnParams | null

  landlordFeedback: InspectionSignParams | null

  tenantFeedback: InspectionSignParams | null

  tenantOfferStatus: InspectionSignParams | null

  statusParams: InspectionSignParams | null

  constructor(inspection: ApartmentInspectionListModel) {
    this.id = inspection.id
    this.type = inspection.inspection_type
    this.date = inspection.date ? DateTime.fromISO(inspection.date) : null
    this.impairmentsId = inspection.impairment || null
    this.appraisalId = inspection.appraisal || null
    this.landlordFeedback = ApartmentInspectionStore.getFeedbackParams(
      inspection.owner_feedback
    )
    this.tenantFeedback = ApartmentInspectionStore.getFeedbackParams(
      inspection.renter_feedback
    )
    this.tenantOfferStatus = ApartmentInspectionStore.getFeedbackParams(
      inspection.conclusion
    )
    this.basedOn = inspection.based_on
      ? {
          id: inspection.based_on.id,
          type: inspection.based_on.inspection_type,
        }
      : null
    this.statusParams =
      inspection.impairments_status === "pending"
        ? {
            status: "notSigned",
            comment: "Impairments is not activated",
          }
        : null
    makeAutoObservable(this)
  }

  updateStatusParams = (data: InspectionSignParams | null) => {
    this.statusParams = data
  }

  static getFeedbackParams = (
    feedback: InspectionFeedbackModelInterface | null
  ) => {
    if (!feedback) return null

    return {
      status: ApartmentInspectionStore.getFeedbackStatus(feedback),
      comment: feedback.comment,
    }
  }

  static getFeedbackStatus = (
    feedback: InspectionFeedbackModelInterface
  ): InspectionSignStatus => {
    if (!feedback.signed) return "notSigned"
    if (feedback.agree) return "signed"
    return "rejected"
  }
}

export default ApartmentInspectionStore
