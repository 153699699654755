import { DateTime } from "luxon"
import { CalendarQuickButtonsParams } from "./types"

const now = DateTime.now()
const prevMonth = now.minus({ month: 1 })
const prevQuarter = now.minus({ quarter: 1 })
const prevYear = now.minus({ year: 1 })

export const quickButtons: CalendarQuickButtonsParams[] = [
  {
    type: "month",
    current: {
      startDate: now.startOf("month"),
      endDate: now,
    },
    prev: {
      startDate: prevMonth.startOf("month"),
      endDate: prevMonth.endOf("month"),
    },
  },
  {
    type: "quarter",
    current: {
      startDate: now.startOf("quarter"),
      endDate: now,
    },
    prev: {
      startDate: prevQuarter.startOf("quarter"),
      endDate: prevQuarter.endOf("quarter"),
    },
  },
  {
    type: "year",
    current: {
      startDate: now.startOf("year"),
      endDate: now,
    },
    prev: {
      startDate: prevYear.startOf("year"),
      endDate: prevYear.endOf("year"),
    },
  },
]
