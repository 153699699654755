import React, { useEffect } from "react"
import { observer } from "mobx-react"
import Container from "../../../../../../../components/ui/Container"
import ApartmentChart from "./ApartmentChart"
import usePromotionStore from "../../../store"
import Loading from "../../../../../../../components/common/Loading"
import PromotionChannelsContent from "./PromotionChannelsContent"

const PromotionChannelsTab = () => {
  const { channelsStore } = usePromotionStore()

  useEffect(() => {
    channelsStore.initChannelsTab()
  }, [])

  if (channelsStore.loader.isLoading) {
    return <Loading />
  }

  return (
    <Container>
      <ApartmentChart />
      <PromotionChannelsContent />
    </Container>
  )
}

export default observer(PromotionChannelsTab)
