import React from "react"
import { UserBadge as UserBadgeKUI } from "kui-crm"
import { TableCellWithUserProps } from "./types"
import { Routes } from "../../../types/route"

function UserBadge(props: TableCellWithUserProps) {
  const { user, ...other } = props

  return (
    <UserBadgeKUI
      link={user ? `${Routes.clients}/${user.id}` : ""}
      user={user}
      {...other}
    />
  )
}

export default UserBadge
