import React from "react"
import { observer } from "mobx-react"
import { Modal } from "kui-crm"
import { ApartmentDocumentEditingModalProps } from "./types"
import ApartmentDocumentForm from "../../../../../../components/forms/apartment/ApartmentDocumentForm"
import useApartmentStore from "../../../../store"
import { ApartmentDocumentFields } from "../../../../../../components/forms/apartment/ApartmentDocumentForm/types"
import { ApartmentDocumentVariants } from "../../../../types/store/apartment"

const ApartmentDocumentEditingModal = (
  props: ApartmentDocumentEditingModalProps
) => {
  const { variant, open, handleClose } = props
  const { overviewStore } = useApartmentStore()

  const handleSubmit = (data: ApartmentDocumentFields) => {
    overviewStore.editApartmentDocument(variant, data)
    handleClose()
  }

  return (
    <Modal title={`Edit ${variant}`} open={open} handleClose={handleClose}>
      <ApartmentDocumentForm
        defaultValues={overviewStore[variant as ApartmentDocumentVariants]}
        variant={variant}
        handleSubmit={handleSubmit}
      />
    </Modal>
  )
}

export default observer(ApartmentDocumentEditingModal)
