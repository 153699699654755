import React from "react"
import { observer } from "mobx-react"
import { Tooltip } from "kui-crm"
import { Caption } from "kui-basic"
import { TooltipByConditionProps } from "./types"

const TooltipByCondition = (props: TooltipByConditionProps) => {
  const { children, className, strategy, condition, message } = props

  if (!condition) return <>{children}</>

  return (
    <Tooltip
      placement="bottom"
      className={className}
      strategy={strategy}
      content={
        <Caption
          data-testid="tooltipMessage"
          size="xs"
          weight={500}
          color="fiftyP"
        >
          {message}
        </Caption>
      }
    >
      {children ? React.cloneElement(children, { disabled: true }) : null}
    </Tooltip>
  )
}

export default observer(TooltipByCondition)
