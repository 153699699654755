import requests from "./index"
import {
  PostApartmentOwnerRequest,
  PostApartmentProxyRequest,
} from "../pages/ApartmentPage/types/api/apartmentOwnershipAPI"

const ApartmentOwnershipAgent = {
  getOwners: (apartmentId: number) =>
    requests.get(`/apartments/${apartmentId}/owners`),
  postOwner: (data: PostApartmentOwnerRequest, apartmentId: number) =>
    requests.post(`/apartments/${apartmentId}/owners`, data),
  patchOwner: (
    id: number,
    data: PostApartmentOwnerRequest,
    apartmentId: number
  ) => requests.patch(`/apartments/${apartmentId}/owners/${id}`, data),
  deleteOwner: (id: number, apartmentId: number) =>
    requests.delete(`/apartments/${apartmentId}/owners/${id}`),
  getProxies: (apartmentId: number) =>
    requests.get(`/apartments/${apartmentId}/proxy-owners`),
  postProxy: (data: PostApartmentProxyRequest, apartmentId: number) =>
    requests.post(`/apartments/${apartmentId}/proxy-owners`, data),
  patchProxy: (
    id: number,
    data: PostApartmentProxyRequest,
    apartmentId: number
  ) => requests.patch(`/apartments/${apartmentId}/proxy-owners/${id}`, data),
  deleteProxy: (id: number, apartmentId: number) =>
    requests.delete(`/apartments/${apartmentId}/proxy-owners/${id}`),
  getDocuments: (offset: number, limit: number, apartmentId: number) =>
    requests.get(
      `/apartments/${apartmentId}/ownership-attachments?limit=${limit}&offset=${offset}`
    ),
  postDocument: (data: FormData, apartmentId: number) =>
    requests.post(`/apartments/${apartmentId}/ownership-attachments`, data),
  patchDocument: (id: number, data: FormData, apartmentId: number) =>
    requests.patch(
      `/apartments/${apartmentId}/ownership-attachments/${id}`,
      data
    ),
  deleteDocument: (id: number, apartmentId: number) =>
    requests.delete(`/apartments/${apartmentId}/ownership-attachments/${id}`),
}

export default ApartmentOwnershipAgent
