import React from "react"
import _ from "lodash"
import styled from "@emotion/styled"
import { Heading } from "kui-basic"
import { GroupedSortedTableProps } from "./types"
import SortingTable from "../SortingTable"
import TableRow from "../../ui/TableRow"
import TableCell from "../../ui/TableCell"

function GroupedSortedTable<T extends object>({
  renderRow,
  list,
  groupBy,
  ...tableProps
}: GroupedSortedTableProps<T>) {
  const groupedList = _.groupBy(list, groupBy)

  return (
    <SortingTable {...tableProps}>
      {Object.keys(groupedList).map((label) => (
        <React.Fragment key={label}>
          <TableRow>
            <StyledLabelCell colSpan={tableProps.headParams.length}>
              <Heading size="h4" weight={700}>
                {label}
              </Heading>
            </StyledLabelCell>
          </TableRow>
          {groupedList[label].map(renderRow)}
        </React.Fragment>
      ))}
    </SortingTable>
  )
}

export default GroupedSortedTable

const StyledLabelCell = styled(TableCell)`
  padding: 24px 0 16px 22px;
  height: 64px;
`
