import React, { useMemo } from "react"
import { observer } from "mobx-react"
import ApartmentPhotoSliderHeader from "../ApartmentPhotoSliderHeader"
import { ApartmentPhotoSliderProps } from "./types"
import FullSliderModal from "../../../../../../../../../components/common/FullSliderModal"

function ApartmentPhotoSlider(props: ApartmentPhotoSliderProps) {
  const { images, disabled, ...other } = props
  const formattedImages = useMemo(
    () => images.map((image) => image.largeImageUrl),
    [images]
  )

  return (
    <FullSliderModal
      images={formattedImages}
      renderHeaderComponent={(index, slider, handleClose) => (
        <ApartmentPhotoSliderHeader
          currentImage={images[index]}
          slider={slider}
          handleClose={handleClose}
          images={formattedImages}
          disabled={disabled}
        />
      )}
      {...other}
    />
  )
}

export default observer(ApartmentPhotoSlider)
