import React from "react"
import { observer } from "mobx-react"
import { getApartmentPaymentFields } from "kui-crm"
import { ApartmentPaymentFieldsProps } from "./types"
import InputsGroupWithTitle from "../../../../../../../components/common/InputsGroupWithTitle"
import Container from "../../../../../../../components/ui/Container"
import useInspectionStore from "../../../store"

const ApartmentPaymentFields = ({ form }: ApartmentPaymentFieldsProps) => {
  const { editor } = useInspectionStore()
  const apartmentPaymentFields = getApartmentPaymentFields()

  return (
    <Container>
      <InputsGroupWithTitle
        title="Payment and tax"
        fields={apartmentPaymentFields}
        form={form}
        size={4}
        isEditing={editor.isEditing}
      />
    </Container>
  )
}

export default observer(ApartmentPaymentFields)
