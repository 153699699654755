const inspectionHeadCells = [
  { id: "id", label: "ID", width: "5%" },
  { id: "folder_number", label: "Folder", width: "5%" },
  { id: "object", label: "Object", width: "30%" },
  { id: "document", label: "Document", width: "28%", withoutSort: true },
  { id: "type", label: "Type", width: "16%" },
  { id: "date", label: "Date", width: "16%" },
]

export default inspectionHeadCells
