import React from "react"
import { observer } from "mobx-react"
import { Box, Heading } from "kui-basic"
import styled from "@emotion/styled"
import useMeterStore from "../../store"
import Container from "../../../../components/ui/Container"
import Table from "../../../../components/ui/Table"
import MeterReplacementRow from "../MeterReplacementRow"
import ScrollingContainer from "../../../../components/common/ScrollingContainer"

function MeterReplacementsTable() {
  const { replacements } = useMeterStore()

  if (replacements.length === 0) {
    return null
  }

  return (
    <Box mt={3}>
      <Container>
        <Box pb={2}>
          <Heading size="h4" weight={700}>
            Replacements
          </Heading>
        </Box>
      </Container>
      <StyledContainer>
        <StyledScrollingContainer>
          <Table>
            <tbody>
              {replacements.map((replacement, key) => (
                <MeterReplacementRow
                  key={replacement.id}
                  replacement={replacement}
                  isDark={key % 2 !== 0}
                />
              ))}
            </tbody>
          </Table>
        </StyledScrollingContainer>
      </StyledContainer>
    </Box>
  )
}

export default observer(MeterReplacementsTable)

const StyledScrollingContainer = styled(ScrollingContainer)`
  max-height: 164px;
`

const StyledContainer = styled(Container)`
  @media (min-width: 1200px) {
    max-width: 1344px;
  }
`
