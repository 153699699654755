import { makeAutoObservable } from "mobx"
import to from "await-to-js"
import { SortingFilter, Loader, resHandler } from "kui-utils"
import ApartmentServicesAgent from "../../agent/ApartmentServices"
import ApartmentServiceStore from "../shared/apartmentService/ApartmentService"
import { ServiceModel } from "../../types/api/apartmentExpenses"

class ApartmentServicesListStore {
  services: ApartmentServiceStore[]

  filter: SortingFilter

  loader: Loader

  constructor() {
    this.services = []
    this.filter = new SortingFilter()
    this.loader = new Loader()
    makeAutoObservable(this)
  }

  fetchServices = async (apartmentId: number, periodId: number) => {
    this.loader.startLoading()

    const response = await to<ServiceModel[]>(
      ApartmentServicesAgent.getPrevServices(apartmentId, periodId)
    )

    resHandler(response, this.loader, (res) => {
      this.services = res.map(
        (service) => new ApartmentServiceStore(null, service)
      )
    })
  }

  get filteredServices() {
    return this.services.filter((service) =>
      service.title.includes(this.filter.searchQuery)
    )
  }
}

export default ApartmentServicesListStore
