import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { LoaderState } from "kui-crm"
import Container from "../../../../../components/ui/Container"
import useCompanyStore from "../../../store"
import Loading from "../../../../../components/common/Loading"
import CompanyBankInfoForm from "../../../forms/CompanyBanksInfoForm"
import { CompanyBanksInfoFields } from "../../../forms/CompanyBanksInfoForm/types"

function CompanyBankInfoTab() {
  const { overviewStore, bankInfoStore, editor } = useCompanyStore()

  const handleSubmit = (data: CompanyBanksInfoFields) => {
    if (overviewStore?.id) {
      bankInfoStore.patchBanksInfo(overviewStore.id, data)
      editor.endEditing()
    }
  }

  useEffect(() => {
    if (overviewStore?.id) {
      bankInfoStore.fetchBanksInfo(overviewStore.id)
    }
  }, [overviewStore?.id])

  if (bankInfoStore.loader.isLoading) {
    return <Loading />
  }

  return (
    <Container>
      <CompanyBankInfoForm handleSubmit={handleSubmit} />

      <LoaderState loader={bankInfoStore.loader} onlyError />
      <LoaderState loader={bankInfoStore.actionLoader} />
    </Container>
  )
}

export default observer(CompanyBankInfoTab)
