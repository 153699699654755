import React from "react"
import styled from "@emotion/styled"
import { useToggle } from "kui-utils"
import { HeadingWithButton, LoaderState } from "kui-crm"
import { DocumentsBlockProps } from "./types"
import ScrollingContainer from "../ScrollingContainer"
import UploadFilesWithSettingsModal from "../../modals/UploadFilesWithSettingsModal"
import FileWithSettingsRow from "../FileWithSettingsRow"
import DocumentStore from "../../../store/templates/DocumentStore"

function DocumentsBlock({
  withScroll,
  documents,
  loader,
  disabled,
  addFiles,
  ...other
}: DocumentsBlockProps) {
  const [isModalOpen, handleModalOpen, handleModalClose] = useToggle()
  const Wrapper = withScroll ? StyledScrollingContainer : "div"

  return (
    <>
      <StyledHeadingBlock
        onClick={handleModalOpen}
        title="Documents"
        variant="whiteWithGray"
        showButton={!disabled}
      />
      <Wrapper>
        {documents?.map((file, key: number) => (
          <FileWithSettingsRow
            key={file.id}
            index={key}
            file={file as DocumentStore}
            disabled={disabled}
            {...other}
          />
        ))}
      </Wrapper>

      <LoaderState loader={loader} onlyError />
      <UploadFilesWithSettingsModal
        handleClose={handleModalClose}
        open={isModalOpen}
        handleSubmit={addFiles}
      />
    </>
  )
}

DocumentsBlock.defaultValues = {
  withScroll: true,
}

export default DocumentsBlock

const StyledHeadingBlock = styled(HeadingWithButton)`
  padding-top: 0;
  padding-bottom: 12px;
`

const StyledScrollingContainer = styled(ScrollingContainer)`
  max-height: 224px;
`
