import React from "react"
import { Caption } from "kui-basic"
import styled from "@emotion/styled"
import { TenantOfferInfoProps } from "./types"

const TenantOfferInfo = (props: TenantOfferInfoProps) => {
  const { offer } = props

  return (
    <Caption size="xs">
      New rent price{" "}
      <StyledPriceAccent>{offer.price.toLocaleString()} ₽</StyledPriceAccent>,
      valid from{" "}
      <StyledDateAccent>{offer.date?.toFormat("dd.MM.yyyy")}</StyledDateAccent>
    </Caption>
  )
}

export default TenantOfferInfo

const StyledPriceAccent = styled.span`
  color: ${({ theme }) => theme.palette.brand.main};
  font-weight: 600;
`

const StyledDateAccent = styled.span`
  font-weight: 600;
`
