import { createContext, useContext } from "react"
import MetersStore from "./MetersStore"

const metersStore = new MetersStore()
const MetersContext = createContext(metersStore)

const useMetersStore = () => useContext(MetersContext)

export default useMetersStore
export { metersStore }
