import { makeAutoObservable } from "mobx"
import { Loader, resHandler } from "kui-utils"
import to from "await-to-js"
import DashboardPageStore from "../DashboardPageStore"
import DashboardAgent from "../../../../agent/Dashboard"
import ApartmentsStore from "../../../../store/lites/ApartmentsStore"
import {
  ObjectsWithoutInitialResponse,
  ObjectWithoutInitialModel,
} from "../../types/api/apartments"
import { ObjectNeedInitialParams } from "../../types/cards/apartments"

class ObjectsNeedInitialStore {
  value: number | null

  done: number | null

  objects: ObjectNeedInitialParams[]

  dashboardStore: DashboardPageStore

  loader: Loader

  constructor(dashboardStore: DashboardPageStore) {
    this.value = null
    this.done = null
    this.objects = []
    this.dashboardStore = dashboardStore
    this.loader = new Loader(true)
    makeAutoObservable(this)
  }

  fetchObjects = async () => {
    this.loader.startLoading()

    const response = await to(
      DashboardAgent.getObjectsWithoutInitial(
        this.dashboardStore.periodFilter.slice(1)
      )
    )

    resHandler(response, this.loader, this.updateObjectsWithoutInitial)
  }

  updateObjectsWithoutInitial = (res: ObjectsWithoutInitialResponse) => {
    this.value = res.count
    this.done = res.completed_count
    this.objects = res.results.map((object) =>
      ObjectsNeedInitialStore.getObjectParams(object)
    )
  }

  static getObjectParams = (
    object: ObjectWithoutInitialModel
  ): ObjectNeedInitialParams => ({
    ...ApartmentsStore.getObjectForMapParams(object.apartment),
    daysWithoutInitial: object.since_object_creation,
    isOverdue: object.since_object_creation > 14,
  })
}

export default ObjectsNeedInitialStore
