import * as yup from "yup"

const ContractClosingSchema = yup.object().shape({
  terminationAgreement: yup.object({
    file: yup.mixed().required("This field is required"),
  }),
  closeDate: yup.mixed().required("This field is required"),
})

export default ContractClosingSchema
