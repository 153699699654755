import to from "await-to-js"
import { makeAutoObservable, runInAction } from "mobx"
import { deleteCookie } from "kui-utils"
import { SignInParams } from "kui-crm/types"
import AuthAgent from "../../agent/Auth"
import RootStore from "../Root"
import { authFlag } from "../../utils/content/constants"

const csrfTokenKey = "csrftoken"
const sessionIdKey = "crm-sessionid"

class AuthStore {
  rootStore: RootStore

  err!: Error | null

  isAuthed: boolean

  isLoading: boolean

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.isAuthed = !!localStorage.getItem(authFlag)
    this.isLoading = false
    makeAutoObservable(this, {
      rootStore: false,
    })
  }

  signIn = async (data: SignInParams) => {
    this.isLoading = true
    this.err = null
    const [err] = await to(AuthAgent.signIn(data))
    runInAction(() => {
      this.isLoading = false
    })

    runInAction(() => {
      if (!err) {
        this.setAuthData()
      } else {
        this.err = err
      }
    })
  }

  cleanAuthData = () => {
    this.removeCookies()
    localStorage.removeItem(authFlag)

    this.isAuthed = false
    this.rootStore.appStore.setInitialRoute("")
  }

  setAuthData = () => {
    this.isAuthed = true
    localStorage.setItem(authFlag, "true")
  }

  logout = async () => {
    this.isLoading = true
    const [err] = await to(AuthAgent.logout())
    runInAction(() => {
      if (!err) {
        this.cleanAuthData()
      } else {
        this.err = err
      }
      this.isLoading = false
    })
  }

  removeCookies = () => {
    deleteCookie(csrfTokenKey)
    deleteCookie(sessionIdKey)
  }

  reset = () => {
    this.isAuthed = false
    this.isLoading = false
    this.err = null
  }
}

export default AuthStore
