import { makeAutoObservable, runInAction } from "mobx"
import { Loader, Paginator, resHandler } from "kui-utils"
import to from "await-to-js"
import DashboardPageStore from "../DashboardPageStore"
import DashboardAgent from "../../../../agent/Dashboard"
import ApartmentsStore from "../../../../store/lites/ApartmentsStore"
import { ContractIncreasedRentParams } from "../../types/cards/contracts"
import { ContractIncreasedRentModel } from "../../types/api/contracts"

class ContractsIncreasedRentStore {
  value: number | null

  contracts: ContractIncreasedRentParams[]

  paginator: Paginator

  loader: Loader

  dashboardStore: DashboardPageStore

  constructor(dashboardStore: DashboardPageStore) {
    this.value = null
    this.contracts = []
    this.paginator = new Paginator(5)
    this.loader = new Loader()
    this.dashboardStore = dashboardStore
    makeAutoObservable(this)
  }

  fetchContracts = async () => {
    this.loader.startLoading()

    const response = await to(
      DashboardAgent.getContractsIncreasedRent(
        this.paginator.limit,
        this.paginator.offset,
        this.dashboardStore.periodFilter
      )
    )

    runInAction(() => {
      resHandler(response, this.loader, (res) => {
        this.value = res.count
        this.contracts = this.paginator.getPageResponse(
          res,
          this.contracts,
          ContractsIncreasedRentStore.getContractParams
        )
      })
    })
  }

  static getContractParams = ({
    contract,
    price_change,
  }: ContractIncreasedRentModel) => ({
    id: contract.id,
    number: contract.number,
    apartment: ApartmentsStore.getApartmentLinkParams(contract.apartment),
    increasedRentValue: Number(price_change),
  })
}

export default ContractsIncreasedRentStore
