import React from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box } from "kui-basic"
import { FormBottom } from "kui-crm"
import styled from "@emotion/styled"
import useSCsStore from "../../store"
import SCFixedCostsFormSchema from "./schema"
import { FormProps } from "../../../../types/ui/form"
import FixedCostsBlock from "../../../../components/forms/serviceContract/FixedCostsBlock"
import { ServiceContractFixedCostsFields } from "../CreateServiceContractForm/types"
import SCCommonFields from "../../components/SCCommonFields"
import FieldsGroupTitle from "../../../../components/common/FieldsGroupTitle"
import InputSelect from "../../../../components/ui/InputSelect"
import { TaxTypeValues } from "../../../../utils/content/listsOptions"

function SCFixedCostsForm({ handleSubmit }: FormProps) {
  const { creationForm } = useSCsStore()
  const { fields, prevStep } = creationForm

  const form = useForm<ServiceContractFixedCostsFields>({
    resolver: yupResolver(SCFixedCostsFormSchema),
    defaultValues: {
      ...fields,
    },
  })

  return (
    <StyledForm onSubmit={form.handleSubmit(handleSubmit)}>
      <SCCommonFields form={form} />

      <Box pr={5} pl={5} mb={3}>
        <FixedCostsBlock form={form} />
        <FieldsGroupTitle title="Taxes" />
        <InputSelect
          label="Type"
          name="agentType"
          form={form}
          options={TaxTypeValues}
        />
      </Box>
      <FormBottom
        withPrevStep
        handlePrevClick={prevStep}
        label="Create contract"
      />
    </StyledForm>
  )
}

export default SCFixedCostsForm

const StyledForm = styled.form`
  max-height: calc(100vh - 64px - 88px);
  overflow-y: scroll;
  scrollbar-width: none;
`
