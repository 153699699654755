import React from "react"
import { useFieldArray } from "react-hook-form"
import { Grid } from "kui-basic"
import { observer } from "mobx-react"
import { useToggle } from "kui-utils"
import { HeadingWithButton } from "kui-crm"
import { StatisticalValuationsFieldsProps } from "./types"
import StatisticalValuationFields from "../StatisticalValuationFields"
import StatisticalValuationModal from "../StatisticalValuationModal"
import { StatisticalValuationFormFields } from "../../../../../../../components/forms/apartment/InspectionCreationForm/variations/AppraisalInspectionForm/StatisticalValuationStep/types"
import useInspectionStore from "../../../store"

const StatisticalValuationsFields = ({
  form,
}: StatisticalValuationsFieldsProps) => {
  const { editor } = useInspectionStore()
  const [isModalOpen, handleModalOpen, handleModalClose] = useToggle()
  const { fields, remove, append } = useFieldArray({
    control: form.control,
    name: "statisticalValuations",
  })
  const disabled = !editor.isEditing

  const addComparableObject = (data: StatisticalValuationFormFields) => {
    append(data)
    handleModalClose()
  }

  return (
    <>
      <HeadingWithButton
        title="Statistical analysis"
        onClick={handleModalOpen}
        variant="primary"
        disabled={disabled}
      />
      <Grid container spacing={3}>
        {fields.map((field, index) => (
          <StatisticalValuationFields
            key={field.id}
            index={index}
            form={form}
            remove={remove}
            disabled={disabled}
          />
        ))}
      </Grid>
      <StatisticalValuationModal
        open={isModalOpen}
        handleClose={handleModalClose}
        handleSubmit={addComparableObject}
      />
    </>
  )
}

export default observer(StatisticalValuationsFields)
