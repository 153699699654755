import React from "react"
import { observer } from "mobx-react"
import { Grid } from "kui-basic"
import EditButton from "../../../../components/ui/EditButton"
import useMeterStore from "../../store"

function MeterEditButton() {
  const { editor } = useMeterStore()

  return (
    <Grid item>
      <EditButton
        isEditing={editor.isEditing}
        onSaveHandler={editor.allowToSendForm}
        onEditHandler={editor.startEditing}
      />
    </Grid>
  )
}

export default observer(MeterEditButton)
