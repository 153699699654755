import { DateTime } from "luxon"
import { ApartmentExpensesPeriodModel } from "../../../../pages/ApartmentPage/pages/ExpensesPage/types/api/expensesPeriodAPI"
import { LastOpenPeriodModel } from "../../../../types/api/apartmentExpenses"

const today = DateTime.now()

export const closedPeriodMock: ApartmentExpensesPeriodModel = {
  id: 1,
  close_datetime: "2022-10-15",
  who_close: {
    id: 1,
    email: "",
    first_name: "Иван",
    last_name: "Иванов",
    middle_name: "Иванович",
    full_name: "Иван Иванов Иванович",
    phone: "",
  },
  status: "closed",
  is_last_for_rental_contract: false,
  rental_contract_end_at: "",
  renter: null,
  owner: null,
  is_deposit_calculated: true,
  closing_conditions: {},
  rental_contract: null,
  service_contract: null,
}

export const openPeriodMock: ApartmentExpensesPeriodModel = {
  id: 1,
  close_datetime: null,
  who_close: null,
  status: "new",
  is_last_for_rental_contract: false,
  rental_contract_end_at: "",
  owner: null,
  renter: null,
  is_deposit_calculated: true,
  closing_conditions: {},
  rental_contract: null,
  service_contract: null,
}

export const periodIds = {
  open: 1,
  closed: 2,
}

export const lastOpenPeriodMock: LastOpenPeriodModel = {
  id: 1,
  year: today.year,
  month: today.month,
  close_datetime: "",
  who_close: null,
  status: "new",
  renter: null,
}
