import InitialInspectionForm from "./variations/InitialInspectionForm"
import AppraisalInspectionForm from "./variations/AppraisalInspectionForm"
import ImpairmentsInspectionForm from "./variations/ImpairmentsInspectionForm"
import InventoryInspectionForm from "./variations/InventoryInspectionForm"
import TransferInspectionForm from "./variations/TransferInspectionForm"
import RegularInspectionForm from "./variations/RegularInspectionForm"

export const inspectionsSteps = {
  initial: InitialInspectionForm,
  appraisal: AppraisalInspectionForm,
  impairments: ImpairmentsInspectionForm,
  inventory: InventoryInspectionForm,
  transfer: TransferInspectionForm,
  regular: RegularInspectionForm,
}
