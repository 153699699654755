import { FieldError, UseFormReturn } from "react-hook-form"

const getFormValue = <T extends object>(
  name?: string,
  form?: UseFormReturn<T> | null
) => {
  const names = name?.split(".")
  let fieldValue = form?.control._formValues
  names?.forEach((field: string) => {
    if (fieldValue) {
      fieldValue = fieldValue[field]
    }
  })
  return fieldValue
}

const getFormError = <T extends object>(
  name?: string,
  form?: UseFormReturn<T> | null
) => {
  const names = name?.split(".")
  let fieldError = form?.formState.errors
  names?.forEach((field) => {
    if (fieldError) {
      // @ts-ignore
      fieldError = fieldError[field as keyof FieldErrors<T>]
    }
  })
  return (fieldError as FieldError)?.message
}

export { getFormError, getFormValue }
