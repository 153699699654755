import React from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box } from "kui-basic"
import {
  ChangeReasonSchema,
  ChangeReasonFormFields,
  FormBottom,
  ChangeReasonFields,
} from "kui-crm"
import { ChangeReasonFormProps } from "./types"
import useResetForm from "../../../../hooks/useResetForm"

function ChangeReasonForm(props: ChangeReasonFormProps) {
  const { label, handleSubmit, customReasons } = props
  const form = useForm<ChangeReasonFormFields>({
    resolver: yupResolver(ChangeReasonSchema),
  })

  useResetForm(form)

  return (
    <form onSubmit={form.handleSubmit(handleSubmit)}>
      <Box pl={5} pr={5} mb={3}>
        <ChangeReasonFields form={form} customReasons={customReasons} />
      </Box>
      <FormBottom label={label || "Save"} />
    </form>
  )
}

export default ChangeReasonForm
