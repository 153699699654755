import React from "react"
import styled from "@emotion/styled"
import { Button, Caption, Grid } from "kui-basic"
import _ from "lodash"
import { useTheme } from "@emotion/react"
import { observer } from "mobx-react"
import { LoaderState } from "kui-crm"
import { CheckServiceOptionProps, CheckServiceOptionStylesProps } from "./types"
import {
  checkServiceIcons,
  checkServiceStatuses,
  checkStatusStyles,
} from "./content"
import Loading from "../../Loading"
import CheckDataPopup from "../CheckDataPopup"

const CheckServiceOption = (props: CheckServiceOptionProps) => {
  const { service, dataPopupLabel } = props
  const theme = useTheme()
  const statusStyles = checkStatusStyles(theme)[service.status]

  const handleOpenResult = () => {
    window.open(service.result, "_blank")
  }

  const serviceActions = {
    ready: service.runCheck,
    fail: service.runCheck,
    passed: handleOpenResult,
    not_enough_data: undefined,
    in_progress: undefined,
  }

  return (
    <StyledOption
      container
      justify="space-between"
      alignItems="center"
      wrap="nowrap"
    >
      <Caption size="s" weight={500}>
        {_.capitalize(service.name)}
      </Caption>

      <StyledStatusBar
        container
        statusStyles={statusStyles}
        justify="space-between"
        alignItems="center"
        wrap="nowrap"
      >
        <Caption size="s" weight={500}>
          {checkServiceStatuses[service.status]}
        </Caption>
        {serviceActions[service.status] && (
          <StyledButton
            isCircle
            variant="transparent"
            onClick={serviceActions[service.status]}
          >
            {service.loader.isLoading ? (
              <StyledButtonLoading />
            ) : (
              checkServiceIcons[service.status]
            )}
          </StyledButton>
        )}
        {service.status === "not_enough_data" && (
          <CheckDataPopup
            label={dataPopupLabel}
            dataList={service.requiredFields}
          />
        )}
      </StyledStatusBar>

      <LoaderState loader={service.loader} onlyError />
    </StyledOption>
  )
}

export default observer(CheckServiceOption)

const StyledOption = styled(Grid)`
  padding: 10px 4px;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
  }
`

const StyledStatusBar = styled(Grid)<CheckServiceOptionStylesProps>`
  height: 32px;
  border-radius: 66px;
  padding: 4px 4px 4px 8px;
  background: ${({ statusStyles }) => statusStyles.background};
  min-width: 100px;
  width: fit-content;
  p {
    color: ${({ statusStyles }) => statusStyles.color};
  }
  button {
    background: ${({ statusStyles }) => statusStyles.color};
  }
`

const StyledButton = styled(Button)`
  width: 24px;
  height: 24px;
  svg {
    pointer-events: none;
  }
  path {
    fill: white;
  }
`

const StyledButtonLoading = styled(Loading)`
  height: 16px;
  .KUI-CircularProgress {
    width: 16px !important;
    height: 16px !important;
    color: white;
  }
`
