import React from "react"
import { useToggle } from "kui-utils"
import { observer } from "mobx-react"
import {
  FieldGroupParams,
  UpdatedFieldParams,
  UpdatedFieldsGroupProps,
} from "./types"
import UpdatedFieldsModal from "../UpdatedFieldsModal"
import UpdatedFieldsWithTitle from "../../forms/common/UpdatedFieldsForm/UpdatedFieldsWithTitle"

function UpdatedFieldsGroup(props: UpdatedFieldsGroupProps) {
  const { title, fields, size, disabled, fieldsGroups, form, editor, name } =
    props
  const { modalProps, validationSchema, customReasons, UpdateModal, AddModal } =
    props
  const { handleUpdate, handleAdd, handleRemove } = props
  const [isUpdateModalOpen, handleUpdateModalOpen, handleUpdateModalClose] =
    useToggle()
  const [isAddModalOpen, handleAddModalOpen, handleAddModalClose] = useToggle()

  const updateForm = (data: any) => {
    if (name) {
      const oldValue = form.getValues(name)
      form.setValue(name, { ...oldValue, ...data })
    } else {
      Object.keys(data).forEach((key) => {
        form.setValue(key, data[key])
      })
    }
  }

  const handleSubmitAdd = (data: any) => {
    updateForm(data)
    if (handleAdd) handleAdd(data)
  }

  const handleSubmitUpdate = (data: any) => {
    updateForm(data)
    if (handleUpdate) handleUpdate()
    editor.startEditing()
  }

  const UpdateModalComponent = UpdateModal || UpdatedFieldsModal
  const AddModalComponent = AddModal || UpdatedFieldsModal

  const updatedFields =
    fields ||
    (fieldsGroups
      ?.map((fieldGroup: FieldGroupParams) => fieldGroup.fields)
      ?.flat() as UpdatedFieldParams[])

  const fieldsGroupProps = {
    handleUpdate: handleUpdateModalOpen,
    handleAdd: handleAdd && handleAddModalOpen,
    handleRemove,
    disabled,
    form,
    editor,
  }

  const fieldsModalProps = {
    fields: updatedFields,
    validationSchema,
    customReasons,
  }

  return (
    <>
      {fieldsGroups ? (
        fieldsGroups.map((fieldsGroup: FieldGroupParams, key: number) => (
          <UpdatedFieldsWithTitle
            withoutButton={key !== fieldsGroups.length - 1}
            {...fieldsGroupProps}
            {...fieldsGroup}
          />
        ))
      ) : (
        <UpdatedFieldsWithTitle
          fields={fields!}
          title={title!}
          size={size}
          {...fieldsGroupProps}
        />
      )}

      {isUpdateModalOpen && (
        <UpdateModalComponent
          open={isUpdateModalOpen}
          handleClose={handleUpdateModalClose}
          title={modalProps?.updatedTitle || `Edit ${title?.toLowerCase()}`}
          handleSubmit={handleSubmitUpdate}
          {...fieldsModalProps}
        />
      )}
      {isAddModalOpen && (
        <AddModalComponent
          open={isAddModalOpen}
          handleClose={handleAddModalClose}
          title={modalProps?.addingTitle || `Add ${title?.toLowerCase()}`}
          handleSubmit={handleSubmitAdd}
          {...fieldsModalProps}
        />
      )}
    </>
  )
}

export default observer(UpdatedFieldsGroup)
