import * as yup from "yup"

const ApartmentDescriptionSchema = yup.object().shape({
  apartment: yup.object({
    address: yup.string().trim().required("This field is required").min(8),
  }),
  metroStations: yup.array().of(
    yup.object({
      name: yup
        .string()
        .trim()
        .required("This field is required")
        .test(
          "check-metro-id",
          "Please select an metro from the dropdown",
          function (value) {
            const { id } = this.parent
            return !!id
          }
        ),
      walkDistance: yup
        .string()
        .required("This field is required")
        .transform((value) => (Number.isNaN(value) ? undefined : value)),
      carDistance: yup
        .string()
        .required("This field is required")
        .transform((value) => (Number.isNaN(value) ? undefined : value)),
      carTime: yup
        .string()
        .required("This field is required")
        .transform((value) => (Number.isNaN(value) ? undefined : value)),
    })
  ),
})

export default ApartmentDescriptionSchema
