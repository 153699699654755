import React from "react"
import { observer } from "mobx-react"
import { useToggle } from "kui-utils"
import SearchPanel from "../../../components/common/SearchPanel"
import { ContractsSearchPanelProps } from "./types"
import useSCsStore from "../../ServiceContractsPage/store"
import RentalContractCreationModal from "../../RentalContractsPage/components/RentalContractCreationModal"
import ServiceContractCreationModal from "../../ServiceContractsPage/components/ServiceContractCreationModal"
import useRCsStore from "../../RentalContractsPage/store"

function ContractsSearchPanel({ currentContract }: ContractsSearchPanelProps) {
  const [isModalOpen, handleModalOpen, handleModalClose] = useToggle()
  const currentContractStore =
    currentContract === "service" ? useSCsStore() : useRCsStore()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    currentContractStore.filter.updateSearchQuery(e.currentTarget.value)
    if (e.target.value.length === 0) {
      currentContractStore.paginator.clearOffset()
      currentContractStore.paginator.startFetching()
    }
  }

  return (
    <>
      <SearchPanel
        onClick={handleModalOpen}
        onChange={handleChange}
        value={currentContractStore.filter.searchQuery}
      />

      {currentContract === "service" ? (
        <ServiceContractCreationModal
          isOpen={isModalOpen}
          handleClose={handleModalClose}
        />
      ) : (
        <RentalContractCreationModal
          isOpen={isModalOpen}
          handleClose={handleModalClose}
        />
      )}
    </>
  )
}

export default observer(ContractsSearchPanel)
