import React from "react"
import { observer } from "mobx-react"
import { Modal } from "kui-crm"
import EditServiceForm from "../../forms/apartmentExpenses/ServiceEditingForm"
import { ServiceEditingModalProps } from "./types"
import { ServiceEditingFields } from "../../forms/apartmentExpenses/ServiceEditingForm/types"

function ServiceEditingModal(props: ServiceEditingModalProps) {
  const { open, handleClose, service, ...other } = props

  const handleSubmit = async (data: ServiceEditingFields) => {
    handleClose()
    await service?.patchService(data)
  }

  return (
    <Modal
      title="Edit service expense"
      open={open}
      handleClose={handleClose}
      isScrollable
      data-testid="edit_service_modal"
    >
      <EditServiceForm
        handleSubmit={handleSubmit}
        service={service}
        {...other}
      />
    </Modal>
  )
}

export default observer(ServiceEditingModal)
