import React from "react"
import styled from "@emotion/styled"
import { Button } from "kui-basic"
import { TrashIcon } from "kui-icon"
import { ImagesSliderProps } from "./types"
import FullSliderModal from "../FullSliderModal"

function ImagesSlider(props: ImagesSliderProps) {
  const { onDelete, ...other } = props

  return (
    <FullSliderModal
      renderHeaderComponent={(index: number) =>
        onDelete ? (
          <StyledButton
            size="s"
            endIcon={<TrashIcon />}
            onClick={() => onDelete && onDelete(index)}
          >
            Delete
          </StyledButton>
        ) : null
      }
      {...other}
    />
  )
}

export default ImagesSlider

const StyledButton = styled(Button)`
  margin-right: 32px;
`
