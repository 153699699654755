import React, { useRef } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { Caption } from "kui-basic"
import { useToggle } from "kui-utils"
import { Tooltip } from "kui-crm"
import { TextOverflowProps, TextOverflowStylesProps } from "./types"

function TextOverflow(props: TextOverflowProps) {
  const {
    children,
    onOpen,
    content,
    placement,
    className,
    strategy,
    ...otherProps
  } = props
  const [isTooltipOpen, openTooltip, closeTooltip] = useToggle()
  const textRef = useRef<HTMLParagraphElement>(null)

  const handleOpen = () => {
    const isEllipsisActive =
      Number(textRef.current?.offsetWidth) <
        Number(textRef.current?.scrollWidth) ||
      Number(textRef.current?.offsetHeight) <
        Number(textRef.current?.scrollHeight)
    if (isEllipsisActive || content) {
      openTooltip()
    }
    if (onOpen) {
      onOpen()
    }
  }

  return (
    <Tooltip
      open={isTooltipOpen}
      onClose={closeTooltip}
      content={<Caption size="xs">{content || children}</Caption>}
      onOpen={handleOpen}
      placement={placement}
      className={className}
      strategy={strategy}
    >
      <StyledCaption
        ref={textRef}
        size="s"
        className="TextOverflow_content"
        {...otherProps}
      >
        {children}
      </StyledCaption>
    </Tooltip>
  )
}

export default TextOverflow

const multilineCSS = ({ multiline }: TextOverflowStylesProps) => css`
  display: -webkit-box;
  -webkit-line-clamp: ${multiline};
  -webkit-box-orient: vertical;
`

const singleLineCSS = css`
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const StyledCaption = styled(Caption)<TextOverflowStylesProps>`
  max-width: 100%;
  overflow: hidden;
  ${({ multiline }) =>
    multiline ? multilineCSS({ multiline }) : singleLineCSS};
`
