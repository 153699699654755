import React from "react"
import { observer } from "mobx-react"
import { ModalProps } from "kui-crm"
import usePromotionStore from "../../../../../store"
import UploadPhotoModal from "../../../../../../../../../components/common/UploadPhotoModal"

function UploadApartmentPhotoModal({ open, handleClose }: ModalProps) {
  const {
    contentStore: { imagesStore },
  } = usePromotionStore()

  return (
    <UploadPhotoModal
      handleSubmit={imagesStore.loadImages}
      open={open}
      handleClose={handleClose}
      reserveMessage="add photos to an apartment"
    />
  )
}

export default observer(UploadApartmentPhotoModal)
