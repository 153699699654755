import * as React from "react"
import styled from "@emotion/styled"
import { Box } from "kui-basic"
import { getPhoneNumberFromPhoneParams } from "kui-utils"
import UndefinedAvatar from "../../../common/UndefinedAvatar"
import { UserAutocompleteOptionProps } from "./types"

function ClientAutocompleteOption({ option }: UserAutocompleteOptionProps) {
  const phone = option.phone ? getPhoneNumberFromPhoneParams(option.phone) : ""

  return (
    <StyledWrapper>
      <Box mr={1}>
        <UndefinedAvatar label={option.name} />
      </Box>
      <div>
        <StyledTopSignature>{option.name}</StyledTopSignature>
        <StyledBottomSignature>
          {phone} • {option.email}
        </StyledBottomSignature>
      </div>
    </StyledWrapper>
  )
}

export default ClientAutocompleteOption

const StyledWrapper = styled.div`
  cursor: pointer;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`

const StyledTopSignature = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.grey.seventy};
`

const StyledBottomSignature = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.grey.fiftyP};
`
