import React from "react"
import { Grid, Button } from "kui-basic"
import { InputWithController } from "kui-complex"
import styled from "@emotion/styled"
import { CloseIcon } from "kui-icon"
import { EditableFixedCostItemProps } from "./types"
import { PayerValues } from "../../../../utils/content/listsOptions"
import InputSelect from "../../../../components/ui/InputSelect"

function EditableFixedCostItem(props: EditableFixedCostItemProps) {
  const { index, onDelete, form, disabled } = props

  const handleDelete = () => onDelete(index)

  return (
    <>
      <Grid item xs={6}>
        <InputWithController
          label="Name"
          name={`fixedCosts.${index}.name`}
          form={form}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={3}>
        <InputWithController
          label="Cost"
          type="number"
          name={`fixedCosts.${index}.cost`}
          form={form}
          cornerLabel="P"
          disabled={disabled}
        />
      </Grid>
      <Grid
        container
        item
        xs={3}
        wrap="nowrap"
        alignItems="center"
        justify="space-between"
      >
        <StyledInputWrapper>
          <InputSelect
            testId="payer"
            label="Payer"
            form={form}
            options={PayerValues}
            name={`fixedCosts.${index}.payer`}
            disabled={disabled}
          />
        </StyledInputWrapper>
        <Button
          isCircle
          size="xs"
          variant="backgroundLight"
          onClick={handleDelete}
          disabled={disabled}
        >
          <CloseIcon width={8} height={8} />
        </Button>
      </Grid>
    </>
  )
}

export default EditableFixedCostItem

const StyledInputWrapper = styled.div`
  width: 100%;
  max-width: calc(100% - 16px - 32px);
`
