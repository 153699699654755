import requests from "./index"
import { DashboardSettingsModel } from "../types/api/dashboard"
import {
  WorkIndicatorResponse,
  WorkIndicatorType,
} from "../pages/DashoboardPage/types/api/common"
import {
  AutoMetersResponse,
  MetersVerificationsResponse,
  MetersWithDeviatingValueResponse,
} from "../pages/DashoboardPage/types/api/meters"
import { ContractTypes } from "../types/common"
import {
  ClosingContractsResponse,
  ContractsIncreasedRentResponse,
  NewContractsResponse,
  PendingContractsResponse,
} from "../pages/DashoboardPage/types/api/contracts"
import { CompaniesDashboardResponse } from "../pages/DashoboardPage/types/api/companies"
import {
  ApartmentServicesResponse,
  NewObjectsResponse,
  ObjectsNeedTenantModelResponse,
  ObjectsWithoutAppraisalResponse,
  ObjectsWithoutInitialResponse,
} from "../pages/DashoboardPage/types/api/apartments"
import {
  ImpairmentInspectionsResponse,
  PendingImpairmentsResponse,
  RejectedAppraisalsResponse,
  UnsignedInspectionsResponse,
  UpcomingInspectionResponse,
} from "../pages/DashoboardPage/types/api/inspections"

const DashboardAgent = {
  getSettings: () => requests.get("/dashboard/settings"),
  editSettings: (data: DashboardSettingsModel) =>
    requests.patch("/dashboard/settings", data),
  getWorkIndicators: (type: WorkIndicatorType, filter: string) =>
    requests.get<WorkIndicatorResponse>(
      `/dashboard/reminders/count?type=${type}${filter}`
    ),
  getAutoMeters: (limit: number, offset: number, filter: string) =>
    requests.get<AutoMetersResponse>(
      `/dashboard/reminders/auto-metering-devices?limit=${limit}&offset=${offset}${filter}`
    ),
  getMetersVerifications: (limit: number, offset: number, filter: string) =>
    requests.get<MetersVerificationsResponse>(
      `/dashboard/reminders/meters-require-verification?limit=${limit}&offset=${offset}${filter}`
    ),
  getObjectsWithoutAppraisal: (limit: number, offset: number, filter: string) =>
    requests.get<ObjectsWithoutAppraisalResponse>(
      `/dashboard/reminders/objects-need-appraisal?limit=${limit}&offset=${offset}${filter}`
    ),
  getPendingImpairments: (limit: number, offset: number, filter: string) =>
    requests.get<PendingImpairmentsResponse>(
      `/dashboard/reminders/pending-impairments?limit=${limit}&offset=${offset}${filter}`
    ),
  getContractsIncreasedRent: (limit: number, offset: number, filter: string) =>
    requests.get<ContractsIncreasedRentResponse>(
      `/dashboard/reminders/contracts-increased-rent?limit=${limit}&offset=${offset}${filter}`
    ),
  getNewObjects: (limit: number, offset: number, filter: string) =>
    requests.get<NewObjectsResponse>(
      `/dashboard/reminders/first-rental-contracts?limit=${limit}&offset=${offset}${filter}`
    ),
  getNewRentalContracts: (limit: number, offset: number, filter: string) =>
    requests.get<NewContractsResponse>(
      `/dashboard/reminders/new-rental-contracts?limit=${limit}&offset=${offset}${filter}`
    ),
  getObjectsWithoutTenant: (limit: number, offset: number, filter: string) =>
    requests.get<ObjectsNeedTenantModelResponse>(
      `/dashboard/reminders/objects-need-tenant?limit=${limit}&offset=${offset}${filter}`
    ),
  getObjectsWithoutInitial: (filter: string) =>
    requests.get<ObjectsWithoutInitialResponse>(
      `/dashboard/reminders/objects-need-initial-inspection?${filter}`
    ),
  getContracts: (
    type: ContractTypes,
    limit: number,
    offset: number,
    filter: string
  ) =>
    requests.get<ClosingContractsResponse>(
      `/dashboard/reminders/closing-${type}-contracts?limit=${limit}&offset=${offset}${filter}`
    ),
  getPendingContracts: (
    type: ContractTypes,
    limit: number,
    offset: number,
    filter: string
  ) =>
    requests.get<PendingContractsResponse>(
      `/dashboard/reminders/pending-${type}-contracts?limit=${limit}&offset=${offset}${filter}`
    ),
  getUpcomingInspections: (filter: string) =>
    requests.get<UpcomingInspectionResponse>(
      `/dashboard/reminders/upcoming-inspections?${filter}`
    ),
  getCompanies: (
    limit: number,
    offset: number,
    filter: string,
    isArchive?: boolean
  ) =>
    requests.get<CompaniesDashboardResponse>(
      `/dashboard/reminders/companies-with-wrong-requisites?limit=${limit}&offset=${offset}&archived=${!!isArchive}${filter}`
    ),
  getApartmentsServices: (
    limit: number,
    offset: number,
    filter: string,
    isArchive?: boolean
  ) =>
    requests.get<ApartmentServicesResponse>(
      `/dashboard/reminders/objects-without-services?limit=${limit}&offset=${offset}&archived=${!!isArchive}${filter}`
    ),
  getRejectedAppraisals: (
    limit: number,
    offset: number,
    filter: string,
    isArchive?: boolean
  ) =>
    requests.get<RejectedAppraisalsResponse>(
      `/dashboard/reminders/rejected-appraisals?limit=${limit}&offset=${offset}&archived=${!!isArchive}${filter}`
    ),
  getMetersWithDeviating: (
    limit: number,
    offset: number,
    filter: string,
    isArchive?: boolean
  ) =>
    requests.get<MetersWithDeviatingValueResponse>(
      `/dashboard/reminders/meters-with-deviating-values?limit=${limit}&offset=${offset}&archived=${!!isArchive}${filter}`
    ),
  getImpairmentsInspections: (
    limit: number,
    offset: number,
    filter: string,
    isArchive?: boolean
  ) =>
    requests.get<ImpairmentInspectionsResponse>(
      `/dashboard/reminders/inspections-require-impairments?limit=${limit}&offset=${offset}&archived=${!!isArchive}${filter}`
    ),
  getUnsignedInspections: (limit: number, offset: number, filter: string) =>
    requests.get<UnsignedInspectionsResponse>(
      `/dashboard/reminders/unsigned-inspections?limit=${limit}&offset=${offset}${filter}`
    ),

  archiveDashboardItem: (id: number) =>
    requests.post(`/dashboard/reminders/${id}/archive`),
  unArchiveDashboardItem: (id: number) =>
    requests.post(`/dashboard/reminders/${id}/unarchive`),
}

export default DashboardAgent
