import React from "react"
import { observer } from "mobx-react"
import AboutPageHeader from "../../../../components/common/AboutPageHeader"
import useSCStore from "../../store"
import { Routes } from "../../../../types/route"
import ContractStatus from "../../../../components/common/ContractStatus"

function SCOverviewHeader() {
  const { contractInfoStore } = useSCStore()

  const serviceContractInfo = [
    {
      title: contractInfoStore.signDate?.toFormat("dd.MM.yyyy") || "-",
      subtitle: "Sign date",
    },
    {
      title: contractInfoStore.startDate?.toFormat("dd.MM.yyyy") || "-",
      subtitle: "Start date",
    },
    {
      title:
        contractInfoStore[
          contractInfoStore.isClosed ? "closeDate" : "endDate"
        ]?.toFormat("dd.MM.yyyy") || "-",
      subtitle: contractInfoStore.isClosed ? "Close date" : "End date",
    },
  ]

  return (
    <AboutPageHeader
      title={contractInfoStore.number}
      status={<ContractStatus status={contractInfoStore.status} />}
      subtitle={
        <>
          {contractInfoStore.id}
          {contractInfoStore.apartment && (
            <a href={`${Routes.apartments}/${contractInfoStore.apartment?.id}`}>
              {" "}
              • {contractInfoStore.apartment?.address}
            </a>
          )}
        </>
      }
      info={serviceContractInfo}
    />
  )
}

export default observer(SCOverviewHeader)
