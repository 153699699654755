import React from "react"
import { MapComponent } from "kui-crm"
import styled from "@emotion/styled"
import { Grid } from "kui-basic"
import { observer } from "mobx-react"
import MapWrapper from "../../../../../../../common/MapWrapper"
import useStore from "../../../../../../../../hooks/useStore"
import { InspectionMapBackgroundProps } from "./types"

const InspectionMapBackground = (props: InspectionMapBackgroundProps) => {
  const { coords } = props
  const { mapStore } = useStore()
  const hasCoords = Number(coords?.lat) > 0

  return (
    <StyledMapWrapper item xs={12}>
      <MapWrapper>
        <MapComponent
          center={hasCoords ? coords : { lat: 55.752626, lng: 37.614985 }}
          withMarker={hasCoords}
          zoom={13}
          setMap={mapStore.setMap}
        />
      </MapWrapper>
    </StyledMapWrapper>
  )
}

export default observer(InspectionMapBackground)

const StyledMapWrapper = styled(Grid)`
  overflow: hidden;
  min-height: 108px;
`
