import React from "react"
import styled from "@emotion/styled"
import { Caption } from "kui-basic"
import { CalendarQuickButtonDropdownProps } from "./types"

const CalendarQuickButtonDropdown = (
  props: CalendarQuickButtonDropdownProps
) => {
  const { options, onClick } = props

  return (
    <>
      {options.map((option) => (
        <StyledMenuItem key={option.label} onClick={() => onClick(option)}>
          <Caption size="xs" weight={500}>
            {option.label}
          </Caption>
        </StyledMenuItem>
      ))}
    </>
  )
}

export default CalendarQuickButtonDropdown

const StyledMenuItem = styled.div`
  border-radius: 12px;
  padding: 13px 8px;
  cursor: pointer;
  transition: all ease-out 0.2s;
  text-align: left;
  &:hover {
    background-color: ${({ theme }) => theme.palette.background.light1};
  }
`
