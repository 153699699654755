import React from "react"
import { useFieldArray } from "react-hook-form"
import { Grid } from "kui-basic"
import { observer } from "mobx-react"
import { HeadingWithButton } from "kui-crm"
import { EditableFixedCostBlockProps } from "./types"
import EditableFixedCostItem from "../EditableFixedCostItem"
import useSCStore from "../../store"

function EditableFixedCostBlock({ form }: EditableFixedCostBlockProps) {
  const { editor } = useSCStore()
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "fixedCosts" as const,
  })

  const addFixedCost = () => {
    append({ cost: 0, payer: "landlord", name: "" })
  }

  return (
    <>
      <HeadingWithButton
        showButton={editor.isEditing}
        onClick={addFixedCost}
        title="Fixed costs"
      />
      <Grid container spacing={2} alignItems="center">
        {fields.map((field, index) => (
          <EditableFixedCostItem
            key={field.id}
            index={index}
            form={form}
            onDelete={remove}
            disabled={!editor.isEditing}
          />
        ))}
      </Grid>
    </>
  )
}

export default observer(EditableFixedCostBlock)
