import React from "react"
import { observer } from "mobx-react"
import { MetersWithDeviatingArchiveProps } from "./types"
import PaginationList from "../../../common/PaginationList"
import MeterWithDeviatingRow from "../MeterWithDeviatingRow"

const MetersWithDeviatingArchive = (props: MetersWithDeviatingArchiveProps) => {
  const { metersStore, isOpen } = props
  const { archiveMeters, fetchArchive, archivePaginator, archiveLoader } =
    metersStore

  return (
    <PaginationList
      paginator={archivePaginator}
      loader={archiveLoader}
      fetchAll={fetchArchive}
      isOpen={isOpen}
    >
      {archiveMeters.map((meter) => (
        <MeterWithDeviatingRow isArchive key={meter.id} meter={meter} />
      ))}
    </PaginationList>
  )
}

export default observer(MetersWithDeviatingArchive)
