import React from "react"
import { observer } from "mobx-react"
import { useToggle } from "kui-utils"
import { ApartmentDocumentMenuProps } from "./types"
import MenuButton from "../../../../../../components/common/MenuButton"
import ApartmentDocumentEditingModal from "../ApartmentDocumentEditingModal"
import useApartmentStore from "../../../../store"

const ApartmentDocumentMenu = (props: ApartmentDocumentMenuProps) => {
  const { variant, disabled } = props
  const [isOpen, handleOpen, handleClose] = useToggle()
  const { overviewStore } = useApartmentStore()

  const handleDelete = () => {
    overviewStore.removeApartmentDocument(variant)
  }

  const menuOptions = [
    { label: "Edit", handleClick: handleOpen },
    { label: "Delete", handleClick: handleDelete },
  ]

  return (
    <>
      <MenuButton disabled={disabled} options={menuOptions} />
      <ApartmentDocumentEditingModal
        variant={variant}
        open={isOpen}
        handleClose={handleClose}
      />
    </>
  )
}

export default observer(ApartmentDocumentMenu)
