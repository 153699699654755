import React from "react"
import _ from "lodash"
import { Caption } from "kui-basic"
import DashboardListRow from "../../../common/DashboardListRow"
import { InspectionImpairmentsRowProps } from "./types"
import { defaultApartmentImageURL } from "../../../../../../utils/content/constants"
import { DynamicRoutes } from "../../../../../../types/route"
import DashboardArchiveRow from "../../../common/DashboardArchiveRow"

const InspectionImpairmentsRow = (props: InspectionImpairmentsRowProps) => {
  const { inspection, isArchive } = props
  const label = `${_.capitalize(
    inspection.type
  )} inspection ${inspection.date?.toFormat("dd.MM.yyyy")}`
  const inspectionLink = DynamicRoutes.inspection(
    inspection.apartment.id,
    inspection.id
  )
  const description = (
    <Caption size="xs" weight={500} color="fiftyP">
      {inspection.apartment.address}
    </Caption>
  )

  const rowProps = {
    logo: inspection.apartment.previewImage || defaultApartmentImageURL,
    label,
    description,
    link: inspectionLink,
  }

  return isArchive ? (
    <DashboardArchiveRow {...rowProps} onClick={inspection.restoreInspection} />
  ) : (
    <DashboardListRow {...rowProps} onClick={inspection.archiveInspection} />
  )
}

export default InspectionImpairmentsRow
