import React, { useEffect, useRef, useState } from "react"
import styled from "@emotion/styled"
import { Button } from "kui-basic"
import { CalendarIcon } from "kui-icon"
import CalendarWithMonth from "../CalendarWithMonth"
import {
  CalendarButtonStylesProps,
  CalendarWithMonthButtonProps,
} from "./types"

function CalendarWithMonthButton(props: CalendarWithMonthButtonProps) {
  const { minDate, initialDate, title, handleChange, maxDate, endIcon } = props
  const { renderMonthContent } = props
  const [isCalendarOpen, setCalendarOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const closeDropdown = () => {
      if (ref.current?.children && ref.current?.children.length > 1) {
        setCalendarOpen(true)
      } else {
        setCalendarOpen(false)
      }
    }

    document.addEventListener("click", closeDropdown)
    return () => {
      document.removeEventListener("click", closeDropdown)
    }
  }, [ref])

  return (
    <CalendarWithMonth
      ref={ref}
      customInput={
        <StyledButton
          isActive={isCalendarOpen}
          size="xs"
          variant="whiteWithGray"
          captionSize="xs"
          startIcon={<CalendarIcon width={14} height={15} />}
          endIcon={endIcon}
        >
          {title || ""}
        </StyledButton>
      }
      initialDate={initialDate}
      minDate={minDate?.set({ day: 1 }).toJSDate()}
      maxDate={maxDate && maxDate.set({ day: 1 }).toJSDate()}
      handleChangeDate={handleChange}
      renderMonthContent={renderMonthContent}
    />
  )
}

export default CalendarWithMonthButton

const StyledButton = styled(Button)<CalendarButtonStylesProps>`
  transition: background-color, box-shadow linear 0.3s;
  border: ${({ isActive, theme }) =>
    `1px solid ${
      isActive ? theme.palette.brand.main : theme.palette.grey.fifteenB
    }`};
  p {
    color: ${({ isActive, theme }) =>
      isActive
        ? theme.palette.grey.seventy
        : theme.palette.grey.fiftyP} !important;
  }
  svg {
    path {
      fill: ${({ isActive, theme }) =>
        isActive ? theme.palette.grey.seventy : theme.palette.grey.fiftyP};
    }
  }
`
