import React from "react"
import { useNavigate } from "react-router-dom"
import { observer } from "mobx-react"
import PageHeader from "../../../../../components/common/PageHeader"
import { Routes } from "../../../../../types/route"
import useCompanyStore from "../../../store"

function CompanyHeader() {
  const { overviewStore, deleteCompany } = useCompanyStore()

  const navigate = useNavigate()

  const handleOnBackClick = () => {
    navigate(Routes.companies)
  }

  const handleDelete = async () => {
    if (overviewStore?.id) {
      const confirmation = window.confirm(
        "Are you sure you want to delete the company?"
      )
      if (confirmation) {
        await deleteCompany(overviewStore?.id)
        handleOnBackClick()
      }
    }
  }

  const breadcrumbs = [
    { link: Routes.companies, title: "Companies" },
    { link: null, title: overviewStore?.name },
  ]

  const menuOptions = [{ label: "Delete", handleClick: handleDelete }]

  return (
    <PageHeader
      handleClick={handleOnBackClick}
      options={menuOptions}
      breadcrumbs={breadcrumbs}
    />
  )
}

export default observer(CompanyHeader)
