import React, { ForwardedRef, forwardRef } from "react"
import styled from "@emotion/styled"
import { HiddenInputFileProps } from "./types"

const HiddenInputFile = forwardRef(
  (props: HiddenInputFileProps, ref: ForwardedRef<HTMLInputElement>) => (
    <StyledInput
      id="file_input"
      ref={ref}
      multiple
      type="file"
      data-testid="file_input"
      {...props}
    />
  )
)

export default HiddenInputFile

const StyledInput = styled.input`
  display: none;
`
