import React from "react"
import { Box, Caption } from "kui-basic"
import { ModalLabelProps } from "./types"

const ModalLabel = ({ label }: ModalLabelProps) => (
  <Box mb={2} pr={5} pl={5}>
    <Caption size="s" color="fiftyP">
      {label}
    </Caption>
  </Box>
)

export default ModalLabel
