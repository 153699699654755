import React, { ChangeEvent, useState } from "react"
import styled from "@emotion/styled"
import { Path, PathValue } from "react-hook-form"
import { Caption, Box, Grid, Switch } from "kui-basic"
import { RadioGroupWithLabel } from "kui-complex"
import { ContractFilesSettingsProps, SettingsFields } from "./types"
import CheckboxGroupWithTitle from "../../ui/CheckboxGroupWithTitle"

const contractOptions = [
  { value: "service_contract", label: "Service" },
  { value: "renter_contract", label: "Rental" },
]

function DocumentSettings<T extends object>(
  props: ContractFilesSettingsProps<T>
) {
  const { selected, contractType, form } = props
  const [isEnableGlobal, setIsEnableGlobal] = useState(true)
  const name =
    typeof selected === "number" ? `settings.${selected}` : "globalSettings"

  const visibilityOptions = [
    { name: `${name}.landlord`, label: "Landlord" },
    { name: `${name}.tenant`, label: "Tenant" },
  ]

  const handleGlobalChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsEnableGlobal((prev) => !prev)

    if (e.target.checked && typeof selected === "number") {
      const currentSettings = form.control._formValues.settings[selected]
      applySettingsToAll(currentSettings)
    }
  }

  const applySettingsToAll = (appliedSettings: SettingsFields) => {
    const settings = form.getValues(
      "settings" as Path<T>
    ) as Array<SettingsFields>
    settings.forEach((_, index: number) => {
      form.setValue(
        `settings.${index}` as Path<T>,
        appliedSettings as PathValue<T, any>
      )
    })
  }

  const disableGlobalSettings = () => {
    if (typeof selected === "number") setIsEnableGlobal(false)
    else if (isEnableGlobal) {
      const globalSettings = form.getValues("globalSettings" as Path<T>)
      applySettingsToAll(globalSettings)
    }
  }

  return (
    <StyledBox pt={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Caption size="xs" weight={600}>
            SETTING THE SELECTED FILE
          </Caption>
        </Grid>
        {contractType && (
          <Grid item xs={12}>
            <RadioGroupWithLabel
              key={`${name}.contractType`}
              name={`${name}.contractType`}
              form={form}
              title="Contract"
              options={contractOptions}
              onChange={disableGlobalSettings}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <CheckboxGroupWithTitle
            key={`${name}.visibility`}
            title="Visibility"
            options={visibilityOptions}
            form={form}
            onChange={disableGlobalSettings}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="space-between" alignItems="center">
            <Caption size="xs" weight={600}>
              APPLY TO ALL
            </Caption>
            <Switch checked={isEnableGlobal} onChange={handleGlobalChange} />
          </Grid>
        </Grid>
      </Grid>
    </StyledBox>
  )
}

export default DocumentSettings

const StyledBox = styled(Box)`
  width: 100%;
`
