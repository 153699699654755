import * as React from "react"
import styled from "@emotion/styled"
import { Grid } from "kui-basic"
import { observer } from "mobx-react"
import getPromotionFeatures from "./content"
import PromotionFeature from "../PromotionFeature"
import usePromotionStore from "../../../../../store"

const PromotionFeatures = () => {
  const { descriptionStore } = usePromotionStore()
  const promotionFeatures = getPromotionFeatures(descriptionStore)

  return (
    <PropertiesContainer container alignItems="center">
      {promotionFeatures.map((item) => (
        <PromotionFeature key={item.label} feature={item} />
      ))}
    </PropertiesContainer>
  )
}

export default observer(PromotionFeatures)

const PropertiesContainer = styled(Grid)`
  margin-bottom: 18px;
`
