import React from "react"
import { Tooltip } from "kui-crm"
import { PopupWithCheckListProps } from "./types"
import CheckList from "./CheckList"

const PopupWithCheckList = (props: PopupWithCheckListProps) => {
  const { button, popupActive, strategy, placement, className, ...other } =
    props

  if (!popupActive) return button

  return (
    <Tooltip
      placement={placement || "bottom"}
      content={<CheckList {...other} />}
      strategy={strategy}
      className={className}
    >
      {button}
    </Tooltip>
  )
}

export default PopupWithCheckList
