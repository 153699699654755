import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Grid } from "kui-basic"
import { observer } from "mobx-react"
import useClientStore from "../../store"
import ClientCompanyInfoFormSchema from "./schema"
import DocumentsBlockWithForm from "../../../../components/common/DocumentsBlockWithForm"
import clientCompanyFieldsGroups from "./fields"
import UpdatedFieldsGroup from "../../../../components/common/UpdatedFieldsGroup"
import { ClientCompanyInfoFormFields } from "./types"
import useEditor from "../../../../hooks/useEditor"

const ClientCompanyInfoForm = () => {
  const { editor, companyInfoStore, overviewStore } = useClientStore()
  const companyInfo = companyInfoStore.fields
  const infoEditor = useEditor()

  const form = useForm<ClientCompanyInfoFormFields>({
    defaultValues: { ...companyInfo },
    resolver: yupResolver(ClientCompanyInfoFormSchema),
  })

  const handleSubmit = (data: Partial<ClientCompanyInfoFormFields>) => {
    if (overviewStore?.id) {
      companyInfoStore?.patchClientCompanyInfo(overviewStore.id, data)
    }
    editor?.endEditing()
    infoEditor.endEditing()
  }

  useEffect(() => {
    if (editor?.isAllowedToSendForm) {
      form.handleSubmit(handleSubmit, editor?.blockToSendForm)()
    }
  }, [editor?.isAllowedToSendForm])

  return (
    <Grid container spacing={3}>
      <Grid item xs={8}>
        <Grid container spacing={2}>
          {clientCompanyFieldsGroups.map((fieldsGroup) => (
            <UpdatedFieldsGroup
              form={form}
              disabled={!editor.isEditing}
              editor={infoEditor}
              {...fieldsGroup}
            />
          ))}
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <DocumentsBlockWithForm
          form={form}
          disabled={!editor?.isEditing}
          documents={companyInfoStore?.documentsStore.documents}
          loader={companyInfoStore?.documentsStore.loader}
        />
      </Grid>
    </Grid>
  )
}

export default observer(ClientCompanyInfoForm)
