import React, { useEffect } from "react"
import { Box, Heading } from "kui-basic"
import { observer } from "mobx-react"
import useTariffStore from "../../store"
import Container from "../../../../components/ui/Container"
import Loading from "../../../../components/common/Loading"
import TariffCompaniesTable from "../TariffCompaniesTable"

function TariffCompaniesTab() {
  const { companiesStore, overviewStore, resource } = useTariffStore()
  const tariffId = overviewStore.tariffInfoStore?.id

  useEffect(() => {
    if (tariffId && resource) {
      companiesStore.fetchTariffCompanies(tariffId, resource)
    }
  }, [tariffId, resource])

  if (companiesStore.loader.isLoading) {
    return <Loading />
  }

  return (
    <>
      <Container>
        <Box pt={2} pb={2}>
          <Heading size="h4" weight={700}>
            Companies
          </Heading>
        </Box>
      </Container>
      <TariffCompaniesTable />
    </>
  )
}

export default observer(TariffCompaniesTab)
