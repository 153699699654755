import React from "react"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import { Grid } from "kui-basic"
import ExpensesSummaryTable from "../ExpensesSummaryTable"
import { ApartmentClientsCalculationProps } from "./types"
import InvoiceBillet from "../InvoiceBillet"

function ExpensesClientsCalculation({
  payouts,
}: ApartmentClientsCalculationProps) {
  return (
    <StyledPayoutsWrapper>
      <InvoiceBillet payout={payouts.tenantPayout} />

      <Grid container>
        <StyledTenantPayoutWrapper item xs={6}>
          <ExpensesSummaryTable type="left" payout={payouts.tenantPayout} />
        </StyledTenantPayoutWrapper>
        <Grid item xs={6}>
          <ExpensesSummaryTable type="right" payout={payouts.landlordPayout} />
        </Grid>
      </Grid>
    </StyledPayoutsWrapper>
  )
}

export default observer(ExpensesClientsCalculation)

const StyledTenantPayoutWrapper = styled(Grid)`
  border-right: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
`

const StyledPayoutsWrapper = styled.div`
  position: relative;
  &:last-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
  }
  &:not(&:first-child) {
    > div:first-child {
      &::after {
        content: "";
        position: absolute;
        top: 11px;
        left: 0;
        display: block;
        width: 100%;
        height: 1px;
        background: ${({ theme }) => theme.palette.grey.fifteenB};
      }
    }
  }
  &:first-child {
    > div:first-child {
      padding-top: 12px;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: calc(50% - 1px);
        display: block;
        width: 1px;
        height: 12px;
        background: ${({ theme }) => theme.palette.grey.fifteenB};
      }
    }
  }
`
