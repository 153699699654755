import React from "react"
import { observer } from "mobx-react"
import useMetersStore from "./store"
import MetersTabPanel from "./components/MetersTabsPanel"
import usePaginationPage from "../../hooks/usePaginationPage"

function MetersPage() {
  const { resource } = useMetersStore()
  const { paginator, filter, fetchAll } = resource

  usePaginationPage(paginator, fetchAll, filter)

  return <MetersTabPanel />
}

export default observer(MetersPage)
