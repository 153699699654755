import requests from "./index"
import { PatchApartmentParams } from "../pages/ApartmentsPage/types/api/apartmentsAPI"

const ApartmentsAgent = {
  all: (offset: number, limit: number, params: string) =>
    requests.get(`/apartments?limit=${limit}&offset=${offset}${params}`),
  forMap: (params: string) => requests.get(`/apartments/for_map?${params}`),
  getById: (id: number) => requests.get(`/apartments/${id}`),
  create: (data: PatchApartmentParams) => requests.post("/apartments", data),
  patch: (id: number, data: PatchApartmentParams) =>
    requests.patch(`/apartments/${id}`, data),
  delete: (id: number) => requests.delete(`/apartments/${id}`),
  getApartmentResources: (apartmentId: number) =>
    requests.get(`/apartments/${apartmentId}/used_resources`),
  getApartmentOwners: (apartmentId: number) =>
    requests.get(`/apartments/${apartmentId}/ownership_users`),
  getLockers: () => requests.get(`/lockers`),
}

export default ApartmentsAgent
