import { FormWrapper } from "kui-crm"
import React, { useRef } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Loader, resHandler } from "kui-utils"
import { observer } from "mobx-react"
import { ServiceApartmentFormFields, ServiceApartmentFormProps } from "./types"
import ServiceApartmentFormSchema from "./schema"
import ApartmentAutocomplete from "../../../entityInputs/ApartmentAutocomplete"
import { ApartmentLiteParams } from "../../../../types/store/apartments"
import ApartmentServicesStore from "../../../../store/shared/apartmentService/ApartmentServicesStore"

const ServiceApartmentForm = (props: ServiceApartmentFormProps) => {
  const { formMaker } = props
  const loaderRef = useRef(new Loader())
  const loader = loaderRef.current
  const form = useForm<ServiceApartmentFormFields>({
    defaultValues: {
      apartment: formMaker.fields?.apartment,
      payerCode: formMaker.fields?.payerCode,
      communalServicesAccount: formMaker.fields?.communalServicesAccount,
      hasRentalContract: formMaker.fields?.hasRentalContract,
    },
    resolver: yupResolver(ServiceApartmentFormSchema),
  })

  const handleSubmit = (data: ServiceApartmentFormFields) => {
    formMaker.updateFormFields(data)
    formMaker.nextStep()
  }

  const handleApartmentSelect = async (item: ApartmentLiteParams | null) => {
    if (item) {
      form.setValue("apartment.apartmentNumber", item.apartmentNumber || "")
      form.setValue("payerCode", item?.payerCode)
      form.setValue("communalServicesAccount", item?.communalServicesAccount)
      form.setValue("hasRentalContract", item.hasRentalContract)

      if (item.id) {
        loader.startLoading("apartment period")

        const response = await ApartmentServicesStore.getLastOpenedPeriod(
          item.id
        )

        resHandler(response, loader, (res) => {
          form.setValue("apartment.periodId", res.id)
        })
      }
    }
  }

  return (
    <FormWrapper
      isLoading={loader.isLoading}
      form={form}
      variant="next"
      label="Next"
      onSubmit={handleSubmit}
    >
      <ApartmentAutocomplete
        label="Object"
        form={form}
        onSelect={handleApartmentSelect}
      />
    </FormWrapper>
  )
}

export default observer(ServiceApartmentForm)
