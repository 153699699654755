import { makeAutoObservable } from "mobx"
import { DateTime } from "luxon"
import to from "await-to-js"
import { Loader, resHandler } from "kui-utils"
import ApartmentPaymentsStore from "./ApartmentPayments"
import ApartmentPayoutsStore from "./ApartmentPayoutsStore"
import ApartmentExpensesAgent from "../../../../../../agent/ApartmentExpenses"
import {
  AgentReportModel,
  DepositFileModel,
} from "../../types/api/expensesSummaryAPI"
import ApartmentExpensesStore from "../ApartmentExpenses"
import ApartmentBalancesSummaryStore from "./ApartmentBalancesSummary"

const API_URL = process.env.REACT_APP_API_URL

class ApartmentSummaryStore {
  expensesStore: ApartmentExpensesStore

  paymentSummaryStore: ApartmentBalancesSummaryStore

  paymentsStore: ApartmentPaymentsStore

  payoutsStore: ApartmentPayoutsStore

  depositFile: string | null

  loader: Loader

  actionLoader: Loader

  constructor(expensesStore: ApartmentExpensesStore) {
    this.paymentSummaryStore = new ApartmentBalancesSummaryStore(this)
    this.paymentsStore = new ApartmentPaymentsStore(expensesStore)
    this.payoutsStore = new ApartmentPayoutsStore(this)
    this.loader = new Loader(true)
    this.actionLoader = new Loader()
    this.expensesStore = expensesStore
    this.depositFile = null
    makeAutoObservable(this)
  }

  initSummaryTab = async (
    apartmentId: number,
    contractId: number | undefined,
    periodId: number
  ) => {
    this.loader.startLoading()

    try {
      await this.paymentSummaryStore.getPaymentSummary(
        apartmentId,
        contractId,
        periodId,
        true
      )
    } finally {
      await this.payoutsStore.fetchPayouts(
        apartmentId,
        contractId,
        periodId,
        true
      )
    }
    this.loader.endLoading()
  }

  getAgentReportPDF = async (apartmentId: number, date: DateTime) => {
    this.actionLoader.startLoading("agent report pdf")

    const [err, res] = await to<AgentReportModel>(
      ApartmentExpensesAgent.getAgentReportPDF(
        apartmentId,
        date.year,
        date.month
      )
    )

    if (err || !res?.report) this.actionLoader.setError("agent report", err)
    this.actionLoader.endLoading()

    return res?.report
  }

  calcDepositAndRefresh = async (
    apartmentId: number,
    contractId: number | undefined,
    periodId: number
  ) => {
    await this.calcDeposit(apartmentId)
    await this.payoutsStore.fetchPayouts(
      apartmentId,
      contractId,
      periodId,
      true
    )
  }

  calcDeposit = async (apartmentId: number) => {
    this.actionLoader.startLoading("deposit calculation")

    const response = await to<DepositFileModel>(
      ApartmentExpensesAgent.calcDeposit(apartmentId)
    )

    resHandler(response, this.actionLoader, () => {
      this.expensesStore.periodsStore.selectedPeriod?.setDepositCalculated()
    })
  }

  static getPreviewAgentReportLink = (apartmentId: number, date: DateTime) =>
    `${API_URL}/apartments/${apartmentId}/expenses/agent-reports/preview?year=${date.year}&month=${date.month}`

  cleanSummaryTab = () => {
    this.paymentSummaryStore = new ApartmentBalancesSummaryStore(this)
    this.paymentsStore = new ApartmentPaymentsStore(this.expensesStore)
    this.payoutsStore = new ApartmentPayoutsStore(this)
    this.loader.endLoading()
  }
}

export default ApartmentSummaryStore
