import React, { useEffect } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { observer } from "mobx-react"
import { Box, Grid } from "kui-basic"
import { yupResolver } from "@hookform/resolvers/yup"
import { CompanyBankInfoFormProps, CompanyBanksInfoFields } from "./types"
import CompanyBankInfoFields from "../../components/tabs/CompanyBankInfoTab/CompanyBankInfoFields"
import useCompanyStore from "../../store"
import DocumentsBlockWithForm from "../../../../components/common/DocumentsBlockWithForm"
import CompanyBankInfoFormSchema from "./schema"

function CompanyBankInfoForm({ handleSubmit }: CompanyBankInfoFormProps) {
  const { bankInfoStore, editor } = useCompanyStore()
  const form = useForm<CompanyBanksInfoFields>({
    defaultValues: {
      banks: bankInfoStore.banksStore.banks.map((bank) => ({
        ...bank,
        banksInfoStore: null,
      })),
      documents: bankInfoStore.documentsStore.documents,
    },
    resolver: yupResolver(CompanyBankInfoFormSchema),
  })

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "banks",
    keyName: "key",
  })

  const bankInfoProps = {
    addBankInfo: append,
    removeBankInfo: remove,
    form,
  }

  useEffect(() => {
    if (editor.isAllowedToSendForm) {
      form.handleSubmit(handleSubmit, editor.blockToSendForm)()
    }
  }, [editor.isAllowedToSendForm])

  return (
    <Box mt={3} pb={3}>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <CompanyBankInfoFields
            key={fields[0]?.key}
            index={0}
            {...bankInfoProps}
          />
          {fields.slice(1)?.map((bankInfo, index) => (
            <CompanyBankInfoFields
              key={fields[index + 1]?.key}
              index={index + 1}
              {...bankInfoProps}
            />
          ))}
        </Grid>
        <Grid item xs={4}>
          <DocumentsBlockWithForm
            form={form}
            disabled={!editor.isEditing}
            documents={bankInfoStore.documentsStore.documents}
            loader={bankInfoStore.documentsStore.loader}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default observer(CompanyBankInfoForm)
