import React from "react"

const SmallScreenIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.87211 9.8824C9.8686 9.89365 9.86719 9.9053 9.86719 9.91306V12.6185C9.86719 12.8946 9.64333 13.1185 9.36719 13.1185C9.09105 13.1185 8.86719 12.8946 8.86719 12.6185V9.91306C8.86719 9.7118 8.92563 9.45992 9.09469 9.24703C9.27863 9.01541 9.56145 8.87197 9.91146 8.87197H12.6328C12.909 8.87197 13.1328 9.09583 13.1328 9.37197C13.1328 9.64812 12.909 9.87197 12.6328 9.87197H9.91146C9.89344 9.87197 9.88164 9.87362 9.87476 9.87507C9.87394 9.87701 9.87303 9.87944 9.87211 9.8824Z"
      fill="#7F8285"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.11283 9.86729C4.10157 9.86378 4.08993 9.86237 4.08217 9.86237H1.37675C1.10061 9.86237 0.876755 9.63851 0.876755 9.36237C0.876755 9.08623 1.10061 8.86237 1.37675 8.86237H4.08217C4.28343 8.86237 4.5353 8.92081 4.7482 9.08988C4.97981 9.27381 5.12325 9.55663 5.12325 9.90664V12.628C5.12325 12.9041 4.89939 13.128 4.62325 13.128C4.34711 13.128 4.12325 12.9041 4.12325 12.628V9.90664C4.12325 9.88862 4.12161 9.87682 4.12016 9.86994C4.11821 9.86912 4.11578 9.86822 4.11283 9.86729Z"
      fill="#7F8285"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.87211 4.10806C9.86859 4.0968 9.86718 4.08516 9.86718 4.07739V1.37198C9.86718 1.09584 9.64333 0.871982 9.36718 0.871982C9.09104 0.871982 8.86718 1.09584 8.86718 1.37198V4.07739C8.86718 4.27865 8.92563 4.53053 9.09469 4.74342C9.27862 4.97504 9.56145 5.11848 9.91146 5.11848H12.6328C12.909 5.11848 13.1328 4.89462 13.1328 4.61848C13.1328 4.34233 12.909 4.11848 12.6328 4.11848H9.91146C9.89343 4.11848 9.88164 4.11684 9.87475 4.11538C9.87394 4.11344 9.87303 4.11101 9.87211 4.10806Z"
      fill="#7F8285"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.10326 4.13265C4.092 4.13617 4.08036 4.13758 4.0726 4.13758H1.36719C1.09105 4.13758 0.867188 4.36143 0.867188 4.63758C0.867188 4.91372 1.09105 5.13758 1.36719 5.13758H4.0726C4.27386 5.13758 4.52574 5.07913 4.73863 4.91007C4.97024 4.72614 5.11368 4.44331 5.11368 4.09331V1.37195C5.11368 1.09581 4.88982 0.871948 4.61368 0.871948C4.33754 0.871948 4.11368 1.09581 4.11368 1.37195V4.09331C4.11368 4.11133 4.11204 4.12312 4.11059 4.13001C4.10864 4.13082 4.10622 4.13173 4.10326 4.13265Z"
      fill="#7F8285"
    />
  </svg>
)

export default SmallScreenIcon
