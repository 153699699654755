import { PatchCompanyParams } from "kui-crm/types"
import requests from "./index"
import {
  AddObjectsToCompanyRequest,
  PatchCompanyBankRequest,
  PatchCompanyInfoRequest,
  PatchCompanyOfficeRequest,
  PostCompanyBankRequest,
  PostCompanyOfficeRequest,
  RemoveObjectsToCompanyRequest,
} from "../pages/CompanyPage/types/api/companyAPI"
import TariffsAgent from "./Tariffs"
import CompaniesAgent from "./Companies"

const CompanyAgent = {
  getById: (id: number) => requests.get(`/companies/${id}`),
  patch: (id: number, data: PatchCompanyParams) =>
    requests.patch(`/companies/${id}`, data),
  delete: (id: number) => requests.delete(`/companies/${id}`),
  postOffice: (id: number, data: PostCompanyOfficeRequest) =>
    requests.post(`/companies/${id}/offices`, data),
  patchOffice: (
    companyId: number,
    officeId: number,
    data: PatchCompanyOfficeRequest
  ) => requests.patch(`/companies/${companyId}/offices/${officeId}`, data),
  patchCompany: (companyId: number, data: PatchCompanyInfoRequest) =>
    requests.patch(`/companies/${companyId}`, data),
  deleteOffice: (companyId: number, officeId: number) =>
    requests.delete(`/companies/${companyId}/offices/${officeId}`),
  getCompanyTariffs: (id: number) => requests.get(`/companies/${id}/tariffs`),
  getCompanyObjects: (id: number) =>
    requests.get(`/companies/${id}/apartments`),
  addObjectsToCompany: (id: number, data: AddObjectsToCompanyRequest) =>
    requests.put(`/companies/${id}/apartments/add`, data),
  deleteObjectsToCompany: (id: number, data: RemoveObjectsToCompanyRequest) =>
    requests.put(`/companies/${id}/apartments/remove`, data),
  getCompanyBanks: (id: number) =>
    requests.get(`/companies/${id}/billing-info`),
  postCompanyBank: (id: number, data: PostCompanyBankRequest) =>
    requests.post(`/companies/${id}/billing-info`, data),
  patchCompanyBank: (
    id: number,
    bankId: number,
    data: PatchCompanyBankRequest
  ) => requests.patch(`/companies/${id}/billing-info/${bankId}`, data),
  deleteCompanyBank: (id: number, bankId: number) =>
    requests.delete(`/companies/${id}/billing-info/${bankId}`),
  getDocuments: (id: number) =>
    requests.get(`/companies/${id}/billing-info/documents`),
  postDocument: (data: FormData, id: number) =>
    requests.post(`/companies/${id}/billing-info/documents`, data),
  patchDocument: (documentId: number, data: FormData, id: number) =>
    requests.patch(
      `/companies/${id}/billing-info/documents/${documentId}`,
      data
    ),
  deleteDocument: (documentId: number, id: number) =>
    requests.delete(`/companies/${id}/billing-info/documents/${documentId}`),
  getConnectedCompanies: TariffsAgent.getConnectedCompanies,
  deleteLogo: (companyId: number) =>
    requests.delete(`/companies/${companyId}/logo`),
  changeLogo: CompaniesAgent.createLogo,
  parseRequisites: (companyId: number, data: FormData) =>
    requests.post(`/companies/${companyId}/billing-info/parse-qr`, data),
}

export default CompanyAgent
