import React from "react"
import { NewContractRowProps } from "./types"
import ContractWithPriceRow from "../../../common/ContractWithPriceRow"

const NewContractRow = (props: NewContractRowProps) => {
  const { contract } = props
  const { contract: contractParams, apartment, rentPrice } = contract

  return (
    <ContractWithPriceRow
      contract={contractParams}
      apartment={apartment}
      value={rentPrice}
    />
  )
}

export default NewContractRow
