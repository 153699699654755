import React from "react"
import { observer } from "mobx-react"
import { Box, Grid, Heading } from "kui-basic"
import { InputByType } from "kui-crm"
import styled from "@emotion/styled"
import userBankTaxInfoFieldsGroup from "./fields"
import useClientStore from "../../../../store"
import { ClientBankTaxFieldsProps } from "./types"

function ClientBankTaxFields({ form }: ClientBankTaxFieldsProps) {
  const { editor } = useClientStore()

  return (
    <>
      <Box mb={2} mt={1}>
        <Heading size="h4" weight={700}>
          Tax data
        </Heading>
      </Box>
      <StyledWrapper container spacing={2}>
        {userBankTaxInfoFieldsGroup.map(({ size, ...field }) => (
          <Grid item xs={size}>
            <InputByType disabled={!editor.isEditing} form={form} {...field} />
          </Grid>
        ))}
      </StyledWrapper>
    </>
  )
}

export default observer(ClientBankTaxFields)

const StyledWrapper = styled(Grid)`
  margin-bottom: 16px;
`
